import React, { useEffect, useRef, useState } from "react";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { adjustHexColor } from "../../../Utils";
import { ChevronIcon, SlackPng } from "../../../images";

interface MsgAttachmentViewContainerProps {
  imgOrSvg: React.ReactNode;
  title: string;
  subtitle: string;
  onQuickViewClick: () => void;
}

// use for link preview or file preview
const MsgAttachmentViewContainer: React.FC<MsgAttachmentViewContainerProps> = ({
  imgOrSvg,
  onQuickViewClick,
  subtitle,
  title,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isWideScreen, setIsWideScreen] = useState(true);

  useEffect(() => {
    const observer = new ResizeObserver(([entry]) => {
      const width = entry.contentRect.width;
      setIsWideScreen(width > 325); // Set to true if width > 325px
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <Stack
      ref={containerRef}
      sx={{
        height: "45px",
        borderRadius: "5px",
        border: "2px solid #E9EDF2",
        bgcolor: adjustHexColor("#8F8D8D", 10),
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
        boxSizing: "border-box",
        flexShrink: 0,
        padding: "0px 17px 0px 10px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "20px",
        width: "100%",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          width: isWideScreen ? "calc(100% - 90px)" : "calc(100% - 40px)",
          overflow: "hidden",
        }}
      >
        {imgOrSvg}
        <Stack
          sx={{ gap: "2px", width: "calc(100% - 30px)", overflow: "hidden" }}
        >
          <Typography
            sx={{
              font: "normal normal 600 12px/15px Source Serif Pro",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              font: "normal normal 300 12px/15px Source Serif Pro",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {subtitle}
            {/* www.figma.com/bearishos Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Amet, porro? */}
          </Typography>
        </Stack>
      </Stack>

      <Stack sx={{ flexShrink: 0 }}>
        {isWideScreen ? (
          <Button
            onClick={onQuickViewClick}
            sx={{
              height: "30px",
              minWidth: 0,
              textTransform: "none",
              padding: "0px 5px",
              border: "1px solid #E9EDF2",
              bgcolor: "#D9E3EF",
              ":hover": { bgcolor: adjustHexColor("#D9E3EF", 110) },
            }}
          >
            <Typography
              sx={{
                font: "normal normal 700 13px/16px Source Serif Pro",
                color: "black",
              }}
            >
              Quick View
            </Typography>
          </Button>
        ) : (
          <IconButton onClick={onQuickViewClick}>
            <ChevronIcon w={15} direction="right" />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default MsgAttachmentViewContainer;
