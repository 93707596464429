import React, { useEffect, useState } from "react";
import { Stack, Tooltip } from "@mui/material";
import {
  IconDropdownUser,
  IconDropdownUserOptionInterface,
  SmallGroupedAvatars,
} from "../../../../../../common";
import { getUserList } from "../../../../../../../Utils";

interface AssigneesDropdownProps {
  onSelect?: (userData: any) => void;
  mode?: string;
  selectedUser?: any;
  allusers?: any;
}

const AssigneesDropdown: React.FC<AssigneesDropdownProps> = ({
  onSelect,
  mode,
  selectedUser,
  allusers,
}) => {
  const userList = getUserList();

  const [selectedUsers, setSelectedUsers] = useState<
    any[]
  >(allusers || []);

  const handleSelect = (item: IconDropdownUserOptionInterface) => {
    setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, item]);

    if (onSelect && item) {
      onSelect(item);
    }
  };

  // console.log("Selected Users:", selectedUsers);

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "20px" }}>
      {/* <Stack
        sx={{
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          // minWidth: "180px",
          // maxWidth: "180px",
        }}
      >
        <IconDropdownUser
          componentType="showUser"
          showSelectedUser={DUMMY_DATA[4] || null}
          options={userList}
          onSelect={(item) => console.log(item)}
        />
        <Typography
          sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}
        >
          Richa
        </Typography>
      </Stack> */}

      <SmallGroupedAvatars maxAvatars={4} usersData={selectedUsers} />

      <Tooltip title="Add new">
        <IconDropdownUser
          componentType={
            mode ? (mode === "view" ? "showUser" : "showUser") : "showUser"
          }
          options={userList}
          showSelectedUser={selectedUser || DUMMY_DATA[4] || null}
          onSelect={handleSelect}
        />
      </Tooltip>
    </Stack>
  );
};

export default AssigneesDropdown;

const DUMMY_DATA: IconDropdownUserOptionInterface[] = [
  {
    id: 1,
    name: "Richard Cornsmith",
    imgSrc: "https://randomuser.me/api/portraits/men/1.jpg",
    avatarColor: "#FF5733",
    email: "richard.cornsmith@example.com",
  },
  {
    id: 2,
    name: "John Doe",
    email: "john.doe@example.com",
  },
  {
    id: 3,
    name: "Jane Smith",
    imgSrc: "https://randomuser.me/api/portraits/women/3.jpg",
    avatarColor: "#3357FF",
    email: "jane.smith@example.com",
  },
  {
    id: 4,
    name: "Alice Johnson",
    imgSrc: "https://randomuser.me/api/portraits/women/4.jpg",
    avatarColor: "#FF33A6",
    email: "alice.johnson@example.com",
  },
  {
    id: 5,
    name: "Bob Brown",
    avatarColor: "#33FFA2",
    email: "bob.brown@example.com",
  },
];
