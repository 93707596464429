import { Tooltip, Typography } from "@mui/material";
import React from "react";
import { ErrorIcon } from "../../../images";

interface ErrorHoverInfoProps {
  errorText: string;
}

const ErrorHoverInfo: React.FC<ErrorHoverInfoProps> = ({ errorText }) => {
  return (
    // <span style={{ height: "17px" }}>
    <Tooltip
      title={
        <Typography
          sx={{
            font: "normal normal 300 11px/14px Source Serif Pro",
            width: "175px",
            whiteSpace: "wrap",
          }}
        >
          {errorText}
        </Typography>
      }
      placement="bottom-start"
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [2, -10], // Adjust the second value to change the gap
            },
          },
        ],
      }}
    >
      <span
        style={{
          flexShrink: 0,
          height: "14px",
          display: "flex",
          alignItems: "center",
          paddingTop: "2px",
        }}
      >
        <ErrorIcon color="#852221" w={15} />
      </span>
    </Tooltip>
    // </span>
  );
};

export default ErrorHoverInfo;
