import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropdownWIconLarge } from "../DropdownWIconLarge";
import { TimeZonePng } from "../../../images";
import { getAllTimezonesFormatted } from "../../pages/Settings/Components/common";
import { RootState } from "../../../redux/store";
import { updateStartEndTimeBasedOnTimeZone } from "../../../redux";
import { Box, Popover, Stack, Typography } from "@mui/material";

interface TimeZoneCalendarDropdownProps {
  initialValue?: string;
  onChange?: (selectedOption: string) => void;
  showSortWords?: boolean;
}

const TimeZoneCalendarDropdown: React.FC<TimeZoneCalendarDropdownProps> = ({
  initialValue,
  onChange,
  showSortWords = false,
}) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "pst-popover" : undefined;
  const currentTimeZone = useSelector(
    (state: RootState) =>
      state &&
      state.calendar &&
      state.calendar.update__start_endTime__based__TimeZone
  );
  const [timezones, setTimezones] = useState<string[]>([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState<string>(
    initialValue ||
      currentTimeZone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  useEffect(() => {
    const fetchedTimezones = getAllTimezonesFormatted();
    setTimezones(
      fetchedTimezones?.map((timezone) => timezone?.name || timezone?.id)
    );
  }, []);

  useEffect(() => {
    if (!initialValue) {
      dispatch(updateStartEndTimeBasedOnTimeZone(selectedTimeZone));
    }
  }, [dispatch, initialValue, selectedTimeZone]);

  useEffect(() => {
    if (onChange && selectedTimeZone) {
      onChange(selectedTimeZone); // Notify parent of the selected timezone
    }
  }, [selectedTimeZone, onChange]);

  return (
    <>
      {showSortWords ? (
        <span>
          <Stack
            onClick={handleClick}
            sx={{
              cursor: "pointer",
              font: "normal normal 400 13px/16px Source Serif Pro",
            }}
          >
            PST
          </Stack>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
                height: "auto",
                overflow: "hidden",
                scrollbarWidth: "none",
              },
            }}
          >
            <DropdownWIconLarge
              multiple={false}
              inputHeight={25}
              initialValue={selectedTimeZone}
              width="200px"
              icon={"chevron"}
              iconSrc={TimeZonePng}
              options={timezones}
              onChange={(value) => {
                setSelectedTimeZone(value);
                dispatch(updateStartEndTimeBasedOnTimeZone(value)); // Dispatch the new value to Redux
              }}
            />
          </Popover>
        </span>
      ) : (
        <DropdownWIconLarge
          multiple={false}
          inputHeight={25}
          initialValue={selectedTimeZone}
          width="200px"
          icon={"chevron"}
          iconSrc={TimeZonePng}
          options={timezones}
          onChange={(value) => {
            setSelectedTimeZone(value);
            dispatch(updateStartEndTimeBasedOnTimeZone(value)); // Dispatch the new value to Redux
          }}
        />
      )}
    </>
  );
};

export default TimeZoneCalendarDropdown;
