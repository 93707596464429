import React from "react";
import CompanyIconAndName from "../CompanyIconAndName";

interface DynamicTagWrapperProps {
  tag: string;
  [key: string]: any;
}

const componentMapping: Record<string, React.ElementType> = {
  showCompany: CompanyIconAndName,
};

const DynamicTagWrapper: React.FC<DynamicTagWrapperProps> = ({
  tag,
  children,
  ...props
}) => {
  const Component = componentMapping[tag] || React.Fragment;

  return (
    <>
      <Component {...props} />
      {children}
    </>
  );
};

export default DynamicTagWrapper;
