import {Stack} from "@mui/material";
import React, {useCallback, useContext, useEffect, useMemo, useRef} from "react";
import {CenterMutedView, MutedMicView, NamePreview, VideoOffView,} from "./view";
import {adjustHexColor} from "../../../../../Utils";
import {MeetingContext} from "../../../../Povider";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import {GlobalState} from "../../../../../redux/Slice/Global/globalTypes";

interface UserPreviewInCallProps {
  isVideoCall?: boolean;
  isMicOff?: boolean;
  isCameraOff?: boolean;
  centerMicIconSize?: number;
  showNameInBotttom?: boolean;
  aspectRatio?: string;
  height?: string;
  width?: string;
  userId?: string;
  videoTileState?: any;
}

const UserPreviewInCall: React.FC<UserPreviewInCallProps> = ({
  centerMicIconSize = 60,
  isCameraOff = false,
  isMicOff = false,
  isVideoCall = false,
  showNameInBotttom = false,
  height = "150px",
  width = "220px",
                                                                 aspectRatio = "1080/720",
                                                                 userId = "",
                                                                 videoTileState,
}) => {

    const videoElementRef = useRef(null);
    const meetingContext: any = useContext(MeetingContext);
    const {groupCallState} = useSelector((state: RootState) => state.global as GlobalState);
    const videoTileUser = useMemo(() => groupCallState?.realTimeCallAttendee?.[userId]
            ,
            [groupCallState?.realTimeCallAttendee, userId]
        )
    ;

    const userFullName = useMemo(
        () =>
            [videoTileUser?.firstName, videoTileUser?.lastName]
                .filter(Boolean)
                .join(" "),
        [videoTileUser?.firstName, videoTileUser?.lastName]
    );

    const updateVideoTile = useCallback(() => {
        if (videoTileState?.tileId && videoElementRef?.current) {
            meetingContext.bindVideoTile({
                tileId: videoTileState.tileId,
                videoElement: videoElementRef.current,
            });
        }
    }, [videoTileState?.tileId]);

    useEffect(() => {
        updateVideoTile();
    }, [updateVideoTile]);

    return (
    <Stack
      sx={{
        height: height,
        width: width,
        boxSizing: "border-box",
        flexShrink: 0,
        flexGrow: 0,
        position: "relative",
      }}
    >
        <video
            ref={videoElementRef}
            autoPlay={true}
            playsInline={true}
            muted={false}
            style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                backgroundColor: "#E9EDF2",
                borderRadius: "inherit",
                // display: videoTileState?.active ? "block" : "none",
                aspectRatio,
            }}
        />

      {/* layer over avatar or video tiles */}
      <Stack
        sx={{
          position: "absolute",
          height: height,
          width: width,
          zIndex: 5,
          boxSizing: "border-box",
          padding: "5px",
          justifyContent: "space-between",
          bgcolor: isVideoCall ? "inherit" : adjustHexColor("#ffffff", 30),
        }}
      >
        <Stack sx={{ flexDirection: "row", justifyContent: "end", gap: "5px" }}>
          {isCameraOff && <VideoOffView />}
          {isMicOff && <MutedMicView />}
        </Stack>

        <Stack sx={{ alignItems: "center", pb: "10px" }}>
          {!isVideoCall && <CenterMutedView size={centerMicIconSize} />}
        </Stack>

        <Stack sx={{ alignItems: "center" }}>
            {showNameInBotttom && <NamePreview name={userFullName}/>}
        </Stack>
      </Stack>
      {/* ---------------------------------- */}
    </Stack>
  );
};

export default UserPreviewInCall;
