import React, { useEffect, useState } from "react";
import styles from "./ToggleButtonGroup.module.css";
import { Button, Typography } from "@mui/material";
import { adjustHexColor } from "../../../../../Utils";

export type ToggleOptionsTyps = {
  _id: string;
  translatedLabel: string;
  value: string;
};

interface ToggleButtonGroupProps {
  options: ToggleOptionsTyps[];
  initialvalue?: ToggleOptionsTyps;
  onChange: (selectedOption: ToggleOptionsTyps) => void;
  selectedColor?: string;
}

const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({
  options,
  onChange,
  selectedColor = "#008D4C",
  initialvalue = options[0],
}) => {
  const [selected, setSelected] = useState<ToggleOptionsTyps>(initialvalue);

  const handleSelect = (option: ToggleOptionsTyps) => {
    if (option.value !== selected.value) {
      setSelected(option);
      onChange(option);
    }
  };

  useEffect(() => {
    setSelected(initialvalue);
  }, [initialvalue]);

  return (
    <div className={styles["toggleButtonGroup"]}>
      {options.map((option) => (
        <Button
          key={option.value}
          className={styles.toggleButton}
          onClick={() => handleSelect(option)}
          sx={{
            color: selectedColor,
            bgcolor:
              option.value === selected.value
                ? adjustHexColor(selectedColor, 30)
                : "transparent",

            ":hover": {
              bgcolor:
                option.value === selected.value
                  ? adjustHexColor(selectedColor, 30)
                  : adjustHexColor(selectedColor, 15),
            },
          }}
        >
          <Typography
            sx={{
              font: "normal normal 300 13px/17px Source Serif Pro",
              color: "black",
              whiteSpace: "nowrap",
            }}
          >
            {option.translatedLabel}
          </Typography>
        </Button>
      ))}
    </div>
  );
};

export default ToggleButtonGroup;
