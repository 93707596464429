import React from "react";
import { ReplyContainer} from "../view";
import { Stack, Typography } from "@mui/material";
import moment from "moment-timezone";

interface MessageReplyBarProps {
    onCloseClick?: () => void;
    replayMessage: any;
    forViewOnly?: boolean;
}

const MessageReplyBar: React.FC<MessageReplyBarProps> = ({onCloseClick = () => {} ,replayMessage,forViewOnly}) => {

    return (
        <ReplyContainer
            replyType="MessageReply"
            dateAndTimeComponents={
                <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
                    <Typography
                        sx={{ font: "normal normal 600 11px/14px Source Serif Pro" }}
                    >
                        {replayMessage?.userDetails?.displayName}
                    </Typography>
                    <Typography
                        sx={{
                            font: "normal normal 300 11px/14px Source Serif Pro",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {" "}  at {moment(replayMessage?.createdAt).format("MM/DD/YYYY [at] hh:mm A")}
                    </Typography>
                </Stack>
            }
            infoComponents={
                <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px", overflow: "hidden" }}>
                    <Typography
                        sx={{
                            font: "normal normal 300 11px/14px Source Serif Pro",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {replayMessage?.content}
                    </Typography>
                </Stack>
            }
            onCloseClick={() => onCloseClick && onCloseClick()}
            forViewOnly={forViewOnly}
            userName={replayMessage?.userDetails?.displayName}
            userStatus={undefined}
            userImgSrc={replayMessage?.userDetails?.avatar}
        />
    );
};

export default MessageReplyBar;
