import React from "react";
import {Box, Stack, styled} from "@mui/material";
import {AvatarNormal} from "../AvatarNormal";
import {StreamIconBearishOSIconPng} from "../../../images";

interface AvatarWithCompanyProps {
  size: 30 | 40;
  userName: string;
  userIconSrc?: string;
  isActive?: boolean;
  onClick?: any;
  onContextMenu?: (event: React.MouseEvent<HTMLElement>) => void;
    companyLogoSrc: any;
    sx?: any;
}

const AvatarWithCompany: React.FC<AvatarWithCompanyProps> = ({
  size,
  isActive,
  userName,
  userIconSrc,
  onClick,
  onContextMenu,
                                                                 companyLogoSrc,
                                                                 sx
}) => {
  // Styled Badge for the top badge (image)
  const TopBadge = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: size === 40 ? -2 : 0,
    right: size === 40 ? -2 : 0,
    width: size === 40 ? "10px" : "5px",
    height: size === 40 ? "10px" : "5px",
    boxSizing: "border-box",
  }));

  // Custom Box for the bottom badge (color)
  const BottomBadge = styled(Box)(({ theme }) => ({
    position: "absolute",
    bottom: size === 40 ? -2 : 0,
    right: size === 40 ? -2 : 0,
    width: size === 40 ? "10px" : "5px",
    height: size === 40 ? "10px" : "5px",
    boxSizing: "border-box",
    borderRadius: "50%",
    backgroundColor: "green",
  }));

  return (
      <Stack
          sx={{
            position: "relative",
            width: `${size}px`,
            height: `${size}px`,
            border: size === 40 ? "2px solid #E9EDF2" : "1px solid #E9EDF2",
            boxSizing: "border-box",
            borderRadius: "50%",
            alignItems: "center",
            justifyContent: "center", cursor: "pointer",
          }}
          onClick={onClick}
          onContextMenu={onContextMenu}
      >
        <AvatarNormal
            username={userName}
            size={size === 40 ? size - 4 : size - 1}
            imgSrc={userIconSrc}
        />
        <TopBadge
            component="img"
            //@ts-ignore
            src={StreamIconBearishOSIconPng as any}
            alt="Top Badge"
            sx={{
              borderRadius: "50%",
            }}
        />
        {isActive !== undefined && <BottomBadge />}
      </Stack>
  );
};

export default AvatarWithCompany;
