import React from "react";
import { ProjectInfoTab } from "../components";
import { Box, Stack, Typography } from "@mui/material";
import { MediumSearchbar } from "../../../common";

interface ProjectDetailsProps {}

const ProjectDetails: React.FC<ProjectDetailsProps> = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        mt: "20px",
        px: "20px",
      }}
    >
      <Stack sx={{ gap: "15px", width: "100%" }}>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              font: "normal normal 900 25px/34px Source Serif Pro",
              maxWidth: "500px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Bearish OS Project
          </Typography>
          <MediumSearchbar ph="Search Bearish OS Project" />
        </Stack>

        <Stack sx={{ height: "calc(100vh - 145px)" }}>
          <ProjectInfoTab />
        </Stack>
      </Stack>
    </Box>
  );
};

export default ProjectDetails;
