import { Box, Popover, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { AddToWorkspaceOptionsEnum } from "../../../../pages/Workspace/components";
import WorkspaceDropdownComponent from "./WorkspaceDropdownComponent";
import {
  BearishPng,
  ChecklistIconPng,
  ConfluenceLogoPng,
  DocsIconPng,
  Grids1IconPng,
  GridsIconPng,
  JiraLogoPng,
  TaskCenterIconPng,
  WhiteboardsIconPng,
  WikisIconPng,
} from "../../../../../images";

interface SelectWorkspaceDropdownProps {}

export type WorkspaceOptionsType = {
  _id: string;
  workspaceName: string;
  workSpaceHost: "bearish" | "jira" | "confluence";
  subList: {
    _id: string;
    name: string;
    type: AddToWorkspaceOptionsEnum;
  }[];
};

/**
 * Render workspace logos
 */
export const renderWorkspaceLogo = (host: string) => {
  switch (host) {
    case "jira":
      return JiraLogoPng;
    case "confluence":
      return ConfluenceLogoPng;
    default:
      return BearishPng;
  }
};

/**
 * Render sublist icons
 */
export const renderSubListIcon = (type: AddToWorkspaceOptionsEnum) => {
  switch (type) {
    case AddToWorkspaceOptionsEnum.Board:
      return Grids1IconPng;
    case AddToWorkspaceOptionsEnum.Checklist:
      return ChecklistIconPng;
    case AddToWorkspaceOptionsEnum.Document:
      return DocsIconPng;
    case AddToWorkspaceOptionsEnum.Grid:
      return GridsIconPng;
    case AddToWorkspaceOptionsEnum.List:
      return TaskCenterIconPng;
    case AddToWorkspaceOptionsEnum.Whiteboard:
      return WhiteboardsIconPng;
    case AddToWorkspaceOptionsEnum.Wiki:
      return WikisIconPng;
    default:
      return BearishPng;
  }
};

const SelectWorkspaceDropdown: React.FC<
  SelectWorkspaceDropdownProps
> = ({}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [selectedWorkspaceItem, setSelectedWorkspaceItem] =
    useState<WorkspaceOptionsType>();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "workspace-popover" : undefined;
  console.log("first", selectedWorkspaceItem);
  return (
    <Stack>
      <Stack onClick={handleClick} sx={{ cursor: "pointer" }}>
        <Stack
          sx={{
            font: "normal normal 900 11px/14px Source Serif Pro",
            whiteSpace: "nowrap",
            flexShrink: 0,
          }}
        >
          {selectedWorkspaceItem ? (
            <Stack
              sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}
            >
              <Box
                component={"img"}
                src={renderWorkspaceLogo(selectedWorkspaceItem.workSpaceHost)}
                alt="logo"
                sx={{ height: "15px", width: "15px" }}
              />
              <span>{selectedWorkspaceItem.workspaceName}</span>{" "}
              <Box
                component={"img"}
                src={renderSubListIcon(selectedWorkspaceItem.subList[0].type)}
                alt="logo"
                sx={{ height: "15px", width: "15px" }}
              />
              <span>{selectedWorkspaceItem.subList[0].name}</span>
            </Stack>
          ) : (
            "[Workspace, Board name]"
          )}
        </Stack>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            mt: "-5px",
          },
        }}
      >
        <WorkspaceDropdownComponent
          options={DUMMY_WORKSPACE_LIST}
          initialValue={selectedWorkspaceItem}
          onChange={(val) => {
            setSelectedWorkspaceItem(val);
            handleClose();
          }}
        />
      </Popover>
    </Stack>
  );
};

export default SelectWorkspaceDropdown;

const DUMMY_WORKSPACE_LIST: WorkspaceOptionsType[] = [
  {
    _id: "1",
    workspaceName: "Team Collaboration",
    workSpaceHost: "bearish",
    subList: [
      {
        _id: "1-1",
        name: "Project Board",
        type: AddToWorkspaceOptionsEnum.Board,
      },
      {
        _id: "1-2",
        name: "Team Checklist",
        type: AddToWorkspaceOptionsEnum.Checklist,
      },
      { _id: "1-3", name: "Task List", type: AddToWorkspaceOptionsEnum.Decks },
      {
        _id: "1-4",
        name: "Feature Grid",
        type: AddToWorkspaceOptionsEnum.Document,
      },
      {
        _id: "1-5",
        name: "Planning Document",
        type: AddToWorkspaceOptionsEnum.Grid,
      },
      {
        _id: "1-6",
        name: "Planning Document",
        type: AddToWorkspaceOptionsEnum.List,
      },
      {
        _id: "1-7",
        name: "Planning Document",
        type: AddToWorkspaceOptionsEnum.Whiteboard,
      },
      {
        _id: "1-8",
        name: "Planning Document",
        type: AddToWorkspaceOptionsEnum.Wiki,
      },
      {
        _id: "1-9",
        name: "Planning Document",
        type: AddToWorkspaceOptionsEnum.Decks,
      },
      {
        _id: "1-10",
        name: "Planning Document",
        type: AddToWorkspaceOptionsEnum.List,
      },
      {
        _id: "1-11",
        name: "Planning Document",
        type: AddToWorkspaceOptionsEnum.Document,
      },
    ],
  },
  {
    _id: "2",
    workspaceName: "Task Management",
    workSpaceHost: "jira",
    subList: [
      { _id: "2-1", name: "Task Board", type: AddToWorkspaceOptionsEnum.Board },
      {
        _id: "2-2",
        name: "Project Checklist",
        type: AddToWorkspaceOptionsEnum.Checklist,
      },
      { _id: "2-3", name: "Sprint List", type: AddToWorkspaceOptionsEnum.List },
      {
        _id: "2-4",
        name: "Backlog Grid",
        type: AddToWorkspaceOptionsEnum.Grid,
      },
      {
        _id: "2-5",
        name: "Design Document",
        type: AddToWorkspaceOptionsEnum.Document,
      },
      {
        _id: "2-6",
        name: "Technical Wiki",
        type: AddToWorkspaceOptionsEnum.Wiki,
      },
    ],
  },
  {
    _id: "3",
    workspaceName: "Marketing Docs",
    workSpaceHost: "confluence",
    subList: [
      {
        _id: "3-1",
        name: "Campaign Board",
        type: AddToWorkspaceOptionsEnum.Board,
      },
      {
        _id: "3-2",
        name: "Strategy Checklist",
        type: AddToWorkspaceOptionsEnum.Checklist,
      },
      {
        _id: "3-3",
        name: "Content List",
        type: AddToWorkspaceOptionsEnum.List,
      },
      { _id: "3-4", name: "Asset Grid", type: AddToWorkspaceOptionsEnum.Grid },
      {
        _id: "3-5",
        name: "Marketing Document",
        type: AddToWorkspaceOptionsEnum.Document,
      },
    ],
  },
  {
    _id: "4",
    workspaceName: "Development Plans",
    workSpaceHost: "bearish",
    subList: [
      {
        _id: "4-1",
        name: "Feature Board",
        type: AddToWorkspaceOptionsEnum.Board,
      },
      {
        _id: "4-2",
        name: "Release Checklist",
        type: AddToWorkspaceOptionsEnum.Checklist,
      },
      { _id: "4-3", name: "Module List", type: AddToWorkspaceOptionsEnum.List },
      { _id: "4-4", name: "Sprint Grid", type: AddToWorkspaceOptionsEnum.Grid },
      {
        _id: "4-5",
        name: "Engineering Document",
        type: AddToWorkspaceOptionsEnum.Document,
      },
    ],
  },
  {
    _id: "5",
    workspaceName: "Knowledge Base",
    workSpaceHost: "confluence",
    subList: [
      {
        _id: "5-1",
        name: "Guideline Board",
        type: AddToWorkspaceOptionsEnum.Board,
      },
      {
        _id: "5-2",
        name: "FAQs Checklist",
        type: AddToWorkspaceOptionsEnum.Checklist,
      },
      {
        _id: "5-3",
        name: "Resource List",
        type: AddToWorkspaceOptionsEnum.List,
      },
      {
        _id: "5-4",
        name: "Knowledge Grid",
        type: AddToWorkspaceOptionsEnum.Grid,
      },
      {
        _id: "5-5",
        name: "Reference Document",
        type: AddToWorkspaceOptionsEnum.Document,
      },
      {
        _id: "5-6",
        name: "Internal Wiki",
        type: AddToWorkspaceOptionsEnum.Wiki,
      },
    ],
  },
  {
    _id: "6",
    workspaceName: "HR Management",
    workSpaceHost: "confluence",
    subList: [
      {
        _id: "6-1",
        name: "Employee Board",
        type: AddToWorkspaceOptionsEnum.Board,
      },
      {
        _id: "6-2",
        name: "Onboarding Checklist",
        type: AddToWorkspaceOptionsEnum.Checklist,
      },
      {
        _id: "6-3",
        name: "Benefits List",
        type: AddToWorkspaceOptionsEnum.List,
      },
      {
        _id: "6-4",
        name: "Payroll Grid",
        type: AddToWorkspaceOptionsEnum.Grid,
      },
      {
        _id: "6-5",
        name: "HR Policies Document",
        type: AddToWorkspaceOptionsEnum.Document,
      },
    ],
  },
  {
    _id: "7",
    workspaceName: "Sales Pipeline",
    workSpaceHost: "jira",
    subList: [
      { _id: "7-1", name: "Lead Board", type: AddToWorkspaceOptionsEnum.Board },
      {
        _id: "7-2",
        name: "Prospect Checklist",
        type: AddToWorkspaceOptionsEnum.Checklist,
      },
      {
        _id: "7-3",
        name: "Opportunity List",
        type: AddToWorkspaceOptionsEnum.List,
      },
      { _id: "7-4", name: "Deal Grid", type: AddToWorkspaceOptionsEnum.Grid },
      {
        _id: "7-5",
        name: "Sales Document",
        type: AddToWorkspaceOptionsEnum.Document,
      },
    ],
  },
  {
    _id: "8",
    workspaceName: "Customer Support",
    workSpaceHost: "jira",
    subList: [
      {
        _id: "8-1",
        name: "Tickets Board",
        type: AddToWorkspaceOptionsEnum.Board,
      },
      {
        _id: "8-2",
        name: "Support Checklist",
        type: AddToWorkspaceOptionsEnum.Checklist,
      },
      {
        _id: "8-3",
        name: "Escalation List",
        type: AddToWorkspaceOptionsEnum.List,
      },
      {
        _id: "8-4",
        name: "Resolution Grid",
        type: AddToWorkspaceOptionsEnum.Grid,
      },
      {
        _id: "8-5",
        name: "Support Guidelines",
        type: AddToWorkspaceOptionsEnum.Document,
      },
    ],
  },
  {
    _id: "9",
    workspaceName: "IT Operations",
    workSpaceHost: "confluence",
    subList: [
      {
        _id: "9-1",
        name: "Incident Board",
        type: AddToWorkspaceOptionsEnum.Board,
      },
      {
        _id: "9-2",
        name: "Maintenance Checklist",
        type: AddToWorkspaceOptionsEnum.Checklist,
      },
      { _id: "9-3", name: "Change List", type: AddToWorkspaceOptionsEnum.List },
      {
        _id: "9-4",
        name: "Infrastructure Grid",
        type: AddToWorkspaceOptionsEnum.Grid,
      },
      {
        _id: "9-5",
        name: "IT Policies Document",
        type: AddToWorkspaceOptionsEnum.Document,
      },
    ],
  },
  {
    _id: "10",
    workspaceName: "Finance Reports",
    workSpaceHost: "bearish",
    subList: [
      {
        _id: "10-1",
        name: "Budget Board",
        type: AddToWorkspaceOptionsEnum.Board,
      },
      {
        _id: "10-2",
        name: "Expenses Checklist",
        type: AddToWorkspaceOptionsEnum.Checklist,
      },
      {
        _id: "10-3",
        name: "Income List",
        type: AddToWorkspaceOptionsEnum.List,
      },
      {
        _id: "10-4",
        name: "Cashflow Grid",
        type: AddToWorkspaceOptionsEnum.Grid,
      },
      {
        _id: "10-5",
        name: "Financial Document",
        type: AddToWorkspaceOptionsEnum.Document,
      },
    ],
  },
];
