import { sortChats } from "./sortChats";

export const sortChannelBasedOnLastMessage = (state: any, data: any) => {
    if (state.channelChats[data.channelId]) {
        state.channelChats[data.channelId].lastMessageDate = data.createdAt;
        state.channelChats[data.channelId].lastMessage = data.content;
        state.channelChats = sortChats(state.channelChats);
    }

    if (state.directChannel[data.channelId]) {
        state.directChannel[data.channelId].lastMessageDate = data.createdAt;
        state.directChannel[data.channelId].lastMessage = data.content;
        state.directChannel = sortChats(state.directChannel);
    }

    if (state.pinnedChannels[data.channelId]) {
        state.pinnedChannels[data.channelId].lastMessageDate = data.createdAt;
        state.pinnedChannels[data.channelId].lastMessage = data.content;
        state.pinnedChannels = sortChats(state.pinnedChannels);
    }
};