import { Box, Divider, IconButton, Slide, Stack } from "@mui/material";
import React from "react";
import {
  ActivtiyIcon,
  BearishOSIconCloudStroge,
  FirstMessageIcon,
  FoldReachIcon,
  LockIcon,
  QuickActionIcon,
  UnlockIcon,
  WorkspaceCIcon,
} from "../../../../../../images";
import {
  BebaAiCloud,
  GreenArrowButton,
  ProjectsSidebar,
  QuickActions,
} from "../../../../../common";
import { CommentFilePanel } from "./CommentFilePanel";
import { useDispatch } from "react-redux";
import { changeLayoutState } from "../../../../../../redux";
import { adjustHexColor } from "../../../../../../Utils";

type RightSidePanelProps = {
  setRightPanelOpen: (isOpen: boolean) => void;
  rightPanelOpen: boolean;
  fileData?: any;
  handleGetRPC?: (data: any) => void;
  rightPanelComponent: string;
};

const RightSidePanel = ({
  rightPanelOpen,
  setRightPanelOpen,
  fileData,
  handleGetRPC,
  rightPanelComponent,
}: RightSidePanelProps) => {
  const selectedColor = adjustHexColor("#00767B", 25);

  const dispatch = useDispatch();

  // Toggle drawer state
  const toggleDrawer = (isPanelOpen: boolean) => {
    dispatch(
      changeLayoutState({
        type: "isDrawer_CloudStorage",
        value: !isPanelOpen, // Close the drawer when the panel is open
      })
    );
  };

  // Handle panel open/close logic
  const handlePanelClick = (componentName: string) => {
    const isSameComponent = rightPanelComponent === componentName;
    const shouldOpenRightPanel = !isSameComponent;

    setRightPanelOpen(shouldOpenRightPanel);

    if (handleGetRPC) {
      handleGetRPC(shouldOpenRightPanel ? componentName : "");
    }

    toggleDrawer(shouldOpenRightPanel);
  };

  // Render the right-side panel component based on `rightPanelComponent`
  const renderRightPanelContent = () => {
    switch (rightPanelComponent) {
      case "comments":
        return (
          <CommentFilePanel handlePanelClose={() => setRightPanelOpen(false)} />
        );
      case "calendar":
        return (
          <CommentFilePanel handlePanelClose={() => setRightPanelOpen(false)} />
        );
      case "project":
        return (
          <ProjectsSidebar handlePanelClose={() => setRightPanelOpen(false)} />
        );
      case "quickAction":
        return (
          <QuickActions handlePanelClose={() => setRightPanelOpen(false)} />
        );
      case "bebeAI":
        return (
          <BebaAiCloud handlePanelClose={() => setRightPanelOpen(false)} />
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ background: "white", height: "100%", padding: "15px" }}>
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        height={"93%"}
        justifyContent={"flex-start"}
      >
        {/* Left Icon Panel */}
        <Stack
          direction={"column"}
          alignItems={"center"}
          justifyContent={"space-between"}
          height={"100%"}
        >
          {/* Top Icons */}
          <Stack
            direction={"column"}
            gap={2}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Stack mt={3} direction={"column"} gap={3}>
              <IconButton
                onClick={() => handlePanelClick("comments")}
                sx={{
                  p: "5px",
                  borderRadius: "5px",
                  backgroundColor:
                    rightPanelComponent === "comments"
                      ? selectedColor
                      : "transparent",
                  "&:hover": {
                    backgroundColor: adjustHexColor("#00767B", 15),
                  },
                }}
              >
                <FirstMessageIcon w={25} />
              </IconButton>
              <IconButton
                onClick={() => handlePanelClick("activity")}
                sx={{
                  p: "5px",
                  borderRadius: "5px",
                  backgroundColor:
                    rightPanelComponent === "activity"
                      ? selectedColor
                      : "transparent",
                  "&:hover": {
                    backgroundColor: adjustHexColor("#00767B", 15),
                  },
                }}
              >
                <ActivtiyIcon w={25} />
              </IconButton>
              <IconButton
                sx={{
                  p: "5px",
                  borderRadius: "5px",
                  backgroundColor: fileData?.locking?.isLocked
                    ? selectedColor
                    : "transparent",
                  "&:hover": {
                    backgroundColor: adjustHexColor("#00767B", 15),
                  },
                }}
              >
                {fileData?.locking?.isLocked ? (
                  <LockIcon w={25} />
                ) : (
                  <UnlockIcon w={25} />
                )}
              </IconButton>
            </Stack>
            <Divider
              orientation="horizontal"
              sx={{ borderColor: "#E9EDF2", width: "25px", height: "10px" }}
            />
            {/* Bottom Icons */}
            <Stack mt={3} direction={"column"} gap={3}>
              <IconButton
                onClick={() => handlePanelClick("project")}
                sx={{
                  p: "5px",
                  borderRadius: "5px",
                  backgroundColor:
                    rightPanelComponent === "project"
                      ? selectedColor
                      : "transparent",
                  "&:hover": {
                    backgroundColor: adjustHexColor("#00767B", 15),
                  },
                }}
              >
                <FoldReachIcon color="#008D4C" w={25} />
              </IconButton>
              <IconButton
                onClick={() => handlePanelClick("calendar")}
                sx={{
                  p: "5px",
                  borderRadius: "5px",
                  backgroundColor:
                    rightPanelComponent === "calendar"
                      ? selectedColor
                      : "transparent",
                  "&:hover": {
                    backgroundColor: adjustHexColor("#00767B", 15),
                  },
                }}
              >
                <WorkspaceCIcon w={25} />
              </IconButton>
              <IconButton
                onClick={() => handlePanelClick("quickAction")}
                sx={{
                  p: "5px",
                  borderRadius: "5px",
                  backgroundColor:
                    rightPanelComponent === "quickAction"
                      ? selectedColor
                      : "transparent",
                  "&:hover": {
                    backgroundColor: adjustHexColor("#00767B", 15),
                  },
                }}
              >
                <QuickActionIcon w={25} />
              </IconButton>
              <IconButton
                onClick={() => handlePanelClick("bebeAI")}
                sx={{
                  p: "5px",
                  borderRadius: "5px",
                  backgroundColor:
                    rightPanelComponent === "bebeAI"
                      ? selectedColor
                      : "transparent",
                  "&:hover": {
                    backgroundColor: adjustHexColor("#00767B", 15),
                  },
                }}
              >
                <BearishOSIconCloudStroge w={25} />
              </IconButton>
            </Stack>
          </Stack>

          {/* Divider and Bottom Icon */}
          <Stack>
            <Divider orientation="horizontal" sx={{ borderColor: "#E9EDF2" }} />
            <Stack direction={"column"} gap={2}>
              <GreenArrowButton onClick={() => handlePanelClick("comments")} />
            </Stack>
          </Stack>
        </Stack>

        {/* Right Slide Panel */}
        <Slide direction="left" in={rightPanelOpen} mountOnEnter unmountOnExit>
          <Stack width={"375px"} height={"100%"}>
            {renderRightPanelContent()}
          </Stack>
        </Slide>
      </Stack>
    </Box>
  );
};

export default RightSidePanel;
