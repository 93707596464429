import React, { memo } from "react";
import { Container, Box, Stack, Skeleton, Typography } from "@mui/material";

interface UdProps {
    skeletop__type?:
    | "react-card"
    | "email-card"
    | "profile-card"
    | "project-card"
    | "Notes"
    | "Resources";
    fDd?: boolean;
    maxCount?: number;
}

const SkeletonItem: React.FC<{
    width: string;
    height: string;
    variant: "text" | "circular" | "rectangular";
}> = ({ width, height, variant }) => (
    <Skeleton
        variant={variant}
        width={width}
        height={height}
        sx={{ fontSize: "1rem", bgcolor: "lightgray", opacity: 0.6 }}
    />
);

const Ud: React.FC<UdProps> = ({
    skeletop__type = "react-card",
    fDd = true,
    maxCount = 8,
}) => {
    const skeletonConfig = {
        "react-card": {
            skeletons: (
                <Box sx={{ display: "flex", gap: "15px", flexDirection: "row" }}>
                    <SkeletonItem variant="circular" width="40px" height="40px" />
                    <Stack sx={{ flex: 1 }}>
                        <SkeletonItem variant="text" width="60%" height="auto" />
                        <SkeletonItem variant="text" width="80%" height="auto" />
                    </Stack>
                </Box>
            ),
        },
        "email-card": {
            skeletons: (
                <Box sx={{ display: "flex", gap: "15px", flexDirection: "row" }}>
                    <SkeletonItem variant="circular" width="40px" height="40px" />
                    <Stack sx={{ flex: 1 }}>
                        <SkeletonItem variant="text" width="50%" height="auto" />
                        <SkeletonItem variant="text" width="90%" height="auto" />
                    </Stack>
                </Box>
            ),
        },
        "profile-card": {
            skeletons: (
                <Box sx={{ display: "flex", gap: "15px", flexDirection: "row" }}>
                    <SkeletonItem variant="circular" width="60px" height="60px" />
                    <Stack sx={{ flex: 1 }}>
                        <SkeletonItem variant="text" width="40%" height="auto" />
                        <SkeletonItem variant="text" width="80%" height="auto" />
                        <SkeletonItem variant="text" width="60%" height="auto" />
                    </Stack>
                </Box>
            ),
        },
        "project-card": {
            skeletons: (
                <Box sx={{ display: "flex", gap: "15px", flexDirection: "row" }}>
                    <SkeletonItem variant="rectangular" width="150px" height="80px" />
                    <Stack sx={{ flex: 1 }}>
                        <SkeletonItem variant="text" width="60%" height="auto" />
                        <SkeletonItem variant="text" width="80%" height="auto" />
                        <SkeletonItem variant="text" width="80%" height="auto" />
                    </Stack>
                </Box>
            ),
        },
        Notes: {
            skeletons: (
                <Box sx={{ display: "flex", gap: "15px", flexDirection: "column" }}>
                    <SkeletonItem variant="text" width="80%" height="auto" />
                    <SkeletonItem variant="text" width="60%" height="auto" />
                </Box>
            ),
        },
        Resources: {
            skeletons: (
                <Box sx={{ display: "flex", gap: "15px", flexDirection: "row" }}>
                    <SkeletonItem variant="rectangular" width="120px" height="60px" />
                    <Stack sx={{ flex: 1 }}>
                        <SkeletonItem variant="text" width="50%" height="auto" />
                        <SkeletonItem variant="text" width="80%" height="auto" />
                    </Stack>
                </Box>
            ),
        },
        default: {
            skeletons: (
                <Box sx={{ display: "flex", gap: "15px", flexDirection: "row" }}>
                    <SkeletonItem variant="circular" width="40px" height="40px" />
                    <Stack sx={{ flex: 1 }}>
                        <SkeletonItem variant="text" width="60%" height="auto" />
                        <SkeletonItem variant="text" width="80%" height="auto" />
                    </Stack>
                </Box>
            ),
        },
    };

    const { skeletons } =
        skeletonConfig[skeletop__type] || skeletonConfig.default;

    return (
        <Container disableGutters>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
            >
                <Stack
                    sx={{
                        gap: "30px",
                        padding: "0px 0px 25px 15px",
                        position: "relative",
                    }}
                    width="100%"
                >
                    {/* Conditional Skeleton Rendering */}
                    {[...Array(maxCount || 8)].map((_, index) => (
                        <React.Fragment key={index}>{skeletons}</React.Fragment>
                    ))}

                    {/* Overlay Text in the middle */}
                    {fDd && (
                        <Typography
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                color: "rgba(0, 0, 0, 0.7)",
                                backgroundColor: "rgb(105 249 190 / 24%)",
                                padding: "10px 20px",
                                borderRadius: "5px",
                                zIndex: 10,
                                font: "italic normal normal 600 20px/21px Source Serif Pro",
                            }}
                        >
                            Under Development
                        </Typography>
                    )}
                </Stack>
            </Box>
        </Container>
    );
};

export default memo(Ud);
