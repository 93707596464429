import React from "react";
import {
  SmallThreeDotMenu,
  SmallThreeDotMenuListInterface,
} from "../../../../common";
import {
  AnalyticsIcon,
  ClosureIcon,
  DeleteIcon,
  RisksAndIssuesIcon,
  SettingsIcon,
} from "../../../../../images";

interface ProjectOptionThreeDotMenuProps {}

const optionsList: SmallThreeDotMenuListInterface[] = [
  {
    id: 6,
    icon: RisksAndIssuesIcon,
    text: "Risks & Issues",
  },
  {
    id: 7,
    icon: AnalyticsIcon,
    text: "Analytics",
  },
  {
    id: 8,
    icon: SettingsIcon,
    text: "Settings",
  },
  {
    id: 9,
    icon: ClosureIcon,
    text: "Closure",
  },
  {
    id: 10,
    icon: DeleteIcon,
    color: "red",
    text: "Delete Project",
  },
];

const ProjectOptionThreeDotMenu: React.FC<
  ProjectOptionThreeDotMenuProps
> = ({}) => {
  return <SmallThreeDotMenu menuItems={optionsList} />;
};

export default ProjectOptionThreeDotMenu;
