import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import {
  EndTimeView,
  HeaderView,
  RequestTypeContainerView,
  StartTimeView,
} from "./view";
import {
  ButtonSmall,
  EditableTextArea,
  SearchFoldersDropdown,
  SmallButtonColor,
  TextLinkButton,
} from "../../../../common";
import { IconContainer } from "../CreateShiftMenu/view";
import {
  AttechIcon,
  BalancesIcon,
  NotesLineIcon,
  TrackedIcon,
  UserTypeIcon,
} from "../../../../../images";
import { LeaveDropDown } from "../LeaveDropDown";
import { LeaveBalanceInfoBox, LeaveBalanceInfoTable } from "../../common";

interface TimeOffRequestMenuProps {
  onCloseClick: () => void;
}

const TimeOffRequestMenu: React.FC<TimeOffRequestMenuProps> = ({
  onCloseClick,
}) => {
  return (
    <Stack sx={{ width: "550px", height: "100%", bgcolor: "white" }}>
      {/* card header */}
      <HeaderView
        onViewEmployeeCardClick={() => null}
        onCloseClick={onCloseClick}
      />

      {/* ---- card body ---- */}
      <Stack
        sx={{
          flex: 1,
          overflow: "auto",
          scrollbarWidth: "none",
          padding: "20px",
          gap: "15px",
        }}
      >
        {/* ----------------------------------- */}

        <RequestTypeContainerView />
        <IconContainer icon={UserTypeIcon} iconSize={18} title="Request Type">
          <Stack sx={{ gap: "5px", alignItems: "end" }}>
            <Stack
              sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}
            >
              {/* this box appears when error occure */}
              <Box
                sx={{
                  height: "20px",
                  width: "5px",
                  borderRadius: "5px",
                  bgcolor: "#FF0000",
                }}
              />
              <LeaveDropDown />
            </Stack>
            <TextLinkButton
              buttonLabel="Create new type"
              sx={{ width: "fit-content" }}
            />
          </Stack>
        </IconContainer>

        {/* ----- Start Time Area ------ */}
        <IconContainer icon={TrackedIcon} title="Start Time">
          <StartTimeView />
        </IconContainer>

        {/* ----- End Time Area ------ */}
        <IconContainer icon={TrackedIcon} title="End Time">
          <EndTimeView />
        </IconContainer>

        {/* ----- Leave details table ------ */}
        <IconContainer icon={BalancesIcon} title="Balances">
          <LeaveBalanceInfoTable>
            <LeaveBalanceInfoBox label="Vacation Days" value={10} />
            <LeaveBalanceInfoBox label="PTO" value={4} showError />
            <LeaveBalanceInfoBox label="Sick days" value={6} />
            <LeaveBalanceInfoBox label="Bereavement" value={2} />
            <LeaveBalanceInfoBox label="Maternity" value={12} />
            <LeaveBalanceInfoBox label="Other" value={7} />
          </LeaveBalanceInfoTable>
        </IconContainer>

        {/* ----------------------------------- */}

        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            mt: "10px",
          }}
        >
          <AttechIcon w={15} />
          <Typography
            sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
          >
            Attachements
          </Typography>
        </Stack>
        <Stack sx={{ width: "455px" }}>
          <SearchFoldersDropdown
            showFilterMenu
            onSelect={() => null}
            options={[]}
          />
        </Stack>

        {/* ------------------- */}
        {/* -------------------------- */}

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <NotesLineIcon w={15} />
          <Typography
            sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
          >
            Notes
          </Typography>
        </Stack>

        <EditableTextArea
          minRows={4}
          maxRows={5}
          style={{
            font: "normal normal 300 13px/16px Source Serif Pro",
            border: "1px solid #E9EDF2",
            marginTop: "-10px",
          }}
        />
        {/* ------------------- */}

        {/* ----------------------------------- */}
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            gap: "10px",
          }}
        >
          <ButtonSmall
            sx={{ padding: "0px 10px" }}
            label="Save & Create Template"
            colorVarient={SmallButtonColor.Transparent}
          />
          <ButtonSmall
            label="Save"
            colorVarient={SmallButtonColor.TraxBluish}
          />
        </Stack>
        {/* ----------------------------------- */}
      </Stack>
    </Stack>
  );
};

export default TimeOffRequestMenu;
