import { AttendanceTableEmployeeListType, ScheduleTableDateListType } from "../AttendanceScheduleTable/AttendanceScheduleTableType";

export type ScheduleTableDataUserView = {
    dateList: ScheduleTableDateListType[];
    employeeList: AttendanceTableEmployeeListType
}

export interface ScheduleTableUserViewInterface {
    tableData: ScheduleTableDataUserView;

}

export const dummy_ScheduleTableListUserView: ScheduleTableDataUserView = {
    dateList: [
        { date: "2024-09-01", type: "dayOff" }, // Sunday
        { date: "2024-09-02", type: "working" },
        { date: "2024-09-03", type: "working" },
        { date: "2024-09-04", type: "holiday", holidayName: "Holiday 1" },
        { date: "2024-09-05", type: "working" },
        { date: "2024-09-06", type: "working" },
        { date: "2024-09-07", type: "dayOff" }, // Saturday
        { date: "2024-09-08", type: "dayOff" }, // Sunday
        { date: "2024-09-09", type: "working" },
        { date: "2024-09-10", type: "working" },
        { date: "2024-09-11", type: "working" },
        { date: "2024-09-12", type: "working" },
        { date: "2024-09-13", type: "working" },
        { date: "2024-09-14", type: "dayOff" }, // Saturday
        { date: "2024-09-15", type: "dayOff" }, // Sunday
        { date: "2024-09-16", type: "working" },
        { date: "2024-09-17", type: "working" },
        { date: "2024-09-18", type: "holiday", holidayName: "Holiday 2" },
        { date: "2024-09-19", type: "working" },
        { date: "2024-09-20", type: "working" },
        { date: "2024-09-21", type: "dayOff" }, // Saturday
        { date: "2024-09-22", type: "dayOff" }, // Sunday
        { date: "2024-09-23", type: "working" },
        { date: "2024-09-24", type: "working" },
        { date: "2024-09-25", type: "working" },
        { date: "2024-09-26", type: "holiday", holidayName: "Holiday 3" },
        { date: "2024-09-27", type: "working" },
        { date: "2024-09-28", type: "dayOff" }, // Saturday
        { date: "2024-09-29", type: "dayOff" }, // Sunday
        { date: "2024-09-30", type: "working" },
    ],
    employeeList: {
        _id: "1a",
        name: "Employee One",
        balance: "4+",
        jobName: "Front Desk",
        workingDayPerWeek: "5",
        scheduledHours: "240hrs",
        lateInMonth: 5,
        lateInWeek: 2,
        attendanceList: [
            { _id: '1a10', date: '2024-09-10', approvalStatus: "approved", shiftType: 'morning', day: 'tuesday', attendanceType: 'late', workStartTime: "08:10:45", workStopTime: "18:55:03", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            { _id: '1a11', date: '2024-09-11', approvalStatus: "approved", shiftType: 'morning', day: 'wednesday', attendanceType: 'late', workStartTime: "08:16:45", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            { _id: '1a12', date: '2024-09-12', approvalStatus: "approved", shiftType: 'morning', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            { _id: '1a13', date: '2024-09-13', approvalStatus: "approved", shiftType: 'morning', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            { _id: '1a14', date: '2024-09-14', approvalStatus: "approved", shiftType: 'morning', day: 'saturday', attendanceType: 'dayoff' },
            { _id: '1a15', date: '2024-09-15', approvalStatus: "approved", shiftType: 'morning', day: 'sunday', attendanceType: 'dayoff' },
            { _id: '1a16', date: '2024-09-16', approvalStatus: "approved", shiftType: 'morning', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            { _id: '1a17', date: '2024-09-17', approvalStatus: "approved", shiftType: 'morning', day: 'tuesday', attendanceType: 'dayoff' },
            { _id: '1a18', date: '2024-09-18', approvalStatus: "approved", shiftType: 'morning', day: 'wednesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            { _id: '1a19', date: '2024-09-19', approvalStatus: "approved", shiftType: 'morning', day: 'thursday', attendanceType: 'dayoff' },
            { _id: '1a20', date: '2024-09-20', approvalStatus: "approved", shiftType: 'morning', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            { _id: '1a21', date: '2024-09-21', approvalStatus: "approved", shiftType: 'morning', day: 'saturday', attendanceType: 'dayoff' },
            { _id: '1a22', date: '2024-09-22', approvalStatus: "approved", shiftType: 'morning', day: 'sunday', attendanceType: 'dayoff' },
            { _id: '1a23', date: '2024-09-23', approvalStatus: "approved", shiftType: 'morning', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            { _id: '1a24', date: '2024-09-24', approvalStatus: "approved", shiftType: 'morning', day: 'tuesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            { _id: '1a25', date: '2024-09-25', approvalStatus: "approved", shiftType: 'morning', day: 'wednesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            { _id: '1a26', date: '2024-09-26', approvalStatus: "approved", shiftType: 'morning', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            { _id: '1a27', date: '2024-09-27', approvalStatus: "approved", shiftType: 'morning', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            { _id: '1a28', date: '2024-09-28', approvalStatus: "approved", shiftType: 'morning', day: 'saturday', attendanceType: 'dayoff' },
            { _id: '1a29', date: '2024-09-29', approvalStatus: "approved", shiftType: 'morning', day: 'sunday', attendanceType: 'dayoff' },
            { _id: '1a30', date: '2024-09-30', approvalStatus: "approved", shiftType: 'morning', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
        ],
    },
};
