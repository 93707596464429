import {
  autocompleteClasses,
  AutocompleteGetTagProps,
  Paper,
  Popper,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { DropdownWithImgBadgeInterface } from "../../DropdownWithImageBadgeTypes";
import React from "react";
import { AvatarNormal } from "../../../AvatarNormal";
import { XCircleIcon } from "../../../../../images";
import { SmallContactCardHover } from "../../../SmallContactCardHover";

// Styled components
export const Root = styled("div")(
  ({ theme }) => `
    width:100%;
    box-sizing: border-box;
    color: ${
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)"
    };
    font-size: 14px;
  `
);

export const InputWrapper = styled("div")(
  () => `
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    padding: 1px;
    display: flex;
    flex-wrap: wrap;
  
    & input {
      color: #000;
      height: 30px;
      box-sizing: border-box;
      padding: 4px 6px;
      width: 0;
      min-width: 30px;
      flex-grow: 1;
      border: 0;
      margin: 0;
      outline: 0;
      font:normal normal 300 13px/16px Source Serif Pro;
    }
  `
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  userData: DropdownWithImgBadgeInterface;
  popoverPlace?: "bottom" | "right" | "left" | "top";
  clendarInviteStatus?: "pending" | "Accept" | "Reject" | "Maybe";
}

export const StyledTag = styled(Tag)<TagProps>(
  () => `
    display: flex;
    align-items: center;
    margin:2px;
    height: 30px;
    min-height: 30px;
    border-radius:100px;
    box-sizing: content-box;
    padding: 0px 8px;
    gap:5px;
    outline: 0;
    overflow: hidden;
    flexShrink: 0;
    cursor: pointer;
  
    &:focus {
      border-color:  #40a9ff;
      background-color: #e6f7ff;
    }
  
    & span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font:normal normal 300 13px/16px Source Serif Pro;
    }
  
    & svg {
      cursor: pointer;
    }
  `
);

export const Listbox = styled("ul")(
  ({ theme }) => `
    box-sizing: border-box;
    margin: 2px 0 0;
    padding: 0;
    position: absolute;
    list-style: none;
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    overflow: auto;
    max-height: 250px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;
    scrollbar-width: none;
  
    & li {
      padding: 5px 12px;
      display: flex;
      box-sizing: border-box;
    }
  
    & li[aria-selected='true'] {
      background-color:#e6f7ff;
    }
  
    & li.${autocompleteClasses.focused} {
      background-color: #ebebeb;
      cursor: pointer;
    }
  `
);

export function Tag(props: TagProps) {
  const {
    userData,
    popoverPlace = "bottom",
    clendarInviteStatus,
    onDelete,
    ...other
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const handleChipHover = (event: React.MouseEvent<HTMLElement>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleChipLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setAnchorEl(null);
    }, 0);
  };

  const getPopperPlacement = () => {
    switch (popoverPlace) {
      case "bottom":
        return "bottom-start";

      case "top":
        return "top-start";

      case "left":
        return "left-start";

      case "right":
        return "right-start";

      default:
        return "bottom-start"; // Default value if none of the cases match
    }
  };

  const getCalendarStatus = (): { borderClr: string; tooltipText: string } => {
    switch (clendarInviteStatus) {
      case "Accept":
        return { borderClr: "#008D4C", tooltipText: "Accepted" };
      case "Maybe":
        return { borderClr: "#E0D700", tooltipText: "Maybe" };
      case "pending":
        return { borderClr: "#E0D700", tooltipText: "Pending" };
      case "Reject":
        return { borderClr: "#FF0000", tooltipText: "Rejected" };
      default:
        return { borderClr: "#E9EDF2", tooltipText: "" };
    }
  };

  return (
    <Tooltip
      title={getCalendarStatus().tooltipText}
      placement="top"
      arrow
      disableInteractive
      disableHoverListener={getCalendarStatus().tooltipText === ""}
    >
      <div
        {...other}
        onMouseEnter={handleChipHover}
        onMouseLeave={handleChipLeave}
        style={{ border: `1px solid ${getCalendarStatus().borderClr}` }}
      >
        <AvatarNormal
          size={20}
          username={userData.name || userData?.email || "Some User"}
          imgSrc={userData.userIconSrc}
          avatarColor="orange"
        />
        <span style={{ flexGrow: 1 }}>{userData.name || userData?.firstName || userData?.email || "Some User"}</span>
        <span style={{ display: "flex", flexShrink: 0 }} onClick={onDelete}>
          <XCircleIcon w={15} />
        </span>

        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement={getPopperPlacement()}
          onMouseLeave={handleChipLeave}
          sx={{
            zIndex: 10001,
            border: "0px",
            borderRadius: "5px",
          }}
        >
          <Paper
            sx={{
              boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            }}
          >
            <SmallContactCardHover
              userEmail={userData.email}
              userName={userData.name}
              userIconSrc={userData.userIconSrc}
              userAvatarColor={"green"}
            />
          </Paper>
        </Popper>
      </div>
    </Tooltip>
  );
}

interface UserCountProps {
  userList: DropdownWithImgBadgeInterface[];
  startIndex: number;
  getTagProps: any;
}

export const UserCount: React.FC<UserCountProps> = ({
  userList,
  startIndex = 0,
  getTagProps,
}) => {
  const userLength = userList.length;
  const [listAnchorEl, setListAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setListAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    if (listAnchorEl && !listAnchorEl.contains(document.activeElement)) {
      setListAnchorEl(null);
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        minWidth: "25px",
        // bgcolor: "green",
        cursor: "pointer",
        width: "fit-content",
        font: "normal normal 300 13px/16px Source Serif Pro",
        color: "#2A30B5",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Typography
        sx={{
          font: "inherit",
          color: "inherit",
        }}
      >
        +{userLength}
      </Typography>

      <Popper
        open={Boolean(listAnchorEl)}
        anchorEl={listAnchorEl}
        placement="bottom-start"
        onMouseLeave={handleMouseLeave}
        sx={{
          zIndex: 10000,
          boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
          borderRadius: "5px",
        }}
      >
        <Stack
          sx={{
            bgcolor: "white",
            borderRadius: "5px",
            width: "200px",
            height: "auto",
            maxHeight: "150px",
            overflow: "auto",
            scrollbarWidth: "none",
          }}
        >
          {userList?.map((user, index) => {
            const i: number = index + startIndex;
            const { key, ...tagProps } = getTagProps({ index: i });
            return (
              <StyledTag
                popoverPlace="right"
                clendarInviteStatus={user.calendarInviteStatus}
                key={key}
                {...tagProps}
                userData={user}
              />
            );
          })}
        </Stack>
      </Popper>
    </Stack>
  );
};
