import { PayloadAction } from "@reduxjs/toolkit";
import { EmailState } from "../emailTypes";
import { getAllEmails__Failure } from "./EmailSliceFailure";

export const getAllEmails__Success = (
  state: EmailState,
  action: PayloadAction<{ data: any[] }>
) => {
  const data = action.payload.data;

  // If no data, handle failure
  if (data.length === 0) {
    getAllEmails__Failure(state, {
      payload: "No emails found",
      type: getAllEmails__Failure.name,
    });
    return;
  }
  state.is_allEmail_fetching = false;
  // Otherwise, update the state with the fetched emails
  state.emails = {
    data: data, // Store the fetched emails
    api_call_timeStamp: new Date().toISOString(), // Record the API call timestamp
    is_api_called: true, // Set the flag to true
    error: null, // Clear any previous error
  };
};

// Gets
export const getMainSettings__Success = (
  state: EmailState,
  action: PayloadAction<any>
) => {
  state.mailSettings = action?.payload?.data;
};
// Gets
export const updateMainSettings__Success = (
  state: EmailState,
  action: PayloadAction<any>
) => {
  state.mailSettings = action?.payload?.data;
  state.data = action?.payload?.data; // Optional: update `data` as well
};

export const deleteEmail__Success = (
  state: EmailState,
  action: PayloadAction<{ data: any[] }>
) => {
  // state.emails = action?.payload?.data;
  console.log("in deleteEmail__Success ");
};

export const get_filters__Success = (
  state: EmailState,
  action: PayloadAction<{ data: any[] }>
) => {
  state.email_filters = action?.payload?.data;
};
