import React, { memo } from "react";
import { Container, Stack, Tooltip, IconButton } from "@mui/material";
import { ColoredBadge, GreenArrowButton } from "../../../../../../../../common";
import { adjustHexColor } from "../../../../../../../../../Utils";
import { PlusIcon } from "../../../../../../../../../images";

interface TaskHeaderProps {
  taskData?: any;
  onClick?: any;
}

const TaskHeader: React.FC<TaskHeaderProps> = ({ taskData, onClick }) => {
  const darkenedOrSoftenColor = adjustHexColor("#fff", 35);
  return (
    <Container
      sx={{
        maxWidth: "none !important",
        marginRight: "unset",
        width: "unset",
        marginLeft: "unset",
      }}
      disableGutters
    >
      <Stack
        alignItems={"center"}
        width={"100%"}
        justifyContent={"space-between"}
        direction={false ? "column" : "row"}
        padding={'10px 0px'}
      >
        <ColoredBadge
          title={taskData?.groupName}
          bgColor={darkenedOrSoftenColor}
          iconColor={taskData?.tasks?.[0]?.status?.customColorHex}
          showIcon
          vertical__textAlign={false}
        />
        <Stack direction={"row"} gap={1}>
          <Stack onClick={() => onClick?.()}>
            <Tooltip title="button" placement="top" arrow>
              <GreenArrowButton
                sx={{
                  transform: false ? "rotate(90deg)" : "rotate(180deg)",
                  py: "2px",
                  px: "4px",
                }}
              />
            </Tooltip>
          </Stack>
          {!false && (
            <Tooltip title="button" placement="top" arrow>
              <IconButton
                disableRipple
                sx={{ height: "20px", width: "20px", padding: 0 }}
              >
                <PlusIcon w={14} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export default memo(TaskHeader);
