import React, { ReactNode } from "react";
import {
  AIFileIcon,
  ArchivedDocsFileIcon,
  AttachmentsFileIcon,
  AudioFileFileIcon, BearishDocFileIcon,
  BoxNotesFileIcon,
  CSVFileIcon,
  DecksFileIcon,
  DocBlueIcon,
  DocFileIcon,
  DocumentFileIcon,
  EPSFileIcon,
  ExcelDocumentFileIcon,
  ExeDocumentFileIcon,
  FlashDocFileIcon,
  FolderFileIcon,
  GoogleDocFileIcon,
  GoogleFormFileIcon,
  GooglePageFileIcon,
  GoogleSheetFileIcon,
  GoogleSlideFileIcon,
  GridsFileIcon,
  HTMLDocFileIcon,
  ImageFileIcon,
  KeynoteFileIcon,
  LinkFileIcon,
  Mp3OrangeIcon,
  MP4FileIcon,
  Mp4PurpleIcon,
  PDFFileIcon,
  PdfRedIcon,
  PGSFileIcon,
  PngGreenIcon,
  PPTFileIcon,
  PptRedIcon,
  PSDFileIcon,
  QuipDocFileIcon,
  QuipSheetFileIcon,
  QuipSlideFileIcon,
  SlideFileIcon,
  TXTFileIcon,
  UnknownFileIcon,
  VideoFileIcon,
  VISFileIcon,
  WebexDocumentFileIcon,
  XlsGreenIcon,
  XMLFileIcon,
  ZipFileIcon,
} from "../images";

export enum FileTypeEnum {
  Document = "Document",
  Slide = "Slide",
  QuipSlide = "QuipSlide",
  QuipDoc = "QuipDoc",
  QuipSheet = "QuipSheet",
  PSD = "PSD",
  PPT = "PPT",
  PDF = "PDF",
  PGS = "PGS",
  ArchivedDocs = "ArchivedDocs",
  MP4 = "MP4",
  Link = "Link",
  KeyNote = "KeyNote",
  Image = "Image",
  HTMLDoc = "HTMLDoc",
  GoogleSheet = "GoogleSheet",
  GoogleSlide = "GoogleSlide",
  GoogleForm = "GoogleForm",
  GooglePage = "GooglePage",
  GoogleDoc = "GoogleDoc",
  FlashDoc = "FlashDoc",
  Folder = "Folder",
  ExeDocument = "ExeDocument",
  ExcelDocument = "ExcelDocument",
  EPS = "EPS",
  CSV = "CSV",
  AudioFile = "AudioFile",
  BoxNotes = "BoxNotes",
  Zip = "Zip",
  XML = "XML",
  Attachments = "Attachments",
  AI = "AI",
  Unknown = "Unknown",
  Video = "Video",
  WebexDocument = "WebexDocument",
  DOC = "DOC",
  VISFile = "VISFile",
  TXTFile = "TXTFile",
  BearishDoc = "BearishDoc",
  Decks = "Decks",
  Grids = "Grids",
}

export type GetFileIconProps = {
  name: string;
  iconGroup?: "group1Default" | "Group1Secondry" | "group2";
  iconSize?: number;
};

// Utility function to get the file type based on extension
const getFileType = (fileExtension: string) => {
  const normalizedExtension = fileExtension?.trim().toLowerCase();

  // Use a Map for efficient lookups
  const fileTypeMap = new Map<
    string,
    {
      group1DefaultIcon: React.ElementType;
      group1SecondryIcon: React.ElementType;
      group2Icon: React.ElementType;
    }
  >();

  // Populate the map with file types
  ALL_FILES_TYPE.forEach((file) => {
    file.extensions.forEach((ext) => {
      fileTypeMap.set(ext.toLowerCase(), {
        group1DefaultIcon: file.group1DefaultIcon,
        group1SecondryIcon: file.group1SecondryIcon,
        group2Icon: file.group2Icon,
      });
    });
  });

  return fileTypeMap.get(normalizedExtension);
};

const IconRenderContainer = ({
  children,
  iconSize,
}: {
  children: React.ReactNode;
  iconSize: number;
}) => {
  return (
    <span
      style={{
        // backgroundColor: "pink",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
        height: `${iconSize}px`,
        width: `${iconSize}px`,
      }}
    >
      {children}
    </span>
  );
};

// Main function to get the file icon based on props
export const getFileIcon = (props: GetFileIconProps): ReactNode => {
  const { name, iconGroup = "group2", iconSize = 15 } = props;

  // Validate input
  if (typeof name !== "string" || !name?.trim()) {
    console.warn("Invalid file name provided.");
    return React.createElement(BearishDocFileIcon, { w: iconSize });
  }

  const lastDotIndex = name?.lastIndexOf(".");
  const fileExtension =
    lastDotIndex !== -1 ? name?.substring(lastDotIndex + 1) : "";

  const file = getFileType(fileExtension);

  if (file) {
    switch (iconGroup) {
      case "group1Default":
        return (
          <IconRenderContainer iconSize={iconSize}>
            {React.createElement(file.group1DefaultIcon, { w: iconSize })}
          </IconRenderContainer>
        );

      case "Group1Secondry":
        return (
          <IconRenderContainer iconSize={iconSize}>
            {React.createElement(file.group1SecondryIcon, { w: iconSize })}
          </IconRenderContainer>
        );

      case "group2":
        return (
          <IconRenderContainer iconSize={iconSize}>
            {React.createElement(file.group2Icon, { w: iconSize })}
          </IconRenderContainer>
        );

      default:
        console.warn("Invalid icon group specified.");
        return (
          <IconRenderContainer iconSize={iconSize}>
            {React.createElement(BearishDocFileIcon, { w: iconSize })}
          </IconRenderContainer>
        );
    }
    // if file extensition is not found or unknown extensition then default icon is here
    //  ---default icon ( if file extensition will not found in ALL_FILES_TYPE list )
  } else {
    return React.createElement(DocumentFileIcon, { w: iconSize });
  }
};

// List for all file extensions and their corresponding icons
const ALL_FILES_TYPE: {
  id: number;
  fileType: FileTypeEnum;
  extensions: string[];
  group1DefaultIcon: React.ElementType;
  group1SecondryIcon: React.ElementType;
  group2Icon: React.ElementType;
}[] = [
  {
    id: 1,
    fileType: FileTypeEnum.AI,
    extensions: ["ai"],
    group1DefaultIcon: AIFileIcon,
    group1SecondryIcon: AIFileIcon,
    group2Icon: AIFileIcon,
  },
  {
    id: 2,
    fileType: FileTypeEnum.ArchivedDocs,
    extensions: ["archive"],
    group1DefaultIcon: ArchivedDocsFileIcon,
    group1SecondryIcon: ArchivedDocsFileIcon,
    group2Icon: ArchivedDocsFileIcon,
  },
  {
    id: 3,
    fileType: FileTypeEnum.Attachments,
    extensions: ["attachment"],
    group1DefaultIcon: AttachmentsFileIcon,
    group1SecondryIcon: AttachmentsFileIcon,
    group2Icon: AttachmentsFileIcon,
  },
  {
    id: 4,
    fileType: FileTypeEnum.AudioFile,
    extensions: ["mp3", "wav"],
    group1DefaultIcon: Mp3OrangeIcon,
    group1SecondryIcon: Mp3OrangeIcon,
    group2Icon: AudioFileFileIcon,
  },
  {
    id: 5,
    fileType: FileTypeEnum.BearishDoc,
    extensions: ["bearishdoc"],
    group1DefaultIcon: BearishDocFileIcon,
    group1SecondryIcon: BearishDocFileIcon,
    group2Icon: BearishDocFileIcon,
  },
  {
    id: 6,
    fileType: FileTypeEnum.BoxNotes,
    extensions: ["boxnotes"],
    group1DefaultIcon: BoxNotesFileIcon,
    group1SecondryIcon: BoxNotesFileIcon,
    group2Icon: BoxNotesFileIcon,
  },
  {
    id: 7,
    fileType: FileTypeEnum.CSV,
    extensions: ["csv"],
    group1DefaultIcon: CSVFileIcon,
    group1SecondryIcon: CSVFileIcon,
    group2Icon: CSVFileIcon,
  },
  {
    id: 8,
    fileType: FileTypeEnum.DOC,
    extensions: ["doc"],
    group1DefaultIcon: DocFileIcon,
    group1SecondryIcon: DocFileIcon,
    group2Icon: DocFileIcon,
  },
  {
    id: 9,
    fileType: FileTypeEnum.Decks,
    extensions: ["deck"],
    group1DefaultIcon: DecksFileIcon,
    group1SecondryIcon: DecksFileIcon,
    group2Icon: DecksFileIcon,
  },
  {
    id: 10,
    fileType: FileTypeEnum.Document,
    extensions: ["docx"],
    group1DefaultIcon: DocBlueIcon,
    group1SecondryIcon: DocBlueIcon,
    group2Icon: DocumentFileIcon,
  },
  {
    id: 11,
    fileType: FileTypeEnum.EPS,
    extensions: ["eps"],
    group1DefaultIcon: EPSFileIcon,
    group1SecondryIcon: EPSFileIcon,
    group2Icon: EPSFileIcon,
  },
  {
    id: 12,
    fileType: FileTypeEnum.ExcelDocument,
    extensions: ["xlsx"],
    group1DefaultIcon: XlsGreenIcon,
    group1SecondryIcon: XlsGreenIcon,
    group2Icon: ExcelDocumentFileIcon,
  },
  {
    id: 13,
    fileType: FileTypeEnum.ExeDocument,
    extensions: ["exe"],
    group1DefaultIcon: ExeDocumentFileIcon,
    group1SecondryIcon: ExeDocumentFileIcon,
    group2Icon: ExeDocumentFileIcon,
  },
  {
    id: 14,
    fileType: FileTypeEnum.FlashDoc,
    extensions: ["flash"],
    group1DefaultIcon: FlashDocFileIcon,
    group1SecondryIcon: FlashDocFileIcon,
    group2Icon: FlashDocFileIcon,
  },
  {
    id: 15,
    fileType: FileTypeEnum.Folder,
    extensions: ["folder"],
    group1DefaultIcon: FolderFileIcon,
    group1SecondryIcon: FolderFileIcon,
    group2Icon: FolderFileIcon,
  },
  {
    id: 16,
    fileType: FileTypeEnum.GoogleDoc,
    extensions: ["gdoc"],
    group1DefaultIcon: GoogleDocFileIcon,
    group1SecondryIcon: GoogleDocFileIcon,
    group2Icon: GoogleDocFileIcon,
  },
  {
    id: 17,
    fileType: FileTypeEnum.GoogleForm,
    extensions: ["gform"],
    group1DefaultIcon: GoogleFormFileIcon,
    group1SecondryIcon: GoogleFormFileIcon,
    group2Icon: GoogleFormFileIcon,
  },
  {
    id: 18,
    fileType: FileTypeEnum.GooglePage,
    extensions: ["gpage"],
    group1DefaultIcon: GooglePageFileIcon,
    group1SecondryIcon: GooglePageFileIcon,
    group2Icon: GooglePageFileIcon,
  },
  {
    id: 19,
    fileType: FileTypeEnum.GoogleSheet,
    extensions: ["gsheet"],
    group1DefaultIcon: GoogleSheetFileIcon,
    group1SecondryIcon: GoogleSheetFileIcon,
    group2Icon: GoogleSheetFileIcon,
  },
  {
    id: 20,
    fileType: FileTypeEnum.GoogleSlide,
    extensions: ["gslide"],
    group1DefaultIcon: GoogleSlideFileIcon,
    group1SecondryIcon: GoogleSlideFileIcon,
    group2Icon: GoogleSlideFileIcon,
  },
  {
    id: 21,
    fileType: FileTypeEnum.Grids,
    extensions: ["grids"],
    group1DefaultIcon: GridsFileIcon,
    group1SecondryIcon: GridsFileIcon,
    group2Icon: GridsFileIcon,
  },
  {
    id: 22,
    fileType: FileTypeEnum.HTMLDoc,
    extensions: ["html"],
    group1DefaultIcon: HTMLDocFileIcon,
    group1SecondryIcon: HTMLDocFileIcon,
    group2Icon: HTMLDocFileIcon,
  },
  {
    id: 23,
    fileType: FileTypeEnum.Image,
    extensions: ["jpg", "png", "gif"],
    group1DefaultIcon: PngGreenIcon,
    group1SecondryIcon: PngGreenIcon,
    group2Icon: ImageFileIcon,
  },
  {
    id: 24,
    fileType: FileTypeEnum.KeyNote,
    extensions: ["keynote"],
    group1DefaultIcon: KeynoteFileIcon,
    group1SecondryIcon: KeynoteFileIcon,
    group2Icon: KeynoteFileIcon,
  },
  {
    id: 25,
    fileType: FileTypeEnum.Link,
    extensions: ["link"],
    group1DefaultIcon: LinkFileIcon,
    group1SecondryIcon: LinkFileIcon,
    group2Icon: LinkFileIcon,
  },
  {
    id: 26,
    fileType: FileTypeEnum.MP4,
    extensions: ["mp4"],
    group1DefaultIcon: Mp4PurpleIcon,
    group1SecondryIcon: Mp4PurpleIcon,
    group2Icon: MP4FileIcon,
  },
  {
    id: 27,
    fileType: FileTypeEnum.PDF,
    extensions: ["pdf"],
    group1DefaultIcon: PdfRedIcon,
    group1SecondryIcon: PdfRedIcon,
    group2Icon: PDFFileIcon,
  },
  {
    id: 28,
    fileType: FileTypeEnum.PGS,
    extensions: ["pgs"],
    group1DefaultIcon: PGSFileIcon,
    group1SecondryIcon: PGSFileIcon,
    group2Icon: PGSFileIcon,
  },
  {
    id: 29,
    fileType: FileTypeEnum.PPT,
    extensions: ["ppt"],
    group1DefaultIcon: PptRedIcon,
    group1SecondryIcon: PptRedIcon,
    group2Icon: PPTFileIcon,
  },
  {
    id: 30,
    fileType: FileTypeEnum.PSD,
    extensions: ["psd"],
    group1DefaultIcon: PSDFileIcon,
    group1SecondryIcon: PSDFileIcon,
    group2Icon: PSDFileIcon,
  },
  {
    id: 31,
    fileType: FileTypeEnum.QuipDoc,
    extensions: ["quipdoc"],
    group1DefaultIcon: QuipDocFileIcon,
    group1SecondryIcon: QuipDocFileIcon,
    group2Icon: QuipDocFileIcon,
  },
  {
    id: 32,
    fileType: FileTypeEnum.QuipSheet,
    extensions: ["quipsheet"],
    group1DefaultIcon: QuipSheetFileIcon,
    group1SecondryIcon: QuipSheetFileIcon,
    group2Icon: QuipSheetFileIcon,
  },
  {
    id: 33,
    fileType: FileTypeEnum.QuipSlide,
    extensions: ["quipslide"],
    group1DefaultIcon: QuipSlideFileIcon,
    group1SecondryIcon: QuipSlideFileIcon,
    group2Icon: QuipSlideFileIcon,
  },
  {
    id: 34,
    fileType: FileTypeEnum.Slide,
    extensions: ["slide"],
    group1DefaultIcon: SlideFileIcon,
    group1SecondryIcon: SlideFileIcon,
    group2Icon: SlideFileIcon,
  },
  {
    id: 35,
    fileType: FileTypeEnum.TXTFile,
    extensions: ["txt"],
    group1DefaultIcon: TXTFileIcon,
    group1SecondryIcon: TXTFileIcon,
    group2Icon: TXTFileIcon,
  },
  {
    id: 36,
    fileType: FileTypeEnum.Unknown,
    extensions: ["unknown"],
    group1DefaultIcon: UnknownFileIcon,
    group1SecondryIcon: UnknownFileIcon,
    group2Icon: UnknownFileIcon,
  },
  {
    id: 37,
    fileType: FileTypeEnum.VISFile,
    extensions: ["vis"],
    group1DefaultIcon: VISFileIcon,
    group1SecondryIcon: VISFileIcon,
    group2Icon: VISFileIcon,
  },
  {
    id: 38,
    fileType: FileTypeEnum.Video,
    extensions: ["mp4", "avi"],
    group1DefaultIcon: Mp4PurpleIcon,
    group1SecondryIcon: Mp4PurpleIcon,
    group2Icon: VideoFileIcon,
  },
  {
    id: 39,
    fileType: FileTypeEnum.WebexDocument,
    extensions: ["webex"],
    group1DefaultIcon: WebexDocumentFileIcon,
    group1SecondryIcon: WebexDocumentFileIcon,
    group2Icon: WebexDocumentFileIcon,
  },
  {
    id: 40,
    fileType: FileTypeEnum.XML,
    extensions: ["xml"],
    group1DefaultIcon: XMLFileIcon,
    group1SecondryIcon: XMLFileIcon,
    group2Icon: XMLFileIcon,
  },
  {
    id: 41,
    fileType: FileTypeEnum.Zip,
    extensions: ["zip"],
    group1DefaultIcon: ZipFileIcon,
    group1SecondryIcon: ZipFileIcon,
    group2Icon: ZipFileIcon,
  },
];
