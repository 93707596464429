import { Button, Stack, Typography, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import {
  DateIcon,
  EditIcon,
  GroupedPersonIcon,
  VideoCallIcon,
} from "../../../../images";
import styles from "./CreateCallButton.module.css";
import { useTranslation } from "react-i18next";
import { MainActionButton } from "../MainActionButton";
import { MainActionColor } from "../AllButtonProps";

interface CreateCallButtonProps {
  onClick: (value: string,event:any) => void; // Add this line
}

type ButtonMenuListType = {
  id: number;
  icon: React.ElementType;
  label: string;
  value: string;
};

const CreateCallButton: React.FC<CreateCallButtonProps> = ({ onClick }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickHandler = (value: string,event:any) => {
    handleClose();
    onClick(value,event);
  };

  const ButtonMenuList: ButtonMenuListType[] = [
    {
      id: 1,
      icon: VideoCallIcon,
      label: t("VIDEO_CENTER.START_CALL"),
      value: "Start",
    },
    {
      id: 2,
      icon: DateIcon,
      label: t("VIDEO_CENTER.SCHEDULE_CALL"),
      value: "Schedule",
    },
    {
      id: 3,
      icon: GroupedPersonIcon,
      label: t("VIDEO_CENTER.JOIN_CALL"),
      value: "Join",
    },
  ];

  return (
    <Stack className={styles.createCallButton__container}>
      <MainActionButton
        onClick={handleClick}
        colorVarient={MainActionColor.CallsSalmon}
        label={t("VIDEO_CENTER.CREATE")}
      />
      {/* <Button
        disableFocusRipple
        variant="contained"
        onClick={handleClick}
        className={styles.createCallButton__button}
      >
        <EditIcon w={20} color="#fff" />
        <Typography
          sx={{ font: "normal normal 300 15px/19px Source Serif Pro" }}
        >
          {t("VIDEO_CENTER.CREATE")}
        </Typography>
      </Button> */}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          className: styles.createCallButton__menu,
        }}
      >
        {ButtonMenuList.map((item) => (
          <MenuItem
            key={item.id}
            className={styles.createCallButton__menuItem}
            onClick={(event) => clickHandler(item.value,event)}
          >
            {React.createElement(item.icon, {
              className: styles.createCallButton__menuItemIcon,
            })}
            <Typography className={styles.createCallButton__menuItemText}>
              {item.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default CreateCallButton;
