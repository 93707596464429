import React from "react";
import { Stack, Tooltip } from "@mui/material";
import {
  AttendanceTableEmployeeListType,
  ScheduleTableDateListType,
  VisibleShiftInWeekDaysType,
} from "../../AttendanceScheduleTableType";
import { AttendanceListInterface } from "../../../TimeCardTable";
import {
  getTimeDifferenceInMinutes,
  isToday,
  sortShifts,
} from "../../AttendanceTableUtils";
import { LateInfoHoverCard, ShiftStatusCard } from "../../common";

/**
 * WeekViewAttendanceAreaProps - Props for the WeekViewAttendanceArea component
 */
interface WeekViewAttendanceAreaProps {
  employeeData: AttendanceTableEmployeeListType;
  visibleShift: VisibleShiftInWeekDaysType;
  rowDate: ScheduleTableDateListType;
  // attendanceDataArray: AttendanceListInterface[];
}

/**
 * Helper function to render a ShiftStatusCard based on row type and shift.
 */
const renderShiftStatusCard = ({
  rowDateType,
  shift,
  attendance,
  lateInMonth,
  lateInWeek,
  lateLoginTime,
}: {
  rowDateType: ScheduleTableDateListType["type"];
  shift: string | undefined;
  attendance?: AttendanceListInterface;
  lateInWeek?: number;
  lateInMonth?: number;
  lateLoginTime?: number;
}) => {
  switch (rowDateType) {
    case "dayOff":
      return <ShiftStatusCard cardType="dayoff" />;
    case "holiday":
      return <ShiftStatusCard cardType="holiday" />;
    case "working":
      if (attendance && attendance.shiftType === shift) {
        return (
          <Tooltip
            arrow
            placement="bottom"
            title={
              <LateInfoHoverCard
                lateInMonth={lateInMonth}
                lateInWeek={lateInWeek}
                lateLoginTime={lateLoginTime}
              />
            }
            disableHoverListener={attendance?.attendanceType !== "late"}
          >
            <span>
              <ShiftStatusCard
                cardType={attendance.attendanceType}
                startTime={
                  attendance.workStartTime || attendance.shiftStartTime
                }
                endTime={attendance.workStopTime || attendance.shiftEndTime}
              />
            </span>
          </Tooltip>
        );
      }
      return <ShiftStatusCard cardType="noShift" />;
  }
};

/**
 * WeekViewAttendanceArea - Displays the attendance data in a week view area.
 */
const WeekViewAttendanceArea: React.FC<WeekViewAttendanceAreaProps> = ({
  employeeData,
  rowDate,
  visibleShift,
}) => {
  const attendanceDataArray = employeeData.attendanceList;
  // Find attendance data for the current row date
  const rowDataAsHeader: AttendanceListInterface | undefined =
    attendanceDataArray.find((item) => item.date === rowDate.date);

  // Get sorted shifts for rendering
  const sortedShifts = sortShifts(visibleShift.selectedShift);

  return (
    <Stack
      sx={{
        position: "relative",
        height: "fit-content",
        width: "150px",
        boxSizing: "border-box",
        flexShrink: 0,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      {/* Highlight for today's date */}
      {isToday(rowDate.date) && (
        <Stack
          sx={{
            width: "100%",
            height: "2px",
            borderRadius: "2px",
            bgcolor: "#00767B",
            position: "absolute",
            top: "-5px",
          }}
        />
      )}

      {/* Render shifts */}
      {sortedShifts.map((shift, index) => (
        <span key={index}>
          {renderShiftStatusCard({
            rowDateType: rowDate.type,
            shift: shift,
            attendance: rowDataAsHeader,
            lateInMonth: employeeData.lateInMonth,
            lateInWeek: employeeData.lateInWeek,
            lateLoginTime: getTimeDifferenceInMinutes(
              rowDataAsHeader?.workStartTime,
              rowDataAsHeader?.shiftStartTime
            ),
          })}
        </span>
      ))}
    </Stack>
  );
};

export default WeekViewAttendanceArea;
