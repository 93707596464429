import { Stack, Typography, Popper } from "@mui/material";
import React, { useState } from "react";
import { EmojiPickerSmall } from "../EmojiPickerSmall";
import { SmallThreeDotMenu } from "../SmallThreeDotMenu";
import { adjustHexColor } from "../../../Utils";
import { SmallThreeDotMenuListInterface } from "../SmallThreeDotMenu/SmallThreeDotMenuTypes";
// import { QuickMessagePreviewBoxProps } from "./QuickMessagePreviewBoxType";
import {
  CopyIcon,
  EditPencilIcon,
  ForwardIcon,
  ReplyIcon,
  TranslateIcon,
} from "../../../images";
import { MessageHistory } from "../MessageHistory";

const QuickMessagePreviewBox: React.FC<any> = ({
  messageSender,
  messageText,
  messageType,
  timeStamp,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "hover-popper" : undefined;

  return (
    <Stack
      sx={{
        gap: "5px",
        boxSizing: "border-box",
        alignItems: messageType === "received" ? "start" : "end",
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "15px" }}>
        {/* render here conditionally , if message is edited */}
        {messageType === "send" && (
          <Stack
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Typography
              sx={{
                font: "normal normal 300 11px/14px Source Serif Pro",
                cursor: "pointer",
              }}
            >
              Edited
            </Typography>

            <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom">
              <MessageHistory />
            </Popper>
          </Stack>
        )}

        <Typography
          sx={{ font: "normal normal 900 11px/14px Source Serif Pro" }}
        >
          {messageType === "send" ? "You" : messageSender}, {timeStamp}
        </Typography>
      </Stack>
      <Stack
        sx={{
          flexDirection: messageType === "received" ? "row" : "row-reverse",
          gap: "8px",
          alignItems: "start",
          boxSizing: "border-box",
        }}
      >
        <Typography
          sx={{
            bgcolor:
              messageType === "received"
                ? adjustHexColor("#775EE2", 25)
                : adjustHexColor("#F5F5F5", 25),
            font: "normal normal 300 13px/16px Source Serif Pro",
            borderRadius: "5px",
            border: `1px solid ${adjustHexColor("#E9EDF2", 30)}`,
            padding: "3px 5px",
            boxSizing: "border-box",
            wordBreak: "break-word",
          }}
        >
          {messageText}
        </Typography>

        <Stack
          sx={{
            flexDirection: "row",
            gap: "8px",
            boxSizing: "border-box",
          }}
        >
          <EmojiPickerSmall
            componentIcon={"😊"}
            onSelect={(emoji) => {
              // console.log(emoji)
            }}
          />
          <SmallThreeDotMenu
            menuItems={MESSAGE_OPTIONS_LIST}
            onClickAction={(value) => {
              // console.log(value)
            }}
            displayOption={messageType === "received" ? "left" : "right"}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default QuickMessagePreviewBox;

const MESSAGE_OPTIONS_LIST: SmallThreeDotMenuListInterface[] = [
  {
    id: 1,
    icon: EditPencilIcon,
    text: "Edit",
  },
  {
    id: 2,
    icon: CopyIcon,
    text: "Copy",
  },
  {
    id: 3,
    icon: ReplyIcon,
    text: "Reply",
  },
  {
    id: 4,
    icon: ForwardIcon,
    text: "Forward",
  },
  {
    id: 5,
    icon: TranslateIcon,
    text: "Translate",
  },
];
