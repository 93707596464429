import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TableCell,
  Skeleton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  allUserTableHeader,
  allUserTableWidthProperty,
} from "./AllUserTableType";
import {
  ActionsMenuView,
  InviteStatusView,
  LastActiveView,
  UserHierarchyView,
  UserInfoView,
  UserTableBodyCell,
  UserTableHeaderCell,
} from "./view";
import { CheckboxMedium } from "../../../../../../common";
import { adjustHexColor } from "../../../../../../../Utils";

interface AllUserTableProps {
  usersList?: any[];
  isLoading: boolean;
}

const AllUserTable: React.FC<AllUserTableProps> = ({
  usersList = [],
  isLoading,
}) => {
  const [selectedRowId, setSelectedRowId] = useState<string[]>([]);

  // Log the usersList to ensure data is being passed correctly
  useEffect(() => {
    if (usersList && Array.isArray(usersList) && usersList?.length > 0) {
    }
  }, [usersList]);

  return (
    <TableContainer
      sx={{
        height: "100%",
        scrollbarWidth: "none",
        boxShadow: "none",
        borderRadius: 0,
        border: "1px solid #E9EDF2",
        minWidth: allUserTableWidthProperty.getMinWidth(),
      }}
      component={Paper}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow sx={{ height: "35px", borderBottom: "1px solid #E9EDF2" }}>
            <UserTableHeaderCell
              minWidth={allUserTableWidthProperty.userArea.minWidth}
              widthPercentage={allUserTableWidthProperty.userArea.width}
              translatedTitle="User"
            >
              <CheckboxMedium />
            </UserTableHeaderCell>

            {allUserTableHeader.map((item, index) => (
              <UserTableHeaderCell
                key={index}
                minWidth={item.minWidth}
                widthPercentage={item.widthPercentage}
                translatedTitle={item.translatedTitle}
              />
            ))}
          </TableRow>
        </TableHead>

        {isLoading ? (
          <TableBody>
            {Array.from({ length: 4 }).map((_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Skeleton variant="rectangular" width="100%" height={40} />
                </TableCell>
                {allUserTableHeader.map((header, idx) => (
                  <TableCell key={idx}>
                    <Skeleton variant="rectangular" width="100%" height={40} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            {Array.isArray(usersList) && usersList.length > 0 ? (
              usersList.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    height: "50px",
                    boxSizing: "border-box",
                    backgroundColor: selectedRowId.includes(item._id)
                      ? adjustHexColor("#0027FF", 5)
                      : "inherit",
                    ":hover": { backgroundColor: adjustHexColor("#0027FF", 5) },
                  }}
                >
                  <UserTableBodyCell>
                    <UserInfoView
                      data={item}
                      checkBoxClick={() =>
                        setSelectedRowId((prev) =>
                          prev.includes(item._id)
                            ? prev.filter((id) => id !== item._id)
                            : [...prev, item._id]
                        )
                      }
                      isSelected={selectedRowId.includes(item._id)}
                    />
                  </UserTableBodyCell>

                  <UserTableBodyCell>
                    <LastActiveView data={item} />
                  </UserTableBodyCell>

                  <UserTableBodyCell>
                    <InviteStatusView data={item} />
                  </UserTableBodyCell>

                  <UserTableBodyCell>
                    <UserHierarchyView data={item} />
                  </UserTableBodyCell>

                  <UserTableBodyCell>
                    <ActionsMenuView data={item} />
                  </UserTableBodyCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <UserTableBodyCell
                  colSpan={allUserTableHeader.length + 1}
                  align="center"
                >
                  No users found
                </UserTableBodyCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default AllUserTable;
