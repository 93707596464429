import { InputBase, Stack, TextFieldProps, Typography } from "@mui/material";
import React from "react";

type LargeInputAreaProps = TextFieldProps & {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  borderColor?: "black" | "white" | "#E9EDF2";
  height?: string;
};

const LargeInputArea: React.FC<LargeInputAreaProps> = ({
  label,
  value,
  onChange,
  borderColor = "#E9EDF2",
  height = "30px",
}) => {
  return (
    <Stack>
      {label && (
        <Typography
          sx={{
            font: "normal normal 300 10px/13px Source Serif Pro",
            marginBottom: "5px",
          }}
        >
          {label}
        </Typography>
      )}

      <InputBase
        value={value}
        onChange={(e) => onChange(e.target.value)}
        sx={{
          border: `0.5px solid ${borderColor}`,
          borderRadius: "5px",
          height: height,
          padding: "0px 5px",
          font: "normal normal 300 14px/20px Source Serif Pro",
        }}
      />
    </Stack>
  );
};

export default LargeInputArea;
