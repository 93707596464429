import React from "react";
import { Stack, Tooltip } from "@mui/material";
import {
  AttendanceTableEmployeeListType,
  dayViewAreaWidth,
  dayViewHeaderArea,
  ScheduleTableDateListType,
  VisibleShiftInWeekDaysType,
} from "../../AttendanceScheduleTableType";
import { AttendanceListInterface } from "../../../TimeCardTable";
import {
  getTimeDifferenceInMinutes,
  sortShifts,
} from "../../AttendanceTableUtils";
import { ShiftStatusCard } from "../../common";

interface DayViewAttendanceAreaProps {
  employeeData: AttendanceTableEmployeeListType;
  // visibleShift: VisibleShiftInWeekDaysType;
  rowDate: ScheduleTableDateListType;
}

/**
 * Helper function to render a ShiftStatusCard based on row type and shift.
 */
const renderShiftStatusCard = ({
  rowDateType,
  shift,
  attendance,
}: {
  rowDateType: ScheduleTableDateListType["type"];
  shift: string | undefined;
  attendance?: AttendanceListInterface;
  lateInWeek?: number;
  lateInMonth?: number;
  lateLoginTime?: number;
}) => {
  switch (rowDateType) {
    case "dayOff":
      return <ShiftStatusCard showHorizontal cardType="dayoff" />;
    case "holiday":
      return <ShiftStatusCard showHorizontal cardType="holiday" />;
    case "working":
      if (attendance && attendance.shiftType === shift) {
        return (
          <ShiftStatusCard
            showHorizontal
            cardType={attendance.attendanceType}
            startTime={attendance.workStartTime || attendance.shiftStartTime}
            endTime={attendance.workStopTime || attendance.shiftEndTime}
          />
        );
      }
      return <ShiftStatusCard cardType="noShift" showHorizontal />;
  }
};

const DayViewAttendanceArea: React.FC<DayViewAttendanceAreaProps> = ({
  employeeData,
  rowDate,
  // visibleShift,
}) => {
  const attendanceDataArray = employeeData.attendanceList;
  // Find attendance data for the current row date
  const rowDataAsHeader: AttendanceListInterface | undefined =
    attendanceDataArray.find((item) => item.date === rowDate.date);

  // Get sorted shifts for rendering
  // const sortedShifts = sortShifts(visibleShift.selectedShift);
  return (
    <Stack
      sx={{
        position: "relative",
        height: "fit-content",
        width: `${dayViewHeaderArea.length * dayViewAreaWidth.boxWidth}px`,
        boxSizing: "border-box",
        flexShrink: 0,
        marginLeft: "10px",
      }}
    >
      {/* Highlight for today's date */}
      {/* {isToday(rowDate.date) && (
        <Stack
          sx={{
            width: "100%",
            height: "2px",
            borderRadius: "2px",
            bgcolor: "#00767B",
            position: "absolute",
            top: "-5px",
          }}
        />
      )} */}

      {/* Render shifts */}
      {["morning", "midDay", "night"].map((shift, index) => (
        <span
          key={index}
          style={{
            marginLeft: `${
              index *
              ((dayViewHeaderArea.length * dayViewAreaWidth.boxWidth) / 3)
            }px`,
          }}
        >
          {renderShiftStatusCard({
            rowDateType: rowDate.type,
            shift: shift,
            attendance: rowDataAsHeader,
          })}
        </span>
      ))}
    </Stack>
  );
};

export default DayViewAttendanceArea;
