import {
  IconButton,
  Stack,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { GreenDropdownIcon, XCircleIcon } from "../../../images";
import { SendMessageButton } from "../Buttons";
import { SendMessageButtonColor } from "../Buttons/AllButtonProps";
import { ChatPreviewView } from "./View";

interface BebaAiCloudProps {handlePanelClose?:any}

const BebaAiCloud: React.FC<BebaAiCloudProps> = ({handlePanelClose}) => {
  return (
    <Stack
      sx={{
        width: "385px",
        height: "100%",
        bgcolor: "white",
        padding: "15px 15px 0px 15px",
        boxSizing: "border-box",
      }}
    >
      {/* ----------------    card title + Back button + close button --------------- */}
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "10px",
          alignItems: "start",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 400 14px/18px Source Serif Pro",
            paddingBottom: "5px",
            borderBottom: "1px solid #E9EDF2",
          }}
        >
          BEBA AI
        </Typography>

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "15px" }}>
          <IconButton
            sx={{ p: 0, gap: "5px", alignItems: "center" }}
            disableRipple
          >
            <GreenDropdownIcon
              open
              sx={{ transform: "rotate(180deg)", height: "11px" }}
            />
            <Typography
              sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}
            >
              Back
            </Typography>
          </IconButton>

          <Tooltip title="close" placement="top" arrow>
            <IconButton onClick={() => handlePanelClose?.()} sx={{ p: 0 }} disableRipple>
              <XCircleIcon w={15} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      {/* ----------------    card title + Back button + close button container end here --------------- */}
      {/*---------    scrollable container here  --------- */}
      <Stack
        sx={{
          flex: 1,
          overflowY: "scroll",
          scrollbarWidth: "none",
          gap: "20px",
          paddingBottom: "20px",
        }}
      >
        <Typography
          sx={{ font: "normal italic 400 14px/18px Source Serif Pro" }}
        >
          BEBA AI is an end to end encrypted AI system powered, trained, and
          solely controlled by Bearish OS. BEBA AI will not train on any of your
          data entered here, and it’s results are never viewed by our team. BEBA
          AI is an AI system and can get this wrong.
        </Typography>

        {/* -- chat container here --------- */}
        <Stack sx={{ flexGrow: 1, gap: "15px" }}>
          {/* ------use map function here---- */}
          <ChatPreviewView
            messageType="recived"
            messageSender="BEBA AI"
            messageText="Dummy Message from server , random text here"
            timeStamp="10-12-2022 11:09 PM"
          />
          <ChatPreviewView
            messageType="send"
            messageSender="you"
            messageText="Dummy Message from server "
            timeStamp="10-12-2022 11:09 PM"
          />
          <ChatPreviewView
            messageType="send"
            messageSender="you"
            messageText="Dummy Message from server , random text here"
            timeStamp="10-12-2022 11:09 PM"
          />
          <ChatPreviewView
            messageType="recived"
            messageSender="BEBA AI"
            messageText="Dummy Message from server , random text here"
            timeStamp="10-12-2022 11:09 PM"
          />
          <ChatPreviewView
            messageType="send"
            messageSender="BEBA AI"
            messageText="Dummy Message from server , random text here"
            timeStamp="10-12-2022 11:09 PM"
          />
          <ChatPreviewView
            messageType="recived"
            messageSender="BEBA AI"
            messageText="Dummy Message from server , example word here"
            timeStamp="10-12-2022 11:09 PM"
          />
          <ChatPreviewView
            messageType="send"
            messageSender="BEBA AI"
            messageText="Dummy Message from server , random text here"
            timeStamp="10-12-2022 11:09 PM"
          />
        </Stack>
      </Stack>
      {/*---------    scrollable container end here  --------- */}
      {/* ----------------  input area and send button here --------------------- */}

      <Stack
        sx={{
          minHeight: "45px",
          borderTop: "0.5px solid #E9EDF2",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "5px 20px",
          boxSizing: "border-box",
          gap: "15px",
          alignItems: "end",
        }}
      >
        <TextareaAutosize
          placeholder="Ask BEBA AI"
          minRows={1}
          style={{
            maxHeight: "200px",
            overflowY: "scroll",
            scrollbarWidth: "none",
            height: "30px",
            flexGrow: 1,
            resize: "none",
            border: "0px",
            padding: 0,
            outline: "none",
            font: "normal normal 300 13px/16px Source Serif Pro",
          }}
        />

        <SendMessageButton
          colorVarient={SendMessageButtonColor.MessagePurple}
        />
      </Stack>
      {/* ----------------  input area and send button here --------------------- */}
    </Stack>
  );
};

export default BebaAiCloud;
