import React from "react";
import {
  TextNormalDropdown,
  TextNormalDropdownOptionsType,
} from "../../../../common";
import {
  formatTimeTo12Hour,
  shiftTiming,
  ShiftTimingType,
} from "../AttendanceScheduleTable";

const morningShiftInfo: ShiftTimingType = shiftTiming.find(
  (item) => item.shiftType === "morning"
)!;
const midDayShiftInfo: ShiftTimingType = shiftTiming.find(
  (item) => item.shiftType === "midDay"
)!;
const nightShiftInfo: ShiftTimingType = shiftTiming.find(
  (item) => item.shiftType === "night"
)!;

const createSubTitle = (item: ShiftTimingType): string => {
  return `${formatTimeTo12Hour(item.startTime)} to ${formatTimeTo12Hour(
    item.endTime
  )}`;
};

const shiftDropDownOptions: TextNormalDropdownOptionsType[] = [
  {
    _id: "1",
    title: "Morning Shift",
    value: "Morning Shift",
    subTitle: createSubTitle(morningShiftInfo),
  },
  {
    _id: "2",
    title: "MidDay Shift",
    value: "MidDay Shift",
    subTitle: createSubTitle(midDayShiftInfo),
  },
  {
    _id: "3",
    title: "Night Shift",
    value: "Night Shift",
    subTitle: createSubTitle(nightShiftInfo),
  },
  {
    _id: "4",
    title: "Multi-Shift",
    value: "Multi-Shift",
    subTitle: "~24hr coverage",
    infoText:
      "Selecting multi-shift will create the same role and same rules across all of your shift blocks.",
  },
];

interface ShiftDropdownProps {}

const ShiftDropdown: React.FC<ShiftDropdownProps> = ({}) => {
  return (
    <TextNormalDropdown
      placeholder="Select shift..."
      //   initialValue={shiftDropDownOptions[2]}
      options={shiftDropDownOptions}
      onSelect={(value) => console.log(value)}
    />
  );
};

export default ShiftDropdown;
