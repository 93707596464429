import React, { useState } from "react";
import { TravelIcon } from "../../../../../../../images";
import { Stack, Typography, Popover } from "@mui/material";
import { EditableNumberInput, TextLinkButton } from "../../../../../../common";
import { BreaksDetailsPopup } from "../../../../common";
import { IconContainer } from "../../../CreateShiftMenu/view";

interface BreaksViewProps {}

const BreaksView: React.FC<BreaksViewProps> = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <IconContainer icon={TravelIcon} title="Breaks">
      <Stack sx={{ gap: "5px", alignItems: "end" }}>
        <Stack
          sx={{
            height: "30px",
            flexShrink: 0,
            border: "1px solid #E9EDF2",
            borderRadius: "5px",
            flexDirection: "row",
            alignItems: "center",
            boxSizing: "border-box",
            padding: "0px 5px",
            gap: "5px",
            minWidth: "80px",
          }}
        >
          <EditableNumberInput />
          <Typography
            sx={{ font: "normal normal 400 11px/14px Source Serif Pro" }}
          >
            Total
          </Typography>
        </Stack>

        <TextLinkButton
          buttonLabel="View Details"
          sx={{ width: "fit-content" }}
          onClick={handleClick}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          PaperProps={{
            sx: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
          }}
        >
          <BreaksDetailsPopup />
        </Popover>
      </Stack>
    </IconContainer>
  );
};

export default BreaksView;
