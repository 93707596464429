import React from "react";
import { UserTypeEnum } from "../../AdminConsolePageType";
import { Stack, Typography } from "@mui/material";

interface UserTypeBadgeProps {
  userType: UserTypeEnum;
}

const UserTypeBadge: React.FC<UserTypeBadgeProps> = ({ userType }) => {
  const getProperty = (
    usertype: UserTypeEnum
  ): {
    translatedTitle: string;
    badgeColor: string;
    textcolor?: string;
  } => {
    switch (usertype) {
      case UserTypeEnum.ADMIN:
        return { badgeColor: "#FF2F2F", translatedTitle: "Administrator" };
      case UserTypeEnum.MANAGER:
        return { badgeColor: "#F77F00", translatedTitle: "Manager" };
      case UserTypeEnum.TEAM_LEAD:
        return { badgeColor: "#FFC107", translatedTitle: "Team Lead" };
      case UserTypeEnum.USER:
        return { badgeColor: "#457B9D", translatedTitle: "User" };
      case UserTypeEnum.GUEST:
        return { badgeColor: "#A8A8A8", translatedTitle: "Guest" };
      case UserTypeEnum.AUDITOR:
        return { badgeColor: "#2A9D8F", translatedTitle: "Auditor" };
    }
  };

  return (
    <Stack
      sx={{
        height: "20px",
        width: "100px",
        flexShrink: 0,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px",
        bgcolor: getProperty(userType)?.badgeColor,
      }}
    >
      <Typography
        sx={{
          font: "normal normal 900 12px/15px Source Serif Pro",
          color: getProperty(userType)?.textcolor || "white",
        }}
      >
        {getProperty(userType)?.translatedTitle}
      </Typography>
    </Stack>
  );
};

export default UserTypeBadge;
