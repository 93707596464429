import { createApiAction } from "../constantApi_config";
import {
    addReactionToMessageFailureAction,
    addReactionToMessageRequestAction,
    addReactionToMessageSuccessAction,
    createNewConnectionFailureAction,
    createNewConnectionRequestAction,
    createNewConnectionSuccessAction,
    forwardMessageFailureAction,
    forwardMessageRequestAction,
    forwardMessageSuccessAction,
    getChannelMessageFailureAction,
    getChannelMessageRequestAction,
    getChannelMessageSuccessAction,
    getUserConnectionsFailureAction,
    getUserConnectionsRequestAction,
    getUserConnectionsSuccessAction,
    removeReactionToMessageFailureAction,
    removeReactionToMessageRequestAction,
    removeReactionToMessageSuccessAction,
    scheduleMessageFailureAction,
    scheduleMessageRequestAction,
    scheduleMessageSuccessAction,
    sendMessageFailureAction,
    sendMessageRequestAction,
    sendMessageSuccessAction,
    updateConnectionFailureAction,
    updateConnectionRequestAction,
    updateConnectionSuccessAction,
    updateMessageFailureAction,
    updateMessageRequestAction,
    updateMessageSuccessAction,
    markMessagesAsReadFailureAction,
    markMessagesAsReadRequestAction,
    markMessagesAsReadSuccessAction,
    getChannelActivitiesSuccessAction,
    getChannelActivitiesRequestAction,
    getChannelActivitiesFailureAction,
    getMessageActivitiesRequestAction,
    getMessageActivitiesSuccessAction,
    getMessageActivitiesFailureAction,
    changeMessagesActivitiesStatusRequestAction,
    changeMessagesActivitiesStatusSuccessAction,
    changeMessagesActivitiesStatusFailureAction,
    setSnoozeMessagesActivitiesRequestAction,
    setSnoozeMessagesActivitiesSuccessAction,
    setSnoozeMessagesActivitiesFailureAction,
    getExternalConnectionsRequestAction,
    getExternalConnectionsSuccessAction,
    getExternalConnectionsFailureAction,
    getSlackUsersRequestAction,
    getSlackUsersSuccessAction,
    getSlackUsersFailureAction,
    getSlackUserProfileRequestAction,
    getSlackUserProfileSuccessAction,
    getSlackUserProfileFailureAction,
    changeParticipantsRoleSuccessAction,
    changeParticipantsRoleFailureAction,
    changeParticipantsRoleRequestAction,
    getTeamsUserProfileRequestAction,
    getTeamsUserProfileSuccessAction,
    getTeamsUserProfileFailureAction,
    addMembersToChannelSuccessAction,
    leaveSlackConversationRequestAction,
    leaveSlackConversationSuccessAction,
    leaveSlackConversationFailureAction,
    pinMessageRequestAction,
    pinMessageSuccessAction,
    pinMessageFailureAction,
    getPinnedMessagesRequestAction,
    getPinnedMessagesSuccessAction,
    getPinnedMessagesFailureAction,
    getAttachedFilesRequestAction,
    getAttachedFilesSuccessAction,
    getAttachedFilesFailureAction, unPinMessageRequestAction, unPinMessageSuccessAction, unPinMessageFailureAction,
} from "./messageSlice";
import { ChatPayload, Message, UserId } from "./messageTypes";

/**
 * Action creator for get channel chats.
 * @param channelId - The channel id to get channel chats.
 * @returns Action object with type and ChannelId.
 */
export const getChannelMessages = (channelData: any) =>
    createApiAction({
        onStart: getChannelMessageRequestAction.type,
        onSuccess: getChannelMessageSuccessAction.type,
        onError: getChannelMessageFailureAction.type,
        method: "GET",
        url: `/message/messages?channelId=${channelData.id}&channelType=${channelData.channelType}`,
        headers: { language: "en" },
        axiosRequired: true, // Explicitly setting axiosRequired
    });

/**
 * Action creator for send user message.
 * @param payload - The Message payload containing user message.
 * @returns Action object with type and payload.
 */
export const sendMessages = (payload: any) =>
    createApiAction({
        onStart: sendMessageRequestAction.type,
        onSuccess: sendMessageSuccessAction.type,
        onError: sendMessageFailureAction.type,
        method: "POST",
        url: `/message/send`,
        data: payload,
        headers: { language: "en" },
        axiosRequired: true,
    });

/**
 * Action creator for get user connections.
 * @param  - The user id will get give us all user connections.
 * @returns Action object with type and userId.
 */
export const getUserConnections = () =>
    createApiAction({
        onStart: getUserConnectionsRequestAction.type,
        onSuccess: getUserConnectionsSuccessAction.type,
        onError: getUserConnectionsFailureAction.type,
        method: "GET",
        url: `/channel/internal`,
        headers: { language: "en" },
        axiosRequired: true,
    });

// pin message acion

export const pinMessage = (payload: any,messageId:any) =>
    createApiAction({
        onStart: pinMessageRequestAction.type,
        onSuccess: pinMessageSuccessAction.type,
        onError: pinMessageFailureAction.type,
        method: "POST",
        data: payload,
        url: `/message/pin/${messageId}`,
        headers: { language: "en" },
        axiosRequired: true,
    });

//unpin message action
export const unpinMessage = (payload: any,messageId:any) =>
    createApiAction({
        onStart: unPinMessageRequestAction.type,
        onSuccess: unPinMessageSuccessAction.type,
        onError: unPinMessageFailureAction.type,
        method: "POST",
        data: payload,
        url: `/message/unpin/${messageId}`,
        headers: { language: "en" },
        axiosRequired: true,
    });

// get pin message action
export const getPinnedMessages = (channelId: any,channelType:any) =>
    createApiAction({
        onStart: getPinnedMessagesRequestAction.type,
        onSuccess: getPinnedMessagesSuccessAction.type,
        onError: getPinnedMessagesFailureAction.type,
        method: "GET",
        url: `/message/pinned/${channelId}?channelType=${channelType}`,
        headers: { language: "en" },
        axiosRequired: true, // Explicitly setting axiosRequired
    });

/**
 * Action creator for get user connections.
 * @param  - The user id will get give us all user connections.
 * @returns Action object with type and userId.
 */
export const getExternalConnections = () =>
    createApiAction({
        onStart: getExternalConnectionsRequestAction.type,
        onSuccess: getExternalConnectionsSuccessAction.type,
        onError: getExternalConnectionsFailureAction.type,
        method: "GET",
        url: `/channel/external`,
        headers: { language: "en" },
        axiosRequired: true,
    });


/**
 * Action creator for create new connection.
 * @param payload - The chat payload containing connecction detail.
 * @returns Action object with type and payload.
 */
export const createNewConnection = (payload: ChatPayload) =>
    createApiAction({
        onStart: createNewConnectionRequestAction.type,
        onSuccess: createNewConnectionSuccessAction.type,
        onError: createNewConnectionFailureAction.type,
        method: "POST",
        url: `/channel/create`,
        data: payload,
        headers: { language: "en" },
        axiosRequired: true,
    });

/**
 * Action creator for update connections.
 * @param channelId - The channel id will update connections.
 * @returns Action object with type updated data.
 */
export const updateConnections = (channelId: UserId, payload: any) =>
    createApiAction({
        onStart: updateConnectionRequestAction.type,
        onSuccess: updateConnectionSuccessAction.type,
        onError: updateConnectionFailureAction.type,
        method: "PUT",
        data: payload,
        url: `/channel/update/${channelId}`,
        headers: { language: "en" },
        axiosRequired: true,
    });

/**
 * Action creator for update message.
 * @param messageId - The message id will update message.
 * @returns Action object with type updated data.
 */
export const updateMessage = (messageId: UserId, payload: any) =>
    createApiAction({
        onStart: updateMessageRequestAction.type,
        onSuccess: updateMessageSuccessAction.type,
        onError: updateMessageFailureAction.type,
        method: "PUT",
        data: payload,
        url: `/message/edit/${messageId}`,
        headers: { language: "en" },
        axiosRequired: true,
    });

/**
 * Action creator for schedule message.
 * @param payload - The message payload will schedule message.
 * @returns Action object with type schedule data.
 */
export const scheduleMessage = (payload: any) =>
    createApiAction({
        onStart: scheduleMessageRequestAction.type,
        onSuccess: scheduleMessageSuccessAction.type,
        onError: scheduleMessageFailureAction.type,
        method: "POST",
        data: payload,
        url: `/message/schedule`,
        headers: { language: "en" },
        axiosRequired: true,
    });

/**
 * Action creator for forward message.
 * @param payload - The message payload will forward message.
 * @returns Action object with type forward data.
 */
export const forwardMessage = (payload: any) =>
    createApiAction({
        onStart: forwardMessageRequestAction.type,
        onSuccess: forwardMessageSuccessAction.type,
        onError: forwardMessageFailureAction.type,
        method: "POST",
        data: payload,
        url: `/message/forward`,
        headers: { language: "en" },
        axiosRequired: true,
    });

/**
 * Action creator for add reaction to message.
 * @param payload - The message payload will add reaction message.
 * @returns Action object with type reaction data.
 */
export const addReactionToMessage = (payload: any) =>
    createApiAction({
        onStart: addReactionToMessageRequestAction.type,
        onSuccess: addReactionToMessageSuccessAction.type,
        onError: addReactionToMessageFailureAction.type,
        method: "POST",
        data: payload,
        url: `/message/add/reactions`,
        headers: { language: "en" },
        axiosRequired: true,
    });

/**
 * Action creator for remove reaction to message.
 * @param payload - The message payload will remove reaction message.
 * @returns Action object with type reaction data.
 */
export const removeReactionToMessage = (payload: any) =>
    createApiAction({
        onStart: removeReactionToMessageRequestAction.type,
        onSuccess: removeReactionToMessageSuccessAction.type,
        onError: removeReactionToMessageFailureAction.type,
        method: "PUT",
        data: payload,
        url: `/message/remove/reactions`,
        headers: { language: "en" },
        axiosRequired: true,
    });

/**
 * Action creator for marking all messages in a channel as read for a user.
 * @param  payload - The payload containing the user ID and channel ID.
 * @returns Action object with type and data for marking messages as read.
 */
export const markMessagesAsRead = (channelId: string) =>
    createApiAction({
        onStart: markMessagesAsReadRequestAction.type,
        onSuccess: markMessagesAsReadSuccessAction.type,
        onError: markMessagesAsReadFailureAction.type,
        method: "PUT",
        url: `/message/${channelId}/mark-as-read`,
        headers: { language: "en" },
        axiosRequired: true,
    });

/**
 * Action creator for changing user role.
 * @param  payload - The payload containing the user ID and channel ID.
 * @returns Action object with type and data for changing user role.
 */
export const changeUserRole = (payload: any) =>
    createApiAction({
        onStart: changeParticipantsRoleRequestAction.type,
        onSuccess: changeParticipantsRoleSuccessAction.type,
        onError: changeParticipantsRoleFailureAction.type,
        method: "POST",
        data: payload,
        url: `/channel/changeUserRole`,
        headers: { language: "en" },
        axiosRequired: true,
    });

export const getChannelActivities = (channelId: any, date?: any) =>
    createApiAction({
        onStart: getChannelActivitiesRequestAction.type,
        onSuccess: getChannelActivitiesSuccessAction.type,
        onError: getChannelActivitiesFailureAction.type,
        method: "GET",
        url: `/channel/activity?channelId=${channelId}${date ? `&date=${date}` : ""
            }`,
        headers: { language: "en" },
        axiosRequired: true,
    });

export const getMessagesActivities = (status: any) =>
    createApiAction({
        onStart: getMessageActivitiesRequestAction.type,
        onSuccess: getMessageActivitiesSuccessAction.type,
        onError: getMessageActivitiesFailureAction.type,
        method: "GET",
        url: `/message/activity?status=${status}`,
        headers: { language: "en" },
        axiosRequired: true,
    });

export const changeMessagesActivitiesStatus = (payload: any) =>
    createApiAction({
        onStart: changeMessagesActivitiesStatusRequestAction.type,
        onSuccess: changeMessagesActivitiesStatusSuccessAction.type,
        onError: changeMessagesActivitiesStatusFailureAction.type,
        method: "PUT",
        data: payload,
        url: `/message/activity/update`,
        headers: { language: "en" },
        axiosRequired: true,
    });

export const setSnoozeMessagesActivities = (payload: any) =>
    createApiAction({
        onStart: setSnoozeMessagesActivitiesRequestAction.type,
        onSuccess: setSnoozeMessagesActivitiesSuccessAction.type,
        onError: setSnoozeMessagesActivitiesFailureAction.type,
        method: "PUT",
        data: payload,
        url: `/message/activity/update`,
        headers: { language: "en" },
        axiosRequired: true,
    });

export const getSlackUsers = () =>
    createApiAction({
        onStart: getSlackUsersRequestAction.type,
        onSuccess: getSlackUsersSuccessAction.type,
        onError: getSlackUsersFailureAction.type,
        method: "POST",
        url: `/slack/user-list`,
        headers: { language: "en" },
        axiosRequired: true,
    });

export const connectSlackWithBearish = () =>
    createApiAction({
        onStart: "getThirdPartyTokenRequestAction.type",
        onSuccess: "getThirdPartyTokenSuccessAction.type",
        onError: "getThirdPartyTokenFailureAction.type",
        method: "GET",
        url: `/slack/auth/login`,
        headers: { language: "en" },
        axiosRequired: true,
    });


export const slackRedirect = (code: any) =>
    createApiAction({
        onStart: "getThirdPartyTokenRequestAction.type",
        onSuccess: "getThirdPartyTokenSuccessAction.type",
        onError: "getThirdPartyTokenFailureAction.type",
        method: "GET",
        url: `/slack/auth/redirect?code=${code}`,
        headers: { language: "en" },
        axiosRequired: true,
    });

export const connectTeamsWithBearish = () =>
    createApiAction({
        onStart: "getThirdPartyTokenRequestAction.type",
        onSuccess: "getThirdPartyTokenSuccessAction.type",
        onError: "getThirdPartyTokenFailureAction.type",
        method: "GET",
        url: `/microsoft/teams/login`,
        headers: { language: "en" },
        axiosRequired: true,
    });

export const teamsRedirect = (code: any) =>
    createApiAction({
        onStart: "getThirdPartyTokenRequestAction.type",
        onSuccess: "getThirdPartyTokenSuccessAction.type",
        onError: "getThirdPartyTokenFailureAction.type",
        method: "GET",
        url: `/microsoft/teams/auth/redirect?code=${code}`,
        headers: { language: "en" },
        axiosRequired: true,
    });

    

export const getThirdPartyUserProfiles = () =>
    createApiAction({
        onStart: getSlackUserProfileRequestAction.type,
        onSuccess: getSlackUserProfileSuccessAction.type,
        onError: getSlackUserProfileFailureAction.type,
        method: "GET",
        url: `/channel/user/profiles`,
        headers: { language: "en" },
        axiosRequired: true,
    });

export const getTeamsUserProfile = () =>
    createApiAction({
        onStart: getTeamsUserProfileRequestAction.type,
        onSuccess: getTeamsUserProfileSuccessAction.type,
        onError: getTeamsUserProfileFailureAction.type,
        method: "GET",
        url: `/microsoft/teams/me/profile`,
        headers: { language: "en" },
        axiosRequired: true,
    });
export const getTeamsUser = () =>
    createApiAction({
        onStart: getTeamsUserProfileRequestAction.type,
        onSuccess: getTeamsUserProfileSuccessAction.type,
        onError: getTeamsUserProfileFailureAction.type,
        method: "GET",
        url: `/microsoft/teams/user-list`,
        headers: { language: "en" },
        axiosRequired: true,
    });

export const syncSlackWithBearish = () =>
    createApiAction({
        onStart: "syncSlackRequestAction.type",
        onSuccess: "syncSlackSuccessAction.type",
        onError: "syncSlackFailureAction.type",
        method: "POST",
        url: `/message/center/slack-sync`,
        headers: { language: "en" },
        axiosRequired: true,
    });

export const addParticipantsToChannel = (payload: any,channelId:any) =>
    createApiAction({
        onStart: "addMembersToChannelRequestAction.type",
        onSuccess: addMembersToChannelSuccessAction.type,
        onError: "addMembersToChannelFailureAction.type",
        method: "PUT",
        data: payload,
        url: `/channel/${channelId}/add/members`,
        headers: { language: "en" },
        axiosRequired: true,
    });

// Delete File API
export const deleteSlectedMessageFile__api = (fileId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/cloud/delete-file/${fileId}`,
        onStart: "deleteFile__RequestAction.type",
        onSuccess: "deleteFile__SuccessAction.type",
        onError: "deleteFile__FailureAction.type",
    });

export const leaveSlackConversation = (channelId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/slack/leave-conversation?channel=${channelId}`,
        onStart: leaveSlackConversationRequestAction.type,
        onSuccess: leaveSlackConversationSuccessAction.type,
        onError: leaveSlackConversationFailureAction.type,
    });

// get attached files

export const getAttachedFilesOfChat = (channelId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/channel/attached-files/${channelId}`,
        onStart: getAttachedFilesRequestAction.type,
        onSuccess: getAttachedFilesSuccessAction.type,
        onError: getAttachedFilesFailureAction.type,
    });