import { fetchAllWorkspaceTasks__api } from "../../../../../redux";

export const getAllTask_function = async (
    dispatch: any,
    loadingSetState: React.Dispatch<React.SetStateAction<{ initialLoader: boolean; refreshers: boolean }>>,
    isInitialLoad: boolean = true
  ): Promise<any[]> => {
    const loadingKey = isInitialLoad ? "initialLoader" : "refreshers";
  
    loadingSetState((prevState) => ({
      ...prevState,
      [loadingKey]: true,
    }));
  
    try {
      const action = fetchAllWorkspaceTasks__api();
      const res: any = await dispatch(action);
  
      if (res?.success && Array.isArray(res?.data)) {
        return res.data;
      }
  
      return [];
    } catch {
      return [];
    } finally {
      loadingSetState((prevState) => ({
        ...prevState,
        [loadingKey]: false,
      }));
    }
  };
  