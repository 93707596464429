import { Stack } from "@mui/material";
import React, { Suspense, useCallback, useRef, useState } from "react";
import { adjustHexColor } from "../../../../../Utils";
import { CoverImgButton, DateSelectorArea } from "./view";
import { useDispatch } from "react-redux";
import { uploadCloudFile__api } from "../../../../../redux";
import { saveOrUpdateCoverImage__api } from "../../../../../redux/Slice/Global/globalActions";
import { Dayjs } from "dayjs";
import { SidebarCalendar } from '../../../Calendar/components';


interface DashboardSidebarCalendarProps {
  dataList?: any[];
  textColor?: "white" | "black";
}

const DashboardSidebarCalendar: React.FC<DashboardSidebarCalendarProps> = ({
  dataList,
  textColor = "black",
}) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Typing for the state
  const [filterByDate__state, setFilterByDate__state] = useState<Dayjs | null>(
    null
  );

  const handleDateChange = useCallback((date: Dayjs | null) => {
    setFilterByDate__state(date); // Updates state only when the date changes
  }, []);

  // Function to reset the filter
  const resetFilter = () => {
    setFilterByDate__state(null); // Reset the filter state to null
  };

  const handleSetCoverImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      const formData = new FormData();
      formData.append("files", selectedFile);

      const response: any = await dispatch(uploadCloudFile__api(formData));

      // file link from the response
      const fileLink = response.data?.[0].path;

      if (fileLink) {
        const payload = {
          dashboardCoverImageUrl: fileLink,
        };

        await dispatch(saveOrUpdateCoverImage__api(payload));
      }
    }
  };
  const handleSelectCoverImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  // Memoize SidebarCalendar to prevent unnecessary re-renders
  const MemoizedSidebarCalendar = React.memo(SidebarCalendar);
  return (
    <Stack sx={{ height: "100%" }}>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "end",
          paddingRight: "20px",
        }}
      >
        <CoverImgButton
          textColor={textColor}
          onClick={handleSelectCoverImage}
        />
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleSetCoverImage}
        />
      </Stack>
      <DateSelectorArea onDateChange={handleDateChange} textColor={textColor} />
      <Stack
        sx={{
          width: "265px",
          flexShrink: 0,
          height: "calc(100% - 73px)",
          overflowY: "scroll",
          scrollbarWidth: "none",
          backgroundColor: adjustHexColor("#ffffff", 65),
          borderRadius: "5px",
          padding: "12px 12px 15px 15px",
          boxSizing: "border-box",
          gap: "3px",
          borderTop: "1px solid #E9EDF2",
          borderBottom: "1px solid #E9EDF2",
          borderLeft: "1px solid #E9EDF2",
          mt: "5px",
        }}
      >
        <Suspense fallback={<div>Loading calendar...</div>}>
          <MemoizedSidebarCalendar
            filterDataByDate={filterByDate__state}
            eliminateStyle={true}
            dataList={dataList || []}
            clearFilter={() => resetFilter()}
          />
        </Suspense>
      </Stack>
    </Stack>
  );
};

export default DashboardSidebarCalendar;
