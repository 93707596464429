import { IconButtonProps } from "@mui/material";
import React from "react";
import { VideoActionRoundedButtons } from "../../../../common";
import { VideoCallIcon } from "../../../../../images";
import { useTranslation } from "react-i18next";

interface VideoButtonProps extends IconButtonProps {
  initialStatus: boolean;
  onClickChange: (status: boolean) => void;
}

const VideoButton: React.FC<VideoButtonProps> = ({
  initialStatus,
  onClickChange,
  ...props
}) => {
  const {t}=useTranslation()
  return (
    <VideoActionRoundedButtons
      activeColor="#FF0000"
      inactiveColor="#000AFF"
      activeHoverText= {t("VIDEO_CENTER.MEETING.TURN_VIDEO_OFF")}
      inActiveHoverText={t("VIDEO_CENTER.MEETING.TURN_VIDEO_ON")}
      icon={VideoCallIcon}
      initialStatus={initialStatus}
      onClickChange={onClickChange}
      {...props}
    />
  );
};
export default VideoButton;
