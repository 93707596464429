import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { AvatarNormal } from "../../../../../../common";
import {
  CheckedCheckboxIcon,
  MicIcon,
  VideoCallIcon,
  XCircleIcon,
} from "../../../../../../../images";
import styles from "./UserCardView.module.css";
import {useTranslation} from "react-i18next";

type Baseprops = {
  // adjust this and pass data in avatar and usename and email
  userName?: string;
  userEmail?: string;
  darkBg?: boolean;
};

type Joinedtype = {
  cardType: "joined";
  isMic: boolean;
  isVideo: boolean;
    avatar?: string;
};

type WaitingProps = {
  cardType: "waiting";
  onAcceptClick: () => void;
  onRejectClick: () => void;
  avatar?: string;
};

export type UserCardViewProps = Baseprops & (Joinedtype | WaitingProps);

const UserCardView: React.FC<UserCardViewProps> = (props) => {
const {t}=useTranslation()
  const getButtonsOrIcon = () => {
    switch (props.cardType) {
      case "joined":
        return (
          <Stack className={styles.userCardView__actionsJoined}>
            {props.isMic && <MicIcon w={25} color="#000000" />}
            {props.isVideo && <VideoCallIcon w={25} color="#000000" />}
          </Stack>
        );

      case "waiting":
        return (
          <Stack className={styles.userCardView__actions}>
            <Tooltip title={t('VIDEO_CENTER.MEETING.ACCEPT')} placement="top" arrow disableInteractive>
              <IconButton
                sx={{ p: 0 }}
                disableRipple
                onClick={props.onAcceptClick}
              >
                <CheckedCheckboxIcon color="#085A15" w={25} />
              </IconButton>
            </Tooltip>

            <Tooltip title={t('VIDEO_CENTER.MEETING.REJECT')} placement="top" arrow disableInteractive>
              <IconButton
                sx={{ p: 0 }}
                disableRipple
                onClick={props.onRejectClick}
              >
                <XCircleIcon color="#FF0000" w={25} />
              </IconButton>
            </Tooltip>
          </Stack>
        );
    }
  };

  return (
    <Stack
      className={styles.userCardView}
      sx={{ bgcolor: props.darkBg ? "#e9edf2" : "#fff" }}
    >
      <Stack className={styles.userCardView__details}>
        <AvatarNormal
          username={props?.userName}
          size={40}
          avatarColor="white"
          imgSrc={props?.avatar}
          sx={{
            font: "normal normal 300 13px/16px Source Serif Pro",
            border: "1px solid black",
            color: "black",
          }}
        />
        <Stack className={styles.userCardView__info}>
          <Typography className={styles.userCardView__name}>
            {props?.userName}
          </Typography>
          <Typography className={styles.userCardView__email}>
            {props?.userEmail}
          </Typography>
        </Stack>
      </Stack>
      {getButtonsOrIcon()}
    </Stack>
  );
};

export default UserCardView;
