import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react"; // Import useState
import {
  FolderFileIcon,
  GreenDropdownIcon,
  XCircleIcon,
} from "../../../images";
import { SearchFoldersDropdown } from "../SearchFoldersDropdown";
import { Dummy_SearchFoldersDropdownList } from "../SearchFoldersDropdown/SearchFoldersDropdownType";
import { FilePreviewWithCheckbox } from "../FilePreviewWithCheckbox";
import { ButtonSmall } from "../Buttons";
import { ButtonType, SmallButtonColor } from "../Buttons/AllButtonProps";
import styles from "./MoveOrCopyItem.module.css";

interface MoveOrCopyItemProps {
  folderData: any;
  onSelectFolder?: (value: string) => void;
}

const MoveOrCopyItem: React.FC<MoveOrCopyItemProps> = ({
  folderData,
  onSelectFolder,
}) => {
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null); // State to keep track of selected file

  const handleCheckBoxClick = (fileId: string) => {
    setSelectedFileId((prevId) => (prevId === fileId ? null : fileId)); // Toggle selection
  };

  const handleMove = () => {
    if (onSelectFolder && selectedFileId) {
      onSelectFolder(selectedFileId);
    }
  };

  return (
    <Stack className={styles.moveOrCopyItem}>
      {/* ---- file name and close button -----*/}
      <Stack className={styles.moveOrCopyItem__header}>
        <Typography className={styles.moveOrCopyItem__title}>
          Move or Copy “Name of item”
        </Typography>

        <Tooltip title="close" placement="top" arrow>
          <IconButton sx={{ p: 0 }} disableRipple>
            <XCircleIcon w={20} />
          </IconButton>
        </Tooltip>
      </Stack>

      <Stack className={styles.moveOrCopyItem__selectDestination}>
        <Typography className={styles.moveOrCopyItem__selectText}>
          Select a destination folder...
        </Typography>
        <SearchFoldersDropdown
          options={folderData || Dummy_SearchFoldersDropdownList}
          onSelect={(value) =>{ 
            // console.log(value)
          }}
        />
      </Stack>

      {/* --- container for Back button and folder path--- */}
      <Stack className={styles.moveOrCopyItem__backContainer}>
        <IconButton className={styles.moveOrCopyItem__backButton} disableRipple>
          <GreenDropdownIcon
            open
            sx={{ transform: "rotate(180deg)", height: "11px" }}
          />
          <Typography className={styles.moveOrCopyItem__backText}>
            Back
          </Typography>
        </IconButton>

        <Stack className={styles.moveOrCopyItem__folderPath}>
          <Stack className={styles.moveOrCopyItem__folderPathItem}>
            <FolderFileIcon w={20} />
            <Typography className={styles.moveOrCopyItem__folderText}>
              All files
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* ------  scrollable container for files  ------- */}
      <Stack className={styles.moveOrCopyItem__filesContainer}>
        {folderData && folderData?.length > 0 ? (
          folderData.map((folder: any) => (
            <FilePreviewWithCheckbox
              key={folder?._id}
              fileName={folder?.folderName}
              isChecked={selectedFileId === folder?._id} // Check if this file is selected
              onCheckBoxClick={() => handleCheckBoxClick(folder?.folderName)} // Pass the file ID to the handler
            />
          ))
        ) : (
          <Typography>No folders available</Typography>
        )}
      </Stack>

      {/* ---- buttons here --- */}
      <Stack className={styles.moveOrCopyItem__buttonsContainer}>
        <ButtonSmall
          label="Cancel"
          colorVarient={SmallButtonColor.Transparent}
          types={ButtonType.Button}
        />
        <ButtonSmall
          label="Move"
          colorVarient={SmallButtonColor.Orange}
          types={ButtonType.Button}
          disabled={!selectedFileId}
          onClick={() => handleMove()}
        />
        <ButtonSmall
          label="Copy"
          colorVarient={SmallButtonColor.Orange}
          types={ButtonType.Button}
          disabled
        />
      </Stack>
    </Stack>
  );
};

export default MoveOrCopyItem;
