import { Box, IconButton,Typography } from "@mui/material";
import React from "react";
import {MESSAGE_ENUMS} from "../../constant";
import {ChevronIcon, SlackPng, StreamIconBearishOSIconPng} from "../../../../../images";
import {MessageAvatarChip} from "../MessageAvatarChip";


interface MemberCardProps {
    userName:string;
    userImageSrc:string;
    userPlatform:string;
}

const MemberCard: React.FC<MemberCardProps> = ({userName, userImageSrc, userPlatform}) => (
    <Box display="flex" alignItems="center" gap={1} ml={0.5}>
        {userName && (
            <MessageAvatarChip
                userMsgStatus="active"
                imgSrc={userImageSrc || ""}
                username={userName}
            />
        )}
        <Box
            component="img"
            sx={{ height: "17px", width: "17px" }}
            src={userPlatform === MESSAGE_ENUMS.SLACK ? SlackPng : StreamIconBearishOSIconPng}
            alt="logo"
        />
        <Typography sx={{ font: "normal normal 400 16.29px/13px Source Serif Pro" }}>
            {userName}
        </Typography>
        <IconButton style={{display:"flex",justifyContent:"end"}} onClick={() => {}}>
            <ChevronIcon w={15} direction="right" />
        </IconButton>
    </Box>
);

export default MemberCard