import {Box, IconButton, Stack, Typography} from "@mui/material";
import React from "react";
import {MESSAGE_ENUMS} from "../../constant";
import {ChevronIcon, SlackPng, StreamIconBearishOSIconPng} from "../../../../../images";
import {MessageAvatarChip} from "../MessageAvatarChip";


interface MemberCardProps {
    userName:string;
    userImageSrc:string;
    userPlatform:string;
}

const MemberCard: React.FC<MemberCardProps> = ({userName, userImageSrc, userPlatform}) => (
    <Stack
        sx={{
            height: "35px",
            flexShrink: 0,
            borderRadius: "5px",
            boxSizing: "border-box",
            padding: "0px 5px 0px 10px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
        }}
    >
        <Stack
            sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
                maxWidth: "calc(100% - 35px)",
            }}
        >
            {userName && (
                <MessageAvatarChip
                    userMsgStatus="active"
                    imgSrc={userImageSrc || ""}
                    username={userName}
                />
            )}
            <Box
                component="img"
                sx={{height: "17px", width: "17px"}}
                src={userPlatform === MESSAGE_ENUMS.SLACK ? SlackPng : StreamIconBearishOSIconPng}
                alt="logo"
            />
            <Typography
                sx={{
                    font: "normal normal 300 12px/15px Source Serif Pro",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                }}
            >
                {userName}
            </Typography>
        </Stack>

        <IconButton onClick={() => {
        }}>
            <ChevronIcon w={15} direction="right" />
        </IconButton>
    </Stack>
);

export default MemberCard