import { Stack, Typography } from "@mui/material";
import React from "react";
import {
  ButtonSmall,
  ButtonType,
  ColorPickerDot,
  SmallGroupedAvatarDataTpyes,
  SmallGroupedAvatars,
} from "../../../../common";
import styles from "./UpcomingEventsCard.module.css"; // Import the CSS module

interface UpcomingEventsCardProps {
  startTime: string;
  endTime: string;
  eventTitle: string;
  eventDetails: string;
  teamList: SmallGroupedAvatarDataTpyes[];
}

const UpcomingEventsCard: React.FC<UpcomingEventsCardProps> = ({
  startTime,
  endTime,
  eventTitle,
  eventDetails,
  teamList,
}) => {
  return (
    <Stack className={styles["upcoming-events-card"]}>
      <Stack className={styles["upcoming-events-card__info"]}>
        <Stack className={styles["upcoming-events-card__time"]}>
          <ColorPickerDot />
          <Typography
            sx={{ font: "normal normal 300 13px/17px Source Serif Pro" }}
          >
            {startTime} to {endTime}
          </Typography>
        </Stack>
        <Typography className={styles["upcoming-events-card__title"]}>
          {eventTitle}
        </Typography>
        <Typography className={styles["upcoming-events-card__details"]}>
          {eventDetails}
        </Typography>
      </Stack>
      <Stack className={styles["upcoming-events-card__actions"]}>
        {/* DUMMY_USER data is static here in last of this page  */}
        <SmallGroupedAvatars maxAvatars={4} usersData={teamList} />
        <ButtonSmall label="Start event" types={ButtonType.Button} />
      </Stack>
    </Stack>
  );
};

export default UpcomingEventsCard;
