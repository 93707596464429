import React from "react";
import { Popover, Stack, Box } from "@mui/material";
import { ColoredBadge } from "../../../../common";

interface Category {
  id: string;
  name: string;
  color: string;
}

type CategoriesTableProps = {
  words: (Category | string)[]; // Allow either app categories or Outlook categories (strings)
  p?: any;
};

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const normalizeCategory = (
  category: Category | string,
  index: number
): Category => {
  if (typeof category === "string") {
    return {
      id: `outlook-${index}`,
      name: category,
      color: getRandomColor(),
    };
  }
  return {
    ...category,
    color: category?.color || getRandomColor(),
    name: String(category?.name),
  };
};

const CategoriesTable: React.FC<CategoriesTableProps> = ({ words, p }) => {
  const renderedCategories =
    p &&
    p?.data?.categories?.map((category: any, index: number) =>
      normalizeCategory(category, index)
    );
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const maxVisible = 2;
  const visibleCategories = renderedCategories?.slice(0, maxVisible);
  const hiddenCategories = renderedCategories?.slice(maxVisible);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        flexWrap="wrap"
        gap={1}
        justifyContent="center"
      >
        {/* Render the first `maxVisible` badges */}
        {visibleCategories?.map((category:any, index:any) => (
          <ColoredBadge
            key={index}
            title={category?.name || "N/A"}
            sx={{ height: "20px", p: "0px 10px" }}
            bgColor={category?.color || "#000000"}
            bgColorOpacity={50}
          />
        ))}

        {/* Show "View More" if there are hidden categories */}
        {hiddenCategories?.length > 0 && (
          <Box
            onClick={handlePopoverOpen}
            sx={{
              cursor: "pointer",
              height: "20px",
              padding: "0px 10px",
              backgroundColor: "#e0e0e0",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            +{hiddenCategories.length} more
          </Box>
        )}
      </Stack>

      {/* Popover for hidden categories */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            padding: "10px",
            maxWidth: "200px",
            boxShadow: "0.5px solid #E9EDF2",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {hiddenCategories?.map((category:any, index:any) => (
            <ColoredBadge
              key={index}
              title={category?.name || "N/A"}
              sx={{ height: "20px", p: "0px 10px" }}
              bgColor={category?.color || "#000000"}
              bgColorOpacity={50}
            />
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default CategoriesTable;
