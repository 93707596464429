import React from "react";
import { ScheduleTableDateListType } from "../../AttendanceScheduleTableType";
import { Stack } from "@mui/material";

interface WeekCountViewProps {
  dateList: ScheduleTableDateListType[];
}

const WeekCountView: React.FC<WeekCountViewProps> = ({ dateList }) => {
  const weekCount: number = Math.ceil(dateList.length / 7);

  const getWeekCount = (weekIndex: number) => {
    switch (weekIndex) {
      case 1:
        return "Week One";
      case 2:
        return "Week Two";
      case 3:
        return "Week Three";
      case 4:
        return "Week Four";
      case 5:
        return "Week Five";
      case 6:
        return "Week Six";
      case 7:
        return "Week Seven";
      case 8:
        return "Week Eight";
      default:
        return "Week";
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: "2px",
      }}
    >
      {[...Array(weekCount)].map((_, index) => (
        <Stack
          key={index}
          sx={{
            width: "222px",
            boxSizing: "border-box",
            borderLeft: "1px solid #E9EDF2",
            borderRight: "1px solid #E9EDF2",
            height: "40px",
            alignItems: "center",
            justifyContent: "center",
            font: "normal normal 300 16px/20px Source Serif Pro",
          }}
        >
          {getWeekCount(index + 1)}
        </Stack>
      ))}
    </Stack>
  );
};

export default WeekCountView;
