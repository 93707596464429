import {PayloadAction} from "@reduxjs/toolkit";
import {
    ChangeUserIdPayload,
    ConnectionPayload,
    CreateNewConnectionPayload,
    Message,
    MessagePayload,
    MessagesState,
} from "../messageTypes";
import {sortChats} from "../../../../Utils/sortChats";
import {sortChannelBasedOnLastMessage} from "../../../../Utils/updateChannelInfo";

export const getChannelMessageSuccess = (
    state: MessagesState,
    action: PayloadAction<MessagePayload>
) => {
    state.error = null;
    if (state.chatTabState) {
        state.chatTabState.conversationChats =
            action?.payload?.data?.reduce(
                (acc: Record<string, Message>, message: Message) => {
                    acc[message._id] = message;
                    return acc;
                },
                {} as Record<string, Message>
            ) ?? {};
    }
    state.loading = false;
};

export const sendMessageSuccess = (
    state: MessagesState,
    action: PayloadAction<MessagePayload>
) => {
    state.error = null;

    const {data} = action.payload;

    if (state.chatTabState?.conversationChats) {
        state.chatTabState.conversationChats[data._id] = data;
    }

    sortChannelBasedOnLastMessage(state, data);
};

export const getUserConnectionsSuccess = (
    state: MessagesState,
    action: PayloadAction<ConnectionPayload>
) => {
    state.error = null;
    if (Array.isArray(action.payload.data)) {

        action.payload.data.forEach((connection: any) => {
            if (connection.isPinned) {
                state.pinnedChannels[connection._id] = connection;
            } else {
                if (connection.type === 'ONE_TO_ONE') {
                    state.directChannel[connection._id] = connection;
                }

                if (connection.type === 'CHANNEL') {
                    state.channelChats[connection._id] = connection;
                }
            }
        });

        state.pinnedChannels = sortChats(state.pinnedChannels);
        state.directChannel = sortChats(state.directChannel);
        state.channelChats = sortChats(state.channelChats);

        const selectedChat: any = action.payload.data.find(
            (channel: any) => channel._id === state.chatTabState.selectedConversation.conversationId
        );

        if (selectedChat) {
            state.chatTabState.selectedConversation.conversationDetails = selectedChat;
            state.chatTabState.selectedConversation.conversationPlatform = selectedChat?.channelType;
            state.chatTabState.selectedConversation.conversationName = selectedChat?.displayName;
            state.chatTabState.selectedConversation.conversationType = selectedChat?.type;
            state.chatTabState.selectedConversation.isUserGuest = selectedChat?.currentUserRole === 'GUEST' ? true : false;
            state.chatTabState.selectedConversation.isUserLeftTheConversation = selectedChat?.currentUserLeave;
        }

    } else {
        state.channelChats = {};
        state.pinnedChannels = {};
        state.directChannel = {};
    }
    state.loading = false;
};


export const getExternalConnectionsSuccess = (
    state: MessagesState,
    action: PayloadAction<ConnectionPayload>
) => {
    state.error = null;
};

export const createNewConnectionSuccess = (
    state: MessagesState,
    action: PayloadAction<CreateNewConnectionPayload>
) => {
    state.error = null;
    state.chatTabState.selectedConversation.conversationId = action?.payload?.data?._id;
    state.chatTabState.selectedConversation.conversationDetails = action?.payload?.data;
    state.chatTabState.selectedConversation.conversationPlatform = action?.payload?.data?.channelType;
    state.chatTabState.selectedConversation.conversationName = action?.payload?.data?.displayName;
    state.chatTabState.selectedConversation.conversationType = action?.payload?.data?.type;
    if (action?.payload?.data?.type === 'CHANNEL') {
        state.channelChats[action.payload.data._id] = action.payload.data;
    }
    if (action?.payload?.data?.type === 'ONE_TO_ONE') {
        state.directChannel[action.payload.data._id] = action.payload.data;
    }
};

export const updateConnectionSuccess = (
    state: MessagesState,
    action: PayloadAction<CreateNewConnectionPayload>
) => {
    state.error = null;
    const channelId = action?.payload?.data?._id;
    const isPinned = action?.payload?.data?.isPinned;

    if (channelId) {
        if (state.directChannel[channelId]) {
            state.directChannel[channelId].isPinned = isPinned;
        }
        if (state.channelChats[channelId]) {
            state.channelChats[channelId].isPinned = isPinned;
        }
        if (state.pinnedChannels[channelId]) {
            state.pinnedChannels[channelId].isPinned = isPinned;
        }
    }
};

export const updateMessageSuccess = (
    state: MessagesState,
    action: PayloadAction<CreateNewConnectionPayload>
) => {
    state.error = null;
    const messageId = action.payload?.data?._id
    state.chatTabState.conversationChats[messageId].content = action.payload.data.content;
    state.chatTabState.conversationChats[messageId].isEdited = action.payload.data.isEdited;
    state.chatTabState.conversationChats[messageId].editHistory = action.payload.data.editHistory;
};

export const scheduleMessageSuccess = (
    state: MessagesState,
    action: PayloadAction<CreateNewConnectionPayload>
) => {
    state.error = null;
    state.loading = false;
};

export const forwardMessageSuccess = (
    state: MessagesState,
    action: PayloadAction<CreateNewConnectionPayload>
) => {
    state.error = null;
    state.loading = false;
};

export const addReactionToMessageSuccess = (
    state: MessagesState,
    action: PayloadAction<CreateNewConnectionPayload>
) => {
    state.error = null;
    const message = action.payload.data;
    if (message?._id) {
        state.chatTabState.conversationChats[message._id] = message;
    }
    state.loading = false
};

export const removeReactionToMessageSuccess = (
    state: MessagesState,
    action: PayloadAction<CreateNewConnectionPayload>
) => {
    state.error = null;
    const message = action.payload.data;
    if (message?._id) {
        state.chatTabState.conversationChats[message._id] = message;
    }
    state.loading = false
};
export const markMessagesAsReadSuccess = (
    state: MessagesState,
    action: PayloadAction<CreateNewConnectionPayload>
) => {
    state.error = null;
    const {channelId} = action.payload.data;

    if (state.channelChats[channelId]) {
        state.channelChats[channelId].unreadMessageCount = 0;
    }
    if (state.directChannel[channelId]) {
        state.directChannel[channelId].unreadMessageCount = 0;
    }
    if (state.pinnedChannels[channelId]) {
        state.pinnedChannels[channelId].unreadMessageCount = 0;
    }
};

export const changeParticipantsRoleSuccess = (
    state: MessagesState,
    action: PayloadAction<ChangeUserIdPayload>
) => {
    state.error = null;
    const {userId, role} = action.payload?.data?.updatedParticipant;
    state.chatTabState.selectedConversation.conversationDetails.participants = state.chatTabState.selectedConversation.conversationDetails.participants.map((participant: any) => {
        if (participant.userId === userId) {
            return {
                ...participant,
                role: role,
            };
        }
        return participant;
    });

    state.loading = false;
};

export const getChannelActivitiesSuccess = (
    state: MessagesState,
    action: PayloadAction<ChangeUserIdPayload>
) => {
    state.error = null;
    state.channelActivity = action.payload.data
    // state.selectedConversation.conversationActivities = action.payload.data;
};

export const getMessageActivitiesSuccess = (
    state: MessagesState,
    action: PayloadAction<ChangeUserIdPayload>
) => {
    state.error = null;
    if (Array.isArray(action.payload.data) && action.payload.data.length > 0) {
        state.messageActivity = action.payload.data.reduce(
            (acc: Record<string, any>, activity: any) => {
                acc[activity._id] = activity;
                return acc;
            },
            {} as Record<string, any>
        );
    } else {
        state.messageActivity = {};
    }
    state.loading = false;
};
export const changeMessagesActivitiesStatusSuccess = (
    state: MessagesState,
    action: PayloadAction<ChangeUserIdPayload>
) => {
    state.error = null;
    state.loading = false;
};
export const setSnoozeMessagesActivitiesSuccess = (
    state: MessagesState,
    action: PayloadAction<ChangeUserIdPayload>
) => {
    state.error = null;
    state.loading = false
};
export const getSlackUsersSuccess = (
    state: MessagesState,
    action: PayloadAction<ChangeUserIdPayload>
) => {
    state.error = null;
    state.slackUsers = action.payload.data;
    state.loading = false
};
export const getSlackUserProfileSuccess = (
    state: MessagesState,
    action: PayloadAction<ChangeUserIdPayload>
) => {
    state.error = null;
    state.slackUserProfile = action.payload.data.slackProfile;
    state.teamsUserProfile = action.payload.data.teamsProfile;
    state.loading = false
};
export const getTeamsUserProfileSuccess = (
    state: MessagesState,
    action: PayloadAction<ChangeUserIdPayload>
) => {
    state.error = null;
    state.teamsUserProfile = action.payload.data;
    state.loading = false
};

/* get messages from channel */
export const getMessagesFromChannelSuccess = (
    state: MessagesState,
    action: PayloadAction<any>
) => {
    state.error = null;
};

/* send message to channel */
export const sendMessageToChannelSuccess = (
    state: MessagesState,
    action: PayloadAction<any>
) => {
    state.error = null;
};

/* send chat message */
export const sendChatMessageSuccess = (
    state: MessagesState,
    action: PayloadAction<any>
) => {
    state.error = null;
};

/* get mentions in messages */
export const getMentionsInMessagesSuccess = (
    state: MessagesState,
    action: PayloadAction<any>
) => {
    state.error = null;
};

/* create channel */
export const createChannelSuccess = (
    state: MessagesState,
    action: PayloadAction<any>
) => {
    state.error = null;
};

/* get user presence */
export const getUserPresenceSuccess = (
    state: MessagesState,
    action: PayloadAction<any>
) => {
    state.error = null;
};

/* create online meeting */
export const createOnlineMeetingSuccess = (
    state: MessagesState,
    action: PayloadAction<any>
) => {
    state.error = null;
};

/* get files in channel */
export const getFilesInChannelSuccess = (
    state: MessagesState,
    action: PayloadAction<any>
) => {
    state.error = null;
};

/* access files in personal chats */
export const accessFilesInPersonalChatsSuccess = (
    state: MessagesState,
    action: PayloadAction<any>
) => {
    state.error = null;
};

/* upload files to personal chats */
export const uploadFilesToPersonalChatsSuccess = (
    state: MessagesState,
    action: PayloadAction<any>
) => {
    state.error = null;
};

/* get user channels from team */
export const getUserChannelsFromTeamSuccess = (
    state: MessagesState,
    action: PayloadAction<any>
) => {
    state.error = null;
};

/* get user team */
export const getUserTeamSuccess = (
    state: MessagesState,
    action: PayloadAction<any>
) => {
    state.error = null;
};

export const addMembersToChannelSuccess = (
    state: MessagesState,
    action: PayloadAction<any>
) => {
    state.error = null;
    state.chatTabState.selectedConversation.conversationDetails = action.payload.data;
}

export const leaveSlackConversationSuccess = (
    state: MessagesState,
    action: PayloadAction<any>
) => {
    state.error = null;
}

export const pinMessageSuccess = (
    state: MessagesState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.loading = false
};

export const getPinnedMessagesSuccess = (
    state: MessagesState,
    action: PayloadAction<any>
) => {
    state.error = action.payload;
    state.loading = false

    if (Array.isArray(action.payload.data) && action.payload.data.length > 0) {
        state.rightSidebarsStates.chatDetailsSidebar.pinnedChats = action.payload.data.reduce(
            (acc: Record<string, any>, message: any) => {
                acc[message._id] = message;
                return acc;
            },
            {} as Record<string, any>
        );
    } else {
        state.rightSidebarsStates.chatDetailsSidebar.pinnedChats = {};
    }
};

export const getAttachedFilesSuccess = (
    state: MessagesState,
    action: PayloadAction<{ data: any[] }>
) => {
    state.error = null;
    state.loading = false;

    if (Array.isArray(action.payload.data)) {
        const filesAndImage = action.payload.data.filter(
            (file) => file.fileType === 'file' || file.fileType === 'image' || file.fileType === 'img'
        );
        const audioAndVideo = action.payload.data.filter(
            (file) => file.fileType === 'audio' || file.fileType === 'video'
        );

        state.rightSidebarsStates.chatDetailsSidebar.attachedFiles = {
            folders: [], // Assuming folders are not part of the payload
            filesAndImage,
            audioAndVideo,
        };
    } else {
        state.rightSidebarsStates.chatDetailsSidebar.attachedFiles = {
            folders: [],
            filesAndImage: [],
            audioAndVideo: [],
        };
    }
};

export const unPinMessageSuccess = (
    state: MessagesState,
    action: PayloadAction<any>
) => {
    state.error = null;
    state.loading = false;

    const messageId = action.payload.data._id;
    delete state.rightSidebarsStates.chatDetailsSidebar.pinnedChats[messageId];
};