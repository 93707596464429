import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { DrawerButton } from "../DrawerButton";

interface NavigationRouteProps {
  label: string;
  path: string;
  labelFont?: string;
  icon: React.ElementType; // Change this to React.ElementType
}

const NavigationRoute: React.FC<NavigationRouteProps> = ({
  label,
  path,
  labelFont,
  icon: Icon, // Icon is now a component type
}) => {
  const location = useLocation(); // Get the current location (path)

  // Normalize paths by removing trailing slashes for both current path and provided path
  const normalizedLocation = location.pathname.replace(/\/$/, ""); // Remove trailing slash from current path
  const normalizedPath = path.replace(/\/$/, ""); // Remove trailing slash from the provided path

  // Check if the current path is `/CRM` or starts with `/CRM/` (this includes subpaths like `/CRM/people`)
  const isSelected =
    normalizedLocation === normalizedPath || normalizedLocation.startsWith(`${normalizedPath}/`) || (normalizedLocation === "/CRM" && path === "/CRM/people");

  return (
    <Link
      component={RouterLink}
      to={path}
      underline="none"
      color="inherit"
      style={{ display: "block", width: "100%" }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <DrawerButton
          icon={Icon} // Pass the component (not the rendered element) to DrawerButton
          buttonLabel={label}
          labelFont={labelFont}
          isSelected={isSelected} // Set isSelected based on the current path
        />
      </Stack>
    </Link>
  );
};

export default NavigationRoute;
