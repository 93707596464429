import React from "react";
import { Button, ButtonProps, Typography } from "@mui/material";
import { adjustHexColor } from "../../../Utils";

interface DrawerButtonProps extends ButtonProps {
    icon?: React.ElementType | string | null;
    buttonLabel: string; // Text label
    isSelected?: boolean; // Selected state
    highlightColor?: string; // Optional custom highlight color
    labelFont?: any; // Optional custom font
}

const DrawerButton: React.FC<DrawerButtonProps> = ({
    icon,
    buttonLabel,
    isSelected = false,
    highlightColor,
    labelFont = "",
    ...props
}) => {
    // Default base color
    const baseColor = highlightColor || adjustHexColor("#00767B", 25);

    return (
        <Button
            variant="text"
            sx={{
                textTransform: "none",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                gap: "5px",
                height: "32px",
                padding: "0px 5px",
                boxSizing: "border-box",
                bgcolor: isSelected ? baseColor : "inherit",
                ":hover": {
                    bgcolor: baseColor, // Highlight color on hover
                },
                transition: "background-color 0.3s ease", // Smooth background transition
                font: labelFont || "normal normal 500 14px/19px Source Serif Pro"
                ,
                width: "100%",
            }}
            {...props}
        >
            {icon &&
                (typeof icon === "string" ? (
                    <img src={icon} alt="icon" style={{ width: 20, height: 20 }} />
                ) : (
                    React.createElement(icon, { style: { width: 20, color: "black" } })
                ))}

            <Typography
                sx={{
                    font: "normal normal 300 13px/16px Source Serif Pro",
                    color: "black",
                    transition: "color 0.3s ease", // Smooth text color transition
                    ":hover": {
                        color: "black", // Change text color on hover (optional)
                    },
                }}
            >
                {buttonLabel}
            </Typography>
        </Button>
    );
};

export default DrawerButton;
