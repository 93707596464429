import { Skeleton, Stack } from "@mui/material";
import React from "react";

const MsgPreviewSkeleton: React.FC<{ isPinned?: boolean }> = ({ isPinned }) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "start",
        gap: "10px",
        boxSizing: "border-box",
        padding: "10px 15px 15px 10px",
        flex: 1,
        border: isPinned ? "1px solid #E9EDF2" : "0px",
      }}
    >
      <Skeleton variant="circular" width={30} height={30} />
      <Stack sx={{ gap: "10px", flex: 1 }}>
        <Skeleton variant="text" sx={{ width: "230px" }} />
        <Stack sx={{ flex: 1, gap: "5px" }}>
          <Skeleton variant="rounded" sx={{ width: "100%" }} />
          <Skeleton variant="rounded" sx={{ width: "100%" }} />
          <Skeleton variant="rounded" sx={{ width: "100%" }} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MsgPreviewSkeleton;
