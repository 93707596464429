import React, { useState } from "react";
import { Stack, Menu, MenuItem } from "@mui/material";
import { ButtonSmall, SmallButtonColor } from "../../../../../../common";
import {
  AddUserAdminPortalOptionList,
  AddUserAdminPortalOptionType,
} from "./AddUserAdminPortalType";

interface AddUserAdminPortalProps {
  menuWidth?: string;
  option?: AddUserAdminPortalOptionType[];
  onSelect: (item: AddUserAdminPortalOptionType) => void;
}

const AddUserAdminPortal: React.FC<AddUserAdminPortalProps> = ({
  menuWidth = "125px",
  onSelect,
  option = AddUserAdminPortalOptionList,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (item: AddUserAdminPortalOptionType) => {
    onSelect(item);
    handleClose();
  };

  return (
    <Stack>
      <ButtonSmall
        label="Add new user"
        colorVarient={SmallButtonColor.BearishClay}
        onClick={handleClick}
        sx={{
          minWidth: "125px",
          width: "fit-content",
          font: "normal normal 900 15px/19px Source Serif Pro",
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            minWidth: menuWidth,
            border: "1px solid #E9EDF2",
            borderRadius: "5px",
            mt: "5px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        {option.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => handleSelect(item)}
            sx={{
              minHeight: 0,
              height: "25px",
              padding: "0px 10px",
              flexShrink: 0,
              font: "normal normal 400 13px/16px Source Serif Pro",
            }}
          >
            {item.translatedTitle}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default AddUserAdminPortal;
