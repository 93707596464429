import React from "react";
import {
  AllUserTableDataType,
  allUserTableWidthProperty,
} from "../../AllUserTableType";
import { Stack, Typography } from "@mui/material";
import { AvatarNormal, CheckboxMedium } from "../../../../../../../../common";

interface UserInfoViewProps {
  data: AllUserTableDataType;
  checkBoxClick?: () => void;
  isSelected?: boolean;
}

const UserInfoView: React.FC<UserInfoViewProps> = ({
  data,
  checkBoxClick,
  isSelected,
}) => {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
      <CheckboxMedium onClick={checkBoxClick} checked={isSelected} />
      <AvatarNormal
        size={30}
        avatarColor={data.userAvatarColor}
        imgSrc={data.userIconSrc}
        username={data.userName}
        sx={{ borderRadius: "5px" }}
      />
      <Stack
        sx={{
          gap: "3px",
          maxWidth: `calc(${allUserTableWidthProperty.userArea.minWidth} - 30px)`,
        }}
      >
        <Typography
          sx={{
            font: "normal normal 400 14px/18px Source Serif Pro",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {data.userName}
        </Typography>
        <Typography
          sx={{
            font: "normal normal 200 12px/15px Source Serif Pro",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {data.userEmail}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default UserInfoView;
