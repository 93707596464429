import React from "react";
import { Stack, Typography,  Avatar } from "@mui/material";
import { SelectIcon } from "../../../images";
import { adjustHexColor } from "../../../Utils";

// Define types for the component props
type BaseProps = {
  title: string;
  bgColor: string;
  bgColorOpacity?: number;
  sx?: any; // For MUI styling
  fontColor?: string;
  ColoredBadgeFont?: string;
  ColoredBadgePadding?: string;
  imageUrl?: string; // Optional prop for image URL
  imageSize?: number; // Optional prop for image size
  vertical__textAlign?: boolean;
};

type IconProps = {
  showIcon: boolean; // Changed to boolean
  icon?: React.ReactNode; // Allow custom icons
  iconColor?: string;
};

type NoIconProps = {
  showIcon?: boolean; // Changed to boolean
  icon?: never;
  iconColor?: never;
};

// Combined type for ColoredBadge props
type ColoredBadgeProps = BaseProps & (IconProps | NoIconProps);

// Define the ColoredBadge component
const ColoredBadge: React.FC<ColoredBadgeProps> = ({
  title,
  bgColor,
  bgColorOpacity = 50,
  showIcon = false,
  fontColor = "black",
  icon,
  iconColor,
  ColoredBadgeFont = "normal normal 600 12px/16px Source Serif Pro",
  ColoredBadgePadding = "5px",
  sx,
  imageUrl,
  imageSize = 20, // Default size
  vertical__textAlign = false,
}) => {
  // Determine which content to render in Avatar
  const content = imageUrl ? (
    <Avatar
      src={imageUrl}
      sx={{
        width: imageSize,
        height: imageSize,
        borderRadius: "50%", // Ensure the image is circular
        backgroundColor: "transparent",
      }}
    />
  ) : (
    icon || <SelectIcon color={iconColor} />
  );

  // Calculate max width based on whether the icon is shown
  const maxWidth = showIcon ? "200px" : "223px"; // Adjust max width based on the icon presence

  return (
    <Stack
      sx={{
        height: vertical__textAlign ? "100%" : imageSize, // Adjust height based on imageSize
        bgcolor: adjustHexColor(bgColor, 50),
        flexDirection: vertical__textAlign ? "column" : "row",
        justifyContent: "center", // Center items horizontally
        alignItems: "center",
        gap: "6px",
        p: ColoredBadgePadding,
        borderRadius: "5px",
        font: ColoredBadgeFont,
        color: fontColor,
        ...sx,
      }}
    >
      {showIcon && content}

      {vertical__textAlign ? (
        <VerticalText text={title} />
      ) : (
        <Typography
          sx={{
            font: "inherit",
            color: "inherit",
            maxWidth,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </Typography>
      )}
    </Stack>
  );
};

export default ColoredBadge;

const VerticalText = ({ text }: { text: string }) => {
  return (
    <div
      style={{
        writingMode: "vertical-rl", // Rotates the text vertically
        transform: "rotate(0deg)", // Rotates it 180 degrees to face left-to-right
        textAlign: "center", // Center-align text
        font: "inherit",
        color: "inherit",
      }}
    >
      {text}
    </div>
  );
};
