import {Box, Stack, Typography} from "@mui/material";
import React, {useCallback, useContext, useEffect} from "react";
import {StreamIconBearishOSIconPng} from "../../../../../images";
import {AvatarNormal} from "../../../../common";
import {AcceptButton, InfoView, RejectButton} from "./view";
import {useDispatch} from "react-redux";
import {handleAcceptCallAction, handleRejectCallAction} from "../../../../../redux/Slice/Global/globalSlice";
import {MeetingContext} from "../../../../Povider";

interface IncomingCallPopupProps {
    groupCallState: any
}

const IncomingCallPopup: React.FC<IncomingCallPopupProps> = ({groupCallState}) => {
    const isVideoCall: boolean = true;

    const dispatch = useDispatch();
    const meetingContext: any = useContext(MeetingContext);

    const handleRejectCall = () => {
        dispatch(handleRejectCallAction());
    }

    const handleAcceptCall = async () => {
        const chimeMeetingId = groupCallState?.callData?.chimeMeetingId;
        const meetingId = groupCallState?.callData?.externalMeetingId;
        if (chimeMeetingId) {
            const attendee = await meetingContext?.createAttendee({
                meetingId: meetingId,
            });
            meetingContext.joinMeeting({
                meetingId: chimeMeetingId,
                attendeeId: attendee?.chimeAttendeeId,
            });
            await meetingContext.configureAudioOutput();
            await meetingContext.startVideoInput();
            await meetingContext.startAudioInput();
            await meetingContext.startSession();
        }
        await dispatch(handleAcceptCallAction());
    }

    const handleGetMeetingDetails = useCallback(async () => {
        await meetingContext?.getMeeting({
            meetingId: groupCallState?.callData?.externalMeetingId,
        });
    }, []);

    useEffect(() => {
        (async () => await handleGetMeetingDetails())();
    }, [handleGetMeetingDetails]);

    return (
        <Stack
            sx={{
                width: "245px",
                height: "335px",
                boxSizing: "border-box",
                borderRadius: "5px",
                boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
                padding: "15px 10px",
                bgcolor: "white",
            }}
        >
            <Stack
                sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    component={"img"}
                    src={StreamIconBearishOSIconPng}
                    alt="logo"
                    sx={{height: "15px", width: "15px"}}
                />
                <Typography
                    sx={{font: "normal normal 400 13px/16px Source Serif Pro"}}
                    children="Incoming Call"
                />
            </Stack>
            {/* -------------------------- */}

            <Stack sx={{alignItems: "center", gap: "5px", mt: "34px"}}>
                <AvatarNormal size={85}/>
                <Typography
                    sx={{
                        font: "normal normal 900 14px/18px Source Serif Pro",
                        mt: "5px",
                    }}
                    children={groupCallState?.callHostDetails?.firstName + " " + groupCallState?.callHostDetails?.lastName}
                />
                <Typography
                    sx={{font: "normal normal 300 12px/15px Source Serif Pro"}}
                    children={isVideoCall ? "is video calling you" : "is calling you"}
                />

                <Stack sx={{flexDirection: "row", gap: "5px"}}>
                    <RejectButton onClick={() => handleRejectCall()}/>
                    <AcceptButton onClick={() => handleAcceptCall()}/>
                </Stack>
            </Stack>

            {/* -------------------------- */}

            <Stack sx={{gap: "10px", alignItems: "center", mt: "15px"}}>
                <InfoView title="Company" value={groupCallState?.callHostDetails?.email.split('@')[1].split('.')[0]}/>
                <InfoView title="Service" value="Bearish OS Calls"/>
                <InfoView title="Role" value="Project Manager"/>
            </Stack>
        </Stack>
    );
};

export default IncomingCallPopup;
