import { Stack } from "@mui/material";
import React from "react";
import { MessagingScreenTabs } from "../components";
import RightSidebars from "../components/RightSidebars/RightSidebars";
import { initiateGroupCall } from "../../../../redux/Slice/Global/globalActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { MessagesState } from "../../../../redux/Slice/message/messageTypes";

const MessageCenter = () => {
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const [selectedSidebar, setSelectedSidebar] = React.useState("OpenCalender");

    const {
        chatTabState,
    } = useSelector((state: RootState) => state.message as MessagesState);

    const handleToggleRightSideBar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const dispatch = useDispatch();

    const handleSetSelectedSidebar = (selectedSidebar: string) => {
        setSelectedSidebar(selectedSidebar);
        if (selectedSidebar === "Start Call") {
            dispatch(initiateGroupCall(chatTabState?.selectedConversation.conversationId));
        }
        if (selectedSidebar === "Start Meeting") {
            dispatch(initiateGroupCall(chatTabState?.selectedConversation.conversationId));
        }
    }

    return (
        <Stack
            sx={{
                height: "calc(100vh - 50px)",
                bgcolor: "#f7f7f8",
                width: "100%",
                boxSizing: "border-box",
                padding: "0px 15px",
                flexDirection: "row",
                justifyContent: "space-between",
            }}
        >
            <MessagingScreenTabs
                handleToggleRightSideBar={handleToggleRightSideBar}
                setSelectedSideBar={handleSetSelectedSidebar}
                isSidebarOpen={isSidebarOpen}
                selectedSidebar={selectedSidebar}
            />
            <Stack
                sx={{
                    width: isSidebarOpen ? "25vw" : "0vw",
                    minWidth: isSidebarOpen ? "375px" : "0px",
                    boxSizing: "border-box",
                    padding: isSidebarOpen ? "0px 15px" : "0px",
                    borderLeft: isSidebarOpen ? "2px solid #E9EDF2" : "0px",
                    flexGrow: 0,
                    flexShrink: 0,
                    transition: "width 0.3s ease, min-width 0.3s ease",
                    overflow: "hidden",
                    animation: isSidebarOpen
                        ? "slideIn 0.3s forwards"
                        : "slideOut 0.3s forwards", 
                    backgroundColor: "white",
                }}
            >
                <RightSidebars handleSetSelectedSidebar={handleSetSelectedSidebar} selectedSidebar={selectedSidebar} />
            </Stack>
        </Stack>
    );
};

export default MessageCenter;
