import React from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import { ScheduleTableDateListType } from "../../AttendanceScheduleTableType";
import { AttendanceStatusColorLiist } from "../../../CrmStatusBadge";
import { adjustHexColor } from "../../../../../../../Utils";
import { getDayNameByDate, isToday } from "../../AttendanceTableUtils";

interface MonthViewDateAreaProps {
  dateList: ScheduleTableDateListType;
}

const MonthViewDateArea: React.FC<MonthViewDateAreaProps> = ({ dateList }) => {
  const { date, type, holidayName } = dateList;

  const extractDateFromDateString = (dateString: string): string => {
    return dateString.split("-")[2];
  };

  const getBgColor = (): string => {
    switch (type) {
      case "working":
      default:
        return "#E9EDF2";
      case "dayOff":
        return adjustHexColor(AttendanceStatusColorLiist.dayoff, 25);
      case "holiday":
        return AttendanceStatusColorLiist.holiday;
    }
  };

  return (
    <Tooltip title={holidayName} placement="top" arrow disableInteractive>
      <Stack
        sx={{
          height: "40px",
          width: "30px",
          bgcolor: getBgColor(),
          flexShrink: 0,
          alignItems: "center",
          paddingTop: "5px",
          gap: "1px",
          boxSizing: "border-box",
          border: isToday(date) ? "2px solid #00767B" : "0px",
        }}
      >
        <Typography
          sx={{ font: "normal normal 600 11px/14px Source Serif Pro" }}
        >
          {getDayNameByDate({ dateString: date })}
        </Typography>
        <Typography
          sx={{ font: "normal normal 600 11px/14px Source Serif Pro" }}
        >
          {extractDateFromDateString(date)}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default MonthViewDateArea;
