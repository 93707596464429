import React from "react";
import { MsgAttachmentViewContainer } from "../../../../common";
import { getFileIcon } from "../../../../../Utils";

interface MsgFilePreviewProps {
  fileData?: any;
}

const MsgFilePreview: React.FC<MsgFilePreviewProps> = ({fileData}) => {

    const handleQuickViewFile = () => {
      fileData && window.open(fileData.fileUrl, "_blank");
    }

  return (
    <MsgAttachmentViewContainer
      imgOrSvg={getFileIcon({ name: "file.png", iconSize: 25 })}
      title={fileData.fileName}
      subtitle="os.bearishos.com/cloud/1234567890/file_url_here"
      onQuickViewClick={() => handleQuickViewFile()}
    />
  );
};

export default MsgFilePreview;
