
import {
  GoogleMeetLogoPng,
  MicrosoftTeamsLogoPng,
  StreamBearishOSLogoIconPng,
  WebexPng,
  ZoomLogoPng,
} from "../../../../../images";
import { CallPlateformEnum } from "../../ReplyBarType";

export const renderVideoCallIcon = (value: CallPlateformEnum) => {
  switch (value) {
    case CallPlateformEnum.BearishCall:
      return StreamBearishOSLogoIconPng;
    case CallPlateformEnum.GoogleMeet:
      return GoogleMeetLogoPng;
    case CallPlateformEnum.Teams:
      return MicrosoftTeamsLogoPng;
    case CallPlateformEnum.Webex:
      return WebexPng;
    case CallPlateformEnum.Zoom:
      return ZoomLogoPng;
  }
};

export type PlateformOptionalListType = {
  _id: string;
  title: string;
  value: CallPlateformEnum;
};


export const PlateformDropdownOptionalList: PlateformOptionalListType[] = [
  { _id: '1', title: "Bearish Calls", value: CallPlateformEnum.BearishCall },
  { _id: '2', title: "Teams", value: CallPlateformEnum.Teams },
  { _id: '3', title: "Zoom", value: CallPlateformEnum.Zoom },
  { _id: '4', title: "Webex", value: CallPlateformEnum.Webex },
  { _id: '5', title: "Google Meet", value: CallPlateformEnum.GoogleMeet },
] 
