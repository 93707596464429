import { Stack, Typography } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { CheckboxMedium } from "../../../../common";
import { EmailClickedMenu } from "../EmailClickedMenu";
import { EmailFilterChips } from "../EmailFilterChips";
import {
  AttachmentView,
  IconsGroupView,
  ReplyButtonView,
  StarToggleView,
  TimeView,
  UserCount,
  UserInfoChip,
} from "../EmailCardComponents";
import { getAccessToken } from "../../../../common/LocalStorage";
import { useParams } from "react-router-dom";

interface EmailCardSquareProps {
  viewType?: "condensed" | "expanded";
  isSelected?: boolean;
  isClicked?: boolean;
  showReplyButton?: boolean;
  email?: any;
  accessToken?: any;
  messageId?: any;
  handleOpenBody?: (messageId: any) => Promise<void>;
  handleAddStar?: (messageId: any) => Promise<void>;
  isRead?: boolean;
}

const EmailCardSquare: React.FC<EmailCardSquareProps> = ({
  viewType = "expanded",
  isSelected = false,
  isClicked = false,
  showReplyButton = false,
  email,
  handleOpenBody,
  handleAddStar,
  isRead = false,
}) => {
  const { emailId } = useParams();
  const [isHovered, setIsHovered] = useState(false);
  const [isToggled, setIsToggled] = useState(email?.isStarted);
  const [accessToken, setAccessToken] = useState<string>("");
  const cardRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const messageId = email?.id;
  const handleClickOutside = (event: MouseEvent) => {
    if (
      cardRef.current &&
      !cardRef.current.contains(event.target as Node) &&
      menuRef.current &&
      !menuRef.current.contains(event.target as Node)
    ) {
      setIsHovered(false);
    }
  };

  useEffect(() => {
    const token = getAccessToken();
    setAccessToken(token);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleStar = (messageId: string) => {
    setIsToggled((val: any) => !val);
    handleAddStar && handleAddStar(messageId);
  };
  const generateColorFromEmail = (email: string) => {
    let hash = 0;
    for (let i = 0; i < email?.length; i++) {
      hash = email?.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `#${(hash & 0x00ffffff)?.toString(16)?.padStart(6, "0")}`;
    return color;
  };
  return (
    <Stack
      className="Esquare-container"
      ref={cardRef}
      sx={{
        minWidth: "100%",
        maxWidth: "342px",
        bgcolor: "#ffffff87",
        padding: "10px 10px 10px 6px",
        boxSizing: "border-box",
        borderRadius: emailId ? "5px" : "5px",
        borderBottom: "1px solid #E9EDF2",
        gap: "10px",
        boxShadow: isHovered ? "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" : null,
        borderLeft:
          isSelected || isClicked ? "4px solid #E9EDF2" : "transparent",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* first line components */}
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          {showReplyButton && <ReplyButtonView />}
          <StarToggleView
            isToogled={isToggled}
            onStarClick={() => handleStar(messageId)}
          />
          <UserInfoChip
            popoverPlace="bottom"
            userName={email?.sender?.emailAddress?.name}
            userEmail={email?.sender?.emailAddress?.address}
            userIconSrc={email?.sender?.emailAddress?.logo}
            userAvatarColor={generateColorFromEmail(
              email?.sender?.emailAddress?.address
            )}
          />
          <UserCount userList={email?.ccRecipients} />
        </Stack>

        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          {/* {!isHovered ? <TimeView time={email?.createdDateTime} /> : <IconsGroupView email={email} accessToken={accessToken} messageId={messageId} />} */}

          {/* Handling display time view and icons group using CSS */}

          <TimeView time={email?.createdDateTime} />
          <IconsGroupView
            email={email}
            accessToken={accessToken}
            messageId={messageId}
          />
        </Stack>
      </Stack>

      {/* -----  second line ----- */}

      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <CheckboxMedium boxSize={15} checked={isSelected} />
        <Typography
          sx={{
            font: `normal normal ${isRead ? 400 : 700} 11px/14px Source Serif Pro`,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
          onClick={() => handleOpenBody && handleOpenBody(messageId)}
        >
          {email?.subject}
        </Typography>
      </Stack>

      {/* ---------------- third line ------------ */}
      {viewType === "expanded" && (
        <Typography
          sx={{
            font: "normal normal 300 11px/14px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",   cursor: "pointer",
          }}onClick={() => handleOpenBody && handleOpenBody(messageId)}
        >
          {email?.bodyPreview}
        </Typography>
      )}

      {/* ------- options and attachment -------- */}
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: viewType === "expanded" ? "space-between" : "end",
        }}
      >
        {viewType === "expanded" && (
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}
          >
            <EmailFilterChips variant={email?.folderName} />

            <AttachmentView AttachmentChipViewData={email?.attachments} />
          </Stack>
        )}
        <div ref={menuRef}>
          <EmailClickedMenu
            accessToken={accessToken}
            messageId={messageId ?? null}
          />
        </div>
      </Stack>
    </Stack>
  );
};

export default EmailCardSquare;
