import { Stack, Typography } from "@mui/material";
import React from "react";
import BalanceInfoView from "../BalanceInfoView/BalanceInfoView";

interface BalancesAndTalliesViewProps {}

const BalancesAndTalliesView: React.FC<BalancesAndTalliesViewProps> = ({}) => {
  return (
    <Stack sx={{ gap: "10px" }}>
      <Typography sx={{ font: "normal normal 900 13px/16px Source Serif Pro" }}>
        Balances & Tallies
      </Typography>

      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <Stack sx={{ flex: 1, gap: "10px" }}>
          <BalanceInfoView label="Paid Time Off" value={5} />
          <BalanceInfoView label="Vacation" value={1} />
          <BalanceInfoView label="Other(s)" />
          <BalanceInfoView label="Late" labelSx={{ color: "#FF0000" }} />
        </Stack>

        <Stack sx={{ flex: 1, gap: "10px" }}>
          <BalanceInfoView label="Sick Leave" />
          <BalanceInfoView label="Maternity" />
          <Stack height={"16px"} />
          <BalanceInfoView label="Absent" labelSx={{ color: "#707070" }} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BalancesAndTalliesView;
