import { Drawer, IconButton, Stack } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import { MainActionButton } from "../../../Buttons";
import styles from "../../DrawerNavbar.module.css";
import { ButtonType, MainActionColor } from "../../../Buttons/AllButtonProps";
import {
  BilledIcon,
  ComplianceIcon,
  DeadlineIcon,
  DocumentIcon,
  GearIcon,
  PayrollIcon,
  TeamMemberIcon,
  TypeIcon,
} from "../../../../../images";
import {
  TraxDrawerButtons,
  TraxDrawerContainer,
  TraxDrawerMenu,
} from "../../../../pages/Trax/components";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TraxPaths } from "../../../../pages";

type TraxDrawerProps = {
  drawerOpen: boolean;
  drawerCloser: () => void;
};

const DrawerContainer = styled("div")({
  position: "fixed",
  top: 0,
  height: "100vh",
  zIndex: 1100, // Ensure the drawer is behind the navbar
});
const TraxDrawer: React.FC<TraxDrawerProps> = ({
  drawerOpen,
  drawerCloser,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <DrawerContainer>
        <Drawer
          sx={{
            position: "absolute",
            top: 0,
            height: "100vh",
            width: "215px",
            // left: '-240px', // Start hidden behind the Navbar
            transition: drawerOpen
              ? "left 225ms ease-out"
              : "left 195ms ease-in",
            left: drawerOpen ? "30px" : "-215px",
            zIndex: 1100, // Ensure the drawer is behind the navbar
            "& .MuiPaper-root": {
              position: "absolute",
              top: 0,
              height: "100vh",
              width: "215px",
              left: 0,
              boxSizing: "border-box",
              zIndex: 1100, // Ensure the paper is also behind the navbar
            },
          }}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
        >
          <Stack
            bgcolor={"white"}
            direction="column"
            flex={1}
            className={`${styles.drawerNavbar__drawer}`}
          >
            {/* Company logo and name here */}
            <CompanyIconAndName arrowButtonClickHandler={drawerCloser} />
            <Stack className={styles.drawerNavbar__content}>
              <Stack
                sx={{
                  marginY: "25px",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TraxDrawerMenu />

                <IconButton>
                  <GearIcon w={15} />
                </IconButton>
              </Stack>
              <Stack gap={"15px"}>
                <TraxDrawerContainer label={t("TRAX.DRAWER_LABELS.TRAX")}>
                  <TraxDrawerButtons
                    icon={TeamMemberIcon}
                    buttonLabel={t("TRAX.DRAWER_LABELS.EMPLOYEE_DIRECTORY")}
                    onClick={() =>
                      navigate(`/Trax/employee-directory` as TraxPaths)
                    }
                    isSelected={location.pathname.startsWith(
                      "/Trax/employee-directory" as TraxPaths
                    )}
                  />
                  <TraxDrawerButtons
                    icon={DeadlineIcon}
                    buttonLabel={t("TRAX.DRAWER_LABELS.TIME_AND_ATTENDANCE")}
                    onClick={() =>
                      navigate(`/Trax/time-attendance` as TraxPaths)
                    }
                    isSelected={location.pathname.startsWith(
                      "/Trax/time-attendance" as TraxPaths
                    )}
                  />
                  <TraxDrawerButtons
                    icon={BilledIcon}
                    buttonLabel={t("TRAX.DRAWER_LABELS.EXPENSES")}
                    onClick={() => navigate(`/Trax/expenses` as TraxPaths)}
                    isSelected={location.pathname.startsWith(
                      "/Trax/expenses" as TraxPaths
                    )}
                  />
                  <TraxDrawerButtons
                    icon={PayrollIcon}
                    buttonLabel={t("TRAX.DRAWER_LABELS.PAYROLL")}
                    onClick={() => navigate(`/Trax/payroll` as TraxPaths)}
                    isSelected={location.pathname.startsWith(
                      "/Trax/payroll" as TraxPaths
                    )}
                  />
                </TraxDrawerContainer>

                <TraxDrawerContainer
                  label={t("TRAX.DRAWER_LABELS.PERFORMANCE")}
                >
                  <TraxDrawerButtons
                    icon={TeamMemberIcon}
                    buttonLabel={t("TRAX.DRAWER_LABELS.REVIEWS")}
                    onClick={() => navigate(`/Trax/reviews` as TraxPaths)}
                    isSelected={location.pathname.startsWith(
                      "/Trax/reviews" as TraxPaths
                    )}
                  />
                  <TraxDrawerButtons
                    icon={TypeIcon}
                    buttonLabel={t("TRAX.DRAWER_LABELS.DEVELOPMENT")}
                    onClick={() => navigate(`/Trax/development` as TraxPaths)}
                    isSelected={location.pathname.startsWith(
                      "/Trax/development" as TraxPaths
                    )}
                  />
                  <TraxDrawerButtons
                    icon={ComplianceIcon}
                    buttonLabel={t("TRAX.DRAWER_LABELS.COMPLIANCE")}
                    onClick={() => navigate(`/Trax/compliance` as TraxPaths)}
                    isSelected={location.pathname.startsWith(
                      "/Trax/compliance" as TraxPaths
                    )}
                  />
                  <TraxDrawerButtons
                    icon={DocumentIcon}
                    buttonLabel={t("TRAX.DRAWER_LABELS.AUDITING_AND_REPORTS")}
                    onClick={() =>
                      navigate(`/Trax/auditing-reports` as TraxPaths)
                    }
                    isSelected={location.pathname.startsWith(
                      "/Trax/auditing-reports" as TraxPaths
                    )}
                  />
                </TraxDrawerContainer>
              </Stack>
            </Stack>
          </Stack>
        </Drawer>
      </DrawerContainer>
    </>
  );
};

export default TraxDrawer;
