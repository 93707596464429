import { Stack, styled, Typography, Popover, SxProps } from "@mui/material";
import React, { useState, useEffect } from "react";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ClockIcon, ClockNewIcon } from "../../../images";

const StyledMultiSectionDigitalClock = styled(MultiSectionDigitalClock)({
  "& .MuiMultiSectionDigitalClockSection-root": {
    "&::-webkit-scrollbar": {
      display: "none" /* Chrome, Safari, Opera */,
    },
    scrollbarWidth: "none" /* Firefox */,
  },
  "& .MuiMultiSectionDigitalClockClock-root": {
    fontFamily: "Source Serif Pro, serif",
  },
  "& .MuiMultiSectionDigitalClockSection-item": {
    fontFamily: "Source Serif Pro, serif",
  },
});

interface DatePickerMiniProps {
  sx?: SxProps;
  hideIcon?: boolean;
  readonlyComponent?: boolean;
  onTimeChange?: (time: Dayjs | null) => void;
  onFormatedTimeSelect?: (time: string) => void;
  initialTime?: string;
  initialTimeAsHHmm?: string; // Pass initial data as HH:mm like "10:30", "12:24", "21:18"
  additionalWord?: string;
  headerType?: "default" | "FixedBox";
  minTime?: string; // Minimum time (HH:mm) // user can only choose time after this
}

const DatePickerMini: React.FC<DatePickerMiniProps> = ({
  sx,
  hideIcon = false,
  readonlyComponent = false,
  onTimeChange,
  onFormatedTimeSelect,
  initialTime,
  initialTimeAsHHmm,
  additionalWord,
  headerType = "default",
  minTime = "00:00", // Default minimum time
}) => {
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(
    initialTime
      ? dayjs(initialTime)
      : initialTimeAsHHmm
      ? dayjs(`1970-01-01T${initialTimeAsHHmm}`)
      // : dayjs()
      : null
  );

  const [anchorElTime, setAnchorElTime] = useState<null | HTMLElement>(null);

  // Create a state for minTime
  const [currentMinTime, setCurrentMinTime] = useState(
    dayjs(`1970-01-01T${minTime}:00`)
  );

  useEffect(() => {
    if (initialTime) {
      const parsedTime = dayjs(initialTime);
      setSelectedTime(parsedTime);
    } else if (initialTimeAsHHmm) {
      const parsedTime = dayjs(`1970-01-01T${initialTimeAsHHmm}`);
      setSelectedTime(parsedTime);
    }
  }, [initialTime, initialTimeAsHHmm]);

  // Update minTime dynamically
  useEffect(() => {
    setCurrentMinTime(dayjs(`1970-01-01T${minTime}:00`));
  }, [minTime]);

  const handleClickTime = (event: React.MouseEvent<HTMLElement>) => {
    if (!readonlyComponent) {
      setAnchorElTime(event.currentTarget);
    }
  };

  const handleCloseTime = () => {
    setAnchorElTime(null);
  };

  const handleTimeChange = (time: Dayjs | null) => {
    if (time) {
      // If the selected time is before the minTime, return early
      if (time.isBefore(currentMinTime, "minute")) {
        return;
      }

      setSelectedTime(time);

      if (onTimeChange) {
        onTimeChange(time);
      }

      if (onFormatedTimeSelect) {
        onFormatedTimeSelect(time.format("hh:mm A"));
      }
    }
  };

  const openTime = Boolean(anchorElTime);

  const renderHeader = (): React.ReactNode => {
    switch (headerType) {
      case "default":
        return (
          <Stack
            sx={{
              height: "25px",
              width: "80px",
              border: "0.5px solid #E9EDF2",
              borderRadius: "5px",
              flexDirection: "row",
              alignItems: "center",
              px: "5px",
              boxSizing: "border-box",
              justifyContent: "space-between",
              cursor: "pointer",
              flexShrink: 0,
              font: "normal normal 300 12px/16px Source Serif Pro",
              ...sx,
            }}
          >
            <Typography sx={{ font: "inherit" }}>
              {selectedTime ? selectedTime.format("hh:mm A") : "time here"}{" "}
              {additionalWord}
            </Typography>
            {!hideIcon && <ClockIcon w={15} />}
          </Stack>
        );

      case "FixedBox":
        return (
          <Stack
            sx={{
              height: "25px",
              width: "110px",
              border: "0.5px solid #E9EDF2",
              borderRadius: "5px",
              flexDirection: "row",
              alignItems: "center",
              pl: "5px",
              boxSizing: "border-box",
              cursor: "pointer",
              flexShrink: 0,
              font: "normal normal 400 12px/15px Source Serif Pro",
              gap: "10px",
              ...sx,
            }}
          >
            {!hideIcon && <ClockNewIcon w={16} />}
            <Typography sx={{ font: "inherit", whiteSpace: "nowrap" }}>
              {selectedTime ? selectedTime.format("hh:mm A") : "time here"}{" "}
              {additionalWord}
            </Typography>
          </Stack>
        );
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack
        onClick={(e: any) => handleClickTime(e)}
        sx={{ width: "fit-content" }}
      >
        {renderHeader()}
      </Stack>
      <Popover
        open={openTime}
        anchorEl={anchorElTime}
        onClose={handleCloseTime}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <StyledMultiSectionDigitalClock
          timeSteps={{ minutes: 1 }}
          value={selectedTime}
          onChange={handleTimeChange}
        />
      </Popover>
    </LocalizationProvider>
  );
};

export default DatePickerMini;
