import { RadioGroup, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  NotesIcon,
  NotesLineIcon,
  TotalhoursIcon,
} from "../../../../../images";
import { TimeMultiplierMenu } from "../TimeMultiplierMenu";
import {
  CustomRadioFormControlLabel,
  EditableTextArea,
  EditableTimeTextField,
} from "../../../../common";

interface AllowedOvertimePopopProps {}

const AllowedOvertimePopop: React.FC<AllowedOvertimePopopProps> = () => {
  const [breakType, setBreakType] = useState("paid");
  return (
    <Stack
      sx={{
        width: "245px",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
        boxSizing: "border-box",
        padding: "15px",
        gap: "15px",
      }}
    >
      {/*  -- card header and icon with title */}
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <TotalhoursIcon w={15} />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          Allowed Overtime
        </Typography>
      </Stack>

      {/* --- Overtime Multiplier dropdown */}
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          Overtime Multiplier
        </Typography>
        <TimeMultiplierMenu onselect={(value) => null} />
      </Stack>

      {/* --- total Breaks --- */}
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          Total Breaks
        </Typography>
        <EditableTimeTextField
          initialValue=""
          onSave={() => null}
          variant="hrs"
        />
        <EditableTimeTextField
          initialValue=""
          onSave={() => null}
          variant="mins"
        />
      </Stack>

      {/* Radio Group - Breaks area*/}
      <RadioGroup
        aria-labelledby="calendar-setting-radio-buttons-group"
        name="calendar-setting-radio-buttons-group"
        value={breakType}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setBreakType(event.target.value)
        }
        sx={{ gap: "15px" }}
      >
        <CustomRadioFormControlLabel
          value="paid"
          label="Paid Overtime Allowed"
        />
        <CustomRadioFormControlLabel
          value="unpaid"
          label="Overtime Unpaid, Unallowed"
        />
      </RadioGroup>

      {/* -------------------------- */}

      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <NotesLineIcon w={15} />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          Notes
        </Typography>
      </Stack>

      <EditableTextArea
        minRows={3}
        maxRows={3}
        style={{
          font: "normal normal 300 13px/16px Source Serif Pro",
          border: "1px solid #E9EDF2",
          marginTop: "-10px",
        }}
      />
    </Stack>
  );
};

export default AllowedOvertimePopop;
