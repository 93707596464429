import { IconButton, Popper, Stack } from "@mui/material";
import React from "react";
import { AttechIcon } from "../../../../../../images";
import AttachmentChipView from "../AttachmentChipView/AttachmentChipView";

interface AttachmentViewProps {
  AttachmentChipViewData?: Array<{ name: string }>; // Specify the expected structure
}

const AttachmentView: React.FC<AttachmentViewProps> = ({
  AttachmentChipViewData,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  // Edge case handling: Return null if no data or undefined
  if (!AttachmentChipViewData || AttachmentChipViewData.length === 0)
    return null;

  return (
    <Stack
      sx={{ cursor: "pointer", width: "fit-content" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <IconButton sx={{ p: 0 }} disableRipple>
        <AttechIcon w={15} />
      </IconButton>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        onMouseLeave={handleMouseLeave}
        sx={{
          zIndex: 10,
          boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
          bgcolor: "white",
          borderRadius: "5px",
        }}
      >
        <Stack
          sx={{
            height: "auto",
            maxHeight: "130px",
            overflowY: "scroll",
            scrollbarWidth: "none",
            flexDirection: "column",
            alignItems: "center",
            boxSizing: "border-box",
            padding: "10px",
            gap: "10px",
          }}
        >
          {/* Check for valid attachment data */}
          {AttachmentChipViewData.map((attachment, index) => (
            <AttachmentChipView
              key={index}
              name={attachment?.name ?? "Unknown Attachment"} // Safely access 'name' property
            />
          ))}
        </Stack>
      </Popper>
    </Stack>
  );
};

export default AttachmentView;
