import React, { useRef, useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { ColorPickerDot, SmallThreeDotMenu } from "../../../../common";
import {
  DummyImage,
  GreenDropdownIcon,
  WikisIconPng,
} from "../../../../../images";
import { DocumentOrItemView } from "./view";
import styles from "./WorkspaceLargeStackedAndImage.module.css";

interface WorkspaceLargeStackedAndImageProps {}

const WorkspaceLargeStackedAndImage: React.FC<
  WorkspaceLargeStackedAndImageProps
> = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <Stack className={styles.workspace}>
      <Stack className={styles["workspace__header"]}>
        <Stack className={styles["workspace__header-left"]}>
          <IconButton
            className={styles["workspace__header-icon"]}
            onClick={toggleExpand}
          >
            <GreenDropdownIcon sx={{ height: "12px" }} open={isExpanded} />
          </IconButton>
          <Box
            component="img"
            src={DummyImage}
            className={styles["workspace__header-image"]}
          />
          <Typography className={styles["workspace__header-title"]}>
            Workspace Name
          </Typography>
        </Stack>

        <Stack className={styles["workspace__header-right"]}>
          <ColorPickerDot />
          <Typography className={styles["workspace__created-on"]}>
            Created on: {"10-10-2024"}
          </Typography>
          <SmallThreeDotMenu />
        </Stack>
      </Stack>

      <Box
        className={`${styles["workspace__content"]} ${
          isExpanded ? styles["workspace__content--expanded"] : ""
        }`}
        style={{
          height: isExpanded ? `${contentRef.current?.scrollHeight}px` : "0px",
          maxHeight: isExpanded ? "200px" : "0px",
        }}
      >
        <div ref={contentRef}>
          {Array.from({ length: 20 }).map((item, index) => (
            <DocumentOrItemView
              imgSrc={WikisIconPng}
              title={`Document title ${index + 1} `}
              key={index}
            />
          ))}
        </div>
      </Box>
    </Stack>
  );
};

export default WorkspaceLargeStackedAndImage;
