import React, { memo, useEffect, useState, useMemo } from "react";
import { Container, Box, Skeleton, Typography } from "@mui/material";
import { SidebarCalenderInfoCard } from "../../../../../../pages/Calendar/components";
import { getUser } from "../../../../../../../Utils";

interface CalendarListProps {
    loader?: boolean;
    allCalendarLists?: any[];
    allBookingLinkLists?: any[];
    handleToggleCalendarVisibility?: (id: string | undefined) => void;
    displayOwnerCalendar?: boolean;
    bookingLink?: boolean;
    setBLinkId?:any;
}

const CalendarList: React.FC<CalendarListProps> = ({
    loader = false,
    allCalendarLists = [],
    handleToggleCalendarVisibility,
    displayOwnerCalendar = true,
    bookingLink = false,
    allBookingLinkLists = [],setBLinkId
}) => {
    const user = getUser();
    const [filteredCalendars, setFilteredCalendars] = useState<any[]>([]);
    const [bookingLinks, setBookingLinks] = useState<any[]>([]);

    const filteredCalendarsMemo = useMemo(() => {
        if (Array.isArray(allCalendarLists) && user?.data?._id) {
            return allCalendarLists?.filter((calendar: any) => {
                if (displayOwnerCalendar) {
                    return calendar?.owner === user?.data?._id;
                } else {
                    return calendar?.owner !== user?.data?._id;
                }
            });
        }
        return [];
        // ? If you are confident that the current implementation is correct
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [allCalendarLists, user?.data?._id, displayOwnerCalendar]);

    useEffect(() => {
        if (Array.isArray(filteredCalendarsMemo)) {
            setFilteredCalendars((prev) =>
                JSON.stringify(prev) !== JSON.stringify(filteredCalendarsMemo)
                    ? filteredCalendarsMemo
                    : prev
            );
        }
    }, [filteredCalendarsMemo]);

    useEffect(() => {
        if (Array.isArray(allBookingLinkLists)) {
            setBookingLinks((prev) =>
                JSON.stringify(prev) !== JSON.stringify(allBookingLinkLists)
                    ? allBookingLinkLists
                    : prev
            );
        }
    }, [allBookingLinkLists]);

    const getFallBackText = () => {
        if (displayOwnerCalendar && !bookingLink) {
            return "No calendar created yet";
        } else if (!displayOwnerCalendar && !bookingLink) {
            return "No shared calendar available";
        }
        return "No Booking Link Available";
    };


    const renderCalendarContent = () => {
        if (Array.isArray(filteredCalendars) && filteredCalendars.length > 0) {
            return filteredCalendars?.map((calendar: any, index: number) => (
                <React.Suspense fallback={<div>Loading Sidebar...</div>} key={calendar?._id || index}>
                    <SidebarCalenderInfoCard
                        key={calendar?._id || index}
                        label={calendar?.name || "No Name"}
                        iconImgSrc={calendar?.icon as string | undefined }
                        viewStatus={true}
                        onEyeButtonClick={() => {
                            handleToggleCalendarVisibility?.(calendar?._id || "");
                        }}
                        isAliveToken={calendar?.isAliveToken ?? false}
                        calColor={calendar?.color || "#000000"}
                        _id={calendar?._id}
                        userInfo={user?.data}
                    />
                </React.Suspense>
            ));
        } else if (Array.isArray(bookingLinks) && bookingLinks.length > 0) {
            return bookingLinks?.map((link: any, index: number) => (
                <React.Suspense fallback={<div>Loading Sidebar...</div>} key={link?._id || index}>
                    <SidebarCalenderInfoCard
                        key={link?._id || index}
                        label={link?.name || "No Name"}
                        iconImgSrc={link?.icon as string | undefined || ""}
                        viewStatus={true}
                        onEyeButtonClick={() => {
                            handleToggleCalendarVisibility?.(link?._id || "");
                        }}
                        isAliveToken={true}
                        calColor={link?.color || "#000000"}
                        _id={link?._id}
                        userInfo={user?.data}
                        b={true}setBLinkId={setBLinkId}
                    />
                </React.Suspense>
            ));
        } else {
            return (
                <Typography
                    variant="body1"
                    sx={{
                        font: "normal normal 500 13px/17px Source Serif Pro",
                        textAlign: "center",
                        color: "text.secondary",
                    }}
                >
                    {getFallBackText()}
                </Typography>
            );
        }
    };

    return (
        <Container disableGutters>
            <Box
                sx={{ width: "100%" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={"8px"}
                flexDirection={"column"}
            >
                {loader
                    ? Array.from({ length: 4 }, (_, index) => (
                          <Skeleton
                              key={index}
                              variant="rounded"
                              width={"100%"}
                              height={40}
                              sx={{ bgcolor: "lightgray", opacity: 0.6 }}
                          />
                      ))
                    : renderCalendarContent()}
            </Box>
        </Container>
    );
};

export default memo(CalendarList);
