import { Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { CheckboxMedium } from "../../../../../../common";
import { AttendanceTableEmployeeListType } from "../../AttendanceScheduleTableType";

const InlineInfoArea: React.FC<{ title: string; value: string }> = ({
  title,
  value,
}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "5px 10px",
        // flexWrap: "wrap",
      }}
    >
      <Typography
        sx={{
          font: "normal normal 300 13px/16px Source Serif Pro",
          whiteSpace: "nowrap",
        }}
      >
        {title}:
      </Typography>
      <Tooltip title={value} placement="top" arrow enterDelay={1000}>
        <Typography
          sx={{
            font: "normal normal 900 13px/16px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {value}
        </Typography>
      </Tooltip>
    </Stack>
  );
};

interface EmployeeDetailsInfoViewProps {
  empData: AttendanceTableEmployeeListType;
}

const EmployeeDetailsInfoView: React.FC<EmployeeDetailsInfoViewProps> = ({
  empData,
}) => {
  const { _id, balance, jobName, name, scheduledHours, workingDayPerWeek } =
    empData;

  return (
    <Stack
      sx={{
        position: "sticky",
        left: 0,
        width: "220px",
        zIndex: 1,
        height: "auto",
        borderRight: "1px solid #E9EDF2",
        bgcolor: "white",
        padding: "20px 0px 5px 0px",
        gap: "10px",
      }}
    >
      {/* -- checkbox and employee name */}
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <CheckboxMedium />
        <Typography
          sx={{
            font: "normal normal 900 13px/16px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {name}
        </Typography>
      </Stack>

      {/* -- Scheduled Hours --  */}
      <InlineInfoArea title="Scheduled Hours" value={scheduledHours} />

      {/* -- SWorking Days per Week --  */}
      <InlineInfoArea title="Working Days per Week" value={workingDayPerWeek} />

      {/* -- Job Name | Details --  */}
      <InlineInfoArea title="Job Name | Details" value={jobName} />

      {/* -- Balances --  */}
      <InlineInfoArea title="Balances" value={balance} />
    </Stack>
  );
};

export default EmployeeDetailsInfoView;
