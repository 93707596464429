import {Box, Stack, Typography} from "@mui/material";
import React, {useEffect} from "react";
import { BothSideArrowIcon} from "../../../../images";
import {getRedirectServiceCss, IconsContainer, PrivacyPolicyTextView, RedirectPageContainer, RedirectServiceGroup, RedirectServiceName} from "../common";
import {Trans, useTranslation} from "react-i18next";
import {connectSlackWithBearish, connectTeamsWithBearish} from "../../../../redux/Slice/message/messageActions";
import {useDispatch} from "react-redux";
import {box__loginUrl__handle, confluence__loginUrl__handle, Jira__loginUrl__handle} from "../../../../redux";
import {msalInstance} from "../../CRM/authConfigCRM";
import { adjustHexColor } from "../../../../Utils";

const Redirect: React.FC = () => {

    const {t} = useTranslation();
    const [serviceName, setServiceName] = React.useState<RedirectServiceName>("jira");
    const [groupName, setGroupName] = React.useState<RedirectServiceGroup>("workspaceDarkOragne");
    const {imgName, labelText, labelColor} = getRedirectServiceCss({
        groupName: groupName || "workspaceDarkOragne",
        serviceName: serviceName || "jira",
    });

    const loginRequestForEmails = {
        scopes: [
            "openid",
            "profile",
            "User.Read",
            "Contacts.Read",
            "Contacts.ReadWrite",
            "Mail.Read",
            "Mail.ReadWrite",
            "Mail.Send",
            "offline_access",
        ],
    };

    const loginRequestForCalender = {
        scopes: ["openid", "profile", "Calendars.ReadWrite", "offline_access","User.Read", // To access user's profile info, including their photo
            "Contacts.Read", // To read contacts
            "Contacts.ReadWrite", // To read and write contacts (if needed)
        ]
    };

    const dispatch = useDispatch();

    const handleAuthRedirect = async (platform: string | null) => {
        switch (platform) {
            case "slack":
                try {
                    const response: any = await dispatch(connectSlackWithBearish());
                    if (response.success && response?.data) {
                        const redirectUrl = response?.data;
                        window.open(redirectUrl, "_blank");
                    }
                } catch (error) {
                    console.error("Error during Slack login:", error);
                }
                break;
            case "teams":
                try {
                    const response: any = await dispatch(connectTeamsWithBearish());
                    if (response.success && response?.data) {
                        const redirectUrl = response?.data;
                        window.open(redirectUrl, "_blank");
                    }
                } catch (error) {
                    console.error("Error during Slack login:", error);
                }
                break;
            case "box":
                try {
                    const response: any = await dispatch(box__loginUrl__handle());
                    if (response?.loginUrl) {
                        const redirectUrl = response?.loginUrl;
                        window.open(redirectUrl, "_blank");
                    }
                } catch (error) {
                    console.error("Error during Slack login:", error);
                }
                break;
            case "confluence":
                try {
                    const response: any = await dispatch(confluence__loginUrl__handle());
                    if (response.success && response?.data) {
                        const redirectUrl = response?.data?.loginUrl;
                        window.open(redirectUrl, "_blank");
                    }
                } catch (error) {
                    console.error("Error during Confluence login:", error);
                }
                break;
                case "jira":
                    try {
                        const response: any = await dispatch(Jira__loginUrl__handle());
                        if (response.success && response?.data) {
                            const redirectUrl = response?.data?.loginUrl;
                            window.open(redirectUrl, "_blank");
                        }
                    } catch (error) {
                        console.error("Error during Jira login:", error);
                    }
                    break;
            case "outlookCalendar":
                try {
                    if (!msalInstance.getAllAccounts().length) {
                        await msalInstance.initialize();
                    }
                    const response: any = await msalInstance.loginPopup(loginRequestForCalender);

                    if (response?.loginUrl) {
                        const redirectUrl = response?.loginUrl;
                        window.open(redirectUrl, "_blank");
                    }
                } catch (error:any) {
                    if (error.errorCode === "user_cancelled") {
                        console.warn("User cancelled the login flow.");
                    } else {
                        console.error("Error during Outlook login:", error);
                    }
                }
                break;
            case "outlookEmail":
                try {
                    if (!msalInstance.getAllAccounts().length) {
                        await msalInstance.initialize();
                    }
                    const response: any = await msalInstance.loginPopup(loginRequestForEmails);

                    if (response?.loginUrl) {
                        const redirectUrl = response?.loginUrl;
                        window.open(redirectUrl, "_blank");
                    }
                } catch (error:any) {
                    if (error.errorCode === "user_cancelled") {
                        console.warn("User cancelled the login flow.");
                    } else {
                        console.error("Error during Outlook login:", error);
                    }
                }
                break;
            default:
                break;
        }
    }


    useEffect(() => {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const platform: any = url.searchParams.get("platform");
        const groupName: any = url.searchParams.get("groupName");
        if (platform) {
        setServiceName(platform);
        setGroupName(groupName);
        }
        const timer = setTimeout(async () => {
            await handleAuthRedirect(platform);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <RedirectPageContainer>
            <Stack
                sx={{
                    width: "500px",
                    minHeight: "600px",
                    bgcolor: adjustHexColor("#E6BA8C", 55),
                    alignItems: "center",
                    paddingTop: "52px",
                    gap: "30px",
                }}
            >
                <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "15px" }}>
                    <IconsContainer borderColor="#775EE2" imgName="bearishPng" />
                    <BothSideArrowIcon w={50} />
                    <IconsContainer borderColor={labelColor} imgName={imgName} />
                </Stack>

                <Typography
                    sx={{ font: "normal normal 900 40px/50px Source Serif Pro" }}
                >
                    <Trans
                        i18nKey="DASHBOARD.REDIRECTING_TO"
                        components={{
                            span: <span style={{ color: labelColor }} />,
                        }}
                        values={{ serviceName: labelText }}
                    />
                </Typography>

                <Typography
                    sx={{
                        font: "normal italic 300 20px/25px Source Serif Pro",
                        width: "450px",
                    }}
                >
                    {t("DASHBOARD.REDIRECT_MESSAGE", {
                        serviceName: labelText,
                        time: "10",
                    })}
                </Typography>
                <Box
                    sx={{ height: "1px", bgcolor: "black", width: "425px", mt: "27px" }}
                />
                <PrivacyPolicyTextView />
            </Stack>
        </RedirectPageContainer>
    );
};

export default Redirect;
