import React, { useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { ButtonSmall } from "../Buttons";
import s from "./WelcomeWalkthrough.module.css";
import { XCircleIcon } from "../../../images";
import { BorderLinearProgress } from "../BorderLinearProgress";
import { ButtonType, SmallButtonColor } from "../Buttons/AllButtonProps";
import { useDispatch } from "react-redux";
import { updateWalkthroughStats__api } from "../../../redux/Slice/auth/authActions";

// Define types for page content
interface PageContent {
  header: string;
  body: string;
}

const pageContents: Record<number, PageContent> = {
  1: {
    header: "Hi {userFirstName}, Welcome to Bearish OS!",
    body: "Flow along to learn about the power of Bearish OS, and how it can streamline your day.",
  },
  2: {
    header: "Intelligently Connect all your work",
    body: "Connect the apps you love to ensure you can start and end your day without leaving Bearish OS.",
  },
  3: {
    header: "Replace the single featured apps",
    body: "We believe apps should have the features you need built in to be successful. We replace 20+ apps with ease.",
  },
  4: {
    header: "Supercharged with BEBA",
    body: "Our own foundational AI system BEBA is at your service. Fully private, fully secure and never trained on your data.",
  },
};

interface WelcomeWalkthroughProps {
  userFirstName: string;
  setShowWelcome: (value: boolean) => void;
}

const WelcomeWalkthrough: React.FC<WelcomeWalkthroughProps> = ({
  userFirstName,
  setShowWelcome,
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const update_walkThroughState = async () => {
    try {
      const action = updateWalkthroughStats__api();

      await dispatch(action);
    } catch (error) {
      console.error("Error updating walkthrough state:", error);
    }
  };

  const closeHandler = (): void => {
    update_walkThroughState();

    localStorage.setItem("WelcomeWalkthrough", "false");
    setShowWelcome(false);
  };

  const skipHandler = (): void => closeHandler();

  const nextPageHandler = (): void => {
    if (currentPage < 4) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (currentPage === 4) {
      closeHandler();
    }
  };

  const backPageHandler = (): void => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const { header, body } = pageContents[currentPage] || {
    header: "",
    body: "",
  };

  return (
    <Box className={s["WelcomeCard"]}>
      <Stack direction="column">
        {/* Upper box with green background */}
        <Stack className={s["WelcomeCard__upperBox"]}>
          <Stack direction="row" justifyContent="flex-end" p={1}>
            <IconButton onClick={closeHandler}>
              <XCircleIcon color="white" />
            </IconButton>
          </Stack>
        </Stack>

        {/* Lower box with text */}
        <Stack className={s["WelcomeCard__lowerBox"]}>
          <Stack className={s["WelcomeCard__lowerBoxContent"]}>
            <Typography className={s["WelcomeCard__lowerBoxContentHeader"]}>
              {header.replace("{userFirstName}", userFirstName)}
            </Typography>
            <Typography className={s["WelcomeCard__lowerBoxContentBody"]}>
              {body}
            </Typography>
            {/* Contain button and progress bar here */}
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-around"
              sx={{ width: "100%" }}
            >
              <span className={s["WelcomeCard__TextBtn"]} onClick={skipHandler}>
                Skip
              </span>

              <Stack sx={{ flex: 1, padding: "0px 15px" }}>
                <BorderLinearProgress
                  sx={{ height: "10px" }}
                  highlightColor="#0B452A"
                  progressPercent={currentPage * 25}
                />
              </Stack>
              <span
                className={s["WelcomeCard__TextBtn"]}
                style={{
                  padding: "0px 15px",
                  visibility: currentPage === 1 ? "hidden" : "visible",
                }}
                onClick={backPageHandler}
              >
                Back
              </span>
              <ButtonSmall
                onClick={nextPageHandler}
                colorVarient={SmallButtonColor.Orange}
                label={currentPage === 4 ? "Done" : "Next"}
                types={ButtonType.Button}
                loaderLogin={false}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default WelcomeWalkthrough;
