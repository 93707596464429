import React from "react";
import { SearchMembers } from "../../../../common";
import { SearchUserCard } from "../SearchUserCard";

interface SearchBarUsersTraxProps {}

const SearchBarUsersTrax: React.FC<SearchBarUsersTraxProps> = () => {
  return (
    <SearchMembers isLoading>
      <SearchUserCard />
    </SearchMembers>
  );
};

export default SearchBarUsersTrax;
