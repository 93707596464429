import { PayloadAction } from "@reduxjs/toolkit";
import { AdminPortalState } from "../AdminPortalType";

// Generalized handler to update state based on action payload
const updateStateWithData = (
  state: AdminPortalState,
  action: PayloadAction<any>,
  additionalUpdates: (state: AdminPortalState, data: any) => void = () => { }
) => {
  const data = action.payload?.data;
  state.data = data;
  additionalUpdates(state, data);
};

// invite Users Success
export const inviteUsers__Success = (
  state: AdminPortalState,
  action: PayloadAction<any>
) => {
  updateStateWithData(state, action);
};

// Get All Invitations for Company Success
export const getAllInvitationsForCompany__Success = (
  state: AdminPortalState,
  action: PayloadAction<any>
) => {
  updateStateWithData(state, action, (state, data) => {
    state.invitedTeamMembers = data;
  });
};

// Get All Users for Company Success
export const getAllUserForCompany__Success = (
  state: AdminPortalState,
  action: PayloadAction<any>
) => {
  updateStateWithData(state, action, (state, data) => {
    state.teamMembers = data;
  });
};

// Create Team Success
export const createTeam__Success = (
  state: AdminPortalState,
  action: PayloadAction<any>
) => {
  updateStateWithData(state, action);
};

// Get All Teams for Company Success
export const getAllTeamsForCompany__Success = (
  state: AdminPortalState,
  action: PayloadAction<any>
) => {
  updateStateWithData(state, action, (state, data) => {
    state.teams = data?.teams;
  });
};

// Get Company Details Success
export const getCompanyDetails__Success = (
  state: AdminPortalState,
  action: PayloadAction<any>
) => {
  updateStateWithData(state, action, (state, data) => {
    state.companyInfo = data;
  });
};

// Update Company Details Success
export const updateCompanyDetails__Success = (
  state: AdminPortalState,
  action: PayloadAction<any>
) => {
  updateStateWithData(state, action);
};

// Add Company Domain Success
export const addCompanyDomain__Success = (
  state: AdminPortalState,
  action: PayloadAction<any>
) => {
  updateStateWithData(state, action);
};
