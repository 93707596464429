import { AuditingReports as AuditingReportsPage } from "./AuditingReports";
import { Compliance as CompliancePage } from "./Compliance";
import { Development as DevelopmentPage } from "./Development";
import { EmployeeDirectory as EmployeeDirectoryPage } from "./EmployeeDirectory";
import { Expenses as Expensespage } from "./Expenses";
import { Payroll as PayrollPage } from "./Payroll";
import { Reviews as ReviewsPage } from "./Reviews";
import { TimeAttendance as TimeAttendancePage } from "./TimeAttendance";

export type TraxPaths =
    | "/Trax/employee-directory"
    | "/Trax/time-attendance"
    | "/Trax/expenses"
    | "/Trax/payroll"
    | "/Trax/reviews"
    | "/Trax/development"
    | "/Trax/compliance"
    | "/Trax/auditing-reports";

export const TraxComponentMap: Record<TraxPaths, React.ComponentType> = {
    "/Trax/employee-directory": EmployeeDirectoryPage,
    "/Trax/time-attendance": TimeAttendancePage,
    "/Trax/expenses": Expensespage,
    "/Trax/payroll": PayrollPage,
    "/Trax/reviews": ReviewsPage,
    "/Trax/development": DevelopmentPage,
    "/Trax/compliance": CompliancePage,
    "/Trax/auditing-reports": AuditingReportsPage,
};
