import { UserTypeEnum } from "../../AdminConsolePageType";

interface AreaWidth {
    width: string;
    minWidth: string;
}

type HeaderNamesType = 'userArea' | 'lastActiveArea' | 'statusArea' | 'hierarchyArea' | 'actionsArea'

export type AllUserTableDataType = {
    _id: string;
    userName: string;
    userEmail: string;
    userIconSrc?: string;
    userAvatarColor?: string;
    lastActive: string;
    isInviteAccepted?: boolean;
    isInviteRejected?: boolean;
    userInvitationType: UserTypeEnum
}

export const allUserTableWidthProperty: Record<HeaderNamesType, AreaWidth> & {
    getMinWidth: () => string;
} = {
    userArea: { width: '45%', minWidth: '410px' },
    lastActiveArea: { width: '15%', minWidth: '165px' },
    statusArea: { width: '15%', minWidth: '165px' },
    hierarchyArea: { width: '15%', minWidth: '165px' },
    actionsArea: { width: '10%', minWidth: '145px' },

    getMinWidth() {
        return Object.values(this)
            .filter((value): value is AreaWidth => typeof value === 'object' && 'minWidth' in value)
            .reduce((sum, area) => sum + parseInt(area.minWidth, 10), 0) + 'px';
    },
};


export const allUserTableHeader: { translatedTitle: string, minWidth: string, widthPercentage: string }[] = [
    {
        minWidth: allUserTableWidthProperty.lastActiveArea.minWidth,
        widthPercentage: allUserTableWidthProperty.lastActiveArea.width,
        translatedTitle: "Last Active"
    },
    {
        minWidth: allUserTableWidthProperty.statusArea.minWidth,
        widthPercentage: allUserTableWidthProperty.statusArea.width,
        translatedTitle: "Status"
    },
    {
        minWidth: allUserTableWidthProperty.hierarchyArea.minWidth,
        widthPercentage: allUserTableWidthProperty.hierarchyArea.width,
        translatedTitle: "User Hierarchy"
    },
    {
        minWidth: allUserTableWidthProperty.actionsArea.minWidth,
        widthPercentage: allUserTableWidthProperty.actionsArea.width,
        translatedTitle: "Actions"
    }
]



export const DUMMY_ALL_INVITED_USERS_LIST: AllUserTableDataType[] = [
    {
        _id: '1',
        userName: 'John Doe',
        userEmail: 'john.doe@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/men/1.jpg',
        userAvatarColor: '#FF5733',
        lastActive: 'Dec 19, 2024',
        isInviteAccepted: true,
        userInvitationType: UserTypeEnum.ADMIN,
    },
    {
        _id: '2',
        userName: 'Jane Smith',
        userEmail: 'jane.smith@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/women/2.jpg',
        userAvatarColor: '#33FF57',
        lastActive: 'Dec 18, 2024',
        isInviteAccepted: false,
        userInvitationType: UserTypeEnum.MANAGER,
    },
    {
        _id: '3',
        userName: 'Alice Johnson',
        userEmail: 'alice.johnson@example.com',
        lastActive: 'Dec 15, 2024',
        isInviteAccepted: true,
        userInvitationType: UserTypeEnum.TEAM_LEAD,
    },
    {
        _id: '4',
        userName: 'Bob Brown',
        userEmail: 'bob.brown@example.com',
        userAvatarColor: '#3377FF',
        lastActive: 'Dec 14, 2024',
        isInviteAccepted: false,
        userInvitationType: UserTypeEnum.USER,
    },
    {
        _id: '5',
        userName: 'Sara Wilson',
        userEmail: 'sara.wilson@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/women/5.jpg',
        lastActive: 'Dec 20, 2024',
        isInviteAccepted: true,
        userInvitationType: UserTypeEnum.GUEST,
    },
    {
        _id: '6',
        userName: 'Mike Taylor',
        userEmail: 'mike.taylor@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/men/6.jpg',
        userAvatarColor: '#FF7733',
        lastActive: 'Dec 16, 2024',
        isInviteAccepted: true,
        userInvitationType: UserTypeEnum.AUDITOR,
    },
    {
        _id: '7',
        userName: 'Emily Davis',
        userEmail: 'emily.davis@example.com',
        // userAvatarColor: '#FFC300',
        lastActive: 'Dec 19, 2024',
        isInviteAccepted: false,
        userInvitationType: UserTypeEnum.ADMIN,
    },
    {
        _id: '8',
        userName: 'George Miller',
        userEmail: 'george.miller@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/men/8.jpg',
        lastActive: 'Dec 17, 2024',
        isInviteAccepted: false,
        userInvitationType: UserTypeEnum.MANAGER,
    },
    {
        _id: '9',
        userName: 'Sophia Anderson',
        userEmail: 'sophia.anderson@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/women/9.jpg',
        lastActive: 'Dec 21, 2024',
        isInviteAccepted: true,
        userInvitationType: UserTypeEnum.TEAM_LEAD,
    },
    {
        _id: '10',
        userName: 'Jack Moore',
        userEmail: 'jack.moore@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/men/10.jpg',
        lastActive: 'Dec 18, 2024',
        isInviteAccepted: true,
        userInvitationType: UserTypeEnum.USER,
    },
    {
        _id: '11',
        userName: 'Lily Evans',
        userEmail: 'lily.evans@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/women/11.jpg',
        userAvatarColor: '#FF33AA',
        lastActive: 'Dec 20, 2024',
        isInviteAccepted: false,
        userInvitationType: UserTypeEnum.GUEST,
    },
    {
        _id: '12',
        userName: 'Chris Hall',
        userEmail: 'chris.hall@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/men/12.jpg',
        lastActive: 'Dec 13, 2024',
        isInviteAccepted: true,
        userInvitationType: UserTypeEnum.AUDITOR,
    },
    {
        _id: '13',
        userName: 'Nina Black',
        userEmail: 'nina.black@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/women/13.jpg',
        lastActive: 'Dec 19, 2024',
        isInviteAccepted: true,
        userInvitationType: UserTypeEnum.MANAGER,
    },
    {
        _id: '14',
        userName: 'Samuel White',
        userEmail: 'samuel.white@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/men/14.jpg',
        lastActive: 'Dec 18, 2024',
        isInviteAccepted: false,
        userInvitationType: UserTypeEnum.ADMIN,
    },
    {
        _id: '15',
        userName: 'Olivia Lewis',
        userEmail: 'olivia.lewis@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/women/15.jpg',
        lastActive: 'Dec 14, 2024',
        isInviteAccepted: true,
        userInvitationType: UserTypeEnum.TEAM_LEAD,
    },
    {
        _id: '16',
        userName: 'Henry Lee',
        userEmail: 'henry.lee@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/men/16.jpg',
        lastActive: 'Dec 15, 2024',
        isInviteAccepted: false,
        userInvitationType: UserTypeEnum.USER,
    },
    {
        _id: '17',
        userName: 'Ella Harris',
        userEmail: 'ella.harris@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/women/17.jpg',
        lastActive: 'Dec 17, 2024',
        isInviteAccepted: true,
        userInvitationType: UserTypeEnum.AUDITOR,
    },
    {
        _id: '18',
        userName: 'David Martin',
        userEmail: 'david.martin@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/men/18.jpg',
        lastActive: 'Dec 16, 2024',
        isInviteAccepted: false,
        userInvitationType: UserTypeEnum.GUEST,
    },
    {
        _id: '19',
        userName: 'Grace Walker',
        userEmail: 'grace.walker@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/women/19.jpg',
        lastActive: 'Dec 20, 2024',
        isInviteAccepted: true,
        userInvitationType: UserTypeEnum.ADMIN,
    },
    {
        _id: '20',
        userName: 'Daniel Young',
        userEmail: 'daniel.young@example.com',
        userIconSrc: 'https://randomuser.me/api/portraits/men/20.jpg',
        userAvatarColor: '#FF33CC',
        lastActive: 'Dec 14, 2024',
        isInviteAccepted: false,
        userInvitationType: UserTypeEnum.USER,
    },
];
