import React from "react";

type AudioPlayerProps = {
  path: string;
};

const AudioPlayer: React.FC<AudioPlayerProps> = ({ path }) => {
  return (
    <div>
      <h3>Audio Player</h3>
      <audio controls>
        <source src={path} type="audio/mp3" />
        <source src={path} type="audio/wav" />
        <source src={path} type="audio/ogg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioPlayer;
