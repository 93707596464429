import React, { useEffect, useRef, useState } from "react";
import {
  ButtonSmall,
  MiniPopupContainer,
  MiniPopupContainerLightLabel,
  MiniPopupContainerNormalInput,
  SingleTeammateDropdown,
  SmallButtonColor,
  useCustomSnackbar,
} from "../../../../../../common";
import { DepartmentIcon } from "../../../../../../../images";
import { Stack } from "@mui/material";
import { TeamName } from "../TeamName";
import { useDispatch } from "react-redux";
import {
  invitationToJoinOrg__api,
  reCallAdminAPIsHandler,
} from "../../../../../../../redux";

interface AddManagerPopupProps {
  onCloseClick: () => void;
  users?: any[] | null;
}

const AddManagerPopup: React.FC<AddManagerPopupProps> = ({
  onCloseClick,
  users = [],
}) => {
  const dispatch = useDispatch();
  const enqueueSnackbar_v1 = useCustomSnackbar();
  const inputRef = useRef<HTMLInputElement | null>(null); // Reference for the input
  const [email, setEmail] = useState<string>("");
  const [adminId, setAdminId] = useState<string>("");
  const [teamId, setTeamId] = useState<any>("")

  const inviteManager = async () => {
    try {
      const payload: any = {
        email: email,
        role: "Manager",
        adminId: adminId,
        teamId: teamId
      };

      const action = invitationToJoinOrg__api(payload); // API call to invite admin to the organization

      const r: any = await dispatch(action); // Wait for the API call to complete

      if (r?.success) {
        dispatch(reCallAdminAPIsHandler("user_list")); // Recall all APIs to fetch the updated data
      }
    } catch (error) {
      console.error(error);
    } finally {
      onCloseClick();
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus to the input field when component mounts
    }
  }, []);

  // Handle email input change
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const transformedUsers = users
    ?.filter((user: any) => user?.userInvitationType === "Admin")
    ?.map((user: any) => {
      const [firstName, lastName] = user?.userName?.split?.(" ");
      return {
        id: user?._id,
        firstName,
        lastName,
        email: user?.userEmail,
        avatar: user?.userIconSrc,
      };
    });

  // Handle save button click
  const handleSaveClick = () => {
    if (email && adminId && teamId) {
      inviteManager(); // Close the popup
    } else {
      enqueueSnackbar_v1("warning", `Missing Email, team ID or Admin ID`, {
        position: { vertical: "top", horizontal: "center" },
        autoHideDuration: 5000,
      });
    }
  };

  return (
    <MiniPopupContainer
      headerIcon={DepartmentIcon}
      containerWidth="270px"
      headerTitle="Add a Manager"
      subtitle="You are about to add a manager to your Bearish OS account."
    >
      <div>
        <MiniPopupContainerLightLabel label="Email Address" />
        <MiniPopupContainerNormalInput
          inputRef={inputRef}
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter email address"
        />
      </div>

      <SingleTeammateDropdown
        options={transformedUsers as any}
        onSelect={(e: any) => {
          setAdminId(e?.id as any);
        }}
        label="Admin"
      />

      <div>
        <MiniPopupContainerLightLabel label="Team name" />
        <TeamName onTeamSelect={(e: any) => { setTeamId(e?._id) }} />
      </div>

      <Stack sx={{ alignItems: "center" }}>
        <ButtonSmall
          label="Save"
          colorVarient={SmallButtonColor.BearishClay}
          onClick={handleSaveClick}
        />
      </Stack>
    </MiniPopupContainer>
  );
};

export default AddManagerPopup;
