export type AttendanceStatusType =
    | "onTime"
    | "late"
    | "sick"
    | "holiday"
    | "pto"
    | "absent"
    | "dayoff"
    | "upcoming"


export type AttendanceHistoryStatusType =
    AttendanceStatusType | 'noShift'

/** Type for Attendance Status Colors */
export type AttendanceStatusColorListType = Record<AttendanceStatusType, string>;

export const AttendanceStatusColorLiist: AttendanceStatusColorListType = {
    onTime: "#21B481",
    late: "#FF0000",
    sick: "#FFB100",
    holiday: "#96A3ED",
    pto: "#660ED7",
    absent: "#D9D9D9",
    dayoff: "#B5005B",
    upcoming: "#E9EDF2",
};
