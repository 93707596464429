import { InputBase, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./DetailsTab.module.css";
import { EditableTextArea } from "../../../../../../common";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../../../redux/store";
import {AuthState} from "../../../../../../../redux";
import { useTranslation } from "react-i18next";
import {updateConnections} from "../../../../../../../redux/Slice/message/messageActions";

interface DetailsTabProps {
  channelDetails?: any;
}

const DetailsTab: React.FC<DetailsTabProps> = ({
  channelDetails,
}) => {
  const {t}=useTranslation()
  const dispatch  = useDispatch();
  const [channelData, setChannelData] = useState({
    chName: channelDetails?.displayName || "",
    topicName: channelDetails?.topic || "",
    chDescription: channelDetails?.details || "",
  });

  const { user } = useSelector((state: RootState) => state?.auth as AuthState);
  const [participantDetail, setParticipantDetail] = useState<any>({});

  const handleInputChange = (field: string, value: string) => {
    setChannelData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const HandleUpdateChannelDetails = async (channelDetailData: any) => {
    if (!channelDetailData?.chName && !channelDetailData?.chDescription) return;
    if (
        channelDetailData?.chName ||
        channelDetailData?.topicName ||
        channelDetailData?.chDescription
    ) {
      const payload = {
        name: channelDetailData?.chName,
        topic: channelDetailData?.topicName,
        details: channelDetailData?.chDescription,
      };
      try {
        await dispatch(updateConnections(channelDetails?._id, payload));
      } catch (error) {
        console.error("Failed to update channel details:", error);
      }
    }
  };

  useEffect(() => {
    if (
        channelData?.chName ||
        channelData?.topicName ||
        channelData?.chDescription
    ) {
      const timer = setTimeout(() => {
        HandleUpdateChannelDetails(channelData);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [channelData]);

  useEffect(() => {
    const participantData = channelDetails?.participants?.filter((x:any)=> x.userId === user?.data?._id)?.[0]
    setParticipantDetail(participantData)
  }, []);

  return (
    <Stack className={styles["details-tab"]}>
      {/* Channel Name */}
      <Stack className={styles["details-tab__section"]}>
        <Typography className={styles["details-tab__label"]}>
          {t('MESSAGE.CHANNEL_NAME')}
        </Typography>
        <InputBase
          value={channelData.chName}
          onChange={(e) => handleInputChange("chName", e.target.value)}
          className={styles["details-tab__value"]}
          placeholder={t('MESSAGE.CHANNEL_NAME_HERE')}
          disabled={participantDetail?.role?.toUpperCase() !== 'ADMIN'}
        />
      </Stack>

      {/* Channel Topic */}
      <Stack className={styles["details-tab__section"]}>
        <Typography className={styles["details-tab__label"]}>
          {t('MESSAGE.CHANNEL_TOPIC')}
        </Typography>
        <InputBase
          value={channelData.topicName}
          onChange={(e) => handleInputChange("topicName", e.target.value)}
          className={styles["details-tab__value"]}
          placeholder= {t('MESSAGE.CHANNEL_TOPIC')}
          disabled={participantDetail?.role?.toUpperCase() !== 'ADMIN'}
        />
      </Stack>

      {/* Channel Description */}
      <Stack className={styles["details-tab__section"]}>
        <Typography className={styles["details-tab__label"]}>
          {t('MESSAGE.CHANNEL_DESCRIPTION')}
        </Typography>
        <EditableTextArea
          placeholder= {t('MESSAGE.DESCRIPTION_HERE')}
          text={channelData.chDescription}
          onTextChange={(newText) =>
            handleInputChange("chDescription", newText)
          }
          disabled={participantDetail?.role?.toUpperCase() !== 'ADMIN'}
          style={{
            font: "normal normal 600 14px/18px Source Serif Pro",
            color: "black",
          }}
        />
      </Stack>

      {/* Created By */}
      <Stack className={styles["details-tab__section"]}>
        <Typography className={styles["details-tab__label"]}>
          {t('MESSAGE.CREATED_BY')}
        </Typography>
        <Stack sx={{ flexDirection: "row", flexWrap: "wrap", gap: "2px 5px" }}>
          <Typography className={styles["details-tab__value"]}>
            {channelDetails?.creatorName}
          </Typography>
        </Stack>
      </Stack>

      {/* Channel ID */}
      <Stack className={styles["details-tab__section"]}>
        <Typography className={styles["details-tab__label"]}>
          {t('MESSAGE.CHANNEL_ID')}
        </Typography>
        <Typography className={styles["details-tab__value"]}>
          {channelDetails?._id}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default DetailsTab;
