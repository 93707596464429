import {Box, Stack} from "@mui/material";
import React, {useRef, useState} from "react";
import {ActionButton, IconActionButton} from "./view";
import {CameraIcon, MicIcon, PauseBoldIcon, PlayIcon, StopBoldIcon, TvRedIcon,} from "../../../../../images";
import PostScreenRecordingDetails from "../PostScreenRecordingDetails/PostScreenRecordingDetails";
import LiveCameraPreviewArea from "../../../../common/LiveCameraPreviewArea/LiveCameraPreviewArea";
import {AuthState, uploadFile__api} from "../../../../../redux";
import {useDispatch, useSelector} from "react-redux";
import {createScreenRecording__api} from "../../../../../redux/Slice/Global/globalActions";
import {RootState} from "../../../../../redux/store";

export type DeviceStatusType = "unTouch" | "active" | "inActive";
type RecordingStatusType = "default" | "started" | "pause" | "stopped";

interface ScreenRecordingLiveProps {
    handleCloseScreenRecordingSection: () => void;
}

const ScreenRecordingLive: React.FC<ScreenRecordingLiveProps> = ({handleCloseScreenRecordingSection}) => {
    const [recordingStatus, setRecordingStatus] = useState<RecordingStatusType>("default");
    const [screenStatus, setScreenStatus] = useState<DeviceStatusType>("unTouch");
    const [cameraStatus, setCameraStatus] = useState<DeviceStatusType>("unTouch");
    const [micStatus, setMicStatus] = useState<DeviceStatusType>("unTouch");
    const [openRecordingDetailsCard, setOpenRecordingDetailsCard] = useState<boolean>(false);
    const [isCameraActive, setIsCameraActive] = useState<boolean>(false);
    const [screenVideoStream, setScreenVideoStream] = useState<string | null>(null);
    const [selectedProjectsArray, setSelectedProjectsArray] = useState<any>([]);
    const [selectedWorkSpaceArray, setSelectedWorkSpaceArray] = useState<any>([]);
    const [selectedFolderArray, setSelectedFolderArray] = useState<any>([]);
    const [videoTitle, setVideoTitle] = useState<string>("");
    const [videoDescription, setVideoDescription] = useState<string>("");
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const recordedChunksRef = useRef<Blob[]>([]);
    const [micRecordingStatus, setMicRecordingStatus] = useState<RecordingStatusType>("default");
    const micMediaRecorderRef = useRef<MediaRecorder | null>(null);
    const micRecordedChunksRef = useRef<Blob[]>([]);
    const micStreamRef = useRef<MediaStream | null>(null);
    const audioStreamRef = useRef<MediaStream | null>(null);
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.auth as AuthState);

    const checkDisableMenu = (status: RecordingStatusType): boolean => {
        if (recordingStatus === "default" && status !== "started") return true;
        if (recordingStatus === "started" && status === "started") return true;
        if (recordingStatus === "pause" && status === "pause") return true;
        return recordingStatus === "stopped";
    };

    const handleStopRecording = () => {
        setOpenRecordingDetailsCard(true);
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setRecordingStatus("stopped");
        }
    };

    const handlePauseRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.pause();
            setRecordingStatus("pause");
        }
    };

    const handleSaveRecordingDetails = async () => {
        if (recordedChunksRef.current.length > 0) {
            const blob = new Blob(recordedChunksRef.current, {type: "video/webm"});
            const file = new File([blob], "recording.webm", {type: "video/webm"});

            const formData = new FormData();
            formData.append("files", file);

            const action = uploadFile__api(formData);
            try {
                const response: any = await dispatch(action);
                if (response?.success && response?.data[0].path) {
                    const payload = {
                        title: videoTitle || "Untitled",
                        recordingUrl: response?.data[0].path,
                        description: videoDescription || "",
                        connectedProject: selectedProjectsArray || [],
                        connectedWorkspace: selectedWorkSpaceArray || [],
                        connectedFolder: selectedFolderArray || [],
                    };
                    dispatch(createScreenRecording__api(payload));
                    setRecordingStatus("default");
                    setOpenRecordingDetailsCard(false);
                    setVideoTitle("");
                    setSelectedWorkSpaceArray([]);
                    setSelectedProjectsArray([]);
                    setSelectedFolderArray([]);
                    setVideoDescription("");
                    setIsCameraActive(false);
                    // Stop all streams: audio, video, mic, and screen share
                    const stopMediaStream = (streamRef:any) => {
                        if (streamRef?.current) {
                            const tracks = streamRef.current.getTracks();
                            tracks.forEach((track:any) => track.stop());
                            streamRef.current = null;
                        }
                    };

                    // Stop audio stream
                    stopMediaStream(audioStreamRef);

                    // Stop video stream (camera)
                    if (isCameraActive && videoRef.current) {
                        const stream = videoRef.current.srcObject as MediaStream;
                        stopMediaStream({ current: stream });
                    }

                    // Stop mic stream
                    stopMediaStream(micStreamRef);
                    if (micMediaRecorderRef.current?.stream) {
                        stopMediaStream({ current: micMediaRecorderRef.current.stream });
                    }

                    // Stop screen sharing stream
                    if (mediaRecorderRef.current?.stream) {
                        stopMediaStream({ current: mediaRecorderRef.current.stream });
                    }

                    // Stop MediaRecorder instances
                    mediaRecorderRef?.current?.stop();
                    micMediaRecorderRef?.current?.stop();
                }
                handleCloseScreenRecordingSection();

            } catch (e) {
                console.log(e, "Error uploading");
            }
        }
    };

    const handleScreenRecordClick = async () => {
        if (recordingStatus === "started") {
            mediaRecorderRef.current?.pause();
            setRecordingStatus("pause");
        } else if (recordingStatus === "pause") {
            mediaRecorderRef.current?.resume();
            setRecordingStatus("started");
        } else {
            const screenStream = await navigator.mediaDevices.getDisplayMedia({video: true});
            const audioStream = await navigator.mediaDevices.getUserMedia({audio: true});
            audioStreamRef.current = audioStream;

            const combinedStream = new MediaStream([
                ...screenStream.getVideoTracks(),
                ...audioStream.getAudioTracks()
            ]);

            const mediaRecorder = new MediaRecorder(combinedStream);
            mediaRecorderRef.current = mediaRecorder;
            recordedChunksRef.current = [];

            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    recordedChunksRef.current.push(event.data);
                }
            };

            mediaRecorder.onstop = () => {
                const blob = new Blob(recordedChunksRef.current, {type: "video/webm"});
                const videoStream = URL.createObjectURL(blob);
                setScreenVideoStream(videoStream);
            };

            mediaRecorder.start();
            setRecordingStatus("started");
        }
    };

    const handleSelectedProjects = (selectedProjects: any) => {
        if (selectedProjects?._id) {
            setSelectedProjectsArray([...selectedProjectsArray, selectedProjects?._id]);
        }
    }

    const handleSelectedWorkSpace = (selectedWorkSpace: any) => {
        if (selectedWorkSpace?._id) {
            setSelectedWorkSpaceArray([...selectedWorkSpaceArray, selectedWorkSpace?._id]);
        }
    }

    const handleSelectedFolder = (selectedFolder: any) => {
        if (selectedFolder?._id) {
            setSelectedFolderArray([...selectedFolderArray, selectedFolder?._id]);
        }
    }

    const handleTitleChange = (value: string) => {
        setVideoTitle(value);
    }

    const handleDescriptionChange = (value: string) => {
        setVideoDescription(value);
    }

    const handleMicClick = async (value: DeviceStatusType) => {
        if (value === "active") {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true});
            micStreamRef.current = stream;

            const mediaRecorder = new MediaRecorder(stream);
            micMediaRecorderRef.current = mediaRecorder;
            micRecordedChunksRef.current = [];

            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    micRecordedChunksRef.current.push(event.data);
                }
            };

            mediaRecorder.start();
            setMicRecordingStatus("started");
        } else if (value === "inActive" && micMediaRecorderRef.current) {
            if (micRecordingStatus === "started") {
                micMediaRecorderRef.current.pause();
                setMicRecordingStatus("pause");
            } else if (micRecordingStatus === "pause") {
                micMediaRecorderRef.current.resume();
                setMicRecordingStatus("started");
            }
        } else if (value === "unTouch" && micStreamRef.current) {
            micStreamRef.current.getTracks().forEach(track => track.stop());
            micStreamRef.current = null;
            micMediaRecorderRef.current?.stop();
            setMicRecordingStatus("default");
        }
        setMicStatus(value);
    };

    const handleBackClick = () => {
        setOpenRecordingDetailsCard(false)
        setRecordingStatus("default");
        setVideoTitle("");
        setSelectedWorkSpaceArray([]);
        setSelectedProjectsArray([]);
        setVideoDescription("");
        setIsCameraActive(false);
    }

    const handleShareButtonClick = () => {
        console.log("Share button clicked");
    }

    return (
        <Stack
            sx={{
                width: "60px",
                position: "fixed",
                bottom: "150px",
                right: "7px",
                height: "474px",
                borderRadius: "5px 0px 0px 5px",
                boxSizing: "border-box",
                bgcolor: "white",
                boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.25)",
                padding: "7px 5px 5px 5px",
                alignItems: "center",
                justifyContent: "start",
                gap: "15px",
            }}
        >
            <Stack sx={{gap: "10px"}}>
                <ActionButton
                    activeColor="#21B481"
                    buttonLabel="Start"
                    icon={PlayIcon}
                    isActive={recordingStatus === "started"}
                    disabled={checkDisableMenu("started")}
                    onClick={handleScreenRecordClick}
                />
                <ActionButton
                    activeColor="#FFB100"
                    buttonLabel="Pause"
                    icon={PauseBoldIcon}
                    isActive={recordingStatus === "pause"}
                    disabled={checkDisableMenu("pause")}
                    onClick={handlePauseRecording}
                />
                <ActionButton
                    activeColor="#FF0000"
                    buttonLabel="Stop"
                    icon={StopBoldIcon}
                    isActive={recordingStatus === "stopped"}
                    disabled={checkDisableMenu("stopped")}
                    onClick={handleStopRecording}
                />
            </Stack>
            <Stack sx={{width: "100%", borderTop: "2px solid #E9EDF2"}}/>
            <Stack sx={{gap: "10px"}}>
                <IconActionButton
                    buttonLabel="camera"
                    icon={CameraIcon}
                    buttonState={cameraStatus}
                    onButtonClick={(value) => {
                        setCameraStatus(value)
                        setIsCameraActive(value === "active");
                    }}
                />
                <IconActionButton
                    buttonLabel="Screen"
                    icon={TvRedIcon}
                    buttonState={screenStatus}
                    onButtonClick={handleScreenRecordClick}
                />
                <IconActionButton
                    buttonLabel="Mic"
                    icon={MicIcon}
                    buttonState={micStatus}
                    onButtonClick={handleMicClick}
                />
            </Stack>
            {
                openRecordingDetailsCard &&
                (
                    <Box style={{
                        backgroundColor: "white",
                        height: "700px",
                        position: "fixed",
                        top: "30px",
                        right: "100px"
                    }}>
                        <PostScreenRecordingDetails
                            handleOnShareButtonClick={handleShareButtonClick}
                            handleBackButtonClick={handleBackClick}
                            screenVideoStream={screenVideoStream}
                            saveRecordingDetails={handleSaveRecordingDetails}
                            handleSelectedProjects={handleSelectedProjects}
                            handleSelectedWorkSpace={handleSelectedWorkSpace}
                            handleSelectedFolder={handleSelectedFolder}
                            setRecordingTitle={handleTitleChange}
                            setRecordingDescription={handleDescriptionChange}
                        />
                    </Box>
                )
            }
            <Box style={{
                position: "fixed",
                bottom: "20px",
                left: "67px",
                zIndex: 9999,
            }}>
                <LiveCameraPreviewArea isCameraActive={isCameraActive} userDetails={user?.data} />
            </Box>
        </Stack>
    );
};

export default ScreenRecordingLive;