import { DropResult } from "react-beautiful-dnd";
import { Dispatch } from "redux";
import { updateTaskPosition } from "../../../../../../../redux/Slice/workspace/workspaceSlice";
export const handleDragEnd__boardCore = (
    result: DropResult,
    userAllTasks: any[] = [],
    dispatch: Dispatch
) => {
    const { source, destination, draggableId } = result;

    // Exit if no valid destination
    if (!destination) {
        return;
    }

    // Extract source and destination group names and task indices
    const sourceGroupName = source.droppableId;
    const destinationGroupName = destination.droppableId;
    const sourceIndex = source.index;
    const destinationIndex = destination.index;

    // Find the source and destination groups
    const sourceGroup = userAllTasks.find(
        (group) => group.groupName === sourceGroupName
    );
    const destinationGroup = userAllTasks.find(
        (group) => group.groupName === destinationGroupName
    );

    if (!sourceGroup || !destinationGroup) {
        return;
    }

    // Find the source task by its draggableId in the source group
    const sourceTask = sourceGroup.tasks.find(
        (task: any) => task._id === draggableId
    );
    if (!sourceTask) {
        return;
    }

    let updatedUserAllTasks;

    // If the task is moved to a different group
    if (sourceGroupName !== destinationGroupName) {
        const updatedSourceTasks = [...sourceGroup.tasks];
        updatedSourceTasks.splice(sourceIndex, 1);

        const updatedDestinationTasks = [
            ...destinationGroup.tasks.slice(0, destinationIndex),
            sourceTask,
            ...destinationGroup.tasks.slice(destinationIndex),
        ];

        const updatedSourceGroup = { ...sourceGroup, tasks: updatedSourceTasks };
        const updatedDestinationGroup = {
            ...destinationGroup,
            tasks: updatedDestinationTasks,
        };

        updatedUserAllTasks = userAllTasks.map((group) =>
            group.groupName === sourceGroupName
                ? updatedSourceGroup
                : group.groupName === destinationGroupName
                    ? updatedDestinationGroup
                    : group
        );
    } else {
        // If the task is dropped within the same group, reorder tasks
        const updatedSourceTasks = [...sourceGroup.tasks];
        const [movedTask] = updatedSourceTasks.splice(sourceIndex, 1);
        updatedSourceTasks.splice(destinationIndex, 0, movedTask);

        const updatedSourceGroup = { ...sourceGroup, tasks: updatedSourceTasks };

        updatedUserAllTasks = userAllTasks.map((group) =>
            group.groupName === sourceGroupName ? updatedSourceGroup : group
        );
    }

    // Dispatch the updated userAllTasks after the task position change
    dispatch(updateTaskPosition({ userAllTasks: updatedUserAllTasks }));
};
