import { Box, Stack } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import PopupupCloudStorageDocument from "../../../../../common/PopupupCloudStorageDocument/PopupupCloudStorageDocument";
import PaginationButtons from "./PaginationButtons";
import { useDispatch } from "react-redux";
import { changeLayoutState } from "../../../../../../redux";
// Set the workerSrc to the correct path
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;

const PdfViewer = ({ path, rightPanelOpen }) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1); // For zooming functionality
  const [sidebarVisible, setSidebarVisible] = useState(true); // For sidebar toggling
  const [fullScreen, setFullScreen] = useState(false); // Full screen toggle
  const [error, setError] = useState("");
  const pdfRef = useRef(); // Reference to the PDF container
  const dispatch = useDispatch();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onDocumentLoadError = (error) => {
    setError("Failed to load PDF file.");
    console.error(error);
  };

  const handlePageChange = (newPageNumber) => {
    if (newPageNumber >= 1 && newPageNumber <= numPages) {
      setPageNumber(newPageNumber);
    }
  };

  const toggleDrawer = () => {
    dispatch(
      changeLayoutState({
        type: "isDrawer_CloudStorage",
        value: sidebarVisible ? true : false,
      })
    );
  };
  useEffect(() => {
    // Scroll to the selected page when pageNumber changes
    if (pdfRef.current) {
      const pageElement = pdfRef.current.querySelector(
        `[data-page-number='${pageNumber}']`
      );
      if (pageElement) {
        pageElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [pageNumber]);

  return (
    <Stack
      style={{
        height: "100vh",
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {error && <div style={{ color: "red" }}>{error}</div>}

      <div
        ref={pdfRef}
        style={{
          marginLeft: sidebarVisible ? "200px" : 0,
          transition: "margin-left 0.3s",
          flexGrow: 1,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Document
          file={path || require("./abc.pdf")}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          style={{ width: "100%" }}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <div
              key={`page_${index + 1}`}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <Page
                pageNumber={index + 1}
                scale={zoom}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          ))}
        </Document>
      </div>

      <Box
        sx={{
          position: "fixed",
          bottom: "25px",
          left: rightPanelOpen ? "8%" : "30%",
        }}
      >
        <PopupupCloudStorageDocument
          numPages={numPages}
          onAction={(actionName, actionValue) => {
            switch (actionName) {
              case "zoomChange":
                setZoom(actionValue);
                break;
              case "sidebar":
                setSidebarVisible(!sidebarVisible);
                toggleDrawer()
                break;
              case "fullScreen":
                setFullScreen(!fullScreen);
                if (fullScreen) {
                  document.exitFullscreen();
                } else {
                  document.documentElement.requestFullscreen();
                }
                break;
              case "pageChange":
                handlePageChange(actionValue);
                break;
              default:
                console.log("Unknown action:", actionName);
            }
          }}
        />
      </Box>

      {sidebarVisible && (
        <PaginationButtons
          numPages={numPages}
          selectedPage={pageNumber}
          handlePageChange={handlePageChange}
          sidebarVisible={sidebarVisible}
          path={path || require("./abc.pdf")} // Pass the PDF file
        />
      )}
    </Stack>
  );
};

export default PdfViewer;
