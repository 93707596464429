import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { getFileIcon } from "../../../../../Utils";
import {
  PauseBoldIcon,
  PlayCircleIcon,
  StopBoldIcon,
} from "../../../../../images";

interface MsgAudioMiniPreviewProps {}

const MsgAudioMiniPreview: React.FC<MsgAudioMiniPreviewProps> = ({}) => {
  return (
    <Stack
      sx={{
        height: "35px",
        flexShrink: 0,
        borderRadius: "5px",
        border: "1px solid #E9EDF2",
        boxSizing: "border-box",
        padding: "0px 5px 0px 10px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          maxWidth: "calc(100% - 130px)",
        }}
      >
        {getFileIcon({ name: "file.mp3", iconSize: 25 })}
        <Typography
          sx={{
            font: "normal normal 300 12px/15px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          Audio file name Lorem ipsum dolor sit amet consectetur adipisicing
          elit. A, doloremque!
        </Typography>
      </Stack>

      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <Typography
          sx={{ font: "normal normal 300 12px/15px Source Serif Pro" }}
        >
          00:00
        </Typography>

        {/* <IconButton>
    <PauseBoldIcon w={20} />
    </IconButton> */}

        <IconButton>
          <PlayCircleIcon w={15} />
        </IconButton>

        <IconButton>
          <StopBoldIcon w={15} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default MsgAudioMiniPreview;
