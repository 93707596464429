/**
 * This file contains action creators for workspace related actions.
 */

import { createApiAction } from "../constantApi_config";

import * as email_slice_apiActions from "./emailSlice";

/* getAllEmails__api */
/* Done */
export const getAllEmails__api = (accessToken: string, query: string) => {
    const menuArray = [
        "Starred",
        "Snoozed",
        "Sent",
        "Drafts",
        "Scheduled",
        "Spam",
        "Archives",
        "Trash",
        "Inbox",
    ];

    const queryMatchesMenu = menuArray.some((item) => item === query);

    const url = queryMatchesMenu
        ? `/email/folder-emails?tab=${query}`
        : query
            ? `/email/smart-filter-emails/${query}`
            : "/email/emails";

    return createApiAction({
        axiosRequired: true,
        method: "POST",
        url,
        data: { accessToken },
        onStart: email_slice_apiActions.getAllEmails__RequestAction.type,
        onSuccess: email_slice_apiActions.getAllEmails__SuccessAction.type,
        onError: email_slice_apiActions.getAllEmails__FailureAction.type,
    });
};

export const DeleteEmail__api = (accessToken: string, messageId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: "/email/move-email",
        data: { accessToken, messageId },
        onStart: email_slice_apiActions.deleteEmail__RequestAction.type,
        onSuccess: email_slice_apiActions.deleteEmail__SuccessAction.type,
        onError: email_slice_apiActions.deleteEmail__FailureAction.type,
    });

export const getMailSettings__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: "/email/settings",
        onStart: email_slice_apiActions.getMainSettings__RequestAction.type,
        onSuccess: email_slice_apiActions.getMainSettings__SuccessAction.type,
        onError: email_slice_apiActions.getMainSettings__FailureAction.type,
    });

export const updateMailSettings__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: "/email/settings",
        data: payload,
        onStart: email_slice_apiActions.updateMainSettings__RequestAction.type,
        onSuccess: email_slice_apiActions.updateMainSettings__SuccessAction.type,
        onError: email_slice_apiActions.updateMainSettings__FailureAction.type,
    });
export const getEmailFilters__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: "/email/filter",
        onStart: `email_slice_apiActions.get_filters__RequestAction.type`,
        onSuccess: email_slice_apiActions.get_filters__SuccessAction.type,
        onError: `email_slice_apiActions.get_filters__FailtureAction.type`,
    });
