import {
  Stack,
  Typography,
  Popper,
  Paper,
  SxProps,
  IconButton,
} from "@mui/material";
import React, { useState, useRef } from "react";
import styles from "./UserInfoChip.module.css";
import { AvatarNormal, SmallContactCardHover } from "../../../../../common";
import { UserInfoChipProps } from "./UserInfoChipType";
import { XCircleIcon } from "../../../../../../images";

interface UserinfoType extends UserInfoChipProps {
  popoverPlace: "bottom" | "right" | "top";
  showOnlyName?: boolean;
  fontSx?: SxProps;
  containerSx?: SxProps;
  userFirstName?: string;
  chipHeight?: "20px" | "30px";
  chippMaxWidth?: string;
  closeButtonClick?: () => void;
  popoverZindex?: number;
}

const UserInfoChip: React.FC<UserinfoType> = ({
  popoverPlace,
  showOnlyName = false,
  fontSx,
  containerSx,
  userEmail,
  userName,
  userFirstName,
  userAvatarColor,
  userIconSrc,
  chipHeight = "20px",
  chippMaxWidth,
  closeButtonClick,
  popoverZindex = 10,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Reference to store the timeout ID

  const handleChipHover = (event: React.MouseEvent<HTMLElement>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Clear the existing timeout when the mouse re-enters
    }
    setAnchorEl(event.currentTarget);
  };

  const handleChipLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setAnchorEl(null);
    }, 50); // Start the timeout when the mouse leaves
  };

  // Function to get Popper placement based on the popoverPlace prop
  const getPopperPlacement = () => {
    switch (popoverPlace) {
      case "bottom":
        return "bottom-start";
      case "right":
        return "right-start";
      case "top":
        return "top-start";
      default:
        return "bottom-start"; // Default value if none of the cases match
    }
  };

  return (
    <div onMouseEnter={handleChipHover} onMouseLeave={handleChipLeave}>
      {showOnlyName ? (
        <Typography
          className={styles.userInfoChip__text}
          sx={{
            cursor: "pointer",
            font: "normal normal 300 11px/14px Source Serif Pro",
            ...fontSx,
          }}
        >
          {userFirstName || userName}
        </Typography>
      ) : (
        <Stack
          className={styles.userInfoChip}
          sx={{ height: chipHeight, maxWidth: chippMaxWidth, ...containerSx }}
        >
          <AvatarNormal
            size={chipHeight === "20px" ? 15 : 20}
            username={userName}
            avatarColor={userAvatarColor}
            imgSrc={userIconSrc}
          />
          <Typography
            className={styles.userInfoChip__text}
            sx={{
              font: "normal normal 300 11px/14px Source Serif Pro",
              ...fontSx,
            }}
          >
            {userFirstName || userName}
          </Typography>

          {closeButtonClick && (
            <IconButton onClick={closeButtonClick} sx={{ p: 0 }} disableRipple>
              <XCircleIcon w={15} />
            </IconButton>
          )}
        </Stack>
      )}

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={getPopperPlacement()}
        onMouseLeave={handleChipLeave}
        sx={{
          zIndex: popoverZindex,
          border: "0px",
          borderRadius: "5px",
        }}
      >
        <Paper
          sx={{
            boxShadow: "none",
            padding: "1px 1px 15px 1px",
            bgcolor: "inherit",
          }}
        >
          <SmallContactCardHover
            userEmail={userEmail}
            userName={userName}
            userIconSrc={userIconSrc}
            userAvatarColor={userAvatarColor}
          />
        </Paper>
      </Popper>
    </div>
  );
};

export default UserInfoChip;
