import { IconButton, Stack } from "@mui/material";
import React from "react";
import { AllDirArrowCircleIcon } from "../../../../../images";
import { formatDateInMonthName } from "../../components";

interface AttendanceTableDateViewProps {
  previousButtonClick: () => void;
  nextButtonClick: () => void;
  startTime: string;
  endTime?: string;
}

const AttendanceTableDateView: React.FC<AttendanceTableDateViewProps> = ({
  endTime,
  nextButtonClick,
  previousButtonClick,
  startTime,
}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        width: "360px",
        justifyContent: "space-between",
      }}
    >
      <IconButton onClick={previousButtonClick}>
        <AllDirArrowCircleIcon direction="left" w={20} />
      </IconButton>

      <Stack sx={{ font: "normal normal 600 16px/20px Source Serif Pro" }}>
        {startTime && formatDateInMonthName(startTime)}{" "}
        {endTime && endTime !== startTime && `- ${formatDateInMonthName(endTime)}`}
      </Stack>

      <IconButton onClick={nextButtonClick}>
        <AllDirArrowCircleIcon direction="right" w={20} />
      </IconButton>
    </Stack>
  );
};

export default AttendanceTableDateView;
