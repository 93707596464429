import React from "react";
import { Box } from "@mui/material";

const ImageViewer: React.FC<{ path: string }> = ({ path }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh", // Full screen height
      width: "100%", // Full screen width
      overflow: "hidden", // Prevents scrolling if the image is larger than viewport
      backgroundColor: "#fff", // Optional: light background for clarity
    }}
  >
    <img
      src={path}
      alt="Preview"
      style={{
        maxWidth: "100%", // Ensures the image doesn't overflow
        maxHeight: "100%", // Ensures the image doesn't overflow
        width: "auto", // Maintains the image's natural width
        height: "auto", // Maintains the image's natural height
      }}
    />
  </Box>
);

export default ImageViewer;
