import { Box, Stack } from "@mui/material";
import React from "react";
import { RedirectServiceImgName, renderIconPng } from "./IconContainerType";

interface IconsContainerProps {
  imgName: RedirectServiceImgName;
  borderColor?: string;
}

const IconsContainer: React.FC<IconsContainerProps> = ({
  imgName,
  borderColor = "#000000",
}) => {
  return (
    <Stack
      sx={{
        height: "100px",
        width: "100px",
        borderRadius: "5px",
        border: `3px solid ${borderColor} `,
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        bgcolor: "white",
      }}
    >
      <Box
        component={"img"}
        src={renderIconPng(imgName)}
        alt="logo"
        sx={{ height: "65px", width: "65px" }}
      />
    </Stack>
  );
};

export default IconsContainer;
