import React from "react";
import {
  TextNormalDropdown,
  TextNormalDropdownOptionsType,
} from "../../../../common";

const DUMMY_ScopeDropDownOptions: TextNormalDropdownOptionsType[] = [
  { _id: "1", title: "Company Wide", value: "companyWide" },
  { _id: "2", title: "Specific Department(s)", value: "specificDepartments" },
  { _id: "3", title: "Specific Team(s)", value: "specificTeams" },
  {
    _id: "4",
    title: "Specific Region/Country",
    value: "specificRegionCountry",
  },
  { _id: "5", title: "Custom", value: "custom" },
];

interface ScopeDropDownProps {}

const ScopeDropDown: React.FC<ScopeDropDownProps> = () => {
  return (
    <TextNormalDropdown
      placeholder="Select scope..."
      // initialValue={DUMMY_ScopeDropDownOptions[3]}
      options={DUMMY_ScopeDropDownOptions}
      onSelect={(value) => console.log(value)}
    />
  );
};

export default ScopeDropDown;
