import React from "react";
import { ScheduleTableUserViewInterface } from "../AttendanceScheduleTableUserViewType";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  MonthViewDateArea,
  StatusViewCard,
  WeekCountView,
} from "../../AttendanceScheduleTable/view";

const ScheduleTableMonthUserView: React.FC<ScheduleTableUserViewInterface> = ({
  tableData,
}) => {
  const empData = tableData.employeeList;

  return (
    <Stack
      sx={{
        height: "100%",
        overflowX: "auto",
        scrollbarWidth: "none",
        boxSizing: "border-box",
      }}
    >
      <TableContainer
        sx={{
          height: "100%",
          scrollbarWidth: "none",
          boxShadow: "none",
          borderRadius: 0,
        }}
        component={Paper}
      >
        <Table stickyHeader>
          {/* Header */}
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderBottom: "2px solid #E9EDF2",
                  height: "90px",
                  padding: 0,
                  boxSizing: "border-box",
                  minHeight: 0,
                }}
              >
                <Stack sx={{ flexDirection: "row", height: "100%" }}>
                  {/* ------- select-all checkbox here ------- */}
                  {/* <TableHeaderSelectAllContainer /> */}

                  {/* All dates and week-one/ week-two/ ... list */}
                  <Stack
                    sx={{
                      // paddingLeft: "17px",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* date list like sun-01 / mon-02 / tue-03.... */}
                    <Stack
                      sx={{
                        flexDirection: "row",
                        gap: "2px",
                      }}
                    >
                      {tableData.dateList.map((date, index) => (
                        <MonthViewDateArea dateList={date} key={index} />
                      ))}
                    </Stack>

                    {/* week-one / week-two / week-three.... */}
                    <WeekCountView dateList={tableData.dateList} />
                  </Stack>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>

          {/* Body */}
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  height: "fit-content",
                  padding: 0,
                  boxSizing: "border-box",
                  minHeight: "150px",
                  display: "flex",
                }}
              >
                <Stack sx={{ flexDirection: "row", height: "100%" }}>
                  {/* ------Fixed (sticky) Employee name + check-box and details Column ------ */}
                  {/* <EmployeeDetailsInfoView empData={empData} /> */}

                  {/* Scrollable Time Slots */}
                  <Stack
                    sx={{
                      flexDirection: "row",
                      paddingTop: "20px",
                      gap: "2px",
                      // paddingLeft: "17px",
                    }}
                  >
                    {/* here date list are map and whole array of attendance list and sppecific date are passed inside this component ,
                   inside this component , if specific date are avilable in attendance list then it render else show no-shift card*/}
                    {tableData.dateList.map((date, index) => (
                      <StatusViewCard
                        key={index}
                        rowDate={date.date}
                        attendanceDataArray={empData.attendanceList}
                      />
                    ))}
                  </Stack>
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default ScheduleTableMonthUserView;
