import {GlobalState} from "../globalTypes";

export const createScreenRecording__Request = (state: GlobalState) => {
    state.error = null;
};

export const getAllScreenRecordings__Request = (state: GlobalState) => {
    state.error = null;
};

export const getScreenRecordingById__Request = (state: GlobalState) => {
    state.error = null;
};

export const updateScreenRecording__Request = (state: GlobalState) => {
    state.error = null;
};

export const deleteScreenRecording__Request = (state: GlobalState) => {
    state.error = null;
};

export const saveOrUpdateCoverImage__Request = (state: GlobalState, action: any) => {
    state.loading = false;
};

export const getCoverImages__Request = (state: GlobalState, action: any) => {
    state.loading = false;
};

export const getAllProjectsGlobal__Request = (state: GlobalState) => {
    state.loading = true;
    state.error = null;
}

export const getAllWorkspaceGlobal__Request = (state: GlobalState) => {
    state.loading = true;
    state.error = null;
}

export const getAllFoldersGlobal__Request = (state: GlobalState, action: any) => {
    state.loading = false;
    state.error = null;
};

export const globalSearch__Request = (state: GlobalState) => {
    state.loading = true;
    state.error = null;
}

export const getAllUserContactsRequest = (state: GlobalState) => {
    state.loading = true;
    state.error = null;
}

export const getAllCloudStorageFiles__Request = (state: GlobalState, action: any) => {
    state.loading = false;
};

export const initiateGroupCall__Request = (state: GlobalState, action: any) => {
    state.loading = false;
};

export const getCallUserDetails__Request = (state: GlobalState, action: any) => {
    state.loading = false;
}