import { IconButtonProps } from "@mui/material";
import React from "react";
import { MicIcon } from "../../../../../images";
import { VideoActionRoundedButtons } from "../../../../common";
import { useTranslation } from "react-i18next";

interface MicButtonProps extends IconButtonProps {
  initialStatus: boolean;
  onClickChange: (status: boolean) => void;
}

const MicButton: React.FC<MicButtonProps> = ({
  initialStatus,
  onClickChange,
  ...props
}) => {
  const {t}=useTranslation()
  return (
    <VideoActionRoundedButtons
      activeColor="#FF0000"
      inactiveColor="#000AFF"
      activeHoverText={t("VIDEO_CENTER.MEETING.MUTE")}
      inActiveHoverText= {t("VIDEO_CENTER.MEETING.TURN_ON_MIC")}
      icon={MicIcon}
      initialStatus={initialStatus}
      onClickChange={onClickChange}
      {...props}
    />
  );
};

export default MicButton;
