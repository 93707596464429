import { Stack, Typography } from "@mui/material";
import { IconSmallButton, LargeheightInputBox } from "../../../../common";

const Objective = () => {
  return (
    <Stack
      sx={{
        boxSizing: "border-box",
        gap: "20px",
        paddingTop: "20px",
        height: "100%",
      }}
    >
      <Typography sx={{ font: "normal normal 600 10px/13px Source Serif Pro" }}>
        Key Objectives
      </Typography>

      <Stack
        sx={{
          gap: "20px",
          overflow: "auto",
          scrollbarWidth: "none",
          height: "192px",
        }}
      >
        <LargeheightInputBox inputTitle="Objective One" />
        <LargeheightInputBox inputTitle="Objective Two" />
      </Stack>

      <IconSmallButton buttonLabel="Add key objectives" />
    </Stack>
  );
};

export default Objective;
