import React, { useEffect, useState } from "react";
import { Box, Divider, Stack } from "@mui/material";
import { TotalCRM } from "../components";
import { GroupTable } from "./components";
import {
  AddColumnCrmTable,
  CRM_sortOrFilterMenuList,
  DUMMY_CRM_COMPANIES_LIST,
} from "../common";
import {
  DropdownButtonAllCompanies,
  DropdownButtonCSV,
  DropdownButtonFilterMenu,
  DropdownButtonSortMenu,
  DropdownButtonVisibleMenu,
  SwitchSmall,
} from "../../../common";
import { ViewMode } from "../../Projects/types";
import { GroupStageMap } from "./GroupStageMap";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  FilterItem,
  getGroupById__api,
  recallAPI__CRM,
  updateFilterKey,
  updateSortingKey,
} from "../../../../redux";
import { DataIDnumberIcon } from "../../../../images";

// Define the props for the Deal component
interface GroupProps {}

// Define the data structure for the component
// const dropDownButtonData: DropdownButtonInterface[] = [
//   {
//     componentIcon: <BriefCaseIcon w={17} />,
//     label: "Groups",
//     componentType: "allPeople",
//   },
//   { componentIcon: <VisibleIcon />, label: "Visible", componentType: "Sort" },
//   { componentIcon: <ImportIcon />, label: "Import", componentType: "CSV" },
//   { componentIcon: <SortIcon />, label: "Sort", componentType: "Sort" },
//   { componentIcon: <FiltersIcon />, label: "Filter", componentType: "Filter" },
// ];
type Params = Record<string, string | undefined>;
// The Deal component
const Group: React.FC<GroupProps> = () => {
  const { groupId } = useParams<Params>();
  const dispatch = useDispatch();

  const [groupDetails, setGroupDetails] = useState<any>({});

  const getGroupDetails = async () => {
    try {
      if (!groupId) {
        return;
      }

      const action = getGroupById__api(groupId);

      const getGroupRes: any = await dispatch(action);

      if (getGroupRes?.success && getGroupRes?.data) {
        setGroupDetails(getGroupRes?.data);
      } else {
        setGroupDetails({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGroupDetails();
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [groupId]);

  const [ViewMode, setViewMode] = useState<ViewMode>("square");
  const countGroupMetrics = (group: any) => {
    const totalDeals = group?.deals?.length || 0; // Count deals
    const totalPeople = group?.people?.length || 0; // Count people
    const totalCompanies = group?.companies?.length || 0; // Count companies

    const totalCount = totalDeals + totalPeople + totalCompanies; // Calculate total count

    return {
      totalCount, // Return the total count
    };
  };
  const { totalCount } = countGroupMetrics(groupDetails);

  const handleSortSelection = (sortSelection: any) => {
    // Assume sortSelection is an array of objects similar to the provided JSON data
    sortSelection?.forEach((item: any) => {
      const field = CRM_sortOrFilterMenuList?.find(
        (option: any) => option?.label === item?.selectedItem?.label
      )?.label;
      const order = item?.order?.toLowerCase() === "ascending" ? "asc" : "desc";

      if (field) {
        dispatch(
          updateSortingKey({
            section: "groups", // This needs to be dynamic based on your actual CRM sections
            sortConfig: { field, order },
          })
        );
      }
    });

    setTimeout(() => {
      dispatch(recallAPI__CRM("getAllGroups"));
    }, 1000); // 1000 milliseconds delay
  };
  const handleFilterUpdate = (filters: FilterItem[]) => {
    filters?.forEach((filter: FilterItem) => {
      const { selectedItem, operator, filterInput, condition } = filter;
      const field = selectedItem.label; // Use the value as the field identifier
      const filterCondition = {
        type: operator, // Corrected from 'operator' to 'type' to match FilterCondition
        value: filterInput || "", // Ensure undefined input is handled as empty string
        conjunction: condition, // Correctly pass the logical operator
      };

      dispatch(
        updateFilterKey({
          section: "groups", // This should be dynamic or correctly set based on your app structure
          filterConfig: {
            field: field,
            conditions: [filterCondition], // Supports adding or updating multiple conditions per field
          },
        })
      );
    });

    setTimeout(() => {
      dispatch(recallAPI__CRM("getAllGroups"));
    }, 1000); // 1000 milliseconds delay
  };

  return (
    <Box sx={{ padding: "10px 0 25px 0" }}>
      <Stack mb={1} p={1} direction={"column"} gap={1.2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={0.5}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <DropdownButtonAllCompanies
              buttonLabel="All people"
              labelIcon={DataIDnumberIcon}
              options={DUMMY_CRM_COMPANIES_LIST}
            />
            <TotalCRM label="Total Deals" totalCount={totalCount} />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <DropdownButtonSortMenu
              buttonColor="CRMOrange"
              sortOption={CRM_sortOrFilterMenuList}
              sortedList={(value: any) => {
                handleSortSelection(value);
              }}
            />
            <DropdownButtonCSV onSelect={() => null} />
          </Stack>
        </Stack>
        <Divider />
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={0.5}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <DropdownButtonSortMenu
              buttonColor="CRMOrange"
              sortOption={CRM_sortOrFilterMenuList}
              sortedList={(value: any) => {
                handleSortSelection(value);
              }}
            />
            <DropdownButtonFilterMenu
              buttonColor="CRMOrange"
              filterOption={CRM_sortOrFilterMenuList}
              filteredList={(list: any) => {
                handleFilterUpdate(list);
              }}
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1.5}>
            <SwitchSmall viewMode={ViewMode} setViewMode={setViewMode} />
          </Stack>
        </Stack>
      </Stack>
      {ViewMode === "square" ? (
        <Stack direction={"row"} alignItems={"flex-start"} gap={0}>
          <GroupTable groupDetails={groupDetails} />
          <AddColumnCrmTable />
        </Stack>
      ) : (
        <GroupStageMap />
      )}
    </Box>
  );
};

export default Group;
