export type AttendanceScheduleFilterSubMenuType = {
    _id: string;
    label: string;
    value: string;
};

export type AttendanceScheduleFilterMenuType = {
    _id: string;
    label: string;
    value: string;
    subMenuItems?: AttendanceScheduleFilterSubMenuType[];
    subMenuinfoText?: string;
    isSubMenuAddable?: boolean;
};


export type AttendanceScheduleFilterOptionsType =
    | ({
        divider: true; // If this is present, it represents a divider
    })
    | (AttendanceScheduleFilterMenuType & {
        divider?: never; // Prevents the use of 'divider' when using AttendanceScheduleFilterMenuItemType
    });



export const attendanceScheduleFilterOptions: AttendanceScheduleFilterOptionsType[] = [
    {
        _id: '1',
        label: 'User Status',
        value: 'userStatus',
        subMenuinfoText: "Above filters refer to users with Bearish OS accounts/access only.",
        subMenuItems: [
            { _id: '1.1', label: 'Admin', value: 'admin' },
            { _id: '1.2', label: 'Manager', value: 'manager' },
            { _id: '1.3', label: 'Team Lead', value: 'teamLead' },
            { _id: '1.4', label: 'User', value: 'user' },
            { _id: '1.5', label: 'Guest', value: 'guest' },
            { _id: '1.6', label: 'Auditor', value: 'auditor' },
        ],
    },
    {
        _id: '2',
        label: 'Department',
        value: 'department',
        isSubMenuAddable: true,
        subMenuItems: [
            { _id: '2.1', label: 'C-Suite', value: 'CSuite' },
        ],
    },
    {
        _id: '3',
        label: 'Teams',
        value: 'teams',
        isSubMenuAddable: true,
        subMenuItems: [

            { _id: '3.1', label: 'First Team', value: 'firstTeam' },
            { _id: '3.2', label: 'Second Team', value: 'secondTeam' },

        ],
    },
    { divider: true },
];


// this array is only for testing
export const initialAttendanceScheduleFilterOptions: AttendanceScheduleFilterMenuType[] = [
    {
        _id: '1',
        label: 'User Status',
        value: 'userStatus',
        subMenuinfoText: "Above filters refer to users with Bearish OS accounts/access only.",
        subMenuItems: [
            { _id: '1.1', label: 'Admin', value: 'admin' },
            { _id: '1.2', label: 'Manager', value: 'manager' },
        ],
    },
    {
        _id: '2',
        label: 'Department',
        value: 'department',
        isSubMenuAddable: true,
        subMenuItems: [
            { _id: '2.1', label: 'C-Suite', value: 'CSuite' },
        ],
    },
];