import React from "react";
import {
  AnnouncementIcon,
  BearishOSIconCloudStroge,
  CommandIcon,
  DocumentIcon,
  GearIcon,
  planYourDayOrWeek_svg,
} from "../../../../../../images"; // Import the icon

// Enum defining button labels
export enum CommonButtonLabels {
  BEBA_AI = "BEBA AI",
  COMMAND_CONTROL = "Command Control",
  CALENDAR_SETTINGS = "Calendar Settings",
  NEW_ANNOUNCEMENT = "New Announcement",
  EMAIL_SETTINGS = "Email Settings",
  MESSAGE_SETTINGS = "Message Settings",
  DRAFTS_TEMPLATES = "Drafts & Templates",
  PLAN_YOUR_DAYS = "Plan your day(s)",
  PLAN_YOUR_WEEK = "Plan your week",
}

export const buttonLabelToIconMapping: Record<
  CommonButtonLabels,
  React.ComponentType<any> | string
> = {
  [CommonButtonLabels.BEBA_AI]: BearishOSIconCloudStroge,
  [CommonButtonLabels.COMMAND_CONTROL]: CommandIcon,
  [CommonButtonLabels.CALENDAR_SETTINGS]: GearIcon,
  [CommonButtonLabels.NEW_ANNOUNCEMENT]: AnnouncementIcon,
  [CommonButtonLabels.EMAIL_SETTINGS]: BearishOSIconCloudStroge,
  [CommonButtonLabels.MESSAGE_SETTINGS]: BearishOSIconCloudStroge,
  [CommonButtonLabels.DRAFTS_TEMPLATES]: DocumentIcon,
  [CommonButtonLabels.PLAN_YOUR_DAYS]: planYourDayOrWeek_svg,
  [CommonButtonLabels.PLAN_YOUR_WEEK]: planYourDayOrWeek_svg,
};
