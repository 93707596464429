import React from "react";
import { Stack, Typography } from "@mui/material";
import {
  CardHeaderView,
  ConflictHandleButtons,
  EmpPreviewTableContainerView,
  EmpPreviewTableRow,
} from "./view";
import { DummyImage, TeamMemberIcon } from "../../../../../images";
import {
  ButtonSmall,
  EditableNumberInput,
  SingleTeammateDropdown,
  SmallButtonColor,
} from "../../../../common";

interface AssignedEmployeesMenuProps {}

const AssignedEmployeesMenu: React.FC<AssignedEmployeesMenuProps> = ({}) => {
  return (
    <Stack
      sx={{
        width: "595px",
        borderRadius: "5px",
        border: "1px solid #E9EDF2",
        boxSizing: "border-box",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
        padding: "20px 30px 15px 15px",
        bgcolor: "white",
        gap: "25px",
      }}
    >
      {/* header and conflict handle button */}
      <CardHeaderView>
        <ConflictHandleButtons isDisabled={!true} />
      </CardHeaderView>
      {/* --------------------------------- */}

      {/* table area here */}
      <EmpPreviewTableContainerView>
        {Array.from({ length: 10 }).map((item, index) => {
          // const isLastChild = index === dataArray.length - 1;  // check if item is last child then make this true like this
          const isLastChild = index === 10 - 1;

          return (
            <EmpPreviewTableRow
              key={index}
              countryFlagSrc={DummyImage}
              countryName="Madrid"
              currenTime="10:25 AM"
              jobTitle="Software developer"
              userName="Ryan Smith"
              userImgSrc={`https://randomuser.me/api/portraits/men/${index}.jpg`}
              onButtonClick={() => null}
              onCheckboxClick={() => null}
              isSelected={false}
              avatarColor=""
              isConflict={{
                conflictType: "positionConflicting",
                jobTitle: "Software developer",
              }}
              isLastChild={isLastChild}
            />
          );
        })}
      </EmpPreviewTableContainerView>
      {/* --------------------------------- */}

      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "6px" }}>
        <TeamMemberIcon />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          Assign different Employee
        </Typography>
      </Stack>

      <Stack sx={{ flexDirection: "row", gap: "10px", alignItems: "center" }}>


        <SingleTeammateDropdown />

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <Typography
            sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
          >
            Required
          </Typography>
          <EditableNumberInput style={{ fontWeight: 900 }} pxPerWord={10} />

          <Typography
            sx={{
              font: "normal normal 300 13px/16px Source Serif Pro",
              mr: "5px",
            }}
          >
            Assigned
          </Typography>
          <EditableNumberInput style={{ fontWeight: 900 }} pxPerWord={10} />
        </Stack>
      </Stack>

      <Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
        <ButtonSmall label="Save" colorVarient={SmallButtonColor.TraxBluish} />
      </Stack>
    </Stack>
  );
};

export default AssignedEmployeesMenu;
