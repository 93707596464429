import {
  Avatar,
  Box,
  Breadcrumbs,
  Grow,
  Link,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CloudStorageItemGrid,
  CloudStorageItemList,
  DetailsCloudStorage,
  ItemMainInsights,
  LinkDetails,
  MediumSearchbar,
  SwitchSmall,
} from "../../common";
import { ViewMode } from "../Projects";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  box__oauth__handle,
  CloudStorageState,
  deleteFile__api,
  deleteFile__Box__api,
  downloadFile__api,
  downloadFile__BOX__api,
  getAllFile__BOX__api,
  getAllFiles__api,
  getAllFolders__api,
  getAllFolders__BOX__api,
  getDetailsSliderState,
  selectedFile,
  toggleRefreshFiles,
  updateFile__api,
} from "../../../redux";
import { RootState } from "../../../redux/store";
import { DarkFolderIcon, NoFileFolder } from "../../../images";
import FileActionDialogRenameMove from "./components/FileActionDialogRenameMove/FileActionDialogRenameMove";
import { getPlateform } from "../../../Utils/csIcon";
import {
  adjustHexColor,
  avatarInitialLetters,
  formatDate,
  getUser,
} from "../../../Utils";
import { SearchContainer } from "../SearchContainer";
import { useSnackbar } from "notistack";

const CloudStorage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleGetTokenFromBox = async (token: string) => {
    try {
      const action = box__oauth__handle(token);

      const r: any = await dispatch(action);

      if (r?.message === "Authorization successful") {
        dispatch(toggleRefreshFiles(true));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const slug = location.pathname.split("/")[1];

    if (code && slug) {
      handleGetTokenFromBox(code);
    }
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [location.pathname]); // Re-run effect if the path changes

  const cloudStorageState = useSelector(
    (state: RootState) => state?.cloudStorage as CloudStorageState
  );

  const {
    bearishOSCloudStorage,
    refreshFiles = false,
    bearishOSCloudStorageFolders,
    detailsSlider = null,
    filterBy = "Other",
  } = cloudStorageState || {};

  const [detailsSliderState, setDetailsSliderState] = useState(detailsSlider);

  useEffect(() => {
    if (detailsSlider) {
      setDetailsSliderState(detailsSlider);
    }
  }, [detailsSlider]);

  const [items, setItems] = useState<any[]>([]);
  const [currentDir, setCurrentDir] = useState<string>(""); // Track current directory

  // Set the currentDir based on the URL query parameter
  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    const dirParam = searchParams?.get("dir") || ""; // Set to empty string if there's no dir param (root level)
    setCurrentDir(dirParam);
  }, [location?.search]);

  const handleItemClick = (id: string) => {
    setItems((prevItems: any) => {
      const updatedItems = prevItems?.map((item: any) =>
        item?._id === id ? { ...item, isSelected: !item.isSelected } : item
      );
      return updatedItems;
    });
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFileData, setSelectedFileData] = useState<any>(null);
  const [actionSelect, setActionSelect] = useState<string>("");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Function to show loading snackbar
  const showLoadingSnackbar = (
    enqueueSnackbar: any,
    message: string,
    key: string
  ) => {
    enqueueSnackbar(message, { key, persist: true, variant: 'info' });
  };

  // Function to hide snackbar
  const hideSnackbar = (closeSnackbar: any, key: string) => {
    closeSnackbar(key);
  };

  // Handle the fetching of files and folders
  const handleGetAllFileFolders = async () => {
    const key = `loading-file-fetch-${new Date().getTime()}`;  // Unique key for the loading Snackbar
    showLoadingSnackbar(enqueueSnackbar, 'Fetching files...', key);  // Show loading Snackbar

    try {
      await dispatch(getAllFiles__api());  // Fetch system files
      await dispatch(getAllFile__BOX__api());  // Fetch Box files

      dispatch(toggleRefreshFiles(false));  // Final action to stop refresh
    } catch (error: unknown) {
      console.error("Error fetching files:", error);  // Optional: handle error logging
    } finally {
      console.log("Finished fetching files...");
      hideSnackbar(closeSnackbar, key);  // Use the same key to hide the snackbar once done
    }
  };

  const handleGetAllFolders = async () => {
    const key = `loading-folder-fetch-${new Date().getTime()}`;  // Unique key for the loading Snackbar
    showLoadingSnackbar(enqueueSnackbar, 'Fetching folders...', key);  // Show loading Snackbar

    try {
      await dispatch(getAllFolders__api());  // Fetch system folders
      await dispatch(getAllFolders__BOX__api());  // Fetch Box folders

      dispatch(toggleRefreshFiles(false));  // Final action to stop refresh
    } catch (error: unknown) {
      console.error("Error fetching folders:", error);  // Optional: handle error logging
    } finally {
      console.log("Finished fetching folder...");
      hideSnackbar(closeSnackbar, key);  // Use the same key to hide the snackbar once done
    }
  };

  const handleNavigate = (fileId?: string, fileName?: string, item?: any) => {
    if (!fileId || !fileName) return;

    const sanitizedFileId = encodeURIComponent(fileId);
    const sanitizedFileName = encodeURIComponent(fileName);
    const fileExtension = sanitizedFileName?.split(".")?.pop();
    if (!fileExtension) {
      console.error("Invalid file extension:", fileExtension);
      return;
    }

    if (!item) {
      return;
    }

    const newDir = `${currentDir}`?.replace("//", "/"); // Navigate deeper by updating currentDir
    setCurrentDir(newDir);

    dispatch(selectedFile(item));

    navigate(
      `/cloud-storage/${sanitizedFileId}/${fileExtension}?dir=${newDir}&provider=${item?.platform}`
    );
  };

  // Folder click logic, passing both folderName and directory
  const handleFolderClick = (
    folderName: string,
    folderDirectory: string,
    platform?: string,
    folderId?: string
  ) => {
    let newDir = folderDirectory
      ? `${folderDirectory}/${folderName}`
      : `/${folderName}`; // Build new directory path
    newDir = newDir?.replace("//", "/"); // Ensure no double slashes
    setCurrentDir(newDir); // Update current directory
    navigate(
      `/cloud-storage/?dir=${newDir}&provider=${platform ? platform : "BearishOS"
      }&folderId=${folderId}`
    ); // Update URL for deep linking
  };

  const [viewMode, setViewMode] = useState<ViewMode>("square");
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    if (refreshFiles) {
      handleGetAllFileFolders();
      handleGetAllFolders();
    } // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [refreshFiles]);

  useEffect(() => {
    if (bearishOSCloudStorage?.length) {
      // Make a shallow copy of the array to avoid mutation
      let filteredItems = [...bearishOSCloudStorage];

      // Apply filtering based on the selected platform if it's not "Other"
      if (filterBy !== "Other") {
        filteredItems = filteredItems.filter(
          (item: any) => item?.platform === filterBy
        );
      }

      // Sort the items by platform alphabetically, checking if 'platform' exists
      filteredItems?.sort((a: any, b: any) => {
        if (a?.platform && b?.platform) {
          return a?.platform?.localeCompare(b?.platform);
        }
        return 0; // Return 0 if no platform exists on either side
      });

      // Update the state with the filtered and sorted items
      setItems(filteredItems);
    } else {
      // In case bearishOSCloudStorage is empty, clear the items state (optional)
      setItems([]);
    }
  }, [bearishOSCloudStorage, filterBy]); // Runs whenever bearishOSCloudStorage or filterBy changes

  // Filter files that belong to the current directory
  const currentDirFiles = items?.filter(
    (item) => item?.directory === currentDir
  );

  // 2. Detect subfolders based on current directory from `bearishOSCloudStorageFolders`
  // Check if we are in the root directory
  const isRootDir = currentDir === "/" || currentDir === "";

  // Filter folders based on the current directory
  const subfolders = [...bearishOSCloudStorageFolders]?.filter(
    (folder: any) => {
      const folderDir = folder?.directory || "/"; // Default to root if directory is not set
      const isPlatformMatch =
        filterBy === "Other" || folder.platform === filterBy;

      if (isRootDir) {
        return folderDir === "/" && isPlatformMatch;
      } else {
        return folderDir === currentDir && isPlatformMatch; // Show folders that belong to the current directory and match the platform
      }
    }
  );

  const downloadFileAction = async (fileId: string, platForm?: string) => {
    try {
      if (!fileId) {
        return;
      }

      if (!platForm) {
        platForm = "BearishOS";
      }

      let action;

      if (platForm === "Box") {
        action = downloadFile__BOX__api(fileId);
      } else {
        action = downloadFile__api(fileId);
      }

      const dataurl: any = await dispatch(action);

      if (dataurl?.success && dataurl?.data?.url) {
        // Create a temporary link element
        const link = document.createElement("a");
        link.href = dataurl.data?.url; // Assuming `dataurl.data` contains the file URL or Blob
        link.download = "BearishOS-file-download"; // You can set the filename dynamically if needed

        // Append the link to the document and trigger a download
        document.body.appendChild(link);
        link.click();

        // Remove the link after download is triggered
        document.body?.removeChild(link);
      }
    } catch (err) {
      console.error("Error downloading file:", err);
    }
  };

  const deleteFileAction = async (fileId: string, platForm?: string) => {
    if (!fileId) {
      return;
    }
    console.log(platForm);
    try {
      if (!platForm) {
        platForm = "BearishOS";
      }

      let action;

      if (platForm === "Box") {
        action = deleteFile__Box__api(fileId);
      } else {
        action = deleteFile__api(fileId);
      }

      await dispatch(action);
    } catch (err) {
    } finally {
      dispatch(toggleRefreshFiles(true));
    }
  };

  const optionAction = (action: string, fileId?: string, fileData?: any) => {
    if (!action) {
      return;
    }

    if (action === "Details" || action === "Edit Tags") {
      setSelectedFileData(fileData);
      setCollapsed(true);
      dispatch(getDetailsSliderState("Main Details"));
    } // Pass original file data to the dialog}

    if (action === "Download") {
      if (!fileId) {
        return;
      }
      downloadFileAction(fileId, fileData?.platform);
    }
    // else if (action === "Delete") {
    //   if (!fileId) {
    //     return;
    //   }
    //   deleteFileAction(fileId);
    // }
    else if (
      action === "Rename" ||
      action === "Move" ||
      action === "Watermark" ||
      action === "Delete" ||
      action === "Upload New Version"
    ) {
      if (!fileId) {
        return;
      }
      setActionSelect(action);
      setDialogOpen(true); // Open the dialog
      setSelectedFileData(fileData); // Pass original file data to the dialog
    } else if (action === "Set Expiration" || action === "Lock") {
      setActionSelect(action);
      setDialogOpen(true); // Open the dialog
      setSelectedFileData(fileData); // Pass original file data to the dialog
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSubmit = async (payload: any) => {
    if (!payload || !selectedFileData?._id) {
      return;
    }

    if (selectedFileData?.platform === "Box") {
      return;
    }

    const action = updateFile__api(selectedFileData?._id, payload);

    await dispatch(action);

    dispatch(toggleRefreshFiles(true));

    setDialogOpen(false); // Close dialog after submission
  };

  // Split the currentDir into breadcrumb parts
  const breadcrumbParts = currentDir?.split("/").filter(Boolean); // Remove empty parts

  // Handle breadcrumb navigation
  const handleBreadcrumbClick = (index: number) => {
    // Create the new directory path based on which breadcrumb was clicked
    const newDir = `/${breadcrumbParts?.slice(0, index + 1)?.join("/")}`;
    navigate(`/cloud-storage/?dir=${newDir}`);
  };

  const user = getUser();

  return (
    <Box>
      <Stack p="15px 25px 2px 25px">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="boxProject"
          position="relative"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Breadcrumbs aria-label="breadcrumb">
              {/* Root Level labeled "All Items" */}
              <Link
                underline="hover"
                color="inherit"
                onClick={() => navigate("/cloud-storage/")}
                sx={{
                  fontFamily: "Source Serif Pro, sans-serif", // Change to your desired font
                  fontWeight: "normal",
                  fontSize: "20px",
                  cursor: "pointer",
                  transition: "font-weight 0.3s ease", // Smooth transition for font weight
                  "&:hover": {
                    fontWeight: "bold", // Bold on hover
                  },
                }}
              >
                All Items
              </Link>

              {/* Dynamic Breadcrumbs */}
              {breadcrumbParts?.slice(0, -1)?.map((part, index) => (
                <Link
                  key={index}
                  underline="hover"
                  color="inherit"
                  onClick={() => handleBreadcrumbClick(index)}
                  sx={{
                    fontFamily: "Source Serif Pro, sans-serif", // Change to your desired font
                    fontWeight: "normal",
                    fontSize: "20px",
                    cursor: "pointer",
                    transition: "font-weight 0.3s ease", // Smooth transition for font weight
                    "&:hover": {
                      fontWeight: "bold", // Bold on hover
                    },
                  }}
                >
                  {part}
                </Link>
              ))}

              {/* Current Folder (non-clickable) */}
              {breadcrumbParts?.length > 0 && (
                <Typography
                  color="text.primary"
                  sx={{
                    fontFamily: "Source Serif Pro, sans-serif", // Change to your desired font
                    fontWeight: "bold", // Always bold for the current folder
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  {breadcrumbParts?.at(-1)}
                </Typography>
              )}
            </Breadcrumbs>
          </Stack>

          <Stack direction="row" alignItems="center" gap={2}>
            <SwitchSmall viewMode={viewMode} setViewMode={setViewMode} />
            <MediumSearchbar ph={"Search all Items"} />
          </Stack>

          {/* {selectedFileData && (
            <Stack position={"absolute"} right={"15px"} top={"44px"}>
              <IconButton onClick={() => setCollapsed(!collapsed)}>
                <GreenArrowButton />
              </IconButton>
            </Stack>
          )} */}
        </Stack>

        <Stack mt={3} direction={"column"} gap={0}>
          {/* Render Files in the Current Directory */}
          <Stack
            sx={{
              maxHeight: "calc(84vh - 0px)", // Ensure max height for scrolling
              overflowY: "auto", // Enable vertical scrolling
              transition: "max-height 0.3s ease",
              scrollbarWidth: "none", // Hide scrollbar in Firefox
            }}
            direction={"row"}
            alignItems={"flex-start"}
            gap={0.5}
            width={"100%"}
            justifyContent={"space-between"}
          >
            <Stack
              flexWrap={"wrap"}
              width={"100%"}
              height="auto" // Set height to auto to allow flexible wrapping
              sx={{
                "&::-webkit-scrollbar": {
                  width: "0 !important", // Hide scrollbar on Chrome, Safari, etc.
                },
                scrollbarWidth: "none", // Hide scrollbar on Firefox
                overflowX: "hidden", // Hide horizontal overflow
                overflowY: "auto", // Enable vertical scrolling for the inner stack
              }}
              direction={viewMode === "square" ? "row" : "column"}
              gap={viewMode === "square" ? 3 : 0}
            >
              {/* Check for currentDirFiles and subfolders availability */}
              {currentDirFiles &&
                currentDirFiles?.length === 0 &&
                subfolders &&
                subfolders?.length === 0 ? (
                <Stack
                  width={"100%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <img
                    width={"350px"}
                    height={"350px"}
                    src={NoFileFolder}
                    alt="No File Folder"
                  />
                </Stack>
              ) : (
                <>
                  {/* Render Files */}
                  {currentDirFiles?.map((item, index) => (
                    <Grow
                      key={item?._id || index}
                      in={true}
                      timeout={500}
                      mountOnEnter
                      unmountOnExit
                      style={{ transformOrigin: "top" }}
                    >
                      <div
                        style={{
                          width: viewMode !== "square" ? "100%" : "auto",
                        }}
                      >
                        {viewMode === "square" ? (
                          <CloudStorageItemGrid
                            plateform={item?.platform}
                            opendBy={item?.uploadedBy}
                            fileSize={item?.size}
                            path={item?.path || ""}
                            isSelected={item?.isSelected}
                            onContainerClick={() =>
                              handleNavigate(
                                item?._id,
                                item?.originalName,
                                item
                              )
                            }
                            timeStamp={item?.uploadDate}
                            fileName={item?.originalName}
                            checkBoxClick={() => handleItemClick(item?._id)}
                            optionAction={(value: any) => {
                              optionAction(value, item?._id, item);
                            }}
                          />
                        ) : (
                          <CloudStorageItemList
                            plateform={item?.platform}
                            opendBy={item?.uploadedBy}
                            fileSize={item?.size}
                            isSelected={item?.isSelected}
                            onContainerClick={() =>
                              handleNavigate(
                                item?._id,
                                item?.originalName,
                                item
                              )
                            }
                            timeStamp={item?.uploadDate}
                            fileName={item?.originalName}
                            checkBoxClick={() => handleItemClick(item?._id)}
                            collapsedView={collapsed}
                            optionAction={(value: any) => {
                              optionAction(value, item?._id, item);
                            }}
                          />
                        )}
                      </div>
                    </Grow>
                  ))}

                  {/* Render Folders from `bearishOSCloudStorageFolders`, excluding the current folder */}
                  {/* <Stack
                    flexWrap={"wrap"}
                    direction={viewMode === "square" ? "row" : "column"}
                    gap={viewMode === "square" ? 3 : 0}
                    m={"5px 5px"}
                    width={"100%"}
                    alignItems={"flex-start"}
                    justifyContent={"space-between"}
                  > */}
                  {/* <Stack
                      flexWrap={"wrap"}
                      direction={viewMode === "square" ? "row" : "column"}
                      gap={viewMode === "square" ? 3 : 0}
                      m={"5px 5px"}
                      width={"100%"}
                    > */}
                  {subfolders?.map((folder: any, index: any) => (
                    <Grow
                      key={folder?._id || index}
                      in={true}
                      timeout={500}
                      mountOnEnter
                      unmountOnExit
                      style={{ transformOrigin: "top" }}
                    >
                      <Stack
                        style={{
                          width: viewMode !== "square" ? "100%" : "auto",
                          border: "1px solid #e9edf2",
                          borderRadius: "5px",
                          // marginBottom: "10px",
                          // marginTop: "10px",
                          // backgroundColor: "#f9f9f9",
                          boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.15)",
                          cursor: "pointer",
                        }}
                        height={viewMode === "square" ? "175px" : "50px"}
                        direction={"row"}
                        alignItems={"center"}
                        gap={1}
                        // minWidth={
                        //   '30px'
                        // }
                        padding={"0 15px"}
                        onClick={() =>
                          handleFolderClick(
                            folder?.folderName,
                            folder?.directory,
                            folder?.platform,
                            folder?._id
                          )
                        } // Pass folderName and directory
                      >
                        <Stack direction={"column"} gap={1}>
                          {viewMode === "square" && (
                            <Stack
                              sx={{
                                bgcolor: adjustHexColor("#E9EDF2", 20),
                                border: `1.5px solid ${adjustHexColor(
                                  "#E9EDF2",
                                  20
                                )} `,
                                backgroundImage: `url(${`https://media.istockphoto.com/id/1191487544/vector/folder-icon-open-folder-with-documents-for-your-web-site-design-app-logo-ui.jpg?s=612x612&w=0&k=20&c=LAIyv4QlG8f0lwBJwZt6Y_s2HG5z76qrVCuFbNqPzSY=`})`, // Set the background image
                                backgroundSize: "cover", // Cover the entire container
                                backgroundPosition: "center", // Center the image
                                backgroundRepeat: "no-repeat", // Prevent repeating

                                width: "210px",
                                height: "95px",
                                boxSizing: "border-box",
                                borderRadius: " 5px",
                                boxShadow:
                                  " 0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
                                flexShrink: 0,
                                alignItems: "flex-end",
                              }}
                            />
                          )}

                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            position={"relative"}
                            width={viewMode !== "square" ? "308px" : "213px"}
                          >
                            <DarkFolderIcon color="#117BB8" w={18} />
                            <Box
                              component={"img"}
                              src={getPlateform(folder?.platform)}
                              alt="logo"
                              sx={{
                                width: "18px",
                                height: "18px",
                                position: "absolute",
                                bottom: "5px",
                                left: "7px",
                              }}
                            />
                            <Typography
                              sx={{
                                padding: "10px",
                                fontWeight: "400",
                                color: "#333",
                                textAlign: "center",
                                fontFamily: "Source Serif Pro",
                                maxWidth:
                                  viewMode !== "square" ? "285px" : "195px", // Limit the max width to 150px
                                whiteSpace: "nowrap", // Prevent text wrapping
                                overflow: "hidden", // Hide overflow text
                                textOverflow: "ellipsis", // Show ellipsis when text overflows
                              }}
                            >
                              {folder.folderName}
                            </Typography>
                          </Stack>
                        </Stack>
                        {viewMode !== "square" && (
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={"10px"}
                            width={"50%"}
                          >
                            <Avatar
                              src={user?.data?.avatar}
                              sx={{ width: "25px", height: "25px" }}
                            >
                              <Typography
                                sx={{
                                  font: "normal normal normal 13px/17px Source Serif Pro",
                                }}
                              >
                                {avatarInitialLetters(user?.data?.firstName)}
                              </Typography>
                            </Avatar>{" "}
                            <Typography
                              sx={{
                                font: "normal normal 400 14px/18px Source Serif Pro",
                                marginRight: "119px",
                              }}
                            >
                              {`${user?.data?.firstName} ${user?.data?.lastName}`}
                            </Typography>
                            <Stack direction={"row"} alignItems={"center"}>
                              <Typography
                                sx={{
                                  font: "normal normal 400 14px/18px Source Serif Pro",
                                }}
                              >
                                {formatDate(folder?.createdAt)} by
                              </Typography>
                              <Typography
                                sx={{
                                  font: "normal normal 400 14px/18px Source Serif Pro",
                                }}
                              >
                                {`${user?.data?.firstName} ${user?.data?.lastName}` ||
                                  folder?.opendBy}
                              </Typography>
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                    </Grow>
                  ))}
                  {/* </Stack> */}
                  {/* </Stack> */}
                </>
              )}
            </Stack>

            <Slide direction="left" in={collapsed} mountOnEnter unmountOnExit>
              <Stack>
                {detailsSliderState === "Main Details" && (
                  <DetailsCloudStorage
                    closeDetails={() => {
                      setCollapsed(false);
                      setSelectedFileData(null);
                      dispatch(getDetailsSliderState(null));
                    }}
                    selectedFileData={selectedFileData}
                    handleDialogSubmit={handleDialogSubmit}
                  />
                )}
                {detailsSliderState === "Item Insight" && (
                  <ItemMainInsights
                    closeDetails={() => {
                      setCollapsed(false);
                      setSelectedFileData(null);
                      dispatch(getDetailsSliderState(null));
                    }}
                    selectedFileData={selectedFileData}
                  />
                )}
                {detailsSliderState === "Link Details" && (
                  <LinkDetails
                    selectedFileData={selectedFileData}
                    closeDetails={() => {
                      setCollapsed(false);
                      setSelectedFileData(null);
                      dispatch(getDetailsSliderState(null));
                    }}
                  />
                )}
              </Stack>
            </Slide>
          </Stack>
        </Stack>
      </Stack>
      <SearchContainer />
      <FileActionDialogRenameMove
        open={dialogOpen}
        onClose={handleDialogClose}
        onSubmit={handleDialogSubmit}
        fileData={selectedFileData}
        action={
          actionSelect as
          | "Rename"
          | "Move"
          | "Set Expiration"
          | "Delete"
          | "Lock"
          | "Watermark"
          | "Upload New Version"
        }
        folders={bearishOSCloudStorageFolders} // Pass folder list as prop
        deleteFileAction={deleteFileAction}
      />
    </Box>
  );
};

export default CloudStorage;
