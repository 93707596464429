import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import Pusher from "pusher-js";
import pusherInstance from "../../../Utils/pusher";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { VideoCenterState } from "../../../redux/Slice/videoCenter/VideoCenterTypes";
import {
  addAgendaToOnGoingMeetingAction, ClintSendAction,
  guestRequestsForAccessAction, guestRequestsForPlaseWaitAction,
  removeAgendaWithIdAction,
  setEndMeetingAction, setIsAbleToJoinMeetingAction, setIsMeetingEndedAction,
  updateAgendaWithIdAction,
} from "../../../redux/Slice/videoCenter/VideoCenterSlice";
import { MeetingContext } from "../MeetingProvider/MeetingProvider";
import { AuthState } from "../../../redux";
import {GlobalState} from "../../../redux/Slice/Global/globalTypes";
import {
  setCallDataAction, setUserAccountDetailsForCallAction,
} from "../../../redux/Slice/Global/globalSlice";

interface PusherContextType {
  pusher: Pusher | null;
}

const PusherContext = createContext<PusherContextType | null>(null);

export const usePusher = (): PusherContextType | null => {
  return useContext(PusherContext);
};

interface PusherProviderProps {
  children: ReactNode;
}

export const PusherProvider: React.FC<PusherProviderProps> = ({ children }) => {
  const [pusher, setPusher] = useState<Pusher | null>(null);
  const dispatch = useDispatch();
  const { onGoingMeeting } = useSelector(
    (state: RootState) => state.videoCenter as VideoCenterState
  );

  const { user } = useSelector((state: RootState) => state.auth as AuthState);

  const {userTimezone} = useSelector((state: RootState) => state.global as GlobalState);

  const meetingContext = useContext(MeetingContext);

  useEffect(() => {

    if (onGoingMeeting?.meeting?._id) {
      var channel: any = pusherInstance.subscribe(onGoingMeeting?.meeting?._id);
    }
    if (user?.data?._id) {
      var channel1: any = pusherInstance.subscribe(user?.data?._id);
    };

    var callback = (eventName: any, data: any) => {
      switch (eventName) {
        case "guest-request":
          handleGuestRequest(data);
          break;
        case "guest-join-request-response":
          handleGuestAccepted(data);
          break;
        case "agenda-update":
          handleUpdateAgenda(data);
          break;
        case "agenda-delete":
          handleRemoveAgenda(data);
          break;
        case "new-agenda":
          handleAddAgenda(data);
          break;
        case "end-meeting":
          handleEndMeeting(data);
          break;
        case "incoming-call":
          handleIncomingCall(data);
          break;
        default:
          break;
      }
    };

    if (channel) {
      channel.bind_global(callback);
    }
    if (channel1) {
      channel1.bind_global(callback);
    }

    const handleIncomingCall = async (data: any) => {
      dispatch(setCallDataAction(data))
      const payload = {
        userId: user?.data?._id,
        lastName: user?.data?.lastName,
        email: user?.data?.email,
        _id: user?.data?._id,
        token: user?.token,
      }
      dispatch(setUserAccountDetailsForCallAction(payload));
    }

    const handleGuestRequest = async (data: any) => {
      // Dispatch the action with the updated guest requests
      await dispatch(guestRequestsForAccessAction(data));
    };

    const handleGuestAccepted = async (data: any) => {
      if (data.userId === user?.data?._id) return;
      if(data.status === 'wait'){
        await dispatch(guestRequestsForPlaseWaitAction(data));
      }

      if(data.status === 'rejected' || data.status === 'wait'){
        await dispatch(ClintSendAction(data));
        return
      }
      if (data.status === "accepted") {
        await dispatch(setIsAbleToJoinMeetingAction(true));
      }
    };
    const handleUpdateAgenda = async (data: any) => {
      if (data?.deletedAgenda?.meetingHostId === user?.data?._id) return;
      await dispatch(updateAgendaWithIdAction(data));
    };
    const handleRemoveAgenda = async (data: any) => {
      if (data?.deletedAgenda?.meetingHostId === user?.data?._id) return;
      await dispatch(removeAgendaWithIdAction(data?.deletedAgenda._id));
    };
    const handleAddAgenda = async (data: any) => {
      if (data?.savedAgendas[0]?.meetingHostId === user?.data?._id) return;
      await dispatch(addAgendaToOnGoingMeetingAction(data));
    };
    const handleEndMeeting = async (data: any) => {
      if (data.userId === user?.data?._id) return;
      await dispatch(setEndMeetingAction(null));
      await dispatch(setIsMeetingEndedAction(true));
      meetingContext?.leaveMeeting();
    };

    setPusher(pusherInstance);
    return () => {
      if (channel) {
        channel.unbind_global(callback);
        pusherInstance.unsubscribe(onGoingMeeting?.meeting?._id || "");
        pusherInstance.disconnect();
      }

      if (channel1) {
        channel1.unbind_global(callback);
        pusherInstance.unsubscribe(user?.data?._id || "");
        pusherInstance.disconnect();
      }

    };
  }, [user?.data?._id,onGoingMeeting?.meeting]);

  return (
    <PusherContext.Provider value={{ pusher }}>
      {children}
    </PusherContext.Provider>
  );
};
