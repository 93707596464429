import React, { memo } from "react";
import { Container, Box, Tabs, Tab, IconButton, Stack } from "@mui/material";
import styled from "@emotion/styled";
import { commonTaskVector } from "../../../images";
import SidebarOpenMenu from "../Message/components/SidebarOpenMenu";

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}
interface StyledTabProps {
    label: string;
}

const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        {...props}
        sx={{ minHeight: "40px" }}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    "& .MuiTabs-indicator": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
        maxWidth: "80%",
        width: "100%",
        backgroundColor: "#B5005B",
    },
});

const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))({
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.7)",
    padding: "5px 10px",
    // marginBottom: "0px",
    minHeight: "50px",
    font: "normal normal 500 14px/17px Source Serif Pro",
    maxWidth: "fit-content",
    minWidth: "fit-content",
    marginBottom:"-10px",
    "&.Mui-selected": {
        color: "#000000",
        font: "normal normal 600 14px/17px Source Serif Pro",
    },
    "&.Mui-focusVisible": {
        backgroundColor: "#B5005B",
    },
});

interface CalendarTabsProps {
    value: number;
    handleShow_rightBar?: any;
    handleChange: (event: React.SyntheticEvent, newValue: number) => void;
    calendarSidebar?: any;
}

const tabList = [
    { label: "Calendar" },
    // { label: "Chat" },
    { label: "Assigned Tasks" },
    // { label: "Trax" },
];

const CalendarTabs: React.FC<CalendarTabsProps> = ({
    value = 0,
    handleChange,
    handleShow_rightBar,
    calendarSidebar = false,
}) => {
    const handleOpenSidebarButton = (actionType: string) => {
        // Map actionType to the proper value
        const actionMap: { [key: string]: string } = {
            "Start Call": "start_call",
            "Start Meeting": "start_meeting",
            "Open Mail": "open_mail",
            "Open Calendar": "open_calendar_mini",
            "Open Tasks": "open_task",
            "Open Cloud": "open_cloud",
        };

        const actionValue = actionMap[actionType];

        if (actionValue) {
            handleShow_rightBar(actionValue);
        } else {
            console.error("Unknown action type:", actionType);
        }
    };

    return (
        <Container
            sx={{
                maxWidth: "none !important", // Force the maxWidth override
                marginRight: "unset",
                marginLeft: "unset",
                "&.css-cz1h7t-MuiContainer-root": {
                    maxWidth: "none !important", // Override the specific class if it's applied
                    marginRight: "unset",
                    marginLeft: "unset",
                },
            }}
            disableGutters
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    justifyContent: "space-between",
                }}
            >
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                >
                    {tabList.map((tab, index) => (
                        <StyledTab key={index} label={tab.label} />
                    ))}
                </StyledTabs>
                {/* <IconButton
                    onClick={() => {
                        handleShow_rightBar("open_calendar_mini");
                    }}
                    sx={{
                        padding: "9px 8px",
                        marginRight: calendarSidebar.state_open ? "0" : "",
                        backgroundColor: calendarSidebar.state_open
                            ? "#b5005b75"
                            : "transparent",
                        transition: "background-color 0.3s ease",
                        "&:hover": {
                            backgroundColor: !calendarSidebar.state_open
                                ? "#b5005b75"
                                : "#b5005b75",
                        },
                    }}
                >
                    <img src={commonTaskVector} alt="" />
                </IconButton> */}

                <Stack direction="row" gap="25px" justifyContent={"end"}>
                    <SidebarOpenMenu
                        onClickAction={(l: any) => {
                            handleOpenSidebarButton(l);
                        }}
                    />
                </Stack>
            </Box>
        </Container>
    );
};

export default memo(CalendarTabs);
