import React from "react";
import { ToggleButtonGroup, ToggleOptionsTyps } from "../../../Projects/common";

interface ScheduleTypeSelectorProps {
  initialValue?: ToggleOptionsTyps;
  onChange: (selectedOption: ToggleOptionsTyps) => void;
}

export const ScheduleTypeSelectorValues: ToggleOptionsTyps[] = [
  { _id: "1", translatedLabel: "List", value: "list" },
  { _id: "2", translatedLabel: "Day", value: "day" },
  { _id: "3", translatedLabel: "Week", value: "week" },
  { _id: "4", translatedLabel: "Month", value: "month" },
];

const ScheduleTypeSelector: React.FC<ScheduleTypeSelectorProps> = ({
  onChange,
  initialValue,
}) => {
  return (
    <ToggleButtonGroup
      selectedColor="#00767B"
      options={ScheduleTypeSelectorValues}
      initialvalue={initialValue}
      onChange={(data) => onChange(data)}
    />
  );
};

export default ScheduleTypeSelector;
