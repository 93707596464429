import { createSlice } from "@reduxjs/toolkit";
import {GlobalState} from "./globalTypes";
import {createScreenRecording__Request,
    createScreenRecording__Success,
    createScreenRecording__Failure,
    getAllScreenRecordings__Request,
    getAllScreenRecordings__Success,
    getAllScreenRecordings__Failure,
    getScreenRecordingById__Request,
    getScreenRecordingById__Success,
    getScreenRecordingById__Failure,
    updateScreenRecording__Request,
    updateScreenRecording__Success,
    updateScreenRecording__Failure,
    deleteScreenRecording__Request,
    deleteScreenRecording__Success,
    deleteScreenRecording__Failure,
    getCoverImages__Request,
    getCoverImages__Success,
    getCoverImages__Failure,
    saveOrUpdateCoverImage__Request,
    saveOrUpdateCoverImage__Success,
    saveOrUpdateCoverImage__Failure,

    getAllProjectsGlobal__Request,
    getAllProjectsGlobal__Success,
    getAllProjectsGlobal__Failure,
    getAllWorkspaceGlobal__Request,
    getAllWorkspaceGlobal__Success,
    getAllWorkspaceGlobal__Failure,

    getAllFoldersGlobal__Request,
    getAllFoldersGlobal__Success,
    getAllFoldersGlobal__Failure,

    globalSearch__Request,
    globalSearch__Success,
    globalSearch__Failure,

    getAllUserContactsRequest,
    getAllUserContactsSuccess,
    getAllUserContactsFailure,
    getAllCloudStorageFiles__Success,
    getAllCloudStorageFiles__Request,
    getAllCloudStorageFiles__Failure,
    initiateGroupCall__Request,
    initiateGroupCall__Success,
    initiateGroupCall__Failure,

    getCallUserDetails__Request,
    getCallUserDetails__Success,
    getCallUserDetails__Failure,

} from "./GlobalSliceAction";

/**
 * ==============================================
 * Define the initial state for the Layout slice
 * ==============================================
 * The initial state includes boolean flags for
 * different sections of the layout. These flags
 * control the visibility of drawers in the application.
 * ==============================================
 */
const initialState: GlobalState = {
    allScreenRecordings:[],
    error: null,
    loading: false,
    coverImages: {
        messageCenterCoverImageUrl: "",
        calenderCenterCoverImageUrl: "",
        emailCenterCoverImageUrl: "",
        callCenterCoverImageUrl: "",
        dashboardCoverImageUrl: "",
    },
    userProjects: [],
    userWorkspaces: [],
    userContacts: [],
    userTimezone: "",
    cloudStorageFolders: [],
    cloudStorageFiles: [],
    globalSearchResult: [],
    groupCallState: {
        incomingCallRinging: null,
        callInProgress: false,
        isVideoCall: false,
        isCallAccepted: false,
        chimeCallDetails: {},
        callDetails: {},
        callData: null,
        isUserIsHost: false,
        userAccountDetails: {},
        realtimeAttendees: [],
        realTimeCallAttendee: [],
        isUserInCall: false,
        callHostDetails: {},
    },
};

/**
 * ==============================================
 * Create a slice for Layout logic
 * ==============================================
 * The layoutSlice handles the layout-related state
 * and actions. It contains a reducer to manage
 * the state changes based on dispatched actions.
 * ==============================================
 */
const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        // global screen recording actions
        createScreenRecording__Request,
        createScreenRecording__Success,
        createScreenRecording__Failure,
        getAllScreenRecordings__Request,
        getAllScreenRecordings__Success,
        getAllScreenRecordings__Failure,
        getScreenRecordingById__Request,
        getScreenRecordingById__Success,
        getScreenRecordingById__Failure,
        updateScreenRecording__Request,
        updateScreenRecording__Success,
        updateScreenRecording__Failure,
        deleteScreenRecording__Request,
        deleteScreenRecording__Success,
        deleteScreenRecording__Failure,

        // global cover image actions
        saveOrUpdateCoverImage__Request,
        saveOrUpdateCoverImage__Success,
        saveOrUpdateCoverImage__Failure,

        getCoverImages__Request,
        getCoverImages__Success,
        getCoverImages__Failure,

        getAllProjectsGlobal__Request,
        getAllProjectsGlobal__Success,
        getAllProjectsGlobal__Failure,

        getAllWorkspaceGlobal__Request,
        getAllWorkspaceGlobal__Success,
        getAllWorkspaceGlobal__Failure,

        getAllFoldersGlobal__Request,
        getAllFoldersGlobal__Success,
        getAllFoldersGlobal__Failure,

        globalSearch__Request,
        globalSearch__Success,
        globalSearch__Failure,

        getAllUserContactsRequest,
        getAllUserContactsSuccess,
        getAllUserContactsFailure,

        getAllCloudStorageFiles__Success,
        getAllCloudStorageFiles__Request,
        getAllCloudStorageFiles__Failure,

        getCallUserDetails__Request,
        getCallUserDetails__Success,
        getCallUserDetails__Failure,

        setUserTimezone: (state, action) => {
            state.userTimezone = action.payload;
        },

        setCallData: (state, action) => {
            state.groupCallState.callData = action.payload?.meeting?.meeting;
            state.groupCallState.isUserInCall = true;
            state.groupCallState.incomingCallRinging = true;
            state.groupCallState.isVideoCall = action.payload?.callType === "video";
            state.groupCallState.chimeCallDetails = action.payload?.meeting?.chimeMeeting;
            state.groupCallState.isUserIsHost = action.payload?.meeting?.meeting?.meetingHostId === action?.payload?.senderDetails?._id;
            state.groupCallState.callHostDetails = action.payload?.senderDetails;
        },

        setUserAccountDetailsForCall : (state, action) => {
            state.groupCallState.userAccountDetails = action.payload;
        },

        initiateGroupCall__Request,
        initiateGroupCall__Success,
        initiateGroupCall__Failure,

        handleRejectCall: (state) => {
            state.groupCallState.callData = null;
            state.groupCallState.incomingCallRinging = false;
            state.groupCallState.isCallAccepted = false;
            state.groupCallState.callInProgress = false;
            state.groupCallState.chimeCallDetails = {};
            state.groupCallState.callDetails = {};
            state.groupCallState.isUserIsHost = false;
            state.groupCallState.isUserInCall = false;
            state.groupCallState.callHostDetails = {};
        },

        handleAcceptCall: (state) => {
            state.groupCallState.incomingCallRinging = false;
            state.groupCallState.isCallAccepted = true;
            state.groupCallState.callInProgress = true;
        }
    },
});

/**
 * ==============================================
 * Export actions and reducer
 * ==============================================
 * The changeLayoutState action is exported for
 * dispatching changes to the layout state.
 * The reducer is exported to be used in the store
 * configuration.
 * ==============================================
 */
export const {
    // global screen recording actions
    createScreenRecording__Request: createScreenRecording__RequestAction,
    createScreenRecording__Success:createScreenRecording__SuccessAction,
    createScreenRecording__Failure:createScreenRecording__FailureAction,
    getAllScreenRecordings__Request:getAllScreenRecordings__RequestAction,
    getAllScreenRecordings__Success:getAllScreenRecordings__SuccessAction,
    getAllScreenRecordings__Failure:getAllScreenRecordings__FailureAction,
    getScreenRecordingById__Request:getScreenRecordingById__RequestAction,
    getScreenRecordingById__Success:getScreenRecordingById__SuccessAction,
    getScreenRecordingById__Failure:getScreenRecordingById__FailureAction,
    updateScreenRecording__Request:updateScreenRecording__RequestAction,
    updateScreenRecording__Success:updateScreenRecording__SuccessAction,
    updateScreenRecording__Failure:updateScreenRecording__FailureAction,
    deleteScreenRecording__Request:deleteScreenRecording__RequestAction,
    deleteScreenRecording__Success:deleteScreenRecording__SuccessAction,
    deleteScreenRecording__Failure:deleteScreenRecording__FailureAction,

    // global cover image actions
    saveOrUpdateCoverImage__Request: saveOrUpdateCoverImage__RequestAction,
    saveOrUpdateCoverImage__Success: saveOrUpdateCoverImage__SuccessAction,
    saveOrUpdateCoverImage__Failure: saveOrUpdateCoverImage__FailureAction,

    getCoverImages__Request:getCoverImages__RequestAction,
    getCoverImages__Success:getCoverImages__SuccessAction,
    getCoverImages__Failure:getCoverImages__FailureAction,

    getAllProjectsGlobal__Request:getAllProjectsGlobal__RequestAction,
    getAllProjectsGlobal__Success:getAllProjectsGlobal__SuccessAction,
    getAllProjectsGlobal__Failure:getAllProjectsGlobal__FailureAction,

    getAllWorkspaceGlobal__Failure:getAllWorkspaceGlobal__FailureAction,
    getAllWorkspaceGlobal__Request:getAllWorkspaceGlobal__RequestAction,
    getAllWorkspaceGlobal__Success:getAllWorkspaceGlobal__SuccessAction,

    getAllFoldersGlobal__Request:getAllFoldersGlobal__RequestAction,
    getAllFoldersGlobal__Success:getAllFoldersGlobal__SuccessAction,
    getAllFoldersGlobal__Failure:getAllFoldersGlobal__FailureAction,

    getAllUserContactsRequest:getAllUserContactsRequestAction,
    getAllUserContactsSuccess:getAllUserContactsSuccessAction,
    getAllUserContactsFailure:getAllUserContactsFailureAction,

    globalSearch__Request:globalSearch__RequestAction,
    globalSearch__Success:globalSearch__SuccessAction,
    globalSearch__Failure:globalSearch__FailureAction,

    getAllCloudStorageFiles__Failure:getAllCloudStorageFiles__FailureAction,
    getAllCloudStorageFiles__Request:getAllCloudStorageFiles__RequestAction,
    getAllCloudStorageFiles__Success:getAllCloudStorageFiles__SuccessAction,

    initiateGroupCall__Request: initiateGroupCall__RequestAction,
    initiateGroupCall__Success: initiateGroupCall__SuccessAction,
    initiateGroupCall__Failure: initiateGroupCall__FailureAction,

    setCallData: setCallDataAction,

    setUserAccountDetailsForCall: setUserAccountDetailsForCallAction,

    getCallUserDetails__Request: getCallUserDetails__RequestAction,
    getCallUserDetails__Success: getCallUserDetails__SuccessAction,
    getCallUserDetails__Failure: getCallUserDetails__FailureAction,

    setUserTimezone: setUserTimezoneAction,
    handleAcceptCall: handleAcceptCallAction,
    handleRejectCall: handleRejectCallAction,

} = globalSlice.actions;

export default globalSlice.reducer;
