import { Stack } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../../../Utils";

interface NamePreviewProps {
  name: string;
}

const NamePreview: React.FC<NamePreviewProps> = ({ name }) => {
  return (
    <Stack
      sx={{
        height: "20px",
        padding: "0px 8px",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px",
        bgcolor: adjustHexColor("#ffffff", 50),
        font: "normal normal 400 13px/16px Source Serif Pro",
        whiteSpace: "nowrap",
      }}
    >
      {name}
    </Stack>
  );
};

export default NamePreview;
