import React, { useState } from "react";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";

interface AutoSizeTextAreaProps {
  TableData: any;
}

const AutoSizeTextArea = ({ TableData }: AutoSizeTextAreaProps) => {
  const [isEditing, setIsEditing] = useState(false); // Toggle between view and edit mode
  const [text, setText] = useState(
    TableData?.value || TableData?.data?.allData
    ?.company?.description
  ); // Manage the text state

  // Styled Textarea
  const Textarea = styled(BaseTextareaAutosize)(`
    box-sizing: border-box;
    width: 100%;
    font-family: 'Source Serif Pro', 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    resize: none;
    color: #303740; /* Dark grey */
    background: #f3f6f9; /* Light grey */
    border: 1px solid #b0b8c4; /* Grey border */
    &:focus {
      outline: none;
      border-color: #3399ff; /* Blue focus border */
      box-shadow: 0 0 0 3px rgba(51, 153, 255, 0.3); /* Blue focus shadow */
    }
  `);

  return (
    <div style={{ maxWidth: "250px" }}>
      {isEditing ? (
        <Textarea
          maxRows={2}
          aria-label="Description"
          value={text}
          onChange={(e) => setText(e.target.value)} // Update text state
          onBlur={() => setIsEditing(false)} // Exit edit mode on blur
          autoFocus
        />
      ) : (
        <Typography
          variant="body2"
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
          onClick={() => setIsEditing(true)} // Enter edit mode on click
        >
          {text}
        </Typography>
      )}
    </div>
  );
};

export default AutoSizeTextArea;
