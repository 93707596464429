import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";

interface MsgMiniCardProps {
  unreadMsgCount?: number;
  isSelected?: boolean;
  highlightColor?: string;
  hostImgUrl: string;
  label: string;
  headerComponent: React.ReactNode;
  onCardClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onCardRightClick?: (event: React.MouseEvent<HTMLElement>) => void; // Add this prop
}

const MsgMiniCard: React.FC<MsgMiniCardProps> = ({
  isSelected,
  unreadMsgCount = 0,
  highlightColor = "#775EE2",
  headerComponent,
  hostImgUrl,
  label,
  onCardClick,
                                                     onCardRightClick, // Add this prop
}) => {
  return (
    <Button
      onClick={(e) => onCardClick(e)}
      onContextMenu={(e) => {
          e.preventDefault();
          if (onCardRightClick) {
              onCardRightClick(e);
          }
      }}
      sx={{
        height: "30px",
        bgcolor: isSelected
          ? adjustHexColor(highlightColor, 50)
          : "transparent",
        ":hover": {
          bgcolor: isSelected
            ? adjustHexColor(highlightColor, 50)
            : adjustHexColor(highlightColor, 20),
        },
        flexShrink: 0,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        boxSizing: "border-box",
        padding: "0px 3px 0px 5px",
        gap: "5px",
        width: "100%",
        textTransform: "none",
        color: highlightColor,
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "3px" }}>
        {/* <MessageAvatarChip userMsgStatus={userMsgStatus} /> */}
        {headerComponent}

        <Box
          component={"img"}
          src={hostImgUrl}
          alt="logo"
          sx={{ height: "17px", width: "17px" }}
        />

        <Typography
          sx={{
            font: "normal normal 400 13px/16px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: unreadMsgCount && unreadMsgCount > 0 ? "115px" : "150px",
            color: "black",
          }}
        >
          {label}
        </Typography>
      </Stack>

      {unreadMsgCount > 0 && (
        <Stack
          sx={{
            height: "17px",
            minWidth: "20px",
            bgcolor: "#D9D9D9",
            flexShrink: 0,
            borderRadius: "5px",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 2px",
            boxSizing: "border-box",
          }}
        >
          <Typography
            sx={{
              font: "normal normal 900 12px/15px Source Serif Pro",
              color: highlightColor,
            }}
          >
            {unreadMsgCount < 10 ? `0${unreadMsgCount}` : unreadMsgCount}
          </Typography>
        </Stack>
      )}
    </Button>
  );
};

export default MsgMiniCard;
