export interface InviteBarUserListInterface {
    department: string,
    teamName: string,
    userName: string,
    // email: string,
    avatarColor?: string,
    imgSrc?: string,
}

export const DUMMY_userList_InviteBar: InviteBarUserListInterface[] = [
    {
        department: "Marketing",
        teamName: "Creative Team",
        userName: "Alice Johnson",
        avatarColor: "blue",
        imgSrc: "https://randomuser.me/api/portraits/women/1.jpg",
    },
    {
        department: "Finance",
        teamName: "Audit Team",
        userName: "Michael Smith",
        avatarColor: "red",
        imgSrc: "https://randomuser.me/api/portraits/men/2.jpg",
    },
    {
        department: "Engineering",
        teamName: "Development Team",
        userName: "Sophia Brown",
        avatarColor: "orange",
        imgSrc: "https://randomuser.me/api/portraits/women/3.jpg",
    },
    {
        department: "Human Resources",
        teamName: "Recruitment Team",
        userName: "James Wilson",
        avatarColor: "purple",
        imgSrc: "https://randomuser.me/api/portraits/men/4.jpg",
    },
    {
        department: "Operations",
        teamName: "Logistics Team",
        userName: "Emily Davis",
        avatarColor: "green",
        imgSrc: "https://randomuser.me/api/portraits/women/5.jpg",
    },
    {
        department: "Sales",
        teamName: "Client Relations",
        userName: "Oliver Garcia",
        avatarColor: "cyan",
        imgSrc: "https://randomuser.me/api/portraits/men/6.jpg",
    },
    {
        department: "Product",
        teamName: "Management Team",
        userName: "Emma Martinez",
        avatarColor: "yellow",
        imgSrc: "https://randomuser.me/api/portraits/women/7.jpg",
    },
    {
        department: "Support",
        teamName: "Customer Support",
        userName: "Liam Anderson",
        avatarColor: "pink",
        imgSrc: "https://randomuser.me/api/portraits/men/8.jpg",
    },
    {
        department: "Design",
        teamName: "UI/UX Team",
        userName: "Charlotte Lee",
        avatarColor: "teal",
        imgSrc: "https://randomuser.me/api/portraits/women/9.jpg",
    },
    {
        department: "IT",
        teamName: "Network Team",
        userName: "Noah Taylor",
        avatarColor: "brown",
        imgSrc: "https://randomuser.me/api/portraits/men/10.jpg",
    },
];
