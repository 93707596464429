import { Dispatch } from "@reduxjs/toolkit"; // Adjust this import to match your Redux setup
import {
    cmd__update__calendar_api,
    eventResponse__api,
    recallAPI,
    update__cmd__api,
} from "../../../../redux";






/**
 * Handles updating a calendar event (PUT or DELETE).
 *
 * @param dispatch - The Redux dispatch function.
 * @param nId - The calendar event ID.
 * @param status - The status or snooze duration.
 * @param method - The HTTP method ("PUT" or "DELETE").
 */
export const handle_CMD_PLANS___utils = async (
    dispatch: Dispatch,
    nId: any,
    status: any,
    method: "PUT" | "DELETE"
) => {
    try {
        const data =
            method === "PUT"
                ? { status }
                : { action: "SNOOZE", snoozeDuration: status };

        const action = cmd__update__calendar_api(nId, data, method);
        const response: any = await dispatch(action);

        if (response?.success) {
            dispatch(recallAPI("cmd"));
        }
    } catch (error) {
        console.error("Error in handleUpdateCmd:", error);
    }
};

/**
 * Handles responding to a calendar event (accept, reject, etc.).
 *
 * @param dispatch - The Redux dispatch function.
 * @param responseType - The response type (e.g., "accepted", "rejected", etc.).
 * @param bookingLinkDatas - The booking link data containing calendar and event info.
 */
export const handle_CMD_EVENTS_PLANS___utils = async (
    dispatch: Dispatch,
    responseType: string,
    bookingLinkDatas: any,
    user_id?: any
) => {
    try {
        const { notifyData, variant } = bookingLinkDatas || {};

        if (
            !notifyData?.calendarId ||
            !notifyData?.eventId ||
            !responseType ||
            !variant
        ) {
            return;
        }

        const action = eventResponse__api(
            notifyData.calendarId,
            notifyData.eventId,
            responseType,
            variant === "@booking_links" ? "host" : "guest",
            { email: "", userId: user_id }
        );

        const response: any = await dispatch(action);

        if (response?.success) {
            if (responseType === "rejected") {
                dispatch(recallAPI("getAllCalEvent"));
            }
            dispatch(recallAPI("cmd"));
        }
    } catch (error) {
        console.error("Error in handleEventResponse:", error);
    }
};

/**
 * Updates command control data for a specific task.
 *
 * @param dispatch - The Redux dispatch function.
 * @param taskId - The ID of the task to update.
 * @param status - The new status of the task.
 * @param fetchCommandControlData - A function to fetch the updated command control data.
 */
export const handle_CRM_WORKSPACE___utils = async (
    dispatch: Dispatch,
    taskId: string,
    status: string,
    fetchCommandControlData?: () => void
) => {
    try {
        if (!taskId || !status) {
            return;
        }

        const payload = {
            userQuote: status, // ["Overdue", "Done", "Task"]
        };

        const action = update__cmd__api(taskId, payload);
        const response: any = await dispatch(action);

        if (response?.success && fetchCommandControlData) {
            fetchCommandControlData();
        }
    } catch (error) {
        console.error("Error in updateCommandControlData:", error);
    }
};
