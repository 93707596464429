import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor, getFileIcon } from "../../../../../../../Utils";
import { XCircleIcon } from "../../../../../../../images";
import { SearchLoader } from "../../../../../../common";

interface SelectedFileViewProps {
  fileName: string;
  isLoading?: boolean;
  onDeleteButtonClick?: () => void;
}

const SelectedFileView: React.FC<SelectedFileViewProps> = ({
  fileName,
  isLoading,
  onDeleteButtonClick,
}) => {
  return (
    <Stack
      sx={{
        height: "35px",
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        flexShrink: 0,
      }}
    >
      <Stack
        sx={{
          width: "20px",
          bgcolor: adjustHexColor("#A53A01", 25),
          borderRadius: "5px 0px 0px 5px",
          height: "100%",
        }}
      />
      <Stack
        sx={{
          height: "100%",
          boxSizing: "border-box",
          padding: "0px 5px",
          width: "calc(100% - 20px)",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px dashed #E9EDF2",
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            width: "calc(100% - 25px)",
          }}
        >
          {getFileIcon({ name: fileName, iconSize: 20 })}
          <Typography
            sx={{
              font: "normal normal 300 12px/15px Source Serif Pro",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {fileName}
          </Typography>
        </Stack>

        <Stack sx={{ pr: isLoading ? "2px" : "0px" }}>
          {isLoading ? (
            <SearchLoader w={20} />
          ) : (
            <IconButton sx={{ p: "2px" }} onClick={onDeleteButtonClick}>
              <XCircleIcon w={20} />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SelectedFileView;
