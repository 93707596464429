import { PayloadAction } from "@reduxjs/toolkit";
import { CalendarState } from "../CalendarType";
import { getAllEvents__Failure } from "./CalendarFailure";

// Successes
export const LoginExternalCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Successes
export const AuthExternalCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Successes
export const createCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateCalendarAppearance__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  try {
    const updatedCalendar = action?.payload?.data;

    if (!updatedCalendar) {
      console.error("Update failed: Missing updated calendar data in payload.");
      return;
    }

    if (!updatedCalendar._id) {
      console.error("Update failed: Missing _id in the updated calendar.");
      return;
    }

    // Find the calendar in the list by matching _id
    const calendarIndex = state.allCalendarList.findIndex(
      (calendar: any) => calendar._id === updatedCalendar._id
    );

    if (calendarIndex === -1) {
      console.error(
        `Update failed: Calendar with _id ${updatedCalendar._id} not found.`
      );
      return;
    }

    // Safely update only icon, color, and isAliveToken in the calendar list
    const updatedCalendarData = {
      ...state.allCalendarList[calendarIndex], // Spread the existing properties
      icon: updatedCalendar.icon || state.allCalendarList[calendarIndex].icon,
      color:
        updatedCalendar.color || state.allCalendarList[calendarIndex].color,
      isAliveToken:
        updatedCalendar.isAliveToken !== undefined
          ? updatedCalendar.isAliveToken
          : state.allCalendarList[calendarIndex].isAliveToken,
    };

    // Update the calendar list with the updated calendar data
    state.allCalendarList[calendarIndex] = updatedCalendarData;

    // Optionally, update the general state with the updated calendar data
    state.data = updatedCalendarData;

    // Now update the events associated with the calendar
    state.allCalenderEventsList = state.allCalenderEventsList.map(
      (event: any) => {
        if (event.calendarId === updatedCalendar._id) {
          // If the event is associated with the updated calendar, update its cardColor and iconName
          return {
            ...event,
            cardColor: updatedCalendar.color || event.cardColor, // Use updated color or keep the existing one
            iconName: updatedCalendar.icon || event.iconName, // Use updated icon or keep the existing one
          };
        }
        return event; // No change for events not associated with the updated calendar
      }
    );
  } catch (error) {
    console.error(
      "An error occurred while updating the calendar appearance and events:",
      error
    );
  }
};

export const getSharedWith__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
  state.memberOfCalendar = action?.payload?.data;
};

export const shareCalendarWithUser__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateEventDate__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const updateEventTaskStatus__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const subscribeToICal__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const subscribeToUserCal__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllCalendars__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
  state.allCalendarList = action?.payload?.data;
  state.isGetAllCalendarList = false;
  state.lastApiCallTime = Date.now(); // Set timestamp after successful API call
};

export const getOneCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getOneEvent__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const createCalendarSettings__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getCalendarSettings__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
  state.calSettings = action?.payload?.data;
};

export const updateCalendarSettings__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
  state.calSettings = action?.payload?.data; // Ensure calSettings is also updated
};

export const addConnectedCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const removeConnectedCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const createEvent__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllEvents__Success = (
  state: CalendarState,
  action: PayloadAction<{ data: any[] }>
): void => {
  const data = action?.payload?.data;

  // Ensure the data is valid (non-null, non-undefined, and non-empty)
  if (!Array.isArray(data) || !data.length) {
    return getAllEvents__Failure(state, action as any);
  }

  // Update state with the fetched data
  state.data = data;
  state.allCalenderEventsList = data;
};


/* delete event id from  */
export const deleteOneEvents__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  const { eventId } = action.payload?.data;
  state.allCalenderEventsList = state.allCalenderEventsList.filter(
    (event: any) => event._id !== eventId
  );
};

export const createTask__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllTasks__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const createBookingLink__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getBookingLinks__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const eventResponse__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Successes
export const createSchedule__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllSchedules__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.scheduleList = action?.payload?.data;
  state.data = action?.payload?.data;
};

export const getOneSchedule__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateSchedule__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteSchedule__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllBookingLinks__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.allBookingList = action?.payload?.data;
};

export const trackVisit__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getPublicBookingLink__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getPrivateBookingLink__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAvailableTimeSlots__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const createBookingEvent__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const cmd__calendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  // Ensure action.payload.data is an array before filtering
  const data = action?.payload?.data;

  // Check if `data` is an array, otherwise default to an empty array
  const filteredData = Array.isArray(data)
    ? data.filter((item: any) => item.center === "PLAN")
    : [];

  // Store only the filtered data
  state.cmd__Notifications = filteredData;
};
