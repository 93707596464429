import {MESSAGE_ENUMS} from "../components/pages/Message/constant";

const getChannelType = (channelId: string) => {
    if (channelId.length === 11) return MESSAGE_ENUMS.SLACK;
    if (channelId.length === 24) return MESSAGE_ENUMS.BEARISH;
    return MESSAGE_ENUMS.TEAMS;
};

function formatHtmlContentToSlackContent(html: any) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    function processNode(node: any) {
        if (node.nodeType === Node.TEXT_NODE) {
            return node.textContent.trim() + " ";
        }
        let result = "";

        for (let child of node.childNodes) {
            if (child.nodeName === "STRONG") {
                result += "*" + processNode(child) + "* ";
            } else if (child.nodeName === "EM") {
                result += "_" + processNode(child) + "_ ";
            } else if (child.nodeName === "BR") {
                result += "\n"; // Replace <br> with newline
            } else {
                result += processNode(child);
            }
        }
        return result;
    }
    return processNode(tempDiv).trim();
}

export {
    getChannelType,
    formatHtmlContentToSlackContent
};