import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import {CardScrollableContainerView, CopyButtonView, UserCardView,} from "../../view";
import {woollyAtTheSea} from "../../../../../../../images";
import {useTranslation} from "react-i18next";

interface AddedUserTabViewProps {
    joinedUserList?: any;
}

const AddedUserTabView: React.FC<AddedUserTabViewProps> = ({
                                                               joinedUserList = {},
                                                           }) => {
    const {t} = useTranslation()
    const userList = joinedUserList && typeof joinedUserList === 'object' ? joinedUserList : {};
    return (
        <>
            {Object.keys(userList)?.length > 0 ? (
                <CardScrollableContainerView
                    hideMoreButton={Object.keys(userList)?.length <= 5}
                >
                    {Object.keys(userList).map((key: any, index: any) => {
                        const item = userList[key];

                        return (
                            <UserCardView
                                cardType="joined"
                                isMic={item?.isMicOn || false}
                                isVideo={item?.isVideoOn || false}
                                userEmail={item?.email}
                                userName={item?.userName}
                                darkBg={index % 2 === 0}
                                key={index}
                                avatar={item?.avatar || ""}
                            />
                        );
                    })}
                </CardScrollableContainerView>
            ) : (
                <Stack sx={{alignItems: "center", gap: "30px"}}>
                    <Typography
                        sx={{font: "normal normal 400 15px/19px Source Serif Pro"}}
                    >
                        {t("VIDEO_CENTER.MEETING.LOOKS_LIKE")}
                    </Typography>
                    <Box
                        component={"img"}
                        src={woollyAtTheSea}
                        alt="Bear png"
                        sx={{height: "150px", width: "150px", marginTop: "8px"}}
                    />

                    <CopyButtonView linkValue="linkNameHere"/>
                </Stack>
            )}
        </>
    );
};

export default AddedUserTabView;
