import { IconButton, Stack, Typography, Popover } from "@mui/material";
import React, { useState } from "react";
import { AddCalPlusIcon, ChevronIcon } from "../../../../../images";
import { CreateNewMessages } from "../../components";

interface MsgDrawerContainerProps {
  children?: React.ReactNode;
  boxMaxHeight?: string;
  label: string;
  hideAddButton?: boolean;
  defaultExpended?: boolean;
  addButtonComponent?: React.ReactNode;
  selectedTab?: number;
    marginTop?: string;
}

const MsgDrawerContainer: React.FC<MsgDrawerContainerProps> = ({
  children,
  label,
  boxMaxHeight = "165px",
  hideAddButton = false,
  defaultExpended = false,
  addButtonComponent,
                                                                   selectedTab,
    marginTop = "20px"
}) => {
  const [isExpended, setIsExpended] = useState<boolean>(defaultExpended);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleAddButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "add-button-popover" : undefined;

  return (
    <Stack
      sx={{
        maxHeight: isExpended ? "100%" : "57px",
        overflow: "hidden",
        flexShrink: 0,
        transition: "max-height 0.3s ease-in-out",
        paddingLeft: "3px",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          mb: "6px",
          mt: marginTop,
        }}
      >
        <Typography
          sx={{ font: "normal normal 900 12px/15px Source Serif Pro",paddingLeft: "5px" }}
        >
          {label}
        </Typography>

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          {!hideAddButton && (
            <>
              <IconButton onClick={handleAddButtonClick}>
                <AddCalPlusIcon w={15} color="#757575" />
              </IconButton>
              <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {addButtonComponent ? (
                  addButtonComponent
                ) : (
                  <CreateNewMessages selectedTab={selectedTab} handleGetBackClick={() => handleClose()} />
                )}
              </Popover>
            </>
          )}

          <IconButton onClick={() => setIsExpended((pre) => !pre)}>
            <ChevronIcon w={15} direction={isExpended ? "up" : "down"} />
          </IconButton>
        </Stack>
      </Stack>

      <Stack sx={{ gap: "4px", overflow: "auto", scrollbarWidth: "none" }}>
        {children}
      </Stack>
    </Stack>
  );
};

export default MsgDrawerContainer;
