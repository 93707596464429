import { createApiAction } from "../constantApi_config";
import {
  /* Add Comment to Task */
  addCommentToTask__FailureAction,
  addCommentToTask__RequestAction,
  addCommentToTask__SuccessAction,

  /* Add Comment to Document */
  addCommentToDocument__FailureAction,
  addCommentToDocument__RequestAction,
  addCommentToDocument__SuccessAction,

  /* Add Comment to Whiteboard */
  addCommentToWhiteboard__FailureAction,
  addCommentToWhiteboard__RequestAction,
  addCommentToWhiteboard__SuccessAction,

  /* Add Comment to Project */
  addCommentToProject__FailureAction,
  addCommentToProject__RequestAction,
  addCommentToProject__SuccessAction,

  /* Toggle Reaction to Comment */
  toggleReactionToComment__FailureAction,
  toggleReactionToComment__RequestAction,
  toggleReactionToComment__SuccessAction,

  /* Add Comment to Cell */
  addCommentToCell__FailureAction,
  addCommentToCell__RequestAction,
  addCommentToCell__SuccessAction,

  /* Add Sub Comment to Comment */
  addSubCommentToComment__FailureAction,
  addSubCommentToComment__RequestAction,
  addSubCommentToComment__SuccessAction,

  /* Toggle Archive Comment */
  toggleArchiveComment__FailureAction,
  toggleArchiveComment__RequestAction,
  toggleArchiveComment__SuccessAction,

  /* Toggle Resolve Comment */
  toggleResolveComment__FailureAction,
  toggleResolveComment__RequestAction,
  toggleResolveComment__SuccessAction,

  /* Edit Comment */
  editComment__FailureAction,
  editComment__RequestAction,
  editComment__SuccessAction,

  /* Get All Sub Comments */
  getAllSubComments__FailureAction,
  getAllSubComments__RequestAction,
  getAllSubComments__SuccessAction,

  /* Get All Comments */
  getAllComments__FailureAction,
  getAllComments__RequestAction,
  getAllComments__SuccessAction,
} from "./CommentsSlice";

/* Add Comment to Task */
export const addCommentToTask__api = (payload: {
  taskId: string;
  content: string;
  mentions: string[];
}) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    url: "/comment/add-comment-to-task",
    data: payload,
    onStart: addCommentToTask__RequestAction.type,
    onSuccess: addCommentToTask__SuccessAction.type,
    onError: addCommentToTask__FailureAction.type,
  });

/* Add Comment to Document */
export const addCommentToDocument__api = (payload: {
  documentId: string;
  content: string;
  mentions?: string[];
}) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    url: "/comment/add-comment-to-document",
    data: payload,
    onStart: addCommentToDocument__RequestAction.type,
    onSuccess: addCommentToDocument__SuccessAction.type,
    onError: addCommentToDocument__FailureAction.type,
  });

/* Add Comment to Whiteboard */
export const addCommentToWhiteboard__api = (payload: {
  whiteboardId: string;
  content: string;
  mentions: string[];
}) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    url: "/comment/add-comment-to-whiteboard",
    data: payload,
    onStart: addCommentToWhiteboard__RequestAction.type,
    onSuccess: addCommentToWhiteboard__SuccessAction.type,
    onError: addCommentToWhiteboard__FailureAction.type,
  });

/* Add Comment to Project */
export const addCommentToProject__api = (payload: {
  projectId: string;
  content: string;
  mentions: string[];
}) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    url: "/comment/add-comment-to-project",
    data: payload,
    onStart: addCommentToProject__RequestAction.type,
    onSuccess: addCommentToProject__SuccessAction.type,
    onError: addCommentToProject__FailureAction.type,
  });

/* Toggle Reaction to Comment */
export const toggleReactionToComment__api = (
  commentId: string,
  payload: { emoji: string }
) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    url: `/comment/toggle-reaction-to-comment/${commentId}`,
    data: payload,
    onStart: toggleReactionToComment__RequestAction.type,
    onSuccess: toggleReactionToComment__SuccessAction.type,
    onError: toggleReactionToComment__FailureAction.type,
  });

/* Add Comment to Cell */
export const addCommentToCell__api = (payload: {
  gridId: string;
  sheetId: string;
  cellId: string;
  content: string;
  mentions: string[];
}) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    url: "/comment/add-comment-to-cell",
    data: payload,
    onStart: addCommentToCell__RequestAction.type,
    onSuccess: addCommentToCell__SuccessAction.type,
    onError: addCommentToCell__FailureAction.type,
  });

/* Add Sub Comment to Comment */
export const addSubCommentToComment__api = (payload: {
  commentId: string;
  content: string;
  mentions: string[];
}) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    url: `/comment/add-sub-comment-to-comment/${payload.commentId}`,
    data: payload,
    onStart: addSubCommentToComment__RequestAction.type,
    onSuccess: addSubCommentToComment__SuccessAction.type,
    onError: addSubCommentToComment__FailureAction.type,
  });

/* Toggle Archive Comment */
export const toggleArchiveComment__api = (commentId: string) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    url: `/comment/toggle-archive-comment/${commentId}`,
    onStart: toggleArchiveComment__RequestAction.type,
    onSuccess: toggleArchiveComment__SuccessAction.type,
    onError: toggleArchiveComment__FailureAction.type,
  });

/* Toggle Resolve Comment */
export const toggleResolveComment__api = (commentId: string) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    url: `/comment/toggle-resolve-comment/${commentId}`,
    onStart: toggleResolveComment__RequestAction.type,
    onSuccess: toggleResolveComment__SuccessAction.type,
    onError: toggleResolveComment__FailureAction.type,
  });

/* Edit Comment */
export const editComment__api = (payload: {
  commentId: string;
  content: string;
  mentions: string[];
}) =>
  createApiAction({
    axiosRequired: true,
    method: "PUT",
    url: `/comment/edit-comment/${payload.commentId}`,
    data: payload,
    onStart: editComment__RequestAction.type,
    onSuccess: editComment__SuccessAction.type,
    onError: editComment__FailureAction.type,
  });

/* Get All Sub Comments */
export const getAllSubComments__api = (
  commentId: string,
  page: number = 1,
  limit: number = 10,
  sort: "asc" | "desc" = "asc"
) =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    url: `/comment/get-all-sub-comments?commentId=${commentId}&page=${page}&limit=${limit}&sort=${sort}`,
    onStart: getAllSubComments__RequestAction.type,
    onSuccess: getAllSubComments__SuccessAction.type,
    onError: getAllSubComments__FailureAction.type,
  });

/* Get All Comments */
export const getAllComments__api = (entityId: string, entityName: string) =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    url: `/comment/get-all-comments?entityId=${entityId}&entityName=${entityName}`,
    onStart: getAllComments__RequestAction.type,
    onSuccess: getAllComments__SuccessAction.type,
    onError: getAllComments__FailureAction.type,
  });

export const addCommentsToFiles__api = (payload: any) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    data: payload,
    url: `/comment/add-comment-to-file`,
    onStart: ` AddCommentToFiles__RequestAction.type`,
    onSuccess: `AddCommentToFiles__SuccessAction.type`,
    onError: `AddCommentToFiles__FailureAction.type`,
  });

export const addCommentsToFolders__api = (payload: any) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    data: payload,
    url: `/comment/add-comment-to-folder`,
    onStart: ` AddCommentToFiolders_RequestAction.type`,
    onSuccess: `AddCommentToFiolders_SuccessAction.type`,
    onError: `AddCommentToFiolders_FailureAction.type`,
  });
