import * as React from "react";
import {
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  InputBase,
  Tooltip,
} from "@mui/material";
import S from "./HighPriorityMenuDropdown.module.css";
import { GreenDropdownIcon } from "../../../images";
import { HighPriorityMenuDropdownOptionTypes } from "./HighPriorityMenuDropdownTypes";
import { ColorPickerDot } from "../ColorPickerDot";

interface HighPriorityMenuDropdownProps {
  options?: HighPriorityMenuDropdownOptionTypes[];
  componentHeader?: string;
  width?: string;
  onChange?: (selectedOption: string) => void;
}

const DUMMY_DATA: HighPriorityMenuDropdownOptionTypes[] = [
  {
    id: 1,
    value: "Low Priority",
    defaultColorHex: "#90EE90",
    customColorHex: "#90EE90",
  },
  {
    id: 2,
    value: "Medium Priority",
    defaultColorHex: "#FFD700",
    customColorHex: "#FFD700",
  },
  {
    id: 3,
    value: "High Priority",
    defaultColorHex: "#FF4500",
    customColorHex: "#FF4500",
  },
];

const HighPriorityMenuDropdown: React.FC<HighPriorityMenuDropdownProps> =
  React.memo(
    ({
      options = DUMMY_DATA,
      componentHeader,
      // multiple = false,
      width = "125px",
      onChange,
      ...props
    }) => {
      const [personName, setPersonName] = React.useState<string[]>([]);
      const [open, setOpen] = React.useState(false);
      const [localOptions, setLocalOptions] =
        React.useState<HighPriorityMenuDropdownOptionTypes[]>(options);
      const [addingCustom, setAddingCustom] = React.useState(false);
      const [newCustomValue, setNewCustomValue] = React.useState("");
      const [newCustomColor, setNewCustomColor] = React.useState("#007BB2");

      const handleChange = React.useCallback(
        (event: SelectChangeEvent<string[]>) => {
          const {
            target: { value },
          } = event;
          setPersonName(typeof value === "string" ? value.split(",") : value);

          if (onChange) {
            const selectedValue =
              typeof value === "string" ? value : value.join(", ");
            onChange(selectedValue);
          }
        },
        [onChange]
      );

      const stopPropagation = (
        event: React.MouseEvent | React.KeyboardEvent
      ) => {
        event.stopPropagation();
      };

      const handleAddCustomClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setAddingCustom(true);
      };

      const handleSaveCustomClick = () => {
        if (newCustomValue?.trim() !== "") {
          const newCustomOption: HighPriorityMenuDropdownOptionTypes = {
            id: localOptions.length + 1,
            value: newCustomValue,
            defaultColorHex: newCustomColor,
            customColorHex: newCustomColor,
          };
          setLocalOptions([...localOptions, newCustomOption]);
        }
        setAddingCustom(false);
        setNewCustomValue("");
        setNewCustomColor("#000000");
      };

      const handleClose = () => {
        if (addingCustom && newCustomValue?.trim() !== "") {
          handleSaveCustomClick();
        }
        setAddingCustom(false);
        setOpen(false);
      };

      const handleColorChange = (id: number, color: string) => {
        const updatedOptions = localOptions.map((option) =>
          option.id === id ? { ...option, customColorHex: color } : option
        );
        setLocalOptions(updatedOptions);
      };

      const MenuProps = {
        PaperProps: {
          className: S["high-priority-menu-dropdown__menu"],
          style: { width: width },
        },
      };

      return (
        <div>
          <FormControl size="small" style={{ width }} {...props}>
            <Select
              // multiple={multiple}
              displayEmpty
              IconComponent={() => (
                <GreenDropdownIcon
                  open={open}
                  sx={{ width: "40%", height: "40%", marginRight: "2px" }}
                />
              )}
              value={personName}
              onChange={handleChange}
              input={
                <OutlinedInput
                  className={S["high-priority-menu-dropdown__inputbox"]}
                  classes={{
                    notchedOutline:
                      S["high-priority-menu-dropdown__notchedOutline"],
                    focused: S["high-priority-menu-dropdown__focused"],
                  }}
                />
              }
              onOpen={() => setOpen(true)}
              onClose={handleClose}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return (
                    <em
                      className={
                        S["high-priority-menu-dropdown__selectPlaceholder"]
                      }
                    >
                      {componentHeader}
                    </em>
                  );
                }
                return (
                  <span className={S["high-priority-menu-dropdown__selected"]}>
                    <span
                      style={{
                        maxWidth: "75px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                      }}
                    >
                      {selected?.join(", ")}
                    </span>
                    <span
                      onMouseDown={stopPropagation}
                      onClick={stopPropagation}
                    >
                      <ColorPickerDot />
                    </span>
                  </span>
                );
              }}
              MenuProps={MenuProps}
            >
              {localOptions.map((item) => (
                <MenuItem
                  className={S["high-priority-menu-dropdown__menu-item"]}
                  key={item.id}
                  value={item.value}
                >
                  <ListItemText
                    primary={item.value}
                    primaryTypographyProps={{
                      className:
                        S["high-priority-menu-dropdown__list-item-text"],
                    }}
                  />
                  <div onMouseDown={stopPropagation} onClick={stopPropagation}>
                    <ColorPickerDot
                      initialColor={item.customColorHex}
                      onColorChange={(color) =>
                        handleColorChange(item.id, color)
                      }
                    />
                  </div>
                </MenuItem>
              ))}
              {addingCustom ? (
                <Stack className={S["high-priority-menu-dropdown__menu-item"]}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <InputBase
                      value={newCustomValue}
                      onChange={(e) => setNewCustomValue(e.target.value)}
                      placeholder="Enter text"
                      fullWidth
                      onMouseDown={stopPropagation}
                      onClick={stopPropagation}
                      onKeyDown={stopPropagation} // Prevent key events from propagating
                      onKeyUp={stopPropagation} // Prevent key events from propagating
                      sx={{
                        height: "20px",
                        border: "0.5px solid #D9E3EF",
                        font: "normal normal 300 12px/16px Source Serif Pro",
                      }}
                    />
                    <div
                      onMouseDown={stopPropagation}
                      onClick={stopPropagation}
                    >
                      <ColorPickerDot
                        onColorChange={(color) => setNewCustomColor(color)}
                      />
                    </div>
                  </Stack>
                </Stack>
              ) : (
                <MenuItem
                  className={S["high-priority-menu-dropdown__menu-item"]}
                >
                  <Tooltip title="Add new priority" placement="right" arrow>
                    <ListItemText
                      onClick={(e) => handleAddCustomClick(e)}
                      primary="Add Custom"
                      primaryTypographyProps={{
                        className:
                          S["high-priority-menu-dropdown__list-item-text"],
                      }}
                    />
                  </Tooltip>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      );
    }
  );

export default HighPriorityMenuDropdown;
