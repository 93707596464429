import { Typography } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";

const PrivacyPolicyTextView: React.FC = () => {
  return (
    <Typography
      sx={{
        font: "normal normal 300 13px/16px Source Serif Pro",
        width: "450px",
      }}
    >
      <Trans
        i18nKey="DASHBOARD.DATA_PRIVACY_COMMITMENT"
        components={{
          span: (
            <span
              style={{ color: "black", fontWeight: 700, cursor: "pointer" }}
              onClick={() => console.log("privacy policy page")}
            />
          ),
        }}
      />
    </Typography>
  );
};

export default PrivacyPolicyTextView;
