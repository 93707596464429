import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  Stack,
  Typography,
  Avatar,
} from "@mui/material";
import {
  CloseIcon,
  HDotsIcon,
  MicIcon,
  PaintRollerIcon,
  VideoCallIcon,
} from "../../../../../../../images";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addCommentsToFiles__api,
  getAllComments__api,
} from "../../../../../../../redux";
import g from "./CommentFilePanel.module.css";

type CommentFilePanelProps = {handlePanelClose?:any};

const CommentFilePanel: React.FC<CommentFilePanelProps> = ({handlePanelClose}) => {
  const [comment, setComment] = useState<string>(""); // For input field
  const [displayComments, setDisplayComments] = useState<any[]>([]); // Store fetched comments
  const dispatch = useDispatch();
  const { fileId, fileType } = useParams<{ fileId?: string; fileType?: string }>();

  // Fetch all comments when the component mounts or fileId changes
  useEffect(() => {
    if (fileId) fetchAllComments();
  }, [fileId]);

  // Fetch all comments for a file
  const fetchAllComments = async () => {
    try {
      const action = getAllComments__api(fileId as string, "file");
      const response: any = await dispatch(action);

      if (response?.success) {
        setDisplayComments(response.data);
      } else {
        console.error("Failed to fetch comments:", response.message);
      }
    } catch (err) {
      console.error("Error fetching comments:", err);
    }
  };

  // Add a new comment and fetch the updated list
  const handleAddCommentToFile = async () => {
    if (!fileId || !fileType || !comment.trim()) return; // Validate input

    try {
      const payload = {
        fileId,
        content: comment,
        mentions: [], // Handle mentions if required
      };

      const action = addCommentsToFiles__api(payload);
      const response: any = await dispatch(action);

      if (response?.success) {
        setComment(""); // Clear the input field
        await fetchAllComments(); // Fetch updated comments
      } else {
        console.error("Failed to add comment:", response.message);
      }
    } catch (err) {
      console.error("Error adding comment:", err);
    }
  };

  return (
    <Box
      sx={{
        borderLeft: "1px solid #f4f4f4",
        height: "100%",
        marginLeft: "10px",
        fontFamily: "Source Serif Pro, serif",
        padding: "15px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {/* Header */}
      <Stack direction="row" justifyContent="space-between" alignItems="center" pb={1}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Source Serif Pro, serif",
            fontWeight: 600,
          }}
        >
          Comments
        </Typography>
        <Stack direction="row" gap={1}>
          <IconButton>
            <HDotsIcon w={15} />
          </IconButton>
          <IconButton onClick={() => {handlePanelClose?.()}}>
            <CloseIcon w={15} />
          </IconButton>
        </Stack>
      </Stack>
      <Divider sx={{ borderColor: "#E9EDF2" }} />

      {/* Display Comments */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          paddingY: "10px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {displayComments.length > 0 ? (
          displayComments.map((comment) => (
            <Stack
              key={comment._id}
              direction="row"
              alignItems="flex-start"
              spacing={2}
            >
              <Avatar
                src={comment.createdBy.avatar}
                alt={comment.createdBy.firstName}
                sx={{ width: 40, height: 40 }}
              />
              <Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                    fontFamily: "Source Serif Pro, serif",
                  }}
                >
                  {`${comment.createdBy.firstName} ${comment.createdBy.lastName}`}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#555",
                    fontFamily: "Source Serif Pro, serif",
                  }}
                >
                  {comment.content}
                </Typography>
              </Box>
            </Stack>
          ))
        ) : (
          <Typography
            sx={{
              fontSize: "14px",
              color: "#999",
              textAlign: "center",
              marginTop: "20px",
              fontFamily: "Source Serif Pro, serif",
            }}
          >
            No comments yet.
          </Typography>
        )}
      </Box>

      {/* Add Comment */}
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{
          borderTop: "1px solid #E9EDF2",
          paddingTop: "10px",
        }}
      >
        <PaintRollerIcon w={31} />
        <InputBase
          className={g.comment_file_input}
          type="text"
          placeholder="Type your text here"
          value={comment} // Bind to state
          onChange={(e) => setComment(e.target.value)} // Update state on input change
          sx={{
            flexGrow: 1,
            fontFamily: "Source Serif Pro, serif",
          }}
        />
        <MicIcon w={31} />
        <VideoCallIcon w={31} />
        <Button
          variant="contained"
          size="small"className={g.button__comment}
          onClick={handleAddCommentToFile} // Trigger API call on button click
          disabled={!comment.trim()} // Disable button if comment is empty
          sx={{
            textTransform: "none",
            fontFamily: "Source Serif Pro, serif",
          }}
        >
          Comment
        </Button>
      </Stack>
    </Box>
  );
};

export default CommentFilePanel;
