import { Stack, Typography } from "@mui/material";
import React from "react";
import {
  CheckboxMedium,
  EventIconButton,
  TextLinkButton,
} from "../../../../../../common";
import { AllDirArrowCircleIcon } from "../../../../../../../images";

interface HeaderViewProps {
  onCloseClick: () => void;
  onGlobalRoleSettingsClick: () => void;
  isCheckboxSelected: boolean;
  checkboxClick: () => void;
}

const HeaderView: React.FC<HeaderViewProps> = ({
  onGlobalRoleSettingsClick,
  onCloseClick,
  isCheckboxSelected,
  checkboxClick,
}) => {
  return (
    <Stack
      sx={{
        height: "55px",
        padding: "0px 15px",
        borderBottom: "1px solid #E9EDF2",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        flexShrink: 0,
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <EventIconButton
          buttonTitle="Close"
          icon={AllDirArrowCircleIcon}
          iconColor="black"
          iconDirection="left"
          onClick={onCloseClick}
        />

        <Stack
          onClick={() => checkboxClick()}
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
        >
          <CheckboxMedium checked={isCheckboxSelected} />
          <Typography
            sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
          >
            Make a template
          </Typography>
        </Stack>
      </Stack>

      <TextLinkButton
        buttonLabel="Global Role Settings"
        onClick={onGlobalRoleSettingsClick}
      />
    </Stack>
  );
};

export default HeaderView;
