import React from "react";
import {
  TextNormalDropdown,
  TextNormalDropdownOptionsType,
} from "../../../../common";
import { Link, Stack } from "@mui/material";

const jobLocationDropDownOptions: TextNormalDropdownOptionsType[] = [
  {
    _id: "1",
    title: "Headquarters",
    value: "Headquarters",
    subTitle: "CA, USA",
  },
  {
    _id: "2",
    title: "Office",
    value: "Office",
    subTitle: "SF, CA, USA",
  },
  {
    _id: "3",
    title: "Remote",
    value: "Remote",
  },
];

interface JobLocationsDropdownProps {}

const JobLocationsDropdown: React.FC<JobLocationsDropdownProps> = ({}) => {
  return (
    <TextNormalDropdown
      placeholder="Select workspace location..."
      //   initialValue={jobLocationDropDownOptions[2]}
      options={jobLocationDropDownOptions}
      onSelect={(value) => console.log(value)}
      additionalOption={
        <Stack
          sx={{
            font: "normal italic 300 12px/15px Source Serif Pro",
            color: "#0027FF",
            display: "block",
          }}
        >
          <Link
            underline="hover"
            sx={{
              cursor: "pointer",
              color: "inherit",
              display: "inline-block",
            }}
          >
            Add workplace location
          </Link>
        </Stack>
      }
    />
  );
};

export default JobLocationsDropdown;
