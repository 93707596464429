// TaskInterface/core-component/TabView.tsx
import React from "react";
import { Tabs, Tab, Box, Stack } from "@mui/material";
import { RenderWorkspaceIcon } from "../../../../../../Utils";

interface TabViewProps {
    activeTab: string;
    availableTabs: { label: string }[];
    handleTabChange: (event: React.SyntheticEvent, newValue: string) => void;
}


const getTabsStyles = (bg?: any) => ({
    "& .MuiTabs-indicator": {
        backgroundColor: bg ? bg : "#b2481f",
        height: "3px",
        borderRadius: "2px",
    },
    "& .MuiTab-root": {
        minWidth: 100,
        fontWeight: "bold",
        textTransform: "none",
        padding: "2px 4px",
        marginBottom: "-45px",
        fontFamily: "Source Serif Pro",
    },
});


const renderTabContent = (label: string) => (
    <Box display="flex" alignItems="center">
        {label && (
            <RenderWorkspaceIcon component={label as any} w={20} color="black" />
        )}
        <Box ml={1}>{label}</Box>
    </Box>
);


const renderSingleTab = (tab: { label: string }, index: number) => (
    <Tab
        key={tab.label || index}
        value={tab.label}
        label={renderTabContent(tab.label)}
        iconPosition="start"
    />
);

const TabView: React.FC<TabViewProps> = ({
    activeTab,
    availableTabs,
    handleTabChange,
}) => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
            <Tabs
                value={activeTab}
                indicatorColor="primary"
                textColor="inherit"
                onChange={handleTabChange}
                sx={getTabsStyles("#B5005B")}
            >
                {availableTabs.map((tab, index) => renderSingleTab(tab, index))}
            </Tabs>
        </Stack>
    );
};

export default TabView;
