export const availableViews = [
  { label: "Checklist" },
  { label: "List" },
  { label: "Tree" },
  { label: "Gantt" },
  { label: "Calendar" },
  { label: "Resource" },
];

interface AssignedUser {
  _id: string;
  firstName: string;
  lastName: string;
  avatar: string;
  email: string;
}

interface Priority {
  id: number;
  value: string;
  defaultColorHex: string;
  customColorHex: string;
}

interface Status {
  id: number;
  value: string;
  defaultColorHex: string;
  customColorHex: string;
}

interface Task {
  _id: string;
  name: string;
  details: string;
  subTasks: string[];
  assignedUsers: AssignedUser[];
  priority: Priority;
  dueDate: string;
  status: Status;
  taskType: string;
  cardColor: string;
  emoji: string;
  createdBy: AssignedUser;
  isSubTask: boolean;
  estimatedTime: number;
  dependencies: number;
  customElements: Record<string, any>;
  componentId: string;
  workspaceId: string;
  isDeleted: boolean;
  checklists: string[];
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface TaskGroup {
  groupName: string;
  tasks: Task[];
}

export type UserAllTasks = TaskGroup[];
