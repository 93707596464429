// TaskInterface/core-component/AddViewMenu.tsx
import React from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";

interface AddViewMenuProps {
    availableViews: { label: string }[];
    handleAddView: (viewLabel: string) => void;
    anchorEl: null | HTMLElement;
    handleMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
    handleMenuClose: () => void;
}

const AddViewMenu: React.FC<AddViewMenuProps> = ({
    availableViews,
    handleAddView,
    anchorEl,
    handleMenuOpen,
    handleMenuClose,
}) => {
    return (
        <Box mt={2}>
            <Button
                variant="contained"
                color="primary"
                onClick={handleMenuOpen}
                sx={{
                    marginBottom: 2,
                    backgroundColor: "transparent",
                    textTransform: "capitalize",
                    font: "normal normal 300 15px/17px Source Serif Pro", // Normal font weight
                    color: "black",
                    boxShadow: "none",
                    "&:hover": {
                        fontWeight: 600, // Make text bold on hover
                        backgroundColor: "transparent", // Ensure no background change on hover
                        boxShadow: "none",
                    },
                }}
            >
                Add View
            </Button>


            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {availableViews.map((view) => (
                    <MenuItem key={view.label} onClick={() => handleAddView(view.label)}>
                        {view.label}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default AddViewMenu;
