import * as React from "react";
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import S from "./ShareOptionDropdown.module.css";
import {
  CompanyIcon,
  ConnectedIcon,
  GreenDropdownIcon,
  LockIcon,
} from "../../../../../images";

interface ShareOptionDropdownProps {
  width?: string; // Add width prop
  onChange?: (selectedOption: string) => void;
  initialSelectedOptions?: string; // Add initial selected option prop
}

interface Option {
  id: number;
  icon: React.ElementType;
  title: string;
  subtitle: string;
}

const OPTIONS: Option[] = [
  {
    id: 1,
    icon: LockIcon,
    title: "Invite only",
    subtitle: "Only you and people you invite can view and edit",
  },
  {
    id: 2,
    icon: CompanyIcon,
    title: "Organizational access",
    subtitle: "Anyone apart of your organizations can view, comment, or edit",
  },
  {
    id: 3,
    icon: ConnectedIcon,
    title: "Link",
    subtitle: "Anyone with the link can view, edit or comment",
  },
];

const ShareOptionDropdown: React.FC<ShareOptionDropdownProps> = React.memo(
  ({
    width = "105px", // Default to full width
    onChange,
    initialSelectedOptions = OPTIONS[0].title, // Default to empty string
    ...props
  }) => {
    const [selectedOption, setSelectedOption] = React.useState<string>(
      initialSelectedOptions
    );
    const [isOpen, setIsOpen] = React.useState(false); // Manage open state

    const handleChange = React.useCallback(
      (event: SelectChangeEvent<string>) => {
        const {
          target: { value },
        } = event;
        setSelectedOption(value);

        if (onChange) {
          onChange(value);
        }
      },
      [onChange]
    );

    const selectedOptionData = OPTIONS.find(
      (option) => option.title === selectedOption
    );

    const menuProps = {
      PaperProps: {
        className: S["share-option-dropdown__menu"],
        style: { width: "210px" },
      },
      anchorOrigin: {
        vertical: "bottom" as const,
        horizontal: "left" as const,
      },
      transformOrigin: {
        vertical: "top" as const,
        horizontal: "left" as const,
      },
    };

    return (
      <div>
        <FormControl
          size="small"
          style={{ width }} // Apply the width prop dynamically
          {...props}
        >
          <Select
            displayEmpty
            IconComponent={() => (
              <GreenDropdownIcon
                open={!isOpen}
                sx={{ width: "40%", height: "40%", marginRight: "2px" }}
              />
            )} // Pass open state as prop
            value={selectedOption}
            onChange={handleChange} // Use handleChange function
            input={
              <OutlinedInput
                className={S["share-option-dropdown__inputbox"]}
                classes={{
                  notchedOutline: S["share-option-dropdown__notchedOutline"],
                  focused: S["share-option-dropdown__focused"],
                }}
              />
            }
            onOpen={() => setIsOpen(true)} // Handle open state
            onClose={() => setIsOpen(false)} // Handle close state
            renderValue={(selected) => {
              if (!selected) {
                return (
                  <em
                    className={S["share-option-dropdown__select-placeholder"]}
                  >
                    {"Select option"}
                  </em>
                );
              }

              return (
                <span className={S["share-option-dropdown__selected"]}>
                  {selectedOptionData && <selectedOptionData.icon w={12} />}
                  <span style={{ paddingLeft: "5px" }}>{selected}</span>
                </span>
              );
            }}
            MenuProps={menuProps}
          >
            {OPTIONS.map((option) => (
              <MenuItem
                className={S["share-option-dropdown__menu-item"]}
                key={option.id}
                value={option.title}
              >
                <Stack
                  direction="row"
                  alignItems="start"
                  gap={"5px"}
                  pl={"5px"}
                  mb={"5px"}
                >
                  <option.icon w={15} />
                  <Stack sx={{ flexDirection: "column", gap: "2px" }}>
                    <Typography className={S["share-option-dropdown__title"]}>
                      {option.title}
                    </Typography>
                    <Typography
                      className={S["share-option-dropdown__subtitle"]}
                    >
                      {option.subtitle}
                    </Typography>
                  </Stack>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
);

export default ShareOptionDropdown;
