import { Stack } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../../../Utils";
import { MuteMicIconMute } from "../../../../../../../images";

const MutedMicView: React.FC = () => {
  return (
    <Stack
      sx={{
        height: "25px",
        width: "25px",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px",
        bgcolor: adjustHexColor("#ffffff", 50),
      }}
    >
      <MuteMicIconMute w={20} />
    </Stack>
  );
};

export default MutedMicView;
