import { AvatarProps } from "@mui/material";

export enum AvatarSize {
  Large = 80,
  Small = 35,
}

export enum BgColorCode {
  Default = "#833BC3",
  Purple = "#4500FF",
  DarkBlue = "#0027FF",
  Blue = "#833BC3",
  LightBlue = "#007BB2",
  Green = "#008D4C",
  Yellow = "#FFB100",
  Red = "#FF0000",
  Gold = "#E0D700",
  LightGreen = "#21B481",
  Teal = "#59D0D5",
  lightBlueWhite = "#E9EDF2",
  
  // Orange Variants
  LightOrange = "#FFA500",  // Classic Orange
  DarkOrange = "#FF8C00",   // Dark Orange
  PeachOrange = "#FF7F50",  // Peach Orange
  CoralOrange = "#FF6347",  // Coral Orange
  LightSalmon = "#FFA07A",  // Light Salmon
  OrangeRed = "#FF4500",    // Orange Red
}


export interface AvatarLargeProps extends AvatarProps {
  size: AvatarSize;
  bgColorCode: BgColorCode; // Ensure bgColorCode is mandatory
  imgSrc?: string;
  text?: string;
}
