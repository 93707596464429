import {Stack} from "@mui/material";
import React, {lazy, Suspense} from "react";
import MsgPreviewSidebarTabs from "../MsgPreviewSidebarTabs/MsgPreviewSidebarTabs";

interface RightSidebarsProps {
    selectedSidebar: string;
    handleSetSelectedSidebar?:any;
}

const LazyOpenCalenderSidebar = lazy(() => import("./sidebars/OpenCalender/OpenCalender"));
const LazyStartCallSidebar = lazy(() => import("./sidebars/StartCall/StartCall"));
const LazyStartMeetingSidebar = lazy(() => import("./sidebars/StartMeeting/StartMeeting"));
const LazyOpenTasksSidebar = lazy(() => import("./sidebars/OpenTasks/OpenTasks"));
const LazyOpenCloudSidebar = lazy(() => import("./sidebars/OpenCloud/OpenCloud"));
const LazyOpenMailSidebar = lazy(() => import("./sidebars/OpenMail/OpenMail"));

const RightSidebars: React.FC<RightSidebarsProps> = ({selectedSidebar, handleSetSelectedSidebar}) => {

    const renderSidebar = () => {
        switch (selectedSidebar) {
            case "Open Calendar":
                return <LazyOpenCalenderSidebar handleSetSelectedSidebar={handleSetSelectedSidebar} />;
            case "Start Call":
                return <LazyStartCallSidebar/>;
            case "Start Meeting":
                return <LazyStartMeetingSidebar/>;
            case "Open Tasks":
                return <LazyOpenTasksSidebar/>;
            case "Open Cloud":
                return <LazyOpenCloudSidebar/>;
            case "Open Mail":
                return <LazyOpenMailSidebar/>;
            case "ChatDetails":
                return <MsgPreviewSidebarTabs/>;
            default:
                return <MsgPreviewSidebarTabs/>;
        }
    };

    return (
        <Stack
            sx={{
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                gap: "25px",
            }}
        >
            <Stack
                sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px",
                }}
            >
                <Suspense fallback={<div>Loading...</div>}>
                    {renderSidebar()}
                </Suspense>
            </Stack>
        </Stack>
    );
};

export default RightSidebars;