import React from "react";
import { CheckboxFilledIcon } from "../../../../../../../images";
import { Stack, Tooltip } from "@mui/material";

const FilledShiftInfoView: React.FC = () => {
  return (
    <Tooltip
      title={
        <Stack
          sx={{
            width: "165px",
            height: "35px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            font: "normal normal 300 11px/14px Source Serif Pro",
          }}
        >
          All shifts created for this day are currently filled.
        </Stack>
      }
      placement="bottom"
      arrow
    >
      <span>
        <CheckboxFilledIcon color="#21B481" />
      </span>
    </Tooltip>
  );
};

export default FilledShiftInfoView;
