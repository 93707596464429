import React, { useEffect, useState } from "react";

type TextFileViewerProps = {
  path: string;
};

const TextFileViewer: React.FC<TextFileViewerProps> = ({ path }) => {
  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    // Fetch the content of the text file
    const fetchTextFile = async () => {
      try {
        const response = await fetch(path);
        const text = await response.text();
        setContent(text);
      } catch (error) {
        console.error("Error fetching text file:", error);
        setContent(null);
      }
    };

    fetchTextFile();
  }, [path]);

  if (!content) {
    return <div>Loading content...</div>;
  }

  return (
    <div>
      <h3>Text File Viewer</h3>
      <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
        {content}
      </pre>
    </div>
  );
};

export default TextFileViewer;
