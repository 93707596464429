import { Box, Modal, RadioGroup, Stack } from "@mui/material";
import React, { useState } from "react";
import { ScopeDropDown } from "../../../ScopeDropDown";
import {
  CustomRadioFormControlLabel,
  DUMMY_AVATAR_USERS,
  SmallGroupedAvatars,
  TextLinkButton,
} from "../../../../../../common";
import { adjustHexColor } from "../../../../../../../Utils";
import { SearchBarUsersTrax } from "../../../SearchBarUsersTrax";

interface ScopeVisibilityViewProps {}

const ScopeVisibilityView: React.FC<ScopeVisibilityViewProps> = () => {
  const [scopeType, setScopeType] = useState<string>("optional");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Stack sx={{ gap: "10px", alignItems: "end" }}>
      <ScopeDropDown />
      <TextLinkButton
        buttonLabel="Search and Select"
        sx={{ width: "fit-content" }}
        onClick={handleOpenModal}
      />
      <SmallGroupedAvatars usersData={DUMMY_AVATAR_USERS} />

      <RadioGroup
        aria-labelledby="create-shift-menu"
        name="create-shift-menu"
        value={scopeType}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setScopeType(event.target.value)
        }
        sx={{ flexDirection: "row", gap: "10px" }}
      >
        <CustomRadioFormControlLabel
          sx={{ gap: "5px" }}
          value="optional"
          label="Optional"
        />
        <CustomRadioFormControlLabel
          sx={{ gap: "5px" }}
          value="mandatory"
          label="Mandatory"
        />
      </RadioGroup>

      {/* Modal */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        BackdropProps={{
          sx: {
            backgroundColor: adjustHexColor("#000000", 20),
            border: "0px",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minHeight: "462px",
          }}
        >
          <SearchBarUsersTrax />
        </Box>
      </Modal>
    </Stack>
  );
};

export default ScopeVisibilityView;
