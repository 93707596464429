import React from "react";
import { AttendanceStatusType } from "../CrmStatusBadge";
import { Stack } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  AttendanceListInterface,
  TimeCardTableHeadersList,
  timeCardTableRowsWidth,
} from "./TimeCardTableType";
import { AbsentCard, DayOffCard, OnTimecard, SickDayCard } from "./card";
import { ApprovalActionArea, CellWrapper, DateView, HeaderCell } from "./view";

interface TimeCardTableProps {
  tableDataList: AttendanceListInterface[];
}

const getRowCard = (item: AttendanceListInterface): React.ReactNode => {
  switch (item.attendanceType) {
    case "absent":
      return <AbsentCard data={item} />;
    case "dayoff":
      return <DayOffCard data={item} />;
    case "holiday":
      return <DayOffCard data={item} />;
    case "late":
      return <OnTimecard data={item} />;
    case "onTime":
      return <OnTimecard data={item} />;
    case "pto":
      return <SickDayCard data={item} />;
    case "sick":
      return <SickDayCard data={item} />;
    default:
      return <div>unknown card</div>;
  }
};

const getBgcolor = (cardType: AttendanceStatusType) => {
  switch (cardType) {
    case "absent":
    case "dayoff":
    case "holiday":
    case "pto":
    case "sick":
      return "#FAF3F3";

    default:
      return "#ffffff";
  }
};

const TimeCardTable: React.FC<TimeCardTableProps> = ({ tableDataList }) => {
  return (
    <TableContainer
      sx={{
        height: "100%",
        scrollbarWidth: "none",
        boxShadow: "none",
        borderRadius: 0,
        border: "1px solid #E9EDF2",
        borderRight: "0px solid #E9EDF2 !important",
      }}
      component={Paper}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                borderBottom: "1px solid #E9EDF2",
                height: "30px",
                padding: 0,
                boxSizing: "border-box",
                minHeight: 0,
              }}
            >
              <Stack sx={{ flexDirection: "row", height: "100%" }}>
                {TimeCardTableHeadersList.map((item, index) => (
                  <HeaderCell
                    key={index}
                    icon={item.icon}
                    colWidth={item.colWidth}
                    translatedLabel={item.translatedLabel}
                    iconColor={item.iconColor}
                  />
                ))}
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableDataList.map((row, index) => (
            <Stack
              key={index}
              sx={{
                flex: 1,
                borderBottom: "1px solid #E9EDF2",
                height: "65px",
                flexDirection: "row",
                alignItems: "center",
                boxSizing: "border-box",
                bgcolor: getBgcolor(row.attendanceType),
              }}
            >
              <CellWrapper colWidth={timeCardTableRowsWidth.DateWidth}>
                <DateView attendanceType={row.attendanceType} />
              </CellWrapper>
              {getRowCard(row)}
              <CellWrapper
                colWidth={timeCardTableRowsWidth.ApprovalWidth}
                sx={{
                  padding: 0,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ApprovalActionArea
                  approvalStatus={row.approvalStatus}
                  cardType={row.attendanceType}
                  isOvertime={!!true}
                />
              </CellWrapper>
            </Stack>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TimeCardTable;
