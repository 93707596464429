import {
  InputAdornment,
  InputBase,
  InputLabel,
  Stack,
  TextFieldProps,
} from "@mui/material";
import React, { useState, useEffect } from "react";

// Define a type that includes the required label and icon props
type MediumInputIconBoxProps = TextFieldProps & {
  label: string;
  icon?: React.ReactNode;
  value: string | undefined | null;
  placeholder?: string;
  onChange: (
    value: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  multiline?: boolean; // Optional multiline prop
  name?: string; // Optional name
  customePass?: boolean;
  disabled__?: boolean;
};

const MediumInputIconBox: React.FC<MediumInputIconBoxProps> = ({
  label,
  icon,
  value,
  onChange,
  placeholder,
  sx,
  multiline = false, // Default multiline to false
  name = "",
  customePass = false,
  disabled__ = false,
}) => {
  const [inputValue, setInputValue] = useState<any>(value || ""); // Store input value

  // Update the state if the `value` prop changes
  useEffect(() => {
    if (value !== undefined && value !== inputValue) {
      setInputValue(value || "");
    }
  }, [value]); // The effect runs every time the `value` prop changes

  // Define an internal onChange handler that calls the provided onChange prop
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = customePass ? e : e.target.value;
    setInputValue(newValue as any); // Store the updated input value in state
  };

  // Define onBlur handler
  const handleBlur = () => {
    onChange(inputValue); // Return the final value on blur
  };

  const sxObject = sx as Record<string, any>;

  const defaultInputHeight = 40;
  const inputHeight = sxObject?.height
    ? parseInt(sxObject.height, 10)
    : defaultInputHeight;
  const parentHeight = inputHeight + 18;

  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      sx={{ height: `${parentHeight}px` }}
    >
      <InputLabel
        sx={{
          font: "normal normal normal 10px/13px Source Serif Pro",
          color: "black",
        }}
      >
        {label}
      </InputLabel>
      <InputBase
        value={inputValue} // Use the internal state value
        name={name}
        onBlur={handleBlur} // Trigger onBlur handler to send the value
        disabled={disabled__}
        onChange={handleChange} // Handle onChange event
        placeholder={placeholder}
        multiline={multiline} // Enable multiline if the prop is true
        minRows={multiline ? 1 : undefined} // Set minimum rows if multiline
        sx={{
          height: `${inputHeight}px`,
          border: "0.5px solid #E9EDF2",
          borderRadius: "5px",
          font: "normal normal normal 15px/21px Source Serif Pro",
          gap: "10px",
          px: "10px",
          width: "200px",
          overflow: "hidden", // Hide overflow text
          overflowY: multiline ? "scroll" : "hidden", // Make scrollable if multiline
          "&::-webkit-scrollbar": {
            width: "0px", // Hide scrollbar width for WebKit browsers
          },
          display: "flex",
          alignItems: multiline ? "flex-start" : "center", // Align top if multiline, center otherwise
          paddingTop: multiline ? "10px" : "0px", // Add padding-top if multiline
          ...sx,
        }}
        startAdornment={
          icon && (
            <InputAdornment
              sx={{
                height: "20px",
                width: "20px",
                padding: "0px",
                margin: "0px",
              }}
              position="start"
            >
              {icon}
            </InputAdornment>
          )
        }
      />
    </Stack>
  );
};

export default MediumInputIconBox;
