import React from "react";
import { styled } from "@mui/system";
/* ----------------------------->PROPS_S */
export type PlanDrawerProps = {
    drawerOpen: boolean;
    drawerCloser: () => void;
};
/* ----------------------------->PROPS_E */

/* MAIN DRAWER_CONTAINER */
export const DrawerContainer = styled("div")<{
    top?: string;
    height?: string;
    backgroundColor?: string;
}>(({ top, height, backgroundColor }) => ({
    position: "fixed",
    top: top || "0", // Default to 0 if no top is provided
    height: height || "100vh", // Default to 100vh if no height is provided
    zIndex: 1100,
    // backgroundColor: backgroundColor || "transparent", // Default to transparent if not provided
}));

/* DRAWR_STYLE */
// utils/DrawerStyles.ts
export const drawerStyles = ({
    drawerOpen,
    width = "215px",
    transitionDuration = "225ms",
}: {
    drawerOpen: boolean;
    width?: string;
    transitionDuration?: string;
}) => ({
    position: "absolute",
    top: 0,
    height: "100vh",
    width: width,
    transition: `left ${transitionDuration} ease-in-out`,
    left: drawerOpen ? "30px" : `-${width}`,
    zIndex: 1100, // Ensure the drawer is behind the navbar
    "& .MuiPaper-root": {
        position: "absolute",
        top: 0,
        height: "100vh",
        width: width,
        left: 0,
        boxSizing: "border-box",
        zIndex: 1100, // Ensure the paper is also behind the navbar
    },
});


