import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { Skeleton } from "@mui/material";

interface EmailLoaderProps {
    maxCount?: number;
    children?: React.ReactNode; // Make children optional
}

const EmailLoader: React.FC<EmailLoaderProps> = ({
    maxCount = 6,
    children,
}) => {
    const emailsLoader = useSelector(
        (state: RootState) => state?.emails?.is_allEmail_fetching
    );
    const [skeletonCount, setSkeletonCount] = useState(2);

    useEffect(() => {
        if (emailsLoader) {
            const interval = setInterval(() => {
                setSkeletonCount((prevCount) => {
                    if (prevCount < maxCount) {
                        return prevCount + 2;
                    }
                    // Reset to the initial value (2) when maxCount is reached
                    return 2;
                });
            }, 1000);

            return () => clearInterval(interval);
        }

        return undefined;
    }, [emailsLoader, maxCount]);

    return (
        <>
            {emailsLoader ? (
                <div>
                    {Array.from({ length: skeletonCount }).map((_, index) => (
                        <Skeleton
                            key={index}
                            variant="rectangular"
                            width="100%"
                            height={40}
                            sx={{
                                borderRadius: "15px",
                                marginBottom: "15px",
                            }}
                        />
                    ))}
                </div>
            ) : (
                children // Render children when emailsLoader is false
            )}
        </>
    );
};

export default EmailLoader;
