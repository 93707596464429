import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/material";
import { CommandIconPng } from "../../../images";

// Props Interface
interface ImageCheckboxProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  label?: string; // Optional label text
}

const CmdIconChecker: React.FC<ImageCheckboxProps> = ({
  checked,
  onChange,
  label,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        padding: "8px",
        borderRadius: "50%",
        backgroundColor: checked ? "#fff2e5" : "transparent", // Light background when checked
        transition: "background-color 0.3s",
        width: "fit-content",
      }}
    >
      {/* Checkbox with Image */}
      <Checkbox
        checked={checked}
        onChange={(e) => onChange(e.target.checked)} // Passes new state to parent
        icon={
          <img
            src={CommandIconPng}
            alt="Command Icon"
            style={{ width: 18, height: 18 }}
          />
        }
        checkedIcon={
          <img
            src={CommandIconPng}
            alt="Command Icon"
            style={{
              width: 18,
              height: 18,
              filter: "brightness(0.8)", // Slight visual change when checked
            }}
          />
        }
      />
      {/* Optional Label */}
      {label && (
        <span
          style={{
            fontSize: "14px",
            fontWeight: 500,
            color: checked ? "#333" : "#777",
          }}
        >
          {label}
        </span>
      )}
    </Box>
  );
};

export default CmdIconChecker;
