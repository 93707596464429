import {Stack} from "@mui/material";
import React, {lazy, Suspense, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {a11yProps, StyledTab, TabPanel, TabsWithState,} from "../../../../common/CardTabsWithoutIcon";
import {TabLoader} from "../../../../common";
import {getPinnedMessages} from "../../../../../redux/Slice/message/messageActions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import {MessagesState} from "../../../../../redux/Slice/message/messageTypes";

// interface MsgPreviewSidebarTabsProps {}

const LazyInfoTab = lazy(() => import("./tabs/InfoTab/InfoTab"));
const LazyFilesTab = lazy(() => import("./tabs/FilesTab/FilesTab"));
const LazyLinksTab = lazy(() => import("./tabs/LinksTab/LinksTab"));
const LazyPinnedChatTab = lazy(
    () => import("./tabs/PinnedChatTab/PinnedChatTab")
);
const LazyInsightsTab = lazy(() => import("./tabs/InsightsTab/InsightsTab"));

const tabs = [
    {
        label: "Info",
        content: <LazyInfoTab/>,
    },
    {
        label: "Files",
        content: <LazyFilesTab/>,
    },
    {
        label: "Links",
        content: <LazyLinksTab/>,
    },
    {
        label: "Pinned",
        content: <LazyPinnedChatTab/>,
    },
    {
        label: "Insights",
        content: <LazyInsightsTab/>,
    },
];

const MsgPreviewSidebarTabs: React.FC = () => {
    const {t} = useTranslation();
    const [tabValue, setTabValue] = React.useState(0);
    const dispatch = useDispatch();

    const {
        chatTabState,
    } = useSelector((state: RootState) => state.message as MessagesState);

    useEffect(() => {
        if (chatTabState?.selectedConversation?.conversationId) {
            dispatch(getPinnedMessages(chatTabState?.selectedConversation?.conversationId, "BEARISH"));
        }
    }, [chatTabState?.selectedConversation?.conversationId]);

    return (
        <Stack
            sx={{
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                gap: "25px",
            }}
        >
            <Stack
                sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px",
                }}
            >
                <TabsWithState
                    ariaLabel="Trax status tabs"
                    value={tabValue}
                    //   tabsGap="0px"
                    onChange={(event, newValue) => setTabValue(newValue)}
                    tabIndicatorColor="#775EE2"
                    sx={{
                        borderBottom: "0px solid #E9EDF2",
                        "& .css-1aquho2-MuiTabs-indicator": {
                            bottom: "0px !important",
                        },
                    }}
                >
                    {tabs.map((tab, index) => (
                        <StyledTab
                            style={{padding: "5px 0px"}}
                            key={index}
                            disableRipple
                            label={t(tab.label)}
                            {...a11yProps(index)}
                        />
                    ))}
                </TabsWithState>
            </Stack>

            {tabs.map((tab, index) => (
                <TabPanel
                    style={{display: "contents"}}
                    key={index}
                    value={tabValue}
                    index={index}
                >
                    <Suspense fallback={<TabLoader/>}>{tab.content}</Suspense>
                </TabPanel>
            ))}
        </Stack>
    );
};

export default MsgPreviewSidebarTabs;
