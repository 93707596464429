import React from "react";
import { Stack } from "@mui/material";

interface ShiftTypeContainerViewProps {
  titleComponent: React.ReactNode;
  children: React.ReactNode;
  icon: React.ElementType;
  sx?: any;
}

const ShiftTypeContainerView: React.FC<ShiftTypeContainerViewProps> = ({
  titleComponent,
  children,
  icon,
  sx,
}) => {
  return (
    <Stack
      sx={{
        border: "2px solid #E9EDF2",
        height: "75px",
        width: "100%",
        borderRadius: "5px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        boxSizing: "border-box",
        flexShrink: 0,
        ...sx,
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        {React.createElement(icon, { w: 25 })}
        {titleComponent}
      </Stack>

      {children}
    </Stack>
  );
};

export default ShiftTypeContainerView;
