import React, { useEffect, useRef, useState } from "react";
import {
  ButtonSmall,
  MiniPopupContainer,
  MiniPopupContainerLightLabel,
  MiniPopupContainerNormalInput,
  SmallButtonColor,
  useCustomSnackbar,
} from "../../../../../../common";
import { DepartmentIcon } from "../../../../../../../images";
import { Stack } from "@mui/material";
import {
  invitationToJoinOrg__api,
  reCallAdminAPIsHandler,
} from "../../../../../../../redux";
import { useDispatch } from "react-redux";

interface AddAuditorPopupProps {
  onCloseClick: () => void;
}

const AddAuditorPopup: React.FC<AddAuditorPopupProps> = ({ onCloseClick }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>(""); // State to store the email input
  const inputRef = useRef<HTMLInputElement | null>(null); // Reference for the input
  const enqueueSnackbar_v1 = useCustomSnackbar();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus to the input field when component mounts
    }
  }, []); // Handle email input change
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const inviteAuditor = async () => {
    try {
      const payload: any = {
        email: email,
        role: "Auditor",
      };

      const action = invitationToJoinOrg__api(payload); // API call to invite admin to the organization

      const r: any = await dispatch(action); // Wait for the API call to complete

      if (r?.success) {
        dispatch(reCallAdminAPIsHandler("user_list")); // Recall all APIs to fetch the updated data
      }
    } catch (error) {
      console.error(error);
    } finally {
      onCloseClick();
    }
  };

  // Handle save button click
  const handleSaveClick = () => {
    if (email) {
      inviteAuditor(); // Close the popup
    } else {
      enqueueSnackbar_v1("warning", `Please enter a valid email address.`, {
        position: { vertical: "top", horizontal: "center" },
        autoHideDuration: 5000,
      });
    }
  };

  return (
    <MiniPopupContainer
      headerIcon={DepartmentIcon}
      headerTitle="Add an Auditor"
      subtitle="You are about to add an auditor to your Bearish OS account."
    >
      <div>
        <MiniPopupContainerLightLabel label="Email Address" />
        <MiniPopupContainerNormalInput
          inputRef={inputRef}
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter email address"
        />
      </div>

      <Stack sx={{ alignItems: "center" }}>
        <ButtonSmall
          label="Save"
          colorVarient={SmallButtonColor.BearishClay}
          onClick={handleSaveClick}
        />
      </Stack>
    </MiniPopupContainer>
  );
};

export default AddAuditorPopup;
