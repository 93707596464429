// TaskInterface/core-component/TabContent.tsx
import { Box, Typography } from "@mui/material";
import React from "react";
import { BoardCore, CheckListCore } from "./core";

interface TabContentProps {
    activeTab: string;
    loading?: any;
    stateOfRightBar?: any;
}

const renderPlaceholder = (content: string) => {
    return (
        <Box
            sx={{
                backgroundColor: "#FFF9C4",
                color: "#ffb600",
                padding: "16px",
                borderRadius: "8px",
                textAlign: "center",
                marginBottom: "16px",
            }}
        >
            <Typography
                variant="body1"
                sx={{ font: "normal normal 700 17px/21px Source Serif Pro" }}
            >
                {content}
            </Typography>
        </Box>
    );
};

const TabContent: React.FC<TabContentProps> = ({
    activeTab,
    loading,
    stateOfRightBar,
}) => {
    return (
        <>
            {activeTab === "Overview" &&
                renderPlaceholder(
                    "The Overview feature will soon provide a comprehensive summary of your projects, offering key metrics, quick insights, and actionable data at a glance."
                )}
            {activeTab === "Board" && <BoardCore l={stateOfRightBar} />}
            {activeTab === "Checklist" && (
                <>
                    {renderPlaceholder(
                        "The Checklist feature is being enhanced to offer smarter task tracking and better organization tools, helping you streamline your workflows effectively."
                    )}
                    <CheckListCore l={stateOfRightBar} />
                </>
            )}
            {activeTab === "List" &&
                renderPlaceholder(
                    "The List feature will soon support detailed item management, allowing for flexible sorting, grouping, and advanced filtering options."
                )}
            {activeTab === "Tree" &&
                renderPlaceholder(
                    "The Tree feature is evolving to provide a hierarchical view of your data, making it easier to manage and navigate complex structures."
                )}
            {activeTab === "Gantt" &&
                renderPlaceholder(
                    "The Gantt feature is being developed to help you visualize project timelines, dependencies, and milestones, ensuring efficient project planning."
                )}
            {activeTab === "Calendar" &&
                renderPlaceholder(
                    "The Calendar feature will include advanced scheduling capabilities, making it easy to plan, view, and track events and tasks."
                )}
            {activeTab === "Resource" &&
                renderPlaceholder(
                    "The Resource feature will provide insights into resource allocation and availability, helping you optimize team performance."
                )}
        </>
    );
};

export default TabContent;
