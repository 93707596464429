import React from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import {
  CalendarIcon,
  GreenDropdownIcon,
  ObjectiveIcon,
} from "../../../../../images";
import {
  BorderLinearProgress,
  DUMMY_AVATAR_USERS,
  SmallGroupedAvatars,
  StyledTooltip,
} from "../../../../common";
import styles from "./TreeViewDropdown.module.css";
import { ObjectiveCardThreeDotManu } from "../../common";

const TreeViewDropdown: React.FC = () => {
  return (
    <Stack className={styles["treeview-dropdown"]}>
      <Stack className={styles["treeview-dropdown__left"]}>
        <GreenDropdownIcon open />
        <Stack className={styles["treeview-dropdown__info"]}>
          <Typography className={styles["treeview-dropdown__title"]}>
            Project objective name here
          </Typography>
          <Stack className={styles["treeview-dropdown__details"]}>
            <Stack direction={"row"} gap={"5px"}>
              <CalendarIcon />
              <Typography className={styles["treeview-dropdown__text"]}>
                Mar 15, 2024
              </Typography>
            </Stack>
            <Stack direction={"row"} gap={"5px"}>
              <ObjectiveIcon />
              <Typography className={styles["treeview-dropdown__text"]}>
                15 key tasks
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack className={styles["treeview-dropdown__right"]}>
        <Stack className={styles["treeview-dropdown__progress"]}>
          <StyledTooltip title="Progress">
            <BorderLinearProgress progressPercent={40} />
          </StyledTooltip>
          <Typography className={styles["treeview-dropdown__progress-text"]}>
            40% done
          </Typography>
        </Stack>
        <Stack className={styles["treeview-dropdown__avatars"]}>
          <SmallGroupedAvatars maxAvatars={4} usersData={DUMMY_AVATAR_USERS} />
        </Stack>
        <ObjectiveCardThreeDotManu />
      </Stack>
    </Stack>
  );
};

export default TreeViewDropdown;
