import { IconButton, Stack, Typography } from "@mui/material";
import { CheckboxMedium } from "../../../../common";
import { AllDirArrowCircleIcon } from "../../../../../images";

interface FilterCheckboxTitleProps<T> {
  item: T;
  isSelected: boolean;
  onCheckboxClick: (value: T) => void;
  label: string;
  isChilds?: boolean;
  arrowButtonClick?: (e: React.MouseEvent<HTMLButtonElement>, value: T) => void;
}

const FilterCheckboxTitle = <T,>({
  item,
  isSelected,
  onCheckboxClick,
  label,
  isChilds,
  arrowButtonClick = () => null,
}: FilterCheckboxTitleProps<T>) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "5px",
      }}
    >
      <Stack
        onClick={() => onCheckboxClick(item)}
        sx={{
          flexDirection: "row",
          alignItems: "start",
          gap: "10px",
          cursor: "pointer",
        }}
      >
        <CheckboxMedium checked={isSelected} />
        <Typography
          sx={{
            font: "normal normal 300 15px/19px Source Serif Pro",
          }}
        >
          {label}
        </Typography>
      </Stack>

      {isChilds && (
        <IconButton sx={{ p: 0 }} onClick={(e) => arrowButtonClick(e, item)}>
          <AllDirArrowCircleIcon direction="right" w={15} />
        </IconButton>
      )}
    </Stack>
  );
};

export default FilterCheckboxTitle;
