import React from "react";
import { PinIcon } from "../../../../../../../images";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { MessageAvatarChip } from "../../../MessageAvatarChip";

const PinHoverCard: React.FC<MsgPinnedInfoViewProps> = ({userName,userAvatar,handleUnpinMessage}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        minWidth: "215px",
        gap: "20px",
        height: "35px",
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "6px" }}>
        <PinIcon color="white" w={15} />
        <MessageAvatarChip imgSrc={userAvatar} username={userName} userMsgStatus="active" avatarSize={15} />
        <Typography
          sx={{ font: "normal normal 300 12px/15px Source Serif Pro" }}
        >
            {userName}
        </Typography>
      </Stack>

      <IconButton
        sx={{
          p: "1px",
          color: "white",
          font: "normal normal 300 12px/15px Source Serif Pro",
          ":hover": {
            textDecoration: "underline",
          },
        }}
        disableRipple
        onClick={handleUnpinMessage}
      >
        Unpin
      </IconButton>
    </Stack>
  );
};

interface MsgPinnedInfoViewProps {
    userName?: string;
    handleUnpinMessage?: () => void;
    userAvatar?: string;
}

const MsgPinnedInfoView: React.FC<MsgPinnedInfoViewProps> = ({userName,userAvatar,handleUnpinMessage}) => {
  return (
    <Tooltip title={<PinHoverCard userName={userName} userAvatar={userAvatar} handleUnpinMessage={handleUnpinMessage} />} placement="top-end">
      <span>
        <PinIcon w={15} />
      </span>
    </Tooltip>
  );
};

export default MsgPinnedInfoView;
