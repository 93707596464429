
// take refrence from \backend\models\Tenant\UserModel.js
// userType: {
//     type: String,
//     enum: ["Admin", "Manager", "Team Lead", "User", "Guest", "Auditor"],
//     required: true,
//   },


export enum UserTypeEnum {
    ADMIN = 'Admin',
    MANAGER = 'Manager',
    TEAM_LEAD = 'Team Lead',
    USER = 'User',
    GUEST = 'Guest',
    AUDITOR = 'Auditor',
}
