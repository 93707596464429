import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { DummyImage } from "../../../../../../../images";
import { adjustHexColor } from "../../../../../../../Utils";
import { AvatarNormal } from "../../../../../../common";

interface ImageDetailsViewProps {}

const ImageDetailsView: React.FC<ImageDetailsViewProps> = ({}) => {
  return (
    <Stack sx={{ borderRadius: "5px", overflow: "hidden", flexShrink: 0 }}>
      {/* --image area-- */}

      <AvatarNormal
        size={200}
        imgSrc={DummyImage}
        username={"Johnathan Stensler"}
        sx={{ borderRadius: "0px", height: "154px", width: "200px" }}
      />

      {/* -- name area -- */}
      <Stack
        sx={{
          bgcolor: "black",
          padding: "5px 0px 0px 0px",
          minHeight: "20px",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 900 13px/16px Source Serif Pro",
            color: "white",
          }}
        >
          Johnathan Stensler
        </Typography>
      </Stack>

      {/* -- job details area -- */}
      <Stack
        sx={{
          bgcolor: adjustHexColor("#000", 90),
          minHeight: "20px",
          flexShrink: 0,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 300 11px/14px Source Serif Pro",
            color: "white",
          }}
        >
          Senior Developer | Full Time
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ImageDetailsView;
