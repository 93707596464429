import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {VideoCenterState} from "./VideoCenterTypes";
import {
  addWorkspacesAndProjectsToMeetingFailure,
  addWorkspacesAndProjectsToMeetingRequest,
  addWorkspacesAndProjectsToMeetingSuccess,
  createMeetingAgendaSuccess,
  createMeetingFailure,
  createMeetingNoteFailure,
  createMeetingNoteRequest,
  createMeetingNoteSuccess,
  createMeetingRequest,
  createMeetingSuccess,
  createOnGoingMeetingAgendaSuccess,
  deleteAgendaFailure,
  deleteAgendaRequest,
  deleteAgendaSuccess,
  deleteMeetingNotesFailure,
  deleteMeetingNotesRequest,
  deleteMeetingNotesSuccess,
  deleteMeetingsFileSuccess,
  getAllMeetingNoteFailure,
  getAllMeetingNoteRequest,
  getAllMeetingNoteSuccess,
  getCallActivityDetailsFailure,
  getCallActivityDetailsRequest,
  getCallActivityDetailsSuccess,
  getMeetingsFileSuccess,
  getOnGoingMeetingAgendaFailure,
  getOnGoingMeetingAgendaRequest,
  getOnGoingMeetingAgendaSuccess,
  getPastMeetingDetailsFailure,
  getPastMeetingDetailsRequest,
  getPastMeetingDetailsSuccess,
  getUserBasicDetails__Failure,
  getUserBasicDetails__Request,
  getUserBasicDetails__Success,
  getPastMeetingsFailure,
  getPastMeetingsRequest,
  getPastMeetingsSuccess,
  getSelectedMeetingAgendaFailure,
  getSelectedMeetingAgendaRequest,
  getSelectedMeetingAgendaSuccess,
  getSingleMeetingFailure,
  getSingleMeetingRequest,
  getSingleMeetingSuccess,
  getUserMeetingsFailure,
  getUserMeetingsRequest,
  getUserMeetingsSuccess,
  postMeetingsFileSuccess,
  updateAgendaStatusFailure,
  updateAgendaStatusRequest,
  updateAgendaStatusSuccess,
  updateMeetingNoteFailure,
  updateInviteStatus__Request,
  updateInviteStatus__Success,
  updateInviteStatus__Failure,
  updateMeetingNoteRequest,
  updateMeetingNoteSuccess,
  updateMeetingsDetails__Success,
  updateMeetingsFileSuccess,
  getNewHomeScreenNotifications__Success,
    getNewHomeScreenNotifications__Failure,
    getNewHomeScreenNotifications__Request,
  getSnoozedHomeScreenNotifications__Request,
  getSnoozedHomeScreenNotifications__Success,
  getSnoozedHomeScreenNotifications__Failure,

  getMeetingDetails__Request,
  getMeetingDetails__Success,
  getMeetingDetails__Failure,

  getDoneHomeScreenNotifications__Request,
  getDoneHomeScreenNotifications__Success,
  getDoneHomeScreenNotifications__Failure,

  updateHomeScreenNotifications__Request,
  updateHomeScreenNotifications__Success,
  updateHomeScreenNotifications__Failure,

  authenticateGuestUserRequest,
  authenticateGuestUserSuccess,
  authenticateGuestUserFailure,

} from "./VideoCenterSliceActions";

/*
 * ==============================================
 * Define the initial state for the meeting slice
 * ==============================================
 */

const initialState: VideoCenterState = {
  selectedMeeting: {
    meeting: {},
    meetingAgendas: [],
    meetingNotes: [],
    meetingFiles: [],
  },
  onGoingMeeting: {
    joinedUserType: "",
    isNonBearishUser: false,
    isUserInvitedToMeeting: false,
    isInstantMeeting: false,
    isAbleToJoinMeeting: false,
    isMeetingEnded: false,
    isMeetingStarted: false,
    isUserIsHost: false,
    isReadyToJoinMeeting: false,
    userAccountDetails: null,
    realtimeAttendees:null,
    ClintNotification: null,
    notificationData: null,
    meeting: {},
    realTimeMeetingAttendee: {},
    WaitingParticipants: [],
    guestRequests: {},
    meetingAgendas: {},
    meetingNotes: [],
    meetingFiles: [],
    meetingsDetails: {},
  },
  selectedPastMeeting:{
    details: {},
    activity:[],
    agendas:[],
    notes:[],
  },
  upcomingMeetings: [],
  newHomeScreenNotifications: {},
  doneHomeScreenNotifications: {},
  snoozedHomeScreenNotifications: {},
  callCenterCoverImage: "",
  pastMeetings: [],
  projects: [],
  loading: false,
  errors: null,
};

/*
 * ==============================================
 * Create a slice for Fetch meetings
 * ==============================================
 */
const videoCenterSlice = createSlice({
  name: "meeting", // Name of the slice
  initialState, // Initial state of the slice

  reducers: {
    // Reducers to handle different actions
    getCallActivityDetailsRequest,
    getCallActivityDetailsSuccess,
    getCallActivityDetailsFailure,

    getPastMeetingDetailsRequest,
    getPastMeetingDetailsSuccess,
    getPastMeetingDetailsFailure,

    createMeetingRequest,
    createMeetingSuccess,
    createMeetingFailure,

    getUserMeetingsRequest,
    getUserMeetingsSuccess,
    getUserMeetingsFailure,

    updateMeetingsDetails__Success,

    getPastMeetingsRequest,
    getPastMeetingsSuccess,
    getPastMeetingsFailure,

    getSingleMeetingRequest,
    getSingleMeetingSuccess,
    getSingleMeetingFailure,

    getSelectedMeetingAgendaSuccess,
    getSelectedMeetingAgendaRequest,
    getSelectedMeetingAgendaFailure,
    getOnGoingMeetingAgendaSuccess,
    getOnGoingMeetingAgendaRequest,
    getOnGoingMeetingAgendaFailure,

    // update agenda status
    updateAgendaStatusSuccess,
    updateAgendaStatusRequest,
    updateAgendaStatusFailure,
    // delete meeting agenda
    deleteAgendaSuccess,
    deleteAgendaRequest,
    deleteAgendaFailure,

    // get all meeting notes
    getAllMeetingNoteSuccess,
    getAllMeetingNoteRequest,
    getAllMeetingNoteFailure,

    // create meeting note
    createMeetingNoteSuccess,
    createMeetingNoteRequest,
    createMeetingNoteFailure,

    // update meeting note
    updateMeetingNoteSuccess,
    updateMeetingNoteRequest,
    updateMeetingNoteFailure,

    // delete meeting note
    deleteMeetingNotesSuccess,
    deleteMeetingNotesRequest,
    deleteMeetingNotesFailure,

    addWorkspacesAndProjectsToMeetingSuccess,
    addWorkspacesAndProjectsToMeetingRequest,
    addWorkspacesAndProjectsToMeetingFailure,

    postMeetingsFileSuccess,
    createMeetingAgendaSuccess,
    createOnGoingMeetingAgendaSuccess,
    updateMeetingsFileSuccess,
    deleteMeetingsFileSuccess,
    getMeetingsFileSuccess,
    getNewHomeScreenNotifications__Success,
    getNewHomeScreenNotifications__Failure,
    getNewHomeScreenNotifications__Request,

    getSnoozedHomeScreenNotifications__Request,
    getSnoozedHomeScreenNotifications__Success,
    getSnoozedHomeScreenNotifications__Failure,

    getDoneHomeScreenNotifications__Request,
    getDoneHomeScreenNotifications__Success,
    getDoneHomeScreenNotifications__Failure,


    updateInviteStatus__Request,
    updateInviteStatus__Success,
    updateInviteStatus__Failure,

    updateHomeScreenNotifications__Request,
    updateHomeScreenNotifications__Success,
    updateHomeScreenNotifications__Failure,

    authenticateGuestUserRequest,
    authenticateGuestUserSuccess,
    authenticateGuestUserFailure,

    getUserBasicDetails__Failure,
    getUserBasicDetails__Request,
    getUserBasicDetails__Success,

    getMeetingDetails__Request,
    getMeetingDetails__Success,
    getMeetingDetails__Failure,

    setWaitingParticipants: (state, action) => {
      state.onGoingMeeting.WaitingParticipants = [...state.onGoingMeeting.WaitingParticipants, action.payload];
    },
    removeWaitingParticipants: (state, action) => {
        state.onGoingMeeting.WaitingParticipants = state.onGoingMeeting.WaitingParticipants.filter((item:any) => item.userId !== action.payload);
    },

    setSelectedPastMeetingDetails: (state, action) => {
        state.selectedPastMeeting.details = action.payload;
    },

    setJoinedUserType: (state, action) => {
        state.onGoingMeeting.joinedUserType = action.payload;
    },

    setIsNonBearishUser: (state, action) => {
        state.onGoingMeeting.isNonBearishUser = action.payload;
    },

    setIsMeetingEnded: (state, action) => {
        state.onGoingMeeting.isMeetingEnded = action.payload;
    },

    setIsInstantMeeting: (state, action) => {
        state.onGoingMeeting.isInstantMeeting = action.payload;
    },

    setIsMeetingStarted : (state, action) => {
        state.onGoingMeeting.isMeetingStarted = action.payload;
    },

    setUserAccountDetails : (state, action) => {
      state.onGoingMeeting.userAccountDetails = action.payload;
    },

    setIsUserIsHost : (state, action) => {
        state.onGoingMeeting.isUserIsHost = action.payload;
    },

    setIsReadyToJoinMeeting : (state, action) => {
        state.onGoingMeeting.isReadyToJoinMeeting = action.payload;
    },

    setIsAbleToJoinMeeting : (state, action) => {
        state.onGoingMeeting.isAbleToJoinMeeting = action.payload;
    },

    setIsUserInvitedToMeeting : (state, action) => {
        state.onGoingMeeting.isUserInvitedToMeeting = action.payload;
    },

    guestRequestsForAccess: (
      state: VideoCenterState,
      action: { payload: any }
    ) => {
      // Convert the incoming array to an object of objects
      state.onGoingMeeting.notificationData = action.payload;
    },

    guestRequestsForPlaseWait: (
      state: VideoCenterState,
      action: { payload: any }
    ) => {
      // Convert the incoming array to an object of objects
      const newRequest = action.payload;
      console.log(newRequest,"newRequest")
      if (!state.onGoingMeeting.guestRequests) {
        state.onGoingMeeting.guestRequests = {};
      }
      // Update the state with the merged requests
      state.onGoingMeeting.guestRequests[newRequest?.userId] = newRequest;
    },

    ClintSendNotifection:(state, action) => {
      state.onGoingMeeting.ClintNotification = action.payload;
    },

    updateAgendaWithId: (state, action) => {
      const updatedAgenda = action.payload.updatedAgenda;
      state.onGoingMeeting.meetingAgendas[updatedAgenda._id] = updatedAgenda;
    },

    removeAgendaWithId: (state, action) => {
      const agendaId = action.payload;
      delete state.onGoingMeeting.meetingAgendas[agendaId];
    },

    removeSelectedMeetingAgendaWithId: (state, action) => {
      const agendaId = action.payload;
      delete state.selectedMeeting.meetingAgendas[agendaId];
    },

    addAgendaToOnGoingMeeting: (state, action) => {
      const agendas = action.payload;
      
      agendas?.savedAgendas?.forEach((agenda: any) => {
        state.onGoingMeeting.meetingAgendas[agenda._id] = agenda;
      });
    },

    removeGuestRequest: (state, action) => {
      const userId = action.payload;
      if (state.onGoingMeeting.guestRequests) {
        delete state.onGoingMeeting.guestRequests[userId];
      }
    },

    setDeleteAgenda(state, action) {
      const agendaId = action.payload;
      if (state.onGoingMeeting.meetingAgendas) {
        delete state.onGoingMeeting.meetingAgendas[agendaId];
      }
    },

    setOnGoingMeeting: (state, action) => {
      state.onGoingMeeting.meeting = action.payload;
    },

    setSelectedMeeting: (state, action) => {
      state.selectedMeeting.meeting = action.payload;
    },

    setUpdateAgendaDetails(
      state,
      action: PayloadAction<{ agendaId: string; payload: any }>
    ) {
      if (state.selectedMeeting) {
        const { agendaId, payload } = action.payload;

        if (agendaId) {
          state.selectedMeeting.meetingAgendas[agendaId].title = payload.title;
          state.selectedMeeting.meetingAgendas[agendaId].description =
            payload.description;
          state.selectedMeeting.meetingAgendas[agendaId].timeAllotted =
            payload.timeAllotted;
        }
      }
    },

    setCreateMeetingAgenda(state, action: PayloadAction<any>) {
      if (state.selectedMeeting) {
        action.payload.forEach((item: any) => {
          state.selectedMeeting.meetingAgendas[item._id] = item;
        });
      }
    },

    setIsLoading(state, action) {
      state.loading = action.payload;
    },

    setEndMeeting(state, action) {
      state.onGoingMeeting.meeting = action.payload;
      state.onGoingMeeting.ClintNotification = action.payload;
      state.onGoingMeeting.notificationData = action.payload;
      state.onGoingMeeting.guestRequests = action.payload;
      state.onGoingMeeting.meetingAgendas = action.payload;
      state.onGoingMeeting.meetingNotes = action.payload;
    },

    setEndMeetingObjBlank(state, action) {
      state.onGoingMeeting.meeting = {};
    },

  },
});

/*
 * ======================================================
 * Export the actions to be used in dispatch calls
 * ======================================================
 */
export const {
  /* get meetingCallActivity */
  getCallActivityDetailsRequest: getCallActivityDetailsRequestAction,
  getCallActivityDetailsSuccess: getCallActivityDetailsSuccessAction,
  getCallActivityDetailsFailure: getCallActivityDetailsFailureAction,

  /*get past Meeting Details */
  getPastMeetingDetailsRequest: getPastMeetingDetailsRequestAction,
  getPastMeetingDetailsSuccess: getPastMeetingDetailsSuccessAction,
  getPastMeetingDetailsFailure: getPastMeetingDetailsFailureAction,


  /* get meeting */
  createMeetingRequest: createMeetingRequestAction,
  createMeetingSuccess: createMeetingSuccessAction,
  createMeetingFailure: createMeetingFailureAction,

  /* get meeting */
  getUserMeetingsRequest: getUserMeetingsRequestAction,
  getUserMeetingsSuccess: getUserMeetingsSuccessAction,
  getUserMeetingsFailure: getUserMeetingsFailureAction,
  /* get  past meeting */
  getPastMeetingsRequest: getPastMeetingsRequestAction,
  getPastMeetingsSuccess: getPastMeetingsSuccessAction,
  getPastMeetingsFailure: getPastMeetingsFailureAction,
  /* get meeting */
  getSingleMeetingRequest: getSingleMeetingRequestAction,
  getSingleMeetingSuccess: getSingleMeetingSuccessAction,
  getSingleMeetingFailure: getSingleMeetingFailureAction,

  updateMeetingsDetails__Success: updateMeetingsDetails__SuccessAction,

  // get meeting agenda
  getSelectedMeetingAgendaSuccess: getSelectedMeetingAgendaSuccessAction,
  getSelectedMeetingAgendaRequest: getSelectedMeetingAgendaRequestAction,
  getSelectedMeetingAgendaFailure: getSelectedMeetingAgendaFailureAction,
  // get meeting agenda
  getOnGoingMeetingAgendaSuccess: getOnGoingMeetingAgendaSuccessAction,
  getOnGoingMeetingAgendaRequest: getOnGoingMeetingAgendaRequestAction,
  getOnGoingMeetingAgendaFailure: getOnGoingMeetingAgendaFailureAction,

  // update agenda status
  updateAgendaStatusSuccess: updateAgendaStatusSuccessAction,
  updateAgendaStatusRequest: updateAgendaStatusRequestAction,
  updateAgendaStatusFailure: updateAgendaStatusFailureAction,

  // delete meeting agenda
  deleteAgendaSuccess: deleteAgendaSuccessAction,
  deleteAgendaRequest: deleteAgendaRequestAction,
  deleteAgendaFailure: deleteAgendaFailureAction,

  // get all meeting notes
  getAllMeetingNoteSuccess: getAllMeetingNoteSuccessAction,
  getAllMeetingNoteRequest: getAllMeetingNoteRequestAction,
  getAllMeetingNoteFailure: getAllMeetingNoteFailureAction,

  // create meeting note
  createMeetingNoteSuccess: createMeetingNoteSuccessAction,
  createMeetingNoteRequest: createMeetingNoteRequestAction,
  createMeetingNoteFailure: createMeetingNoteFailureAction,

  // update meeting note
  updateMeetingNoteSuccess: updateMeetingNoteSuccessAction,
  updateMeetingNoteRequest: updateMeetingNoteRequestAction,
  updateMeetingNoteFailure: updateMeetingNoteFailureAction,

  // delete meeting note
  deleteMeetingNotesSuccess: deleteMeetingNotesSuccessAction,
  deleteMeetingNotesRequest: deleteMeetingNotesRequestAction,
  deleteMeetingNotesFailure: deleteMeetingNotesFailureAction,

  postMeetingsFileSuccess:postMeetingsFileSuccessAction,

  updateMeetingsFileSuccess: updateMeetingsFileSuccessAction,
  deleteMeetingsFileSuccess :deleteMeetingsFileSuccessAction,
  getMeetingsFileSuccess:getMeetingsFileSuccessAction,
  createMeetingAgendaSuccess: createMeetingAgendaSuccessAction,
  createOnGoingMeetingAgendaSuccess: createOnGoingMeetingAgendaSuccessAction,

  updateInviteStatus__Request:updateInviteStatus__RequestAction,
  updateInviteStatus__Success:updateInviteStatus__SuccessAction,
  updateInviteStatus__Failure:updateInviteStatus__FailureAction,

  updateHomeScreenNotifications__Request:updateHomeScreenNotifications__RequestAction,
  updateHomeScreenNotifications__Success:updateHomeScreenNotifications__SuccessAction,
  updateHomeScreenNotifications__Failure:updateHomeScreenNotifications__FailureAction,

  guestRequestsForAccess: guestRequestsForAccessAction,
  setIsAbleToJoinMeeting: setIsAbleToJoinMeetingAction,
  guestRequestsForPlaseWait:guestRequestsForPlaseWaitAction,
  removeGuestRequest: removeGuestRequestAction,
  setOnGoingMeeting: setOnGoingMeetingAction,
  setIsInstantMeeting: setIsInstantMeetingAction,
  setSelectedMeeting: setSelectedMeetingAction,
  addAgendaToOnGoingMeeting: addAgendaToOnGoingMeetingAction,
  setDeleteAgenda: setDeleteAgendaAction,
  setUpdateAgendaDetails: setUpdateAgendaDetailsAction,
  setCreateMeetingAgenda: setCreateMeetingAgendaAction,
  updateAgendaWithId: updateAgendaWithIdAction,
  removeAgendaWithId: removeAgendaWithIdAction,
  removeSelectedMeetingAgendaWithId: removeSelectedMeetingAgendaWithIdAction,
  setIsLoading: setIsLoadingAction,
  setSelectedPastMeetingDetails: setSelectedPastMeetingDetailsAction,
  getNewHomeScreenNotifications__Success:getNewHomeScreenNotifications__SuccessAction,
  getNewHomeScreenNotifications__Failure:getNewHomeScreenNotifications__FailureAction,
  getNewHomeScreenNotifications__Request:getNewHomeScreenNotifications__RequestAction,

  getDoneHomeScreenNotifications__Request:getDoneHomeScreenNotifications__RequestAction,
  getDoneHomeScreenNotifications__Success:getDoneHomeScreenNotifications__SuccessAction,
  getDoneHomeScreenNotifications__Failure:getDoneHomeScreenNotifications__FailureAction,

  getSnoozedHomeScreenNotifications__Request:getSnoozedHomeScreenNotifications__RequestAction,
  getSnoozedHomeScreenNotifications__Success:getSnoozedHomeScreenNotifications__SuccessAction,
  getSnoozedHomeScreenNotifications__Failure:getSnoozedHomeScreenNotifications__FailureAction,

  authenticateGuestUserRequest:authenticateGuestUserRequestAction,
  authenticateGuestUserSuccess:authenticateGuestUserSuccessAction,
  authenticateGuestUserFailure:authenticateGuestUserFailureAction,

  getUserBasicDetails__Request:getUserBasicDetails__RequestAction,
  getUserBasicDetails__Success:getUserBasicDetails__SuccessAction,
  getUserBasicDetails__Failure:getUserBasicDetails__FailureAction,

  getMeetingDetails__Request:getMeetingDetails__RequestAction,
  getMeetingDetails__Success:getMeetingDetails__SuccessAction,
  getMeetingDetails__Failure:getMeetingDetails__FailureAction,

  setUserAccountDetails: setUserAccountDetailsAction,

    //
  setJoinedUserType: setJoinedUserTypeAction,
  setIsNonBearishUser: setIsNonBearishUserAction,
    setIsMeetingEnded: setIsMeetingEndedAction,
    setIsMeetingStarted: setIsMeetingStartedAction,
    setIsUserIsHost: setIsUserIsHostAction,
    setIsReadyToJoinMeeting: setIsReadyToJoinMeetingAction,
    setIsUserInvitedToMeeting: setIsUserInvitedToMeetingAction,
  setWaitingParticipants: setWaitingParticipantsAction,
  removeWaitingParticipants: removeWaitingParticipantsAction,

  ClintSendNotifection:ClintSendAction,
  setEndMeeting: setEndMeetingAction,
  setEndMeetingObjBlank: setEndMeetingObjBlankAction,
} = videoCenterSlice.actions;

/*
 * ======================================================
 * Export the reducer to be used in the store
 * ======================================================
 */
export default videoCenterSlice.reducer;
