import {
  Box,
  InputBase,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, MouseEvent, useEffect } from "react";
import {
  renderSubListIcon,
  renderWorkspaceLogo,
  WorkspaceOptionsType,
} from "./SelectWorkspaceDropdown";
import {
  AllDirArrowCircleIcon,
  DefaultWorkspaceIcon,
} from "../../../../../images";
import styles from "./SelectWorkspaceDropdown.module.css";
import { StyledRadioButtonBlack } from "../../../RadioButtonBlack";

interface WorkspaceDropdownComponentProps {
  options: WorkspaceOptionsType[];
  initialValue?: WorkspaceOptionsType;
  onChange: (value: WorkspaceOptionsType) => void;
}

const WorkspaceDropdownComponent: React.FC<WorkspaceDropdownComponentProps> = ({
  onChange,
  options,
  initialValue,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] =
    useState<WorkspaceOptionsType[]>(options);

  const [selectedItem, setSelectedItem] = useState<WorkspaceOptionsType | null>(
    initialValue || null
  );

  useEffect(() => {
    if (initialValue) {
      setSelectedItem(initialValue);
    }
  }, [initialValue]);

  // State for managing the popover
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSubList, setSelectedSubList] = useState<
    WorkspaceOptionsType["subList"] | null
  >(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    const filtered = options.filter((item) =>
      item.workspaceName.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredOptions(filtered);
  };

  // when workspace name clicked
  const handleMenuItemClick = (
    event: MouseEvent<HTMLElement>,
    item: WorkspaceOptionsType
    // subList: WorkspaceOptionsType["subList"]
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem((pre) => {
      if (pre?._id === item._id) {
        return pre;
      } else {
        return {
          _id: item._id,
          workspaceName: item.workspaceName,
          workSpaceHost: item.workSpaceHost,
          subList: [],
        };
      }
    });
    // setworkspaceId(item._id);
    setSelectedSubList(item.subList);
  };

  const handleSublistClick = (subItem: WorkspaceOptionsType["subList"][0]) => {
    setSelectedItem((prev) => {
      if (!prev) return prev; // Keep the previous value if it's null
      const updatedItem = {
        ...prev,
        subList: [subItem], // Replace the subList with a single item
      };
      onChange(updatedItem); // Call onChange with the updated item
      return updatedItem;
    });

    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedSubList(null);
  };

  return (
    <Stack className={styles["workspaceDropdown"]}>
      <Stack className={styles["workspaceDropdown__header"]}>
        <InputBase
          sx={{ font: "normal normal 300 14px/18px Source Serif Pro", flex: 1 }}
          placeholder="Search all workspaces"
          value={searchTerm}
          onChange={handleInputChange}
        />
      </Stack>

      <Stack className={styles["workspaceDropdown__list"]}>
        {filteredOptions.map((item) => (
          <MenuItem
            className={styles["workspaceDropdown__item"]}
            key={item._id}
            onClick={(e) => handleMenuItemClick(e, item)}
            selected={selectedItem?._id === item._id}
          >
            <span className={styles["workspaceDropdown__item-icon"]}>
              <DefaultWorkspaceIcon w={20} />
            </span>
            <Box
              component={"img"}
              src={renderWorkspaceLogo(item.workSpaceHost)}
              alt="logo"
              className={styles["workspaceDropdown__item-logo"]}
            />
            <Typography className={styles["workspaceDropdown__item-text"]}>
              {item.workspaceName}
            </Typography>
            <span className={styles["workspaceDropdown__item-icon"]}>
              <AllDirArrowCircleIcon direction="right" w={20} />
            </span>
          </MenuItem>
        ))}
      </Stack>

      {/* Popover for SubList */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            padding: "5px 0px",
            maxHeight: "210px",
            overflow: "auto",
            scrollbarWidth: "none",
          }}
        >
          {selectedSubList && selectedSubList.length > 0 ? (
            selectedSubList.map((subItem) => (
              <MenuItem
                key={subItem._id}
                onClick={() => handleSublistClick(subItem)}
                selected={selectedItem?.subList[0]?._id === subItem._id}
                sx={{
                  minHeight: 0,
                  padding: "0px 5px 0px 15px",
                  gap: "5px",
                  flexShrink: 0,
                  height: "30px",
                  alignItems: "center",
                }}
              >
                <StyledRadioButtonBlack
                  checked={selectedItem?.subList[0]?._id === subItem._id}
                />
                <Box
                  component={"img"}
                  src={renderSubListIcon(subItem.type)}
                  alt="logo"
                  sx={{ height: "20px", width: "20px", mr: "5px" }}
                />
                <Typography
                  sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
                >
                  {subItem.name}
                </Typography>
              </MenuItem>
            ))
          ) : (
            <Typography sx={{ p: 2 }}>No sub-items available</Typography>
          )}
        </Box>
      </Popover>
    </Stack>
  );
};

export default WorkspaceDropdownComponent;
