import {
  Box,
  Divider,
  InputBase,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AvatarLarge,
  BgColorCode,
  ButtonSmall,
  IconButtonLong,
  MediumInputIconBox,
  SmallButtonColor,
  TextLinkButton,
} from "../../../../common";
import {
  ActiveDomains,
  AddUserAdminPortal,
  AddUserAdminPortalOptionType,
  AddUserContainer,
  AllUserTable,
} from "./components";
import { SearchIcon } from "../../../../../images";
import styles from "./AdminConsolePage.module.css";
import { UserTypeEnum } from "./AdminConsolePageType";
import { adjustHexColor, getUser } from "../../../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { createAdminPortalSession__api } from "../../../../../redux/Slice/auth/authActions";
import { RootState } from "../../../../../redux/store";
import {
  getAllInvitationsForCompany__api,
  getAllTeamsForCompany__api,
  getAllUsersForCompany__api,
  getCompanyDetails__api,
  reCallAdminAPIsHandler,
  updateCompanyEmail__api,
  updateCompanyHeadquarteredLocation__api,
  updateCompanyIcon__api,
  updateCompanyName__api,
  updateCompanyReferenceId__api,
  uploadFile__api,
} from "../../../../../redux";
import { useNavigate } from "react-router-dom";

interface AdminConsolePageProps { }

const AdminConsolePage: React.FC<AdminConsolePageProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState({
    companyInfo: {} as any,
    loader: true as boolean,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const adminState = useSelector((state: RootState) => state && state?.admin);
  const {
    companyInfo,
    teamMembers,
    invitedTeamMembers,
    reCall_AdminPortal_APIs,
  } = adminState || {};
  const { users }: any = teamMembers || {};
  const { invitations }: any = invitedTeamMembers || {};
  // State to store the merged users and invitations
  const [mergedUserList, setMergedUserList] = useState<any[]>([]);

  const [userPopupType, setUserPopupType] = useState<UserTypeEnum | null>(null);

  const handleCloseModal = () => {
    setUserPopupType(null);
  };

  const newUserClickHandler = (item: AddUserAdminPortalOptionType) => {
    setUserPopupType(item.value);
  };

  // Add event listener for ESC key to close the modal when open
  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleCloseModal();
      }
    };

    // Only add the event listener when the modal is open
    if (userPopupType) {
      document.addEventListener("keydown", handleEscKey);
    }

    // Cleanup the event listener on modal close
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [userPopupType]);

  const user = getUser();
  const handleClick = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (!user?.data?._id) return;

    event.preventDefault();

    try {
      const { success, data } = (await dispatch(
        createAdminPortalSession__api(user.data._id)
      )) as any;

      if (success && data?.link) {
        window.open(data.link, "_blank", "noopener,noreferrer");
      }
    } catch (error) {
      console.error("Failed to create admin portal session:", error);
    }
  };

  const getCompanyInfo = async () => {
    try {
      // Start by setting the company info loading state
      setCompanyData({ companyInfo: companyInfo, loader: true });

      // Dispatch action to fetch company details
      const action = getCompanyDetails__api();
      await dispatch(action);
    } catch (error) {
      console.error("Failed to get company info:", error);
    }
  };

  const getCompanyTeams = async () => {
    try {
      const action = getAllTeamsForCompany__api();
      await dispatch(action);
    } catch (error) {
      console.error("Failed to get teams list:", error);
    }
  };
  const getCompany_InvitedUser = async () => {
    try {
      setIsLoading(true);
      // Dispatch action to fetch all users for the company

      // Dispatch action to fetch all invitations for the company
      const action_1 = getAllInvitationsForCompany__api();
      await dispatch(action_1);

      const action = getAllUsersForCompany__api();
      await dispatch(action);
    } catch (error) {
      console.error("Failed to get company users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCompanyDetails = async () => {
    const apiCalls: Promise<void>[] = [];

    // Prepare the API calls based on the `reCall_AdminPortal_APIs` value
    if (reCall_AdminPortal_APIs === "All") {
      apiCalls.push(
        getCompanyInfo(),
        getCompany_InvitedUser(),
        getCompanyTeams()
      );
    } else if (reCall_AdminPortal_APIs === "user_list") {
      apiCalls.push(getCompany_InvitedUser());
    } else if (reCall_AdminPortal_APIs === "team_list") {
      apiCalls.push(getCompanyTeams());
    } else if (reCall_AdminPortal_APIs === "company_info") {
      apiCalls.push(getCompanyInfo());
    }

    // Execute the API calls in parallel and wait for them to complete
    await Promise.all(apiCalls);

    // Dispatch the action once all API calls are completed
    dispatch(reCallAdminAPIsHandler(null));
  };

  useEffect(() => {
    fetchCompanyDetails();
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [reCall_AdminPortal_APIs]);

  useEffect(() => {
    if (companyInfo) {
      setCompanyData({ companyInfo: companyInfo, loader: false });
    }
  }, [companyInfo]);

  useEffect(() => {
    // Merging teamMembers and invitedTeamMembers into one array
    const mergedList = [
      ...(users || []), // Include team members
      ...(invitations || []), // Include invited team members
    ];

    // Update the state with the merged list
    setMergedUserList(mergedList);
  }, [users, invitations]);

  const Admin__updateCompanyInfo = async (infoType: string, data: any) => {
    const actionMap: Record<string, (data: any) => any> = {
      name: updateCompanyName__api,
      companyEmail: updateCompanyEmail__api,
      companyReferenceId: updateCompanyReferenceId__api,
      headquartersLocation: updateCompanyHeadquarteredLocation__api,
    };

    try {
      if (infoType === "logo") {
        // Handle logo upload separately
        const formData = new FormData();
        formData.append("files", data);

        const action = uploadFile__api(formData);
        let mediaUrl = "";

        try {
          const response: any = await dispatch(action);
          if (response?.success && response?.data) {
            mediaUrl = response?.data?.[0]?.path;
          }
        } catch (e) {
          console.log("error uploading file");
        }

        const payload = { icon: mediaUrl };
        const logoAction = updateCompanyIcon__api(payload);
        const res: any = await dispatch(logoAction);

        if (res?.success) {
          // Dispatch the action once all API calls are completed
          dispatch(reCallAdminAPIsHandler("company_info"));
        }
        return;
      }

      // Handle other info types (name, email, etc.)
      const action = actionMap[infoType];
      if (!action) {
        throw new Error("invalidInfoType");
      }

      const payload = { [infoType]: data };
      const res: any = await dispatch(action(payload));

      if (res?.success) {
        // Dispatch the action once all API calls are completed
        dispatch(reCallAdminAPIsHandler("company_info"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigation = (path: string) => {
    try {
      navigate(path);
    } catch (error) { }
  };

  return (
    <Box className={styles["admin-console-page"]}>
      <Typography
        className={styles["admin-console-page__title"]}
        children="Company Settings"
      />

      {/* --------- Company Settings area ----------- */}
      <Stack className={styles["admin-console-page__section"]}>
        {/* Avatar and img upload area */}
        <Stack className={styles["admin-console-page__avatar-section"]}>
          <AvatarLarge
            imgSrc={companyData?.companyInfo?.company?.logo || ""}
            size={80}
            text="AA"
            bgColorCode={BgColorCode.PeachOrange}
          />
          <IconButtonLong
            onFileSelect={(file: any) => {
              Admin__updateCompanyInfo("logo", file);
            }}
          />
        </Stack>

        {/* Company name / Headquarter and email */}
        <Stack className={styles["admin-console-page__details-section"]}>
          <Stack className={styles["admin-console-page__details-row"]}>
            <MediumInputIconBox
              label={"Company name"}
              value={companyData?.companyInfo?.company?.name || ""}
              onChange={(value) => {
                Admin__updateCompanyInfo("name", value);
              }}
            />
            <MediumInputIconBox
              label={"Headquartered In"}
              value={
                companyData?.companyInfo?.company?.headquartersLocation || ""
              }
              onChange={(value) => {
                Admin__updateCompanyInfo("headquartersLocation", value);
              }}
            />
          </Stack>

          <MediumInputIconBox
            label={"Company Email"}
            sx={{ width: "100%" }}
            value={companyData?.companyInfo?.company?.companyEmail || ""}
            onChange={(value) => {
              Admin__updateCompanyInfo("companyEmail", value);
            }}
          />
        </Stack>

        {/* Company Reference ID */}
        <Stack className={styles["admin-console-page__reference-section"]}>
          <MediumInputIconBox
            label={"Company Reference ID"}
            sx={{ width: "100%" }}
            value={
              companyData?.companyInfo?.company?.companyReferenceId ||
              companyData?.companyInfo?.company?.workOSOrgId ||
              ""
            }
            onChange={(value) => {
              Admin__updateCompanyInfo("companyReferenceId", value);
            }}
          />

          <Stack className={styles["admin-console-page__reference-actions"]}>
            <ButtonSmall
              onClick={(event: any) => {
                handleClick(event);
              }}
              label="Setup WorkOS"
              colorVarient={SmallButtonColor.BearishClay}
              sx={{
                height: "40px",
                width: "135px",
                font: "normal normal 600 15px/19px Source Serif Pro",
              }}
            />
            <ActiveDomains
              domain={companyData?.companyInfo?.company?.domain}
              domains={companyData?.companyInfo?.company?.domains}
            />
          </Stack>
        </Stack>
      </Stack>

      {/* -------------------------------------------------- */}
      <Divider className={styles["admin-console-page__divider"]} />

      {/* ----------- Users section ----------- */}
      <Stack className={styles["admin-console-page__users-section"]}>
        <Typography className={styles["admin-console-page__users-title"]}>
          {`${companyData?.companyInfo?.company?.name || "Bearish OS"} `}
          <span
            style={{
              fontSize: "1.5em",
              color: "#1976d2",
              margin: "0 0.2em",
              position: "relative",
              top: "3px",
            }}
          >
            •
          </span>
          {" Users"}
        </Typography>

        <Stack className={styles["admin-console-page__users-toolbar"]}>
          {/* Search box and add user button */}
          <Stack className={styles["admin-console-page__search-section"]}>
            <Stack className={styles["admin-console-page__search-box"]}>
              <SearchIcon w={15} />
              <InputBase
                placeholder="Enter email address or name"
                className={styles["admin-console-page__search-input"]}
              />
            </Stack>
            <AddUserAdminPortal
              onSelect={(value) => newUserClickHandler(value)}
            />
          </Stack>

          <TextLinkButton
            className={styles["admin-console-page__plan-link"]}
            buttonLabel="Go to Plan Details"
            onClick={() => {
              handleNavigation("/Settings/plan-details");
            }}
          />
        </Stack>

        {/* ----- Table area ----- */}
        <Stack className={styles["admin-console-page__table"]}>
          <AllUserTable
            usersList={mergedUserList || []}
            isLoading={isLoading}
          />
        </Stack>
      </Stack>

      {/* -------- Modal for User Popup -------- */}
      <Modal
        open={Boolean(userPopupType)}
        onClose={handleCloseModal}
        className={styles["admin-console-page__modal"]}
        BackdropProps={{
          style: { backgroundColor: adjustHexColor("#000000", 10) },
        }}
      >
        <>
          {userPopupType && (
            <AddUserContainer
              users={users}
              addUserType={userPopupType}
              modalClose={handleCloseModal}
            />
          )}
        </>
      </Modal>
    </Box>
  );
};

export default AdminConsolePage;
