import {Box, Typography} from "@mui/material";
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../redux/store";
import {MessagesState} from "../../../../../../redux/Slice/message/messageTypes";
import {MESSAGE_ENUMS} from "../../../../../pages/Message/constant";
import {SlackPng, StreamIconBearishOSIconPng} from "../../../../../../images";
import {MessageAvatarChip} from "../../../../../pages/Message/common";

interface ChatProfilePreviewProps {
    openTasks?: number;
    upcomingEvents?: number;
}

const ChatProfilePreview: React.FC<ChatProfilePreviewProps> = ({openTasks = 0 ,upcomingEvents = 0}) => {
    const {
        chatTabState,
    } = useSelector((state: RootState) => state.message as MessagesState);
    return (
        <Box
            display="flex"
            alignItems="center"
            gap={1}
            ml={0.5}
        >
            {/* Avatar */}
            {chatTabState?.selectedConversation?.conversationName && (
                <MessageAvatarChip userMsgStatus={"active"} imgSrc={chatTabState?.selectedConversation?.conversationDetails?.avatar || ""} username={chatTabState?.selectedConversation?.conversationName}/>
            )}

            {/* Icon */}
            <Box
                component={"img"}
                sx={{height: "17px", width: "17px"}}
                src={chatTabState?.selectedConversation?.conversationPlatform === MESSAGE_ENUMS.SLACK
                    ? SlackPng
                    : StreamIconBearishOSIconPng}
                alt="logo"
            />

            {/* Name */}
            <Typography
                sx={{font: "normal normal 400 16.29px/13px Source Serif Pro"}}
            >
                {chatTabState?.selectedConversation?.conversationName}
            </Typography>
            <Typography
                sx={{font: "normal normal 300 16.29px/13px Source Serif Pro",marginLeft:"15px"}}
            >
                Upcoming Events:{" "}
                <Typography
                    component="span"
                    sx={{ font: "normal normal 900 16.29px/13px Source Serif Pro", color: "#B5005B" }}
                >
                    {upcomingEvents}
                </Typography>
            </Typography>
            <Typography
                sx={{font: "normal normal 300 16.29px/13px Source Serif Pro",marginLeft:"15px"}}
            >
                Total Open Tasks:{" "}
                <Typography
                    component="span"
                    sx={{ font: "normal normal 900 16.29px/13px Source Serif Pro", color: "#A53A01" }}
                >
                    {openTasks}
                </Typography>
            </Typography>
        </Box>
    );
};

export default ChatProfilePreview;
