import { Box } from '@mui/material'
import React from 'react'

const CommandControlMaster = () => {
  return (
    <Box>
            {/* Your code */}
    </Box>
  )
}

export default CommandControlMaster