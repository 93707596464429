import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import {
  BearishOSIcon,
  BoxLogoPng,
  ConcurLogoPng,
  ConfluenceLogoPng,
  DropBoxLogoPng,
  ExchangeLogoPng,
  GmailLogoPng,
  GoogleCalendarLogoPng,
  GoogleDriveLogoPng,
  GoogleMeetLogoPng,
  JiraLogoPng,
  MicrosoftTeamsLogoPng,
  OneDriveLogoPng,
  OutlookCalendarLogoPng,
  OutlookLogoPng,
  SalesforceLogoPng,
  SAPSuccessFactorsLogoPng,
  SlackPng,
  WebexPng,
  ZoomLogoPng,
} from "../../../../../images";
import { useTranslation } from "react-i18next";

interface RedirectPageContainerProps {
  children: React.ReactNode;
}

const RedirectPageContainer: React.FC<RedirectPageContainerProps> = ({
  children,
}) => {
  const ImgList1 = [
    MicrosoftTeamsLogoPng,
    SlackPng,
    GoogleMeetLogoPng,
    WebexPng,
    ZoomLogoPng,
    GoogleCalendarLogoPng,
    ConfluenceLogoPng,
  ];
  const ImgList2 = [
    OutlookLogoPng,
    BoxLogoPng,
    GmailLogoPng,
    JiraLogoPng,
    ConcurLogoPng,
    ConfluenceLogoPng,
    GoogleDriveLogoPng,
  ];
  const ImgList3 = [
    ExchangeLogoPng,
    DropBoxLogoPng,
    OutlookCalendarLogoPng,
    OneDriveLogoPng,
    SalesforceLogoPng,
    SAPSuccessFactorsLogoPng,
  ];

  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        height: "100vh",
        width: "100vw",
        bgcolor: "#F2F5F8",
        boxSizing: "border-box",
        flexDirection: "row",
      }}
    >
      {/* left static container */}
      <Stack sx={{ width: "440px", bgcolor: "#E9EDF2" }}>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "15px",
            padding: "50px 0px 0px 30px",
          }}
        >
          <BearishOSIcon w={75} />
          <Typography
            sx={{ font: "normal normal 400 60px/73px Averia Serif Libre" }}
          >
            Bearish OS
          </Typography>
        </Stack>

        {/* img container */}
        <Stack sx={{ gap: "35px", mt: "95px" }}>
          <Stack
            sx={{ flexDirection: "row", gap: "25px", marginLeft: "-25px" }}
          >
            {ImgList1.map((item, index) => (
              <Box
                key={index}
                component={"img"}
                src={item}
                alt="logo"
                sx={{ height: "50px", width: "50px" }}
              />
            ))}
          </Stack>
          <Stack
            sx={{ flexDirection: "row", gap: "25px", marginLeft: "-17px" }}
          >
            {ImgList2.map((item, index) => (
              <Box
                key={index}
                component={"img"}
                src={item}
                alt="logo"
                sx={{ height: "50px", width: "50px" }}
              />
            ))}
          </Stack>
          <Stack
            sx={{ flexDirection: "row", gap: "25px", marginLeft: "-21px" }}
          >
            {ImgList3.map((item, index) => (
              <Box
                key={index}
                component={"img"}
                src={item}
                alt="logo"
                sx={{ height: "50px", width: "50px" }}
              />
            ))}
          </Stack>
        </Stack>

        <Stack sx={{ marginTop: "50px", gap: "20px", paddingLeft: "30px" }}>
          <Typography
            sx={{ font: "normal normal 900 32px/40px Source Serif Pro" }}
          >
            {t("DASHBOARD.INTELLIGENT_CONNECT")}
          </Typography>
          <Typography
            sx={{
              font: "normal normal 400 20px/25px Source Serif Pro",
              width: "365px",
            }}
          >
            {t("DASHBOARD.SUPERCHARGE_YOUR_WORK")}
          </Typography>
        </Stack>
      </Stack>

      {/* main container */}
      <Stack
        sx={{
          width: "calc(100vw - 440px)",
          bgcolor: "white",
          flexDirection: "row",
          // alignItems: "center",
          justifyContent: "center",
          py: "60px",
        }}
      >
        {children}
      </Stack>

      {/* ----- */}
    </Stack>
  );
};

export default RedirectPageContainer;
