import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import {
  dayViewAreaWidth,
  dayViewHeaderArea,
} from "../../AttendanceScheduleTableType";
import ShiftColorIndicator from "../ShiftColorIndicator/ShiftColorIndicator";

interface DayViewHeaderTimeAreaProps {}

const DayViewHeaderTimeArea: React.FC<DayViewHeaderTimeAreaProps> = ({}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: `${dayViewAreaWidth.distance}px`,
        paddingLeft: "10px",
        boxSizing: "border-box",
        borderLeft: "1px solid #E9EDF2",
      }}
    >
      <ShiftColorIndicator />

      {dayViewHeaderArea.map((item, index) => (
        <Stack
          key={index}
          sx={{
            height: "42px",
            width: `${dayViewAreaWidth.timeAreaWidth}px`,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              font: "normal normal 300 10px/13px Source Serif Pro",
              transform: "rotate(270deg)",
              whiteSpace: "nowrap",
            }}
          >
            {item.label}
          </Typography>
        </Stack>
      ))}

      <Button
        variant="text"
        sx={{
          textTransform: "none",
          color: "black",
          font: "normal normal 300 10px/13px Source Serif Pro",
          ml: "4px",
          minWidth: 0,
          padding: "3px 4px",
          whiteSpace: "nowrap",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        Next Day
      </Button>
    </Stack>
  );
};

export default DayViewHeaderTimeArea;
