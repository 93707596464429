import { PayloadAction } from "@reduxjs/toolkit";
import { VideoCenterState } from "../VideoCenterTypes";
import {CalendarState} from "../../Calendar";

export const createMeetingRequest = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
};
export const getUserMeetingsRequest = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
    state.loading = true;
};
export const getPastMeetingsRequest = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
    state.loading = true;
};
export const getSingleMeetingRequest = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
};
export const getOnGoingMeetingAgendaRequest = (
  state: VideoCenterState,
  action: PayloadAction<string>
) => {
  state.errors = null;
};
export const getSelectedMeetingAgendaRequest = (
  state: VideoCenterState,
  action: PayloadAction<string>
) => {
  state.errors = null;
  state.loading = true;
};
export const updateAgendaStatusRequest = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
};
export const deleteAgendaRequest = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
};
export const deleteMeetingNotesRequest = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
};
export const updateMeetingNoteRequest = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
};
export const createMeetingNoteRequest = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
};
export const getAllMeetingNoteRequest = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
    state.loading = true;
};
export const getCallActivityDetailsRequest = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
};
export const getPastMeetingDetailsRequest = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
};
export const updateMeetingsDetails__Request = (state: CalendarState) => {
    state.error = null;
    state.data = null;
};

export const addWorkspacesAndProjectsToMeetingRequest = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
};

export const updateInviteStatus__Request = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
}

export const updateHomeScreenNotifications__Request = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
}

export const getSnoozedHomeScreenNotifications__Request = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
}

export const getDoneHomeScreenNotifications__Request = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
}

export const getNewHomeScreenNotifications__Request = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
}

export const authenticateGuestUserRequest = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
}

export const getUserBasicDetails__Request = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
}

export const getMeetingDetails__Request = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = null;
}