import { Box, Stack, Typography, Menu, MenuItem, Button } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { ChevronIcon, MicrosoftTeamsLogoPng } from "../../../images";
import { CheckboxMedium } from "../CheckboxMedium";
import { SingleSelectCheckBoxDropdownOptionsType } from "./SingleSelectCheckBoxDropdownPropsType";
import { adjustHexColor } from "../../../Utils";

interface SingleSelectCheckBoxDropdownProps {
  options: SingleSelectCheckBoxDropdownOptionsType[];
  initialSelected?: SingleSelectCheckBoxDropdownOptionsType;
  onSelect: (value: SingleSelectCheckBoxDropdownOptionsType) => void;
  placeholder?: string;
  menuWidth?: string;
  optionsColumn?: 1 | 2;
}

const SingleSelectCheckBoxDropdown: React.FC<
  SingleSelectCheckBoxDropdownProps
> = ({
  menuWidth = "100%",
  optionsColumn = 1,
  onSelect,
  options = [],
  initialSelected,
  placeholder,
}) => {
  const [selectedValue, setSelectedValue] = useState<
    SingleSelectCheckBoxDropdownOptionsType | undefined
  >();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dynamicWidth, setDynamicWidth] = useState<string>("205px");
  const triggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (initialSelected) {
      setSelectedValue(initialSelected);
    }
  }, [initialSelected]);
  
  const open = Boolean(anchorEl);

  const optionsClickHandler = (
    val: SingleSelectCheckBoxDropdownOptionsType
  ) => {
    setSelectedValue(val);
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (triggerRef.current) {
      const width = `${triggerRef.current.offsetWidth}px`;
      setDynamicWidth(width); // Set the dynamic width
    }
  };

  useEffect(() => {
    if (selectedValue) {
      onSelect(selectedValue);
    }
  }, [selectedValue]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack ref={triggerRef} sx={{ width: menuWidth }}>
      <Button
        disableRipple
        sx={{
          color: "black",
          textTransform: "none",
          gap: "5px",
          height: "30px",
          borderRadius: "5px",
          border: "1px solid #E9EDF2",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          boxSizing: "border-box",
          padding: "0px 10px",
          cursor: "pointer",
          ":hover": { bgcolor: "inherit" },
        }}
        onClick={handleClick}
      >
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            overflow: "hidden",
          }}
        >
          {selectedValue?.imgSrc && (
            <Box
              component={"img"}
              src={selectedValue.imgSrc}
              sx={{ height: "20px", width: "20px" }}
              alt="logo"
            />
          )}
          {selectedValue?.title ? (
            <Typography
              sx={{
                font: "normal normal 300 13px/16px Source Serif Pro",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {selectedValue?.title}
            </Typography>
          ) : (
            <Typography
              sx={{
                font: "normal normal 300 13px/16px Source Serif Pro",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                color: adjustHexColor("#000000", 50),
              }}
            >
              {placeholder}
            </Typography>
          )}
        </Stack>
        <Box sx={{ flexShrink: 0 }}>
          <ChevronIcon direction={open ? "down" : "up"} />
        </Box>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: dynamicWidth, // Use the dynamic width
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            mt: "10px",
            border: "1px solid #E9EDF2",
            maxHeight: "140px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            scrollbarWidth: "none",
            "& ul": {
              display: "flex",
              flexDirection: "row !important",
              flexWrap: "wrap !important",
            },
          },
        }}
      >
        {options.map((item, index) => {
          return (
            <MenuItem
              selected={
                item.title === selectedValue?.title ||
                item._id === selectedValue?._id
              }
              key={index}
              onClick={() => optionsClickHandler(item)}
              sx={{
                width: `${100 / optionsColumn}%`,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                height: "30px",
                minHeight: 0,
                boxSizing: "border-box",
                padding: "0px 10px 0px 15px",
                gap: "5px",
              }}
            >
              <CheckboxMedium
                checked={
                  item.title === selectedValue?.title &&
                  item._id === selectedValue?._id
                }
              />
              {item.imgSrc && (
                <Box
                  component={"img"}
                  src={item.imgSrc}
                  sx={{ height: "20px", width: "20px" }}
                  alt="logo"
                />
              )}
              <Typography
                sx={{
                  font: "normal normal 300 12px/15px Source Serif Pro",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {item.title}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  );
};

export default SingleSelectCheckBoxDropdown;
