import React, { useState } from "react";
import Skeleton from "@mui/material/Skeleton";

interface ImageWithSkeletonProps {
  src: string;
  alt: string;
  height?: number;
  width?: number;
}

const ImageWithSkeleton: React.FC<ImageWithSkeletonProps> = ({
  src,
  alt,
  height = 150,
  width = 150,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
  };

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        position: "relative",
      }}
    >
      {isLoading && (
        <Skeleton
          variant="rectangular"
          width={width}
          height={height}
          style={{ position: "absolute", top: 0, left: 0, borderRadius: "5px" }}
        />
      )}
      <img
        src={src}
        alt={alt}
        style={{
          display: isLoading ? "none" : "block",
          width: `${width}px`,
          height: `${height}px`,
          borderRadius: "5px",
        }}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />
    </div>
  );
};

export default ImageWithSkeleton;
