import React from "react";
import { Avatar, IconButton, Stack, Typography } from "@mui/material";
import { GreenArrowButton } from "../Buttons";
import { dCompany } from "../../../images"; // Assuming dCompany is your default company logo
import { getUser, isAuthenticated } from "../../../Utils";

interface CompanyNameProps {
  arrowButtonClickHandler: () => void;
  isHideGreenArrow?: boolean;
}

const CompanyIconAndName: React.FC<CompanyNameProps> = ({
  arrowButtonClickHandler,
  isHideGreenArrow = false,
}) => {
  const auth = isAuthenticated();
  const user = getUser();

  // Default values
  const defaultCompanyName = "Default Company";
  const defaultImgSrc = dCompany; // Replace with your default company logo

  // Extract company information based on authentication status
  let companyName = defaultCompanyName;
  let imgSrc = defaultImgSrc;

  if (auth && user && user.data) {
    companyName = user?.data?.companyId?.name || defaultCompanyName;
    imgSrc = user?.data?.companyId?.logo || defaultImgSrc;
  }

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      width={'100%'}
      justifyContent={"space-between"}
      sx={{ height: "40px", paddingLeft: "18px", paddingRight: "2px" }}
    >
      <Stack direction={"row"} alignItems={"center"} gap={1.2}>
        <Avatar
          sx={{
            backgroundColor: "white",
            border: "1px solid #E9EDF2",
            height: "38px",
            width: "38px",
          }}
          alt="Company Logo"
          src={imgSrc}
          variant="rounded"
        >
          {!imgSrc && "C"} {/* Placeholder text if no image source */}
        </Avatar>
        <Typography
          sx={{
            font: "normal normal normal 15px/21px Source Serif Pro",
            maxWidth: "120px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textTransform: "capitalize",
          }}
        >
          {companyName}
        </Typography>
      </Stack>
      {isHideGreenArrow ? null :
        <IconButton sx={{ p: "8px" }} onClick={arrowButtonClickHandler}>
          <GreenArrowButton />
        </IconButton>
      }
    </Stack>
  );
};

export default CompanyIconAndName;
