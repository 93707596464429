import { Stack, Typography } from "@mui/material";
import React from "react";
import {
  AttendanceHistoryStatusType,
  AttendanceStatusColorLiist,
} from "../../../CrmStatusBadge";
import { adjustHexColor } from "../../../../../../../Utils";

export type ShiftStatusCardType = {
  cardType: AttendanceHistoryStatusType;
  startTime?: string;
  endTime?: string;
  showHorizontal?: boolean;
};

const ShiftStatusCard: React.FC<ShiftStatusCardType> = ({
  cardType,
  startTime,
  endTime,
  showHorizontal = false,
}) => {
  const getData = (
    cardType: AttendanceHistoryStatusType
  ): {
    bgClr: string;
    label?: string;
    fontClr?: string;
    isBorder?: boolean;
  } => {
    switch (cardType) {
      case "absent":
        return { bgClr: AttendanceStatusColorLiist.absent, label: "Absent" };
      case "dayoff":
        return {
          bgClr: adjustHexColor(AttendanceStatusColorLiist.dayoff, 25),
          label: "Day Off",
        };
      case "holiday":
        return { bgClr: AttendanceStatusColorLiist.holiday, label: "Holiday" };

      case "pto":
        return {
          bgClr: AttendanceStatusColorLiist.pto,
          label: "Paid Time Off",
          fontClr: "white",
        };
      case "sick":
        return { bgClr: AttendanceStatusColorLiist.sick, label: "Sick" };
      case "noShift":
        return {
          bgClr: adjustHexColor("#59D0D5", 25),
          isBorder: true,
          label: "No Shift",
        };

      case "upcoming":
        return { bgClr: AttendanceStatusColorLiist.upcoming };
      case "late":
        return {
          bgClr: AttendanceStatusColorLiist.late,
          fontClr: "white",
        };
      case "onTime":
        return {
          bgClr: AttendanceStatusColorLiist.onTime,
          fontClr: "white",
        };
    }
  };

  const getStartAndEndTime = (): string => {
    if (cardType === "upcoming") {
      return `${startTime} - ${endTime}`;
    }

    if (cardType === "onTime" || cardType === "late") {
      return `${startTime} - ${endTime || "Ongoing"}`;
    }
    return "";
  };

  return (
    <Stack
      sx={{
        width: showHorizontal ? "333px" : "30px",
        height: showHorizontal ? "30px" : "110px",
        bgcolor: getData(cardType).bgClr,
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        border: getData(cardType).isBorder
          ? `2px dotted ${adjustHexColor("#000000", 25)}`
          : "0px",
      }}
    >
      <Typography
        sx={{
          font: "normal normal 300 11px/14px Source Serif Pro",
          transform: showHorizontal ? "none" : "rotate(270deg)",
          whiteSpace: "nowrap",
          color: getData(cardType).fontClr || "black",
        }}
      >
        {getData(cardType).label || getStartAndEndTime()}
      </Typography>
    </Stack>
  );
};

export default ShiftStatusCard;
