import {jwtDecode} from "jwt-decode";
import {clearAllStorage} from "../redux/middleware/apiMiddleware";

export const checkUserTypeAndRedirect = (token: string) => {
    try {
        const decoded: any = jwtDecode(token);

        if (decoded.userType !== "USER_TYPE_AUTHENTICATED") {
            clearAllStorage();
            window.location.replace("/login");
        }
    } catch (error) {
        console.error("Error decoding token:", error);
    }
};