import React from "react";
import { ReplyContainer, SelectWorkspaceDropdown, SquareInput } from "../view";
import { Stack, Typography } from "@mui/material";
import { DateAndTimePicker } from "../../DateAndTimePicker";

interface TaskReplyBarProps {
    selectedUser: string | null;
    onCloseClick: () => void;
}

const TaskReplyBar: React.FC<TaskReplyBarProps> = ({onCloseClick,selectedUser}) => {
  return (
    <ReplyContainer
      replyType="task"
      dateAndTimeComponents={
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <Typography
            sx={{ font: "normal normal 600 11px/14px Source Serif Pro" }}
          >
              {selectedUser}
          </Typography>
          <Typography
            sx={{
              font: "normal normal 300 11px/14px Source Serif Pro",
              whiteSpace: "nowrap",
            }}
          >
            due on
          </Typography>
          <DateAndTimePicker
            sx={{
              font: "normal normal 600 11px/14px Source Serif Pro",
              width: "127px",
            }}
          />
        </Stack>
      }
      infoComponents={
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <SquareInput
            squareTitle="Task Name"
            initialValue=""
            onValueChange={(value) => console.log("Final Value:", value)}
            placeholder="Enter Task Name..."
          />
          <Typography
            sx={{
              font: "normal normal 300 11px/14px Source Serif Pro",
              whiteSpace: "nowrap",
            }}
          >
            in
          </Typography>
          <SelectWorkspaceDropdown />
        </Stack>
      }
      onCloseClick={() => onCloseClick()}
      userName="Abhishek"
      userStatus={undefined}
    />
  );
};

export default TaskReplyBar;
