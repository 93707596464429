import React, { memo } from "react";
import {
  Container,
  Stack,
  InputBase,
  Tooltip,
  IconButton,
  AvatarGroup,
  Avatar,
  Typography,
} from "@mui/material";
import styles from "../../../../../../components/BoardCard/BoardCardDisplay/BoardCardDisplay.module.css";
import {
  BoardCardDatePicker,
  CheckboxMedium,
  ColoredBadge,
  DropdownWithColor,
  DUMMY_PRIORITY_OPTIONS,
  EditableTimeTextField,
  ThreeDotMenuLarge,
} from "../../../../../../../../common";
import {
  ClockIcon,
  CommentsIcon,
  DepedanciesIcon,
  FlagIcon,
  FullViewIcon,
  LastCalendarIcon,
  NotesIcon,
  SelectIcon,
  StrongestrelationshipIcon,
} from "../../../../../../../../../images";
import { adjustHexColor } from "../../../../../../../../../Utils";
import dayjs, { Dayjs } from "dayjs";
import { THREE_DOT_MENU_OPTIONS } from "../../../../../../components/BoardCard/BoardCardDisplay/BoardCardDisplay";
interface TaskBodyProps {
  taskData?: any;
  c?: any;
}

const TaskBody: React.FC<TaskBodyProps> = ({ taskData, c }) => {
  const badgedColor = adjustHexColor(
    taskData?.status?.customColorHex || taskData?.status?.defaultColorHex || c,
    100
  );
  const initialDate: Dayjs = dayjs(taskData?.dueDate);
  return (
    <Stack className={styles["board-card-display"]} sx={{ bgcolor: "white" }}>
      <Stack className={styles["board-card-display__header"]}>
        <Stack className={styles["board-card-display__header-name"]}>
          <InputBase
            sx={{
              font: "normal normal normal 14px/20px Source Serif Pro",
              flexGrow: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            value={taskData?.name}
            readOnly={true}
          // onChange={handleChange}
          // onBlur={(e: any) => handleBlur(e)}
          // onKeyDown={(e: any) => handleKeyDown(e)}
          />
        </Stack>
        <Stack className={styles["board-card-display__header-options"]}>
          <ThreeDotMenuLarge options={THREE_DOT_MENU_OPTIONS} />
          <Tooltip placement="top" arrow title="Full View">
            <IconButton
              disableRipple
              className={styles["board-card-display__icon-button"]}
            // onClick={() => handleFullView && handleFullView("view", stack)}
            >
              <FullViewIcon />
            </IconButton>
          </Tooltip>
          <CheckboxMedium />
        </Stack>
      </Stack>

      {/* bard-body here */}
      <Stack className={styles["board-card-display__body"]}>
        {/* -- icon and badge here -- */}
        <Stack className={styles["board-card-display__body-item"]}>
          <SelectIcon color={badgedColor || "black"} w={15} />
          <ColoredBadge title={taskData?.status?.value} bgColor={badgedColor} />
        </Stack>

        {/* -- time component -- */}
        <Stack className={styles["board-card-display__body-item"]}>
          <LastCalendarIcon w={15} />
          <EditableTimeTextField
            initialValue={taskData?.estimatedTime?.hours || "0"}
            variant="hrs"
            onSave={(hours) => {
              // Call updateStackData with hours and current minutes
            }}
          />
          <EditableTimeTextField
            initialValue={taskData?.estimatedTime?.minutes || "0"}
            variant="mins"
            onSave={(minutes) => {
              // Call updateStackData with current hours and new minutes
            }}
          />
        </Stack>

        {/* -- priority component -- */}
        <Stack className={styles["board-card-display__body-item"]}>
          <FlagIcon w={15} />
          <DropdownWithColor
            options={DUMMY_PRIORITY_OPTIONS}
            onChange={(value) => null}
            onSelect={(value) => { }}
            initialValue={taskData?.priority}
          />
          {/* <HighPriorityMenuDropdown /> */}
        </Stack>

        <Stack className={styles["board-card-display__body-item"]}>
          <StrongestrelationshipIcon w={15} />

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            {taskData?.assignedUsers?.length > 1 ? (
              <AvatarGroup max={4}>
                {taskData?.assignedUsers?.map(
                  (assignee: any, index: number) => (
                    <Avatar
                      key={assignee?._id || index}
                      alt={`${assignee?.firstName} ${assignee?.lastName}`}
                      src={assignee?.avatar}
                      sx={{ width: 20, height: 20 }}
                    />
                  )
                )}
              </AvatarGroup>
            ) : (
              <Stack direction="row" gap="5px" alignItems="center">
                <Avatar
                  alt={`${taskData?.assignedUsers?.[0]?.firstName} ${taskData?.assignedUsers?.[0]?.lastName}`}
                  src={taskData?.assignedUsers?.[0]?.avatar}
                  sx={{ width: 20, height: 20 }}
                />
                <Typography
                  sx={{
                    font: "normal normal 300 12px/16px Source Serif Pro",
                    color: "black",
                  }}
                >
                  {`${taskData?.assignedUsers?.[0]?.firstName} ${taskData?.assignedUsers?.[0]?.lastName}`}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>

        {/* --date component -- */}
        <Stack className={styles["board-card-display__body-item"]}>
          <ClockIcon w={15} />
          <BoardCardDatePicker
            initialDate={initialDate}
            onSelect={(value: any) => { }}
          />
        </Stack>
      </Stack>

      {/* card-footer here */}
      <Stack className={styles["board-card-display__footer"]}>
        {/* -- add notes component -- */}
        <Tooltip placement="top" arrow title="Add Notes">
          <IconButton
            className={styles["board-card-display__icon-button"]}
            disableRipple
          >
            <NotesIcon w={15} />
          </IconButton>
        </Tooltip>

        {/* -- add comments component -- */}
        <Tooltip placement="top" arrow title="Add Comments">
          <IconButton
            className={styles["board-card-display__icon-button"]}
            disableRipple
          >
            <CommentsIcon w={15} />
          </IconButton>
        </Tooltip>

        {/* -- total depedancies number -- */}
        <Tooltip placement="top" arrow title="Depedancies">
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              cursor: "default",
            }}
          >
            <DepedanciesIcon w={15} />
            <Typography
              sx={{ font: "normal normal 300 12px/16px Source Serif Pro" }}
            >
              0
            </Typography>
          </Stack>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default memo(TaskBody);
