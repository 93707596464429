import { Box, Rating, Stack, Typography } from "@mui/material";
import React from "react";
import {
  DummyImage,
  RatingStarIcon,
  WebexPng,
} from "../../../../../../../images";
import {
  AvatarNormal,
  ButtonSmall,
  ButtonType,
  CompanyLongDropdown,
  DropdownWithColor,
  DUMMY_CompanyLongDropdownOption,
  DUMMY_DEALSTAGE_OPTIONS,
  LargeInputBox,
  SingleDatePickerSmall,
  SingleTeammateDropdown,
  SmallButtonColor,
} from "../../../../../../common";
import { adjustHexColor } from "../../../../../../../Utils";
import M from "../../AddDealPopupLarge.module.css";
import S from "./ProjectPublicationView.module.css";

interface ProjectPublicationViewProps {}

const ProjectPublicationView: React.FC<ProjectPublicationViewProps> = ({}) => {
  return (
    <>
      <Stack className={M["add-deal-popup-large__header"]}>
        <AvatarNormal
          size={15}
          username="Kendal Michaels VI"
          avatarColor="orange"
          imgSrc={DummyImage}
        />
        <Typography
          sx={{ font: "normal normal normal 14px/20px Source Serif Pro" }}
        >
          Kendal Michaels
        </Typography>
      </Stack>

      {/* Card body */}
      <Stack className={M["add-deal-popup-large__body"]}>
        <LargeInputBox
          borderColor="#E9EDF2"
          height="30px"
          label={"Project name"}
          value=""
          onChange={(value) => null}
        />

        <Stack className={S["project-publication-view__row"]}>
          <DropdownWithColor
            options={DUMMY_DEALSTAGE_OPTIONS}
            label="Outreach type"
            placeholder="choose one... "
            onChange={(value) => console.log("new data", value)}
            onSelect={(value) => console.log("selected option", value)}
          />

          <SingleTeammateDropdown
            label="Journalist"
            placeholder="select user"
          />

          <CompanyLongDropdown
            width="100%"
            label="Associated company"
            options={DUMMY_CompanyLongDropdownOption}
          />
        </Stack>

        <LargeInputBox
          height="30px"
          borderColor="#E9EDF2"
          label={"Link to publication"}
          value=""
          onChange={(value) => null}
        />

        <Stack className={S["project-publication-view__row--priority"]}>
          <DropdownWithColor
            options={DUMMY_DEALSTAGE_OPTIONS}
            label="Team"
            placeholder="choose one... "
            onChange={(value) => console.log("new data", value)}
            onSelect={(value) => console.log("selected option", value)}
          />

          <SingleDatePickerSmall label="Publication date" />

          <Stack className={S["project-publication-view__confidence"]}>
            <Typography
              sx={{
                font: "normal normal 300 10px/13px Source Serif Pro",
                marginBottom: "5px",
              }}
            >
              Publication confidence
            </Typography>
            <Rating
              value={3}
              // readOnly
              className={S["project-publication-view__rating"]}
              emptyIcon={
                <RatingStarIcon w={20} color={adjustHexColor("#E2875E", 20)} />
              }
              icon={<RatingStarIcon w={20} color="#E2875E" />}
            />
          </Stack>

          <DropdownWithColor
            options={DUMMY_DEALSTAGE_OPTIONS}
            label="Priority"
            placeholder="choose one... "
            onChange={(value) => console.log("new data", value)}
            onSelect={(value) => console.log("selected option", value)}
          />

          <DropdownWithColor
            options={DUMMY_DEALSTAGE_OPTIONS}
            label="Status"
            placeholder="choose one... "
            onChange={(value) => console.log("new data", value)}
            onSelect={(value) => console.log("selected option", value)}
          />
        </Stack>

        <LargeInputBox
          height="30px"
          borderColor="#E9EDF2"
          label={"Details"}
          value=""
          onChange={(value) => null}
        />

        {/* Buttons */}
        <Stack className={S["project-publication-view__buttons"]}>
          <ButtonSmall
            label="Cancel"
            types={ButtonType.Button}
            colorVarient={SmallButtonColor.Transparent}
          />
          <ButtonSmall
            label="Save"
            types={ButtonType.Button}
            colorVarient={SmallButtonColor.CRMOrange}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ProjectPublicationView;
