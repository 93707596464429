import { Button, ButtonProps, Typography } from "@mui/material";
import React from "react";
import { SquarePlusIcon } from "../../../../../images";

interface IconSmallButtonProps extends ButtonProps {
  buttonLabel: string;
}

const IconSmallButton: React.FC<IconSmallButtonProps> = ({
  buttonLabel,
  ...props
}) => {
  return (
    <Button
      sx={{
        flexDirection: "row",
        alignItems: "center",
        height: "25px",
        p: 0,
        boxSizing: "border-box",
        textTransform: "none",
        justifyContent: "start",
        minWidth: "125px",
        width: "fit-content",
        paddingX: "10px",
        gap: "5px",
        border: "1px solid #E9EDF2",
      }}
      {...props}
    >
      <SquarePlusIcon w={15} />
      <Typography
        sx={{
          font: "normal normal normal 10px/13px Source Serif Pro",
          color: "black",
        }}
      >
        {buttonLabel}
      </Typography>
    </Button>
  );
};

export default IconSmallButton;
