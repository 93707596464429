import {Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {HighlightTabs, HighlightTabsType} from "../../common";
import {SelectedFileView, UploadBoxView} from "./view";
import {ButtonSmall, SearchFoldersDropdown, SmallButtonColor} from "../../../../common";
import {uploadCloudFile__api} from "../../../../../redux";
import {useDispatch, useSelector} from "react-redux";
import {deleteSlectedMessageFile__api} from "../../../../../redux/Slice/message/messageActions";
import {RootState} from "../../../../../redux/store";
import {GlobalState} from "../../../../../redux/Slice/Global/globalTypes";

interface FileUploadMenuProps {
    onFilesUpload?: (files: any[]) => void; // Add this line
    handleCloseFileUploadMenu: () => void;
}

const FileUploadMenu: React.FC<FileUploadMenuProps> = ({onFilesUpload, handleCloseFileUploadMenu}) => {
    const tabList: HighlightTabsType[] = [
        {tabindex: 0, translatedTitle: "Upload", value: "upload"},
        {tabindex: 1, translatedTitle: "Cloud Storage", value: "cloudStorage"},
    ];
    const [tabPageIndex, setTabPageIndex] = useState<number>(0);
    const [filesToUpload, setFilesToUpload] = useState<any[]>([]);
    const dispatch = useDispatch();
    const {cloudStorageFiles} = useSelector((state: RootState) => state.global as GlobalState);

    const handleFileChange = (file: File) => {
        if (file) {
            const fileType = file.type.split('/')[0]; // Get the file type (e.g., image, video, audio)
            const tempFile = {
                fileName: file.name,
                isLoading: true,
                fileId: Date.now(), // Temporary ID
                fileType: fileType,
            };
            setFilesToUpload((prevFiles) => [...prevFiles, tempFile]);
            handleFileUpload(file, tempFile.fileId, fileType); // Pass the file type to handleFileUpload
        }
    };

    const handleFileUpload = async (file: File, tempFileId: number, fileType: string) => {
        const formData = new FormData();
        formData.append("files", file);

        try {
            const response: any = await dispatch(uploadCloudFile__api(formData));
            const fileLink = response.data?.[0]?.path; // Ensure safe access with optional chaining
            const fileName = response.data?.[0]?.name; // Ensure safe access with optional chaining
            const fileId = response.data?.[0]?._id; // Ensure safe access with optional chaining

            // Handle the uploaded file link based on its type
            if (response?.success) {
                setFilesToUpload((prevFiles) =>
                    prevFiles.map((f) =>
                        f.fileId === tempFileId
                            ? {
                                fileUrl: fileLink,
                                fileName: fileName,
                                fileId: fileId,
                                fileType: fileType,
                                isLoading: false
                            }
                            : f
                    )
                );
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    const handleRemoveSelectedFile = async (fileId: any) => {
        if (fileId) {
            const response: any = await dispatch(deleteSlectedMessageFile__api(fileId));
            if (response?.success) {
                setFilesToUpload((prevFiles) =>
                    prevFiles.filter((file) => file.fileId !== fileId)
                );
            }
        }
    };

    const handleClearFileUploadMenu = () => {
        setFilesToUpload([]);
        handleCloseFileUploadMenu();
    };

    useEffect(() => {
        if (onFilesUpload) {
            onFilesUpload(filesToUpload);
        }
    }, [filesToUpload]);

    return (
        <Stack
            sx={{
                width: "270px",
                height: "335px",
                borderRadius: "5px",
                boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
                bgcolor: "white",
                padding: "8px 5px 5px 10px",
                boxSizing: "border-box",
                overflow: "hidden",
                gap: "10px",
                flexShrink: 0,
                border: "0.5px solid #E9EDF2",
            }}
        >
            <HighlightTabs onChange={(val) => setTabPageIndex(val.tabindex)} options={tabList}/>

            <Stack
                sx={{
                    overflow: "auto",
                    scrollbarWidth: "none",
                    gap: "6px",
                    flex: 1,
                }}
            >
                {(() => {
                    switch (tabPageIndex) {
                        case 0:
                            return (
                                <Stack sx={{gap: "10px", flex: 1}}>
                                    <Stack sx={{gap: "10px", flex: 1}}>
                                        <UploadBoxView onFileSelect={handleFileChange}/>
                                        <Stack
                                            sx={{
                                                overflow: "auto",
                                                scrollbarWidth: "none",
                                                gap: "5px",
                                                height: "130px",
                                            }}
                                        >
                                            {filesToUpload.length > 0 &&
                                                filesToUpload.map((file, index) => (
                                                    <SelectedFileView
                                                        key={index}
                                                        fileName={file.fileName}
                                                        isLoading={file.isLoading}
                                                        onDeleteButtonClick={() => handleRemoveSelectedFile(file.fileId)}
                                                    />
                                                ))}
                                        </Stack>
                                    </Stack>

                                    <Stack
                                        sx={{
                                            flexDirection: "row",
                                            justifyContent: "end",
                                            gap: "12px",
                                        }}
                                    >
                                        <ButtonSmall
                                            colorVarient={SmallButtonColor.Transparent}
                                            label="Cancel"
                                            onClick={handleClearFileUploadMenu}
                                            sx={{border: "1px solid #E9EDF2"}}
                                        />
                                        <ButtonSmall colorVarient={SmallButtonColor.MessagePurple}
                                                     onClick={handleCloseFileUploadMenu} label="Save"/>
                                    </Stack>
                                </Stack>
                            );

                        case 1:
                            return (
                                <Stack sx={{gap: "10px", flex: 1}}>
                                    <Stack sx={{gap: "10px", flex: 1}}>
                                        <SearchFoldersDropdown
                                            placeholder="Search all items"
                                            // showFilterMenu
                                            onSelect={() => null}
                                            options={cloudStorageFiles}
                                            showResultInSingleLine
                                        />
                                    </Stack>
                                    <Stack sx={{gap: "10px", flex: 1}}>
                                        <Stack
                                            sx={{
                                                overflow: "auto",
                                                scrollbarWidth: "none",
                                                gap: "5px",
                                                height: "130px",
                                            }}
                                        >
                                            {filesToUpload.length > 0 &&
                                                filesToUpload.map((file, index) => (
                                                    <SelectedFileView
                                                        key={index}
                                                        fileName={file.fileName}
                                                        isLoading={file.isLoading}
                                                        onDeleteButtonClick={() => handleRemoveSelectedFile(file.fileId)}
                                                    />
                                                ))}
                                        </Stack>
                                    </Stack>

                                    <Stack
                                        sx={{
                                            flexDirection: "row",
                                            justifyContent: "end",
                                            gap: "12px",
                                        }}
                                    >
                                        <ButtonSmall
                                            colorVarient={SmallButtonColor.Transparent}
                                            label="Cancel"
                                            onClick={handleClearFileUploadMenu}
                                            sx={{border: "1px solid #E9EDF2"}}
                                        />
                                        <ButtonSmall colorVarient={SmallButtonColor.MessagePurple}
                                                     onClick={handleCloseFileUploadMenu} label="Save"/>
                                    </Stack>
                                </Stack>
                            );

                        default:
                            return null;
                    }
                })()}
            </Stack>
        </Stack>
    );
};

export default FileUploadMenu;