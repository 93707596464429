import { createApiAction } from "../constantApi_config";
import * as workspaceActions from "./workspaceSlice";
import * as workspaceActionType from "./workspaceTypes";

/* createWorkspace__api */
/* Done */
export const createWorkspace__api = (
    payload: workspaceActionType.CreateWorkspace | FormData
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        headers: {
            "Content-Type": "multipart/form-data",
        },
        url: "/workspace",
        data: payload,
        onStart: workspaceActions.createWorkspace__RequestAction.type,
        onSuccess: workspaceActions.createWorkspace__SuccessAction.type,
        onError: workspaceActions.createWorkspace__FailureAction.type,
    });

/* getAllWorkspace__api */
/* Done */
export const getAllWorkspace__api = () =>
    createApiAction({
        axiosRequired: true, // Explicitly setting axiosRequired
        method: "GET",
        url: "/workspace",
        onStart: workspaceActions.getAllWorkspace__RequestAction.type,
        onSuccess: workspaceActions.getAllWorkspace__SuccessAction.type,
        onError: workspaceActions.getAllWorkspace__FailureAction.type,
    });

export const getAllSharedWorkspace__api = () =>
    createApiAction({
        axiosRequired: true, // Explicitly setting axiosRequired
        method: "GET",
        url: "/workspace/shared",
        onStart: workspaceActions.getALllSharedWorkspace__RequestAction.type,
        onSuccess: workspaceActions.getAllSharedWorkspace__SuccessAction.type,
        onError: workspaceActions.getALllSharedWorkspace__FailureAction.type,
    });

/* getOneWorkspace__api */
/* Done */
export const getOneWorkspace__api = (
    payload: workspaceActionType.Get_delete_By____Id
) =>
    createApiAction({
        axiosRequired: true, // Explicitly setting axiosRequired
        method: "GET",
        url: `/workspace/${payload._id}`,
        onStart: workspaceActions.getOneWorkspace__RequestAction.type,
        onSuccess: workspaceActions.getOneWorkspace__SuccessAction.type,
        onError: workspaceActions.getOneWorkspace__FailureAction.type,
    });

/* updateWorkspace__api */
/* Done */
export const updateWorkspace__api = (
    payload: workspaceActionType.UpdateWorkspace | FormData,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true, // Explicitly setting axiosRequired
        method: "PUT",
        url: `/workspace/${workspaceId}`,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        data: payload,
        onStart: workspaceActions.updateWorkspace__RequestAction.type,
        onSuccess: workspaceActions.updateWorkspace__SuccessAction.type,
        onError: workspaceActions.updateWorkspace__FailureAction.type,
    });
/* deleteWorkspace__api */
/* Done */
export const deleteWorkspace__api = (
    payload: workspaceActionType.Get_delete_By____Id
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/workspace/${payload._id}`,
        onStart: workspaceActions.deleteWorkspace__RequestAction.type,
        onSuccess: workspaceActions.deleteWorkspace__SuccessAction.type,
        onError: workspaceActions.deleteWorkspace__FailureAction.type,
    });

/* addWorkspaceContent__api */
/* Done */
export const addWorkspaceContent__api = (
    payload: workspaceActionType.AddWorkspaceContent,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${workspaceId}/content`,
        data: payload,
        onStart: workspaceActions.addWorkspaceContent__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceContent__SuccessAction.type,
        onError: workspaceActions.addWorkspaceContent__FailureAction.type,
    });

/* reOrderWorkspaceContent__api */
/* Done --- some funtionality pending */
export const reOrderWorkspaceContent__api = (payload: {
    workspaceId: string;
}) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/workspace/${payload.workspaceId}/content/reorder`,
        data: payload,
        onStart: workspaceActions.reOrderWorkspaceContent__RequestAction.type,
        onSuccess: workspaceActions.reOrderWorkspaceContent__SuccessAction.type,
        onError: workspaceActions.reOrderWorkspaceContent__FailureAction.type,
    });

/* updateWorkspaceContent__api */
/* Done */
export const updateWorkspaceContent__api = (
    payload: workspaceActionType.UpdateWorkSpaceContent,
    workspaceId: string,
    contentId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/workspace/${workspaceId}/content/${contentId}`,
        data: payload,
        onStart: workspaceActions.updateWorkspaceContent__RequestAction.type,
        onSuccess: workspaceActions.updateWorkspaceContent__SuccessAction.type,
        onError: workspaceActions.updateWorkspaceContent__FailureAction.type,
    });

/* deleteWorkspaceContent__api */
/* Done -- need delete button in frontend to implement */
export const deleteWorkspaceContent__api = (
    componentId: string,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/workspace/${workspaceId}/content/${componentId}`, // Matches your router setup
        onStart: workspaceActions.deleteWorkspaceContent__RequestAction.type,
        onSuccess: workspaceActions.deleteWorkspaceContent__SuccessAction.type,
        onError: workspaceActions.deleteWorkspaceContent__FailureAction.type,
    });

/* addWorkspaceMember__api */
/* Can't be setup untill we have team setup in settings */
export const addWorkspaceMember__api = (
    payload: workspaceActionType.AddWorkspaceMember,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${workspaceId}/members`,
        data: payload,
        onStart: workspaceActions.addWorkspaceMember__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceMember__SuccessAction.type,
        onError: workspaceActions.addWorkspaceMember__FailureAction.type,
    });

/* getAllWorkspaceMember__api */
/* Done */
export const getAllWorkspaceMember__api = (payload: { workspaceId: string }) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/workspace/${payload.workspaceId}/members`,
        onStart: workspaceActions.getAllWorkspaceMember__RequestAction.type,
        onSuccess: workspaceActions.getAllWorkspaceMember__SuccessAction.type,
        onError: workspaceActions.getAllWorkspaceMember__FailureAction.type,
    });

/* updateWorkspaceMember__api */
/* Can't be setup untill we have team setup in settings */
export const updateWorkspaceMember__api = (
    workspaceId: string,
    memberId: string,
    payload: workspaceActionType.UpdateWorkspaceMember
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/workspace/${workspaceId}/member/${memberId}`,
        data: payload,
        onStart: workspaceActions.updateWorkspaceMember__RequestAction.type,
        onSuccess: workspaceActions.updateWorkspaceMember__SuccessAction.type,
        onError: workspaceActions.updateWorkspaceMember__FailureAction.type,
    });

/* deleteWorkspaceMember__api */
export const deleteWorkspaceMember__api = (
    payload: workspaceActionType.Get_delete_By____Id,
    memberId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/workspace/${payload._id}/members/${memberId}`,
        onStart: workspaceActions.deleteWorkspaceMember__RequestAction.type,
        onSuccess: workspaceActions.deleteWorkspaceMember__SuccessAction.type,
        onError: workspaceActions.deleteWorkspaceMember__FailureAction.type,
    });

/* addWorkspaceCustomField__api */
/* No UI yet */
export const addWorkspaceCustomField__api = (
    payload: workspaceActionType.Add_or_update__WorkspaceCustomField
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/custom-fields`,
        data: payload,
        onStart: workspaceActions.addWorkspaceCustomField__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceCustomField__SuccessAction.type,
        onError: workspaceActions.addWorkspaceCustomField__FailureAction.type,
    });

/* updateWorkspaceCustomField__api */
/* No UI yet */
export const updateWorkspaceCustomField__api = (
    workspaceId: string,
    fieldId: string,
    payload: workspaceActionType.Add_or_update__WorkspaceCustomField
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/workspace/${workspaceId}/custom-fields/${fieldId}`,
        data: payload,
        onStart: workspaceActions.updateWorkspaceCustomField__RequestAction.type,
        onSuccess: workspaceActions.updateWorkspaceCustomField__SuccessAction.type,
        onError: workspaceActions.updateWorkspaceCustomField__FailureAction.type,
    });

/* deleteWorkspaceCustomField__api */
/* No UI yet */
export const deleteWorkspaceCustomField__api = (payload: {
    workspaceId: string;
    fieldId: string;
}) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/workspace/${payload.workspaceId}/custom-fields/${payload.fieldId}`,
        onStart: workspaceActions.deleteWorkspaceCustomField__RequestAction.type,
        onSuccess: workspaceActions.deleteWorkspaceCustomField__SuccessAction.type,
        onError: workspaceActions.deleteWorkspaceCustomField__FailureAction.type,
    });

/* addWorkspaceTag__api */
/* No UI yet */
export const addWorkspaceTag__api = (
    payload: workspaceActionType.AddWorkspaceTag
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/tags`,
        data: payload,
        onStart: workspaceActions.addWorkspaceTag__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceTag__SuccessAction.type,
        onError: workspaceActions.addWorkspaceTag__FailureAction.type,
    });

/* updateWorkspaceTag__api */
/* No UI yet */
export const updateWorkspaceTag__api = (
    payload: workspaceActionType.AddWorkspaceTag
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/workspace/${payload.workspaceId}/tags/${payload.tagId}`,
        data: payload,
        onStart: workspaceActions.updateWorkspaceTag__RequestAction.type,
        onSuccess: workspaceActions.updateWorkspaceTag__SuccessAction.type,
        onError: workspaceActions.updateWorkspaceTag__FailureAction.type,
    });

/* deleteWorkspaceTag__api */
/* No UI yet */
export const deleteWorkspaceTag__api = (
    payload: workspaceActionType.DeleteWorkspaceTag
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/workspace/${payload.workspaceId}/tags`,
        data: payload,
        onStart: workspaceActions.deleteWorkspaceTag__RequestAction.type,
        onSuccess: workspaceActions.deleteWorkspaceTag__SuccessAction.type,
        onError: workspaceActions.deleteWorkspaceTag__FailureAction.type,
    });

/* addWorkspaceTemplate__api */
/* Done */
export const addWorkspaceTemplate__api = (
    payload: workspaceActionType.AddWorkspaceTemplate,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${workspaceId}/templates`,
        data: payload,
        onStart: workspaceActions.addWorkspaceTemplate__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceTemplate__SuccessAction.type,
        onError: workspaceActions.addWorkspaceTemplate__FailureAction.type,
    });

/* applyWorkspaceTemplate__api */
/* Done -- Remain need to render templates */
export const applyWorkspaceTemplate__api = (payload: {
    workspaceId: string;
    templateId: string;
}) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/apply-template/${payload.templateId}`,
        onStart: workspaceActions.applyWorkspaceTemplate__RequestAction.type,
        onSuccess: workspaceActions.applyWorkspaceTemplate__SuccessAction.type,
        onError: workspaceActions.applyWorkspaceTemplate__FailureAction.type,
    });

/* addWorkspaceIntegration__api */
/* Not Mention in doc */
export const addWorkspaceIntegration__api = (
    payload: workspaceActionType.AddWorkspaceIntegration
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/integrations`,
        data: payload,
        onStart: workspaceActions.addWorkspaceIntegration__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceIntegration__SuccessAction.type,
        onError: workspaceActions.addWorkspaceIntegration__FailureAction.type,
    });

/* updateWorkspaceIntegration__api */
/* Not Mention in doc */
export const updateWorkspaceIntegration__api = (
    payload: workspaceActionType.Update__Delete__WorkspaceIntegration
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/workspace/${payload.workspaceId}/integrations/${payload.integrationId}`,
        data: payload,
        onStart: workspaceActions.updateWorkspaceIntegration__RequestAction.type,
        onSuccess: workspaceActions.updateWorkspaceIntegration__SuccessAction.type,
        onError: workspaceActions.updateWorkspaceIntegration__FailureAction.type,
    });

/* deleteWorkspaceIntegration__api */
/* Not Mention in doc */
export const deleteWorkspaceIntegration__api = (
    payload: workspaceActionType.Update__Delete__WorkspaceIntegration
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/workspace/${payload.workspaceId}/integrations/${payload.integrationId}`,
        onStart: workspaceActions.deleteWorkspaceIntegration__RequestAction.type,
        onSuccess: workspaceActions.deleteWorkspaceIntegration__SuccessAction.type,
        onError: workspaceActions.deleteWorkspaceIntegration__FailureAction.type,
    });

/* addWorkspaceActivity__api */
/* Not Mention in doc */
export const addWorkspaceActivity__api = (
    payload: workspaceActionType.AddWorkspaceActivity
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/activities`,
        data: payload,
        onStart: workspaceActions.addWorkspaceActivity__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceActivity__SuccessAction.type,
        onError: workspaceActions.addWorkspaceActivity__FailureAction.type,
    });

/* getAllWorkspaceActivity__api */
/* Not Mention in doc */
export const getAllWorkspaceActivity__api = (
    payload: workspaceActionType.GetWorkspaceAllActivityParams
) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/workspace/${payload.workspaceId}/activity?page=${payload.page}&limit==${payload.limit}&startDate==${payload.startDate}&endDate==${payload.endDate}&type==${payload.type}&userId==${payload.userId}`,
        onStart: workspaceActions.getAllWorkspaceActivity__RequestAction.type,
        onSuccess: workspaceActions.getAllWorkspaceActivity__SuccessAction.type,
        onError: workspaceActions.getAllWorkspaceActivity__FailureAction.type,
    });

/* updateWorkspaceColumn__api */
/* Not Mention in doc */
export const updateWorkspaceColumn__api = (
    payload: workspaceActionType.UpdateWorkspaceColumn
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/workspace/${payload.workspaceId}/columns`,
        data: payload,
        onStart: workspaceActions.updateWorkspaceColumn__RequestAction.type,
        onSuccess: workspaceActions.updateWorkspaceColumn__SuccessAction.type,
        onError: workspaceActions.updateWorkspaceColumn__FailureAction.type,
    });

/* addWorkspaceConnection__api */
/* Not set up yet */
export const addWorkspaceConnection__api = (
    payload: workspaceActionType.AddWorkspaceConnection
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/connect`,
        data: payload,
        onStart: workspaceActions.addWorkspaceConnection__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceConnection__SuccessAction.type,
        onError: workspaceActions.addWorkspaceConnection__FailureAction.type,
    });

/* addWorkspaceReminder__api */
/* Not set up yet */
export const addWorkspaceReminder__api = (
    payload: workspaceActionType.AddWorkspaceReminder
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/reminders`,
        data: payload,
        onStart: workspaceActions.addWorkspaceReminder__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceReminder__SuccessAction.type,
        onError: workspaceActions.addWorkspaceReminder__FailureAction.type,
    });

/* addWorkspaceVideoReport__api */
/* Not set up yet */
export const addWorkspaceVideoReport__api = (
    payload: workspaceActionType.AddWorkspaceVideoReport
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/video-reports`,
        data: payload,
        onStart: workspaceActions.addWorkspaceVideoReport__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceVideoReport__SuccessAction.type,
        onError: workspaceActions.addWorkspaceVideoReport__FailureAction.type,
    });

/* addWorkspaceFile__api */
/* Not set up yet */
export const addWorkspaceFile__api = (
    payload: workspaceActionType.AddWorkspaceFile
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/files`,
        data: payload,
        onStart: workspaceActions.addWorkspaceFile__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceFile__SuccessAction.type,
        onError: workspaceActions.addWorkspaceFile__FailureAction.type,
    });

/* addWorkspaceMedia__api */
/* Not set up yet */
export const addWorkspaceMedia__api = (
    payload: workspaceActionType.AddWorkspaceMedia
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/media`,
        data: payload,
        onStart: workspaceActions.addWorkspaceMedia__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceMedia__SuccessAction.type,
        onError: workspaceActions.addWorkspaceMedia__FailureAction.type,
    });

/* addWorkspaceBookMark__api */
/* Not set up yet */
export const addWorkspaceBookMark__api = (
    payload: workspaceActionType.AddWorkspaceBookmark
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/bookmarks`,
        data: payload,
        onStart: workspaceActions.addWorkspaceBookMark__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceBookMark__SuccessAction.type,
        onError: workspaceActions.addWorkspaceBookMark__FailureAction.type,
    });

/* addWorkspaceTableOfContent__api */
/* Not set up yet, nor UI */
export const addWorkspaceTableOfContent__api = (
    payload: workspaceActionType.AddWorkspaceTableOfContents
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/workspace/${payload.workspaceId}/table-of-contents`,
        data: payload,
        onStart: workspaceActions.addWorkspaceTableOfContent__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceTableOfContent__SuccessAction.type,
        onError: workspaceActions.addWorkspaceTableOfContent__FailureAction.type,
    });

/* addWorkspaceEquation__api */
/* Not set up yet */
export const addWorkspaceEquation__api = (
    payload: workspaceActionType.AddWorkspaceEquation
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/equations`,
        data: payload,
        onStart: workspaceActions.addWorkspaceEquation__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceEquation__SuccessAction.type,
        onError: workspaceActions.addWorkspaceEquation__FailureAction.type,
    });

/* addWorkspaceButton__api */
/* Not set up yet */
export const addWorkspaceButton__api = (
    payload: workspaceActionType.AddWorkspaceButton
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/buttons`,
        data: payload,
        onStart: workspaceActions.addWorkspaceButton__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceButton__SuccessAction.type,
        onError: workspaceActions.addWorkspaceButton__FailureAction.type,
    });

/* addWorkspaceSyncElement__api */
/* Not set up yet */
export const addWorkspaceSyncElement__api = (
    payload: workspaceActionType.AddWorkspaceSyncElement
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/sync-elements`,
        data: payload,
        onStart: workspaceActions.addWorkspaceSyncElement__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceSyncElement__SuccessAction.type,
        onError: workspaceActions.addWorkspaceSyncElement__FailureAction.type,
    });

/* addWorkspaceHeadingToggle__api */
/* Not set up yet */
export const addWorkspaceHeadingToggle__api = (
    payload: workspaceActionType.AddWorkspaceHeadingToggle
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/heading-toggles`,
        data: payload,
        onStart: workspaceActions.addWorkspaceHeadingToggle__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceHeadingToggle__SuccessAction.type,
        onError: workspaceActions.addWorkspaceHeadingToggle__FailureAction.type,
    });

/* addWorkspaceCodeBlock__api */
/* Not set up yet */
export const addWorkspaceCodeBlock__api = (
    payload: workspaceActionType.AddWorkspaceCodeBlock
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/workspace/${payload.workspaceId}/code-blocks`,
        data: payload,
        onStart: workspaceActions.addWorkspaceCodeBlock__RequestAction.type,
        onSuccess: workspaceActions.addWorkspaceCodeBlock__SuccessAction.type,
        onError: workspaceActions.addWorkspaceCodeBlock__FailureAction.type,
    });

/* deleteWorkspaceInBulk__api */
/* Not set up yet */
export const deleteWorkspaceInBulk__api = (
    workspaceIds: workspaceActionType.DeleteWorkspaceInBulk
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: "/workspace/bulk",
        data: { workspaceIds },
        onStart: workspaceActions.deleteWorkspaceInBulk__RequestAction.type,
        onSuccess: workspaceActions.deleteWorkspaceInBulk__SuccessAction.type,
        onError: workspaceActions.deleteWorkspaceInBulk__FailureAction.type,
    });

/* Add Grid workspace API */
export const addGridWorkspace__api = (
    payload: workspaceActionType.AddGridPayload
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: "/grid",
        data: payload,
        onStart: workspaceActions.addGrid__RequestAction.type,
        onSuccess: workspaceActions.addGrid__SuccessAction.type,
        onError: workspaceActions.addGrid__FailureAction.type,
    });

/* Get All Grids API */
export const getAllGrids__api = (limit: number, page: number, sort: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/grids?limit=${limit}&page=${page}&sort=${sort}`,
        onStart: workspaceActions.getAllGrids__RequestAction.type,
        onSuccess: workspaceActions.getAllGrids__SuccessAction.type,
        onError: workspaceActions.getAllGrids__FailureAction.type,
    });

/* Get One Grid API */
export const getOneGrid__api = (gridId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/grid/${gridId}`,
        onStart: workspaceActions.getOneGrid__RequestAction.type,
        onSuccess: workspaceActions.getOneGrid__SuccessAction.type,
        onError: workspaceActions.getOneGrid__FailureAction.type,
    });

/* Update One Grid API */
export const updateOneGrid__api = (
    gridId: string,
    payload: workspaceActionType.UpdateGridPayload
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/grid/${gridId}`,
        data: payload,
        onStart: workspaceActions.updateOneGrid__RequestAction.type,
        onSuccess: workspaceActions.updateOneGrid__SuccessAction.type,
        onError: workspaceActions.updateOneGrid__FailureAction.type,
    });

/* Delete Grid API */
export const deleteGrid__api = (gridId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/grid/${gridId}`,
        onStart: workspaceActions.deleteGrid__RequestAction.type,
        onSuccess: workspaceActions.deleteGrid__SuccessAction.type,
        onError: workspaceActions.deleteGrid__FailureAction.type,
    });

/* Add Sheet API */
export const addSheet__api = (payload: workspaceActionType.AddSheetPayload) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: "/sheet",
        data: payload,
        onStart: workspaceActions.addSheet__RequestAction.type,
        onSuccess: workspaceActions.addSheet__SuccessAction.type,
        onError: workspaceActions.addSheet__FailureAction.type,
    });

/* Update Sheet API */
export const updateSheet__api = (
    sheetId: string,
    payload: workspaceActionType.UpdateSheetPayload
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/sheet/${sheetId}`,
        data: payload,
        onStart: workspaceActions.updateSheet__RequestAction.type,
        onSuccess: workspaceActions.updateSheet__SuccessAction.type,
        onError: workspaceActions.updateSheet__FailureAction.type,
    });

/* Delete Sheet API */
export const deleteSheet__api = (sheetId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/sheet/${sheetId}`,
        onStart: workspaceActions.deleteSheet__RequestAction.type,
        onSuccess: workspaceActions.deleteSheet__SuccessAction.type,
        onError: workspaceActions.deleteSheet__FailureAction.type,
    });

/* Reorder Sheets API */
export const reorderSheets__api = (
    payload: workspaceActionType.ReorderSheetsPayload
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: "/sheets/reorder",
        data: payload,
        onStart: workspaceActions.reorderSheets__RequestAction.type,
        onSuccess: workspaceActions.reorderSheets__SuccessAction.type,
        onError: workspaceActions.reorderSheets__FailureAction.type,
    });

/* Update Cell API */
export const updateCell__api = (
    cellId: string,
    payload: workspaceActionType.UpdateCellPayload
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/cell/${cellId}`,
        data: payload,
        onStart: workspaceActions.updateCell__RequestAction.type,
        onSuccess: workspaceActions.updateCell__SuccessAction.type,
        onError: workspaceActions.updateCell__FailureAction.type,
    });

/* Add Component API */
export const addComponent__api = (
    payload: workspaceActionType.AddComponentPayload,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/component/${workspaceId}`,
        data: payload,
        onStart: workspaceActions.addComponent__RequestAction.type,
        onSuccess: workspaceActions.addComponent__SuccessAction.type,
        onError: workspaceActions.addComponent__FailureAction.type,
    });

/* Add Component View API */
export const addComponentView__api = (
    payload: { view: string },
    workspaceId: string,
    componentId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/component/${workspaceId}/${componentId}/view`,
        data: payload,
        onStart: workspaceActions.AddComponentView__RequestAction.type,
        onSuccess: workspaceActions.AddComponentView__SuccessAction.type,
        onError: workspaceActions.AddComponentView__FailureAction.type,
    });

export const getAllComponents__api = (
    payload: workspaceActionType.GetAllComponentsParams
) => {
    return createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/component/${payload.workspaceId}?page=${payload.page}&limit=${payload.limit}&sort=${payload.sort}`,
        onStart: workspaceActions.getAllComponents__RequestAction.type,
        onSuccess: workspaceActions.getAllComponents__SuccessAction.type,
        onError: workspaceActions.getAllComponents__FailureAction.type,
        data: payload.workspaceId,
    });
};

/* Get One Component API */
export const getOneComponent__api = (
    workspaceId: string,
    componentId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/component/${workspaceId}/${componentId}`,
        onStart: workspaceActions.getOneComponent__RequestAction.type,
        onSuccess: workspaceActions.getOneComponent__SuccessAction.type,
        onError: workspaceActions.getOneComponent__FailureAction.type,
    });

/* Update One Component API */
export const updateOneComponent__api = (
    workspaceId: string,
    componentId: string,
    payload: workspaceActionType.UpdateComponentPayload
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/component/${workspaceId}/${componentId}`,
        data: payload,
        onStart: workspaceActions.updateOneComponent__RequestAction.type,
        onSuccess: workspaceActions.updateOneComponent__SuccessAction.type,
        onError: workspaceActions.updateOneComponent__FailureAction.type,
    });

/* Delete One Component API */
export const deleteOneComponent__api = (
    workspaceId: string,
    componentId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/component/${workspaceId}/${componentId}`,
        onStart: workspaceActions.deleteOneComponent__RequestAction.type,
        onSuccess: workspaceActions.deleteOneComponent__SuccessAction.type,
        onError: workspaceActions.deleteOneComponent__FailureAction.type,
    });

/* Delete Bulk Components API */
export const deleteBulkComponents__api = (
    payload: workspaceActionType.DeleteBulkComponentsPayload,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/component/${workspaceId}`,
        data: payload,
        onStart: workspaceActions.deleteBulkComponents__RequestAction.type,
        onSuccess: workspaceActions.deleteBulkComponents__SuccessAction.type,
        onError: workspaceActions.deleteBulkComponents__FailureAction.type,
    });

// Create Task
export const createTask__api = (
    payload: workspaceActionType.CreateTaskPayload
) =>
    createApiAction({
        onStart: workspaceActions.createTask__RequestAction.type,
        onSuccess: workspaceActions.createTask__SuccessAction.type,
        onError: workspaceActions.createTask__FailureAction.type,
        method: "POST",
        url: `/task`,
        data: payload,
        axiosRequired: true,
    });

// Get One Task
export const getOneTask__api = (workspaceId: string, taskId: string) =>
    createApiAction({
        onStart: workspaceActions.getOneTask__RequestAction.type,
        onSuccess: workspaceActions.getOneTask__SuccessAction.type,
        onError: workspaceActions.getOneTask__FailureAction.type,
        method: "GET",
        url: `/task/${workspaceId}/${taskId}`,
        axiosRequired: true,
    });

// Update One Task
export const updateOneTask__api = (
    taskId: string,
    payload: workspaceActionType.UpdateTaskPayload
) =>
    createApiAction({
        onStart: workspaceActions.updateOneTask__RequestAction.type,
        onSuccess: workspaceActions.updateOneTask__SuccessAction.type,
        onError: workspaceActions.updateOneTask__FailureAction.type,
        method: "PUT",
        url: `/task/${taskId}`,
        data: payload,
        axiosRequired: true,
    });

// Delete One Task
export const deleteOneTask__api = (workspaceId: string, taskId: string) =>
    createApiAction({
        onStart: workspaceActions.deleteOneTask__RequestAction.type,
        onSuccess: workspaceActions.deleteOneTask__SuccessAction.type,
        onError: workspaceActions.deleteOneTask__FailureAction.type,
        method: "DELETE",
        url: `/task/${workspaceId}/${taskId}`,
        axiosRequired: true,
    });

// Get All Tasks
export const getAllTasks__api = (workspaceId: string, componentId: string) =>
    createApiAction({
        onStart: workspaceActions.getAllTasks__RequestAction.type,
        onSuccess: workspaceActions.getAllTasks__SuccessAction.type,
        onError: workspaceActions.getAllTasks__FailureAction.type,
        method: "GET",
        url: `/task/get-all-task?workspaceId=${workspaceId}&componentId=${componentId}`,
        axiosRequired: true,
    });

// Create Sub Task
export const createSubTask__api = (
    componentId: string,
    payload: workspaceActionType.CreateSubTaskPayload
) =>
    createApiAction({
        onStart: workspaceActions.createSubTask__RequestAction.type,
        onSuccess: workspaceActions.createSubTask__SuccessAction.type,
        onError: workspaceActions.createSubTask__FailureAction.type,
        method: "POST",
        url: `/task/${componentId}/subtask`,
        data: payload,
        axiosRequired: true,
    });

// Get All Sub Tasks
export const getAllSubTasks__api = (
    taskID: string,
    page: number,
    limit: number,
    sort: string
) =>
    createApiAction({
        onStart: workspaceActions.getAllSubTasks__RequestAction.type,
        onSuccess: workspaceActions.getAllSubTasks__SuccessAction.type,
        onError: workspaceActions.getAllSubTasks__FailureAction.type,
        method: "GET",
        url: `/task/${taskID}/subtask?page=${page}&limit=${limit}&sort=${sort}`,
        axiosRequired: true,
    });

// Delete Bulk Tasks
export const deleteBulkTasks__api = (
    payload: workspaceActionType.DeleteBulkTasksPayload
) =>
    createApiAction({
        onStart: workspaceActions.deleteBulkTasks__RequestAction.type,
        onSuccess: workspaceActions.deleteBulkTasks__SuccessAction.type,
        onError: workspaceActions.deleteBulkTasks__FailureAction.type,
        method: "POST",
        url: `/task/delete-bulk`,
        data: payload,
        axiosRequired: true,
    });

/* Add Whiteboard */
export const addWhiteboard__api = (
    workspaceID: string,
    payload: workspaceActionType.AddWhiteboardRequest
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/whiteboard/${workspaceID}`,
        data: payload,
        onStart: workspaceActions.AddWhiteboard__RequestAction.type,
        onSuccess: workspaceActions.AddWhiteboard__SuccessAction.type,
        onError: workspaceActions.AddWhiteboard__FailureAction.type,
    });

/* Get All Whiteboards */
export const getAllWhiteboards__api = (payload: {
    workspaceId: string;
    page: number;
    limit: number;
    sort: string;
}) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/whiteboard/${payload?.workspaceId}?page=${payload?.page}&limit=${payload?.limit}&sort=${payload?.sort}`,
        onStart: workspaceActions.GetAllWhiteboards__RequestAction.type,
        onSuccess: workspaceActions.GetAllWhiteboards__SuccessAction.type,
        onError: workspaceActions.GetAllWhiteboards__FailureAction.type,
    });

/* Get One Whiteboard */
export const getOneWhiteboard__api = (
    workspaceID: string,
    whiteBoardID: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}`,
        onStart: workspaceActions.GetOneWhiteboard__RequestAction.type,
        onSuccess: workspaceActions.GetOneWhiteboard__SuccessAction.type,
        onError: workspaceActions.GetOneWhiteboard__FailureAction.type,
    });

/* Update Whiteboard */
export const updateWhiteboard__api = (
    workspaceID: string,
    whiteBoardID: string,
    payload: workspaceActionType.UpdateWhiteboardRequest
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}`,
        data: payload,
        onStart: workspaceActions.UpdateWhiteboard__RequestAction.type,
        onSuccess: workspaceActions.UpdateWhiteboard__SuccessAction.type,
        onError: workspaceActions.UpdateWhiteboard__FailureAction.type,
    });

/* Delete Whiteboard */
export const deleteWhiteboard__api = (
    workspaceID: string,
    whiteBoardID: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}`,
        onStart: workspaceActions.DeleteWhiteboard__RequestAction.type,
        onSuccess: workspaceActions.DeleteWhiteboard__SuccessAction.type,
        onError: workspaceActions.DeleteWhiteboard__FailureAction.type,
    });

/* Add Object */
export const addObject__api = (
    workspaceID: string,
    whiteBoardID: string,
    payload: workspaceActionType.AddObjectRequest
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/object`,
        data: payload,
        onStart: workspaceActions.AddObject__RequestAction.type,
        onSuccess: workspaceActions.AddObject__SuccessAction.type,
        onError: workspaceActions.AddObject__FailureAction.type,
    });

/* Update Object */
export const updateObject__api = (
    workspaceID: string,
    whiteBoardID: string,
    payload: workspaceActionType.UpdateObjectRequest
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/object`,
        data: payload,
        onStart: workspaceActions.UpdateObject__RequestAction.type,
        onSuccess: workspaceActions.UpdateObject__SuccessAction.type,
        onError: workspaceActions.UpdateObject__FailureAction.type,
    });

/* Delete Object */
export const deleteObject__api = (
    workspaceID: string,
    whiteBoardID: string,
    objectID: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/object/${objectID}`,
        onStart: workspaceActions.DeleteObject__RequestAction.type,
        onSuccess: workspaceActions.DeleteObject__SuccessAction.type,
        onError: workspaceActions.DeleteObject__FailureAction.type,
    });

/* Add Collaborator */
export const addCollaborator__api = (
    workspaceID: string,
    whiteBoardID: string,
    payload: workspaceActionType.AddCollaboratorRequest
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/collaborator`,
        data: payload,
        onStart: workspaceActions.AddCollaborator__RequestAction.type,
        onSuccess: workspaceActions.AddCollaborator__SuccessAction.type,
        onError: workspaceActions.AddCollaborator__FailureAction.type,
    });

/* Get All Collaborators */
export const getAllCollaborators__api = (
    workspaceID: string,
    whiteBoardID: string,
    page: number = 1,
    limit: number = 10,
    sort: string = "asc"
) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/collaborator?page=${page}&limit=${limit}&sort=${sort}`,
        onStart: workspaceActions.GetAllCollaborators__RequestAction.type,
        onSuccess: workspaceActions.GetAllCollaborators__SuccessAction.type,
        onError: workspaceActions.GetAllCollaborators__FailureAction.type,
    });

/* Update Collaborator */
export const updateCollaborator__api = (
    workspaceID: string,
    whiteBoardID: string,
    payload: workspaceActionType.UpdateCollaboratorRequest
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/collaborator`,
        data: payload,
        onStart: workspaceActions.UpdateCollaborator__RequestAction.type,
        onSuccess: workspaceActions.UpdateCollaborator__SuccessAction.type,
        onError: workspaceActions.UpdateCollaborator__FailureAction.type,
    });

/* Delete Collaborator */
export const deleteCollaborator__api = (
    workspaceID: string,
    whiteBoardID: string,
    memberID: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/collaborator/${memberID}`,
        onStart: workspaceActions.DeleteCollaborator__RequestAction.type,
        onSuccess: workspaceActions.DeleteCollaborator__SuccessAction.type,
        onError: workspaceActions.DeleteCollaborator__FailureAction.type,
    });

/* Create Version */
export const createVersion__api = (
    workspaceID: string,
    whiteBoardID: string,
    payload: workspaceActionType.CreateVersionRequest
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/version`,
        data: payload,
        onStart: workspaceActions.CreateVersion__RequestAction.type,
        onSuccess: workspaceActions.CreateVersion__SuccessAction.type,
        onError: workspaceActions.CreateVersion__FailureAction.type,
    });

/* Get All Versions */
export const getAllVersions__api = (
    workspaceID: string,
    whiteBoardID: string,
    page: number = 1,
    limit: number = 10,
    sort: string = "asc"
) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/version?page=${page}&limit=${limit}&sort=${sort}`,
        onStart: workspaceActions.GetAllVersions__RequestAction.type,
        onSuccess: workspaceActions.GetAllVersions__SuccessAction.type,
        onError: workspaceActions.GetAllVersions__FailureAction.type,
    });

/* Restore Version */
export const restoreVersion__api = (
    workspaceID: string,
    whiteBoardID: string,
    versionID: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/whiteboard/${workspaceID}/${whiteBoardID}/version/${versionID}/restore`,
        onStart: workspaceActions.RestoreVersion__RequestAction.type,
        onSuccess: workspaceActions.RestoreVersion__SuccessAction.type,
        onError: workspaceActions.RestoreVersion__FailureAction.type,
    });

/* Add Docs */
export const addDocs__api = (
    workspaceID: string,
    payload: workspaceActionType.AddDocPayload
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/document/${workspaceID}`,
        data: payload,
        onStart: workspaceActions.addDocs__RequestAction.type,
        onSuccess: workspaceActions.addDocs__SuccessAction.type,
        onError: workspaceActions.addDocs__FailureAction.type,
    });

/* Get All Docs */
export const getAllDocs__api = (payload: {
    folderId?: string;
    isStarred?: boolean;
    page: number;
    limit: number;
    sort: string;
    filter?: string;
    workspaceId: string;
}) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/document/${payload?.workspaceId}`,
        onStart: workspaceActions.getAllDocs__RequestAction.type,
        onSuccess: workspaceActions.getAllDocs__SuccessAction.type,
        onError: workspaceActions.getAllDocs__FailureAction.type,
    });

/* Get One Doc */
export const getOneDoc__api = (workspaceID: string, docID: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/document/${workspaceID}/${docID}`,
        onStart: workspaceActions.getOneDoc__RequestAction.type,
        onSuccess: workspaceActions.getOneDoc__SuccessAction.type,
        onError: workspaceActions.getOneDoc__FailureAction.type,
    });

/* Update One Doc */
export const updateOneDoc__api = (
    workspaceID: string,
    docID: string,
    payload: any
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/document/${workspaceID}/${docID}`,
        onStart: workspaceActions.updateOneDoc__RequestAction.type,
        onSuccess: workspaceActions.updateOneDoc__SuccessAction.type,
        onError: workspaceActions.updateOneDoc__FailureAction.type,
    });

/* Add Wiki */
export const addWiki__api = (
    payload: workspaceActionType.AddWiki,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/wiki/${workspaceId}`,
        onStart: workspaceActions.AddWiki__RequestAction.type,
        onSuccess: workspaceActions.AddWiki__SuccessAction.type,
        onError: workspaceActions.AddWiki__FailureAction.type,
    });

/* Get All Wikis */
export const getAllWikis__api = (workspaceId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/wiki/${workspaceId}`,
        onStart: workspaceActions.GetAllWikis__RequestAction.type,
        onSuccess: workspaceActions.GetAllWikis__SuccessAction.type,
        onError: workspaceActions.GetAllWikis__FailureAction.type,
    });

/* Get One Wiki */
export const getOneWiki__api = (workspaceId: string, wikiID: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/wiki/${workspaceId}/${wikiID}`,
        onStart: workspaceActions.GetOneWiki__RequestAction.type,
        onSuccess: workspaceActions.GetOneWiki__SuccessAction.type,
        onError: workspaceActions.GetOneWiki__FailureAction.type,
    });

/* Add Wiki Page */
export const addWikiPage__api = (
    wikiID: string,
    payload: workspaceActionType.AddWikiPage,
    workspaceId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/wiki/${workspaceId}/${wikiID}/page`,
        onStart: workspaceActions.AddWikiPage__RequestAction.type,
        onSuccess: workspaceActions.AddWikiPage__SuccessAction.type,
        onError: workspaceActions.AddWikiPage__FailureAction.type,
    });

/* Get Wiki Page */
export const getWikiPage__api = (
    workspaceID: string,
    wikiID: string,
    pageID: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/wiki/${workspaceID}/${wikiID}/page/${pageID}`,
        onStart: workspaceActions.GetWikiPage__RequestAction.type,
        onSuccess: workspaceActions.GetWikiPage__SuccessAction.type,
        onError: workspaceActions.GetWikiPage__FailureAction.type,
    });

/* Update Wiki Page */
export const updateWikiPage__api = (
    workspaceID: string,
    wikiID: string,
    pageID: string,
    payload: any
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/wiki/${workspaceID}/${wikiID}/page/${pageID}`,
        onStart: workspaceActions.UpdateWikiPage__RequestAction.type,
        onSuccess: workspaceActions.UpdateWikiPage__SuccessAction.type,
        onError: workspaceActions.UpdateWikiPage__FailureAction.type,
    });

/* confluence */

/* Create Page */
export const createPage__Confluence__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/confluence/wiki/rest/api/content`,
        onStart: workspaceActions.CreatePage__Confluence__RequestAction.type,
        onSuccess: workspaceActions.CreatePage__Confluence__SuccessAction.type,
        onError: workspaceActions.CreatePage__Confluence__FailureAction.type,
    });

/* Retrieve All Pages */
export const retrieveAllPages__Confluence__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/confluence/wiki/rest/api/content`,
        onStart: workspaceActions.RetrieveAllPage__Confluence__RequestAction.type,
        onSuccess: workspaceActions.RetrieveAllPage__Confluence__SuccessAction.type,
        onError: workspaceActions.RetrieveAllPage__Confluence__FailureAction.type,
    });

/* Update Page */
export const updatePage__Confluence__api = (pageId: string, payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/confluence/wiki/rest/api/content/${pageId}`,
        onStart: workspaceActions.UpdatePage__Confluence__RequestAction.type,
        onSuccess: workspaceActions.UpdatePage__Confluence__SuccessAction.type,
        onError: workspaceActions.UpdatePage__Confluence__FailureAction.type,
    });

/* Retrieve Page */
export const retrievePage__Confluence__api = (pageId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/confluence/wiki/rest/api/content/${pageId}`,
        onStart: workspaceActions.RetrievePage__Confluence__RequestAction.type,
        onSuccess: workspaceActions.RetrievePage__Confluence__SuccessAction.type,
        onError: workspaceActions.RetrievePage__Confluence__FailureAction.type,
    });

/* List Pages In Space */
export const listPagesInSpace__Confluence__api = (
    spaceKey: string,
    contentType?: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/confluence/wiki/rest/api/space/${spaceKey}/content/${contentType}`,
        onStart: workspaceActions.ListPagesInSpace__Confluence__RequestAction.type,
        onSuccess:
            workspaceActions.ListPagesInSpace__Confluence__SuccessAction.type,
        onError: workspaceActions.ListPagesInSpace__Confluence__FailureAction.type,
    });

/* Search Content */
export const searchContent__Confluence__api = (cql: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/confluence/wiki/rest/api/search/${cql}`,
        onStart: workspaceActions.SearchContent__Confluence__RequestAction.type,
        onSuccess: workspaceActions.SearchContent__Confluence__SuccessAction.type,
        onError: workspaceActions.SearchContent__Confluence__FailureAction.type,
    });

/* Delete Page */
export const deletePage__Confluence__api = (pageId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/confluence/wiki/rest/api/content/${pageId}`,
        onStart: workspaceActions.DeletePage__Confluence__RequestAction.type,
        onSuccess: workspaceActions.DeletePage__Confluence__SuccessAction.type,
        onError: workspaceActions.DeletePage__Confluence__FailureAction.type,
    });

/* Add Comment */
export const addComment__Confluence__api = (pageId: string, payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/confluence/wiki/rest/api/content/${pageId}/child/comment`,
        onStart: workspaceActions.AddComment__Confluence__RequestAction.type,
        onSuccess: workspaceActions.AddComment__Confluence__SuccessAction.type,
        onError: workspaceActions.AddComment__Confluence__FailureAction.type,
    });

/* Retrieve Comments */
export const retrieveComments__Confluence__api = (pageId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/confluence/wiki/rest/api/content/${pageId}/child/comment`,
        onStart: workspaceActions.RetrieveComments__Confluence__RequestAction.type,
        onSuccess:
            workspaceActions.RetrieveComments__Confluence__SuccessAction.type,
        onError: workspaceActions.RetrieveComments__Confluence__FailureAction.type,
    });

/* Like Content */
export const likeContent__Confluence__api = (contentId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/confluence/wiki/rest/experimental/content/${contentId}/like`,
        onStart: workspaceActions.LikeContent__Confluence__RequestAction.type,
        onSuccess: workspaceActions.LikeContent__Confluence__SuccessAction.type,
        onError: workspaceActions.LikeContent__Confluence__FailureAction.type,
    });

/* Retrieve Attachments */
export const retrieveAttachments__Confluence__api = (contentId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/confluence/wiki/rest/api/content/${contentId}/child/attachment`,
        onStart:
            workspaceActions.RetrieveAttachments__Confluence__RequestAction.type,
        onSuccess:
            workspaceActions.RetrieveAttachments__Confluence__SuccessAction.type,
        onError:
            workspaceActions.RetrieveAttachments__Confluence__FailureAction.type,
    });

/* Add Label to Content */
export const addLabelToContent__Confluence__api = (
    contentId: string,
    payload: any
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/confluence/wiki/rest/api/content/${contentId}/label`,
        onStart: workspaceActions.AddLabelToContent__Confluence__RequestAction.type,
        onSuccess:
            workspaceActions.AddLabelToContent__Confluence__SuccessAction.type,
        onError: workspaceActions.AddLabelToContent__Confluence__FailureAction.type,
    });

/* View Content Labels */
export const viewContentLabels__Confluence__api = (contentId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/confluence/wiki/rest/api/content/${contentId}/label`,
        onStart: workspaceActions.ViewContentLabels__Confluence__RequestAction.type,
        onSuccess:
            workspaceActions.ViewContentLabels__Confluence__SuccessAction.type,
        onError: workspaceActions.ViewContentLabels__Confluence__FailureAction.type,
    });

/* Delete Attachment */
export const deleteAttachment__Confluence__api = (
    contentId: string,
    attachmentId: string
) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/confluence/wiki/rest/api/content/${contentId}/child/attachment/${attachmentId}`,
        onStart: workspaceActions.DeleteAttachment__Confluence__RequestAction.type,
        onSuccess:
            workspaceActions.DeleteAttachment__Confluence__SuccessAction.type,
        onError: workspaceActions.DeleteAttachment__Confluence__FailureAction.type,
    });

/* Create Space */
export const createSpace__Confluence__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/confluence/wiki/rest/api/space`,
        onStart: workspaceActions.CreateSpace__Confluence__RequestAction.type,
        onSuccess: workspaceActions.CreateSpace__Confluence__SuccessAction.type,
        onError: workspaceActions.CreateSpace__Confluence__FailureAction.type,
    });

/* Get Space */
export const getSpace__Confluence__api = (spaceKey: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/confluence/wiki/rest/api/space/${spaceKey}`,
        onStart: workspaceActions.GetSpace__Confluence__RequestAction.type,
        onSuccess: workspaceActions.GetSpace__Confluence__SuccessAction.type,
        onError: workspaceActions.GetSpace__Confluence__FailureAction.type,
    });

/* List Spaces */
export const listSpaces__Confluence__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/confluence/wiki/rest/api/space`,
        onStart: workspaceActions.ListSpaces__Confluence__RequestAction.type,
        onSuccess: workspaceActions.ListSpaces__Confluence__SuccessAction.type,
        onError: workspaceActions.ListSpaces__Confluence__FailureAction.type,
    });

/* Delete Space */
export const deleteSpace__Confluence__api = (spaceKey: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/confluence/wiki/rest/api/space/${spaceKey}`,
        onStart: workspaceActions.DeleteSpace__Confluence__RequestAction.type,
        onSuccess: workspaceActions.DeleteSpace__Confluence__SuccessAction.type,
        onError: workspaceActions.DeleteSpace__Confluence__FailureAction.type,
    });

/* Update Space Permissions */
export const updateSpacePermissions__Confluence__api = (
    spaceKey: string,
    payload: any
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/confluence/wiki/rest/api/space/${spaceKey}/permissions`,
        onStart:
            workspaceActions.UpdateSpacePermissions__Confluence__RequestAction.type,
        onSuccess:
            workspaceActions.UpdateSpacePermissions__Confluence__SuccessAction.type,
        onError:
            workspaceActions.UpdateSpacePermissions__Confluence__FailureAction.type,
    });

export const confluence__oauth__handle = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/confluence/oauth?code=${payload}`,
        onStart: `confluence_oauth__RequestAction.type`,
        onSuccess: `confluence_oauth__SuccessAction.type`,
        onError: `confluence_oauth__FailureAction.type`,
    });
export const confluence__loginUrl__handle = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/confluence/getConfluenceLoginUrl`,
        onStart: `confluence_authUrl__RequestAction.type`,
        onSuccess: `confluence_authUrl__SuccessAction.type`,
        onError: `confluence_authUrl__FailureAction.type`,
    });

/* Jira ============== */

export const Jira__oauth__handle = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/jira/oauth-jira?code=${payload}`,
        onStart: `jira_oauth__RequestAction.type`,
        onSuccess: `jira_oauth__SuccessAction.type`,
        onError: `jira_oauth__FailureAction.type`,
    });
export const Jira__loginUrl__handle = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/jira/getJiraLoginUrl`,
        onStart: `jira_authUrl__RequestAction.type`,
        onSuccess: `jira_authUrl__SuccessAction.type`,
        onError: `jira_authUrl__FailureAction.type`,
    });

/* Create Project */
export const createProject__Jira__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: "/jira/project",
        onStart: `CreateProject__Jira__RequestAction.type`,
        onSuccess: workspaceActions.CreateProject__Jira__SuccessAction.type,
        onError: `CreateProject__Jira__FailureAction.type`,
    });

/* Get All Projects */
export const getAllProjects__Jira__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: "/jira/project",
        onStart: `GetAllProjects__Jira__RequestAction.type`,
        onSuccess: workspaceActions.GetAllProjects__Jira__SuccessAction.type,
        onError: `GetAllProjects__Jira__FailureAction.type`,
    });

/* Get Project Details */
export const getProjectDetails__Jira__api = (projectIdOrKey: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/jira/project/${projectIdOrKey}`,
        onStart: `GetProjectDetails__Jira__RequestAction.type`,
        onSuccess: workspaceActions.GetProjectDetails__Jira__SuccessAction.type,
        onError: `GetProjectDetails__Jira__FailureAction.type`,
    });

/* Update Project */
export const updateProject__Jira__api = (
    projectIdOrKey: string,
    payload: any
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/jira/project/${projectIdOrKey}`,
        onStart: `UpdateProject__Jira__RequestAction.type`,
        onSuccess: workspaceActions.UpdateProject__Jira__SuccessAction.type,
        onError: `UpdateProject__Jira__FailureAction.type`,
    });

/* Delete Project */
export const deleteProject__Jira__api = (projectIdOrKey: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/jira/project/${projectIdOrKey}`,
        onStart: `DeleteProject__Jira__RequestAction.type`,
        onSuccess: workspaceActions.DeleteProject__Jira__SuccessAction.type,
        onError: `DeleteProject__Jira__FailureAction.type`,
    });

/* Get All Boards */
export const getAllBoards__Jira__api = (projectId?: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/jira/get-all-board/${projectId || ""}`,
        onStart: `GetAllBoards__Jira__RequestAction.type`,
        onSuccess: workspaceActions.GetAllBoards__Jira__SuccessAction.type,
        onError: `GetAllBoards__Jira__FailureAction.type`,
    });

/* Get One Board */
export const getOneBoard__Jira__api = (boardId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/jira/get-one-board/${boardId}`,
        onStart: `GetOneBoard__Jira__RequestAction.type`,
        onSuccess: workspaceActions.GetOneBoard__Jira__SuccessAction.type,
        onError: `GetOneBoard__Jira__FailureAction.type`,
    });

/* Create Sprint */
export const createSprint__Jira__api = (payload: any, projectId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/jira/sprint/${projectId}`,
        onStart: `CreateSprint__Jira__RequestAction.type`,
        onSuccess: workspaceActions.CreateSprint__Jira__SuccessAction.type,
        onError: `CreateSprint__Jira__FailureAction.type`,
    });

/* Update Sprint */
export const updateSprint__Jira__api = (sprintId: string, payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/jira/sprint/${sprintId}`,
        onStart: `UpdateSprint__Jira__RequestAction.type`,
        onSuccess: workspaceActions.UpdateSprint__Jira__SuccessAction.type,
        onError: `UpdateSprint__Jira__FailureAction.type`,
    });

/* Delete Sprint */
export const deleteSprint__Jira__api = (sprintId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/jira/sprint/${sprintId}`,
        onStart: `DeleteSprint__Jira__RequestAction.type`,
        onSuccess: workspaceActions.DeleteSprint__Jira__SuccessAction.type,
        onError: `DeleteSprint__Jira__FailureAction.type`,
    });

/* Get All Sprints */
export const getAllSprints__Jira__api = (boardId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/jira/sprint/${boardId}`,
        onStart: `GetAllSprints__Jira__RequestAction.type`,
        onSuccess: workspaceActions.GetAllSprints__Jira__SuccessAction.type,
        onError: `GetAllSprints__Jira__FailureAction.type`,
    });

/* Update Issue Transition */
export const updateIssueTransition__Jira__api = (
    issueIdOrKey: string,
    payload: any
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/jira/sprint/update-issue-transition/${issueIdOrKey}`,
        onStart: `UpdateIssueTransition__Jira__RequestAction.type`,
        onSuccess: workspaceActions.UpdateIssueTransition__Jira__SuccessAction.type,
        onError: `UpdateIssueTransition__Jira__FailureAction.type`,
    });

/* Get Issues in Sprint */
export const getIssuesInSprint__Jira__api = (sprintId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/jira/sprint/get-issue/${sprintId}`,
        onStart: `workspaceActions.GetIssuesInSprint__Jira__RequestAction.type`,
        onSuccess: workspaceActions.GetIssuesInSprint__Jira__SuccessAction.type,
        onError: `GetIssuesInSprint__Jira__FailureAction.type`,
    });

/* Create Issue */
export const createIssue__Jira__api = (
    projectId: string,
    sprintId: string,
    payload: any
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/jira/sprint/issue/${projectId}/${sprintId}`,
        onStart: `CreateIssue__Jira__RequestAction.type`,
        onSuccess: workspaceActions.CreateIssue__Jira__SuccessAction.type,
        onError: `CreateIssue__Jira__FailureAction.type`,
    });

/* Add Comment */
export const addComment__Jira__api = (issueIdOrKey: string, payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/jira/issue/${issueIdOrKey}/comment`,
        onStart: `AddComment__Jira__RequestAction.type`,
        onSuccess: workspaceActions.AddComment__Jira__SuccessAction.type,
        onError: `AddComment__Jira__FailureAction.type`,
    });

/* Get Comments */
export const getComments__Jira__api = (issueIdOrKey: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/jira/issue/${issueIdOrKey}/comment`,
        onStart: `GetComments__Jira__RequestAction.type`,
        onSuccess: workspaceActions.GetComments__Jira__SuccessAction.type,
        onError: `GetComments__Jira__FailureAction.type`,
    });

/* Assign Issue */
export const assignIssue__Jira__api = (issueIdOrKey: string, payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/jira/issue/${issueIdOrKey}/assignee`,
        onStart: `AssignIssue__Jira__RequestAction.type`,
        onSuccess: workspaceActions.AssignIssue__Jira__SuccessAction.type,
        onError: `AssignIssue__Jira__FailureAction.type`,
    });

/* Search Issues */
export const searchIssues__Jira__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/jira/search?${new URLSearchParams(payload).toString()}`,
        onStart: `SearchIssues__Jira__RequestAction.type`,
        onSuccess: workspaceActions.SearchIssues__Jira__SuccessAction.type,
        onError: `SearchIssues__Jira__FailureAction.type`,
    });

/* Get User List */
export const getUserList__Jira__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: "/jira/user-list",
        onStart: `GetUserList__Jira__RequestAction.type`,
        onSuccess: workspaceActions.GetUserList__Jira__SuccessAction.type,
        onError: `GetUserList__Jira__FailureAction.type`,
    });
/* Get User List */
export const get__cmd__for__user__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/task/control-list-user?page=1&limit=100&sort=asc`,
        onStart: `workspaceActions.Get__cmd__for__user.type`,
        onSuccess: workspaceActions.Get__cmd__for__user__SuccessAction.type,
        onError: `Get__cmd__for__user.type`,
    });
/* Get User List */
export const get__cmd__for__admin__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/task/control-list-admin?page=1&limit=100&sort=asc`,
        onStart: `Get__cmd__for__admin.type`,
        onSuccess: workspaceActions.Get__cmd__for__admin__SuccessAction.type,
        onError: `Get__cmd__for__admin.type`,
    });

/* Get User List */
export const update__cmd__api = (taskID: any, payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/task/${taskID}/update-user-control`,
        onStart: `update.type`,
        onSuccess: ` update.type`,
        onError: `update.type`,
    });

/* Get all task List */
export const fetchAllWorkspaceTasks__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/task/get-all-workspace-tasks`,
        onStart: `fetchAllWorkspaceTasks__SuccessAction.type`,
        onSuccess: workspaceActions.fetchAllWorkspaceTasks__SuccessAction.type,
        onError: `workspaceActions.fetchAllWorkspaceTasks__FailureAction.type`,
    });
