import React from "react";
import {
  SmallThreeDotMenu,
  SmallThreeDotMenuListInterface,
} from "../../../../../../common";

interface ActionsAdminProps {
  onSelect: (value: string) => void;
}

const MENU_LISTS: SmallThreeDotMenuListInterface[] = [
  {
    id: 1,
    text: "Resend Invite",
    LANG: "Resend Invite",
  },
  {
    id: 2,
    text: "Show details",
    LANG: "Show details",
  },
];

const ActionsAdmin: React.FC<ActionsAdminProps> = ({ onSelect }) => {
  return (
    <SmallThreeDotMenu
      menuItems={MENU_LISTS}
      onClickAction={(value) => onSelect(value)}
    />
  );
};

export default ActionsAdmin;
