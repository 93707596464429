export type SmallGroupedAvatarDataTpyes = {
  id?: number | string;
  name?: string;
  _id?: string; // Changed from `id` to `_id` based on your data
  firstName?: string; // Optional, as it might not always be present
  lastName?: string;  // Optional, as it might not always be present
  email?: string; // Optional
  status?: string; // Optional
  avatar?: string; // Optional, as it might not always be present and can be a color or image URL
  companyId?: {
    _id: string;
    name: string;
    domain: string;
  };
  agreedToTerms?: boolean; // Optional
  tenentName?: string; // Optional
  imgSrc?: string; // For image URLs
  avatarColor?: string; // For color codes
};


export const DUMMY_AVATAR_USERS: SmallGroupedAvatarDataTpyes[] = [
  { id: 1, name: "John Doe", imgSrc: "https://randomuser.me/api/portraits/men/1.jpg", avatarColor: "#FF5733" },
  { id: 2, name: "Jane Smith", imgSrc: "https://randomuser.me/api/portraits/women/2.jpg", avatarColor: "#3357FF" },
  { id: 3, name: "Emily Johnson", imgSrc: "https://randomuser.me/api/portraits/women/3.jpg", avatarColor: "#33FF57" },
  { id: 4, name: "Michael Brown", imgSrc: "https://randomuser.me/api/portraits/men/4.jpg", avatarColor: "#F333FF" },
  { id: 5, name: "Sarah Davis", imgSrc: "https://randomuser.me/api/portraits/women/5.jpg", avatarColor: "#33FFF5" },
  { id: 6, name: "David Wilson", imgSrc: "https://randomuser.me/api/portraits/men/6.jpg", avatarColor: "#F5FF33" },
  { id: 7, name: "Jessica Miller", imgSrc: "https://randomuser.me/api/portraits/women/7.jpg", avatarColor: "#FF33A5" },
  { id: 8, name: "Daniel Anderson", imgSrc: "https://randomuser.me/api/portraits/men/8.jpg", avatarColor: "#A533FF" },
  { id: 9, name: "Laura Martinez", imgSrc: "https://randomuser.me/api/portraits/women/9.jpg", avatarColor: "#33FF85" },
  { id: 10, name: "James Taylor", imgSrc: "https://randomuser.me/api/portraits/men/10.jpg", avatarColor: "#FF8533" },
  { id: 11, name: "Patricia Thomas", imgSrc: "https://randomuser.me/api/portraits/women/11.jpg", avatarColor: "#3385FF" },
]