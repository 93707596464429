type TimeFormatOptions = {
  hour12?: boolean;
  locale?: string;
  timeZone?: string;
};

type DateFormatOptions = {
  locale?: string;
  useShortMonth?: boolean;
  timeZone?: string;
};

/**
 * Format a date object into a time string based on the provided options.
 * Silently handles any errors and provides a fallback value.
 * @param {Date} date - The date object to format.
 * @param {TimeFormatOptions} options - Formatting options.
 * @returns {string} Formatted time string or fallback value.
 */
export const formatTime = (
  date: Date,
  {
    hour12 = true,
    locale = "en-US",
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  }: TimeFormatOptions = {}
): string => {
  try {
    return date.toLocaleTimeString(locale, {
      hour: "2-digit",
      minute: "2-digit",
      hour12,
      timeZone,
    });
  } catch (error) {
    console.error("Error formatting time:", error);
    return "Invalid Time"; // Fallback value
  }
};

/**
 * Format a date object into a date string based on the provided options.
 * Silently handles any errors and provides a fallback value.
 * @param {Date} date - The date object to format.
 * @param {DateFormatOptions} options - Formatting options.
 * @returns {string} Formatted date string or fallback value.
 */
const formatDate = (
  date: Date,
  {
    locale = "en-US",
    useShortMonth = false,
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  }: DateFormatOptions = {}
): string => {
  try {
    return date.toLocaleDateString(locale, {
      year: "numeric",
      month: useShortMonth ? "short" : "long",
      day: "numeric",
      timeZone,
    });
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Invalid Date"; // Fallback value
  }
};

/**
 * Get current time in 12-hour and 24-hour formats.
 * Silently handles any errors and provides fallback values.
 * @param {string} [locale="en-US"] - Locale for formatting.
 * @param {string} [timeZone=Intl.DateTimeFormat().resolvedOptions().timeZone] - Timezone for formatting.
 * @returns {string[]} Array of formatted time strings or fallback values.
 */
export const getCurrentTimeFormats = (
  locale: string = "en-US",
  timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
): string[] => {
  try {
    const now = new Date();

    return [
      `12 Hours: ${formatTime(now, { hour12: true, locale, timeZone })}`,
      `24 Hours: ${formatTime(now, { hour12: false, locale, timeZone })}`,
    ];
  } catch (error) {
    console.error("Error getting current time formats:", error);
    return ["12 Hours: Invalid Time", "24 Hours: Invalid Time"]; // Fallback values
  }
};

/**
 * Get current date in various formats.
 * Silently handles any errors and provides fallback values.
 * @param {DateFormatOptions} [options] - Custom options for formatting.
 * @returns {string[]} Array of formatted date strings or fallback values.
 */
export const getCurrentDateFormats = (
  options: DateFormatOptions = {}
): string[] => {
  try {
    const now = new Date();
    const {
      locale = "en-US",
      useShortMonth = false,
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    } = options;

    return [
      formatDate(now, { locale, useShortMonth, timeZone }),
      formatDate(now, { locale: "en-GB", useShortMonth, timeZone }),
    ];
  } catch (error) {
    console.error("Error getting current date formats:", error);
    return ["Invalid Date", "Invalid Date"]; // Fallback values
  }
};

/**
 * Format a file size in bytes into a human-readable string.
 * Silently handles any errors and provides a fallback value.
 * @param {number} sizeInBytes - Size in bytes.
 * @returns {string} Formatted file size string or fallback value.
 */
export const formatFileSize = (sizeInBytes: number): string => {
  try {
    const units = ["B", "KB", "MB", "GB", "TB"];
    let size = sizeInBytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return `${size.toFixed(2)} ${units[unitIndex]}`;
  } catch (error) {
    console.error("Error formatting file size:", error);
    return "Invalid Size"; // Fallback value
  }
};

/**
 * Get the base URL for the backend API based on the environment variable.
 * Silently handles any errors and provides a fallback value.
 * @returns {string} Backend base URL or fallback value.
 */
export const getBaseUrl = (): string => {
  try {
    const env = process.env.REACT_APP_NODE;

    switch (env) {
      case "production":
        return process.env.REACT_APP_BACKEND_BASEURL_PRODUCTION || "";
      case "localDevelopment":
        return process.env.REACT_APP_BACKEND__LOCAL_BASEURL_DEVELOPMENT || "";
      case "development":
        return process.env.REACT_APP_BACKEND_BASEURL_DEVELOPMENT || "";
      default:
        return "";
    }
  } catch (error) {
    console.error("Error getting base URL:", error);
    return ""; // Fallback value
  }
};

// Define type for the variants
export type VariantKeys =
  | "@calls"
  | "@mention"
  | "@pending_events"
  | "@reminder_events"
  | "@flag"
  | "@chat"
  | "@recap"
  | "@timeoff"
  | "@expense"
  | "@payroll"
  | "@timesheet"
  | "@performance"
  | "@development"
  | "@compliance"
  | "@post_call"
  | "@upcoming_events"
  | "@ongoing_events"
  | "@past_events"
  | "@booking_links"
  | "@action_required"
  | "@read_mails"
  | "@unread_mails";

// Define the color mapping
export const variantColorMap: Record<VariantKeys, string> = {
  "@calls": "#2196F3",
  "@mention": "#FFC107",
  "@pending_events": "#FF5722",
  "@reminder_events": "#4CAF50",
  "@flag": "#F44336",
  "@chat": "#673AB7",
  "@recap": "#3F51B5",
  "@timeoff": "#009688",
  "@expense": "#8BC34A",
  "@payroll": "#CDDC39",
  "@timesheet": "#FF9800",
  "@performance": "#795548",
  "@development": "#607D8B",
  "@compliance": "#9E9E9E",
  "@post_call": "#8E44AD",
  "@upcoming_events": "#16A085",
  "@ongoing_events": "#16A085",
  "@past_events": "#8E44AD",
  "@booking_links": "#34495E",
  "@action_required": "#F44336",
  "@read_mails": "#F54336",
  "@unread_mails": "#F64336",
};
