import React, {useState} from "react";
import RecordingCallMenu from "../RecordingCallMenu/RecordingCallMenu";
import styles from "./ConferenceRoomHeader.module.css";
import {AvatarMedium, BgColorCode,} from "../../../../common";
import {CopyLinkIcon, GreenArrow, MeetingModeIcon, WorkSpaceModeIcon,} from "../../../../../images";
import {AppBar, Box, Button, Divider, MenuItem, Stack, Toolbar, Typography,} from "@mui/material";
import {AuthState,} from "../../../../../redux";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import UserProfile from "../../../../common/TopNavbar/UserProfile";
import {StyledMenu} from "../../../../common/TopNavbar/StyledMenubar";
import {RootState} from "../../../../../redux/store";
import {VideoCenterState} from "../../../../../redux/Slice/videoCenter/VideoCenterTypes";
import {clearAllStorage} from "../../../../../redux/middleware/apiMiddleware";
import {cookieDomain, ENABLE_WORKOS_MODE} from "../../../../../Utils";
import {a11yProps, StyledTab, TabsWithState} from "../../../../common/CardTabsWithoutIcon";

const ConferenceRoomHeader = ({handelSetWorkSpaceMode}: any) => {
    const {t} = useTranslation()
    let bgColorCode: BgColorCode = BgColorCode.Default; // Default color
    const {user} = useSelector((state: RootState) => state.auth as AuthState);
    const [value, setValue] = useState(0);
    const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(
        null
    );
    const [anchorEl, setAnchorEl] = useState<any>()
    const [guestAnchor, setGuestAnchor] = useState<any>()
    const navigate = useNavigate();

    const {onGoingMeeting} = useSelector(
        (state: RootState) => state.videoCenter as VideoCenterState
    );
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        handelSetWorkSpaceMode()
        setValue(newValue);
    };

    const handleCopyLink = () => {
        const url = window.location.href;
        navigator.clipboard.writeText(url).then(
            () => console.log("Message copied to clipboard!"),
            (err) => console.error("Failed to copy message:", err)
        );
    };

    const handleAvatarClick = (event: any) => {
        if (onGoingMeeting?.isNonBearishUser) {
            setGuestAnchor(event?.currentTarget)
        } else {
            setAnchorEl(event?.currentTarget);
        }
    };

    const handleClose = () => {
        if (onGoingMeeting?.isNonBearishUser) {
            setGuestAnchor(null);
        } else {
            setAnchorEl(null);
        }
    };

    // Function to open the support page
    const openSupportPage = () => {
        window.open("https://bearishos.freshdesk.com/support/home", "_blank");
    };

    // Function to open the legal page
    const openLegalPage = () => {
        window.open("https://www.bearishos.com/termofservice", "_blank");
    };

    // Function to handle logout and redirect to os.localtest.me with f=1 query param
    const handleLogout = () => {
        // Clear all cookies and localStorage
        clearAllStorage();

        if (ENABLE_WORKOS_MODE) {
            // Redirect to os.localtest.me with f=1 query parameter
            const redirectUrl = `http://os${
                cookieDomain === ".localtest.me" ? ".localtest.me:3000" : cookieDomain
            }/dashboard?f=1`; // Or use relative path as needed
            window.location.replace(redirectUrl);
        } else {
            window.location.replace("/login");
        }
    };

    const handleStatusClick = (event: React.MouseEvent<HTMLElement>) => {
        setStatusAnchorEl(event?.currentTarget);
    };

    return (
        <div className={styles.mainContainer}>
            <AppBar
                style={{height: "50px", borderBottom: "1px solid #E9EDF2", marginBottom: "15px"}}
                position="static"
                color="transparent"
                elevation={0}
            >
                <Toolbar
                    style={{minHeight: "50px"}}
                    sx={{justifyContent: "space-between"}}
                >

                    <div
                        style={{display: "flex", alignItems: "center", width: "45%"}}
                    >
                        <Stack
                            sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "20px",
                                font: "normal normal 400 13px/16px Source Serif Pro",
                            }}
                        >
                            <Button
                                style={{
                                    marginLeft: "20px",
                                    borderRadius: "5px",
                                    padding: "5px 8px",
                                    color: "black",
                                    gap: "5px",
                                    textDecoration: "none"
                                }}
                                onClick={handleCopyLink}
                            >
                                <CopyLinkIcon w={20}/>
                                <Typography
                                    className={styles.tab}
                                    sx={{
                                        font: "normal normal 400 16.29px/13px Source Serif Pro",

                                    }}
                                >
                                    {t('VIDEO_CENTER.MEETING.COPY_MEETING_LINK')}
                                </Typography>
                            </Button>
                            <TabsWithState
                                ariaLabel="teax center tabs"
                                value={value}
                                onChange={handleChange}
                                // tabsGap="25px"
                                tabIndicatorColor="#FF8181"
                                sx={{
                                    maxWidth: "max-content",
                                    borderBottom: "0px solid #E9EDF2",
                                    "& .css-1aquho2-MuiTabs-indicator": {
                                        bottom: "0px !important",
                                    },
                                }}
                            >
                                <StyledTab
                                    icon={<MeetingModeIcon w={20}/>}
                                    style={{
                                        font: "normal normal 400 13px/16px Source Serif Pro",
                                        padding: "5px 0px",
                                    }}
                                    // disableRipple
                                    label={t('VIDEO_CENTER.MEETING.MEETING_MODE')}
                                    {...a11yProps(0)}
                                />
                                <StyledTab
                                    label={t('VIDEO_CENTER.MEETING.WORKSPACE_MODE')}
                                    icon={<WorkSpaceModeIcon w={20}/>}
                                    style={{
                                        font: "normal normal 400 13px/16px Source Serif Pro",
                                        padding: "5px 0px",
                                    }}
                                    // disableRipple

                                    {...a11yProps(1)}
                                />
                            </TabsWithState>
                        </Stack>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "33%",
                            justifyContent: "flex-end",
                        }}
                    >
                        {!onGoingMeeting?.isNonBearishUser && <RecordingCallMenu/>}
                        <Divider
                            orientation="vertical"
                            flexItem
                            style={{marginLeft: "5px", marginRight: "20px"}}
                        />.
                        <AvatarMedium
                            showBadge={true}
                            imgSrc={user?.data?.avatar || onGoingMeeting?.userAccountDetails?.firstName}
                            userName={user?.data?.firstName?.[0] + user?.data?.lastName?.[0] || onGoingMeeting?.userAccountDetails?.firstName + " " + onGoingMeeting?.userAccountDetails?.lastName}
                            bgColorCode={bgColorCode}
                            onClick={handleAvatarClick}
                        />
                        <StyledMenu
                            anchorEl={guestAnchor}
                            open={Boolean(guestAnchor)}
                            onClose={handleClose}
                        >
                            <Box className={styles.topNavbarUserBox}>
                                <UserProfile
                                    fullName={
                                        user ? user?.data?.firstName + " " + user?.data?.lastName :
                                            onGoingMeeting?.userAccountDetails?.firstName + " " + onGoingMeeting?.userAccountDetails?.lastName
                                    }
                                    email={user?.data?.email || onGoingMeeting?.userAccountDetails?.email}
                                />
                            </Box>
                        </StyledMenu>
                        <StyledMenu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <Box className={styles.topNavbarUserBox}>
                                <UserProfile
                                    fullName={
                                        user?.data?.firstName + " " + user?.data?.lastName ||
                                        "user-name"
                                    }
                                    email={user?.data?.email || "user-email"}
                                />
                            </Box>
                            <Stack justifyContent="center" className={styles.topNavbarMenuItem}>
                                <Typography className={styles.topNavbarSubscriptionType}>
                                    Subscription Type:
                                    <Typography
                                        component="span"
                                        className={styles.topNavbarSubscriptionTypeValue}
                                    >
                                        Pro
                                    </Typography>
                                </Typography>
                            </Stack>
                            <MenuItem
                                className={styles.topNavbarMenuItem}
                                sx={{justifyContent: "space-between"}}
                                onClick={() => {
                                    openLegalPage();
                                }}
                            >
                                <Typography className={styles.topNavbarMenuItemText}>
                                    Bearish's Policies & Legal
                                </Typography>
                                <Box
                                    className={styles.topNavbarMenuItemBox}
                                    style={{backgroundImage: `url(${GreenArrow})`}}
                                />
                            </MenuItem>
                            <MenuItem
                                className={styles.topNavbarMenuItem}
                                sx={{justifyContent: "space-between"}}
                                onClick={handleStatusClick}
                            >
                                <Typography className={styles.topNavbarMenuItemText}>
                                    Status:
                                    <Typography
                                        component="span"
                                        className={styles.topNavbarStatusValue}
                                    >
                                        {user?.data?.status || "Online"}
                                    </Typography>
                                </Typography>
                                <Box
                                    className={styles.topNavbarMenuItemBox}
                                    style={{backgroundImage: `url(${GreenArrow})`}}
                                />
                            </MenuItem>
                            <MenuItem
                                onClick={() => navigate("/Settings")}
                                className={styles.topNavbarMenuItem}
                            >
                                <Typography className={styles.topNavbarMenuItemText}>
                                    Settings
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    openSupportPage();
                                }}
                                className={styles.topNavbarMenuItem}
                            >
                                <Typography className={styles.topNavbarMenuItemText}>
                                    Support
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={() => handleLogout()}
                                className={styles.topNavbarMenuItem}
                            >
                                <Typography className={styles.topNavbarMenuItemText}>
                                    Logout
                                </Typography>
                            </MenuItem>
                        </StyledMenu>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default ConferenceRoomHeader;
