import React from "react";
import {CallWaitingUserProps} from "../../InCallGuestMenuType";
import {CardScrollableContainerView, UserCardView} from "../../view";
import {responseToJoinMeeting} from "../../../../../../../redux";
import {
    removeGuestRequestAction,
    removeWaitingParticipantsAction
} from "../../../../../../../redux/Slice/videoCenter/VideoCenterSlice";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../../../redux/store";
import {VideoCenterState} from "../../../../../../../redux/Slice/videoCenter/VideoCenterTypes";

interface WaitingUserTabViewProps {
  waitingUserList?: CallWaitingUserProps[];
}

const WaitingUserTabView: React.FC<WaitingUserTabViewProps> = ({
  waitingUserList = [],
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { onGoingMeeting } = useSelector(
    (state: RootState) => state.videoCenter as VideoCenterState
  );
  const OnHandleAcceptGuest = async (GuestObj:any) => {
    try {
      dispatch(
        responseToJoinMeeting({
          meetingId: params.id,
          userId: GuestObj?.userId,
          chimeMeetingId: GuestObj.chimeMeetingId,
          meeting_id: onGoingMeeting?.meeting?._id,
          status: "accepted",
        })
      );
      await dispatch(removeGuestRequestAction(GuestObj?.userId));
        await dispatch(removeWaitingParticipantsAction(GuestObj?.userId));
    } catch (error) {
      console.error("Error accepting guest:", error);
    }
  };

    const OnHandleRejectGuest = async (GuestObj: any) => {
        try {
            dispatch(
                responseToJoinMeeting({
                    meetingId: params.id,
                    userId: GuestObj?.userId,
                    chimeMeetingId: GuestObj.chimeMeetingId,
                    meeting_id: onGoingMeeting?.meeting?._id,
                    status: "rejected",
                })
            );
            await dispatch(removeWaitingParticipantsAction(GuestObj?.userId));
            await dispatch(removeGuestRequestAction(GuestObj?.userId));
        } catch (error) {
            console.error("Error accepting guest:", error);
        }
    };
  
  return (
    <CardScrollableContainerView hideMoreButton={waitingUserList.length <= 5}>
      {waitingUserList?.map((GuestObj, index) => (
        <UserCardView
          cardType="waiting"
          onAcceptClick={() => OnHandleAcceptGuest(GuestObj)}
          onRejectClick={() => OnHandleRejectGuest(GuestObj)}
          darkBg={index % 2 === 0}
          key={index}
          userEmail={GuestObj?.userEmail}
          userName={GuestObj?.userName}
        />
      ))}
    </CardScrollableContainerView>
  );
};

export default WaitingUserTabView;
