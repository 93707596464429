import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Stack, Typography } from "@mui/material";
import { ButtonSmall } from "../Buttons";
import { ComputerPng, IphonePng, IpadPng } from "../../../images";
import styles from "./MediumTableAndButtons.module.css";
import {
  Column,
  ColumnId,
  DeviceType,
  MediumTableAndButtonsProps,
  TableRowType,
} from "./MediumTableAndButtonsTypes";
import { ButtonType, SmallButtonColor } from "../Buttons/AllButtonProps";

// dummy data for table
// const API_DATA: TableRowType[] = [
//   {
//     id: "abc_i1",
//     device: DeviceType.Ipad,
//     browser: "safari",
//     location: "San Francisco, California, USA",
//     ipAddress: "2510:1650:251h:313:g7j3:7627:2f50:1eh1",
//     date: "01-00-0000",
//     time: "00:00",
//   },
// ];

const buttonClickHandler = (dataId: TableRowType["id"]): void => {
  // console.log(`button clicked - id: ${dataId}`);
};

const iconSrcHandler = (device: DeviceType): string => {
  switch (device) {
    case DeviceType.Mac:
      return ComputerPng;
    case DeviceType.Ipad:
      return IpadPng;
    case DeviceType.Iphone:
      return IphonePng;
    default:
      return "";
  }
};

const MediumTableAndButtons: React.FC<MediumTableAndButtonsProps> = ({
  deviceData,
}) => {
  const COLUMNS_NAME: readonly Column[] = [
    { id: ColumnId.Device, label: "Device", minWidth: 115 },
    { id: ColumnId.Location, label: "Location", minWidth: 246 },
    { id: ColumnId.Time, label: "Time", minWidth: 157 },
    { id: ColumnId.Logout, label: "Logout", minWidth: 155 },
  ];
  const API_DATA = deviceData;
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer className={styles["table-container"]}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {COLUMNS_NAME.map((column) => (
                <TableCell
                  key={column.id}
                  className={`${styles["table-header-cell"]} ${styles["table-header-container"]}`}
                  style={{
                    minWidth: column.minWidth,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {API_DATA.map((item) => (
              <TableRow hover role="checkbox" key={item.id}>
                <TableCell className={styles["table-body-container"]}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={"10px"}
                    className={styles["device-info"]}
                  >
                    <Box
                      component={"img"}
                      src={iconSrcHandler(item.device)}
                      alt="icon"
                      className={styles["device-info__icon"]}
                    />
                    <Stack>
                      <Typography className={styles["device-info__text"]}>
                        {item.device}
                      </Typography>
                      <Typography className={styles["device-info__browser"]}>
                        {item.browser}
                      </Typography>
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell className={styles["table-body-container"]}>
                  <Stack className={styles["location-info"]}>
                    <Typography
                      className={styles["location-info__text"]}
                      component={"p"}
                    >
                      {item.location}
                    </Typography>
                    <Typography
                      className={styles["location-info__text"]}
                      component={"p"}
                    >
                      {item.ipAddress}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell className={styles["table-body-container"]}>
                  <Stack className={styles["date-time-info"]}>
                    <Typography className={styles["date-time-info__text"]}>
                      {item.date}
                    </Typography>
                    <Typography className={styles["date-time-info__text"]}>
                      {item.time}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell className={styles["table-body-container"]}>
                  <ButtonSmall
                    onClick={() => buttonClickHandler(item.id)}
                    colorVarient={SmallButtonColor.Orange}
                    label={"Logout"}
                    loaderLogin={false}
                    types={ButtonType.Button}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default MediumTableAndButtons;
