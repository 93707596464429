import { Stack, Typography } from "@mui/material";
import React from "react";
import { TeamMemberIcon } from "../../../../../../../images";

interface CardHeaderViewProps {
  children: React.ReactNode;
}

const CardHeaderView: React.FC<CardHeaderViewProps> = ({ children }) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <TeamMemberIcon />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          Assigned Employees
        </Typography>
      </Stack>

      <Stack>{children}</Stack>
    </Stack>
  );
};

export default CardHeaderView;
