import { Stack, Typography } from "@mui/material";
import React from "react";

interface IconContainerProps {
  icon: React.ElementType;
  iconSize?: number;
  title: string;
  children: React.ReactNode;
  titleMarginTop?: string;
}

const IconContainer: React.FC<IconContainerProps> = ({
  icon,
  iconSize = 15,
  title,
  children,
  titleMarginTop = "5px",
}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "start",
        justifyContent: "space-between",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          mt: titleMarginTop,
        }}
      >
        {React.createElement(icon, { w: iconSize, color: "black" })}
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          {title}
        </Typography>
      </Stack>

      {/* ------------------- */}

      {children}
    </Stack>
  );
};

export default IconContainer;
