import React from "react";
import {
  TextNormalDropdown,
  TextNormalDropdownOptionsType,
} from "../../../../common";
import { Link, Stack } from "@mui/material";

const skillsOptions: TextNormalDropdownOptionsType[] = [
  { _id: "1", title: "Bachelors Degree", value: "Bachelors Degree" },
  { _id: "2", title: "Masters Degree", value: "Masters Degree" },
  { _id: "3", title: "PhD", value: "PhD" },
];

interface DegreeDropdownProps {}

const DegreeDropdown: React.FC<DegreeDropdownProps> = ({}) => {
  return (
    <TextNormalDropdown
      placeholder="Select skills..."
      //   initialValue={skillsOptions[2]}
      options={skillsOptions}
      onSelect={(value) => console.log(value)}
      menuHeight="300px"
      additionalOption={
        <Stack
          sx={{
            font: "normal italic 300 12px/15px Source Serif Pro",
            color: "#0027FF",
            display: "block",
          }}
        >
          <Link
            underline="hover"
            sx={{
              cursor: "pointer",
              color: "inherit",
              display: "inline-block",
            }}
          >
            Add
          </Link>{" "}
          or{" "}
          <Link
            underline="hover"
            sx={{
              cursor: "pointer",
              color: "inherit",
              display: "inline-block",
            }}
          >
            edit
          </Link>{" "}
          skills
        </Stack>
      }
    />
  );
};

export default DegreeDropdown;
