// authService.ts

import {
    AccountCenter,
    AccountType,
    AuthState,
    ConnectedAccount,
    User,
} from "../redux";
import { RootState, store } from "../redux/store";
import { CalendarTypesEnum } from "./CalenderTypes";

/**
 * Retrieves the authentication state from the Redux store.
 * This function handles errors and ensures that the state and auth slice are properly accessed.
 *
 * @returns {RootState['auth'] | null} The auth state if accessible, otherwise null.
 */
const getAuthState = () => {
    try {
        const state: RootState = store.getState();
        if (!state || !state?.auth) {
            console.error("Auth state is not initialized");
            return null;
        }
        return state?.auth;
    } catch (error) {
        console.error("Error accessing auth state:", error);
        return null;
    }
};
/**
 * Retrieves the calendar state from the Redux store.
 * This function handles errors and ensures that the state and calendar slice are properly accessed.
 *
 * @returns {RootState['calendar'] | null} The calendar state if accessible, otherwise null.
 */
const getCalendarState = () => {
    try {
        const state: RootState = store.getState();
        if (!state || !state.calendar) {
            console.error("Calendar state is not initialized");
            return null;
        }
        return state.calendar;
    } catch (error) {
        console.error("Error accessing calendar state:", error);
        return null;
    }
};

/**
 * Checks if the user is authenticated.
 * This function relies on the getAuthState function to retrieve the current authentication state.
 *
 * @returns {boolean} True if the user is authenticated, false otherwise.
 */
export const isAuthenticated = (): boolean => {
    const authState = getAuthState();
    if (!authState) return false;
    return !!authState.isAuthenticated;
};

/**
 * Retrieves the user information from the authentication state.
 * This function ensures that the authentication state and user information are properly accessed and handled.
 *
 * @returns {User | null} The user information if available, otherwise null.
 */
export const getUser = (authState?: AuthState): User | null => {
    // If no authState is provided, use the default state (could be Redux store or any default value)
    const state = authState || getAuthState();

    if (!state || !state.user) return null;
    return state.user;
};

export const getUserList = (): any | null => {
    const authState = getAuthState();
    if (!authState || !authState?.usersList) return null;
    return authState?.usersList;
};

export const getDepartmentList = (): any => {
    const authState = getAuthState();
    if (!authState || !authState.departmentList) return [];
    return authState.departmentList;
};

export const getConnectedAccounts = (
    authState: AuthState, // Accept the current auth state as an argument
    center: AccountCenter | "ALL",
    accountType?: AccountType // Optional parameter for specific account type
): ConnectedAccount[] => {
    // Check if authState is valid
    if (!authState) {
        // console.warn("Authentication state is not available.");
        return []; // Return empty array if auth state is not available
    }

    // Check if connectedAccounts is an array
    if (!Array.isArray(authState.connectedAccounts)) {
        // console.warn(
        //     "Connected accounts are not available or not in the expected format."
        // );
        return []; // Return empty array if connectedAccounts is not an array
    }

    // If center is "ALL", return all connected accounts
    if (center === "ALL") {
        if (accountType) {
            // If accountType is provided, filter by account type
            const filteredAccounts = authState.connectedAccounts.filter(
                (acc: any) => acc.type === accountType
            );
            return filteredAccounts.length > 0 ? filteredAccounts : []; // Return the specific account type if found or empty array if not found
        }
        return authState.connectedAccounts; // Return all connected accounts
    }

    // Validate that the provided center is part of the AccountCenter enum
    if (!Object.values(AccountCenter).includes(center)) {
        // console.warn(
        //     `Invalid center provided: ${center}. Returning an empty array.`
        // );
        return []; // Return empty array if center is invalid
    }

    // Filter accounts based on the specified center
    const filteredAccounts = authState.connectedAccounts.filter(
        (account: any) => account.center === center
    );

    // If accountType is provided, filter further to find the specific account type
    if (accountType) {
        const typeFilteredAccounts = filteredAccounts.filter(
            (acc: any) => acc.type === accountType
        );
        return typeFilteredAccounts.length > 0 ? typeFilteredAccounts : []; // Return the specific account type if found or empty array if not found
    }

    // If no accounts found for the specified center
    if (filteredAccounts.length === 0) {
        // console.warn(`No connected accounts found for the center: ${center}.`);
    }

    return filteredAccounts; // Return the filtered accounts
};

/**
 * Retrieves the list of all calendars from the calendar state.
 *
 * @returns {Array} The list of all calendars if available, otherwise an empty array.
 */
export const getAllCalendarList = (transform: boolean = false): any[] => {
    const calendarState = getCalendarState();

    if (!calendarState || !Array.isArray(calendarState.allCalendarList)) {
        return [];
    }

    if (calendarState.allCalendarList.length === 0) {
        return [];
    }

    const validCalendars = calendarState.allCalendarList.filter(
        (calendar: any) => {
            if (!calendar._id || !calendar.name) {
                console.warn(
                    "Invalid calendar found with missing _id or name:",
                    calendar
                );
                return false;
            }
            return true;
        }
    );

    if (validCalendars.length === 0) {
        console.warn("No valid calendars found.");
        return [];
    }

    if (transform) {
        // If transform is true, modify the calendarType based on the provider
        return validCalendars.map((calendar: any) => {
            let calendarType = CalendarTypesEnum.BearishCalendar; // Default value

            if (calendar?.isExternal) {
                // If it's an external calendar, check the provider
                if (calendar?.externalCalendar?.provider === "Microsoft") {
                    calendarType = CalendarTypesEnum.OutlookCalendar;
                } else if (calendar?.externalCalendar?.provider === "Google") {
                    calendarType = CalendarTypesEnum.GoogleCalendar;
                } else if (calendar?.externalCalendar?.provider === "Teams") {
                    calendarType = CalendarTypesEnum.TeamsCalendar;
                } else if (calendar?.externalCalendar?.provider === "Jira") {
                    calendarType = CalendarTypesEnum.JiraCalendar;
                }
            }

            return {
                id: calendar?._id,
                calendarType, // Dynamically assigned calendarType
                calendarName: calendar?.name,
                _id: calendar?._id,
            };
        });
    }

    // If transform is false or not provided, return validCalendars as is
    return validCalendars;
};
