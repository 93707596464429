import { Stack, Typography } from "@mui/material";
import React from "react";
import {
  DatePickerMini,
  DatePickerWithIcon,
  TimeZoneCalendarDropdown,
} from "../../../../../../common";

interface StartAndEndTimeViewProps {}

const StartAndEndTimeView: React.FC<StartAndEndTimeViewProps> = ({}) => {
  return (
    <Stack
      sx={{
        height: "30px",
        flexShrink: 0,
        border: "1px solid #E9EDF2",
        borderRadius: "5px",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        boxSizing: "border-box",
        padding: "0px 5px",
        font: "normal normal 300 13px/16px Source Serif Pro",
      }}
    >
      <DatePickerWithIcon
        dateFormat="MM/DD/YY"
        hideIcon
        sx={{ font: "inherit", width: "55px" }}
      />

      <Typography sx={{ font: "inherit", color: "#0027FF", pr: "4px" }}>
        to
      </Typography>

      <DatePickerWithIcon
        dateFormat="MM/DD/YY"
        hideIcon
        sx={{ font: "inherit", width: "55px" }}
      />

      <TimeZoneCalendarDropdown showSortWords />
    </Stack>
  );
};

export default StartAndEndTimeView;
