import {Stack} from "@mui/material";
import React, {useState} from "react";
import {HighlightTabs, HighlightTabsType} from "../../common";
import {
    BebaAiMentionButton,
    CommandsButtonView,
    MemberViewButton,
} from "./view";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import {AuthState} from "../../../../../redux";

interface MentionMenuProps {
    onSelectionComplete: (user: string, command: string) => void;
}

const MentionMenu: React.FC<MentionMenuProps> = ({onSelectionComplete}) => {
    const {users} = useSelector((state: RootState) => state.auth as AuthState);
    const [selectedUser, setSelectedUser] = useState<string | null>(null);
    const [selectedCommand, setSelectedCommand] = useState<string | null>(null);

    const tabList: HighlightTabsType[] = [
        {tabindex: 0, translatedTitle: "Member", value: "member"},
        {tabindex: 1, translatedTitle: "Commands", value: "commands"},
    ];

    const [tabPageIndex, setTabPageIndex] = useState<number>(0);

    const handleUserSelect = (userName: any) => {
        setSelectedUser(userName);
        setTabPageIndex(1); // Move to the Commands tab
    };

    const handleCommandSelect = (command: any) => {
        setSelectedCommand(command);
        if (selectedUser) {
            onSelectionComplete(selectedUser, command);
        }
    };

    const tabPages = (pageIndex: number) => {
        switch (pageIndex) {
            case 0:
                return (
                    <>
                        {users && Object.keys(users).map((key: any) => (
                            <MemberViewButton userName={users[key]?.firstName} onButtonClick={() => handleUserSelect(users[key]?.firstName)}/>
                        ))}
                        <BebaAiMentionButton/>
                    </>
                );

            case 1:
                return (
                    <>
                        <CommandsButtonView commandType="task" onSelect={(value: string) => handleCommandSelect("task")}/>
                        <CommandsButtonView commandType="event" onSelect={(value: string) => handleCommandSelect("event")}/>
                        <CommandsButtonView commandType="call" onSelect={(value: string) => handleCommandSelect("call")}/>
                        <CommandsButtonView commandType="expense" onSelect={(value: string) => handleCommandSelect("expense")}/>
                        <CommandsButtonView commandType="team" onSelect={(value: string) => handleCommandSelect("team")}/>
                    </>
                );
        }
    };

  return (
    <Stack
      sx={{
        width: "185px",
        height: "205px",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
        bgcolor: "white",
        padding: "8px 5px 5px 10px",
        boxSizing: "border-box",
        overflow: "hidden",
        gap: "10px",
        flexShrink: 0,
        border: "0.5px solid #E9EDF2",
      }}
    >
      <HighlightTabs
        onChange={(val) => setTabPageIndex(val.tabindex)}
        options={tabList}
      />

            <Stack sx={{overflow: "auto", scrollbarWidth: "none", gap: "6px"}}>
                {tabPages(tabPageIndex)}
            </Stack>
        </Stack>
    );
};

export default MentionMenu;