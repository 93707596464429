import { Stack, Typography } from "@mui/material";
import React from "react";
import { EventIconButton, TextLinkButton } from "../../../../../../common";
import { AllDirArrowCircleIcon } from "../../../../../../../images";

interface HeaderViewProps {
  onCloseClick: () => void;
  onViewEmployeeCardClick: () => void;
}

const HeaderView: React.FC<HeaderViewProps> = ({
  onViewEmployeeCardClick,
  onCloseClick,
}) => {
  return (
    <Stack
      sx={{
        height: "55px",
        padding: "0px 15px",
        borderBottom: "1px solid #E9EDF2",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        flexShrink: 0,
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "15px" }}>
        <EventIconButton
          buttonTitle="Close"
          icon={AllDirArrowCircleIcon}
          iconColor="black"
          iconDirection="left"
          onClick={onCloseClick}
        />
        <Typography
          sx={{ font: "normal normal 900 12px/15px Source Serif Pro" }}
        >
          Time off Request
        </Typography>
      </Stack>

      <TextLinkButton
        buttonLabel="View employee card"
        onClick={onViewEmployeeCardClick}
      />
    </Stack>
  );
};

export default HeaderView;
