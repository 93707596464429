import React from "react";

type ArchiveViewerProps = {
  path: string;
};

const ArchiveViewer: React.FC<ArchiveViewerProps> = ({ path }) => {
  return (
    <div>
      <h3>Archive File</h3>
      <p>This is an archive file. You can download it:</p>
      <a href={path} download>
        Download {path}
      </a>
    </div>
  );
};

export default ArchiveViewer;
