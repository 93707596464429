import {IconButton, InputBase, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {BearishIcon, SnippetIcon, ZoomViewIcon,} from "../../../../../images";
import {ButtonSmall, ButtonType, EmojiPickerSmall, SmallButtonColor, StyleFormat,} from "../../../../common";
import {CoverView} from "./view";
import CustomSimpleFroalaEditor from "../../../../common/CustomFroalaEditor/CustomFroalaEditor";
import {useDispatch} from "react-redux";
import {createOrUpdateNote, getAllMeetingNotes} from "../../../../../redux";
import {useTranslation} from "react-i18next";

interface PrePostMeetingNotesProps {
    accessDenied?: boolean;
    meetingId: string;
    isInstantMeeting?: boolean;
}

const PrePostMeetingNotes: React.FC<PrePostMeetingNotesProps> = ({
                                                                     accessDenied = false,
                                                                     meetingId,
                                                                     isInstantMeeting = false
                                                                 }) => {
    const {t} = useTranslation()
    const [isScheduleMeetingView, setIsScheduleMeetingView] = useState<boolean>(true);
    const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState<boolean>(true);
    const [editorData, setEditorData] = useState<{ content: string; title: string }>({
        content: "",
        title: ""
    });
    const [allNotes, setAllNotes] = useState<{ preMeeting: any; inMeeting: any }>({
        preMeeting: {content: "", title: ""},
        inMeeting: {content: "", title: ""}
    });
    const dispatch = useDispatch();

    const handleInputChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditorData({...editorData, content: event?.target?.value});
    };

    const HandleNotesDataGet = async () => {
        const res: any = await dispatch(getAllMeetingNotes(meetingId));
        const notesData = res?.data || [];
        const preMeetingNote = notesData.find((note: any) => note.isPreMeeting) || {};
        const inMeetingNote = notesData.find((note: any) => !note.isPreMeeting) || {};
        setAllNotes({
            preMeeting: {content: preMeetingNote?.content, title: preMeetingNote?.title},
            inMeeting: {content: inMeetingNote?.content, title: inMeetingNote?.title}
        });
        setEditorData(isScheduleMeetingView ? preMeetingNote : inMeetingNote);
    }

    const handleSaveNotes = async () => {
        const payload = {
            content: editorData?.content || "",
            title: editorData?.title,
            isPreMeeting: false,
        };
        await dispatch(createOrUpdateNote(meetingId, payload));
        HandleNotesDataGet()
    };

    const handleEmojiSelect = (emoji: { native: string }) => {
        setEditorData((prevMessage) => ({
            ...prevMessage,
            content: prevMessage.content + emoji.native
        }));
    };

    useEffect(() => {
        const fetchMeetingNotes = async () => {
            try {
                HandleNotesDataGet()
            } catch (error) {
                console.error("Error fetching meeting notes:", error);
            }
        };

        if (meetingId) {
            fetchMeetingNotes();
        }
    }, [meetingId, dispatch]);

    useEffect(() => {
        const note = isScheduleMeetingView ? allNotes.preMeeting : allNotes.inMeeting;
        setEditorData({content: note.content, title: note.title});
        setIsSaveBtnDisabled(note.content === editorData.content && note.title === editorData.title && !isScheduleMeetingView);
    }, [isScheduleMeetingView, allNotes]);

    return (
        <Stack sx={{
            flex: 1,
            height: "250px",
            bgcolor: "white",
            borderRadius: "5px",
            border: "1px solid #E9EDF2",
            boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.16)",
            position: "relative"
        }}>
            {accessDenied && <CoverView/>}
            <Stack sx={{
                padding: "0px 10px",
                boxSizing: "border-box",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                height: "40px",
                borderBottom: "1px solid #E9EDF2"
            }}>
                <InputBase
                    value={editorData?.title || ""}
                    sx={{font: "normal normal 400 13px/16px Inter"}}
                    onChange={(e) => setEditorData({...editorData, title: e.target.value})}
                    placeholder={t("VIDEO_CENTER.MEETING.NOTES")}
                />
                <Stack sx={{flexDirection: "row", alignItems: "center", gap: "10px"}}>
                    <Stack sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "20px",
                        font: "normal normal 400 13px/16px Source Serif Pro"
                    }}>
                        {!isInstantMeeting && (
                            <Stack sx={{font: "inherit", cursor: "pointer"}} onClick={() => setIsScheduleMeetingView(false)}>
                            <Typography sx={{font: "inherit", padding: "0px 2px"}}>Pre-Meeting</Typography>
                            <Stack sx={{
                                height: "2px",
                                bgcolor: !isScheduleMeetingView ? "#FF8181" : "inherit",
                                borderRadius: "10px",
                                width: "100%"
                            }}/>
                            </Stack>)}
                        <Stack sx={{font: "inherit", cursor: "pointer"}} onClick={() => setIsScheduleMeetingView(true)}>
                            <Typography sx={{
                                font: "inherit",
                                padding: "0px 2px"
                            }}>{t("VIDEO_CENTER.MEETING.MEETING_NOTES")}</Typography>
                            <Stack sx={{
                                height: "2px",
                                bgcolor: isScheduleMeetingView ? "#FF8181" : "inherit",
                                borderRadius: "10px",
                                width: "100%"
                            }}/>
                        </Stack>
                    </Stack>
                    <IconButton sx={{p: "4px"}}>
                        <ZoomViewIcon w={17}/>
                    </IconButton>
                </Stack>
            </Stack>

            <Stack sx={{flex: 1,padding:"8px"}}>
                <CustomSimpleFroalaEditor handleInputChanges={handleInputChanges} value={editorData?.content}/>
            </Stack>

            <Stack sx={{
                padding: "0px 10px",
                boxSizing: "border-box",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                height: "54px",
                borderTop: "1px solid #E9EDF2"
            }}>
                <Stack sx={{flexDirection: "row", alignItems: "center", gap: "20px"}}>
                    <StyleFormat w={20}/>
                    <IconButton sx={{
                        p: 0,
                        borderRadius: "5px",
                        flexDirection: "column",
                        justifyContent: "center",
                        color: "black"
                    }} disableRipple>
                        <SnippetIcon w={20}/>
                        <Typography
                            sx={{font: "normal normal 300 11px/14px Source Serif Pro"}}>{t("VIDEO_CENTER.MEETING.STYLE")}</Typography>
                    </IconButton>
                    <EmojiPickerSmall onSelect={handleEmojiSelect} w={16} labeltext={t("VIDEO_CENTER.MEETING.EMOJI")}
                                      sx={{flexDirection: "column", gap: "4px"}}/>
                    <IconButton sx={{
                        p: 0,
                        borderRadius: "5px",
                        flexDirection: "column",
                        justifyContent: "center",
                        color: "black",
                        gap: "2px"
                    }} disableRipple>
                        <BearishIcon height={20} width={20} color="#e2875e"/>
                        <Typography
                            sx={{font: "normal normal 300 11px/14px Source Serif Pro"}}>{t("VIDEO_CENTER.MEETING.BEBA_AI")}</Typography>
                    </IconButton>
                </Stack>

                <ButtonSmall label={t("VIDEO_CENTER.MEETING.SAVE")} types={ButtonType.Button}
                             colorVarient={SmallButtonColor.Black} onClick={handleSaveNotes} disabled={isSaveBtnDisabled}/>
            </Stack>
        </Stack>
    );
};

export default PrePostMeetingNotes;