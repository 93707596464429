/**
 * This file contains action creators for authentication related actions.
 */

import { createApiAction } from "../constantApi_config";
import * as authSlice from "./authSlice";
import * as authTypes from "./authTypes";


/**
 * Interface for defining the payload structure required for API requests.
 */

/**
 * Action creator for logging in a user.
 * @param userData - The login payload containing user credentials.
 * @returns Action object with type and payload.
 */
export const loginUser = (userData: authTypes.LoginPayload) =>
  createApiAction({
    onStart: authSlice.loginRequestAction.type,
    onSuccess: authSlice.loginSuccessAction.type,
    onError: authSlice.loginFailureAction.type,
    method: "POST",
    url: `/user/auth/login`,
    data: userData,
    headers: {
      language: "en",
    },
    axiosRequired: true,
  });

export const reLoginUserDetails = (payload: string) =>
  createApiAction({
    onStart: authSlice.loginRequestAction.type,
    onSuccess: authSlice.loginSuccessAction.type,
    onError: authSlice.loginFailureAction.type,
    method: "GET",
    url: `/user/auth/get-user-details/${payload}`,
    headers: {
      language: "en",
    },
    axiosRequired: true,
  });

/**
 * Action creator for signing up a new user.
 * @param userData - The signup payload containing user details.
 * @returns Action object with type and payload.
 */
export const signupUser = (userData: authTypes.SignUpPayload | FormData) =>
  createApiAction({
    onStart: authSlice.signUpRequestAction.type,
    onSuccess: authSlice.signUpSuccessAction.type,
    onError: authSlice.signUpFailureAction.type,
    method: "POST",
    url: `/user/auth/sign-up`,
    data: userData,
    headers: {
      "Content-Type": "multipart/form-data",
      language: "en",
    },
    axiosRequired: true,
  });

/**
 * Action creator for sending OTP to a user via email.
 * @param eOptUser - The payload containing email and other details for OTP sending.
 * @returns Action object with type and payload.
 */

export const sendOptToUser = (eOptUser: authTypes.sendOptPayload) =>
  createApiAction({
    onStart: authSlice.optSendRequestAction.type,
    onSuccess: authSlice.optSendSuccessAction.type,
    onError: authSlice.optSendFailureAction.type,
    method: "POST",
    url: `/user/send-otp-email`,
    data: eOptUser,
    axiosRequired: true,
  });

/**
 * Action creator for checking the availability of a company.
 * @param caCompany - The payload containing the company domain for availability check.
 * @returns Action object with type and payload.
 */
export const companyRegistryCheck = (caCompany: authTypes.companyAvailibityPayload) =>

  createApiAction({
    onStart: authSlice.companyAvailibityRequestAction.type,
    onSuccess: authSlice.companyAvailibitySuccessAction.type,
    onError: authSlice.companyAvailibityFailureAction.type,
    method: "GET",
    url: `/company/check-company-availability?domain=${caCompany?.domain}`,
    axiosRequired: true,
  });

/**
 * Action creator for fetching a list of companies.
 * @param caCompany - The payload containing the company name for fetching the list.
 * @returns Action object with type and payload.
 */
export const fetchCompanyLists = (caCompany: authTypes.companyAvailibityPayload) =>
  createApiAction({
    onStart: authSlice.fetchCompanyListRequestAction.type,
    onSuccess: authSlice.fetchCompanyListSuccessAction.type,
    onError: authSlice.fetchCompanyListFailureAction.type,
    method: "GET",
    url: `/company/fetch-company-list?companyName=${caCompany?.companyName}`,
    axiosRequired: true,
  });

/**
 * Action creator for fetching all departments.
 * @returns Action object with type and payload.
 */
export const getAllDepartment = (email?: any) =>
  createApiAction({
    onStart: authSlice.getAllDepartmentsRequestAction.type,
    onSuccess: authSlice.getAllDepartmentsSuccessAction.type,
    onError: authSlice.getAllDepartmentsFailureAction.type,
    method: "GET",
    url: `/department/get-all-department?email=${email?.email}`,
    axiosRequired: true,
  });

/**
 * Action creator for verifying the OTP sent to a user.
 * @param vUser - The payload containing OTP and user details for verification.
 * @returns Action object with type and payload.
 */
export const verifyOptOfUser = (vUser: authTypes.verifyOptPayload) =>
  createApiAction({
    onStart: authSlice.optVerificationRequestAction.type,
    onSuccess: authSlice.optVerificationSuccessAction.type,
    onError: authSlice.optVerificationFailureAction.type,
    method: "POST",
    url: `/user/verify-otp-email`,
    data: vUser,
    axiosRequired: true,
  });

/**
 * Action creator for handling external authentication options.
 * @param eUserData - The payload containing external auth data and option type.
 * @returns Action object with type and payload.
 */
export const externalEntitiesOptions = (eUserData: authTypes.ExternalAuthType) =>
  createApiAction({
    onStart:
      eUserData.optionAuth === "GoogleOAuth"
        ? authSlice.googleAuthRequestAction.type
        : authSlice.microsoftAuthRequestAction.type,
    onSuccess:
      eUserData.optionAuth === "GoogleOAuth"
        ? authSlice.googleAuthSuccessAction.type
        : authSlice.microsoftAuthSuccessAction.type,
    onError:
      eUserData.optionAuth === "GoogleOAuth"
        ? authSlice.googleAuthFailureAction.type
        : authSlice.microsoftAuthFailureAction.type,
    method: "POST",
    url: `/user/auth/sign-up?code=${eUserData.optionAuth}&urlcode=1`,
    data: eUserData,
    axiosRequired: true,
  });

export const externalEntitiesLoginOptions = (eUserData: authTypes.ExternalAuthType) =>
  createApiAction({
    onStart:
      eUserData.optionAuth === "GoogleAuth"
        ? authSlice.googleAuthRequestAction.type
        : authSlice.microsoftAuthRequestAction.type,
    onSuccess:
      eUserData.optionAuth === "GoogleAuth"
        ? authSlice.googleAuthSuccessAction.type
        : authSlice.microsoftAuthSuccessAction.type,
    onError:
      eUserData.optionAuth === "GoogleAuth"
        ? authSlice.googleAuthFailureAction.type
        : authSlice.microsoftAuthFailureAction.type,
    method: "POST",
    url: `/user/auth/login?code=${eUserData.optionAuth}&urlcode=1`,
    data: eUserData,
    axiosRequired: true,
  });

export const externalEntitiesOAuthCallback = (code: string, slug: string) =>
  createApiAction({
    onStart: authSlice.oAuthCallBackRequestAction.type,
    onSuccess: authSlice.oAuthCallBackSuccessAction.type,
    onError: authSlice.oAuthCallBackFailureAction.type,
    method: "GET",
    url: `/user/auth/workOSclient?code=${code}&flow=${slug}`,
    axiosRequired: true,
  });

export const externalEntitiesOAuthCallbackAfterLogin = (code: string) =>
  createApiAction({
    onStart: authSlice.oAuthCallBackRequestAction.type,
    onSuccess: authSlice.oAuthCallBackSuccessAction.type,
    onError: authSlice.oAuthCallBackFailureAction.type,
    method: "GET",
    url: `/user/auth/workOSClientLogin?code=${code}`,
    axiosRequired: true,
  });

/**
 * Action creator for fetching all users.
 * @returns Action object with type and payload.
 */

export const getAllUsers = (externalUser?:boolean) =>
  createApiAction({
    onStart: authSlice.getAllUsersRequestAction.type,
    onSuccess: authSlice.getAllUsersSuccessAction.type,
    onError: authSlice.getAllUsersFailureAction.type,
    method: "GET",
    url: `/user/?limit=100&page=1&sort=desc&externalUser=${externalUser ? true : false}`,
    axiosRequired: true,
  });

export const getAllExistingUsers = () =>
  createApiAction({
    onStart: authSlice.getAllUsersRequestAction.type,
    onSuccess: authSlice.getAllUsersSuccessAction.type,
    onError: authSlice.getAllUsersFailureAction.type,
    method: "GET",
    url: `/user/users`,
    axiosRequired: true,
  });

export const createGuestUsers = (data: any) =>
  createApiAction({
    onStart: authSlice.createNewGuestRequestAction.type,
    onSuccess: authSlice.createNewGuestSuccessAction.type,
    onError: authSlice.createNewGuestFailureAction.type,
    method: "POST",
    data,
    url: `/user/create-guest-user`,
    axiosRequired: true,
  });

export const createAdminPortalSession__api = (userId: string) =>
  createApiAction({
    onStart: "createAdminPortalSessionRequestAction.type",
    onSuccess: "createAdminPortalSessionSuccessAction.type",
    onError: "createAdminPortalSessionFailureAction.type",
    method: "GET",
    url: `/user/auth/generate-workos-link/${userId}`,
    axiosRequired: true,
  });

export const update2FactorAuthentication__api = (payload: any) =>
  createApiAction({
    onStart: "update2FactorAuthenticationRequestAction.type",
    onSuccess: "update2FactorAuthenticationSuccessAction.type",
    onError: "update2FactorAuthenticationFailureAction.type",
    data: payload,
    method: "PUT",
    url: `/user/auth/two-factor-auth`,
    axiosRequired: true,
  });
export const updateMfaBackupCodes__api = (payload: any) =>
  createApiAction({
    onStart: "updateMfaBackupCodesRequestAction.type",
    onSuccess: "updateMfaBackupCodesSuccessAction.type",
    onError: "updateMfaBackupCodesFailureAction.type",
    method: "PUT",
    data: payload,
    url: `/user/auth/mfa-backup-codes`,
    axiosRequired: true,
  });
export const updateIpRestrictions__api = (payload: any) =>
  createApiAction({
    onStart: "updateIpRestrictionsRequestAction.type",
    onSuccess: "updateIpRestrictionsSuccessAction.type",
    onError: "updateIpRestrictionsFailureAction.type",
    method: "PUT",
    data: payload,
    url: `/user/auth/ip-restrictions`,
    axiosRequired: true,
  });

export const updateWalkthroughStats__api = () =>
  createApiAction({
    onStart: "updateWalkthroughStatsRequestAction.type",
    onSuccess: authSlice.updateWalkthroughStatsSuccessAction.type,
    onError: "updateWalkthroughStatsFailureAction.type",
    method: "PUT",
    url: `/user/auth/walk-through-status?walkthroughCompleted=true`,
    axiosRequired: true,
  });

export const updateUserProfile__api = (payload: any) =>
  createApiAction({
    onStart: authSlice.updateUserProfileRequestAction.type,
    onSuccess: authSlice.updateUserProfileSuccessAction.type,
    onError: authSlice.updateUserProfileFailureAction.type,
    method: "PUT",
    data: payload,
    url: `/user/auth/update-profile`,
    axiosRequired: true,
  });

export const changeUserStatus__api = (payload: any) =>
  createApiAction({
    onStart: authSlice.changeUserPresenceStatusRequestAction.type,
    onSuccess: authSlice.changeUserPresenceStatusSuccessAction.type,
    onError: authSlice.changeUserPresenceStatusFailureAction.type,
    method: "PUT",
    data: payload,
    url: `/user/auth/update-status`,
    axiosRequired: true, // Explicitly setting axiosRequired
  });


export const updateUserAndOrg__api = (payload: any, userId: any) =>
  createApiAction({
    onStart: `updateUserAndOrg__api.type`,
    onSuccess: authSlice.oAuthCallBackSuccessAction.type,
    onError: `updateUserAndOrg__api.type`,
    method: "PUT",
    data: payload,
    url: `/user/auth/update-user-and-org/${userId}`,
    axiosRequired: true, // Explicitly setting axiosRequired
  });
