import React from "react";
import {
  Avatar,
  AvatarGroup,
  AvatarGroupProps,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./SmallGroupedAvatars.module.css";
import { SmallGroupedAvatarDataTpyes } from "./SmallGroupedAvaterDataTypes";
import { avatarInitialLetters } from "../../../Utils";

interface SmallGroupedAvatarsProps extends AvatarGroupProps {
  maxAvatars?: number;
  usersData: SmallGroupedAvatarDataTpyes[];
}

const SmallGroupedAvatars: React.FC<SmallGroupedAvatarsProps> = ({
  maxAvatars = 4,
  usersData,
}) => {
  const colorList: string[] = [
    "#1B2631",
    "#212F3D",
    "#2C3E50",
    "#17202A",
    "#4A235A",
    "#512E5F",
    "#154360",
    "#1F618D",
    "#0E6251",
    "#117A65",
    "#145A32",
    "#7E5109",
    "#784212",
    "#6E2C00",
    "#4D5656",
    "#424949",
    "#616A6B",
    "#283747",
    "#1B4F72",
    "#34495E",
  ];

  const TOTAL_USERS: number = usersData.length;

  return (
    <AvatarGroup
      max={maxAvatars}
      total={TOTAL_USERS}
      renderSurplus={(surplus) => (
        <span className={styles["smallgroupedavatars__surplus"]}>
          +{surplus?.toString()}
        </span>
      )}
      slotProps={{
        additionalAvatar: {
          className: styles["smallgroupedavatars__additional-avatar"],
        },
      }}
    >
      {Array.from({ length: Math.min(maxAvatars, TOTAL_USERS) }).map(
        (_, index) => {
          const user = usersData[index];
          // Safeguard against undefined user?.name
          // const nameLetters =
          //   user?.firstName && user?.lastName
          //     ? `${user?.firstName[0].toUpperCase()}${user?.lastName[0].toUpperCase()}`
          //     :  ; // Default value if name is not defined

          const nameLetters = avatarInitialLetters(
            user.name || `${user.firstName} ${user.lastName}` || "#"
          );

          return (
            <Tooltip
              arrow
              key={user?._id}
              placement="top"
              title={
                user?.firstName && user?.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : user?.firstName
                  ? user.firstName
                  : user?.name || ""
              }
            >
              <Avatar
                className={styles["smallgroupedavatars__avatar"]}
                alt={nameLetters}
                src={
                  user?.avatar?.startsWith("#")
                    ? undefined
                    : user?.avatar || user.imgSrc
                }
                sx={{
                  backgroundColor: user?.avatar?.startsWith("#")
                    ? user?.avatar?.split("|")[0]
                    : colorList[index % colorList.length], // Cycle through colors
                }}
              >
                <Typography className={styles["smallgroupedavatars__text"]}>
                  {nameLetters}
                </Typography>
              </Avatar>
            </Tooltip>
          );
        }
      )}
    </AvatarGroup>
  );
};

export default SmallGroupedAvatars;
