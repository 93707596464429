import { InputBase, Stack } from "@mui/material";
import React, { useState } from "react";
import { DealStageCurrencySymbols } from "../../../../../../common";

interface PayScaleinputAreaProps {}

const PayScaleinputArea: React.FC<PayScaleinputAreaProps> = ({}) => {
  const currencySymbol = DealStageCurrencySymbols.USD;
  const [value, setValue] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Allow only numbers and a single decimal point
    if (/^\d*\.?\d*$/.test(inputValue)) {
      setValue(inputValue);
    }
  };

  return (
    <Stack
      sx={{
        width: "200px",
        height: "30px",
        border: "1px solid #E9EDF2",
        borderRadius: "5px",
        boxSizing: "border-box",
        padding: "0px 10px",
        font: "normal normal 300 14px/18px Source Serif Pro",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
      }}
    >
      {currencySymbol}

      <InputBase
        sx={{ font: "inherit" }}
        value={value}
        onChange={handleChange}
        inputProps={{ inputMode: "decimal" }}
        placeholder="00.00"
      />
    </Stack>
  );
};

export default PayScaleinputArea;
