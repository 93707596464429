import React, {useState} from "react";
import {DrawerMsgViewCard, MsgDrawerContainer} from "../../common";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import {MessagesState} from "../../../../../redux/Slice/message/messageTypes";
import {MESSAGE_ENUMS} from "../../constant";
import {SlackPng, StreamIconBearishOSIconPng} from "../../../../../images";
import {useNavigate, useParams} from "react-router-dom";
import {
    addSelectedConversationIdAction,
    handlePinAndUnPinChannelAction,
    handleRemoveChannelAction,
    resetSelectedConversationAction
} from "../../../../../redux/Slice/message/messageSlice";
import {Popover} from "@mui/material";
import {
    hideLeaveForPrsonalChat,
    MessageDetailsMenus,
    messageDetailsMenusChatList,
    thirdPartyChat
} from "../MessageDetailsMenus";
import {
    getUserConnections,
    leaveSlackConversation,
    updateConnections
} from "../../../../../redux/Slice/message/messageActions";
import {AuthState} from "../../../../../redux";

// interface DirectMessageContainerProps {}

const DirectMessageContainer: React.FC = () => {
    const [selectedChannel, setSelectedChannel] = useState<any>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? "menu-popover" : undefined;
    const navigate = useNavigate();
    const param = useParams();
    const dispatch = useDispatch();

    const {directChannel} = useSelector(
        (state: RootState) => state.message as MessagesState
    );

    const {user} = useSelector((state: RootState) => state.auth as AuthState);

    const handleChannelClick = async (channelId: string) => {
        await dispatch(addSelectedConversationIdAction(channelId));
        navigate(`/Message/${channelId}`);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChatMenuClick = async (event: any, data: any) => {
        const userData = user
        const channelId = selectedChannel?._id;

        if (data.value?.includes("Pin")) {

            const channelIsPinned = {
                isPinned: data?.isPinned ? false : true,
                userId: userData?.data._id,
            };

            const payload = {
                isPinned: true,
                _id: channelId,
            }

            await dispatch(handlePinAndUnPinChannelAction({payload}));

            await dispatch(updateConnections(channelId, channelIsPinned));
        }

        if (data.value?.includes("Leave")) {
            if (selectedChannel.channelType === MESSAGE_ENUMS.BEARISH) {
                const channelIsPinned = {
                    channelId: channelId,
                    userId: userData?.data._id,
                    isLeave: true,
                };
                await dispatch(updateConnections(channelId, channelIsPinned));
                await dispatch(getUserConnections());
            }
            if (selectedChannel.channelType === MESSAGE_ENUMS.SLACK) {
                const response: any = await dispatch(leaveSlackConversation(channelId));
                if (response?.success) {
                    await dispatch(handleRemoveChannelAction(channelId));
                    if (channelId === param?.id) {
                        await dispatch(resetSelectedConversationAction());
                        navigate("/Message");
                    }
                }
            }
        }

        if (data.value.includes("Hide")) {
            const channelIsHide = {
                isHide: true,
                userId: userData?.data?._id,
                channelId: channelId,
            };
            await dispatch(updateConnections(channelId, channelIsHide));
            await dispatch(getUserConnections());
            navigate("/Message");
        }

        if (data.value.includes("Copy")) {
            navigator.clipboard.writeText(
                `https://os.bearishos.com/Message/${channelId}`
            );
        }
        handleClose();
    };

    const handleChannelRightClick = (event: React.MouseEvent<HTMLElement>, channelId: string) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setSelectedChannel(directChannel[channelId]);
    };

    return (
        <MsgDrawerContainer label="Direct Message" selectedTab={0} defaultExpended>
            {Object.keys(directChannel).length > 0 && Object.keys(directChannel).map((key, index) => (
                <DrawerMsgViewCard
                    key={key}
                    userIconSrc={directChannel[key].avatar || ""}
                    unreadMsgCount={directChannel[key].unreadMessageCount}
                    isSelected={param.id === directChannel[key]._id}
                    onCardClick={() => handleChannelClick(directChannel[key]._id)}
                    onCardRightClick={(e: any) => handleChannelRightClick(e, directChannel[key]._id)}
                    title={directChannel[key].displayName}
                    platformIcon={directChannel[key]?.channelType === MESSAGE_ENUMS.SLACK
                        ? SlackPng
                        : StreamIconBearishOSIconPng}
                />
            ))}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        bgcolor: "transparent",
                        p: 0,
                        boxShadow: "none",
                        scrollbarWidth: "none",
                    },
                }}
            >
                <MessageDetailsMenus
                    handleClick={(event: any, data: any) => onChatMenuClick(event, data)}
                    options={
                        selectedChannel?.channelType === MESSAGE_ENUMS.BEARISH
                            ? !selectedChannel?.currentUserLeave
                                ? messageDetailsMenusChatList
                                : hideLeaveForPrsonalChat
                            : thirdPartyChat
                    }
                />
            </Popover>
        </MsgDrawerContainer>
    );
};

export default DirectMessageContainer;
