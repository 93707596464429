import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Container,
  Box,
  Drawer,
  Stack,
  Grow,
  Zoom,
  Modal,
} from "@mui/material";
import {
  DrawerContainer,
  drawerStyles,
  PlanDrawerProps,
} from "../GlobalDrawerUtils";

import styles from "../../DrawerNavbar.module.css";
import DynamicTagWrapper from "../DynamicTagWrapper";
import { CommonButtonLabels, SidebarDrawerButtons } from "../components";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import {
  CalendarState,
  getAllBookingLinks__api,
  getAllCalendars__api,
  getAllEvents__api,
  getOneBookingLinks__api,
  recallAPI,
  toggleCalendarIdVisibility,
} from "../../../../../redux";
import { CalendarList, ListSection } from "./components";
import {
  BookingLinkCalendarPreview,
  CalendarSettings,
  LinkAnalyticsContainer,
  NamePlackCard,
} from "../../../../pages/Calendar/components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "970px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 2,
  display: "flex",
  alignItems: "flex-start",
  borderRadius: "5px",
};
const PlanDrawerV201: React.FC<PlanDrawerProps> = ({
  drawerOpen,
  drawerCloser,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loader_fetchCalendar, setLoader_fetchCalendar] =
    useState<boolean>(false);
  const [loader_fetchBookingLink, setLoader_fetchBookingLink] = useState(false);
  const [loader_fetchEvents, setLoadfetchEvents] = useState(false);
  const [showCalendarSetting, setShowCalendarSetting] = useState(false);
  const calendarState = useSelector(
    (state: RootState) => state && (state?.calendar as CalendarState)
  );

  const { allCalendarList, allBookingList, reCall } = calendarState || {};
  const [selectedButton, setSelectedButton] =
    useState<CommonButtonLabels | null>(null);

  const fetchData = async (
    apiAction: any,
    setLoader: (state: boolean) => void
  ) => {
    try {
      setLoader(true);
      await dispatch(apiAction);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      dispatch(recallAPI(null));
      setLoader(false);
    }
  };
  const navigate = useNavigate();
  const getAllCalendar = () =>
    fetchData(getAllCalendars__api(), setLoader_fetchCalendar);
  const getAllBookingLinks = () =>
    fetchData(getAllBookingLinks__api(), setLoader_fetchBookingLink);
  const getAllEvents = () => fetchData(getAllEvents__api(), setLoadfetchEvents);

  useEffect(() => {
    const fetchDataBasedOnReCall = async () => {
      switch (reCall) {
        case "All":
          await Promise.all([
            getAllBookingLinks(),
            getAllCalendar(),
            getAllEvents(),
          ]);
          break;
        case "getAllBook":
          await getAllBookingLinks();
          break;
        case "getAllCal":
          await getAllCalendar();
          break;
        case "getAllCalEvent":
          await getAllEvents();
          break;
        default:
          break;
      }
    };

    fetchDataBasedOnReCall(); // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [reCall]);

  const handleButtonClick = (label: CommonButtonLabels) => {
    const isCalendarSettings = label === CommonButtonLabels.CALENDAR_SETTINGS;
    const isNavigationLabel =
      label === CommonButtonLabels.PLAN_YOUR_DAYS ||
      label === CommonButtonLabels.COMMAND_CONTROL;

    // Toggle calendar settings visibility
    setShowCalendarSetting(isCalendarSettings);

    // Handle navigation if applicable
    if (isNavigationLabel) {
      const path =
        label === CommonButtonLabels.PLAN_YOUR_DAYS
          ? "plan-your-day"
          : "commands-control";
      navigate(`/Calendar/${path}`);
    }

    // Set the selected button
    setSelectedButton(label);
  };

  const handleToggleCalendarVisibility = useCallback(
    (calendarId: string | undefined) => {
      if (!calendarId) {
        return;
      }
      dispatch(toggleCalendarIdVisibility(calendarId));
    },
    [dispatch]
  );

  const [open_list, setOpen] = useState({
    yourCalendars: true,
    sharedCalendars: true,
    bookingLinks: true,
  });

  const handleClickControlList = (type: string) => {
    setOpen((prevState) => ({
      ...prevState,
      [type]: !prevState[type as keyof typeof prevState],
    }));
  };
  const memoizedAllCalendarLists = useMemo(
    () => allCalendarList,
    [allCalendarList]
  );
  const memoizedAllBookingLinkLists = useMemo(
    () => allBookingList,
    [allBookingList]
  );
  const [bookingLinks, setBookingLinks] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const tabStyle = (tab: any) => ({
    marginRight: "15px", // Add spacing between the tabs
    cursor: "pointer", // Show pointer on hover
    fontWeight: activeTab === tab ? "bold" : "normal", // Bold the active tab
    borderBottom: activeTab === tab ? "2px solid #B5005B" : "none", // Underline for active tab
  });
  const [bLinkId, setBLinkId] = useState("");
  const [bookLinkDataForHost, setBookLinkDataForHost] = useState<any>([]);
  const getBookingLinkData = async () => {
    try {
      if (!bLinkId) {
        return;
      }
      const action = getOneBookingLinks__api(bLinkId);

      const blinkRes: any = await dispatch(action);

      if (blinkRes?.success && blinkRes?.data) {
        setBookingLinks(true);
        setBookLinkDataForHost(blinkRes?.data);
      } else {
        setBookingLinks(false);
        setBookLinkDataForHost([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getBookingLinkData(); // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [bLinkId]);

  return (
    <>
      {showCalendarSetting && (
        <>
          <Grow
            in={true}
            style={{ transformOrigin: "0 0 0 0" }}
            {...(true ? { timeout: 500 } : {})}
          >
            <div
              style={{
                position: "absolute",
                zIndex: "9999",
                top: "7%",
                left: "290px",
                width: `88vw`,
                maxWidth: "calc(100vw - 300px)",
                overflowX: "auto",
                overflowY: "scroll",
                maxHeight: "calc(100vh - 45px)",
                height: "100vh",
              }}
            >
              <Suspense fallback={<div>Loading Sidebar...</div>}>
                <CalendarSettings
                  setShowCalendarSetting={setShowCalendarSetting}
                />
              </Suspense>
            </div>
          </Grow>
        </>
      )}
      {!!bookingLinks && (
        <>
          <Modal
            open={true}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <React.Suspense fallback={<div>Loading Sidebar...</div>}>
                <BookingLinkCalendarPreview
                  bLinkIdData={bookLinkDataForHost}
                  onClickhandle={() => setBookingLinks(!bookingLinks)}
                />
              </React.Suspense>
              <span
                style={{
                  borderLeft: "1px solid #e0e0e0",
                  height: "100%",
                  marginRight: "10px",
                  position: "relative",
                }}
              ></span>
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <h2 style={{ marginRight: "30px", fontWeight: "bold" }}>
                    {t("CALENDAR.MEETINGS")}
                  </h2>

                  <div
                    style={tabStyle("all")}
                    onClick={() => setActiveTab("all")}
                  >
                    {t("CALENDAR.All")}
                  </div>

                  <div
                    style={tabStyle("past")}
                    onClick={() => setActiveTab("past")}
                  >
                    {t("CALENDAR.Past")}
                  </div>

                  <div
                    style={tabStyle("upnext")}
                    onClick={() => setActiveTab("upnext")}
                  >
                    {t("CALENDAR.UP_NEXT")}
                  </div>
                </div>
                <div
                  style={{
                    maxHeight: "50vh",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {bookLinkDataForHost &&
                    bookLinkDataForHost?.analytics &&
                    bookLinkDataForHost?.analytics?.visits && // Access visits correctly
                    bookLinkDataForHost?.analytics?.visits?.length > 0 &&
                    bookLinkDataForHost?.analytics?.visits?.map((v: any) => {
                      return (
                        <React.Suspense
                          fallback={<div>Loading Sidebar...</div>}
                        >
                          <NamePlackCard key={v?._id} vister={v} />;
                        </React.Suspense>
                      );
                    })}
                </div>

                <div>
                  {bookLinkDataForHost && bookLinkDataForHost?.analytics && (
                    <React.Suspense fallback={<div>Loading Sidebar...</div>}>
                      <LinkAnalyticsContainer
                        analytics={bookLinkDataForHost?.analytics}
                      />
                    </React.Suspense>
                  )}
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}

      <DrawerContainer>
        <Drawer
          sx={drawerStyles({
            drawerOpen,
            width: "215px",
            transitionDuration: "225ms",
          })}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
        >
          <Stack
            bgcolor={"white"}
            direction="column"
            flex={1}
            className={`${styles.drawerNavbar__drawer}`}
          >
            <DynamicTagWrapper
              tag="showCompany"
              arrowButtonClickHandler={drawerCloser}
            >
              <Container disableGutters>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Stack
                    className={styles.drawerNavbar__content}
                    width={"100%"}
                    padding={"5px"}
                  >
                    <Stack mt={3} mb={3}>
                      <SidebarDrawerButtons
                        buttonLabels={[
                          CommonButtonLabels.PLAN_YOUR_DAYS,
                          CommonButtonLabels.COMMAND_CONTROL,
                          CommonButtonLabels.PLAN_YOUR_WEEK,
                          CommonButtonLabels.CALENDAR_SETTINGS,
                        ]}
                        selectedButton={selectedButton}
                        onButtonClick={(l: any) => {
                          handleButtonClick(l);
                        }}
                        baseColor="#B5005B"
                      />
                    </Stack>

                    {/* Your Calendar */}
                    <Stack>
                      <ListSection
                        sectionName="Your Calendars"
                        openList={open_list}
                        handleClick={handleClickControlList}
                        content={
                          <CalendarList
                            loader={loader_fetchCalendar}
                            allCalendarLists={memoizedAllCalendarLists}
                            handleToggleCalendarVisibility={
                              handleToggleCalendarVisibility
                            }
                          />
                        }
                      />

                      {/* Render Shared Calendar Section */}
                      <ListSection
                        sectionName="Shared Calendars"
                        openList={open_list}
                        handleClick={handleClickControlList}
                        content={
                          <CalendarList
                            loader={loader_fetchCalendar}
                            allCalendarLists={memoizedAllCalendarLists}
                            displayOwnerCalendar={false}
                            handleToggleCalendarVisibility={
                              handleToggleCalendarVisibility
                            }
                          />
                        }
                      />

                      {/* Render Booking Links Section */}
                      <ListSection
                        sectionName="Booking Links"
                        openList={open_list}
                        handleClick={handleClickControlList}
                        content={
                          <CalendarList
                            loader={loader_fetchBookingLink}
                            allBookingLinkLists={memoizedAllBookingLinkLists}
                            handleToggleCalendarVisibility={
                              handleToggleCalendarVisibility
                            }
                            bookingLink={true}
                            setBLinkId={setBLinkId}
                          />
                        }
                      />
                    </Stack>
                  </Stack>
                </Box>
              </Container>
            </DynamicTagWrapper>
          </Stack>
        </Drawer>
      </DrawerContainer>
    </>
  );
};

export default PlanDrawerV201;
