import React, { useState } from "react";
import { Stack, Popover, Typography } from "@mui/material";
import {
  ButtonSmall,
  EditableTextArea,
  EventIconButton,
  SmallButtonColor,
} from "../../../../../../common";
import {
  CheckboxFilledIcon,
  NotesLineIcon,
  XCircleIcon,
} from "../../../../../../../images";
import { UserInfoChip } from "../../../../../Email/components";
import styles from "./ConflictHandleButtons.module.css";

interface ConflictHandleButtonsProps {
  isDisabled: boolean;
}

const ConflictHandleButtons: React.FC<ConflictHandleButtonsProps> = ({
  isDisabled,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={styles["conflictHandleButtons"]}
      style={{ opacity: isDisabled ? 0.3 : 1 }}
    >
      <EventIconButton
        buttonTitle="Override Error"
        icon={CheckboxFilledIcon}
        iconColor="#21B481"
        onClick={handlePopoverOpen}
        disabled={isDisabled}
      />

      <EventIconButton
        buttonTitle="Remove Employee"
        icon={XCircleIcon}
        iconColor="#FF0000"
        disabled={isDisabled}
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={styles["conflictHandleButtons__popover"]}>
          <div className={styles["conflictHandleButtons__popover-header"]}>
            <CheckboxFilledIcon color="#21B481" w={15} />
            <Typography
              className={styles["conflictHandleButtons__popover-description"]}
            >
              Overriding Conflict
            </Typography>
          </div>

          <Typography
            className={styles["conflictHandleButtons__popover-description"]}
          >
            Please provide a reason why you are overriding the conflict with
            this employees scheduled working times.
          </Typography>

          <div className={styles["conflictHandleButtons__reason-area"]}>
            <div className={styles["conflictHandleButtons__reason-header"]}>
              <NotesLineIcon w={15} />
              <Typography
                className={styles["conflictHandleButtons__popover-description"]}
              >
                Reason
              </Typography>
            </div>

            <EditableTextArea
              minRows={3}
              maxRows={3}
              style={{
                font: "normal normal 300 13px/16px Source Serif Pro",
                border: "1px solid #E9EDF2",
              }}
            />
          </div>

          <div className={styles["conflictHandleButtons__acted-on-section"]}>
            <Typography
              className={styles["conflictHandleButtons__popover-description"]}
            >
              Acted on by:
            </Typography>

            <UserInfoChip
              containerSx={{ width: "fit-content" }}
              popoverPlace="top"
              userEmail=""
              userName="User Name"
              chipHeight="30px"
              popoverZindex={1300}
            />
          </div>

          <div className={styles["conflictHandleButtons__button-container"]}>
            <ButtonSmall
              label="Override & Approve"
              colorVarient={SmallButtonColor.TraxBluish}
              sx={{ width: "135px" }}
            />
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default ConflictHandleButtons;
