import React from "react";
import { ReplyContainer, SquareInput } from "../view";
import { Stack, Typography } from "@mui/material";
import { DatePickerWithIcon } from "../../DatePickerWithIcon";
import { DatePickerMini } from "../../DatePickerMini";
import { TextLinkButton } from "../../Buttons";

interface ScheduleEventBarProps {
    onCloseClick: () => void;
    selectedUser?: string | null;
}

const ScheduleEventBar: React.FC<ScheduleEventBarProps> = ({onCloseClick,selectedUser}) => {
  return (
    <ReplyContainer
      replyType="eventSchedule"
      titleActionButton={
        <TextLinkButton
          sx={{ font: "normal italic 600 11px/14px Source Serif Pro" }}
          buttonLabel="Convert to video call"
        />
      }
      dateAndTimeComponents={
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            font: "normal normal 600 11px/14px Source Serif Pro",
          }}
        >
          <Typography sx={{ font: "inherit" }}>{selectedUser}</Typography>
          <Typography
            sx={{
              font: "normal normal 300 11px/14px Source Serif Pro",
              whiteSpace: "nowrap",
            }}
          >
            on
          </Typography>
          <DatePickerWithIcon
            hideIcon
            dateFormat="MM-DD-YYYY"
            sx={{ font: "inherit", width: "55px" }}
          />
          <DatePickerMini
            sx={{ font: "inherit", border: "0px", width: "60px" }}
            hideIcon
          />
          <span>to</span>
          <DatePickerMini
            sx={{ font: "inherit", border: "0px", width: "60px" }}
            hideIcon
          />
        </Stack>
      }
      infoComponents={
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <SquareInput
            squareTitle="Event name"
            initialValue=""
            onValueChange={(value) => console.log("Final Value:", value)}
            placeholder="Enter Event name..."
          />
          <Typography
            sx={{
              font: "normal normal 300 11px/14px Source Serif Pro",
              whiteSpace: "nowrap",
            }}
          >
            at
          </Typography>
          <SquareInput
            squareTitle="Type your location here..."
            initialValue=""
            onValueChange={(value) => console.log("Final Value:", value)}
            placeholder="Type your location here..."
          />
        </Stack>
      }
      onCloseClick={() => onCloseClick()}
      userName="Abhishek"
      userStatus={false}
    />
  );
};

export default ScheduleEventBar;
