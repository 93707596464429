import {Box, Stack, Typography} from "@mui/material";
import React from "react";
import styles from "./EventControlCards.module.css";
import {AvatarNormal} from "../../../../common";
import {StreamIconBearishOSIconPng} from "../../../../../images";
import {useTranslation} from "react-i18next";
import {ButtonGroupOneView, ButtonGroupThreeView, ButtonGroupTwoView,} from "./view";

type buttonsGroup = "group1" | "group2" | "group3";

interface EventControlCardsProps {
    tag: "@pending_events" | "@calls" | "@postCall";
    onButtonClick?: (eventId?: number, snoozeDuration?: string, notificationId?: any, calenderId?: string, event_id?: any) => void; // Add this prop
    notificationInfo?: any;
}

const EventControlCards: React.FC<EventControlCardsProps> = ({tag, onButtonClick, notificationInfo}) => {
    const {t} = useTranslation();

    const getTagLabel = (): {
        tagColor: string;
        translatedWord: string;
        buttonGroup: buttonsGroup;
    } => {
        switch (tag) {
            case "@calls":
                return {
                    tagColor: "#FF8181",
                    translatedWord: "CALL_DETAILS.CALL",
                    buttonGroup: "group1",
                };
            case "@pending_events":
                return {
                    tagColor: "#B5005B",
                    translatedWord: "CALL_DETAILS.PENDING_EVENT",
                    buttonGroup: "group2",
                };
            case "@postCall":
                return {
                    tagColor: "#FF8181",
                    translatedWord: "CALL_DETAILS.POST_CALL",
                    buttonGroup: "group3",
                };
            default:
                return {
                    tagColor: "#000000",
                    translatedWord: "CALL_DETAILS.UNKNOWN",
                    buttonGroup: "group1",
                };
        }
    };


    const getButton = (buttonGroup: buttonsGroup) => {
        switch (buttonGroup) {
            case "group1":
                return <ButtonGroupOneView notificationInfo={notificationInfo} onButtonClick={onButtonClick ?? (() => {
                })}/>;
            case "group2":
                return <ButtonGroupTwoView notificationInfo={notificationInfo} onButtonClick={onButtonClick ?? (() => {
                })}/>;
            case "group3":
                return <ButtonGroupThreeView onButtonClick={onButtonClick ?? (() => {
                })}/>;
        }
    };

    return (
        <Stack className={styles.eventControlCards}>
            {/* User icon with online/offline-status and name here */}
            <Stack className={styles.eventControlCards__user}>
                <AvatarNormal size={30} imgSrc={notificationInfo?.creatorInfo?.avatar}
                              username={`${notificationInfo?.creatorInfo?.firstName}  ${notificationInfo?.creatorInfo?.lastName}`}/>

                <Typography className={styles.eventControlCards__username}>
                    {notificationInfo?.title}
                </Typography>
            </Stack>

            {/* Heighlighter[@tags] and message text here */}
            <Stack className={styles.eventControlCards__content}>
                <Stack className={styles.eventControlCards__contentRow}>
                    <Typography
                        className={styles.eventControlCards__tagText}
                        sx={{color: getTagLabel().tagColor, width: "120px"}}
                    >
                        @{t(getTagLabel().translatedWord)}
                    </Typography>
                    <Stack
                        sx={{
                            flexGrow: 1,
                            width: "calc(100% - 80px)",
                        }}
                    >
                        <Typography className={styles.eventControlCards__message}>
                            {notificationInfo?.description}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>

            {/* Buttons and company icons */
            }
            <Stack className={styles.eventControlCards__actions}>
                <Box
                    className={styles.eventControlCards__icon}
                    component={"img"}
                    alt="logo"
                    src={StreamIconBearishOSIconPng}
                />
                {notificationInfo?.status !== "DONE" && getButton(getTagLabel().buttonGroup)}
            </Stack>
        </Stack>
    )
        ;
};

export default EventControlCards;
