import {Box, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import { BothSideArrowIcon} from "../../../../images";
import {getRedirectServiceCss, IconsContainer, PrivacyPolicyTextView, RedirectButtons, RedirectPageContainer,} from "../common";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {slackRedirect, syncSlackWithBearish, teamsRedirect} from "../../../../redux/Slice/message/messageActions";
import {useDispatch} from "react-redux";
import { adjustHexColor } from "../../../../Utils";

const RedirectStatus: React.FC = () => {
    const {t} = useTranslation();
    const [isSuccess, setIsSuccess] = useState<boolean>(true);
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const params: any = useParams();

    const navigate = useNavigate();
    const {imgName, labelText, labelColor} = getRedirectServiceCss({
        groupName: params?.groupName || "workspaceDarkOragne",
        serviceName: params?.platform || "jira",
    });


    const handleAuthRedirect = async (platform: string | null) => {
        const code = searchParams.get("code");
        switch (platform) {
            case "slack":
                if (code) {
                    const response: any = await dispatch(slackRedirect(code));
                    if (response?.success) {
                        setIsSuccess(true);
                        dispatch(syncSlackWithBearish())
                        setTimeout(() => {
                            navigate("/Message")
                        }, 10000);
                    }
                }
                break;
            case "teams":
                if (code) {
                    const response: any = await dispatch(teamsRedirect(code));
                    if (response?.success) {
                        setIsSuccess(true);
                        setTimeout(() => {
                            navigate("/Message");
                        }, 10000);
                    }
                }
                break;
            case "box":
                console.log("box");
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        const platform = params?.platform || null;
        (async () => {
            await handleAuthRedirect(platform);
        })();
    }, [params]);

    const handleRedirectToDashboard = () => {
        navigate("/dashboard");
    }

    const handleRedirectToTryAgain = () => {
        navigate("/Settings/intelligent-connections");
    }

    return (
        <RedirectPageContainer>
            <Stack
                sx={{
                    width: "500px",
                    minHeight: "600px",
                    bgcolor: adjustHexColor("#E6BA8C", 55),
                    alignItems: "center",
                    paddingTop: "52px",
                    gap: "30px",
                }}
            >
                <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "15px" }}>
                    <IconsContainer borderColor="#775EE2" imgName="bearishPng" />
                    <BothSideArrowIcon w={50} />
                    <IconsContainer borderColor={labelColor} imgName={imgName} />
                </Stack>

                <Typography
                    sx={{ font: "normal normal 900 40px/50px Source Serif Pro" }}
                >
                    {isSuccess ? t("DASHBOARD.SUCCESS!") : t("DASHBOARD.WHOOPS!")}
                </Typography>

                <Typography
                    sx={{
                        font: "normal italic 300 20px/25px Source Serif Pro",
                        width: "450px",
                        textAlign: "center",
                    }}
                >
                    {isSuccess ? (
                        <Trans
                            i18nKey="DASHBOARD.SUCCESSFULLY_CONNECTED_TO"
                            components={{
                                span: <span style={{ color: labelColor }} />,
                            }}
                            values={{ serviceName: labelText }}
                        />
                    ) : (
                        <Trans
                            i18nKey="DASHBOARD.CONNECTION_ISSUE"
                            components={{
                                span: <span style={{ color: labelColor }} />,
                            }}
                            values={{ serviceName: labelText }}
                        />
                    )}
                </Typography>
                <Box
                    sx={{ height: "1px", bgcolor: "black", width: "425px", mt: "27px" }}
                />
                <PrivacyPolicyTextView />

                <Stack sx={{ gap: "25px" }}>
                    {isSuccess ? (
                        <>
                            <RedirectButtons
                                btnLabel={t("DASHBOARD.BACK_TO_BEARISH_OS_SUCCESS")}
                                onClick={handleRedirectToDashboard}
                            />
                        </>
                    ) : (
                        <>
                            <RedirectButtons
                                btnLabel={t("DASHBOARD.LOGIN_FAILED_TRY_AGAIN")}
                                btnBgColor="pink"
                                btnTextColor="white"
                                onClick={handleRedirectToTryAgain}
                            />
                            <RedirectButtons
                                btnLabel={t("DASHBOARD.BACK_TO_BEARISH_OS_TRY_AGAIN_LATER")}
                                onClick={handleRedirectToDashboard}
                            />
                        </>
                    )}
                </Stack>
            </Stack>
        </RedirectPageContainer>
    );
};

export default RedirectStatus;
