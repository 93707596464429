import { IconButton } from "@mui/material";
import React from "react";
import { LockIcon, UnlockIcon } from "../../../images";

interface LockAndUnlockedProps {
  iconSize?: number;
  isLocked: boolean; // Controlled by parent
  onSelect: (value: "lock" | "unlock") => void; // Specify value type
}

const LockAndUnlocked: React.FC<LockAndUnlockedProps> = ({
  onSelect,
  iconSize = 15,
  isLocked,
}) => {
  const clickHandler = () => {
    const newValue = isLocked ? "unlock" : "lock"; // Determine new value based on prop
    onSelect(newValue); // Notify parent immediately on click
  };

  return (
    <IconButton sx={{ p: 0 }} disableRipple onClick={clickHandler}>
      {isLocked ? (
        <LockIcon w={iconSize} />
      ) : (
        <UnlockIcon w={iconSize} />
      )}
    </IconButton>
  );
};

export default LockAndUnlocked;
