import {Stack} from "@mui/material";
import React from "react";
import {filterAttachmentsByType, MsgAttachmentsType} from "../../MsgType";
import MsgImageContainer from "../MsgImageContainer/MsgImageContainer";
import {MsgFilePreview} from "../../../MsgFilePreview";
import {ImageWithSkeleton} from "../../../../../../common";
import {MsgVideoPreview} from "../../../MsgVideoPreview";
import {MsgAudioPreview} from "../../../MsgAudioPreview";
import {MsgLinkPreview} from "../../../MsgLinkPreview";

interface AttachmentViewProps {
    attachmentList: MsgAttachmentsType[];
}

const AttachmentView: React.FC<AttachmentViewProps> = ({attachmentList}) => {
    return (
        <Stack sx={{gap: "20px", flexShrink: 0}}>

            {filterAttachmentsByType({
                data: attachmentList,
                fileType: "file",
            }).map((item, index) => {
                return <MsgFilePreview fileData={item}/>;
            })}

            {filterAttachmentsByType({
                data: attachmentList,
                fileType: "image",
            }).length > 0 && (
                <MsgImageContainer>
                    {filterAttachmentsByType({
                        data: attachmentList,
                        fileType: "image",
                    }).map((item, index) => {
                        return <ImageWithSkeleton alt="image" src={item.fileUrl!}/>;
                    })}
                </MsgImageContainer>
            )}

            {filterAttachmentsByType({
                data: attachmentList,
                fileType: "video",
            }).map((item, index) => {
                return <MsgVideoPreview videoData={item}/>;
            })}

            {filterAttachmentsByType({
                data: attachmentList,
                fileType: "audio",
            }).map((item, index) => {
                return <MsgAudioPreview item={item}/>;
            })}

            {filterAttachmentsByType({
                data: attachmentList,
                fileType: "link",
            }).map((item, index) => {
                return <MsgLinkPreview url={item.fileUrl!} key={index}/>;
            })}
        </Stack>
    );
};

export default AttachmentView;
