import { createApiAction } from "../constantApi_config";
import {
  authenticateGuestUserFailureAction,
  authenticateGuestUserRequestAction,
  authenticateGuestUserSuccessAction,
  createMeetingAgendaSuccessAction,
  createMeetingFailureAction,
  createMeetingNoteFailureAction,
  createMeetingNoteRequestAction,
  createMeetingNoteSuccessAction,
  createMeetingRequestAction,
  createMeetingSuccessAction,
  createOnGoingMeetingAgendaSuccessAction,
  deleteAgendaFailureAction,
  deleteAgendaRequestAction,
  deleteAgendaSuccessAction,
  deleteMeetingNotesFailureAction,
  deleteMeetingNotesRequestAction,
  deleteMeetingNotesSuccessAction,
  deleteMeetingsFileSuccessAction,
  getAllMeetingNoteFailureAction,
  getAllMeetingNoteRequestAction,
  getAllMeetingNoteSuccessAction,
  getCallActivityDetailsFailureAction,
  getCallActivityDetailsRequestAction,
  getCallActivityDetailsSuccessAction,
  getDoneHomeScreenNotifications__FailureAction,
  getDoneHomeScreenNotifications__RequestAction,
  getDoneHomeScreenNotifications__SuccessAction,
  getMeetingDetails__FailureAction,
  getMeetingDetails__RequestAction,
  getMeetingDetails__SuccessAction,
  getMeetingsFileSuccessAction,
  getNewHomeScreenNotifications__FailureAction,
  getNewHomeScreenNotifications__RequestAction,
  getNewHomeScreenNotifications__SuccessAction,
  getOnGoingMeetingAgendaFailureAction,
  getOnGoingMeetingAgendaRequestAction,
  getOnGoingMeetingAgendaSuccessAction,
  getPastMeetingDetailsFailureAction,
  getPastMeetingDetailsRequestAction,
  getPastMeetingDetailsSuccessAction,
  getPastMeetingsFailureAction,
  getPastMeetingsRequestAction,
  getPastMeetingsSuccessAction,
  getSelectedMeetingAgendaFailureAction,
  getSelectedMeetingAgendaRequestAction,
  getSelectedMeetingAgendaSuccessAction,
  getSingleMeetingFailureAction,
  getSingleMeetingRequestAction,
  getSingleMeetingSuccessAction,
  getSnoozedHomeScreenNotifications__FailureAction,
  getSnoozedHomeScreenNotifications__RequestAction,
  getSnoozedHomeScreenNotifications__SuccessAction,
  getUserBasicDetails__FailureAction,
  getUserBasicDetails__RequestAction,
  getUserBasicDetails__SuccessAction,
  getUserMeetingsFailureAction,
  getUserMeetingsRequestAction,
  getUserMeetingsSuccessAction,
  postMeetingsFileSuccessAction,
  updateAgendaStatusFailureAction,
  updateAgendaStatusRequestAction,
  updateAgendaStatusSuccessAction,
  updateHomeScreenNotifications__FailureAction,
  updateHomeScreenNotifications__RequestAction,
  updateHomeScreenNotifications__SuccessAction,
  updateInviteStatus__FailureAction,
  updateInviteStatus__RequestAction,
  updateInviteStatus__SuccessAction,
  updateMeetingNoteFailureAction,
  updateMeetingNoteRequestAction,
  updateMeetingNoteSuccessAction,
  updateMeetingsDetails__SuccessAction,
  updateMeetingsFileSuccessAction,
} from "./VideoCenterSlice";

export const createNewMeeting = (data: any) =>
  createApiAction({
    onStart: createMeetingRequestAction.type,
    onSuccess: createMeetingSuccessAction.type,
    onError: createMeetingFailureAction.type,
    method: "POST",
    data,
    url: "/meeting/create",
    axiosRequired: true,
    unwrap: false,
  });

export const getSingleMeeting = (meetingId: string) =>
  createApiAction({
    onStart: getSingleMeetingRequestAction.type,
    onSuccess: getSingleMeetingSuccessAction.type,
    onError: getSingleMeetingFailureAction.type,
    method: "GET",
    url: `/meeting/${meetingId}`,
    axiosRequired: true,
    unwrap: false,
  });

export const getUpcomingMeetings = () =>
  createApiAction({
    onStart: getUserMeetingsRequestAction.type,
    onSuccess: getUserMeetingsSuccessAction.type,
    onError: getUserMeetingsFailureAction.type,
    method: "GET",
    url: `/calendar/events?expand=upcoming_call`,
    axiosRequired: true,
    unwrap: false,
  });

export const getPastMeetings = () =>
  createApiAction({
    onStart: getPastMeetingsRequestAction.type,
    onSuccess: getPastMeetingsSuccessAction.type,
    onError: getPastMeetingsFailureAction.type,
    method: "GET",
    url: `/calendar/events?expand=post_call`,
    axiosRequired: true,
    unwrap: false,
  });

// export const getAttendee = (meetingId: string, attendeeId: string) => createApiAction({
//     onStart: "meeting/get/request",
//     onSuccess: "meeting/get/success",
//     onError: "meeting/get/failure",
//     method: "GET",
//     url: `/meeting/${meetingId}/${attendeeId}`,
//     axiosRequired: true,
//     unwrap: false
// });

export const createAttendeeAction = (meetingId: string) =>
  createApiAction({
    onStart: "meeting/attendee/create/request",
    onSuccess: "meeting/attendee/create/success",
    onError: "meeting/attendee/create/failure",
    method: "POST",
    url: `/meeting/${meetingId}/attendees`,
    axiosRequired: true,
    unwrap: false,
  });
export const endMeeting = (data: any, meetingId: string) =>
  createApiAction({
    onStart: "meeting/attendee/create/request",
    onSuccess: "meeting/attendee/create/success",
    onError: "meeting/attendee/create/failure",
    method: "POST",
    url: `/meeting/${meetingId}/end`,
    axiosRequired: true,
    data,
    unwrap: false,
  });
export const requestToJoinMeeting = (data: any) =>
  createApiAction({
    onStart: "meeting/attendee/create/request",
    onSuccess: "meeting/attendee/create/success",
    onError: "meeting/attendee/create/failure",
    method: "POST",
    url: `/meeting/${data.meeting_id}/join-request`,
    axiosRequired: true,
    data,
    unwrap: false,
  });
export const responseToJoinMeeting = (data: any) =>
  createApiAction({
    onStart: "meeting/attendee/create/request",
    onSuccess: "meeting/attendee/create/success",
    onError: "meeting/attendee/create/failure",
    method: "POST",
    url: "/meeting/join-response",
    axiosRequired: true,
    data,
    unwrap: false,
  });

export const getOnGoingMeetingAgenda = (id: any) =>
  createApiAction({
    onStart: getOnGoingMeetingAgendaRequestAction.type,
    onSuccess: getOnGoingMeetingAgendaSuccessAction.type,
    onError: getOnGoingMeetingAgendaFailureAction.type,
    method: "GET",
    url: `/meeting/${id}/agendas`,
    axiosRequired: true,
    unwrap: false,
  });
export const getSelectedMeetingAgenda = (id: any) =>
  createApiAction({
    onStart: getSelectedMeetingAgendaRequestAction.type,
    onSuccess: getSelectedMeetingAgendaSuccessAction.type,
    onError: getSelectedMeetingAgendaFailureAction.type,
    method: "GET",
    url: `/meeting/${id}/agendas`,
    axiosRequired: true,
    unwrap: false,
  });
export const createMeetingAgenda = (data: any, meetingId: string) =>
  createApiAction({
    onStart: "meeting/attendee/create/request",
    onSuccess: createMeetingAgendaSuccessAction.type,
    onError: "meeting/attendee/create/failure",
    method: "POST",
    url: `/meeting/${meetingId}/agendas`,
    data,
    axiosRequired: true,
    unwrap: false,
  });
export const createOnGoingMeetingAgenda = (data: any, meetingId: string) =>
  createApiAction({
    onStart: "meeting/attendee/create/request",
    onSuccess: createOnGoingMeetingAgendaSuccessAction.type,
    onError: "meeting/attendee/create/failure",
    method: "POST",
    url: `/meeting/${meetingId}/agendas`,
    data,
    axiosRequired: true,
    unwrap: false,
  });
export const updateAgendaStatus = (id: any, payload: any) =>
  createApiAction({
    onStart: updateAgendaStatusRequestAction.type,
    onSuccess: updateAgendaStatusSuccessAction.type,
    onError: updateAgendaStatusFailureAction.type,
    method: "PUT",
    url: `/meeting/agendas/${id}/status`,
    data: payload,
    axiosRequired: true,
    unwrap: false,
  });
export const updateAgendaDetails = (id: any, payload: any) =>
  createApiAction({
    onStart: updateAgendaStatusRequestAction.type,
    onSuccess: updateAgendaStatusSuccessAction.type,
    onError: updateAgendaStatusFailureAction.type,
    method: "PUT",
    url: `/meeting/agendas/${id}`,
    data: payload,
    axiosRequired: true,
    unwrap: false,
  });
export const deleteAgenda = (id: any) =>
  createApiAction({
    onStart: deleteAgendaRequestAction.type,
    onSuccess: deleteAgendaSuccessAction.type,
    onError: deleteAgendaFailureAction.type,
    method: "DELETE",
    url: `/meeting/agendas/${id}`,
    axiosRequired: true,
    unwrap: false,
  });

export const createMeetingNotes = (data: any) =>
  createApiAction({
    onStart: createMeetingNoteRequestAction.type,
    onSuccess: createMeetingNoteSuccessAction.type,
    onError: createMeetingNoteFailureAction.type,
    method: "POST",
    url: `/meeting/${data.meetingId}/notes`,
    data,
    axiosRequired: true,
    unwrap: false,
  });
export const createOrUpdateNote = (id: any, data: any) =>
  createApiAction({
    onStart: updateMeetingNoteSuccessAction.type,
    onSuccess: updateMeetingNoteRequestAction.type,
    onError: updateMeetingNoteFailureAction.type,
    method: "POST",
    url: `/meeting/${id}/notes`,
    data,
    axiosRequired: true,
    unwrap: false,
  });
export const getAllMeetingNotes = (id: any) =>
  createApiAction({
    onStart: getAllMeetingNoteRequestAction.type,
    onSuccess: getAllMeetingNoteSuccessAction.type,
    onError: getAllMeetingNoteFailureAction.type,
    method: "GET",
    url: `/meeting/${id}/notes`,
    axiosRequired: true,
    unwrap: false,
  });

export const deleteMeetingNotes = (id: any) =>
  createApiAction({
    onStart: deleteMeetingNotesRequestAction.type,
    onSuccess: deleteMeetingNotesSuccessAction.type,
    onError: deleteMeetingNotesFailureAction.type,
    method: "DELETE",
    url: `/meeting/notes/${id}`,
    axiosRequired: true,
    unwrap: false,
  });
export const logMeetingActivity = (id: any, data: any) =>
  createApiAction({
    onStart: "deleteMeetingNotesRequestAction.type",
    onSuccess: "deleteMeetingNotesSuccessAction.type",
    onError: "deleteMeetingNotesFailureAction.type",
    method: "POST",
    data,
    url: `/meeting/${id}/log-event`,
    axiosRequired: true,
    unwrap: false,
  });


export const getCallActivityDetails = (id: string | undefined) =>
  createApiAction({
    onStart: getCallActivityDetailsRequestAction.type,
    onSuccess: getCallActivityDetailsSuccessAction.type,
    onError: getCallActivityDetailsFailureAction.type,
    method: "GET",
    url: `/meeting/activity/${id}`,
    axiosRequired: true,
    unwrap: false,
  });

export const getPastMeetingDetails = (id: string | undefined) =>
  createApiAction({
    onStart: getPastMeetingDetailsRequestAction.type,
    onSuccess: getPastMeetingDetailsSuccessAction.type,
    onError: getPastMeetingDetailsFailureAction.type,
    method: "GET",
    url: `/meeting/${id}/past/details`,
    axiosRequired: true,
    unwrap: false,
  });

export const updateMeetingsDetails = (calendarId: any, data: any) =>
  createApiAction({
    axiosRequired: true,
    method: "put",
    data: data,
    url: `/calendar/update/${calendarId}`,
    onStart: "updateCalendarEvent__RequestAction.type",
    onSuccess: updateMeetingsDetails__SuccessAction.type,
    onError: "updateCalendarEvent__FailureAction.type",
  });

// For Files

export const postMeetingsFiles = (meetingId: any, data: any) =>
  createApiAction({
    axiosRequired: true,
    method: "post",
    data: data,
    url: `/meeting/${meetingId}/files`,
    onStart: "updateCalendarEvent__RequestAction.type",
    onSuccess: postMeetingsFileSuccessAction.type,
    onError: "updateCalendarEvent__FailureAction.type",
  });

export const getMeetingsFiles = (meetingId: string) =>
  createApiAction({
    axiosRequired: true,
    method: "get",
    url: `/meeting/${meetingId}/files`,
    onStart: "updateCalendarEvent__RequestAction.type",
    onSuccess: getMeetingsFileSuccessAction.type,
    onError: "updateCalendarEvent__FailureAction.type",
  });

export const updateMeetingsFiles = (fileId: any, data: any) =>
  createApiAction({
    axiosRequired: true,
    method: "put",
    data: data,
    url: `/meeting/files/${fileId}`,
    onStart: "updateCalendarEvent__RequestAction.type",
    onSuccess: updateMeetingsFileSuccessAction.type,
    onError: "updateCalendarEvent__FailureAction.type",
  });

export const deleteMeetingsFiles = (fileId: any) =>
  createApiAction({
    axiosRequired: true,
    method: "delete",
    url: `/meeting/files/${fileId}`,
    onStart: "updateCalendarEvent__RequestAction.type",
    onSuccess: deleteMeetingsFileSuccessAction.type,
    onError: "updateCalendarEvent__FailureAction.type",
  });

export const saveMeetingRecording = (meetingId: string, data: any) => createApiAction({
  onStart: "meeting/recording/save/request",
  onSuccess: "meeting/recording/save/success",
  onError: "meeting/recording/save/failure",
  method: "POST",
  url: `/meeting/${meetingId}/save-recording`,
  axiosRequired: true,
  data,
  unwrap: false
});

export const getNewHomeScreenNotifications = () => createApiAction({
  axiosRequired: true,
  method: "get",
  url: `/command-notifications/notifications?center=PLAN&status=NEW`,
  onStart: getNewHomeScreenNotifications__RequestAction.type,
  onSuccess: getNewHomeScreenNotifications__SuccessAction.type,
  onError: getNewHomeScreenNotifications__FailureAction.type,
});
export const getDoneHomeScreenNotifications = () => createApiAction({
  axiosRequired: true,
  method: "get",
  url: `/command-notifications/notifications?center=PLAN&status=DONE`,
  onStart: getDoneHomeScreenNotifications__RequestAction.type,
  onSuccess: getDoneHomeScreenNotifications__SuccessAction.type,
  onError: getDoneHomeScreenNotifications__FailureAction.type,
});
export const getSnoozedHomeScreenNotifications = () => createApiAction({
  axiosRequired: true,
  method: "get",
  url: `/command-notifications/notifications?center=PLAN&status=SNOOZED`,
  onStart: getSnoozedHomeScreenNotifications__RequestAction.type,
  onSuccess: getSnoozedHomeScreenNotifications__SuccessAction.type,
  onError: getSnoozedHomeScreenNotifications__FailureAction.type,
});

/* Event Response */
export const updateInviteStatus = ({
  calendarId,
  eventId,
  response,
  type,
  queryParams
}: {
  calendarId: string,
  eventId: string,
  response: string,
  type: string
  queryParams?: { email?: string; userId?: string } // Accept query parameters
}
) =>
  createApiAction({
    axiosRequired: true,
    method: "PUT",
    url: (() => {
      // Build the base URL
      let url = `/calendar/c/${calendarId}/events/${eventId}/${type}/r/${response}`;

      // Add query parameters if available
      if (queryParams) {
        const queryString = new URLSearchParams(queryParams).toString();
        if (queryString) {
          url += `?${queryString}`;
        }
      }

      return url;
    })(),
    onStart: updateInviteStatus__RequestAction.type,
    onSuccess: updateInviteStatus__SuccessAction.type,
    onError: updateInviteStatus__FailureAction.type,
  });

export const updateHomeScreenNotifications = ({ notificationId, data, method }: any) => {
  return createApiAction({
    axiosRequired: true,
    method: method,
    data: data,
    url: `/command-notifications/notifications/${notificationId}`,
    onStart: updateHomeScreenNotifications__RequestAction.type,
    onSuccess: updateHomeScreenNotifications__SuccessAction.type,
    onError: updateHomeScreenNotifications__FailureAction.type,
  });
}

export const checkMeetingExistance = (meetingId: any) => {
  return createApiAction({
    axiosRequired: true,
    method: "GET",
    url: `/meeting/${meetingId}/check-existence`,
    onStart: "updateHomeScreenNotifications__RequestAction.type",
    onSuccess: "updateHomeScreenNotifications__SuccessAction.type",
    onError: "updateHomeScreenNotifications__FailureAction.type",
  });
}


export const authenticateGuestUser = (data: any) =>
  createApiAction({
    onStart: authenticateGuestUserRequestAction.type,
    onSuccess: authenticateGuestUserSuccessAction.type,
    onError: authenticateGuestUserFailureAction.type,
    method: "POST",
    data,
    url: `/user/guest-authenticate`,
    axiosRequired: true,
  });

export const getUserBasicDetails = (data: any) => createApiAction({
  axiosRequired: true,
  method: "POST",
  url: `/user/basic-details`,
  data,
  onStart: getUserBasicDetails__RequestAction.type,
  onSuccess: getUserBasicDetails__SuccessAction.type,
  onError: getUserBasicDetails__FailureAction.type,
});

export const getMeetingDetails = (meetingId: string) => createApiAction({
  axiosRequired: true,
  method: "GET",
  url: `/meeting/${meetingId}/details`,
  onStart: getMeetingDetails__RequestAction.type,
  onSuccess: getMeetingDetails__SuccessAction.type,
  onError: getMeetingDetails__FailureAction.type,
});