import { RadioGroup, Stack, Modal, Box } from "@mui/material";
import React, { useState } from "react";
import { BalancesIcon } from "../../../../../../../images";
import {
  CustomRadioFormControlLabel,
  DatePickerWithIcon,
  TextLinkButton,
} from "../../../../../../common";
import { UserInfoChip } from "../../../../../Email/components";
import { adjustHexColor } from "../../../../../../../Utils";
import { SearchBarUsersTrax } from "../../../SearchBarUsersTrax";

interface RequestTypeContainerViewProps {}

const RequestTypeContainerView: React.FC<
  RequestTypeContainerViewProps
> = () => {
  const [requestType, setRequestType] = useState("self");
  const [selectedUser, setSelectedUser] = useState<any>("selected user data");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Stack
      sx={{
        border: "2px solid #E9EDF2",
        height: "75px",
        width: "100%",
        borderRadius: "5px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        boxSizing: "border-box",
        flexShrink: 0,
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <BalancesIcon w={25} />
        <DatePickerWithIcon
          hideIcon
          sx={{
            font: "normal normal 900 12px/15px Source Serif Pro",
            width: "60px",
          }}
        />
      </Stack>

      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "15px" }}>
        <Stack>
          {requestType === "self" ? (
            <UserInfoChip
              popoverPlace="top"
              userEmail="user email"
              userName="user name"
              chipHeight="30px"
              popoverZindex={1212}
            />
          ) : selectedUser ? (
            <UserInfoChip
              popoverPlace="top"
              userEmail="user email"
              userName="user name"
              chipHeight="30px"
              popoverZindex={1212}
              closeButtonClick={() => setSelectedUser(null)}
            />
          ) : (
            <TextLinkButton
              buttonLabel="Select a user"
              onClick={handleOpenModal}
            />
          )}
        </Stack>

        <RadioGroup
          aria-labelledby="create-Day-off-request-menu"
          name="create-Day-off-request-menu"
          value={requestType}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setRequestType(event.target.value)
          }
          sx={{ flexDirection: "row", gap: "10px" }}
        >
          <CustomRadioFormControlLabel
            sx={{ gap: "5px" }}
            value="self"
            label="Self"
          />
          <CustomRadioFormControlLabel
            sx={{ gap: "5px" }}
            value="other"
            label="Other"
          />
        </RadioGroup>
      </Stack>

      {/* Modal */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        BackdropProps={{
          sx: {
            backgroundColor: adjustHexColor("#000000", 20),
            border: "0px",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minHeight: "462px",
          }}
        >
          <SearchBarUsersTrax />
        </Box>
      </Modal>
    </Stack>
  );
};

export default RequestTypeContainerView;
