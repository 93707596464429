import { RadioGroup, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { HeaderView, ScopeVisibilityView, StartAndEndTimeView } from "./view";
import { IconContainer, ShiftTypeContainerView } from "../CreateShiftMenu/view";
import {
  ButtonSmall,
  CustomRadioFormControlLabel,
  DatePickerWithIcon,
  EditableTextArea,
  SearchFoldersDropdown,
  SmallButtonColor,
  TextLinkButton,
} from "../../../../common";
import {
  AttechIcon,
  EyeIcon,
  NotesLineIcon,
  PositionTypeIcon,
  TotalhoursIcon,
  TrackedIcon,
  TravelIcon,
} from "../../../../../images";
import { EventTypeDropdown } from "../EventTypeDropdown";
import { FrequencyDropdown } from "../FrequencyDropdown";

interface NewEventOrBreakMenuProps {}

const NewEventOrBreakMenu: React.FC<NewEventOrBreakMenuProps> = () => {
  const [breakType, setBreakType] = useState("event");
  return (
    <Stack sx={{ width: "550px", height: "100%", bgcolor: "white" }}>
      {/* card header */}
      <HeaderView
        onCloseClick={() => null}
        onViewEmployeeCardClick={() => null}
      />

      {/* ---- card body ---- */}
      <Stack
        sx={{
          flex: 1,
          overflow: "auto",
          scrollbarWidth: "none",
          padding: "20px",
          gap: "20px",
        }}
      >
        <ShiftTypeContainerView
          sx={{ mb: "15px" }}
          icon={TravelIcon}
          titleComponent={
            <DatePickerWithIcon
              hideIcon
              sx={{
                font: "normal normal 900 12px/15px Source Serif Pro",
                width: "60px",
              }}
            />
          }
        >
          <RadioGroup
            aria-labelledby="create-shift-menu"
            name="create-shift-menu"
            value={breakType}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setBreakType(event.target.value)
            }
            sx={{ flexDirection: "row", gap: "10px" }}
          >
            <CustomRadioFormControlLabel
              sx={{ gap: "5px" }}
              value="event"
              label="Event"
            />
            <CustomRadioFormControlLabel
              sx={{ gap: "5px" }}
              value="other"
              label="Other"
            />
          </RadioGroup>
        </ShiftTypeContainerView>

        {/* ----- Job Role/Position Area ------ */}
        <IconContainer icon={PositionTypeIcon} iconSize={18} title="Event Type">
          <Stack sx={{ gap: "5px", alignItems: "end" }}>
            <EventTypeDropdown />
            <TextLinkButton
              buttonLabel="Create new type"
              sx={{ width: "fit-content" }}
            />
          </Stack>
        </IconContainer>

        {/* ----- Start & End Time Area ------ */}
        <IconContainer icon={TrackedIcon} title="Start & End Time">
          <StartAndEndTimeView />
        </IconContainer>

        {/* ----- Frequency Area ------ */}
        <IconContainer icon={TotalhoursIcon} iconSize={18} title="Frequency">
          <Stack sx={{ gap: "5px", alignItems: "end" }}>
            <FrequencyDropdown />
            <TextLinkButton
              buttonLabel="Add end on date"
              sx={{ width: "fit-content" }}
            />
          </Stack>
        </IconContainer>

        {/* ----- Visibility Area ------ */}
        <IconContainer icon={EyeIcon} title="Visibility">
          <ScopeVisibilityView />
        </IconContainer>

        {/* ----- Attachment Area ------ */}
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            mt: "10px",
          }}
        >
          <AttechIcon w={15} />
          <Typography
            sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
          >
            Attachements
          </Typography>
        </Stack>
        <Stack sx={{ width: "455px" }}>
          <SearchFoldersDropdown
            showFilterMenu
            onSelect={() => null}
            options={[]}
          />
        </Stack>

        {/* -------  Notes area  ----- */}
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <NotesLineIcon w={15} />
          <Typography
            sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
          >
            Notes
          </Typography>
        </Stack>

        <EditableTextArea
          minRows={4}
          maxRows={5}
          style={{
            font: "normal normal 300 13px/16px Source Serif Pro",
            border: "1px solid #E9EDF2",
            marginTop: "-10px",
          }}
        />

        {/* ----------------------------------- */}
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            gap: "10px",
          }}
        >
          <ButtonSmall
            sx={{ padding: "0px 10px" }}
            label="Save & Create Template"
            colorVarient={SmallButtonColor.Transparent}
          />
          <ButtonSmall
            label="Save"
            colorVarient={SmallButtonColor.TraxBluish}
          />
        </Stack>
        {/* ----------------------------------- */}
      </Stack>
    </Stack>
  );
};

export default NewEventOrBreakMenu;
