import { Stack, Typography } from "@mui/material";
import React from "react";

interface InfoViewProps {
  title: string;
  value: string;
}

const InfoView: React.FC<InfoViewProps> = ({ title, value }) => {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
      <Typography
        sx={{
          width: "95px",
          font: "normal normal 400 12px/15px Source Serif Pro",
          textAlign: "start",
          flexShrink: 0,
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          width: "95px",
          font: "normal normal 300 12px/15px Source Serif Pro",
          textAlign: "start",
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

export default InfoView;
