import { Box, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import g from "./insideFile.module.css";
import { RightSidePanel } from "./Components";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  changeLayoutState,
  getOneFile__api,
  getOneFile__BOX__api,
} from "../../../../redux";
import { DocumentViewer, ImageViewer, PdfViewer, VideoPlayer } from "./view";
import { CodeViewer } from "./view/CodeViewer";
import { AudioPlayer } from "./view/AudioPlayer";
import { TextFileViewer } from "./view/TextFileViewer";
import { ArchiveViewer } from "./view/ArchiveViewer";

const InsideFileCloudStorage = () => {
  const dispatch = useDispatch();
  const { fileId, fileType } = useParams<{
    fileId?: string;
    fileType?: string;
  }>();

  const [rightPanelOpen, setRightPanelOpen] = useState<boolean>(false);
  const [rightPanelComponent, setRightPanelComponent] = useState<string | "">(
    ""
  );

  const [file__loader, setFile__loader] = useState(true);

  const handleGetRPC = (cName: string) => {
    if (cName === "" || !cName) {
      setRightPanelComponent("");
    } else {
      setRightPanelComponent(cName);
    }
  };
  const [fileData, setFileData] = useState<any | null>(null); // Use proper typing
  useEffect(() => {
    const fetchFileData = async () => {
      if (!fileId || !fileType) return;
      const params = new URLSearchParams(window.location.search);
      const provider = params.get("provider");

      setFile__loader(true);

      try {
        let action;

        // Conditional API call based on provider
        if (provider === "Box") {
          action = getOneFile__BOX__api(fileId);
        } else {
          action = getOneFile__api(fileId);
        }
        const fileResponse: any = await dispatch(action);

        if (fileResponse?.success && fileResponse?.data) {
          setFileData(fileResponse.data);
        } else {
          setFileData(null); // Reset to null if not found
        }
      } catch (error) {
        console.error("Error fetching file data:", error);
        setFileData(null); // Reset to null on error
      } finally {
        setFile__loader(false);
      }
    };

    fetchFileData();

    dispatch(
      changeLayoutState({
        type: "isDrawer_CloudStorage",
        value: false,
      })
    );
  }, [dispatch, fileId, fileType]);

  const renderFileComponent = (rightPanelOpen: any) => {
    if (
      !fileData ||
      (typeof fileData !== "object" && !fileData.path && !fileData.name)
    ) {
      return <div>Invalid file data</div>;
    }

    const getExtension = (
      filePath?: string,
      fileName?: string
    ): string | null => {
      const combinedPath = filePath || fileName || "";
      // Remove query params and fragments (e.g., "file.tar.gz?version=2#section")
      const sanitizedPath = combinedPath.split(/[?#]/)[0];

      const match = sanitizedPath.match(/\.([a-zA-Z0-9]+)$/);
      if (!match && fileName) {
        const fileNameMatch = fileName.match(/\.([a-zA-Z0-9]+)$/);
        return fileNameMatch ? fileNameMatch[1].toLowerCase() : null;
      }

      return match ? match[1].toLowerCase() : null;
    };

    const extension = getExtension(fileData?.path, fileData?.name);
    const path = fileData?.path;

    if (!extension) {
      return (
        <div>
          Unable to determine the file type. File path:{" "}
          <code>{fileData?.path || fileData?.name}</code>
        </div>
      );
    }

    switch (extension) {
      // Image file types
      case "jpg":
      case "jpeg":
      case "png":
      case "webp":
      case "gif":
      case "bmp":
      case "tiff":
      case "svg":
        return <ImageViewer path={path} />;

      // Video file types
      case "mp4":
      case "mov":
      case "webm":
      case "avi":
      case "mkv":
      case "flv":
      case "wmv":
      case "mpeg":
      case "mpg":
        return <VideoPlayer path={path} />;

      // PDF files
      case "pdf":
        return <PdfViewer rightPanelOpen={rightPanelOpen} path={path} />;

      // Word and Excel files (Office documents)
      case "doc":
      case "docx":
      case "xls":
      case "xlsx":
      case "ppt":
      case "pptx":
        return <DocumentViewer path={path} />;

      // Text files
      case "txt":
      case "md": // Markdown files
        return <TextFileViewer path={path} />;

      // Archive files (ZIP, RAR, etc.)
      case "zip":
      case "rar":
      case "tar":
      case "gz":
      case "7z":
        return <ArchiveViewer path={path} />;

      // Audio files
      case "mp3":
      case "wav":
      case "ogg":
      case "aac":
      case "flac":
      case "m4a":
        return <AudioPlayer path={path} />;

      // Code files (e.g., for code editing)
      case "js":
      case "ts":
      case "html":
      case "css":
      case "json":
      case "xml":
      case "py":
      case "java":
      case "cpp":
      case "rb":
      case "go":
        return <CodeViewer path={path} />;

      // Unsupported file type
      default:
        return (
          <div>
            Unsupported file type: <code>{extension}</code> for file{" "}
            <code>{fileData?.path || fileData?.name}</code>
          </div>
        );
    }
  };

  return (
    <Box
      sx={{
        justifyContent: rightPanelOpen ? "flex-start" : "center",
      }}
      className={g.insideFile__mainContainer}
    >
      <Stack
        width={rightPanelOpen ? "65%" : "85%"}
        ml={rightPanelOpen ? "15px" : "0px"}
        height={"96vh"}
        bgcolor={"white"}
      >
  
        {file__loader ? "Loading..." : renderFileComponent(rightPanelOpen)}
      </Stack>

      {/* <Box
        sx={{
          position: "fixed",
          bottom: "25px",
          left: rightPanelOpen ? "20%" : "unset",
        }}
      >
        <BottomSidePanel fileType={fileType} />
      </Box> */}

      <Box
        sx={{ position: "fixed", right: "5px", height: "100%", top: "49px" }}
      >
        <RightSidePanel
          fileData={fileData}
          rightPanelOpen={rightPanelOpen}
          rightPanelComponent={rightPanelComponent}
          handleGetRPC={handleGetRPC}
          setRightPanelOpen={setRightPanelOpen}
        />
      </Box>
    </Box>
  );
};

export default InsideFileCloudStorage;
