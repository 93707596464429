import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { FolderIcon } from "../../../../../../images";
import { ShareButton } from "../../../../Buttons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import { CloudStorageState } from "../../../../../../redux";
import { formatDate, getUser } from "../../../../../../Utils";

interface CloudStorageComponentProps {
  folderId: string;
  fileId?: string;
  SharedfileId?: string;
}

const CloudStorageRoute: React.FC<CloudStorageComponentProps> = ({
  folderId,
  fileId,
  SharedfileId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const cloudStorageState = useSelector(
    (state: RootState) => state?.cloudStorage as CloudStorageState
  );
  const searchParams = new URLSearchParams(location?.search);
  const dir = searchParams?.get("dir"); // Extract 'dir' if it exists
  const { selectedFileData = {} } = cloudStorageState || {};

  const handleNavigate = () => {
    // Get the current search params (query string)
    const searchParams = new URLSearchParams(location?.search);
    const dir = searchParams?.get("dir"); // Extract 'dir' if it exists

    // If `dir` exists, include it in the URL
    if (dir) {
      navigate(`/cloud-storage/?dir=${encodeURIComponent(dir)}`);
    } else {
      navigate(`/cloud-storage/`); // Default navigation to cloud storage
    }
  };
  if (!folderId || !fileId) return null;

  const user = getUser();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Stack
        mt={"5px"}
        gap={2}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Stack
          width={"35px"}
          height={"35px"}
          bgcolor={"#FF9100"}
          alignContent={"center"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={"5px"}
        >
          <Typography
            sx={{
              font: "normal normal bold 24px/27px Source Serif Pro",
              color: "white",
            }}
          >
            V1
          </Typography>
        </Stack>

        <Stack direction={"column"} gap={0}>
          <Stack>
            {" "}
            <Typography
              sx={{ font: "normal normal 15px/17px Source Serif Pro" }}
            >
              {selectedFileData?.originalName}
            </Typography>{" "}
          </Stack>

          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={0.6}
          >
            <FolderIcon color="#00A1E0" w={15} />
            {!SharedfileId && (
              <Typography
                onClick={() => handleNavigate()}
                sx={{ font: "normal normal 11px/13px Source Serif Pro" }}
              >
                { dir !== "" ? dir : "All files"}
              </Typography>
            )}{" "}
            <Stack mt={"-4px"} sx={{ opacity: 0.5 }}>
              |
            </Stack>
            <Typography
              sx={{ font: "normal normal 11px/13px Source Serif Pro" }}
            >
              {/* Not Getting any data about who updated last time */}
              Updated on {formatDate(selectedFileData?.updatedAt)} by{" "}
              {user?.data?.firstName + " " + user?.data?.lastName}
            </Typography>{" "}
          </Stack>
        </Stack>
      </Stack>

      <Stack
        gap={2}
        alignItems={"center"}
        justifyContent={"flex-start"}
        direction={"row"}
      >
        <ShareButton />
        <Stack
          sx={{
            width: "1.5px",
            height: "30px",
            background: "#E9EDF2",
            opacity: 1,
          }}
          mt={"3px"}
        ></Stack>
      </Stack>
    </Box>
  );
};

export default CloudStorageRoute;
