import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, IconButton, Paper, Popover, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";
import {CameraIcon, FileIcon, MicIcon, SquareFunnyEmojiIcon} from "../../../../../images";
import {MESSAGE_ENUMS} from "../../constant";
import {
    markMessagesAsRead,
    scheduleMessage,
    sendMessages,
    updateMessage
} from "../../../../../redux/Slice/message/messageActions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import {MessagesState} from "../../../../../redux/Slice/message/messageTypes";
import ScheduleVideoMeetingBar from "../../../../common/ReplyBar/ScheduleVideoMeetingBar/ScheduleVideoMeetingBar";
import ScheduleEventBar from "../../../../common/ReplyBar/ScheduleEventBar/ScheduleEventBar";
import {EmojiPickerSmallButton, MessageReplyBar, TaskReplyBar} from "../../../../common";
import {ScheduleMessage} from "../ScheduleMessage";
import dayjs from "dayjs";
import {AuthState} from "../../../../../redux";
import {MentionMenu} from "../MentionMenu";
import {FileUploadMenu} from "../FileUploadMenu";
import {AttachmentView} from "../../common/MsgPreview/view";
import {useParams} from "react-router-dom";
import CustomRichTextDecoration from "../CustomRichTextDecoration/CustomRichTextDecoration";
import {formatHtmlContentToSlackContent} from "../../../../../Utils/MessageCenterUtiles";


interface CustomRichTextEditorProps {
    actionParameter: any;
    handleResetActionParameter?: any;
}

type FileType = 'folder' | 'file' | 'image' | 'video' | 'audio' | 'link'

const CustomRichTextEditor: React.FC<CustomRichTextEditorProps> = ({actionParameter, handleResetActionParameter}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [mentionAnchorEl, setMentionAnchorEl] = useState<HTMLTextAreaElement | null>(null);
    const [fileUploadMenuAnchorEl, setFileUploadMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isMentionMenuOpen, setIsMentionMenuOpen] = useState(false);
    const [isFileUploadMenuOpen, setIsFileUploadMenuOpen] = useState(false);
    const editorRef = useRef<HTMLDivElement | null>(null);
    const {chatTabState} = useSelector((state: RootState) => state.message as MessagesState);
    const {user} = useSelector((state: RootState) => state.auth as AuthState);
    const [messageText, setMessageText] = useState<string>("");
    const [openSchedule, setOpenSchedule] = useState<boolean>(false);
    const [isEditMessage, setIsEditMessage] = useState<boolean>(false);
    const [isReplayMessageBarActive, setIsReplayMessageBarActive] = useState<boolean>(false);
    const [isScheduleVideoMeetingBarActive, setIsScheduleVideoMeetingBarActive] = useState<boolean>(false);
    const [isEventBarActive, setIsEventBarActive] = useState<boolean>(false);
    const [isScheduleEventBarActive, setIsScheduleEventBarActive] = useState<boolean>(false);
    const [isTaskBarActive, setIsTaskBarActive] = useState<boolean>(false);
    const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
    const [selectedUser, setSelectedUser] = useState<string | null>(null);
    const [selectedCommand, setSelectedCommand] = useState<string | null>(null);
    const [activeStyles, setActiveStyles] = useState<{ [key: string]: boolean }>({
        bold: false,
        italic: false,
        underline: false,
    });
    const [isEmpty, setIsEmpty] = useState(messageText === '' || messageText === '<br>');
    console.log("isEmpty",isEmpty);
    const param = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const handleInputChange = (event: React.FormEvent<HTMLDivElement>) => {
        const content = event.currentTarget.innerHTML;
        setMessageText(content);
    };

    const handleFocus = () => {
        setIsEmpty(false);
    }

    const handleBlur = () => {
        if (messageText === '' || messageText === '<br>') setIsEmpty(true);
    };

    const handlePopupToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setIsPopupOpen(!isPopupOpen);
    };

    const handlePopupClose = () => {
        setAnchorEl(null);
        setIsPopupOpen(false);
    };

    const applyStyle = (command: any) => {
        const selection = window.getSelection();
        if (!selection || selection.rangeCount === 0) return;

        const range = selection.getRangeAt(0);
        const parentElement = range.commonAncestorContainer.parentElement;

        if (parentElement && parentElement.nodeName === 'SPAN' && parentElement.style[command]) {
            document.execCommand(`removeFormat`, false, command);
        } else {
            document.execCommand(command, false, '');
        }

        setActiveStyles((prevStyles) => ({
            ...prevStyles,
            [command]: !prevStyles[command],
        }));
    };

    const handleOpenSchedulePopup = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenSchedule(true);
    };

    const handleCloseSchedulePopup = () => setOpenSchedule(false);

    const handleResetMessageBar = () => {
        setMessageText("");
        if (editorRef.current) editorRef.current.innerHTML = "";
        setFilesToUpload([]);
        setIsEditMessage(false);
        setIsScheduleVideoMeetingBarActive(false);
        setIsEventBarActive(false);
        handleCancelReplayMenu();
        handleCloseSchedulePopup();
        handleResetActionParameter();
    };

    const handleFilesUpload = (uploadedFiles: any[]) => {
        setFilesToUpload(uploadedFiles);

        if (uploadedFiles.length > 0) {
            setIsEventBarActive(true);
        }
    }

    const handleSendAndEditMessage = async () => {

        const slackFormatContent = formatHtmlContentToSlackContent(messageText)

        const content = chatTabState?.selectedConversation?.conversationPlatform === MESSAGE_ENUMS.SLACK ? slackFormatContent : messageText.trim();
        const channelId = chatTabState?.selectedConversation?.conversationId;
        const channelType = chatTabState?.selectedConversation?.conversationPlatform || MESSAGE_ENUMS.BEARISH;
        const teamId = chatTabState?.selectedConversation?.conversationDetails?.teamId;

        if (isEditMessage) {
            const editedMessageContent = {
                content,
                channelId,
                slackMessageTimeStamp: actionParameter.messageId,
                channelType,
                teamId,
                attachments: filesToUpload,
            };
            await dispatch(updateMessage(actionParameter.messageId, editedMessageContent));
        } else {
            const payload = {
                content,
                channelId,
                replyTo: actionParameter.messageId || null,
                channelType,
                teamId,
                attachedFile: filesToUpload,
            };
            await dispatch(sendMessages(payload));
        }
        if (chatTabState?.selectedConversation?.conversationPlatform === "BEARISH") {
            dispatch(markMessagesAsRead(chatTabState?.selectedConversation?.conversationId));
        }
        handleResetMessageBar();
    };

    const handleScheduleMessage = async (dateAndTime: any) => {
        if (!messageText) {
            setOpenSchedule(false);
            return;
        }
        const formattedDateTime = dayjs(dateAndTime).second(0).toISOString();
        const payload = {
            scheduledAt: formattedDateTime,
            userId: user?.data._id,
            content: messageText,
            channelId: chatTabState?.selectedConversation?.conversationId,
            channelType: chatTabState?.selectedConversation?.conversationPlatform,
        };
        await dispatch(scheduleMessage(payload));
        setOpenSchedule(false);
        handleResetMessageBar();
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && event.shiftKey) {
            document.execCommand('insertLineBreak');
            event.preventDefault();
        } else if (event.key === 'Enter') {
            event.preventDefault();
            handleSendAndEditMessage();
        } else if (event.key === '@') {
            setMentionAnchorEl(event.currentTarget as unknown as HTMLTextAreaElement);
            setIsMentionMenuOpen(true);
        }
    };

    const handleCancelReplayMenu = () => {
        setIsReplayMessageBarActive(false);
        handleResetActionParameter();
        setIsEventBarActive(false);
    };

    const handleCancelScheduleVideoMenu = () => {
        setIsScheduleVideoMeetingBarActive(false);
        clearBarData();
        setIsEventBarActive(false);
    };

    const clearBarData = () => {
        setSelectedUser("");
        setSelectedCommand("");
    };

    const handleCancelScheduleEventMenu = () => {
        setIsScheduleEventBarActive(false);
        clearBarData();
        setIsEventBarActive(false);
    };

    const handleCancelTaskMenu = () => {
        setIsTaskBarActive(false);
        clearBarData();
        setIsEventBarActive(false);
    };

    const handleCancelEditMessage = () => {
        setIsEditMessage(false);
        setMessageText("");
        handleResetActionParameter();
        if (editorRef.current) editorRef.current.innerHTML = "";
    };

    const handleLinkClick = () => {
        // const url = prompt('Enter the URL');
        // document.execCommand('createLink', false, url);
    }

    const handleEmojiSelect = (emoji: any) => {
        setMessageText((prevMessage) => prevMessage + emoji.native);
        if (editorRef.current) editorRef.current.innerHTML = messageText + emoji.native;
    };

    const handleFileIconClick = (event: any) => {
        setIsFileUploadMenuOpen(true);
        setFileUploadMenuAnchorEl(event.currentTarget);
    };

    const handleCloseFileUploadMenu = () => {
        setIsFileUploadMenuOpen(false);
        setFileUploadMenuAnchorEl(null);
    };

    const handleMentionSelection = (user: string, command: string) => {
        setSelectedUser(user);
        setSelectedCommand(command);
        setIsMentionMenuOpen(false);
        switch (command) {
            case "task":
                setIsTaskBarActive(true);
                setIsEventBarActive(true);
                break;
            case "event":
                setIsScheduleEventBarActive(true);
                setIsEventBarActive(true);
                break;
            case "call":
                setIsScheduleVideoMeetingBarActive(true);
                setIsEventBarActive(true);
                break;
        }
    };

    useEffect(() => {
        if (actionParameter.selectedOption === "Edit") {
            const message = chatTabState?.conversationChats[actionParameter.messageId];
            setIsEditMessage(true);
            setMessageText(message?.content);
            if (editorRef.current) editorRef.current.innerHTML = message?.content;
        }
        if (actionParameter.selectedOption === "Reply") {
            setIsReplayMessageBarActive(true);
            setIsEventBarActive(true);
        }
    }, [actionParameter]);

    useEffect(() => {
        if (messageText === '' || messageText === "<br>") setIsEmpty(true);
    }, [messageText]);


    useEffect(() => {
        if (param.id) {
            handleResetMessageBar();
        }
        setIsEmpty(false);
        setTimeout(() => {
            setIsEmpty(true);
        }, 0);
    }, [param.id]);

    return (
        <Box sx={{
            fontFamily: 'Arial, sans-serif',
            padding: "5px 10px 15px 10px"
        }}>
            <Paper
                elevation={2}
                sx={{
                    display: 'flex',
                    padding: '10px 10px 5px 10px',
                    borderRadius: '5px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)',
                    flexDirection: 'Column',
                    height: 'auto',
                }}
            >
                {isEventBarActive && (
                    <Box sx={{
                        flex: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                        paddingTop: '3px',
                        marginBottom: "15px"
                    }}>
                        {isScheduleVideoMeetingBarActive &&
                            <ScheduleVideoMeetingBar selectedUser={selectedUser}
                                                     onCloseClick={() => handleCancelScheduleVideoMenu()}/>}
                        {isScheduleEventBarActive &&
                            <ScheduleEventBar selectedUser={selectedUser}
                                              onCloseClick={() => handleCancelScheduleEventMenu()}/>}
                        {isTaskBarActive &&
                            <TaskReplyBar selectedUser={selectedUser} onCloseClick={() => handleCancelTaskMenu()}/>}
                        {isReplayMessageBarActive && (
                            <MessageReplyBar
                                replayMessage={chatTabState?.conversationChats[actionParameter.messageId]}
                                onCloseClick={() => handleCancelReplayMenu()}
                            />
                        )}
                        {filesToUpload?.length > 0 && <AttachmentView attachmentList={filesToUpload?.map((file: any) => ({
                            fileId: file.fileId, // or any unique identifier
                            fileType: file.fileType as FileType, // Ensure fileType is of type FileType
                            fileUrl: file.fileUrl,
                            fileName: file.fileName
                        }))}/>}
                    </Box>
                )
                }

                <Box style={{display: 'flex', flexDirection: 'row'}}>
                    <Box sx={{flex: 4, display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        {isEmpty ?
                            <div
                            contentEditable
                            suppressContentEditableWarning
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                            style={{
                                flex: 1,
                                outline: 'none',
                                padding: '0px 0 8px 0',
                                width: '100%',
                                border: 'none',
                                resize: 'none',
                                font: "normal normal 300 13px/16.29px Source Serif Pro",
                                color: "#B0B0B0"
                            }}
                        >{"Type your text messages here"}</div> :
                            <div
                            ref={editorRef}
                            contentEditable
                            suppressContentEditableWarning
                            onInput={handleInputChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                            defaultValue={'Type your text messages here'}
                            style={{
                                flex: 1,
                                outline: 'none',
                                padding: '0px 0 8px 0',
                                width: '100%',
                                border: 'none',
                                resize: 'none',
                                font: "normal normal 300 13px/16.29px Source Serif Pro",
                            }}
                        />}
                        <Box sx={{flex: 4, display: 'flex', gap: '10px', alignItems: "end"}}>
                            <IconButton style={{borderRadius: "5px", padding: "0px", height: "25px", width: "25px",paddingTop:"4px"}}
                                        sx={{
                                            "&:hover": {
                                                backgroundColor: "rgba(119, 94, 226, 0.25)",
                                            },
                                        }}>
                                <EmojiPickerSmallButton
                                    w={20}
                                    sx={{margin: "0px"}}
                                    onSelect={handleEmojiSelect}
                                    tooltipTitle={t('MESSAGE.ADD_EMOJI')}
                                    componentIcon={SquareFunnyEmojiIcon}
                                />
                            </IconButton>

                            <IconButton style={{borderRadius: "5px", padding: "0px", height: "25px", width: "25px"}}
                                        onMouseDown={handlePopupToggle}
                                        sx={{
                                            "&:hover": {
                                                backgroundColor: "rgba(119, 94, 226, 0.25)",
                                            },
                                        }}>
                                <Typography variant="h6">A</Typography>
                            </IconButton>
                            <IconButton style={{borderRadius: "5px", padding: "0px", height: "25px", width: "25px"}}
                                        onClick={(e) => handleFileIconClick(e)} sx={{
                                "&:hover": {
                                    backgroundColor: "rgba(119, 94, 226, 0.25)",
                                },
                            }}>
                                <FileIcon w={20}/>
                            </IconButton>
                            <IconButton style={{borderRadius: "5px", padding: "0px", height: "25px", width: "25px"}}
                                        sx={{
                                            "&:hover": {
                                                backgroundColor: "rgba(119, 94, 226, 0.25)",
                                            },
                                        }}>
                                <CameraIcon w={20}/>
                            </IconButton>
                            <IconButton style={{borderRadius: "5px", padding: "0px", height: "25px", width: "25px"}}
                                        sx={{
                                            "&:hover": {
                                                backgroundColor: "rgba(119, 94, 226, 0.25)",
                                            },
                                        }}>
                                <MicIcon w={20}/>
                            </IconButton>
                            {/*<FontDecoration />*/}

                        </Box>
                    </Box>

                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        justifyContent: 'end',
                        alignItems: 'center'
                    }}>
                        {
                            isEditMessage ? (
                                <Button
                                    variant="outlined"
                                    style={{
                                        borderColor: "#D9E3EF",
                                        color: "black",
                                        textTransform: "none",
                                        width: "75px",
                                        height: "30px"
                                    }}
                                    onClick={handleCancelEditMessage}
                                >
                                    <Typography sx={{font: "normal normal 400 13px/16.29px Source Serif Pro"}}>
                                        {t('MESSAGE.CANCEL')}
                                    </Typography>
                                </Button>
                            ) : (
                                <Button
                                    variant="outlined"
                                    style={{
                                        borderColor: "#D9E3EF",
                                        color: "black",
                                        textTransform: "none",
                                        width: "75px",
                                        height: "30px"
                                    }}
                                    disabled={!messageText}
                                    onClick={handleOpenSchedulePopup}
                                >
                                    <Typography sx={{font: "normal normal 400 13px/16.29px Source Serif Pro"}}>
                                        {t('MESSAGE.SCHEDULE')}
                                    </Typography>
                                </Button>
                            )
                        }

                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                marginLeft: "8px",
                                color: "white",
                                backgroundColor: "#775EE2",
                                textTransform: "none",
                                width: "75px",
                                height: "30px"
                            }}
                            onClick={handleSendAndEditMessage}
                            disabled={!messageText}
                        >
                            <Typography sx={{font: "normal normal 400 13px/16.29px Source Serif Pro"}}>
                                {isEditMessage ? t('MESSAGE.UPDATE') : t('MESSAGE.SEND')}
                            </Typography>
                        </Button>
                    </Box>

                </Box>
            </Paper>

            <Popover
                open={isPopupOpen}
                anchorEl={anchorEl}
                onClose={handlePopupClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <CustomRichTextDecoration
                    onBoldClick={() => applyStyle('bold')}
                    onItalicClick={() => applyStyle('italic')}
                    onUnderlineClick={() => applyStyle('underline')}
                    onLinkClick={handleLinkClick}
                    onImageClick={handleFileIconClick}
                />
            </Popover>


            <Popover
                id={anchorEl ? 'simple-popover' : undefined}
                open={openSchedule}
                anchorEl={anchorEl}
                onClose={handleCloseSchedulePopup}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <ScheduleMessage
                    onSchedule={handleScheduleMessage}
                />
            </Popover>

            <Popover
                open={isMentionMenuOpen}
                anchorEl={mentionAnchorEl}
                onClose={() => setIsMentionMenuOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MentionMenu onSelectionComplete={handleMentionSelection}/>
            </Popover>
            <Popover
                open={isFileUploadMenuOpen}
                anchorEl={fileUploadMenuAnchorEl}
                onClose={handleCloseFileUploadMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <FileUploadMenu onFilesUpload={handleFilesUpload}
                                handleCloseFileUploadMenu={handleCloseFileUploadMenu}/>
            </Popover>
        </Box>
    );
};

export default CustomRichTextEditor;