import React, { lazy, Suspense, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { TraxTimeAttendancePageType } from "./TimeAttendanceType";
import { TabLoader } from "../../../common";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeLayoutState } from "../../../../redux";
import { RootState } from "../../../../redux/rootReducer";

const LazyPersonalTabPage = lazy(
  () => import("./components/PersonalTabPage/PersonalTabPage")
);
const LazyScheduleTabPage = lazy(
  () => import("./components/ScheduleTabPage/ScheduleTabPage")
);

const TimeAttendance: React.FC = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const drawerState = useSelector((state: RootState) => state && state.layout);
  const { isDrawer_Trax } = drawerState || {};

  const value: TraxTimeAttendancePageType | null = searchParams.get(
    "value"
  ) as TraxTimeAttendancePageType;

  const renderPage = (): React.ReactNode => {
    switch (value) {
      case "personal":
        return <LazyPersonalTabPage />;

      case "schedule":
      default:
        return <LazyScheduleTabPage />;
    }
  };

  const previousState = isDrawer_Trax;

  useEffect(() => {
    dispatch(
      changeLayoutState({
        type: "isDrawer_Trax",
        value: false,
      })
    );

    return () => {
      dispatch(
        changeLayoutState({
          type: "isDrawer_Trax",
          value: previousState,
        })
      );
    };
  }, []);

  return (
    <Stack sx={{ padding: "15px 25px 0px 25px" }}>
      <Suspense fallback={<TabLoader />}>
        <div>{renderPage()}</div>
      </Suspense>
    </Stack>
  );
};

export default TimeAttendance;
