import {
  CheckboxFilledIcon,
  DateIcon,
  NotesLineIcon,
  TotalhoursIcon,
  TrackedIcon,
  TravelIcon,
} from "../../../../../images";
import { WeekDaysList } from "../../../../../Utils";
import { UserInfoChipProps } from "../../../Email/components";
import { ShiftType } from "../AttendanceScheduleTable";
import { AttendanceStatusType } from "../CrmStatusBadge";
import { ExpenseStatusType } from "../TraxApprovedBadge/TraxApprovedBadge";

export const timeHeaderListArray: string[] = [
  "0AM",
  "1AM",
  "2AM",
  "3AM",
  "4AM",
  "5AM",
  "6AM",
  "7AM",
  "8AM",
  "9AM",
  "10AM",
  "11AM",
  "12PM",
  "1PM",
  "2PM",
  "3PM",
  "4PM",
  "5PM",
  "6PM",
  "7PM",
  "8PM",
  "9PM",
  "10PM",
  "11PM",
];

export const timeListTableRowsWidth: {
  DateWidth: number;
  timeAreaWidth: number;
  timeAreaNum: number;
  ApprovalWidth: number;
  totalColWidth: number;
  totalTimeAreaWidth: number;
} = {
  DateWidth: 100,
  timeAreaWidth: 50,
  timeAreaNum: 24,
  ApprovalWidth: 125,
  get totalColWidth() {
    return (
      this.DateWidth +
      this.timeAreaNum * this.timeAreaWidth +
      this.ApprovalWidth
    );
  },
  get totalTimeAreaWidth() {
    return this.timeAreaNum * this.timeAreaWidth;
  },
};

export const timeCardTableRowsWidth: {
  DateWidth: number;
  ShiftWidth: number;
  BreakWidth: number;
  HoursWidth: number;
  OvertWidth: number;
  NotesWidth: number;
  ApprovalWidth: number;
  totalColWidth: number;
} = {
  DateWidth: 140,
  ShiftWidth: 105,
  BreakWidth: 105,
  HoursWidth: 105,
  OvertWidth: 105,
  NotesWidth: 105,
  ApprovalWidth: 105,
  get totalColWidth() {
    return (
      this.DateWidth +
      this.ShiftWidth +
      this.BreakWidth +
      this.HoursWidth +
      this.OvertWidth +
      this.NotesWidth +
      this.ApprovalWidth
    );
  },
};

export type BreakListInterface = {
  _id: string;
  start: string;
  end: string;
};

// this interface is used for render data in table
export interface AttendanceListInterface {
  _id: string;
  date: string;
  day: WeekDaysList;
  shiftStartTime?: string;
  shiftEndTime?: string;
  workStartTime?: string;
  workStopTime?: string;
  breakList?: BreakListInterface[];
  overtime?: BreakListInterface;
  scheduleOrApprovedBy?: UserInfoChipProps;
  approvalDate?: string;
  approvalTime?: string;
  approvalStatus: ExpenseStatusType;
  attendanceType: AttendanceStatusType;
  shiftType?: ShiftType;
}

type TimeCardTableHeadersListType = {
  translatedLabel: string;
  icon: React.ElementType;
  iconColor?: string;
  colWidth: number;
};
export const TimeCardTableHeadersList: TimeCardTableHeadersListType[] = [
  {
    translatedLabel: "Date",
    icon: DateIcon,
    colWidth: timeCardTableRowsWidth.DateWidth,
  },
  {
    translatedLabel: "Shift",
    icon: TrackedIcon,
    colWidth: timeCardTableRowsWidth.ShiftWidth,
  },
  {
    translatedLabel: "Break",
    icon: TravelIcon,
    colWidth: timeCardTableRowsWidth.BreakWidth,
  },
  {
    translatedLabel: "Hours",
    icon: TrackedIcon,
    colWidth: timeCardTableRowsWidth.HoursWidth,
  },
  {
    translatedLabel: "Overtime",
    icon: TotalhoursIcon,
    colWidth: timeCardTableRowsWidth.OvertWidth,
  },
  {
    translatedLabel: "Note",
    icon: NotesLineIcon,
    colWidth: timeCardTableRowsWidth.NotesWidth,
  },
  {
    translatedLabel: "Approval",
    icon: CheckboxFilledIcon,
    colWidth: timeCardTableRowsWidth.ApprovalWidth,
  },
];

export const DummyAttendanceList: AttendanceListInterface[] = [
  {
    _id: "90fde1",
    day: "monday",
    date: "12-12-2023",
    workStartTime: "07:54:12",
    workStopTime: "18:12:48",
    breakList: [
      { _id: "001abc", start: "12:15:13", end: "13:14:41" },
      { _id: "002abc", start: "15:05:08", end: "15:28:56" },
    ],
    overtime: { _id: "aqws", start: "17:25:09", end: "18:12:48" },
    approvalStatus: "pending",
    attendanceType: "onTime",
  },
  {
    _id: "90fde2",
    day: "tuesday",
    date: "13-12-2023",
    // shiftStartTime: "08:00:00",
    // shiftEndTime: "17:00:00",
    workStartTime: "07:58:10",
    workStopTime: "17:06:12",
    breakList: [
      { _id: "001abcab", start: "12:25:04", end: "13:10:31" },
      { _id: "002abc", start: "15:10:06", end: "15:28:14" },
    ],
    overtime: { _id: "aqws", start: "17:01:09", end: "17:06:12" },
    scheduleOrApprovedBy: { userEmail: "abhi@mail", userName: "Abhi" },
    approvalStatus: "rejected",
    attendanceType: "onTime",
  },
  {
    _id: "90fde3",
    day: "wednesday",
    date: "14-12-2023",
    // shiftStartTime: "08:00:00",
    // shiftEndTime: "17:00:00",
    workStartTime: "08:15:10",
    workStopTime: "19:00:00",
    breakList: [
      { _id: "001abcab", start: "10:08:04", end: "10:42:31" },
      { _id: "002abc", start: "12:10:06", end: "15:28:14" },
      { _id: "002abc", start: "16:10:06", end: "16:28:14" },
    ],
    // overtime: { _id: "aqws", start: "17:01:09", end: "17:06:12" },
    approvalStatus: "pending",
    attendanceType: "late",
  },
  {
    _id: "90fde4",
    day: "thursday",
    date: "15-12-2023",
    // shiftStartTime: "08:00:00",
    // shiftEndTime: "17:00:00",
    workStartTime: "08:05:10",
    workStopTime: "19:50:12",
    breakList: [
      { _id: "001abcab", start: "11:08:04", end: "11:42:31" },
      { _id: "002abc", start: "15:10:06", end: "15:28:14" },
      { _id: "002abc", start: "17:10:21", end: "17:40:42" },
    ],
    approvalStatus: "approved",
    attendanceType: "late",
  },
  {
    _id: "90fde5",
    day: "friday",
    date: "16-12-2023",
    // shiftStartTime: "08:00:00",
    // shiftEndTime: "17:00:00",
    workStartTime: "08:05:10",
    workStopTime: "16:50:25",
    // breakList: [
    //   { _id: "001abcab", start: "12:01:04", end: "13:25:31" },
    //   { _id: "001abcab", start: "14:27:04", end: "14:58:31" },
    // ],
    overtime: { _id: "aqws", start: "16:01:09", end: "16:50:25" },
    approvalStatus: "pending",
    attendanceType: "onTime",
  },
  {
    _id: "90fde6",
    day: "saturday",
    date: "17-12-2023",
    // shiftStartTime: "08:00:00",
    // shiftEndTime: "12:00:00",
    workStartTime: "07:58:19",
    workStopTime: "13:55:10",
    approvalStatus: "pending",
    attendanceType: "onTime",
  },
  {
    _id: "90fde7",
    day: "sunday",
    date: "18-12-2023",
    scheduleOrApprovedBy: {
      userEmail: "userEmail@mail",
      userName: "name here",
    },
    approvalDate: "07-12-2023",
    approvalTime: "10:25:29",
    attendanceType: "dayoff",
    approvalStatus: "pending",
  },
  {
    _id: "90fde8",
    day: "monday",
    date: "19-12-2023",
    attendanceType: "absent",
    approvalStatus: "pending",
  },
  {
    _id: "90fde9",
    day: "tuesday",
    date: "20-12-2023",
    // shiftStartTime: "08:00:00",
    // shiftEndTime: "17:00:00",
    workStartTime: "07:45:17",
    workStopTime: "17:02:12",
    breakList: [
      { _id: "001abcab", start: "11:12:04", end: "11:58:31" },
      { _id: "002abc", start: "15:10:06", end: "15:30:14" },
    ],
    overtime: { _id: "aqws", start: "16:23:09", end: "17:02:12" },
    approvalStatus: "approved",
    attendanceType: "onTime",
  },
  {
    _id: "90fde10",
    day: "wednesday",
    date: "21-12-2023",
    approvalStatus: "rejected",
    attendanceType: "pto",
    scheduleOrApprovedBy: {
      userEmail: "user1@mail.com",
      userName: "person name",
    },
    approvalDate: "05-12-2023",
  },
  {
    _id: "90fde11",
    day: "thursday",
    date: "22-12-2023",
    approvalStatus: "approved",
    attendanceType: "absent",
  },
  {
    _id: "90fde12",
    day: "friday",
    date: "23-12-2023",
    // shiftStartTime: "08:00:00",
    // shiftEndTime: "17:00:00",
    workStartTime: "07:50:17",
    workStopTime: "19:50:12",
    breakList: [
      { _id: "001abcab", start: "11:12:04", end: "11:58:31" },
      { _id: "002abc", start: "15:10:06", end: "15:30:14" },
    ],
    overtime: { _id: "aqws", start: "17:01:09", end: "19:50:12" },
    approvalStatus: "pending",
    attendanceType: "onTime",
  },
  {
    _id: "90fde13",
    day: "saturday",
    date: "24-12-2023",
    // shiftStartTime: "08:00:00",
    // shiftEndTime: "12:00:00",
    workStartTime: "07:58:19",
    workStopTime: "11:55:10",
    breakList: [{ _id: "001abcab", start: "09:30:04", end: "09:50:31" }],
    approvalStatus: "pending",
    attendanceType: "onTime",
  },
  {
    _id: "90fde14",
    day: "sunday",
    date: "25-12-2023",
    approvalStatus: "approved",
    attendanceType: "holiday",
    scheduleOrApprovedBy: {
      userEmail: "userEmail@mail",
      userName: "name here",
    },
  },
  {
    _id: "90fde15",
    day: "monday",
    date: "26-12-2023",
    approvalStatus: "approved",
    attendanceType: "sick",
    scheduleOrApprovedBy: {
      userEmail: "userEmail@mail",
      userName: "name here",
    },
    approvalDate: "07-12-2023",
    approvalTime: "10:25:29",
  },
  {
    _id: "90fde16",
    day: "tuesday",
    date: "27-12-2023",
    approvalStatus: "approved",
    attendanceType: "absent",
  },
  {
    _id: "90fde17",
    day: "wednesday",
    date: "28-12-2023",
    approvalStatus: "approved",
    attendanceType: "pto",
    scheduleOrApprovedBy: {
      userEmail: "userEmail@mail",
      userName: "name here",
    },
    approvalDate: "07-12-2023",
    approvalTime: "10:25:29",
  },
  {
    _id: "90fde18",
    day: "thursday",
    date: "29-12-2023",
    approvalStatus: "approved",
    attendanceType: "holiday",
    scheduleOrApprovedBy: {
      userEmail: "userEmail@mail",
      userName: "name here",
    },
  },
  {
    _id: "90fde19",
    day: "friday",
    date: "30-12-2023",
    approvalStatus: "approved",
    attendanceType: "pto",
    scheduleOrApprovedBy: {
      userEmail: "userEmail@mail",
      userName: "name here",
    },
    approvalDate: "07-12-2023",
    approvalTime: "10:25:29",
  },
];
