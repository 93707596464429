import React, { useState, useRef } from "react";
import g from "./VerifyOpt.module.css";
import { IconButton, Stack, Typography } from "@mui/material";
import { CloseIcon } from "../../../images";
import { useTranslation } from "react-i18next";
import { ButtonXLarge } from "../Buttons";
import { ButtonType, ButtonXLargeColor } from "../Buttons/AllButtonProps";

type Props = {
  emails?: string;
  onClick: (type: number) => void;
  handleChangeSetOPT: (value: string) => void;
  opt?: string;
};

const STRING_CONSTANTS = [
  {
    id: "verify",
    str: "verify",
    font: "normal normal bold 40px/54px Source Serif Pro",
  },
  {
    id: "cst",
    str: "codeSendTo",
    font: "italic normal normal 15px/21px Source Serif Pro",
  },
];

const VerifyOpt: React.FC<Props> = ({
  emails = "johnsmith@bearishfh.com",
  onClick,
  handleChangeSetOPT,
  opt = "",
}) => {
  const { t } = useTranslation();

  const [otp, setOtp] = useState(Array(6).fill("")); // Initialize OTP with empty values
  const inputRefs = useRef<HTMLInputElement[]>([]); // Ref for each input element

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;

    if (/[^0-9]/.test(value)) return; // Only accept numbers

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    handleChangeSetOPT(newOtp.join(""));

    // Focus the next input field if the current one is filled
    if (newOtp[index] !== "" && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    // Move focus back if backspace is pressed and the current input is empty
    if (e.key === "Backspace" && otp[index] === "") {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    const paste = e.clipboardData.getData("Text");

    // Only allow paste if the input value is a 6-digit number
    if (/^\d{6}$/.test(paste)) {
      const newOtp = paste.split("").map((digit) => digit);
      setOtp(newOtp);
      handleChangeSetOPT(newOtp.join(""));

      // Focus on the last input box after pasting
      inputRefs.current[5]?.focus(); 
    } else {
      // If paste value is not valid OTP, just allow default paste behavior
      e.preventDefault();
    }
  };

  return (
    <>
      <Stack className={g.cVerifyOpt}>
        <Stack width={"100%"} alignItems={"flex-end"} justifyContent={"flex-end"}>
          <IconButton onClick={() => onClick(1)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack alignItems={"center"} justifyContent={"center"} width={"100%"} gap={"20px"}>
          {STRING_CONSTANTS.map((strings) => (
            <Typography sx={{ font: strings.font }} key={strings.id}>
              {t(strings.str)}
            </Typography>
          ))}
          <Typography sx={{ font: "normal normal 600 15px/21px Source Serif Pro", marginTop: "10px" }}>
            {emails}
          </Typography>
        </Stack>

        <form style={{ gap: "10px", padding: "0", width: "100%", display:"flex", alignItems:"center", flexDirection:"column"  }} autoComplete="off">
          <Stack direction="row" spacing={2} justifyContent="center" width="100%" m={'15px 0 20px 0'}>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                value={digit}
                onChange={(e) => handleOtpChange(e, index)}
                onKeyUp={(e) => handleKeyUp(e, index)}
                onPaste={(e) => handlePaste(e, index)}
                maxLength={1}
                className={g.otpInputBox}
                ref={(el) => (inputRefs.current[index] = el!)} // Assign each input to the ref array
                autoFocus={index === 0 && otp[index] === ""} // Auto-focus the first empty input box
              />
            ))}
          </Stack>

          <ButtonXLarge
            colorVarient={ButtonXLargeColor.Green}
            buttonSize="default"
            label={t("Continue")}
            fullwidth={true}
            types={ButtonType.Button}
            loaderLogin={false}
            onClick={onClick}
            someValue={2}
          />

          <ButtonXLarge
            colorVarient={ButtonXLargeColor.Orange}
            buttonSize="default"
            label={t("resendOPTCode")}
            fullwidth={true}
            types={ButtonType.Button}
            loaderLogin={false}
            onClick={onClick}
            someValue={3}
          />
        </form>
      </Stack>
    </>
  );
};

export default VerifyOpt;
