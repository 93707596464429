import { Button, ButtonProps, SxProps, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../Utils";

interface EventIconButtonProps extends ButtonProps {
  icon: React.ElementType;
  iconSize?: number;
  iconColor: string;
  iconDirection?: "up" | "down" | "left" | "right";
  buttonTitle: string;
  buttonSx?: SxProps;
  titleSx?: SxProps;
  highlighted?: boolean;
}

const EventIconButton: React.FC<EventIconButtonProps> = ({
  buttonTitle,
  icon,
  iconColor,
  iconSize = 15,
  buttonSx,
  iconDirection,
  titleSx,
  highlighted = false,
  ...props
}) => {
  return (
    <Button
      variant="text"
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        padding: "3px 4px",
        textTransform: "none",
        border: highlighted
          ? `1px solid ${adjustHexColor("#D9E3EF", 35)}`
          : "1px solid white",
        ...buttonSx,
      }}
      {...props}
    >
      {React.createElement(icon, {
        w: iconSize,
        color: iconColor,
        direction: iconDirection,
      })}
      <Typography
        sx={{
          font: "normal normal 300 13px/16px Source Serif Pro",
          color: "black",
          whiteSpace: "nowrap",
          ...titleSx,
        }}
      >
        {buttonTitle}
      </Typography>
    </Button>
  );
};

export default EventIconButton;
