// App.tsx
import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const Message: React.FC = () => {

  return (
    <Box
      display="flex"
      padding="0 30px 0 30px"
      flexDirection="column"
      height="92vh"
      width="100%"
    >

    </Box>
  );
};

export default Message;
