import React, { useState } from "react";
import {
  AvatarLarge,
  AvatarLargeProps,
  AvatarSize,
  BgColorCode,
  IconButtonLong,
  LargeInputBox,
} from "../../../common";
import { FirstLoginData, FirstLoginDataProps } from "./StepsType";
import { Box, Stack } from "@mui/material";

// Define the StepOneProps interface
interface StepOneProps extends FirstLoginDataProps {
  firstLoginData: FirstLoginData;
  setFirstLoginData: React.Dispatch<React.SetStateAction<FirstLoginData>>;
}

const StepOne: React.FC<StepOneProps> = ({
  firstLoginData,
  setFirstLoginData,
}) => {
  const colors: BgColorCode[] = [
    BgColorCode.Purple,
    BgColorCode.DarkBlue,
    BgColorCode.Blue,
    BgColorCode.LightBlue,
    BgColorCode.Green,
    BgColorCode.Yellow,
    BgColorCode.Red,
    BgColorCode.Gold,
    BgColorCode.LightGreen,
    BgColorCode.Teal,
  ];

  const [file, setFile] = useState<string | undefined>(undefined);

  const handleFileSelect = (file: File) => {
    setFirstLoginData({
      ...firstLoginData,
      avatarFile: file,
    });
    /* Peserve the data below */
    const fileUrl = URL.createObjectURL(file);
    setFile(fileUrl); // Update file state with the created object URL
  };

  const avatars: AvatarLargeProps[] = Array.from({ length: 10 }).map(
    (_, index) => ({
      bgColorCode: colors[index % colors.length], // Ensure bgColorCode is used correctly here
      size: AvatarSize.Small,
    })
  );

  return (
    <div style={{ width: "100%" }}>
      <Stack direction={"column"} gap={"8px"}>
        <LargeInputBox
          borderColor="black"
          label="First Name"
          value={firstLoginData?.firstName}
          autoComplete="off"
          onChange={(e) => {
            const value = typeof e === "string" ? e : e.target.value;
            setFirstLoginData({
              ...firstLoginData,
              firstName: value,
            });
          }}
        />
        <LargeInputBox
          borderColor="black"
          label="Last Name"
          value={firstLoginData?.lastName}
          autoComplete="off"
          onChange={(e) => {
            const value = typeof e === "string" ? e : e.target.value;
            setFirstLoginData({
              ...firstLoginData,
              lastName: value,
            });
          }}
        />
      </Stack>
      <Stack
        mt="20px"
        mb="20px"
        gap="20px"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="80%"
      >
        <Stack direction="column" gap="8px">
          <AvatarLarge
            bgColorCode={firstLoginData.avatarDefaultColor}
            size={AvatarSize.Large}
            imgSrc={file || (firstLoginData?.avatarFile as string) || ""}
            text={`${firstLoginData?.firstName?.charAt(0) || "A"}${firstLoginData?.lastName?.charAt(0) || "A"
              }`}
          />
          <div>
            <IconButtonLong onFileSelect={handleFileSelect} />
          </div>
        </Stack>

        <Stack gap="15px" direction="row" flexWrap="wrap">
          {avatars.map((avatar, index) => (
            <Box
              key={index}
              component="span"
              sx={{ borderRadius: "25px" }}
              onClick={() => {
                setFirstLoginData({
                  ...firstLoginData,
                  avatarDefaultColor: avatar.bgColorCode as BgColorCode,
                });
                console.log(avatar.bgColorCode);
              }}
            >
              <AvatarLarge {...avatar} />
            </Box>
          ))}
        </Stack>
      </Stack>
    </div>
  );
};

export default StepOne;
