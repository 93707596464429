import {Stack} from "@mui/material";
import React, {lazy, Suspense, useEffect} from "react";
import {a11yProps, StyledTab, TabPanel, TabsWithState,} from "../../../../common/CardTabsWithoutIcon";
import {useTranslation} from "react-i18next";
import {ClearInfoIcon,} from "../../../../../images";
import {
    addSelectedConversationDetailsAction,
    addSelectedConversationIdAction,
    sortChannelsAction
} from "../../../../../redux/Slice/message/messageSlice";
import {getChannelMessages} from "../../../../../redux/Slice/message/messageActions";
import {getChannelType} from "../../../../../Utils/MessageCenterUtiles";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import {MessagesState} from "../../../../../redux/Slice/message/messageTypes";
import {useNavigate, useParams} from "react-router-dom";
import SidebarOpenMenu from "../SidebarOpenMenu";

interface MessageCenterTabsProps {
    handleToggleRightSideBar?: () => void;
    setSelectedSideBar?: any;
    isSidebarOpen?: boolean;
    selectedSidebar?: string;
}

const LazyCalenderTab = lazy(
    () => import("./tabs/CalenderTab/CalenderTab")
);
const LazyChatTab = lazy(() => import("./tabs/ChatTab/ChatTab"));
const LazyAssignedTasksTab = lazy(() => import("./tabs/AssignedTasksTab/AssignedTasksTab"));
const LazyTraxTab = lazy(() => import("./tabs/TraxTab/TraxTab"));

const tabs: {
    label: string;
    content: React.ReactNode;
    LANG: string;
}[] = [
    {
        label: "Chat",
        content: <LazyChatTab/>,
        LANG: "MESSAGE.CHAT",
    },
    {
        label: "Calender",
        content: <LazyCalenderTab/>,
        LANG: "MESSAGE.CALENDER",
    },

    {
        label: "Assigned Tasks",
        content: <LazyAssignedTasksTab/>,
        LANG: "MESSAGE.ASSIGNED_TASKS",
    },
    {
        label: "Trax",
        content: <LazyTraxTab/>,
        LANG: "MESSAGE.TRAX",
    },
];

const MessagingScreenTabs: React.FC<MessageCenterTabsProps> = ({
                                                                   handleToggleRightSideBar,
                                                                   setSelectedSideBar,
                                                                   isSidebarOpen,
                                                                   selectedSidebar
                                                               }) => {
    const {t} = useTranslation();
    const [tabValue, setTabValue] = React.useState(0);

    const {
        channelChats,
        directChannel,
        chatTabState,
        pinnedChannels
    } = useSelector((state: RootState) => state.message as MessagesState);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams();

    const handleTabChange = (newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if (!chatTabState?.selectedConversation.conversationId) {
            dispatch(addSelectedConversationIdAction(id));
        }
        if (chatTabState?.selectedConversation.conversationId) {
            const currentChannel = channelChats[chatTabState?.selectedConversation.conversationId] || directChannel[chatTabState?.selectedConversation.conversationId] || pinnedChannels[chatTabState?.selectedConversation.conversationId];
            if (currentChannel) {
                dispatch(addSelectedConversationDetailsAction(currentChannel));
            }
            fetchChannelDetails(chatTabState?.selectedConversation.conversationId);
            dispatch(sortChannelsAction());
        }
    }, [chatTabState?.selectedConversation.conversationId]);

    const fetchChannelDetails = async (channelId: string) => {
        const response: any = await dispatch(getChannelMessages({
            id: channelId,
            channelType: getChannelType(channelId),
        }));
        if (response?.state?.message?.error) {
            navigate(`/Message`);
        }
    };

    const handleSelectTab = (selectedMenu: string) => {
        if (!isSidebarOpen) {
            if (handleToggleRightSideBar) {
                handleToggleRightSideBar();
            }
        }
        setSelectedSideBar(selectedMenu);
    }

    const handleOpenChatInfo = () => {
        if (handleToggleRightSideBar) {
            handleToggleRightSideBar();
        }
        setSelectedSideBar("ChatDetails");
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (chatTabState?.selectedConversation.conversationId && chatTabState?.selectedConversation?.conversationPlatform === "SLACK") {
                fetchChannelDetails(chatTabState?.selectedConversation.conversationId);
            }
        }, 20000);

        return () => clearInterval(intervalId);
    }, [chatTabState?.selectedConversation.conversationId]);

    return (
        <Stack
            sx={{
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                gap: "15px",

            }}
        >
            <Stack
                sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "24px",
                    justifyContent: "space-between",
                    padding: "5px 15px 5px 15px",
                }}
            >
                <Stack direction="row" gap="25px" alignItems={"center"}>
                    <TabsWithState
                        ariaLabel="Message center tabs"
                        value={tabValue}
                        onChange={(event, newValue) => handleTabChange(newValue)}
                        tabIndicatorColor="#775EE2"
                        sx={{
                            maxWidth: "max-content",
                            maxHeight:"32px",
                            gap: "25px",
                            "& .css-1aquho2-MuiTabs-indicator": {
                                bottom: "2px !important", // Adjust this value to decrease the space
                            },
                        }}
                    >
                        {tabs.map((tab, index) => (
                            <StyledTab
                                style={{
                                    font: "normal normal 300 14px/17.54px Source Serif Pro",
                                    padding: "10px 0px",
                                }}
                                key={index}
                                disableRipple
                                label={t(tab.LANG)}
                                {...a11yProps(index)}
                            />
                        ))}
                    </TabsWithState>
                    <Stack onClick={handleOpenChatInfo} style={{cursor: "pointer"}}>
                        <ClearInfoIcon/>
                    </Stack>
                </Stack>
                <Stack direction="row" gap="25px" justifyContent={"end"}>
                    <SidebarOpenMenu onClickAction={handleSelectTab}/>
                </Stack>
            </Stack>

            {tabs &&
                tabs.map(
                    (tab: { label: string; content: React.ReactNode }, index: number) => (
                        <TabPanel
                            // style={{display: "contents"}} /* Milan this causing issue in calendar -> not able to drag events */
                            key={index}
                            value={tabValue}
                            index={index}
                        >
                            <Suspense fallback={<div>Loading...</div>}>
                             {/* Here, pass `isSidebarOpen` dynamically to LazyCalenderTab */}
                             {index === 1 ? (
                                    <LazyCalenderTab isSidebarOpen={isSidebarOpen} />
                                ) : (
                                    tab.content
                                )}
                            </Suspense>
                        </TabPanel>
                    )
                )}
        </Stack>
    );
};

export default MessagingScreenTabs;
