import { InputBase, InputBaseProps, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";

interface SquareInputProps extends InputBaseProps {
  squareTitle: string;
  initialValue?: string; // The initial value to display
  onValueChange?: (value: string) => void; // Callback to send the updated value
}

const SquareInput: React.FC<SquareInputProps> = (props) => {
  const {
    initialValue = "",
    onValueChange,
    onChange,
    squareTitle,
    ...rest
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(initialValue);
  const [inputWidth, setInputWidth] = useState(50); // Default min-width for the input
  const textRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setInputValue(initialValue); // Update inputValue when initialValue changes
  }, [initialValue]);

  // Adjust the input width dynamically based on text size
  useEffect(() => {
    if (textRef.current) {
      const width = textRef.current.offsetWidth + 10; // Add padding
      setInputWidth(Math.max(width, 50)); // Ensure a minimum width of 50px
    }
  }, [inputValue]);

  const handleEdit = () => setIsEditing(true);

  const handleBlur = () => {
    setIsEditing(false);
    if (onValueChange) {
      onValueChange(inputValue); // Send the updated value when editing ends
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setIsEditing(false);
      if (onValueChange) {
        onValueChange(inputValue); // Send the updated value on Enter
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value); // Update local state
    if (onChange) {
      onChange(e); // Pass the event to the parent if onChange is provided
    }
  };

  return (
    <>
      {!isEditing ? (
        <Typography
          onClick={handleEdit}
          style={{
            cursor: "pointer",
            font: "normal normal 900 11px/14px Source Serif Pro",
          }}
          ref={textRef}
        >
          {inputValue || `[${squareTitle}]`}
        </Typography>
      ) : (
        <InputBase
          {...rest}
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
          style={{
            font: "normal normal 900 11px/14px Source Serif Pro",
            width: `${inputWidth}px`, // Dynamically adjust width
            minWidth: "150px",
            transition: "width 0.2s ease",
            height: "14px",
          }}
        />
      )}
    </>
  );
};

export default SquareInput;
