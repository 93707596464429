import { createApiAction } from "../constantApi_config";
import {
  /* Actions for creating a calendar */
  createCalendar__RequestAction, // Action to indicate the start of the calendar creation process
  createCalendar__FailureAction, // Action for handling errors during calendar creation
  createCalendar__SuccessAction, // Action for successful calendar creation

  /* Actions for fetching all calendars */
  getAllCalendars__RequestAction, // Action to indicate the start of fetching all calendars
  getAllCalendars__FailureAction, // Action for handling errors while fetching all calendars
  getAllCalendars__SuccessAction, // Action for successful retrieval of all calendars

  /* Actions for fetching a single calendar */
  getOneCalendar__RequestAction, // Action to indicate the start of fetching a specific calendar
  getOneCalendar__FailureAction, // Action for handling errors while fetching a specific calendar
  getOneCalendar__SuccessAction, // Action for successful retrieval of a specific calendar

  /* Actions for updating a calendar */
  updateCalendar__RequestAction, // Action to indicate the start of the calendar update process
  updateCalendar__FailureAction, // Action for handling errors during calendar update
  updateCalendar__SuccessAction, // Action for successful calendar update

  /* Actions for deleting a calendar */
  deleteCalendar__RequestAction, // Action to indicate the start of the calendar deletion process
  deleteCalendar__FailureAction, // Action for handling errors during calendar deletion
  deleteCalendar__SuccessAction, // Action for successful calendar deletion

  /* Actions for creating calendar settings */
  createCalendarSettings__RequestAction, // Action to indicate the start of creating calendar settings
  createCalendarSettings__FailureAction, // Action for handling errors during calendar settings creation
  createCalendarSettings__SuccessAction, // Action for successful creation of calendar settings

  /* Actions for fetching calendar settings */
  getCalendarSettings__RequestAction, // Action to indicate the start of fetching calendar settings
  getCalendarSettings__FailureAction, // Action for handling errors while fetching calendar settings
  getCalendarSettings__SuccessAction, // Action for successful retrieval of calendar settings

  /* Actions for updating calendar settings */
  updateCalendarSettings__RequestAction, // Action to indicate the start of updating calendar settings
  updateCalendarSettings__FailureAction, // Action for handling errors during calendar settings update
  updateCalendarSettings__SuccessAction, // Action for successful update of calendar settings

  /* Actions for adding connected calendars */
  addConnectedCalendar__RequestAction, // Action to indicate the start of adding a connected calendar
  addConnectedCalendar__FailureAction, // Action for handling errors while adding a connected calendar
  addConnectedCalendar__SuccessAction, // Action for successful addition of a connected calendar

  /* Actions for removing connected calendars */
  removeConnectedCalendar__RequestAction, // Action to indicate the start of removing a connected calendar
  removeConnectedCalendar__FailureAction, // Action for handling errors during removal of a connected calendar
  removeConnectedCalendar__SuccessAction, // Action for successful removal of a connected calendar

  /* Actions for creating events */
  createEvent__RequestAction, // Action to indicate the start of creating an event
  createEvent__FailureAction, // Action for handling errors during event creation
  createEvent__SuccessAction, // Action for successful event creation

  /* Actions for fetching all events */
  getAllEvents__RequestAction, // Action to indicate the start of fetching all events
  getAllEvents__FailureAction, // Action for handling errors while fetching all events
  getAllEvents__SuccessAction, // Action for successful retrieval of all events

  /* Actions for creating tasks */
  createTask__RequestAction, // Action to indicate the start of creating a task
  createTask__FailureAction, // Action for handling errors during task creation
  createTask__SuccessAction, // Action for successful task creation

  /* Actions for fetching all tasks */
  getAllTasks__RequestAction, // Action to indicate the start of fetching all tasks
  getAllTasks__FailureAction, // Action for handling errors while fetching all tasks
  getAllTasks__SuccessAction, // Action for successful retrieval of all tasks

  /* Actions for creating booking links */
  createBookingLink__RequestAction, // Action to indicate the start of creating a booking link
  createBookingLink__FailureAction, // Action for handling errors during booking link creation
  createBookingLink__SuccessAction, // Action for successful creation of a booking link

  /* Actions for fetching booking links */
  getBookingLinks__RequestAction, // Action to indicate the start of fetching booking links
  getBookingLinks__FailureAction, // Action for handling errors while fetching booking links
  getBookingLinks__SuccessAction, // Action for successful retrieval of booking links

  /* Actions for responding to events */
  eventResponse__RequestAction, // Action to indicate the start of responding to an event
  eventResponse__FailureAction, // Action for handling errors during event response
  eventResponse__SuccessAction, // Action for successful event response

  /* Actions for creating schedules */
  createSchedule__RequestAction, // Action to indicate the start of creating a new schedule
  createSchedule__FailureAction, // Action for handling errors while creating a new schedule
  createSchedule__SuccessAction, // Action for successful creation of a new schedule

  /* Actions for fetching all schedules */
  getAllSchedules__RequestAction, // Action to indicate the start of fetching all schedules
  getAllSchedules__FailureAction, // Action for handling errors while fetching all schedules
  getAllSchedules__SuccessAction, // Action for successful retrieval of all schedules

  /* Actions for fetching one schedule */
  getOneSchedule__RequestAction, // Action to indicate the start of fetching one schedule by ID
  getOneSchedule__FailureAction, // Action for handling errors while fetching one schedule
  getOneSchedule__SuccessAction, // Action for successful retrieval of one schedule

  /* Actions for updating schedules */
  updateSchedule__RequestAction, // Action to indicate the start of updating a schedule
  updateSchedule__FailureAction, // Action for handling errors while updating a schedule
  updateSchedule__SuccessAction, // Action for successful update of a schedule

  /* Actions for deleting schedules */
  deleteSchedule__RequestAction, // Action to indicate the start of deleting a schedule
  deleteSchedule__FailureAction, // Action for handling errors while deleting a schedule
  deleteSchedule__SuccessAction, // Action for successful deletion of a schedule
  subscribeToICal__RequestAction,
  subscribeToICal__FailureAction,
  subscribeToICal__SuccessAction,
  subscribeToUserCal__RequestAction,
  subscribeToUserCal__FailureAction,
  subscribeToUserCal__SuccessAction,
  getAllBookingLinks__RequestAction,
  getAllBookingLinks__FailureAction,
  getAllBookingLinks__SuccessAction,
  trackVisit__RequestAction,
  trackVisit__FailureAction,
  trackVisit__SuccessAction,
  getPublicBookingLink__RequestAction,
  getPublicBookingLink__FailureAction,
  getPublicBookingLink__SuccessAction,
  getPrivateBookingLink__RequestAction,
  getPrivateBookingLink__FailureAction,
  getPrivateBookingLink__SuccessAction,
  getAvailableTimeSlots__RequestAction,
  getAvailableTimeSlots__FailureAction,
  getAvailableTimeSlots__SuccessAction,
  createBookingEvent__RequestAction,
  createBookingEvent__FailureAction,
  createBookingEvent__SuccessAction,
  LoginExternalCalendar__RequestAction,
  LoginExternalCalendar__FailureAction,
  LoginExternalCalendar__SuccessAction,
  AuthExternalCalendar__RequestAction,
  AuthExternalCalendar__SuccessAction,
  AuthExternalCalendar__FailureAction,
  getOneEvent__RequestAction,
  getOneEvent__FailureAction,
  getOneEvent__SuccessAction,
  updateCalendarAppearance__RequestAction,
  updateCalendarAppearance__SuccessAction,
  updateCalendarAppearance__FailureAction,
  shareCalendarWithUser__RequestAction,
  shareCalendarWithUser__SuccessAction,
  shareCalendarWithUser__FailureAction,
  getShareWith__RequestAction,
  getShareWith__SuccessAction,
  getShareWith__FailureAction,
  updateEventDate__RequestAction,
  updateEventDate__SuccessAction,
  updateEventDate__FailureAction,
  updateEventTaskStatus__RequestAction,
  updateEventTaskStatus__SuccessAction,
  updateEventTaskStatus__FailureAction,
  cmd__calendar__SuccessAction,
  deleteOneEvents__SuccessAction,
} from "./CalendarSlice";

import {
  BookingLinkData,
  CalendarData,
  CalendarSettingsPayload,
  CreateBookingEventPayload,
  EventData,
  ScheduleData,
} from "./CalendarType";

/* Create Calendar */
export const createCalendar__api = (calendarData: CalendarData) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    data: calendarData,
    url: `/calendar/createCalendar`,
    onStart: createCalendar__RequestAction.type,
    onSuccess: createCalendar__SuccessAction.type,
    onError: createCalendar__FailureAction.type,
  });

/* subscribeToICal Calendar */
export const subscribeToICalCalendar__api = (url?: string, provider?: string) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    data: {
      url:
        url ||
        "https://calendar.google.com/calendar/ical/en.usa%23holiday%40group.v.calendar.google.com/public/basic.ics",
      provider: provider || "iCal",
    },
    url: `/calendar/subscribeToICal`,
    onStart: subscribeToICal__RequestAction.type,
    onSuccess: subscribeToICal__SuccessAction.type,
    onError: subscribeToICal__FailureAction.type,
  });

/* subscribeToUserCal Calendar */
export const subscribeToUserCalendar__api = (userIdToSubscribe: string) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    data: {
      userIdToSubscribe: userIdToSubscribe,
    },
    url: `/calendar/subscribe-user-calendar`,
    onStart: subscribeToUserCal__RequestAction.type,
    onSuccess: subscribeToUserCal__SuccessAction.type,
    onError: subscribeToUserCal__FailureAction.type,
  });

/* Get All Calendars */
export const getAllCalendars__api = () =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    url: `/calendar/all`,
    onStart: getAllCalendars__RequestAction.type,
    onSuccess: getAllCalendars__SuccessAction.type,
    onError: getAllCalendars__FailureAction.type,
  });

/* Get All Calendars */
// Login using Google
export const LoginCalendars__api = (provider: string) =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    url: `/calendar/auth/login?provider=${provider}`, // Add provider query parameter
    onStart: LoginExternalCalendar__RequestAction.type,
    onSuccess: LoginExternalCalendar__SuccessAction.type,
    onError: LoginExternalCalendar__FailureAction.type,
  });

export const AuthCalendars__api = (provider: string, allResponse: any) =>
  createApiAction({
    axiosRequired: true,
    method: "POST", // Changed to POST as we are sending the code_verifier
    url: `/calendar/auth/callback`, // Use POST to send the code_verifier in the body
    data: {
      provider, // The provider, e.g., Microsoft
      msalResponse: allResponse,
    },
    onStart: AuthExternalCalendar__RequestAction.type,
    onSuccess: AuthExternalCalendar__SuccessAction.type,
    onError: AuthExternalCalendar__FailureAction.type,
  });

/* Get One Event */
export const getOneEvent__api = (calendarId: string, eventId: string) =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    url: `/calendar/c/${calendarId}/events/${eventId}`,
    onStart: getOneEvent__RequestAction.type,
    onSuccess: getOneEvent__SuccessAction.type,
    onError: getOneEvent__FailureAction.type,
  });

/* Get One Calendar */
export const getOneCalendar__api = (calendarId: string) =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    url: `/calendar/getOneCalendar/${calendarId}`,
    onStart: getOneCalendar__RequestAction.type,
    onSuccess: getOneCalendar__SuccessAction.type,
    onError: getOneCalendar__FailureAction.type,
  });

/* Update Calendar */
export const updateCalendar__api = (
  calendarId: string,
  calendarData: CalendarData
) =>
  createApiAction({
    axiosRequired: true,
    method: "PUT",
    data: calendarData,
    url: `/calendar/getOneCalendar/${calendarId}`,
    onStart: updateCalendar__RequestAction.type,
    onSuccess: updateCalendar__SuccessAction.type,
    onError: updateCalendar__FailureAction.type,
  });

/* Delete Calendar */
export const deleteCalendar__api = (calendarId: string) =>
  createApiAction({
    axiosRequired: true,
    method: "DELETE",
    url: `/calendar/getOneCalendar/${calendarId}`,
    onStart: deleteCalendar__RequestAction.type,
    onSuccess: deleteCalendar__SuccessAction.type,
    onError: deleteCalendar__FailureAction.type,
  });

/* Delete Calendar */
export const deleteEvent__api = (eventId: string) =>
  createApiAction({
    axiosRequired: true,
    method: "DELETE",
    url: `/calendar/delete/${eventId}`,
    onStart: `deleteEvent__RequestAction.type`,
    onSuccess: deleteOneEvents__SuccessAction.type,
    onError: `deleteEvent__FailureAction.type`,
  });

/* Create Calendar Settings */
export const createCalendarSettings__api = (
  settingsData: CalendarSettingsPayload
) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    data: settingsData,
    url: `/calendar/calendarSettings`,
    onStart: createCalendarSettings__RequestAction.type,
    onSuccess: createCalendarSettings__SuccessAction.type,
    onError: createCalendarSettings__FailureAction.type,
  });

/* Get Calendar Settings */
export const getCalendarSettings__api = () =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    url: `/calendar/calendarSettings`,
    onStart: getCalendarSettings__RequestAction.type,
    onSuccess: getCalendarSettings__SuccessAction.type,
    onError: getCalendarSettings__FailureAction.type,
  });

/* Update Calendar Settings */
export const updateCalendarSettings__api = (
  settingsData: CalendarSettingsPayload
) =>
  createApiAction({
    axiosRequired: true,
    method: "PUT",
    data: settingsData,
    url: `/calendar/calendarSettings`,
    onStart: updateCalendarSettings__RequestAction.type,
    onSuccess: updateCalendarSettings__SuccessAction.type,
    onError: updateCalendarSettings__FailureAction.type,
  });

/* Add Connected Calendar */
export const addConnectedCalendar__api = (calendarData: CalendarData) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    data: calendarData,
    url: `/calendar/calendar-settings/connected`,
    onStart: addConnectedCalendar__RequestAction.type,
    onSuccess: addConnectedCalendar__SuccessAction.type,
    onError: addConnectedCalendar__FailureAction.type,
  });

/* Remove Connected Calendar */
export const removeConnectedCalendar__api = (calendarId: string) =>
  createApiAction({
    axiosRequired: true,
    method: "DELETE",
    url: `/calendar/calendar-settings/connected/${calendarId}`,
    onStart: removeConnectedCalendar__RequestAction.type,
    onSuccess: removeConnectedCalendar__SuccessAction.type,
    onError: removeConnectedCalendar__FailureAction.type,
  });

/* Create Event */
export const createEvent__api = (eventData: EventData) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    data: eventData,
    url: `/calendar/create/${eventData?.taskOrEvent}/${eventData?.calendarId}`,
    onStart: createEvent__RequestAction.type,
    onSuccess: createEvent__SuccessAction.type,
    onError: createEvent__FailureAction.type,
  });

/* Get All Events */
export const getAllEvents__api = () =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    url: `/calendar/events`,
    onStart: getAllEvents__RequestAction.type,
    onSuccess: getAllEvents__SuccessAction.type,
    onError: getAllEvents__FailureAction.type,
  });
export const update_OneEvents__api = (payload: any, eventId: any) =>
  createApiAction({
    axiosRequired: true,
    method: "PUT",
    data: payload,
    url: `/calendar/update/${eventId}`,
    onStart: `getAllEvents__RequestAction.type`,
    onSuccess: `getAllEvents__SuccessAction.type`,
    onError: `getAllEvents__FailureAction.type`,
  });

/* Create Task */
export const createTaskCalendar__api = (taskData: EventData) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    data: taskData,
    url: `/calendar/task/create`,
    onStart: createTask__RequestAction.type,
    onSuccess: createTask__SuccessAction.type,
    onError: createTask__FailureAction.type,
  });

/* Get All Tasks */
export const getAllTasksCalendar__api = () =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    url: `/calendar/tasks`,
    onStart: getAllTasks__RequestAction.type,
    onSuccess: getAllTasks__SuccessAction.type,
    onError: getAllTasks__FailureAction.type,
  });

/* Create Booking Link */
export const createBookingLink__api = (linkData: BookingLinkData) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    data: linkData,
    url: `/calendar/createBookingLink`,
    onStart: createBookingLink__RequestAction.type,
    onSuccess: createBookingLink__SuccessAction.type,
    onError: createBookingLink__FailureAction.type,
  });

/* Get Booking Links */
export const getOneBookingLinks__api = (bookingLinkId: string) =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    url: `/calendar/booking/private/${bookingLinkId}`,
    onStart: getBookingLinks__RequestAction.type,
    onSuccess: getBookingLinks__SuccessAction.type,
    onError: getBookingLinks__FailureAction.type,
  });

export const eventResponse__api = (
  calendarId: string,
  eventId: string,
  response: string,
  type: string,
  queryParams?: { email?: string; userId?: string } // Accept query parameters
) =>
  createApiAction({
    axiosRequired: true,
    method: "PUT",
    url: (() => {
      // Build the base URL
      let url = `/calendar/c/${calendarId}/events/${eventId}/${type}/r/${response}`;

      // Add query parameters if available
      if (queryParams) {
        const queryString = new URLSearchParams(queryParams).toString();
        if (queryString) {
          url += `?${queryString}`;
        }
      }

      return url;
    })(),
    onStart: eventResponse__RequestAction.type,
    onSuccess: eventResponse__SuccessAction.type,
    onError: eventResponse__FailureAction.type,
  });

/* Create Schedule */
export const createSchedule__api = (scheduleData: ScheduleData) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    data: scheduleData,
    url: `/calendar/schedule`,
    onStart: createSchedule__RequestAction.type,
    onSuccess: createSchedule__SuccessAction.type,
    onError: createSchedule__FailureAction.type,
  });

/* Get All Schedules */
export const getAllSchedules__api = () =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    url: `/calendar/schedule`,
    onStart: getAllSchedules__RequestAction.type,
    onSuccess: getAllSchedules__SuccessAction.type,
    onError: getAllSchedules__FailureAction.type,
  });

/* Get One Schedule */
export const getOneSchedule__api = (scheduleId: string) =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    url: `/calendar/schedule/${scheduleId}`,
    onStart: getOneSchedule__RequestAction.type,
    onSuccess: getOneSchedule__SuccessAction.type,
    onError: getOneSchedule__FailureAction.type,
  });

/* Update Schedule */
export const updateSchedule__api = (
  scheduleId: string,
  scheduleData: ScheduleData
) =>
  createApiAction({
    axiosRequired: true,
    method: "PUT",
    data: scheduleData,
    url: `/calendar/schedule/${scheduleId}`,
    onStart: updateSchedule__RequestAction.type,
    onSuccess: updateSchedule__SuccessAction.type,
    onError: updateSchedule__FailureAction.type,
  });

/* Delete Schedule */
export const deleteSchedule__api = (scheduleId: string) =>
  createApiAction({
    axiosRequired: true,
    method: "DELETE",
    url: `/calendar/schedule/${scheduleId}`,
    onStart: deleteSchedule__RequestAction.type,
    onSuccess: deleteSchedule__SuccessAction.type,
    onError: deleteSchedule__FailureAction.type,
  });

/* Get All Booking Links */
export const getAllBookingLinks__api = () =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    url: `/calendar/bookingLinks`,
    onStart: getAllBookingLinks__RequestAction.type,
    onSuccess: getAllBookingLinks__SuccessAction.type,
    onError: getAllBookingLinks__FailureAction.type,
  });

/* Track Visit */
export const trackVisit__api = (
  bookingLinkSlug: string,
  bookingLinkSlugData: any
) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    data: bookingLinkSlugData,
    url: `/calendar/booking-link/track/${bookingLinkSlug}`,
    onStart: trackVisit__RequestAction.type,
    onSuccess: trackVisit__SuccessAction.type,
    onError: trackVisit__FailureAction.type,
  });

/* Get Public Booking Link */
export const getPublicBookingLink__api = (
  bookingLinkSlug: string,
  ipDevice: any
) =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    data: ipDevice,
    url: `/calendar/booking/public/${bookingLinkSlug}`,
    onStart: getPublicBookingLink__RequestAction.type,
    onSuccess: getPublicBookingLink__SuccessAction.type,
    onError: getPublicBookingLink__FailureAction.type,
  });

/* Get Private Booking Link */
export const getPrivateBookingLink__api = (bookingLinkId: string) =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    url: `/calendar/booking/private/${bookingLinkId}`,
    onStart: getPrivateBookingLink__RequestAction.type,
    onSuccess: getPrivateBookingLink__SuccessAction.type,
    onError: getPrivateBookingLink__FailureAction.type,
  });

/* Get Available Time Slots */
export const getAvailableTimeSlots__api = (
  bookingLinkSlug: string,
  date: any,
  userTimeZone: string
) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    data: { date: date, timeZone: userTimeZone },
    url: `/calendar/booking/slots/${bookingLinkSlug}`,
    onStart: getAvailableTimeSlots__RequestAction.type,
    onSuccess: getAvailableTimeSlots__SuccessAction.type,
    onError: getAvailableTimeSlots__FailureAction.type,
  });

/* Create Booking Event */
export const createBookingEvent__api = (
  bookingLinkSlug: string,
  eventData: CreateBookingEventPayload
) =>
  createApiAction({
    axiosRequired: true,
    method: "POST",
    data: eventData,
    url: `/calendar/booking/${bookingLinkSlug}`,
    onStart: createBookingEvent__RequestAction.type,
    onSuccess: createBookingEvent__SuccessAction.type,
    onError: createBookingEvent__FailureAction.type,
  });

export const updateCalendarAppearance__api = (
  calendarId: string,
  calendarUpdateData: any,
  type: any
) =>
  createApiAction({
    axiosRequired: true,
    method: "PATCH",
    data:
      type === "color"
        ? { color: calendarUpdateData }
        : { icon: calendarUpdateData },
    url: `/calendar/appearance/${calendarId}`,
    onStart: updateCalendarAppearance__RequestAction.type,
    onSuccess: updateCalendarAppearance__SuccessAction.type,
    onError: updateCalendarAppearance__FailureAction.type,
  });

/* share calendar with user */
export const shareCalendarWithUser__api = (shareWithUserData: any) =>
  createApiAction({
    axiosRequired: true,
    method: "PATCH",
    data: shareWithUserData,
    url: `/calendar/sharedWith`,
    onStart: shareCalendarWithUser__RequestAction.type,
    onSuccess: shareCalendarWithUser__SuccessAction.type,
    onError: shareCalendarWithUser__FailureAction.type,
  });

/* share calendar with user */
export const updateEventDate__api = (
  calendarId: string,
  eventId: string,
  date: any
) =>
  createApiAction({
    axiosRequired: true,
    method: "PUT",
    data: { date: date },
    url: `/calendar/events/${calendarId}/${eventId}/date`,
    onStart: updateEventDate__RequestAction.type,
    onSuccess: updateEventDate__SuccessAction.type,
    onError: updateEventDate__FailureAction.type,
  });

/* share calendar with user */
export const updateEventTaskStatus__api = (
  calendarId: string,
  eventId: string
) =>
  createApiAction({
    axiosRequired: true,
    method: "PUT",
    url: `/calendar/task/${calendarId}/${eventId}/status`,
    onStart: updateEventTaskStatus__RequestAction.type,
    onSuccess: updateEventTaskStatus__SuccessAction.type,
    onError: updateEventTaskStatus__FailureAction.type,
  });

/* share calendar with user */
export const getShareCalendarWithUser__api = (calendarId: any) =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    url: `/calendar/sharedWith/${calendarId}`,
    onStart: getShareWith__RequestAction.type,
    onSuccess: getShareWith__SuccessAction.type,
    onError: getShareWith__FailureAction.type,
  });

export const cmd__calendar_api = (center?: any) =>
  createApiAction({
    axiosRequired: true,
    method: "GET",
    url: center
      ? `/command-notifications/notifications?center=${center}`
      : "/command-notifications/notifications", // URL without the center parameter if not provided
    onStart: `type__start.type`,
    onSuccess: cmd__calendar__SuccessAction.type,
    onError: `type__error.type`,
  });

export const cmd__update__calendar_api = (
  notificationId: any,
  datas: any,
  m?: any
) =>
  createApiAction({
    axiosRequired: true,
    method: m || "PUT",
    data: datas,
    url: `/command-notifications/notifications/${notificationId}`, // URL without the center parameter if not provided
    onStart: `type__start.type`,
    onSuccess: `cmd__calendar__SuccessAction.type`,
    onError: `type__error.type`,
  });
