import { Stack, Typography } from "@mui/material";
import React from "react";

interface ActionInfoViewProps {
  icon: React.ElementType;
  label: string;
  countValue?: number;
}

const ActionInfoView: React.FC<ActionInfoViewProps> = ({
  icon,
  label,
  countValue,
}) => {
  const formattedValue = countValue?.toString().padStart(2, "0");
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        {React.createElement(icon, { w: 20, color: "black" })}
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          {label}
        </Typography>
      </Stack>

      <Typography
        sx={{
          font: "normal normal 900 13px/16px Source Serif Pro",
          color: "#00767B",
        }}
      >
        {formattedValue}
      </Typography>
    </Stack>
  );
};

export default ActionInfoView;
