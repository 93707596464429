import React, { useEffect, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism"; // Correct import for styles

type CodeViewerProps = {
  path: string;
};

const CodeViewer: React.FC<CodeViewerProps> = ({ path }) => {
  const [code, setCode] = useState<string | null>(null);

  useEffect(() => {
    const fetchCode = async () => {
      try {
        const response = await fetch(path);
        const text = await response.text();
        setCode(text);
      } catch (error) {
        console.error("Error fetching code:", error);
        setCode(null);
      }
    };

    fetchCode();
  }, [path]);

  if (!code) {
    return <div>Loading code...</div>;
  }

  return (
    <div>
      <h3>Code Viewer</h3>
      <SyntaxHighlighter language="javascript" style={darcula}>
        {code}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeViewer;
