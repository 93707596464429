// useEmailApiAction.ts
import { useDispatch } from "react-redux";
import axios, { AxiosError } from "axios";
import {
    baseURL,
    getCurrentIp,
} from "../../../../../redux/middleware/apiMiddleware";
import { fetchEmailsFromBackend } from "../../../../../redux/Slice/email/MsalInitialize";
import { getToken } from "../../../../common/LocalStorage";

const useEmailApiAction = () => {
    const dispatch = useDispatch();
    const token = getToken();

    const getApiHeaders = async () => {
        const currentIp = await getCurrentIp();
        return {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Current-IP": currentIp,
        };
    };

    const EmailApiAction = async (
        accessToken: string,
        messageId: string | null,
        actionType: "delete" | "archive" | "markRead"
    ) => {
        if (!accessToken || !messageId) {
            return null; // Return null for consistency
        }

        try {
            // Check if actionType is valid
            if (!["delete", "archive", "markRead"].includes(actionType)) {
                return null; // Return null if invalid actionType
            }

            // Get headers and current IP once
            const headers = await getApiHeaders();

            // Dynamically determine the correct API endpoint and method
            const apiEndpoint =
                actionType === "delete" || actionType === "archive"
                    ? `${baseURL}/email/move-email`
                    : `${baseURL}/email/read-toggle`;

            const method = actionType === "delete" ? "DELETE" : "PUT";

            const res = await axios({
                method,
                url: apiEndpoint,
                data: { accessToken, messageId },
                headers,
            });

            if (res?.status === 200 || res?.status === 201) {
                // Only dispatch if the response is successful
                fetchEmailsFromBackend(accessToken, dispatch, "");
            }

            return res;
        } catch (err) {
            // Check if error is an AxiosError and log response data
            if (err instanceof AxiosError) {
                console.error(
                    "Error performing API action:",
                    err.response?.data || err.message
                );
            } else {
                console.error("Unexpected error:", err);
            }
            return null; // Return null if there was an error
        }
    };

    return { EmailApiAction };
};

export default useEmailApiAction;
