import React from "react";
import { Stack, Typography } from "@mui/material";
import styles from "./DocumentCommandsMenu.module.css";
import {
  ADVANCED_ELEMENTS,
  BASIC_ELEMENTS,
  INLINE_ELEMENTS,
  MEDIA_ELEMENTS,
} from "./DocumentCommandItemLists";

interface DocumentCommandsMenuProps {
  onClickAction: (args: any) => void;
}

const DocumentCommandsMenu: React.FC<DocumentCommandsMenuProps> = ({
  onClickAction,
}) => {
  return (
    <Stack className={styles["document-commands-menu__scrollable-stack"]}>
      {/* Basic elements map here */}
      <Typography className={styles["document-commands-menu_item-header-text"]}>
        Basic elements
      </Typography>
      {BASIC_ELEMENTS.map((item, index) => (
        <DocumentCommandItem
          key={index}
          title={item.title}
          description={item.description}
          icon={item.icon}
          text={item.text}
          onClick={onClickAction}
          componentType={item.componentType}
        />
      ))}

      {/* Media elements map here */}
      <Typography className={styles["document-commands-menu_item-header-text"]}>
        Media elements
      </Typography>
      {MEDIA_ELEMENTS.map((item, index) => (
        <DocumentCommandItem
          key={index}
          title={item.title}
          description={item.description}
          icon={item.icon}
          text={item.text}
          onClick={onClickAction}
          componentType={item.componentType}
        />
      ))}

      {/* Advanced elements map here */}
      <Typography className={styles["document-commands-menu_item-header-text"]}>
        Advanced elements
      </Typography>
      {ADVANCED_ELEMENTS.map((item, index) => (
        <DocumentCommandItem
          key={index}
          title={item.title}
          description={item.description}
          icon={item.icon}
          text={item.text}
          onClick={onClickAction}
          componentType={item.componentType}
        />
      ))}

      {/* Inline elements map here */}
      <Typography className={styles["document-commands-menu_item-header-text"]}>
        Inline elements
      </Typography>
      {INLINE_ELEMENTS.map((item, index) => (
        <DocumentCommandItem
          key={index}
          title={item.title}
          description={item.description}
          icon={item.icon}
          text={item.text}
          onClick={onClickAction}
          componentType={item.componentType}
        />
      ))}
    </Stack>
  );
};

export default DocumentCommandsMenu;

interface DocumentCommandItemProps {
  title: string;
  description: string;
  icon?: React.ElementType | string;
  text?: string;
  onClick: (args: string) => void;
  componentType: string;
}

// this is only card which displays the data as a list
const DocumentCommandItem: React.FC<DocumentCommandItemProps> = ({
  title,
  description,
  icon,
  text,
  onClick,
  componentType,
}) => {
  return (
    <Stack
      className={styles["document-commands-menu__item-stack"]}
      onClick={() => {
        onClick(componentType);
      }}
    >
      <Stack className={styles["document-commands-menu__icon-container"]}>
        {typeof icon === "string" ? (
          <img src={icon} alt={title} width={30} height={30} />
        ) : icon ? (
          React.createElement(icon, { color: "black", w: 30 })
        ) : (
          <Typography
            sx={{ font: "normal normal normal 22px/30px Source Serif Pro" }}
          >
            {text}
          </Typography>
        )}
      </Stack>
      <Stack>
        <Typography
          sx={{ font: "normal normal normal 18px/25px Source Serif Pro" }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}
        >
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};
