import { dayViewAreaWidth, dayViewHeaderArea, ShiftTimingType, ShiftType } from "./AttendanceScheduleTableType";

/**
 * Checks if the given date string represents today's date.
 *
 * @param dateString - The date string to check, in a format recognized by the Date constructor (e.g., '2023-10-05').
 * @returns `true` if the date string represents today's date, otherwise `false`.
 */
export const isToday = (dateString: string): boolean => {
    const inputDate = new Date(dateString);
    const today = new Date('2024-09-11'); // use this as a specific date consider as today
    // const today = new Date();  //use this for today date

    return (
        inputDate.getFullYear() === today.getFullYear() &&
        inputDate.getMonth() === today.getMonth() &&
        inputDate.getDate() === today.getDate()
    );
};

// ------------------------------------------------------------------------------------------------------------------

/**
 * Converts a date string in the format 'YYYY-MM-DD' to 'DD/MM/YYYY'.
 * 
 * @param {string} inputDate - The input date string in the format 'YYYY-MM-DD'.
 * @returns {string} - The formatted date string in the format 'DD/MM/YYYY'.
 * 
 * @example
 * const formattedDate = formatDateAsDDMMYYYY('2024-09-02');
 * console.log(formattedDate); // Output: '02/09/2024'
 */
export const formatDateAsDDMMYYYY = (inputDate: string): string => {
    const date = new Date(inputDate); // Parse the input date string into a Date object.
    const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad it to 2 digits.
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the zero-based month, adjust, and pad to 2 digits.
    const year = date.getFullYear(); // Get the 4-digit year.
    return `${day}/${month}/${year}`; // Return the formatted date string.
};

// ------------------------------------------------------------------------------------------------------------------

/**
 * Returns the name of the day of the week for a given date string.
 *
 * @param dateString - The date string to check, in a format recognized by the Date constructor (e.g., '2023-10-05').
 * @param dayLength - Optional parameter to specify the length of the day name. Can be 'half' for abbreviated names ("Sun", "Mon", etc.) or 'full' for full names ("Sunday", "Monday", etc.). Defaults to 'half'.
 * @returns The name of the day of the week, either abbreviated or full based on the `dayLength` parameter.
 */
export const getDayNameByDate = ({ dateString, dayLength = 'half' }: { dateString: string, dayLength?: 'half' | 'full' }): string => {
    const daysFull = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const daysHalf = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const dayIndex = new Date(dateString).getDay();

    switch (dayLength) {
        case 'full':
            return daysFull[dayIndex];
        case 'half':
        default:
            return daysHalf[dayIndex];
    }
};



export const sortShifts = (shifts: ShiftType[]): ShiftType[] => {
    const order: Record<ShiftType, number> = { morning: 0, midDay: 1, night: 2 };
    return shifts.sort((a, b) => order[a] - order[b]);
};


/**
 * Calculates the difference between two time strings in minutes.
 *
 * @param time1 - The first time string in the format "HH:MM:SS". Defaults to "00:00:00".
 * @param time2 - The second time string in the format "HH:MM:SS". Defaults to "00:00:00".
 * @returns The absolute difference between the two times in minutes.
 */
export function getTimeDifferenceInMinutes(time1: string = "00:00:00", time2: string = "00:00:00"): number {
    // Parse the time strings into hours, minutes, and seconds
    const [hours1, minutes1, seconds1] = time1.split(':').map(Number);
    const [hours2, minutes2, seconds2] = time2.split(':').map(Number);

    // Convert the times to total seconds
    const totalSeconds1 = hours1 * 3600 + minutes1 * 60 + seconds1;
    const totalSeconds2 = hours2 * 3600 + minutes2 * 60 + seconds2;

    // Get the absolute difference in seconds
    const differenceInSeconds = Math.abs(totalSeconds1 - totalSeconds2);

    // Convert seconds to minutes
    return Math.floor(differenceInSeconds / 60);
}


// --------------------------------------------------------------------------------------


/**
 * Calculate marginValue and length based on start time and end time.
 * @param startTime - The start time in "HH:mm:ss" format.
 * @param endTime - The end time in "HH:mm:ss" format.
 * @returns An object containing marginValue and length.
 * 
 * @example
 * const result = calculateShiftColorMetrics("08:30:00", "10:00:00");
 * console.log(result); // Output: { marginValue: 340, length: 60 }
 */
export const calculateShiftColorMetrics = (startTime: string, endTime: string): { marginValue: number; length: number } => {
    // Helper function to convert time string to a number (e.g., "08:30:00" -> 8.5)
    const timeToNumber = (time: string): number => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours + minutes / 60;
    };

    const startTimeNumber = timeToNumber(startTime);
    const endTimeNumber = timeToNumber(endTime);

    // Calculate marginValue and length
    const marginValue = startTimeNumber * dayViewAreaWidth.boxWidth;
    const length = (endTimeNumber - startTimeNumber) * dayViewAreaWidth.boxWidth;

    return { marginValue, length };
}



// ---------------------------------------------------------------------------------------


/**
 * Splits shifts that cross midnight into two separate shifts and sorts them based on their start time.
 * 
 * If the `endTime` is earlier than the `startTime` (i.e., the shift crosses midnight), the function
 * will split that shift into two, one from the original start time to midnight (`00:00:00`), and the other
 * from midnight (`00:00:00`) to the original end time. The result is then sorted by the `startTime`, 
 * with shifts starting at `00:00:00` appearing first.
 * 
 * Example:
 * 
 * Input: [
 *   { _id: '1', shiftType: 'morning', startTime: '09:00:00', endTime: '17:00:00' },
 *   { _id: '2', shiftType: 'midDay', startTime: '14:00:00', endTime: '22:00:00' },
 *   { _id: '3', shiftType: 'night', startTime: '22:00:00', endTime: '06:00:00' }
 * ]
 * 
 * Output: [
 *   { _id: '4', shiftType: 'night', startTime: '00:00:00', endTime: '06:00:00' },
 *   { _id: '1', shiftType: 'morning', startTime: '09:00:00', endTime: '17:00:00' },
 *   { _id: '2', shiftType: 'midDay', startTime: '14:00:00', endTime: '22:00:00' },
 *   { _id: '5', shiftType: 'night', startTime: '22:00:00', endTime: '00:00:00' }
 * ]
 * 
 * @param {ShiftTimingType[]} shifts - The array of shift objects to be processed.
 * @returns {ShiftTimingType[]} The processed and sorted array of shifts.
 */
export const splitShiftsAtMidnight = (shifts: ShiftTimingType[]): ShiftTimingType[] => {
    let currentId = shifts.length + 1;  // Initialize the currentId to continue from the existing IDs
    const result: ShiftTimingType[] = [];  // Array to store the final result

    // Iterate through the original shifts
    for (const shift of shifts) {
        const { _id, shiftType, startTime, endTime } = shift;

        // Check if the shift crosses midnight (endTime is less than startTime)
        if (endTime < startTime) {
            // Split the shift into two: before and after midnight
            result.push(
                { _id: currentId.toString(), shiftType, startTime, endTime: `${dayViewHeaderArea.length - 1}:59:59` },  // First part of the shift
                { _id: (++currentId).toString(), shiftType, startTime: '00:00:00', endTime }  // Second part of the shift
            );
            currentId++;  // Increment the ID for the second shift
        } else {
            // If the shift does not cross midnight, add it as is
            result.push({ _id, shiftType, startTime, endTime });
        }
    }

    // Sort the resulting array based on startTime
    result.sort((a, b) => {
        // Prioritize shifts starting at 00:00:00
        if (a.startTime === '00:00:00' && b.startTime !== '00:00:00') return -1;
        if (b.startTime === '00:00:00' && a.startTime !== '00:00:00') return 1;

        // Convert startTime to seconds for proper numerical comparison
        const [aHours, aMinutes, aSeconds] = a.startTime.split(':').map(Number);
        const [bHours, bMinutes, bSeconds] = b.startTime.split(':').map(Number);
        const aTime = aHours * 3600 + aMinutes * 60 + aSeconds;
        const bTime = bHours * 3600 + bMinutes * 60 + bSeconds;
        return aTime - bTime;
    });

    return result;  // Return the sorted and processed array of shifts
};


// --------------------------------------------------------------------------------------------

/**
 * Converts a time string in the format "HH:MM:SS" to a 12-hour format with AM/PM.
 *
 * @param timeString - The time string to convert, in the format "HH:MM:SS".
 * @returns The time string in 12-hour format with AM/PM.
 *
 * @example
 * const formattedTime = formatTimeTo12Hour("00:00:00");
 * console.log(formattedTime); // Output: "12:00 AM"
 */
export const formatTimeTo12Hour = (timeString: string): string => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
    return `${String(adjustedHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${period}`;
};



// ---------------------------------------------------------------------------------------------------

/**
 * Formats a date string into "D Month YYYY" format using a switch case for month names.
 * 
 * @param dateStr - The date string in "YYYY-MM-DD" format.
 * @returns The formatted date string in "D Month YYYY" format.
 * 
 * @example
 * formatDateWithSwitchCase("2024-09-01"); // Returns "1 September 2024"
 * formatDateWithSwitchCase("2023-12-25"); // Returns "25 December 2023"
 */
export const formatDateInMonthName = (dateStr: string): string => {
    const date = new Date(dateStr);

    // Extract day, month (0-based index), and year from the date
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    let monthName = "";

    // Determine the month name using a switch case
    switch (month) {
        case 0:
            monthName = "January";
            break;
        case 1:
            monthName = "February";
            break;
        case 2:
            monthName = "March";
            break;
        case 3:
            monthName = "April";
            break;
        case 4:
            monthName = "May";
            break;
        case 5:
            monthName = "June";
            break;
        case 6:
            monthName = "July";
            break;
        case 7:
            monthName = "August";
            break;
        case 8:
            monthName = "September";
            break;
        case 9:
            monthName = "October";
            break;
        case 10:
            monthName = "November";
            break;
        case 11:
            monthName = "December";
            break;
        default:
            throw new Error("Invalid month");
    }

    // Return the formatted date string
    return `${day} ${monthName} ${year}`;
};