import React, { useEffect, useState } from "react";
import { MsgAttachmentViewContainer } from "../../../../common";
import { Box } from "@mui/material";
import { getLinkPreview } from "link-preview-js";
import { WwwImage } from "../../../../../images";

interface PreviewData {
  title?: string;
  url?: string;
  siteName?: string;
  images?: string[];
}

interface MsgLinkPreviewProps {
  url: string;
}

const MsgLinkPreview: React.FC<MsgLinkPreviewProps> = ({ url }) => {
  const [previewData, setPreviewData] = useState<PreviewData | null>(null);

  useEffect(() => {
    // Fetch link preview data when the component mounts
    getLinkPreview(url)
      .then((data:any) => setPreviewData(data as PreviewData))
      .catch((err:any) => console.error("Error fetching link preview:", err));
  }, [url]);

  return (
    <MsgAttachmentViewContainer
      imgOrSvg={
        <Box
          component={"img"}
          alt="logo"
          src={previewData?.images?.[0] || WwwImage}
          sx={{ height: "25px", width: "25px" }}
        />
      }
      title={previewData?.title || "Untitled"}
      subtitle={url}
      onQuickViewClick={() => null}
    />
  );
};

export default MsgLinkPreview;
