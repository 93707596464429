import { Avatar, SxProps } from "@mui/material";
import React from "react";
import { avatarInitialLetters } from "../../../Utils";


// avatar: "#FF5733|AS" or avatar: <img-url>

interface AvatarNormalProps {
  size: number;
  username?: string | undefined;
  imgSrc?: string;
  avatarColor?: string;
  sx?: SxProps;
  avatarApiData?: string; //"#FF5733|AS" or img-url-string -> this props have priority over all props
}

const AvatarNormal: React.FC<AvatarNormalProps> = ({
  size,
  username,
  avatarColor,
  imgSrc,
  sx,
  avatarApiData,
}) => {
  // Helper to determine if a string is a URL
  const isUrl = (value: string) => {
    try {
      new URL(value);
      return true;
    } catch {
      return false;
    }
  };

  // Process `avatarApiData`
  let dynamicImgSrc = imgSrc;
  let dynamicAvatarColor = avatarColor;
  let dynamicUsernameInitials = avatarInitialLetters(username);

  if (avatarApiData) {
    if (isUrl(avatarApiData)) {
      dynamicImgSrc = avatarApiData; // Use as image URL
    } else if (avatarApiData.startsWith("#")) {
      const [color, initials] = avatarApiData.split("|");
      if (color) {
        dynamicAvatarColor = color; // Set background color
      }
      if (initials) {
        dynamicUsernameInitials = initials.toUpperCase(); // Use initials
      }
    }
  }

  const fontSize = Math.floor(size * 0.4);

  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        backgroundColor: dynamicAvatarColor || "default",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "center",
        font: `normal normal 600 ${fontSize}px/${fontSize}px Source Serif Pro`,
        ...sx,
      }}
      src={dynamicImgSrc}
      alt={dynamicUsernameInitials}
    >
      {!dynamicImgSrc && dynamicUsernameInitials}
    </Avatar>
  );
};

export default AvatarNormal;
