import { Stack } from "@mui/material";
import React from "react";
import {
  AttendanceShiftColorList,
  shiftTiming,
} from "../../AttendanceScheduleSetting";
import {
  calculateShiftColorMetrics,
  splitShiftsAtMidnight,
} from "../../AttendanceTableUtils";
import { ShiftType } from "../../AttendanceScheduleTableType";
import { adjustHexColor } from "../../../../../../../Utils";

const TimeColorArea: React.FC<{
  bgClr: string;
  boxLenght: number;
  leftMargin: number;
  showInBottom?: boolean;
}> = ({ bgClr, leftMargin, boxLenght, showInBottom = false }) => {
  return (
    <Stack
      sx={{
        marginLeft: `${leftMargin}px`,
        bgcolor: bgClr,
        borderRadius: "5px",
        height: "20px",
        width: `${boxLenght}px`,
        position: "absolute",
        alignSelf: showInBottom ? "end" : "start",
      }}
    />
  );
};

interface ShiftColorIndicatorProps {}

const ShiftColorIndicator: React.FC<ShiftColorIndicatorProps> = ({}) => {
  const getBgClr = (shiftName: ShiftType) => {
    switch (shiftName) {
      case "midDay":
        return AttendanceShiftColorList.midDay;
      case "morning":
        return AttendanceShiftColorList.morning;
      case "night":
        return AttendanceShiftColorList.night;

      default:
        return "#ffffff";
    }
  };

  console.log(splitShiftsAtMidnight(shiftTiming));
  return (
    <Stack
      sx={{
        position: "absolute",
        width: "0px",
        height: "44px",
        flexDirection: "row",
        paddingBottom: "2px",
      }}
    >
      {splitShiftsAtMidnight(shiftTiming).map((item, index) => {
        const { length, marginValue } = calculateShiftColorMetrics(
          item.startTime,
          item.endTime
        );
        return (
          <TimeColorArea
            key={index}
            bgClr={adjustHexColor(getBgClr(item.shiftType), 50)}
            leftMargin={marginValue}
            boxLenght={length}
            showInBottom={index % 2 !== 0}
          />
        );
      })}
    </Stack>
  );
};

export default ShiftColorIndicator;
