import React, { useState } from "react";
import { TeamMemberIcon } from "../../../../../../../images";
import { Popover, Stack, Typography } from "@mui/material";
import {
  DropdownWithImageBadge,
  DUMMY_USER_EMAIL_LIST,
  EditableNumberInput,
  TextLinkButton,
} from "../../../../../../common";
import { AssignedEmployeesMenu } from "../../../../common";
import { IconContainer } from "../../../CreateShiftMenu/view";

interface AssignedEmployeesViewProps {}

const AssignedEmployeesView: React.FC<AssignedEmployeesViewProps> = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <IconContainer icon={TeamMemberIcon} title="Assigned Employees">
      <Stack sx={{ gap: "5px", alignItems: "end" }}>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            gap: "10px",
          }}
        >
          {/* border-container */}
          <Stack
            sx={{
              height: "30px",
              flexShrink: 0,
              border: "1px solid #E9EDF2",
              borderRadius: "5px",
              flexDirection: "row",
              alignItems: "center",
              boxSizing: "border-box",
              padding: "0px 5px",
              gap: "5px",
              minWidth: "80px",
            }}
          >
            <EditableNumberInput
              // value={"21"}
              onChange={(value) => console.log(value)}
              // readOnlyComponent
            />
            <Typography
              sx={{
                font: "normal normal 400 11px/14px Source Serif Pro",
                mr: "5px",
              }}
            >
              Required
            </Typography>

            <EditableNumberInput />
            <Typography
              sx={{ font: "normal normal 400 11px/14px Source Serif Pro" }}
            >
              Assigned
            </Typography>
          </Stack>

          <DropdownWithImageBadge
            onChangeSelection={() => null}
            showMaxSelected={1}
            readonlyComponent
            initialData={DUMMY_USER_EMAIL_LIST.slice(2, 8)}
          />
        </Stack>

        <TextLinkButton
          buttonLabel="Edit Details"
          sx={{ width: "fit-content" }}
          onClick={handleClick}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            sx: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
          }}
        >
          <AssignedEmployeesMenu />
        </Popover>
      </Stack>
    </IconContainer>
  );
};

export default AssignedEmployeesView;
