import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BearishOSIcon, FirstLogin2x } from "../../../images";
import g from "./FirstLogin.module.css";
import {
  ButtonXLarge,
  ThreePageMarker,
  ButtonType,
  ButtonXLargeColor,
  useCustomSnackbar,
  BgColorCode,
} from "../../common";
import { Stack, Typography } from "@mui/material";
import { Steppers } from "./components";
import { FirstLoginData, initialState } from "./components/StepsType";
import { useNavigate } from "react-router-dom";
import {
  AuthState,
  // SignUpPayload,
  signupUser,
  tempAuthStoreRequestAction,
  uploadFile__api,
} from "../../../redux";
import { RootState } from "../../../redux/store";
import { updateUserAndOrg__api } from "../../../redux/Slice/auth/authActions";

// Define props interface for FirstLogin component
type FirstLoginProps = {};

/**
 * FirstLogin component responsible for handling the initial login steps.
 * Users provide necessary information to set up their account.
 *
 * @returns FirstLogin component
 */
const FirstLogin: React.FC<FirstLoginProps> = () => {
  const { error, user, isSignUp, email_temp, password_temp } = useSelector(
    (state: RootState) => state.auth as AuthState
  );
  const dispatch = useDispatch();
  // Navigate function for routing
  const navigate = useNavigate();
  const enqueueSnackbar_v1 = useCustomSnackbar();
  useEffect(() => {
    // Check if necessary fields are present
    const isUserDataValid = email_temp || password_temp || user?.data?._id;

    if (!isUserDataValid) {
      // Show error notification
      enqueueSnackbar_v1("error", "Please sign up first to continue.", {
        position: { vertical: "top", horizontal: "center" },
        autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
      });

      // Redirect to signup page after a short delay
      const timer = setTimeout(() => {
        navigate("/signup");
      }, 3000);

      // Cleanup timeout if the component unmounts or dependencies change
      return () => clearTimeout(timer);
    }
    // Disable exhaustive-deps warning for this specific dependency check
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [email_temp, password_temp, user?.data?._id, navigate]);

  // State variables for first login data and current step
  const [firstLoginData, setFirstLoginData] =
    useState<FirstLoginData>(initialState);

  // Populate the state if user data is present
  useEffect(() => {
    if (user) {
      setFirstLoginData((prevState) => ({
        ...prevState,
        firstName: user?.data?.firstName || "",
        lastName: user?.data?.lastName || "",
        avatarFile: user?.data?.avatar || ("" as File | string | null),
        companyName: user?.data?.companyId?.name || "",
        companyNameSearch: user?.data?.companyId?.name || "",
        logo: user?.data?.companyId?.logo || "",
        domain: user?.data?.companyId?.domain || "",
        companyRegistryChecked: user?.data?.companyId ? true : false,
        avatarDefaultColor: BgColorCode.Purple, // Optional, keeping default
        // Add more fields as needed based on user data structure
      }));
    }
  }, [user]);

  // console.log(firstLoginData, "firstLoginData");

  // Function to handle moving between steps
  const [currentStep, setCurrentStep] = useState<number>(1);

  const emails = email_temp;
  const passwords = password_temp;

  const id_found_update_profileAndOrg = async () => {
    try {
      let mediaUrl = "" as string | null | any;

      // Check if avatarFile is a valid URL string
      if (
        typeof firstLoginData?.avatarFile === "string" &&
        isValidURL(firstLoginData.avatarFile)
      ) {
        mediaUrl = firstLoginData.avatarFile;
      } else if (firstLoginData?.avatarFile instanceof File) {
        try {
          // Prepare file upload form data
          const formData = new FormData();
          formData.append("files", firstLoginData.avatarFile);

          // Dispatch file upload action
          const fileUploadResponse: any = await dispatch(
            uploadFile__api(formData)
          );

          // Validate response and extract file path
          if (
            fileUploadResponse?.success &&
            fileUploadResponse?.data?.length > 0
          ) {
            mediaUrl = fileUploadResponse.data[0]?.path || "";
          } else {
            console.error(
              "File upload failed or returned invalid data:",
              fileUploadResponse
            );
          }
        } catch (fileUploadError) {
          console.error("Error uploading file:", fileUploadError);
          mediaUrl = null; // Set to null if upload fails
        }
      } else {
        mediaUrl = null; // Set to null if avatarFile is invalid or missing
      }
      const departmentIds = firstLoginData?.departMent?.map(
        (department) => department?._id
      );
      // Construct the payload
      const payload = {
        firstName: firstLoginData?.firstName || "",
        lastName: firstLoginData?.lastName || "",
        avatarFile: mediaUrl,
        avatarDefaultColor: firstLoginData?.avatarDefaultColor || "",
        companyName: firstLoginData?.companyName || "",
        domain: firstLoginData?.domain || "",
        logo: firstLoginData?.logo || "",
        departMent:
          user?.data?.userType === "Admin" || user?.data?.userType === "Manager"
            ? departmentIds
            : user?.data?.departmentIds || [],
        projectName: firstLoginData?.projectName || "",
        actionType: user?.data?.companyId ? "none" : "create",
      };

      // Validate required fields in payload before dispatching the API call
      if (!payload.firstName || !payload.lastName) {
        console.error("Missing required fields: firstName or lastName");
        return;
      }

      // Dispatch the action to update the user and organization
      try {
        const updateResponse: any = await dispatch(
          updateUserAndOrg__api(payload, user?.data?._id)
        );
        if (!updateResponse?.success) {
          console.error("Update failed:", updateResponse);
        } else {
          navigate(`/dashboard?workOS=true`);
        }
      } catch (updateError) {
        console.error(
          "Error occurred while updating profile and organization:",
          updateError
        );
      }
    } catch (unexpectedError) {
      console.error("Unexpected error occurred:", unexpectedError);
    }
  };

  // Helper function to validate URLs
  const isValidURL = (url: string): boolean => {
    try {
      const parsedUrl = new URL(url);
      return !!parsedUrl.protocol && !!parsedUrl.host;
    } catch {
      return false;
    }
  };

  const handleMoveStep = async (step: number) => {
    if (step === 1 || step === 2 || step === 3) {
      setCurrentStep(step);
    } else if (step === 4) {
      if (user?.data?._id) {
        id_found_update_profileAndOrg();
        return;
      }

      if (!emails || !passwords) {
        // Handle the case where email or password is null
        enqueueSnackbar_v1("error", "Email or password is missing.", {
          position: { vertical: "top", horizontal: "center" },
          autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
        });
        return;
      } else if (
        !firstLoginData.firstName ||
        !firstLoginData.lastName ||
        !firstLoginData.companyName ||
        !firstLoginData.departMent
      ) {
        enqueueSnackbar_v1("error", "All fields are required to be filled.", {
          position: { vertical: "top", horizontal: "center" },
          autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
        });
        return;
      }

      if (!firstLoginData.companyRegistryChecked) {
        enqueueSnackbar_v1(
          "error",
          "Your company is not registered or the registration is incomplete.",
          {
            position: { vertical: "top", horizontal: "center" },
            autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
          }
        );
        return;
      }

      const departmentIds = firstLoginData?.departMent?.map(
        (department) => department._id
      );

      const isValidFile = (file: any) => {
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
        return file && allowedTypes.includes(file.type);
      };

      const formData = new FormData();

      // Append fields to FormData
      formData.append("firstName", firstLoginData.firstName || "");
      formData.append("lastName", firstLoginData.lastName || "");
      formData.append("logo", firstLoginData.logo || "");
      formData.append("email", emails || "");
      formData.append("agreedToTerms", "true"); // FormData accepts only strings or Blob
      // Append departmentIds as separate form data entries if it's an array
      if (Array.isArray(departmentIds)) {
        departmentIds.forEach((id, index) => {
          formData.append(`departmentIds[${index}]`, id);
        });
      } else {
        formData.append("departmentIds", ""); // Handle if departmentIds is empty or undefined
      }

      // Handle avatar conditionally
      if (firstLoginData.avatarFile && isValidFile(firstLoginData.avatarFile)) {
        formData.append("avatar", firstLoginData.avatarFile); // Append file
      } else {
        formData.append(
          "avatar",
          `${firstLoginData.avatarDefaultColor || "#833BC3"}|${firstLoginData.firstName?.charAt(0) || "A"
          }${firstLoginData.lastName?.charAt(0) || "A"}`
        ); // Append string
      }

      // Append remaining fields
      formData.append("companyName", firstLoginData.companyName || "");
      formData.append("domain", firstLoginData?.domain || "");
      formData.append("projectName", firstLoginData?.projectName || "");

      // Dispatch the FormData instead of JSON payload
      const action = signupUser(formData);

      await dispatch(action);
    }
  };

  // Function to determine main text based on current step
  const handleMainText = (currentStep: number): string => {
    if (currentStep === 1) {
      return `Welcome to Bearish OS, What should we call you?`;
    } else if (currentStep === 2) {
      return `What Company and Department are you in?`;
    } else if (currentStep === 3) {
      return `Create your first project`;
    }
    return "";
  };

  useEffect(() => {
    if (!error && isSignUp && user) {
      dispatch(tempAuthStoreRequestAction());
      navigate("/login");
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [error, user, isSignUp, navigate]);

  // Container style for background image
  const containerStyleFirstLogin = {
    backgroundImage: `url(${FirstLogin2x})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div className={`${g.containerFl}`} style={containerStyleFirstLogin}>
      <BearishOSIcon w={75} />

      {/* Main text based on current step */}
      <Typography className={g.firstlogin__main__text}>
        {handleMainText(currentStep)}
      </Typography>

      {/* Stack for layout */}
      <Stack width={"476px"} alignItems={"center"} justifyContent={"center"}>
        {/* Additional text for step 3 */}
        {currentStep === 3 && (
          <Typography
            className={g.currentSte3Italic__main}
            children={`In Bearish OS everything falls into a project, add team members, set budgets, communicate with sake holders, share anything, and so much more`}
          />
        )}

        {/* Steppers component */}
        <Steppers
          currentStep={currentStep}
          firstLoginData={firstLoginData}
          setFirstLoginData={setFirstLoginData}
        />

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={1}
          width={"100%"}
        >
          {/* Button for proceeding to next step */}

          {currentStep !== 1 && (
            <ButtonXLarge
              colorVarient={ButtonXLargeColor.Orange}
              buttonSize="default"
              label={"Previous"}
              fullwidth={true}
              types={ButtonType.Button}
              loaderLogin={false}
              someValue={currentStep - 1}
              onClick={handleMoveStep}
            />
          )}
          <ButtonXLarge
            colorVarient={ButtonXLargeColor.Orange}
            buttonSize="default"
            label={currentStep === 3 ? "Start Now" : "Next"}
            fullwidth={true}
            types={ButtonType.Button}
            loaderLogin={false}
            someValue={currentStep + 1}
            onClick={handleMoveStep}
          />
        </Stack>

        {/* ThreePageMarker component */}
        <Stack width={"100%"} mt={"20px"}>
          <ThreePageMarker
            handleMoveStep={handleMoveStep}
            totalPage={3}
            activePage={currentStep}
          />
        </Stack>
      </Stack>
    </div>
  );
};

export default FirstLogin;
