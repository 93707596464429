import { Drawer, DrawerProps, Stack } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../Utils";

interface RightOpenDrawerProps extends DrawerProps {
  children?: React.ReactNode;
}

const RightOpenDrawer: React.FC<RightOpenDrawerProps> = ({
  children,
  sx,
  ...props
}) => {
  return (
    <Drawer
      anchor={"right"}
      BackdropProps={{
        sx: {
          backgroundColor: adjustHexColor("#000000", 20),
        },
      }}
      sx={{
        zIndex: "1210 !important",
        ...sx,
      }}
      {...props}
    >
      <Stack sx={{ height: "100vh" }}>{children}</Stack>
    </Drawer>
  );
};

export default RightOpenDrawer;
