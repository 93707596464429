import { Button, ButtonProps, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../../Utils";
import { PhoneReciverIcon } from "../../../../../../images";

interface RejectButtonProps extends ButtonProps {}

const RejectButton: React.FC<RejectButtonProps> = ({ ...props }) => {
  return (
    <Button
      sx={{
        minHeight: 0,
        minWidth: 0,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "5px",
        height: "30px",
        width: "95px",
        boxSizing: "border-box",
        flexShrink: 0,
        color: "#FF0000",
        textTransform: "none",
        bgcolor: "#FF0000",
        ":hover": {
          bgcolor: adjustHexColor("#FF0000", 120),
        },
      }}
      {...props}
    >
      <PhoneReciverIcon w={15} color="white" />
      <Typography
        sx={{
          font: "normal normal 600 14px/18px Source Serif Pro",
          color: "white",
        }}
        children={"Reject"}
      />
    </Button>
  );
};

export default RejectButton;
