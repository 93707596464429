import React from "react";
import {
  TextNormalDropdown,
  TextNormalDropdownOptionsType,
} from "../../../../common";

interface LeaveDropDownProps {}

const DUMMY_LeaveDropDownOptions: TextNormalDropdownOptionsType[] = [
  { _id: "1", title: "Paid Time Off", value: "Paid Time Off" },
  { _id: "2", title: "Sick Leave", value: "Sick Leave" },
  { _id: "3", title: "Vacation", value: "Vacation" },
  { _id: "4", title: "Maternity", value: "Maternity" },
  { _id: "5", title: "Bereavement", value: "Bereavement" },
  { _id: "6", title: "Other", value: "Other" },
];

const LeaveDropDown: React.FC<LeaveDropDownProps> = () => {
  return (
    <TextNormalDropdown
      placeholder="Select leave type..."
      options={DUMMY_LeaveDropDownOptions}
      onSelect={(value) => console.log(value)}
    />
  );
};

export default LeaveDropDown;
