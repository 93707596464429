import React from "react";
import { AttendanceScheduleFilterMenuType } from "../../AttendanceScheduleFilterType";
import { Divider, Stack, Typography } from "@mui/material";
import { FilterCheckboxTitle } from "../../../../common";
import { TextLinkButton } from "../../../../../../common";

interface ChildMenuProps {
  options: AttendanceScheduleFilterMenuType;
  mainState: AttendanceScheduleFilterMenuType[];
  setMainState: React.Dispatch<
    React.SetStateAction<AttendanceScheduleFilterMenuType[]>
  >;
}

const ChildMenu: React.FC<ChildMenuProps> = ({
  options,
  mainState,
  setMainState,
}) => {
  const childMenuClicked = (item: AttendanceScheduleFilterMenuType) => {
    setMainState((prevState) => {
      const parentIndex = prevState.findIndex(
        (menuItem) => menuItem._id === options._id
      );

      if (parentIndex !== -1) {
        // Parent item exists, update its subMenuItems
        const updatedParent = { ...prevState[parentIndex] };
        const isChildSelected = updatedParent.subMenuItems?.some(
          (subItem) => subItem._id === item._id
        );

        if (isChildSelected) {
          // Remove child if already selected
          updatedParent.subMenuItems = updatedParent.subMenuItems?.filter(
            (subItem) => subItem._id !== item._id
          );
        } else {
          // Add child if not selected
          updatedParent.subMenuItems = [
            ...(updatedParent.subMenuItems || []),
            item,
          ];
        }

        return [
          ...prevState.slice(0, parentIndex),
          updatedParent,
          ...prevState.slice(parentIndex + 1),
        ];
      } else {
        // Parent item does not exist, add it with the child
        const newParent = {
          ...options,
          subMenuItems: [item],
        };
        return [...prevState, newParent];
      }
    });
  };

  return (
    <Stack
      sx={{
        border: "1px solid #E9EDF2",
        borderRadius: "5px",
        boxSizing: "border-box",
        bgcolor: "#fff",
        padding: "13px 10px 10px 10px",
        width: "fit-content",
        minWidth: "150px",
        maxHeight: "425px",
        gap: "15px",
      }}
    >
      {options.subMenuItems!.map((item, index) => (
        <FilterCheckboxTitle
          key={index}
          isSelected={
            mainState
              .find((menuItem) => menuItem._id === options._id)
              ?.subMenuItems?.some((subItem) => subItem._id === item._id) ||
            false
          }
          item={item}
          label={item.label}
          onCheckboxClick={() => childMenuClicked(item)}
        />
      ))}

      {options.subMenuinfoText && (
        <Typography
          sx={{
            font: "normal italic 300 13px/16px Source Serif Pro",
            color: "#00767B",
            whiteSpace: "wrap",
            width: "125px",
          }}
        >
          {options.subMenuinfoText}
        </Typography>
      )}

      {options.isSubMenuAddable && (
        <Stack sx={{ gap: "10px" }}>
          <Divider />
          <TextLinkButton
            onClick={() => console.log(options)}
            buttonLabel="Create New Type"
            sx={{
              font: "normal normal 300 13px/16px Source Serif Pro",
              color: "#00767B",
              height: "25px",
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ChildMenu;
