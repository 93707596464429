import {Button, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ButtonType} from "../../../../common";
import {MediumButtonSmall} from "../MediumButtonSmall";
import {AgendaFormView} from "./view";
import {PlusIcon} from "../../../../../images";
import {useDispatch, useSelector} from "react-redux";
import {createMeetingAgenda, createOnGoingMeetingAgenda, updateAgendaDetails,} from "../../../../../redux";
import {convertSecondsToTime} from "../../../../../Utils/timeConvertedIntoFromSec";
import {setUpdateAgendaDetailsAction} from "../../../../../redux/Slice/videoCenter/VideoCenterSlice";
import {useTranslation} from "react-i18next";
import {RootState} from "../../../../../redux/store";
import {VideoCenterState} from "../../../../../redux/Slice/videoCenter/VideoCenterTypes";

interface AddAgendaPopupProps {
  userData?: any;
  meetingData?: any;
  editAgendaItem?: any;
  onClose: () => void;
  isOngoingMeeting?: boolean;
}
interface AllottedTime {
  hrs: string;
  mins: string;
}
interface AgendaItem {
  agendaTitle: string;
  agendaDetails: string;
  allottedTime: AllottedTime;
  timeAllotted: number;
  _id?: string;
  description?: string;
  title?: string;
  mediaUrl?: string;
}

const AgendaItem = {
  agendaTitle: "",
  agendaDetails: "",
  allottedTime: { hrs: "0", mins: "0" },
  timeAllotted: 0,
};
const AddAgendaPopup: React.FC<AddAgendaPopupProps> = ({
  userData,
  meetingData,
  editAgendaItem,
  onClose,
  isOngoingMeeting,
}) => {
  // State to keep track of agenda items
  const {t}=useTranslation()
  const [agendaItems, setAgendaItems] = useState<AgendaItem[]>([AgendaItem]); // Initial state with one item
  const [isFormValid, setIsFormValid] = useState(false);
  const { selectedMeeting } = useSelector(
      (state: RootState) => state.videoCenter as VideoCenterState
  );

  const dispatch = useDispatch();

  // Initialize form with editAgendaItem if it exists
  useEffect(() => {
    if (editAgendaItem) {
      const convertedItem = {
        ...editAgendaItem,
        allottedTime: convertSecondsToTime(editAgendaItem.timeAllotted),
      };
      setAgendaItems([convertedItem]);
    }
  }, [editAgendaItem]);
  // Function to handle adding a new agenda item
  const addAgendaItem = () => {
    setAgendaItems([...agendaItems, AgendaItem]);
  };
  // Function to handle form data change
  const handleAgendaChange = (index: number, data: any) => {
    const newAgendaItems = [...agendaItems];
    newAgendaItems[index] = data;
    setAgendaItems(newAgendaItems);
  };

  // Function to handle form submission
  const handleSubmit = () => {
    if (!isFormValid) return; 
    if (editAgendaItem) {
      const payload = {
        title: agendaItems[0]?.title,
        description: agendaItems[0]?.description,
        mediaUrl: agendaItems[0]?.mediaUrl,
        timeAllotted:
          parseInt(agendaItems[0].allottedTime?.hrs || "0") * 3600 +
          parseInt(agendaItems[0].allottedTime?.mins || "0") * 60,
      };
      dispatch(updateAgendaDetails(editAgendaItem._id, payload));
      dispatch(setUpdateAgendaDetailsAction({ agendaId: editAgendaItem._id, payload }));
    } else {
      const payload = agendaItems.map((item) => ({
        meetingId: meetingData?.chimeMeetingInfo?._id || meetingData?._id,
        meetingHostId: meetingData?.meetingHostId || selectedMeeting?.meeting?.createdBy,
        userId: userData?._id,
        title: item?.title,
        description: item?.description,
        mediaUrl: item?.mediaUrl,
        timeAllotted:
          parseInt(item.allottedTime.hrs) * 3600 +
          parseInt(item.allottedTime.mins) * 60,
      }));
      // Create new agenda items
      if(isOngoingMeeting){
        dispatch(createOnGoingMeetingAgenda(payload, meetingData?._id));
      } else {
        dispatch(createMeetingAgenda(payload, meetingData?.chimeMeetingInfo?._id));
      }
    }
    onClose();
  };
  const validateForm = () => {
    const isValid = agendaItems.every(item => 
      item?.title?.trim() !== '' &&
      (parseInt(item?.allottedTime?.hrs) > 0 || parseInt(item?.allottedTime?.mins) > 0)
    );
    setIsFormValid(isValid);
  };

  useEffect(() => {
    validateForm();
  }, [agendaItems]);

  return (
    <Stack
      sx={{
        bgcolor: "white",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
        width: "575px",
        maxHeight: "1050px",
        overflow: "auto",
        scrollbarWidth: "none",
        padding: "20px 20px 15px 20px",
        boxSizing: "border-box",
        gap: "20px",
      }}
    >
      <Stack gap={"15px"}>
        <Typography
          sx={{ font: "normal normal 900 18px/23px Source Serif Pro" }}
        >
          {t('VIDEO_CENTER.AGENDA.ADD_AGENDA_ITEM')}
        </Typography>
        <Typography
          sx={{ font: "normal normal 200 13px/16px Source Serif Pro" }}
        >
          {t('VIDEO_CENTER.AGENDA.DESCRIPTION')}
        </Typography>
      </Stack>

      {agendaItems.map((item, index) => (
        <AgendaFormView
          key={index}
          agendaItem={item}
          setIsFormValid={setIsFormValid}
          onChange={(data: any) => handleAgendaChange(index, data)}
        />
      ))}
      {!editAgendaItem && (<Button
          variant="text"
          onClick={addAgendaItem} // Handle button click
          sx={{
            height: "40px",
            border: "1px dashed #000",
            borderRadius: "5px",
            padding: "0px 10px",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "none",
            color: "black",
            gap: "5px",
            width: "100%",
            flexShrink: 0,
          }}
      >
        <PlusIcon w={13}/>
        <Typography
            sx={{
              font: "normal normal 400 15px/19px Source Serif Pro",
              color: "black",
            }}
        >
          {t('VIDEO_CENTER.AGENDA.ADD_ANOTHER_AGENDA_ITEM')}
        </Typography>
      </Button>)}

      {/* ------------------ */}
      <Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
        <MediumButtonSmall
          label={t('VIDEO_CENTER.AGENDA.SAVE_AGENDA')}
          disabled={!isFormValid}
          types={ButtonType.Button}
          onClick={handleSubmit}
        />
      </Stack>
    </Stack>
  );
};

export default AddAgendaPopup;
