import React, { useRef, useState, useEffect } from "react";
import { IconButton, Stack } from "@mui/material";
import { AllDirArrowCircleIcon } from "../../../../../../../images";

interface MsgImageContainerProps {
  children: React.ReactNode;
}

const MsgImageContainer: React.FC<MsgImageContainerProps> = ({ children }) => {
  const scrollpx: number = 320;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isScrollable, setIsScrollable] = useState(false);

  const updateScrollableState = () => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth } = containerRef.current;
      setIsScrollable(scrollWidth > clientWidth);
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(updateScrollableState);
    const currentRef = containerRef.current;

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    updateScrollableState(); // Update scrollable state when children change
  }, [children]);

  const handleScroll = (direction: "left" | "right") => {
    if (containerRef.current) {
      const scrollAmount = direction === "left" ? -scrollpx : scrollpx;
      containerRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        flex: 1,
        // bgcolor: "pink",
        gap: "10px",
        justifyContent: "space-between",
      }}
    >
      {isScrollable && (
        <IconButton onClick={() => handleScroll("left")}>
          <AllDirArrowCircleIcon direction="left" w={20} />
        </IconButton>
      )}

      <Stack
        ref={containerRef}
        sx={{
          flexDirection: "row",
          flex: 1,
          // bgcolor: "red",
          gap: "10px",
          overflowX: "auto",
          scrollbarWidth: "none",
          scrollBehavior: "smooth",
        }}
      >
        {children}
      </Stack>

      {isScrollable && (
        <IconButton onClick={() => handleScroll("right")}>
          <AllDirArrowCircleIcon direction="right" w={20} />
        </IconButton>
      )}
    </Stack>
  );
};

export default MsgImageContainer;
