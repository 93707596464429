import { Box, Stack } from "@mui/material";
import React from "react";
import { MicIcon } from "../../../../../../../images";

interface CenterMutedViewProps {
  size: number;
}

const CenterMutedView: React.FC<CenterMutedViewProps> = ({ size }) => {
  return (
    <Stack
      sx={{
        height: `${size}px`,
        width: `${size}px`,
        bgcolor: "#775EE2",
        flexShrink: 0,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px",
      }}
    >
      <MicIcon w={size * 0.8} color="white" />
    </Stack>
  );
};

export default CenterMutedView;
