import React from "react";
import {
  TextNormalDropdown,
  TextNormalDropdownOptionsType,
} from "../../../../common";

const languageDropDownOptions: TextNormalDropdownOptionsType[] = [
  {
    _id: "1",
    title: "English",
    value: "English",
  },
  {
    _id: "2",
    title: "Spanish",
    value: "Spanish",
  },
  {
    _id: "3",
    title: "French",
    value: "French",
  },
  {
    _id: "4",
    title: "Italian",
    value: "Italian",
  },
  {
    _id: "5",
    title: "German",
    value: "German",
  },
  {
    _id: "6",
    title: "Arabic",
    value: "Arabic",
  },
  {
    _id: "7",
    title: "Japanese",
    value: "Japanese",
  },
  {
    _id: "8",
    title: "Russian",
    value: "Russian",
  },
];

interface RoleLanguageDropdownProps {}

const RoleLanguageDropdown: React.FC<RoleLanguageDropdownProps> = ({}) => {
  return (
    <TextNormalDropdown
      placeholder="Select language..."
      //   initialValue={languageDropDownOptions[2]}
      options={languageDropDownOptions}
      onSelect={(value) => console.log(value)}
    />
  );
};

export default RoleLanguageDropdown;
