import { ShiftColorListType, ShiftTimingType, VisibleShiftInWeekDaysType } from "./AttendanceScheduleTableType";

export const AttendanceShiftColorList: ShiftColorListType = {
    morning: '#A1FF9F',
    midDay: '#FFDA48',
    night: '#97E9FF'
};

export const shiftTiming: ShiftTimingType[] = [
    { _id: '1', shiftType: 'morning', startTime: '09:00:00', endTime: '17:00:00' }, // here change only this start and end time , make sure time formate not change
    { _id: '2', shiftType: 'midDay', startTime: '14:00:00', endTime: '22:00:00' },
    { _id: '3', shiftType: 'night', startTime: '22:00:00', endTime: '06:00:00' }
]

export const visibleShiftInWeekDays: VisibleShiftInWeekDaysType[] = [
    { _id: '0', dayIndex: 0, dayName: 'sunday', selectedShift: ['morning'] },
    { _id: '1', dayIndex: 1, dayName: 'monday', selectedShift: ['night'] },
    { _id: '2', dayIndex: 2, dayName: 'tuesday', selectedShift: ['morning', 'midDay', 'night'] },
    { _id: '3', dayIndex: 3, dayName: 'wednesday', selectedShift: ['morning', 'midDay', 'night'] },
    { _id: '4', dayIndex: 4, dayName: 'thursday', selectedShift: ['morning', 'midDay', 'night'] },
    { _id: '5', dayIndex: 5, dayName: 'friday', selectedShift: ['morning', 'midDay', 'night'] },
    { _id: '6', dayIndex: 6, dayName: 'saturday', selectedShift: ['morning', 'night'] }
]