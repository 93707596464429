import React, { useState, lazy, Suspense } from "react";
import { Tabs, Tab, Box, Stack } from "@mui/material";
import { styled } from "@mui/system";
import {
  ActivtiyIcon,
  FirstMessageIcon,
  FolderIcon,
  NotesIcon,
  ProjectIcon,
  TeamMemberIcon,
} from "../../../../../images";

const LazyActivityTab = lazy(() => import("./Tabs/ActivityTab/ActivityTab"));
const LazyCommunicationsTab = lazy(
  () => import("./Tabs/CommunicationsTab/CommunicationsTab")
);
const LazyNotesTab = lazy(() => import("./Tabs/NotesTab/NotesTab"));
const LazyPeopleTab = lazy(() => import("./Tabs/PeopleTab/PeopleTab"));
const LazyProjectsTab = lazy(() => import("./Tabs/ProjectsTab/ProjectsTab"));
const LazyResourceTab = lazy(() => import("./Tabs/ResourceTab/ResourceTab")) as React.LazyExoticComponent<
  React.FC<{ contactDetails?: any }>
>;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// inline component
const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        overflowY: "scroll",
        scrollbarWidth: "none",
      }}
    >
      {value === index && children}
    </div>
  );
};

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
});

const StyledTabs = styled(Tabs)({
  padding: "0px 15px",
  boxSizing: "border-box",
  minHeight: 0,
  height: "45px",
  flexShrink: 0,
  borderBottom: "1px solid #E9EDF2",
  "& .MuiTabs-flexContainer": {
    // justifyContent: "space-between",
    gap: "10px",
  },
});

const StyledTab = styled(Tab)({
  flexDirection: "row",
  font: "normal normal 300 15px/21px Source Serif Pro",
  height: "45px",
  minHeight: 0,
  boxSizing: "border-box",
  color: "black !important",
  gap: "5px",
  padding: 0,
  textTransform: "capitalize",
});

interface TimelineTabsProps {
  contactDetails?: any;
  cType?: any;
  
}

const TimelineTabs: React.FC<TimelineTabsProps> = ({
  contactDetails,
  cType,

}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      const newIndex = tabs.findIndex(
        (tab) => tab.label === event.currentTarget.getAttribute("aria-label")
      );
      if (newIndex !== -1) setValue(newIndex);
    }
  };
  const tabs = [
    {
      label: "Activity",
      icon: <ActivtiyIcon w={20} />,
      content: <LazyActivityTab />,
    },
    {
      label: "Communications",
      icon: <FirstMessageIcon w={20} />,
      content: <LazyCommunicationsTab />,
    },
    {
      label: "People",
      icon: <TeamMemberIcon w={20} />,
      content: <LazyPeopleTab />,
    },
    {
      label: "Projects",
      icon: <ProjectIcon w={20} />,
      content: <LazyProjectsTab />,
    },
    {
      label: "Notes",
      icon: <NotesIcon w={20} />,
      content: <LazyNotesTab cType={cType} contactDetails={contactDetails} />,
    },
    {
      label: "Resource",
      icon: <FolderIcon w={20} />,
      content: <LazyResourceTab contactDetails={contactDetails} />,
    },
  ];
  return (
    <Stack
      sx={{
        border: "1px solid #E9EDF2",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        gap: "20px",
      }}
    >
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="timeline tabs"
        TabIndicatorProps={{
          style: { backgroundColor: "#E2875E", bottom: "1px" },
        }}
      >
        {tabs.map((tab, index) => (
          <StyledTab
            key={index}
            disableRipple
            icon={tab.icon}
            label={tab.label}
            {...a11yProps(index)}
            onKeyDown={handleKeyDown}
          />
        ))}
      </StyledTabs>

      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          <Suspense fallback={<div>Loading...</div>}>{tab.content}</Suspense>
        </TabPanel>
      ))}
    </Stack>
  );
};

export default TimelineTabs;
