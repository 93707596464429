import { Button, ButtonProps, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";

interface RedirectButtonsProps extends ButtonProps {
  btnLabel: String;
  btnBgColor?: "white" | "pink";
  btnTextColor?: "black" | "white";
  onClick?: () => void;
}

const RedirectButtons: React.FC<RedirectButtonsProps> = ({
  btnLabel,
  btnBgColor = "white",
  btnTextColor = "black",
  onClick,
  ...props
}) => {
  const getBgColor = (): string => {
    switch (btnBgColor) {
      case "white":
        return "#ffffff";
      case "pink":
        return "#F3206A";
    }
  };

  return (
    <Button
      sx={{
        borderRadius: "5px",
        border: "1px solid #E9EDF2",
        bgcolor: adjustHexColor(getBgColor(), 90),
        ":hover": {
          bgcolor: adjustHexColor(getBgColor(), 110),
        },
        textTransform: "none",
        minWidth: "240px",
        width: "fit-content",
        height: "30px",
        boxSizing: "border-box",
        font: "normal normal 900 13px/16px Source Serif Pro",
        color: "white",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={onClick}
    >
      <Typography
        sx={{ font: "inherit", color: btnTextColor, whiteSpace: "nowrap" }}
      >
        {btnLabel}
      </Typography>
    </Button>
  );
};

export default RedirectButtons;
