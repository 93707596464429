import React from "react";
import { Box, Container, Button, Typography, Chip } from "@mui/material";
import styles from "./SidebarDrawerButtons.module.css"; // Import CSS module
import {
    CommonButtonLabels,
    buttonLabelToIconMapping,
} from "./SidebarDrawerButtons_type";
import { adjustHexColor } from "../../../../../../Utils";

interface SidebarDrawerButtonProps {
    label: CommonButtonLabels;
    isSelected: boolean;
    onClick: () => void;
    icon: any;
    baseColor?: string;
}

const getSoonLabel = (label: string): boolean => {
    // Add labels that are coming soon to this array
    const comingSoonLabels = ["Plan your week"];
    return comingSoonLabels.includes(label);
};

const SidebarDrawerButton: React.FC<SidebarDrawerButtonProps> = ({
    label,
    isSelected,
    onClick,
    icon,
    baseColor = adjustHexColor("#00767B", 25),
}) => {
    const isComingSoon = getSoonLabel(label);

    return (
        <Button
            className={`${styles.SidebarDrawerButton_button} ${isSelected ? styles.SidebarDrawerButton_selected : ""
                }`}
            onClick={onClick}
            variant="text"
            sx={{
                textTransform: "none",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                gap: "8px",
                height: "32px",
                padding: "0px 5px",
                boxSizing: "border-box",
                bgcolor: isSelected ? baseColor : "inherit",
                ":hover": {
                    bgcolor: baseColor,
                },
                transition: "background-color 0.3s ease",
                width: "100%",
            }}
        >
            {icon &&
                (typeof icon === "string" ? (
                    <img src={icon} alt="icon" style={{ width: 20, height: 20 }} />
                ) : (
                    React.createElement(icon, {
                        w: 20,
                        color: label === "Plan your week" ? "#B5005B" : "#000",
                    })
                ))}
            <Typography
                sx={{
                    font: "normal normal 300 13px/16px Source Serif Pro",
                    color: "black",
                    transition: "color 0.3s ease",
                    ":hover": {
                        color: "black",
                    },
                }}
            >
                {label}
            </Typography>

            {isComingSoon && (
                <Chip
                    label="Soon"
                    size="small"
                    sx={{
                        marginLeft: "auto",
                        fontSize: "9px",
                        height: "20px",
                        backgroundColor: "#21B481",
                        color: "#fff",
                        font: "normal normal 400 14px/17px Source Serif Pro",
                    }}
                />
            )}
        </Button>
    );
};

interface SidebarDrawerButtonsProps {
    buttonLabels: CommonButtonLabels[];
    selectedButton: CommonButtonLabels | null;
    onButtonClick: (label: CommonButtonLabels) => void;
    baseColor?: string;
}

const SidebarDrawerButtons: React.FC<SidebarDrawerButtonsProps> = ({
    buttonLabels,
    selectedButton,
    onButtonClick,
    baseColor = "#00767B",
}) => {
    return (
        <Container disableGutters>
            <Box
                sx={{ width: "100%" }}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                {buttonLabels.map((label) => (
                    <Box sx={{ width: "100%" }} key={label} marginBottom={0.9}>
                        <SidebarDrawerButton
                            baseColor={adjustHexColor(baseColor, 25)}
                            label={label}
                            isSelected={selectedButton === label}
                            onClick={() => onButtonClick(label)}
                            icon={buttonLabelToIconMapping[label]} // Pass the icon component or string
                        />
                    </Box>
                ))}
            </Box>
        </Container>
    );
};

export default SidebarDrawerButtons;
