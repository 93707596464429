import React, { memo, useState } from "react";
import {
    Container,
    Box,
    Stack,
    Popover,
    Modal,
    IconButton,
} from "@mui/material";
import { PlusIcon } from "../../../../../../images";
import { CalendarSettingsEditPopup } from "../../../../../pages/Calendar/components";
import { CreateBookingLinks } from "../../../../../pages/Calendar/components_v2_0_1";

interface PlusButtonsProps {
    plusType?: "yourCalendars" | "sharedCalendars" | "bookingLinks";
    popOverOrModalType?: "popover" | "modal";
    onActionClick?: (type: string) => void; // Callback function for actions
}

const PlusButtons: React.FC<PlusButtonsProps> = ({
    plusType = "yourCalendars",
    popOverOrModalType = "popover",
    onActionClick,
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isModalOpen, setModalOpen] = useState(false);

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event?.preventDefault();
        event?.stopPropagation();
        if (popOverOrModalType === "popover") {
            setAnchorEl(event?.currentTarget);
        } else if (popOverOrModalType === "modal") {
            setModalOpen(true);
        }
    };

    const handleClosePopover = (event: React.MouseEvent | React.TouchEvent) => {
        event?.stopPropagation();
        setAnchorEl(null);
    };

    const handleCloseModal = (event?: React.MouseEvent | React.TouchEvent) => {
        event?.stopPropagation();
        setModalOpen(false);
    };

    const isPopoverOpen = Boolean(anchorEl);

    // Map components based on `plusType`
    const componentMap: Record<string, JSX.Element> = {
        yourCalendars: (
            <React.Suspense fallback={<div>Loading Sidebar...</div>}>
                <CalendarSettingsEditPopup
                    setZoomType={(v: any) => {
                        handleCloseModal();
                    }}
                    createTrue={true}
                />{" "}
            </React.Suspense>
        ),
        sharedCalendars: (
            <React.Suspense fallback={<div>Loading Sidebar...</div>}>
                <CalendarSettingsEditPopup
                    setZoomType={(v: any) => {
                        handleCloseModal();
                    }}
                    createTrue={true}
                />{" "}
            </React.Suspense>
        ),
        bookingLinks: (
            <React.Suspense fallback={<div>Loading 1...</div>}>
                <CreateBookingLinks handleCloseModal={handleCloseModal} />
            </React.Suspense>
        ),
    };

    const SelectedComponent = componentMap[plusType] || null;

    return (
        <Container disableGutters>
            <Box display="flex" justifyContent="center" alignItems="center">
                <Stack
                    width="100%"
                    sx={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <IconButton
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                            handleButtonClick(e)
                        }
                    >
                        <PlusIcon w={10} />
                    </IconButton>
                </Stack>

                {/* Popover */}
                {popOverOrModalType === "popover" && (
                    <Popover
                        open={isPopoverOpen}
                        anchorEl={anchorEl}
                        onClose={handleClosePopover}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        <Box
                            onMouseDown={(e: React.MouseEvent) => {
                                e.stopPropagation();
                            }}
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                            }}
                        >
                            {SelectedComponent}
                        </Box>
                    </Popover>
                )}

                {/* Modal */}
                {popOverOrModalType === "modal" && (
                    <Modal
                        open={isModalOpen}
                        onClose={(e: React.MouseEvent | React.TouchEvent) =>
                            handleCloseModal(e)
                        }
                    >
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                bgcolor: "background.paper",
                                boxShadow: 24,
                                borderRadius: 1,
                                width: "fit-content",
                            }}
                            onMouseDown={(e: React.MouseEvent) => {
                                e.stopPropagation();
                            }}
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                            }}
                        >
                            {SelectedComponent}
                        </Box>
                    </Modal>
                )}
            </Box>
        </Container>
    );
};

export default memo(PlusButtons);
