import React, { useState } from "react";
import {
  MainActionButton,
  MainActionColor,
  RightOpenDrawer,
  SmallThreeDotMenuListInterface,
} from "../../../../common";
import { Menu, MenuItem, Typography } from "@mui/material";
import {
  BalancesIcon,
  ShiftIcon,
  TableIcon,
  TravelIcon,
} from "../../../../../images";
import { useTranslation } from "react-i18next";
import { CreateShiftMenu } from "../CreateShiftMenu";
import { TimeOffRequestMenu } from "../TimeOffRequestMenu";
import { NewEventOrBreakMenu } from "../NewEventOrBreakMenu";
import { CreateRoleMenu } from "../CreateRoleMenu";

enum MenuText {
  NewRole = "New Role",
  ShiftTemplate = "Shift Template",
  TimeOffRequest = "Time off Request",
  EventOrBreak = "Event or Break",
}

const MENU_LISTS: SmallThreeDotMenuListInterface[] = [
  {
    id: 1,
    icon: ShiftIcon,
    text: MenuText.NewRole,
    LANG: "New Role",
  },
  {
    id: 2,
    icon: TableIcon,
    text: MenuText.ShiftTemplate,
    LANG: "Shift Template",
  },
  {
    id: 3,
    icon: BalancesIcon,
    text: MenuText.TimeOffRequest,
    LANG: "Time off Request",
  },
  {
    id: 4,
    icon: TravelIcon,
    text: MenuText.EventOrBreak,
    LANG: "Event or Break",
  },
];

const TraxDrawerMenu: React.FC = () => {
  const { t } = useTranslation();

  const [newRoletDrawer, setNewRoletDrawer] = useState<boolean>(false);
  const [timeOffDrawer, setTimeOffDrawer] = useState<boolean>(false);
  const [newBreakDrawer, setNewBreakDrawer] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickhandler = (item: SmallThreeDotMenuListInterface) => {
    optionHandler(item.text! as MenuText);
    handleClose();
  };

  const closeDrawer = () => {
    setNewRoletDrawer(false);
    setTimeOffDrawer(false);
    setNewBreakDrawer(false);
  };

  const optionHandler = (value: MenuText) => {
    switch (value) {
      case MenuText.NewRole:
        setNewRoletDrawer(true);
        break;
      case MenuText.ShiftTemplate:
        console.log("clickedsdfasd");
        break;
      case MenuText.TimeOffRequest:
        setTimeOffDrawer(true);
        break;
      case MenuText.EventOrBreak:
        setNewBreakDrawer(true);
        break;
    }
  };

  return (
    <div>
      <MainActionButton
        colorVarient={MainActionColor.TraxBluish}
        label={t("VIDEO_CENTER.CREATE")}
        icon="editIcon"
        onClick={handleClick}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "5px",
            mt: "10px",
            border: "1px solid #E9EDF2",
          },
        }}
      >
        {MENU_LISTS.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => clickhandler(item)}
            sx={{
              padding: "0px 10px",
              alignItems: "center",
              gap: "5px",
              height: "30px",
            }}
          >
            {React.createElement(item.icon!, { w: 15, color: "black" })}
            <Typography
              sx={{
                color: item.textColor || "black",
                font: "normal normal 300 13px/16px Source Serif Pro",
              }}
            >
              {item?.LANG}
            </Typography>
          </MenuItem>
        ))}
      </Menu>

      {/* -------  new Role drawer --------- */}
      <RightOpenDrawer open={newRoletDrawer} onClose={closeDrawer}>
        <CreateRoleMenu onCloseClick={closeDrawer} />
        {/* <CreateShiftMenu onCloseClick={closeDrawer} /> */}
      </RightOpenDrawer>

      {/* -------  Time off request drawer --------- */}
      <RightOpenDrawer open={timeOffDrawer} onClose={closeDrawer}>
        <TimeOffRequestMenu onCloseClick={closeDrawer} />
      </RightOpenDrawer>

      {/* -------  Time off request drawer --------- */}
      <RightOpenDrawer open={newBreakDrawer} onClose={closeDrawer}>
        <NewEventOrBreakMenu />
      </RightOpenDrawer>
    </div>
  );
};

export default TraxDrawerMenu;
