import { Stack, Popover } from "@mui/material";
import React, { useState } from "react";
import { DottedButton } from "../DottedButton";
import { FirstMessageIcon } from "../../../images";
import { DragAndDropMenu, DragAndDropMenuListType } from "../DragAndDropMenu";
import { connectCommunicationOptions } from "./ConnectCommunicationDropdownType";
import { useTranslation } from "react-i18next";

interface ConnectCommunicationDropdownProps {
  options?: DragAndDropMenuListType[];
}

const ConnectCommunicationDropdown: React.FC<
  ConnectCommunicationDropdownProps
> = ({ options = connectCommunicationOptions }) => {
    const {t}=useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOptions, setMenuOptions] =
    useState<DragAndDropMenuListType[]>(options);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;
  return (
    <Stack>
      <DottedButton
        icon={FirstMessageIcon}
        title={t('VIDEO_CENTER.MEETING.CONNECT_COMMUNICATION')}
        aria-describedby={id}
        onClick={handleButtonClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            padding: 0,
            width: "250px",
            boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.16)",
            marginTop: "10px",
          },
        }}
      >
        <DragAndDropMenu
          menuItems={menuOptions}
          setMenuItems={(newValue) => setMenuOptions(newValue)}
          dragIconPlace="left"
          label={t('VIDEO_CENTER.MEETING.CONNECT_COMMUNICATION')}
          multiple
          onMenuSelect={(value) => {
            // console.log(value)
          }}
        />
      </Popover>
    </Stack>
  );
};

export default ConnectCommunicationDropdown;
