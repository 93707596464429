import React, { memo } from 'react';
import { Container, Box } from '@mui/material';

interface PlanYourDaysProps {
  // Define your props here
}

const PlanYourDays: React.FC<PlanYourDaysProps> = () => {
  return (
    <Container
      sx={{
        maxWidth: 'none !important',
        marginRight: 'unset',
        width: 'unset',
        marginLeft: 'unset',
      }}
      disableGutters
    >
      <Box display='flex' justifyContent='center' alignItems='center' width={'100%'}>
       Comming soon...
      </Box>
    </Container>
  );
};

export default memo(PlanYourDays);