import { Stack, Typography } from "@mui/material";
import React from "react";

export interface MiniPopupTabIndicatorOptionsProps {
  id: number;
  label: string;
  icon?: React.ElementType;
}

interface MiniPopupTabIndicatorProps {
  options: MiniPopupTabIndicatorOptionsProps[];
  activePage: number;
  setCurrentPage: (pageid: number) => void;
  tabHeilightColor?: string;
  tabUnHeilightColor?: string;
  indicatorPosition?: "top" | "bottom";
}

const MiniPopupTabIndicator: React.FC<MiniPopupTabIndicatorProps> = ({
  activePage = 1,
  setCurrentPage,
  indicatorPosition = "top",
  tabHeilightColor = "#B5005B",
  tabUnHeilightColor = "#B9B9B9",
  options,
}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        // justifyContent: "space-between",
        gap: "10px",
      }}
    >
      {options.map((page) => (
        <Stack
          key={page.id}
          onClick={() => setCurrentPage(page.id)}
          sx={{
            maxWidth: "33%",
            flex: 1,
            cursor: "pointer",
            gap: "5px",
            flexDirection:
              indicatorPosition === "top" ? "column" : "column-reverse",
          }}
        >
          <Stack
            sx={{
              height: "2px",
              borderRadius: "5px",
              width: "100%",
              bgcolor:
                activePage === page.id ? tabHeilightColor : tabUnHeilightColor,
            }}
          />
          <Typography
            sx={{
              font: "normal normal 400 11px/14px Source Serif Pro",
              whiteSpace: "nowrap",
            }}
          >
            {page.label}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default MiniPopupTabIndicator;
