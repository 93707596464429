import React, { useState } from "react";
import { Box, Grow, Stack } from "@mui/material";
import { DummyImage } from "../../../images";
import {
  ActiveProjectTab,
  LargeCardAndImage,
  LargeStackedAndImage,
  MainTopProject,
} from "./components";
import { ViewMode } from "./types";
import { SearchContainer } from "../SearchContainer";

/**
 * Interface representing the structure of project data.
 */
interface ProjectData {
  title: string; // Title of the project
  dateCreated: string; // Date when the project was created
  imageUrl: string; // URL of the project's image
  sharedProject: boolean; // Indicates whether the project is shared or not
}

/**
 * Styles for the container box that holds project cards.
 */
const BOX_STYLE = {
  maxHeight: "81.5vh", // Maximum height of the container box
  overflow: "hidden", // Hide overflowing content
  overflowX: "hidden", // Hide horizontal overflow
  overflowY: "scroll", // Enable vertical scrolling for overflow
  "&::-webkit-scrollbar": {
    width: "5px", // Width of the scrollbar
    borderRadius: "4px", // Border radius of the scrollbar
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "gray", // Color of the scrollbar thumb
    borderRadius: "4px", // Border radius of the scrollbar thumb
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "lightgray", // Color of the scrollbar track
    borderRadius: "8px", // Border radius of the scrollbar track
  },
};

/**
 * Sticky tab styles for the top navigation tabs.
 */
const STICKY_TAB_STYLE = {
  position: "sticky", // Stick to the top of the container
  top: 0, // Stick to the very top of the container
  zIndex: 1000, // Ensure it's above other content
  background: "white", // Background color of the sticky tab
  marginTop: "10px", // Space above the sticky tab
};

/**
 * Main component for managing and displaying project cards.
 */
const Project: React.FC = () => {
  // Generate sample project data
  const largeCardsData: ProjectData[] = Array.from(
    { length: 20 },
    (_, index) => ({
      title: `Project ${index + 1}`,
      dateCreated: "10-10-2024",
      imageUrl: DummyImage,
      sharedProject: index % 2 === 0, // Alternate between shared and non-shared
    })
  );

  const [tabActive, setTabActive] = useState(0); // State to track active tab index

  /**
   * Handles tab change event.
   * @param newTab - Index of the newly selected tab.
   */
  const handleTabChange = (newTab: number) => {
    setTabActive(newTab);
  };

  // Filter projects into shared and non-shared categories
  const sharedProjects = largeCardsData.filter((card) => card.sharedProject);
  const nonSharedProjects = largeCardsData.filter(
    (card) => !card.sharedProject
  );
  const [viewMode, setViewMode] = useState<ViewMode>("square");

  return (
    <Box>
      {/* Top section with main project content */}
      <Stack p="30px 25px 2px 25px">
        <MainTopProject viewMode={viewMode} setViewMode={setViewMode} />
      </Stack>
      <SearchContainer />
      {/* Container box for displaying project cards */}
      <Box sx={BOX_STYLE}>
        {/* Sticky tabs for switching between project views */}
        <Box sx={{ ...STICKY_TAB_STYLE, padding: "10px 0px 0px 25px" }}>
          <Stack>
            <ActiveProjectTab
              tabActive={tabActive}
              setTabActive={handleTabChange}
            />
          </Stack>
        </Box>

        {/* Stack container for displaying project cards */}
        <Stack
          mt="10px"
          direction={viewMode === "square" ? "row" : "column"}
          alignItems="center"
          justifyContent="flex-start"
          gap={1}
          flexWrap="wrap"
          sx={{ padding: "10px" }}
        >
          {/* Grow animation for shared projects */}
          {sharedProjects.map((card, index) => (
            <Grow
              key={index}
              in={tabActive === 1} // Only animate if active tab is for shared projects
              {...(tabActive === 1 ? { timeout: 500 } : {})}
              mountOnEnter // Mount component when entering the view
              unmountOnExit // Unmount component when leaving the view
              style={{
                transformOrigin: "top",
              }} // Optional: Apply transform origin
            >
              <div style={{ width: viewMode !== "square" ? "100%" : "auto" }}>
                <SharedProjectCard
                  title={card.title}
                  dateCreated={card.dateCreated}
                  imageUrl={card.imageUrl}
                  viewMode={viewMode}
                />
              </div>
            </Grow>
          ))}

          {/* Grow animation for non-shared projects */}
          {nonSharedProjects.map((card, index) => (
            <Grow
              key={index}
              in={tabActive === 0} // Only animate if active tab is for non-shared projects
              {...(tabActive === 0 ? { timeout: 500 } : {})}
              mountOnEnter // Mount component when entering the view
              unmountOnExit // Unmount component when leaving the view
              style={{
                transformOrigin: "top",
              }}
            >
              <div style={{ width: viewMode !== "square" ? "100%" : "auto" }}>
                <NonSharedProjectCard
                  title={card.title}
                  dateCreated={card.dateCreated}
                  imageUrl={card.imageUrl}
                  viewMode={viewMode}
                />
              </div>
            </Grow>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

/**
 * Props interface for the card components.
 */
interface CardProps {
  title: string; // Title of the project card
  dateCreated: string; // Date when the project was created
  imageUrl: string; // URL of the project's image
  viewMode: string; // View mode of the project
}

/**
 * Component for displaying a shared project card.
 * @param title - Title of the project.
 * @param dateCreated - Date when the project was created.
 * @param imageUrl - URL of the project's image.
 * @param viewMode - View mode of the project
 */
const SharedProjectCard: React.FC<CardProps> = ({
  title,
  dateCreated,
  imageUrl,
  viewMode,
}) => (
  <>
    {viewMode === "square" ? (
      <LargeCardAndImage
        title={title}
        dateCreated={dateCreated}
        imageUrl={imageUrl} _id={'workspace-id'}
      />
    ) : (
      <LargeStackedAndImage
        projectName={title}
        dateCreated={dateCreated}
        imageUrl={imageUrl} _id={'workspace-id'}
      />
    )}
  </>
);

/**
 * Component for displaying a non-shared project card.
 * @param title - Title of the project.
 * @param dateCreated - Date when the project was created.
 * @param imageUrl - URL of the project's image.
 * @param viewMode - View mode of the project
 */
const NonSharedProjectCard: React.FC<CardProps> = ({
  title,
  dateCreated,
  imageUrl,
  viewMode,
}) => (
  <>
    {viewMode === "square" ? (
      <LargeCardAndImage
        title={title}
        dateCreated={dateCreated} _id={'workspace-id'}
        imageUrl={imageUrl}
      />
    ) : (
      <LargeStackedAndImage
        projectName={title}
        dateCreated={dateCreated}
        imageUrl={imageUrl} _id={'workspace-id'}
      />
    )}
  </>
);

export default Project;
