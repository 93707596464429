import {Divider, ListItem, Stack, Typography} from "@mui/material";
import React from "react";
import styles from "./MessageDetailsMenus.module.css";
import {MessageDetailsMenusListInterface} from "./MessageDetailsMenusType";
import {useTranslation} from "react-i18next";

interface MessageDetailsMenusProps {
    options?: MessageDetailsMenusListInterface[];
    handleClick?: (event: any, item: any) => void;
}

const MessageDetailsMenus: React.FC<MessageDetailsMenusProps> = ({
                                                                     options,
                                                                     handleClick
                                                                 }) => {
    const {t} = useTranslation()

    return (
        <Stack className={styles["message-details-menus"]}>
            {options?.map((item, index) => {
                const isFirst = index === 0;
                const isLast = index === options?.length - 1;
                return item.divider ? (
                    <Divider
                        key={index}
                        className={styles["message-details-menus__divider"]}
                    />
                ) : (
                    <ListItem
                        button
                        onClick={(event: any) => handleClick ? handleClick(event, item) : null}
                        key={index}
                        className={`${styles["message-details-menus__item"]} ${
                            isFirst
                                ? styles["message-details-menus__item--first"]
                                : isLast
                                    ? styles["message-details-menus__item--last"]
                                    : ""
                        }`}
                    >
                        <Typography
                            className={styles["message-details-menus__text"]}
                            sx={{color: item.textColor}}
                        >
                            {t(item.LANG)}
                        </Typography>
                    </ListItem>
                );
            })}
        </Stack>
    );
};

export default MessageDetailsMenus;
