import { PayloadAction } from "@reduxjs/toolkit";
import { ComponentItem, ContentItem, WorkspaceState } from "../workspaceTypes";
import {
  getAllSharedWorkspace__Failure,
  getAllWorkspace__Failure,
  getAlTask__Failure,
} from "./WorkspaceSliceFailure";
export const createWorkspace__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Helper function to merge and deduplicate spaces with safety checks and added platform information
const mergeSpaces = (
  state: WorkspaceState,
  newSpaces: any[],
  source: string, // New source parameter (e.g., "Confluence" or "BearishOS")
  platform: string // Added platform to track the origin
) => {
  // Ensure newSpaces is always an array
  const updatedSpaces = Array.isArray(newSpaces)
    ? newSpaces.map((space: any) => ({
      ...space,
      source, // Set source (e.g., "App" or "Confluence")
      platform, // Set platform (e.g., "Confluence" or "BearishOS")
      isSelected: false, // Default selection state
    }))
    : []; // If not an array, use an empty array

  // Maintain a map for deduplication based on space id or key
  const spaceMap = new Map(
    (state?.workspaceList?.workspaceList || [])?.map((space: any) => [
      space?._id || space?.key,
      space,
    ])
  );

  // Merge new spaces into the map
  updatedSpaces.forEach((space: any) => {
    spaceMap.set(space._id || space.key, { ...space, source, platform });
  });

  // Update the state with the merged spaces
  state.workspaceList.workspaceList = Array.from(spaceMap.values());
};

// List App Spaces Success
export const getAllWorkspace__Success = (
  state: WorkspaceState,
  action: PayloadAction<{ data: any[] }>
) => {
  const data = action?.payload?.data;

  if (data.length === 0) {
    getAllWorkspace__Failure(state, action as any);
    state.error = "No workspace found";
    return;
  }

  // Merge app spaces with existing spaces
  mergeSpaces(state, data, "BearishOS", "BearishOS");

  state.workspaceList = {
    workspaceList: state.workspaceList.workspaceList,
    api_call_timeStamp: new Date().toISOString(),
    is_api_called: false,
    WorkspaceListError: null,
  };
};

// List Confluence Spaces Success
export const ListSpaces__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  const data = action?.payload?.data;

  if (data?.length === 0) {
    state.error = "No Confluence workspaces found";
    return;
  }

  // Merge Confluence spaces with existing spaces
  mergeSpaces(state, data, "Confluence", "Confluence");

  // Update the state with the merged list
  state.workspaceList = {
    workspaceList: state.workspaceList.workspaceList,
    api_call_timeStamp: new Date().toISOString(),
    is_api_called: false,
    WorkspaceListError: null,
  };
};

// Get All Projects Success
export const GetAllProjects__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  const data = action?.payload?.data;

  if (data?.length === 0) {
    state.error = "No Jira workspaces found";
    return;
  }

  // Merge Confluence spaces with existing spaces
  mergeSpaces(state, data, "Jira", "Jira");

  // Update the state with the merged list
  state.workspaceList = {
    workspaceList: state.workspaceList.workspaceList,
    api_call_timeStamp: new Date().toISOString(),
    is_api_called: false,
    WorkspaceListError: null,
  };
};

export const getAllSharedWorkspace__Success = (
  state: WorkspaceState,
  action: PayloadAction<{ data: any[] }>
) => {
  const data = action?.payload?.data;

  if (data.length === 0) {
    getAllSharedWorkspace__Failure(state, action as any);
    state.error = "No Shared workspace found";
    return;
  }

  state.sharedWorkspaceList = {
    workspaceSharedList: data,
    WorkspaceSharedListError: null,
  };
};

export const getOneWorkspace__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.insideWorkspaceItem = action?.payload?.data;
  state.data = action?.payload?.data;
};

export const updateWorkspace__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  const updatedWorkspace = action?.payload?.data;

  if (!updatedWorkspace || !updatedWorkspace._id) {
    console.error("Update failed: No workspace data or _id present");
    return;
  }

  // Update workspace data
  state.data = updatedWorkspace;

  // Update workspaceList with the new data
  if (state.workspaceList.workspaceList) {
    state.workspaceList.workspaceList = state.workspaceList.workspaceList.map(
      (workspace) =>
        workspace._id === updatedWorkspace._id ? updatedWorkspace : workspace
    );
  }
};

export const deleteWorkspace__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceContent__Success = (
  state: WorkspaceState,
  action: PayloadAction<{ data: { _id: string; content: ContentItem[] } }>
) => {
  const newData = action.payload.data;
  const workspaceId = newData._id;
  const newContentArray: ContentItem[] = newData.content;

  state.workspaceList.workspaceList = state.workspaceList.workspaceList.map(
    (workspace) => {
      if (workspace._id === workspaceId) {
        // Filter out duplicate content
        const existingContentIds = new Set(
          workspace.content.map((item) => item._id)
        );
        const filteredNewContent = newContentArray.filter(
          (newItem: ContentItem) => !existingContentIds.has(newItem._id)
        );

        return {
          ...workspace,
          content: [...workspace.content, ...filteredNewContent],
          updatedAt: new Date().toISOString(), // Optionally update the timestamp
        };
      }
      return workspace;
    }
  );

  state.workspaceContentUpdated = newContentArray;
  state.data = newContentArray;
};

export const reOrderWorkspaceContent__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateWorkspaceContent__Success = (
  state: WorkspaceState,
  action: PayloadAction<{ data: any }>
) => {
  const updatedData = action.payload.data;
  const workspaceId = updatedData._id;
  const updatedContent = updatedData.content;

  state.workspaceList.workspaceList = state.workspaceList.workspaceList.map(
    (workspace) => {
      if (workspace._id === workspaceId) {
        return {
          ...workspace,
          content: workspace.content.map((item) =>
            item._id === updatedContent._id
              ? { ...item, ...updatedContent }
              : item
          ),
          updatedAt: new Date().toISOString(), // Optionally update the timestamp
        };
      } else {
        console.warn("not found workspace " + workspaceId);
      }
      return workspace;
    }
  );

  state.workspaceContentUpdated = updatedContent;
};

export const deleteWorkspaceContent__Success = (
  state: WorkspaceState,
  action: PayloadAction<{ data: any }>
) => {
  const contentId: string = action?.payload?.data?.contentId;

  // Iterate through each workspace and update the content array
  state.workspaceList.workspaceList = state.workspaceList.workspaceList.map(
    (workspace) => {
      if (workspace.content) {
        return {
          ...workspace,
          content: workspace.content.map((item) =>
            item._id === contentId
              ? { ...item, isDeleted: true } // Mark item as deleted
              : item
          ),
          updatedAt: new Date().toISOString(), // Optionally update the timestamp
        };
      }
      return workspace;
    }
  );

  state.workspaceContentUpdated = { contentId }; // Store the contentId of the deleted item
};
export const addWorkspaceMember__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllWorkspaceMember__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.memberOfWorkspace = action?.payload?.data;
};

export const updateWorkspaceMember__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteWorkspaceMember__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceCustomField__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateWorkspaceCustomField__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteWorkspaceCustomField__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceTag__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateWorkspaceTag__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteWorkspaceTag__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceTemplate__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const applyWorkspaceTemplate__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceIntegration__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateWorkspaceIntegration__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteWorkspaceIntegration__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceActivity__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllWorkspaceActivity__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateWorkspaceColumn__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceConnection__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceReminder__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceVideoReport__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceFile__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceMedia__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceBookMark__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceTableOfContent__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceEquation__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceButton__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceSyncElement__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceHeadingToggle__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addWorkspaceCodeBlock__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteWorkspaceInBulk__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addGrid__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllGrids__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const getOneGrid__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const updateOneGrid__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const deleteGrid__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const addSheet__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const updateSheet__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const deleteSheet__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const reorderSheets__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const updateCell__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addComponent__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllComponents__Success = (
  state: WorkspaceState,
  action: PayloadAction<{ data: ComponentItem[] }>
) => {
  const { data } = action.payload;

  data.forEach((item) => {
    const workspaceId = item.workspaceId;

    // Validate that the item contains necessary fields
    if (!workspaceId || !item._id || !item.name || !item.componentType) {
      console.error("Invalid item data:", item);
      return;
    }

    if (!state.componentsData[workspaceId]) {
      state.componentsData[workspaceId] = {
        loading: false,
        data: { items: [] },
      };
    } else {
      state.componentsData[workspaceId].loading = false;
    }

    const existingIds = new Set(
      state.componentsData[workspaceId].data.items.map((i) => i._id)
    );

    if (!existingIds.has(item._id)) {
      state.componentsData[workspaceId].data.items.push(item);
    }
  });
};

export const getOneComponent__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
  state.insideWorkspaceComponentData = action?.payload?.data;
};
export const updateOneComponent__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const deleteOneComponent__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const deleteBulkComponents__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const createTask__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getOneTask__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateOneTask__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteOneTask__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const getAllTasks__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const createSubTask__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllSubTasks__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteBulkTasks__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const AddWhiteboard__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const GetAllWhiteboards__Success = (
  state: WorkspaceState,
  action: PayloadAction<{ data: ComponentItem[] }>
) => {
  const { data } = action.payload;
  data.forEach((item) => {
    const { workspaceId, _id, title } = item;

    // Validate that the item contains necessary fields
    if (!workspaceId || !_id || !title) {
      console.error("Invalid item data:", item);
      return;
    }

    // Set the componentType manually
    const componentType = "Whiteboard";

    // Create a new item with the componentType
    const updatedItem = {
      ...item,
      title, // Use title from API response
      componentType, // Manually set the componentType
    };

    if (!state.componentsData[workspaceId]) {
      state.componentsData[workspaceId] = {
        loading: false,
        data: { items: [] },
      };
    } else {
      state.componentsData[workspaceId].loading = false;
    }

    const existingIds = new Set(
      state.componentsData[workspaceId].data.items.map((i) => i._id)
    );

    if (!existingIds.has(_id)) {
      state.componentsData[workspaceId].data.items.push(updatedItem);
    }
  });
};

export const GetOneWhiteboard__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.insideWorkspaceComponentData = action?.payload?.data;
  state.data = action?.payload?.data;
};

export const UpdateWhiteboard__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const DeleteWhiteboard__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const AddObject__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const UpdateObject__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const DeleteObject__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const AddCollaborator__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const GetAllCollaborators__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const UpdateCollaborator__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const DeleteCollaborator__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const CreateVersion__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const GetAllVersions__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const RestoreVersion__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const addDocs__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllDocs__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  const { data } = action.payload;
  data.forEach((item: any) => {
    const { workspaceId, _id, title } = item;

    // Validate that the item contains necessary fields
    if (!workspaceId || !_id || !title) {
      console.error("Invalid item data:", item);
      return;
    }

    // Set the componentType manually
    const componentType = "Document";

    // Create a new item with the componentType
    const updatedItem = {
      ...item,
      title, // Use title from API response
      componentType, // Manually set the componentType
    };

    if (!state.componentsData[workspaceId]) {
      state.componentsData[workspaceId] = {
        loading: false,
        data: { items: [] },
      };
    } else {
      state.componentsData[workspaceId].loading = false;
    }

    const existingIds = new Set(
      state.componentsData[workspaceId].data.items.map((i) => i._id)
    );

    if (!existingIds.has(_id)) {
      state.componentsData[workspaceId].data.items.push(updatedItem);
    }
  });
};

export const getOneDoc__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.insideWorkspaceComponentData = action?.payload?.data;
  state.data = action?.payload?.data;
};

export const updateOneDoc__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Add Wiki Success
export const AddWiki__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Get All Wikis Success
export const GetAllWikis__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  const { data } = action?.payload;
  data.forEach((item: any) => {
    const { workspaceId, _id, name, pages = [] } = item;

    // Validate that the item contains necessary fields
    if (!workspaceId || !_id || !name) {
      console.error("Invalid item data:", item);
      return;
    }

    // Set the componentType manually
    const componentType = "Wiki";

    // Create a new item with the componentType
    const updatedItem = {
      ...item,
      name, // Use name from API response
      componentType, // Manually set the componentType
    };

    // Initialize the workspace if it doesn't exist
    if (!state?.componentsData[workspaceId]) {
      state.componentsData[workspaceId] = {
        loading: false,
        data: { items: [] },
      };
    } else {
      state.componentsData[workspaceId].loading = false;
    }

    // Find if the wiki item already exists
    const existingItemIndex = state.componentsData[
      workspaceId
    ].data.items.findIndex((i) => i._id === _id);

    if (existingItemIndex === -1) {
      // If the item doesn't exist, add the new one
      state.componentsData[workspaceId].data.items.push(updatedItem);
    } else {
      // If the item exists, update its pages and other properties

      const existingItem =
        state.componentsData[workspaceId].data.items[existingItemIndex];

      // Merge pages without duplicates
      const updatedPages = Array.from(
        new Set([...existingItem.pages, ...pages])
      );

      // Update the existing item with new pages and any other fields that might have changed
      state.componentsData[workspaceId].data.items[existingItemIndex] = {
        ...existingItem,
        ...updatedItem, // Ensure the new data (like description, etc.) is updated
        pages: updatedPages, // Update pages with merged array
      };
    }
  });
};

// Get One Wiki Success
export const GetOneWiki__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
  state.insideWorkspaceComponentData = action?.payload?.data;
};

// Add Wiki Page Success
export const AddWikiPage__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Get Wiki Page Success
export const GetWikiPage__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Update Wiki Page Success
export const UpdateWikiPage__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Add Component View Success
export const AddComponentView__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Create Page Success
export const CreatePage__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Retrieve All Pages Success
export const RetrieveAllPage__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Update Page Success
export const UpdatePage__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Retrieve Page Success
export const RetrievePage__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.insideWorkspaceComponentData = action?.payload?.data;
  state.data = action?.payload?.data;
};

export const ListPagesInSpace__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  const { data } = action?.payload;

  // Validate data presence and type
  if (!Array.isArray(data)) {
    console.error("Invalid payload data: Expected an array", action?.payload);
    return;
  }

  data.forEach((item: any) => {
    const { workspaceId, _id, title } = item;

    // Validate that the item contains necessary fields
    if (!workspaceId || !_id || !title) {
      console.error("Invalid item data:", item);
      return;
    }

    // Set the componentType manually
    const componentType = "Document";

    // Create a new item with the componentType
    const updatedItem = {
      ...item,
      title, // Use title from API response
      componentType, // Manually set the componentType
    };

    // Ensure workspace exists in state
    if (!state.componentsData[workspaceId]) {
      state.componentsData[workspaceId] = {
        loading: false,
        data: { items: [] },
      };
    } else {
      state.componentsData[workspaceId].loading = false;
    }

    // Check if the item already exists
    const existingItems = state.componentsData[workspaceId].data.items;
    const itemExists = existingItems.some((i) => i._id === _id);

    if (!itemExists) {
      existingItems.push(updatedItem);
    }
  });
};

// Search Content Success
export const SearchContent__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Delete Page Success
export const DeletePage__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Add Comment Success
export const AddComment__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Retrieve Comments Success
export const RetrieveComments__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Like Content Success
export const LikeContent__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Retrieve Attachments Success
export const RetrieveAttachments__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Add Label to Content Success
export const AddLabelToContent__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// View Content Labels Success
export const ViewContentLabels__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Delete Attachment Success
export const DeleteAttachment__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Create Space Success
export const CreateSpace__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Get Space Success
export const GetSpace__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Delete Space Success
export const DeleteSpace__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Update Space Permissions Success
export const UpdateSpacePermissions__Confluence__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

/* JIRA */

// Get Jira Login URL Success
export const GetJiraLoginUrl__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Exchange Jira Token Success
export const ExchangeJiraToken__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Create Project Success
export const CreateProject__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Get Project Details Success
export const GetProjectDetails__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Update Project Success
export const UpdateProject__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Delete Project Success
export const DeleteProject__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const GetAllBoards__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  const { data } = action.payload;

  // Ensure data is an array before using forEach
  if (!Array.isArray(data)) {
    console.error("Invalid data format: expected an array, received:", data);
    return;
  }

  data.forEach((item: any) => {
    const workspaceId = item.workspaceId;

    // Validate that the item contains necessary fields
    if (!workspaceId || !item._id || !item.name || !item.componentType) {
      console.error("Invalid item data:", item);
      return;
    }

    // Ensure the workspaceId exists in componentsData
    if (!state.componentsData[workspaceId]) {
      state.componentsData[workspaceId] = {
        loading: false,
        data: { items: [] },
      };
    } else {
      state.componentsData[workspaceId].loading = false;
    }

    // Avoid duplicate entries
    const existingIds = new Set(
      state.componentsData[workspaceId].data.items.map((i) => i._id)
    );

    if (!existingIds.has(item._id)) {
      state.componentsData[workspaceId].data.items.push(item);
    }
  });
};

// Get One Board Success
export const GetOneBoard__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Create Sprint Success
export const CreateSprint__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Update Sprint Success
export const UpdateSprint__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Delete Sprint Success
export const DeleteSprint__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Get All Sprints Success
export const GetAllSprints__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Update Issue Transition Success
export const UpdateIssueTransition__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Get Issues in Sprint Success
export const GetIssuesInSprint__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Create Issue Success
export const CreateIssue__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Add Comment Success
export const AddComment__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Get Comments Success
export const GetComments__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Assign Issue Success
export const AssignIssue__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Search Issues Success
export const SearchIssues__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Get User List Success
export const GetUserList__Jira__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Get User List Success
export const Get__cmd__for__user__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.cmd__control__for__workspace = action?.payload?.data;
};
export const Get__cmd__for__admin__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const fetchAllWorkspaceTasks__Success = (
  state: WorkspaceState,
  action: PayloadAction<any>
) => {
  const payload = action?.payload?.data;

  if (payload?.length === 0) {
    getAlTask__Failure(state, action as any);
    state.error = "No task found";
    return;
  }

  if (
    Array.isArray(payload) &&
    payload?.every((group) => Array?.isArray(group?.tasks) && group?.group)
  ) {
    const allTasksWithGrouping: any[] = [];

    const defaultGroups: any = {
      status: ["To Do", "In Progress", "Done", "Delayed", "In Review"],
      priority: ["Low", "Medium", "High"],
      taskType: ["Task", "Milestone", "Bug", "Feature", "Campaign"],
    };

    const getGroupName = (task: any): string => {
      if (state?.taskFilters?.assignee) {
        const assignees =
          task?.assignedUsers
            ?.map((user: any) => user?.firstName)
            ?.join(", ") || "Unassigned";
        return assignees;
      } else if (state.taskFilters?.status) {
        return task?.status?.value || "No Status";
      } else if (state.taskFilters?.priority) {
        return task?.priority?.value || "No Priority";
      } else if (state.taskFilters?.dueDate) {
        return task?.dueDate || "No Due Date";
      } else if (state.taskFilters?.type) {
        return task?.taskType || "No Type";
      }
      return "Uncategorized";
    };

    let filterField = "";
    if (state.taskFilters?.status) filterField = "status";
    else if (state.taskFilters?.priority) filterField = "priority";
    else if (state.taskFilters?.assignee) filterField = "assignee";
    else if (state.taskFilters?.dueDate) filterField = "dueDate";
    else if (state.taskFilters?.type) filterField = "type";

    if (filterField) {
      defaultGroups[filterField]?.forEach((defaultGroup: any) => {
        if (
          !allTasksWithGrouping?.some((g: any) => g?.groupName === defaultGroup)
        ) {
          allTasksWithGrouping?.push({
            groupName: defaultGroup,
            tasks: [],
          });
        }
      });
    }

    payload?.forEach((group: any) => {
      group?.tasks?.forEach((task: any) => {
        const groupName = getGroupName(task);

        const existingGroup = allTasksWithGrouping?.find(
          (g: any) => g?.groupName === groupName
        );

        if (existingGroup) {
          existingGroup?.tasks?.push(task);
        } else {
          allTasksWithGrouping?.push({
            groupName,
            tasks: [task],
          });
        }
      });
    });

    state.userAllTasks = allTasksWithGrouping;
  } else {
    state.userAllTasks = [];
  }
};
