import React from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { FirstLoginDataProps } from "./StepsType";
import { Slide } from "@mui/material";

type SteppersProps = FirstLoginDataProps & {
  currentStep: number;
};

const Steppers: React.FC<SteppersProps> = ({
  currentStep,
  firstLoginData,
  setFirstLoginData,
}) => {
  return (
    <>
      <div style={{ maxHeight: "510px", width: "100%" }}>
        <Slide
          timeout={300}
          direction={"down"}
          unmountOnExit
          in={currentStep === 1}
        >
          <div>
            <StepOne
              firstLoginData={firstLoginData}
              setFirstLoginData={setFirstLoginData}
            />
          </div>
        </Slide>

        <Slide
          timeout={currentStep === 1 ? 300 : 800}
          direction={"up"}
          unmountOnExit
          in={currentStep === 2}
        >
          <div>
            <StepTwo
              firstLoginData={firstLoginData}
              setFirstLoginData={setFirstLoginData}
            />
          </div>
        </Slide>

        <Slide
          timeout={currentStep === 1 || currentStep === 2 ? 300 : 1200}
          direction={"up"}
          unmountOnExit
          in={currentStep === 3}
        >
          <div>
            <StepThree
              firstLoginData={firstLoginData}
              setFirstLoginData={setFirstLoginData}
            />
          </div>
        </Slide>
      </div>
    </>
  );
};

export default Steppers;
