import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";

interface SortDetailsMenuViewProps {
  onMoreClick: () => void;
  totalShift: number;
  unFilledShift: number;
}

const SortDetailsMenuView: React.FC<SortDetailsMenuViewProps> = ({
  onMoreClick,
  totalShift,
  unFilledShift,
}) => {
  return (
    <Stack
      sx={{
        minHeight: "35px",
        flexDirection: "row",
        alignItems: "start",
        justifyContent: "space-between",
        width: "165px",
        font: "normal normal 300 11px/14px Source Serif Pro",
      }}
    >
      <Stack sx={{ font: "inherit" }}>
        <Typography sx={{ font: "inherit" }}>Shifts not Filled:</Typography>
        <Typography sx={{ font: "inherit" }}>
          {unFilledShift} of {totalShift} Shifts Unfilled
        </Typography>
      </Stack>

      <IconButton
        sx={{
          p: 0,
          font: "normal normal 300 11px/14px Source Serif Pro",
          borderRadius: 0,
          color: "white",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        disableRipple
        onClick={onMoreClick}
      >
        More
      </IconButton>
    </Stack>
  );
};

export default SortDetailsMenuView;
