import { Stack, Typography } from "@mui/material";
import React from "react";
import { AvatarNormal } from "../../../../common";
import { EmailIcon, PhoneOutlineIcon } from "../../../../../images";
import styles from "./ContactMiniCard.module.css";

interface ContactMiniCardProps {}

const ContactMiniCard: React.FC<ContactMiniCardProps> = ({}) => {
  return (
    <Stack className={styles["contact-mini-card"]}>
      <AvatarNormal size={45} />
      <Stack className={styles["contact-mini-card__details"]}>
        <Typography className={styles["contact-mini-card__name"]}>
          user full name here
        </Typography>

        <Stack className={styles["contact-mini-card__info"]}>
          <span className={styles["contact-mini-card__icon"]}>
            <EmailIcon w={15} />
          </span>
          <Typography className={styles["contact-mini-card__text"]}>
            contactemail@email.com
          </Typography>
        </Stack>

        <Stack className={styles["contact-mini-card__info"]}>
          <span className={styles["contact-mini-card__icon"]}>
            <PhoneOutlineIcon w={15} />
          </span>
          <Typography className={styles["contact-mini-card__text"]}>
            415-0320-3213
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ContactMiniCard;
