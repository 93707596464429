import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Stack, Typography, Zoom } from "@mui/material";
import {
  cookieDomain,
  getContrastingColor,
  getUser,
  isAuthenticated,
} from "../../../Utils";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import {
  CommandControlDashboardCardVariants,
  DashboardCardHoverPopup,
  DashboardSidebarCalendar,
  TodayShiftDashboard,
} from "./components";
import { GlobalState } from "../../../redux/Slice/Global/globalTypes";
import { reLoginUserDetails } from "../../../redux/Slice/auth/authActions";
import {
  getAllFoldersGlobal__api,
  getCoverImages__api,
} from "../../../redux/Slice/Global/globalActions";
import { clearAllStorage } from "../../../redux/middleware/apiMiddleware";
import { WelcomeWalkthrough } from "../../common";
import { getAllEventsAndTasksForDashboard__api } from "../../../redux/Slice/dashboard/dashboardActions";
import {
  getAllEvents__api,
} from "../../../redux";
import CommandQuickView from "./components/CommandQuickView";
import { SearchContainer } from "../SearchContainer";

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const authState = useSelector((state: RootState) => state && state.auth);

  const calendarState = useSelector(
    (state: RootState) => state && state?.calendar
  );
  const { allCalenderEventsList, reCall } = calendarState || {};

  const [showWelcome, setShowWelcome] = useState(() => {
    const showWelcomeState = authState?.user?.data?.walkthroughCompleted;
    return !showWelcomeState || showWelcomeState === "true";
  });

  const auth = useMemo(() => isAuthenticated(), []);
  const user = useMemo(() => getUser(authState), [authState]);
  const [textColor, setTextColor] = useState<"black" | "white">("black");
  const token = useMemo(() => localStorage.getItem("token"), []);
  const userId = useMemo(() => localStorage.getItem("user_id"), []);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState<{
    cardType: CommandControlDashboardCardVariants;
    data: any;
  } | null>(null);
  const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);
  const { coverImages } = useSelector(
    (state: RootState) => state.global as GlobalState
  );

  const userFirstName = useMemo(() => {
    if (!auth || !user?.data) return "";
    const { firstName = "", lastName = "" } = user?.data;
    return `${firstName} ${lastName}`?.trim();
  }, [auth, user]);

  const handleReLogin = useCallback(() => {
    if (!authState?.isAuthenticated && !authState?.user && token && userId) {
      try {
        dispatch(reLoginUserDetails(userId));
      } catch (error) {
        console.error("Error during re-login:", error);
      }
    }
  }, [authState, token, userId, dispatch]);

  useEffect(() => {
    handleReLogin();
  }, [handleReLogin]);

  useEffect(() => {
    (async () => {
      await dispatch(getCoverImages__api());
      await dispatch(getAllFoldersGlobal__api());
      await dispatch(getAllEventsAndTasksForDashboard__api());
    })();
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get("f") === "1") {
      clearAllStorage();

      const redirectUrl = `http://os${cookieDomain === ".localtest.me" ? ".localtest.me:3000" : cookieDomain
        }/login?f=success`;

      window.location.replace(redirectUrl);
    } // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [location]);

  const handleMouseEnter = (cardType: any, data: any) => {
    const timeout = setTimeout(() => {
      setPopupData({ cardType, data });
      setShowPopup(true);
    }, 2000);
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
  };

  const handleBackClick = () => {
    setShowPopup(false);
    setPopupData(null);
  };


  useEffect(() => {
    switch (reCall) {
      case "All":
        getAllEvents();
        break;
      case "getAllCalEvent":
        getAllEvents();
        break;
      default:
        break;
    } // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [reCall]);

  const getAllEvents = async () => {
    try {
      const action = getAllEvents__api();

      await dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const updateTextColor = async () => {
      if (coverImages?.dashboardCoverImageUrl) {
        const color = await getContrastingColor(
          coverImages.dashboardCoverImageUrl
        );
        setTextColor(color);
      } else {
        setTextColor("black");
      }
    };

    updateTextColor();
  }, [coverImages?.dashboardCoverImageUrl]);
  // Function to determine the greeting
  const getGreeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return "DASHBOARD.GOOD_MORNING";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "DASHBOARD.GOOD_AFTERNOON";
    } else {
      return "DASHBOARD.GOOD_EVENING";
    }
  };
  // Get the appropriate greeting key
  const greetingKey = getGreeting();

  const [eventsList, setEventsList] = useState<any | null>([]);

  useEffect(() => {
    if (allCalenderEventsList && allCalenderEventsList?.length > 0) {
      setEventsList(allCalenderEventsList);
    }
  }, [allCalenderEventsList]);
  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
        backgroundImage: `url(${coverImages?.dashboardCoverImageUrl || ""})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "93.5dvh",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          height: "100%",
          gap: "5px",
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            gap: "45px",
            paddingLeft: "15px",
          }}
          justifyContent={
            'space-between'
          }
        >
          <Stack  sx={{ gap: "15px", overflow: "hidden" }}>
            <Typography
              sx={{
                font: "normal normal 900 40px/50px Source Serif Pro",
                wordWrap: "break-word",
                color: textColor,
                marginTop: "25px",
                padding: "10px",
                paddingBottom: "0px !important",
              }}
            >
              {t(greetingKey)} 
            </Typography>
            <Stack
              sx={{
                height: "calc(100vh - 200px)",
                overflow: "auto",
                scrollbarWidth: "none",
                padding: "10px",
              }}
            >
              <CommandQuickView />
            </Stack>
          </Stack>
          <Stack sx={{ marginTop: "90px" }}>
            {showPopup && popupData && (
              <DashboardCardHoverPopup
                cardType={popupData.cardType}
                data={popupData.data}
                handleOnBackClick={handleBackClick}
              />
            )}
          </Stack>
        </Stack>
        <Stack pr={'5px'} sx={{ height: "100%" }}>
          <Stack sx={{ height: "calc(100% - 280px)" }}>
            <DashboardSidebarCalendar
              dataList={eventsList}
              textColor={textColor}
            />
          </Stack>
          <TodayShiftDashboard />
        </Stack>
        {showWelcome && (
          <Stack position="absolute" bottom="30px" right="30px">
            <Zoom
              in={showWelcome}
              style={{ transitionDelay: showWelcome ? "500ms" : "0ms" }}
            >
              <div>
                <WelcomeWalkthrough
                  setShowWelcome={setShowWelcome}
                  userFirstName={userFirstName}
                />
              </div>
            </Zoom>
          </Stack>
        )}
        <SearchContainer />
      </Stack>
    </Box>
  );
};

export default Dashboard;
