import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { ShiftTimingType } from "../../AttendanceScheduleTableType";
import { AttendanceShiftColorList } from "../../AttendanceScheduleSetting";

type InlineTooltipInfoAreaProps = {
  title: string;
  startTime: string;
  endTime: string;
  timeZoneName: string;
  onEditButtonClick: () => void;
  useAsUserView?: boolean;
};
const InlineTooltipInfoArea: React.FC<InlineTooltipInfoAreaProps> = ({
  endTime,
  onEditButtonClick,
  startTime,
  timeZoneName,
  title,
  useAsUserView = false, // use this props for change anything in user view table here
}) => {
  return (
    <Stack
      sx={{
        width: "165px",
        height: "35px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}>
        <Typography sx={{ font: "inherit" }}>{title}</Typography>
        <Typography sx={{ font: "inherit" }}>
          {startTime} to {endTime} {timeZoneName}
        </Typography>
      </Stack>

      {/* {!useAsUserView && ( */}
        <IconButton
          sx={{
            p: 0,
            font: "normal normal 300 11px/14px Source Serif Pro",
            borderRadius: 0,
            color: "white",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          disableRipple
          onClick={onEditButtonClick}
        >
          Edit
        </IconButton>
      {/* )} */}
    </Stack>
  );
};

interface ShiftNameBoxProps {
  shiftInfo: ShiftTimingType;
  count: number;
  useAsUserView?: boolean;
}

const ShiftNameBox: React.FC<ShiftNameBoxProps> = ({
  count,
  shiftInfo,
  useAsUserView = false,
}) => {
  const getProperty = (): { bgClr: string; labelWord: string } => {
    switch (shiftInfo.shiftType) {
      case "midDay":
        return {
          bgClr: AttendanceShiftColorList.midDay,
          labelWord: "Midday Shift",
        };
      case "morning":
      default:
        return {
          bgClr: AttendanceShiftColorList.morning,
          labelWord: "Morning Shift",
        };
      case "night":
        return {
          bgClr: AttendanceShiftColorList.night,
          labelWord: "Night Shift",
        };
    }
  };

  const getWidthArea = () => {
    switch (count) {
      case 1:
        return "144px";
      case 2:
        return "70px";
      case 3:
        return "45px";
      default:
        return "45px";
    }
  };

  return (
    <Tooltip
      title={
        <InlineTooltipInfoArea
          title={getProperty().labelWord}
          startTime={shiftInfo.startTime}
          endTime={shiftInfo.endTime}
          timeZoneName="PST" //adjust this as api
          onEditButtonClick={() => null}
          useAsUserView={useAsUserView}
        />
      }
      placement="bottom"
      arrow
    >
      <Stack
        sx={{
          width: getWidthArea(),
          height: "35px",
          bgcolor: getProperty().bgClr,
          flexShrink: 0,
          flexGrow: 0,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          paddingLeft: "3px",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 300 10px/13px Source Serif Pro",
            whiteSpace: "wrap",
            width: "35px",
          }}
        >
          {getProperty().labelWord}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default ShiftNameBox;
