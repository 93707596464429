import { Stack, Typography } from "@mui/material";
import React from "react";
import {
  EmployeeCountIcon,
  LocationIcon,
  TeamMemberIcon,
} from "../../../../../../../images";

interface EmpPreviewTableContainerViewProps {
  children: React.ReactNode;
}

const iconData: { Icon: React.ElementType; text: string }[] = [
  { Icon: TeamMemberIcon, text: "Employee" },
  { Icon: EmployeeCountIcon, text: "Title" },
  { Icon: LocationIcon, text: "Current Country and Time" },
];

const EmpPreviewTableContainerView: React.FC<
  EmpPreviewTableContainerViewProps
> = ({ children }) => {
  return (
    <Stack gap={"10px"}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "102px",
          ml: "25px",
          pt: "1px",
        }}
      >
        {iconData.map(({ Icon, text }, index) => (
          <Stack
            key={index}
            sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}
          >
            <Icon w={15} />
            <Typography
              sx={{ font: "normal normal 300 12px/15px Source Serif Pro" }}
            >
              {text}
            </Typography>
          </Stack>
        ))}
      </Stack>

      {/* children container */}
      <Stack
        sx={{
          maxHeight: "210px",
          overflow: "auto",
          scrollbarWidth: "none",
          mr: "-25px",
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default EmpPreviewTableContainerView;
