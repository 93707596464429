import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { BriefCaseIcon } from "../../../../../../../images";
import {
  ButtonSmall,
  ButtonType,
  CompanyLongDropdown,
  CompanyLongDropdownOptionInterface,
  DropdownWithColor,
  DUMMY_CompanyLongDropdownOption,
  DUMMY_DEALSTAGE_OPTIONS,
  LargeInputBox,
  SingleTeammateDropdown,
  SmallButtonColor,
} from "../../../../../../common";
import M from "../../AddDealPopupLarge.module.css";
import S from "./CreateDealView.module.css";
import { getUserList } from "../../../../../../../Utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../redux/store";

interface CreateDealViewProps {
  createDeal?: any;
}

const CreateDealView: React.FC<CreateDealViewProps> = ({ createDeal }) => {
  const crmState = useSelector((state: RootState) => state && state?.crm);
  const { companyList } = crmState || {};

  const [state, setState] = useState<any>({
    dealName: "",
    dealStage: "",
    dealOwner: {},
    dealValue: 0,
    description: "",
    company: {},
    contactsInvolved: [] as string[], // Assuming it's an array of strings
  });
  const handleInputChange = (field: keyof typeof state) => (value: any) => {
    setState((prev: any) => ({ ...prev, [field]: value }));
  };

  const userlist = getUserList();

  const handleCreateDeal = async () => {
    try {
      const payload: any = {
        dealName: state?.dealName,
        dealStage: state?.dealStage,
        dealOwner: (state?.dealOwner?._id as string) || "",
        dealValue: state?.dealValue,
        description: state?.description,
        company: state?.company?.id,
        contactsInvolved:
          state?.contactsInvolved?.map((contact: any) => contact?._id) || [], // Map to store _id of each contact
      };

      if (createDeal) {
        createDeal(payload);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const transformedCompanyList: CompanyLongDropdownOptionInterface[] =
    companyList &&
    companyList?.map((company: any) => ({
      id: company?._id,
      companyIconSrc:
        company?.businessLogo ||
        "https://cdn.vectorstock.com/i/500p/36/69/gold-building-cityscape-logo-vector-14913669.jpg", // Use default if empty
      companyName: company?.businessName || "Unknown company",
      companyMail: `${company?.businessName}@${
        company?.domain || "example.com"
      }`, // Assuming domain array contains the domain names
    }));

  return (
    <>
      <Stack className={M["add-deal-popup-large__header"]}>
        <BriefCaseIcon w={17} />
        <Typography
          sx={{ font: "normal normal normal 14px/20px Source Serif Pro" }}
        >
          Deal
        </Typography>
      </Stack>

      {/* Card body */}
      <Stack className={M["add-deal-popup-large__body"]}>
        <LargeInputBox
          borderColor="#E9EDF2"
          height="30px"
          label={"Deal name"}
          value={state.dealName}
          onChange={(value: any) => handleInputChange("dealName")(value)}
        />

        <Stack className={S["create-deal-view__row"]}>
          <DropdownWithColor
            options={DUMMY_DEALSTAGE_OPTIONS}
            label="Deal stage"
            width="215px"
            placeholder="choose one..."
            onChange={(value) => handleInputChange("dealStage")(value)}
            onSelect={(value: any) =>
              handleInputChange("dealStage")(value?.value)
            }
          />

          <SingleTeammateDropdown
            options={userlist || []}
            label="Deal owner"
            placeholder="select user"
            forceRequired_selfUser={true}
            onSelect={(value: any) => handleInputChange("dealOwner")(value)}
          />

          <CompanyLongDropdown
            width="100%"
            label="Associated company"
            options={transformedCompanyList}
            onChange={(value) => handleInputChange("company")(value)}
          />
        </Stack>

        <SingleTeammateDropdown
          width="100%"
          options={userlist || []} forceRequired_selfUser={true}
          label="Associated people"
          placeholder="select user"
          onSelect={(value: any) => {
            setState((prev: any) => ({
              ...prev,
              contactsInvolved: [...prev.contactsInvolved, value],
            }));
          }}
        />

        <LargeInputBox
          height="30px"
          borderColor="#E9EDF2"
          label={"Deal value"}
          value={state.dealValue.toString()} // Convert to string for the input
          onChange={(value: any) =>
            handleInputChange("dealValue")(parseFloat(value))
          }
        />

        {/* Buttons */}
        <Stack className={S["create-deal-view__buttons"]}>
          <ButtonSmall
            label="Cancel"
            types={ButtonType.Button}
            colorVarient={SmallButtonColor.Transparent}
          />
          <ButtonSmall
            label="Save"
            types={ButtonType.Button}
            onClick={() => handleCreateDeal()}
            colorVarient={SmallButtonColor.CRMOrange}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default CreateDealView;
