import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { get__cmd__for__user__api, update__cmd__api } from "../../../../redux";
import { Box, Stack, Typography } from "@mui/material";
import { CommandControlDashboard } from "../../Dashboard/components";
import {
    a11yProps,
    StyledTab,
    TabsWithState,
} from "../../../common/CardTabsWithoutIcon";

type CommandControl_W_Props = {};
const tabs = [{ label: "Pending" }, { label: "Done" }];
const CommandControl_W: React.FC<CommandControl_W_Props> = () => {
    const dispatch = useDispatch();

    const workspaceState = useSelector((state: RootState) => state.workspace);
    const { cmd__control__for__workspace } = workspaceState || {};

    const [cmdControlList, setCmdControlList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const fetchCommandControlData = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const action = get__cmd__for__user__api();
            await dispatch(action);
        } catch (error) {
            setError("Failed to fetch command controls");
            console.error("Error fetching command control data:", error);
        } finally {
            setIsLoading(false);
        }
    }, [dispatch]);

    const updateCommandControlData = async (taskId: string, status: string) => {
        try {
            if (!taskId || !status) {
                return;
            }

            const payload = {
                userQuote: status, // ["Overdue", "Done", "Task"]
            };

            const action = update__cmd__api(taskId, payload);

            const r: any = await dispatch(action);

            if (r?.success) {
                fetchCommandControlData();
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCommandControlData();
    }, [fetchCommandControlData]);

    useEffect(() => {
        if (cmd__control__for__workspace) {
            setCmdControlList(cmd__control__for__workspace);
        }
    }, [cmd__control__for__workspace]);

    const memoizedCmdList = useMemo(() => cmdControlList, [cmdControlList]);

    const pendingData = memoizedCmdList.filter(
        (item) => item.status === "PENDING"
    );
    const doneData = memoizedCmdList.filter((item) => item.status === "DONE");

    const renderContent = () => {
        if (isLoading) {
            return (
                <p
                    style={{
                        marginTop: "10px",
                        font: "normal normal 500 13px/16px Source Serif Pro",
                    }}
                >
                    Loading...
                </p>
            );
        }
        if (error) {
            return <p style={{ color: "red" }}>{error}</p>;
        }
        if (selectedTab === 0 && pendingData.length === 0) {
            return (
                <p
                    style={{
                        marginTop: "10px",
                        font: "normal normal 500 13px/16px Source Serif Pro",
                    }}
                >
                    No pending command controls available
                </p>
            );
        }
        if (selectedTab === 1 && doneData.length === 0) {
            return (
                <p
                    style={{
                        marginTop: "10px",
                        font: "normal normal 500 13px/16px Source Serif Pro",
                    }}
                >
                    No completed command controls available
                </p>
            );
        }

        const dataToRender = selectedTab === 0 ? pendingData : doneData;

        return (
            <Stack mt={2} width={"100%"}>
                {dataToRender.map((item, index) => (
                    <CommandControlDashboard
                        key={index}
                        cmd_data={item}
                        onUpdateButton_Click={() => {
                            updateCommandControlData(item?._id, "Done");
                        }}
                        onSnoozed__Click={(value: any) => { }}
                        w={"740px"}
                        cardType={item.userQuote?.toLowerCase()}
                    />
                ))}
            </Stack>
        );
    };

    return (
        <Box sx={{ padding: "15px 30px" }}>
            <Typography
                variant="h4"
                sx={{
                    fontWeight: 600,
                    marginBottom: 1,
                    fontFamily: "Source Serif Pro",
                }}
            >
                Command Control Insights for Workspace
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    color: "text.secondary",
                    marginBottom: 3,
                    fontFamily: "Source Serif Pro",
                }}
            >
                Monitor and manage all workspace command controls with ease. Stay
                updated with real-time status and control assignments.
            </Typography>

            <TabsWithState
                ariaLabel="Command Control Tabs"
                value={selectedTab}
                onChange={(event, newValue) => setSelectedTab(newValue)}
                tabIndicatorColor="#008D4C"
                sx={{
                    maxWidth: "max-content",
                    borderBottom: "0px solid #E9EDF2",
                    "& .css-1aquho2-MuiTabs-indicator": {
                        bottom: "0px !important",
                    },
                }}
            >
                {tabs.map((tab, index) => (
                    <StyledTab
                        key={index}
                        disableRipple
                        style={{
                            font: "normal normal 300 15px/19px Source Serif Pro",
                            padding: "10px 0px",
                        }}
                        label={tab.label}
                        {...a11yProps(index)}
                    />
                ))}
            </TabsWithState>

            {/* Render content based on selected tab */}
            {renderContent()}
        </Box>
    );
};

export default CommandControl_W;
