import React from "react";
import {MicrosoftTeamsLogoPng} from "../../../../../images";
import {MessageAvatarChip, UserMsgStatusType} from "../MessageAvatarChip";
import {MsgMiniCard} from "../MsgMiniCard";

interface DrawerMsgViewCardProps {
    unreadMsgCount?: number;
    isSelected?: boolean;
    userMsgStatus?: UserMsgStatusType;
    onCardClick?: any;
    title?: string;
  onCardRightClick?: any; // Add this prop
    platformIcon?: string;
    userIconSrc?: any;
}

const DrawerMsgViewCard: React.FC<DrawerMsgViewCardProps> = ({
                                                                 unreadMsgCount = 0,
                                                                 isSelected,
                                                                 userMsgStatus = "active",
                                                                 onCardRightClick, // Add this prop
                                                                 onCardClick,
                                                                 title = "",
                                                                 platformIcon = MicrosoftTeamsLogoPng,
                                                                 userIconSrc
                                                             }) => {
    return (
        <MsgMiniCard
            headerComponent={<MessageAvatarChip userMsgStatus={userMsgStatus} imgSrc={userIconSrc} username={title}/>}
            hostImgUrl={platformIcon}
            label={title}
            unreadMsgCount={unreadMsgCount}
            isSelected={isSelected}
            onCardClick={onCardClick}
            onCardRightClick={onCardRightClick} // Pass this prop
        />
    );
};

export default DrawerMsgViewCard;
