import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {Box, CssBaseline, Stack} from "@mui/material";
import {ROUTES} from "../../../Router/index.utils";
import g from "./CommonContainer.module.css";
import {BottomPopOutUniversal, DrawerNavbar, TopNavbar} from "../../common";
import {styled} from "@mui/material/styles";
import {LayoutState} from "../../../redux/Slice/Layout/layoutTypes";
import {RootState} from "../../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {pathToDrawerStateKey} from "./CommanDrawerType";
import {DrawerManager} from "./DrawerManager";
import {changeLayoutState} from "../../../redux";
import {IncomingCallPopup} from "../../pages/Message/components/IncomingCallPopup";
import {GlobalState} from "../../../redux/Slice/Global/globalTypes";
import MiniProgressCallPopup from "../../pages/Message/components/MiniProgressCallPopup/MiniProgressCallPopup";

interface CommonContainerProps {
    children: ReactNode;
}

const Main = styled("main", {shouldForwardProp: (prop) => prop !== "open"})<{
    open?: boolean;
}>(({theme, open}) => ({
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${215}px`,
    ...(open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const CommonContainer: React.FC<CommonContainerProps> = ({children}) => {
    const location = useLocation();
    const layoutState = useSelector(
        (state: RootState) => state.layout as LayoutState
    );
    const dispatch = useDispatch();

    const {groupCallState} = useSelector((state: RootState) => state.global as GlobalState);

    const [drawerOpen, setDrawerOpen] = useState<LayoutState>(layoutState);

    useEffect(() => {
        const currentPath = location.pathname;

        const drawerKey = Object.keys(pathToDrawerStateKey).find((key) =>
            currentPath.startsWith(key)
        );

        if (drawerKey) {
            const drawerType = pathToDrawerStateKey[drawerKey];
            if (drawerOpen[drawerType] === undefined) {
                setDrawerOpen((prev) => ({
                    ...prev,
                    [drawerType]: false,
                }));
            }
        }
    }, [location.pathname, drawerOpen]);

    useEffect(() => {
        const updatedDrawerState = Object.keys(layoutState).reduce((acc, key) => {
            const layoutKey = key as keyof LayoutState;
            if (layoutState[layoutKey] !== drawerOpen[layoutKey]) {
                acc[layoutKey] = layoutState[layoutKey];
            }
            return acc;
        }, {} as Partial<LayoutState>);

        if (Object.keys(updatedDrawerState).length > 0) {
            setDrawerOpen((prev) => ({
                ...prev,
                ...updatedDrawerState,
            }));
        }
    }, [layoutState, drawerOpen]);

    const handleChangeDrawer = useCallback(() => {
        const currentPath = location.pathname;
        const drawerKey = Object.keys(pathToDrawerStateKey).find((key) =>
            currentPath.startsWith(key)
        );

        if (drawerKey) {
            const drawerType = pathToDrawerStateKey[drawerKey];
            if (drawerType.includes("Dashboard")) {
                dispatch(changeLayoutState({type: drawerType, value: false}));
                setDrawerOpen((prev) => ({
                    ...prev,
                    [drawerType]: false,
                }));
            } else {
                dispatch(
                    changeLayoutState({
                        type: drawerType,
                        value: !drawerOpen[drawerType],
                    })
                );
                setDrawerOpen((prev) => ({
                    ...prev,
                    [drawerType]: !prev[drawerType],
                }));
            }
        }
    }, [location.pathname, drawerOpen, dispatch]);

    const activeDrawerPath = Object.keys(pathToDrawerStateKey).find((path) =>
        location.pathname.startsWith(path)
    );

    const isAnyDrawerOpen = Object.keys(pathToDrawerStateKey).some(
        (key) =>
            location.pathname.startsWith(key) && drawerOpen[pathToDrawerStateKey[key]]
    );

    return (
        <Box sx={{display: "flex", flexDirection: "column"}}>
            <TopNavbar
                drawerOpen={isAnyDrawerOpen}
                drawerOpenOrCloser={handleChangeDrawer}
            />
            <div
                style={{
                    position: "fixed",
                    zIndex: 1210,
                    bottom: "80px",
                    right: 0,
                }}
            >
                <BottomPopOutUniversal/>
            </div>

            {groupCallState?.incomingCallRinging && <div
                style={{
                    position: "fixed",
                    zIndex: 1210,
                    top: "60px",
                    right: "10px",
                }}
            >
                <IncomingCallPopup groupCallState={groupCallState}/>
            </div>
            }
            <CssBaseline/>
            {(groupCallState?.isCallAccepted && groupCallState?.callInProgress) && <div
                style={{
                    position: "fixed",
                    zIndex: 1210,
                    top: "60px",
                    right: "10px",
                }}
            >
                <MiniProgressCallPopup/>
            </div>}
            <Stack
                sx={{
                    flexShrink: 0,
                    "& .MuiPaper-root": {
                        boxSizing: "border-box",
                        alignItems: "center",
                        backgroundColor: "#EDF1F5",
                        zIndex: "1202 !important",
                        overflow: "hidden",
                    },
                }}
                className={g.drawerbearish20}
            >
                <DrawerNavbar ROUTES={ROUTES}/>
                {activeDrawerPath && (
                    <DrawerManager
                        drawerOpen={drawerOpen}
                        handleChangeDrawer={handleChangeDrawer}
                    />
                )}
            </Stack>
            <Main
                open={isAnyDrawerOpen}
                sx={{
                    flexGrow: 1,
                    backgroundColor: "#FFFFFF",
                    marginLeft: isAnyDrawerOpen ? "270px" : "55px",
                    minHeight: "93.68vh",
                    maxHeight: "93.68vh",
                    overflow: "scroll",
                    overflowX: "hidden",
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
            >
                {children}
            </Main>
        </Box>
    );
};

export default CommonContainer;
