import React, { useEffect, useState } from "react";
import { TextNormalDropdownOptionsType } from "./TextNormalDropdownType";
import { Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import styles from "./TextNormalDropdown.module.css";
import { adjustHexColor } from "../../../Utils";
import { ChevronIcon, InfoIcon } from "../../../images";

interface TextNormalDropdownProps {
  options: TextNormalDropdownOptionsType[];
  onSelect: (selected: TextNormalDropdownOptionsType) => void;
  initialValue?: TextNormalDropdownOptionsType;
  placeholder?: string;
  menuWidth?: string;
  menuHeight?: string;
  additionalOption?: React.ReactNode;
  headerButtonCss?: React.CSSProperties;
  optionsButtonCss?: React.CSSProperties;
}

const TextNormalDropdown: React.FC<TextNormalDropdownProps> = ({
  onSelect,
  options,
  initialValue,
  menuWidth = "200px",
  menuHeight = "200px",
  placeholder,
  additionalOption,
  headerButtonCss,
  optionsButtonCss,
}) => {
  const [selectedValue, setSelectedValue] = useState<
    TextNormalDropdownOptionsType | undefined
  >();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectHandler = (value: TextNormalDropdownOptionsType) => {
    setSelectedValue(value);
    onSelect(value);
    handleClose();
  };

  useEffect(() => {
    if (initialValue) {
      setSelectedValue(initialValue);
    }
  }, [initialValue]);

  return (
    <Stack>
      <Stack
        className={styles["textnormal-dropdown__trigger"]}
        style={{ width: menuWidth, ...headerButtonCss }}
        onClick={handleClick}
      >
        <Typography className={styles["textnormal-dropdown__text"]}>
          {selectedValue ? (
            selectedValue.title
          ) : (
            <span style={{ color: adjustHexColor("#000000", 50) }}>
              {placeholder}
            </span>
          )}
        </Typography>
        <ChevronIcon direction={open ? "down" : "up"} />
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            className: styles["textnormal-dropdown__menu"],
            style: { width: menuWidth, maxHeight: menuHeight },
          },
        }}
      >
        {options.map((item, index) => (
          <MenuItem
            key={index}
            selected={item.value === selectedValue?.value}
            onClick={() => selectHandler(item)}
            className={styles["textnormal-dropdown__menu-item"]}
            style={{ ...optionsButtonCss }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                flex: 1,
                gap: "5px",
              }}
            >
              {item.title}

              <Stack
                sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}
              >
                <Typography
                  sx={{
                    font: "normal normal 200 10px/13px Source Serif Pro",
                    letterSpacing: "-0.02em",
                  }}
                >
                  {item.subTitle}
                </Typography>

                {item.infoText && (
                  <Tooltip
                    arrow
                    title={
                      <Typography
                        sx={{
                          font: "normal normal 300 11px/14px Source Serif Pro",
                          width: "160px",
                        }}
                      >
                        {item.infoText}
                      </Typography>
                    }
                  >
                    <span style={{ height: "15px", width: "15px" }}>
                      <InfoIcon w={15} />
                    </span>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
          </MenuItem>
        ))}

        {additionalOption && (
          <Stack sx={{ width: "100%", padding: "2px 10px" }}>
            {additionalOption}
          </Stack>
        )}
      </Menu>
    </Stack>
  );
};

export default TextNormalDropdown;
