import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  createCalendar__Request,
  createCalendar__Failure,
  createCalendar__Success,
  subscribeToICal__Request,
  subscribeToICal__Failure,
  subscribeToICal__Success,
  getAllCalendars__Request,
  getAllCalendars__Failure,
  getAllCalendars__Success,
  getOneCalendar__Request,
  getOneCalendar__Failure,
  getOneCalendar__Success,
  updateCalendar__Request,
  updateCalendar__Failure,
  updateCalendar__Success,
  deleteCalendar__Request,
  deleteCalendar__Failure,
  deleteCalendar__Success,
  createCalendarSettings__Request,
  createCalendarSettings__Failure,
  createCalendarSettings__Success,
  getCalendarSettings__Request,
  getCalendarSettings__Failure,
  getCalendarSettings__Success,
  updateCalendarSettings__Request,
  updateCalendarSettings__Failure,
  updateCalendarSettings__Success,
  addConnectedCalendar__Request,
  addConnectedCalendar__Failure,
  addConnectedCalendar__Success,
  removeConnectedCalendar__Request,
  removeConnectedCalendar__Failure,
  removeConnectedCalendar__Success,
  createEvent__Request,
  createEvent__Failure,
  createEvent__Success,
  getAllEvents__Request,
  getAllEvents__Failure,
  getAllEvents__Success,
  createTask__Request,
  createTask__Failure,
  createTask__Success,
  getAllTasks__Request,
  getAllTasks__Failure,
  getAllTasks__Success,
  createBookingLink__Request,
  createBookingLink__Failure,
  createBookingLink__Success,
  getBookingLinks__Request,
  getBookingLinks__Failure,
  getBookingLinks__Success,
  eventResponse__Request,
  eventResponse__Failure,
  eventResponse__Success,
  createSchedule__Request,
  createSchedule__Failure,
  createSchedule__Success,
  getAllSchedules__Request,
  getAllSchedules__Failure,
  getAllSchedules__Success,
  getOneSchedule__Request,
  getOneSchedule__Failure,
  getOneSchedule__Success,
  updateSchedule__Request,
  updateSchedule__Failure,
  updateSchedule__Success,
  deleteSchedule__Request,
  deleteSchedule__Failure,
  deleteSchedule__Success,
  subscribeToUserCal__Request,
  subscribeToUserCal__Failure,
  subscribeToUserCal__Success,
  getAllBookingLinks__Request,
  getAllBookingLinks__Failure,
  getAllBookingLinks__Success,
  trackVisit__Request,
  trackVisit__Failure,
  trackVisit__Success,
  getPublicBookingLink__Request,
  getPublicBookingLink__Failure,
  getPublicBookingLink__Success,
  getPrivateBookingLink__Request,
  getPrivateBookingLink__Failure,
  getPrivateBookingLink__Success,
  getAvailableTimeSlots__Request,
  getAvailableTimeSlots__Failure,
  getAvailableTimeSlots__Success,
  createBookingEvent__Request,
  createBookingEvent__Failure,
  createBookingEvent__Success,
  LoginExternalCalendar__Request,
  LoginExternalCalendar__Failure,
  LoginExternalCalendar__Success,
  AuthExternalCalendar__Request,
  AuthExternalCalendar__Success,
  AuthExternalCalendar__Failure,
  getOneEvent__Request,
  getOneEvent__Failure,
  getOneEvent__Success,
  updateCalendarAppearance__Request,
  updateCalendarAppearance__Success,
  updateCalendarAppearance__Failure,
  shareCalendarWithUser__Request,
  shareCalendarWithUser__Success,
  shareCalendarWithUser__Failure,
  getSharedWith__Request,
  getSharedWith__Success,
  getSharedWith__Failure,
  updateEventDate__Request,
  updateEventDate__Success,
  updateEventDate__Failure,
  updateEventTaskStatus__Request,
  updateEventTaskStatus__Failure,
  updateEventTaskStatus__Success,
  cmd__calendar__Success,
  deleteOneEvents__Success,
} from "./CalendarSliceAction";

import {
  BookingLinkData,
  CalendarSettingsPayload,
  CalendarState,
  EventData,
  FilterCriteria,
  GuestData,
  RecallAPI,
  ScheduleData,
} from "./CalendarType";

const initialState: CalendarState = {
  error: null,
  data: null,
  reCall: "All",
  calSettings: null,
  isGetAllCalendarList: false,
  allCalendarList: null,
  lastApiCallTime: null,
  allCalenderEventsList: null,
  hiddenCalendarIds: [],
  memberOfCalendar: null,
  filterCriteria: null,
  cmd__Notifications: null,
  update__start_endTime__based__TimeZone: null,
  allBookingList: null,
};

const CalendarSlice = createSlice({
  name: "calendar", // Name of the slice
  initialState, // Initial state of the slice
  reducers: {
    createCalendar__Request,
    createCalendar__Failure,
    createCalendar__Success,

    getAllCalendars__Request,
    getAllCalendars__Failure,
    getAllCalendars__Success,

    getOneCalendar__Request,
    getOneCalendar__Failure,
    getOneCalendar__Success,

    updateCalendar__Request,
    updateCalendar__Failure,
    updateCalendar__Success,

    deleteCalendar__Request,
    deleteCalendar__Failure,
    deleteCalendar__Success,

    createCalendarSettings__Request,
    createCalendarSettings__Failure,
    createCalendarSettings__Success,

    getCalendarSettings__Request,
    getCalendarSettings__Failure,
    getCalendarSettings__Success,

    updateCalendarSettings__Request,
    updateCalendarSettings__Failure,
    updateCalendarSettings__Success,

    addConnectedCalendar__Request,
    addConnectedCalendar__Failure,
    addConnectedCalendar__Success,

    removeConnectedCalendar__Request,
    removeConnectedCalendar__Failure,
    removeConnectedCalendar__Success,

    createEvent__Request,
    createEvent__Failure,
    createEvent__Success,

    getAllEvents__Request,
    getAllEvents__Failure,
    getAllEvents__Success,

    createTask__Request,
    createTask__Failure,
    createTask__Success,

    getAllTasks__Request,
    getAllTasks__Failure,
    getAllTasks__Success,

    createBookingLink__Request,
    createBookingLink__Failure,
    createBookingLink__Success,

    getBookingLinks__Request,
    getBookingLinks__Failure,
    getBookingLinks__Success,

    eventResponse__Request,
    eventResponse__Failure,
    eventResponse__Success,

    createSchedule__Request,
    createSchedule__Failure,
    createSchedule__Success,

    getAllSchedules__Request,
    getAllSchedules__Failure,
    getAllSchedules__Success,

    getOneSchedule__Request,
    getOneSchedule__Failure,
    getOneSchedule__Success,

    updateSchedule__Request,
    updateSchedule__Failure,
    updateSchedule__Success,

    deleteSchedule__Request,
    deleteSchedule__Failure,
    deleteSchedule__Success,

    subscribeToICal__Request,
    subscribeToICal__Failure,
    subscribeToICal__Success,

    subscribeToUserCal__Request,
    subscribeToUserCal__Failure,
    subscribeToUserCal__Success,

    getAllBookingLinks__Request,
    getAllBookingLinks__Failure,
    getAllBookingLinks__Success,

    trackVisit__Request,
    trackVisit__Failure,
    trackVisit__Success,

    getPublicBookingLink__Request,
    getPublicBookingLink__Failure,
    getPublicBookingLink__Success,

    getPrivateBookingLink__Request,
    getPrivateBookingLink__Failure,
    getPrivateBookingLink__Success,

    getAvailableTimeSlots__Request,
    getAvailableTimeSlots__Failure,
    getAvailableTimeSlots__Success,

    createBookingEvent__Request,
    createBookingEvent__Failure,
    createBookingEvent__Success,

    LoginExternalCalendar__Request,
    LoginExternalCalendar__Failure,
    LoginExternalCalendar__Success,

    AuthExternalCalendar__Request,
    AuthExternalCalendar__Success,
    AuthExternalCalendar__Failure,

    getOneEvent__Request,
    getOneEvent__Failure,
    getOneEvent__Success,

    updateCalendarAppearance__Request,
    updateCalendarAppearance__Success,
    updateCalendarAppearance__Failure,

    shareCalendarWithUser__Request,
    shareCalendarWithUser__Success,
    shareCalendarWithUser__Failure,

    getSharedWith__Request,
    getSharedWith__Success,
    getSharedWith__Failure,

    updateEventDate__Request,
    updateEventDate__Success,
    updateEventDate__Failure,

    updateEventTaskStatus__Request,
    updateEventTaskStatus__Failure,
    updateEventTaskStatus__Success,

    cmd__calendar__Success,
    deleteOneEvents__Success,

    recallAPI: (state, { payload }: PayloadAction<RecallAPI>) => {
      if (payload) {
        state.reCall = payload; // Update the state with the payload value
      } else {
        state.reCall = null;
      }
    },
    updateStartEndTimeBasedOnTimeZone: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.update__start_endTime__based__TimeZone =
        payload || Intl.DateTimeFormat().resolvedOptions().timeZone; // Update the field with the provided payload
    },

    toggleCalendarIdVisibility: (state, { payload }: PayloadAction<string>) => {
      const calendarId = payload;
      if (state?.hiddenCalendarIds?.includes(calendarId)) {
        // Remove the calendar ID if it is already hidden
        state.hiddenCalendarIds = state?.hiddenCalendarIds?.filter(
          (id) => id !== calendarId
        );
      } else {
        // Add the calendar ID to hide its events
        state.hiddenCalendarIds?.push(calendarId);
      }
    },

    // Action to update filter criteria
    updateFilterCriteria: (
      state,
      { payload }: PayloadAction<Partial<FilterCriteria>>
    ) => {
      state.filterCriteria = {
        ...state.filterCriteria,
        ...payload, // Merge existing criteria with new ones
      };
    },

    resetFilterCriteria: (state) => {
      state.filterCriteria = null;
    },

    updateCalendarSettings: (
      state,
      { payload }: PayloadAction<Partial<CalendarSettingsPayload>>
    ) => {
      state.calSettings = {
        ...state.calSettings,
        ...payload,
      };
    },

    addEvent: (state, { payload }: PayloadAction<EventData>) => {
      state.data.events.push(payload);
    },

    updateEvent: (state, { payload }: PayloadAction<any>) => {
      const index = state.data.events.findIndex(
        (event: any) => event.id === payload.id
      );
      if (index !== -1) {
        state.data.events[index] = { ...state.data.events[index], ...payload };
      }
    },

    deleteEvent: (state, { payload }: PayloadAction<string>) => {
      state.data.events = state.data.events.filter(
        (event: any) => event.id !== payload
      );
    },

    loadCalendarEvents: (state, { payload }: PayloadAction<EventData[]>) => {
      state.allCalenderEventsList = payload;
    },

    toggleEventStatus: (state, { payload }: PayloadAction<string>) => {
      const event = state.data.events.find(
        (event: any) => event.id === payload
      );
      if (event) {
        event.completed = !event.completed;
      }
    },

    loadCalendarSettings: (
      state,
      { payload }: PayloadAction<CalendarSettingsPayload>
    ) => {
      state.calSettings = payload;
    },

    clearCalendarEvents: (state) => {
      state.data.events = [];
    },

    setHiddenCalendars: (state, { payload }: PayloadAction<string[]>) => {
      state.hiddenCalendarIds = payload;
    },

    addGuestToEvent: (
      state,
      { payload }: PayloadAction<{ eventId: string; guest: GuestData }>
    ) => {
      const event = state.data.events.find(
        (event: any) => event.id === payload.eventId
      );
      if (event) {
        event.guests.push(payload.guest);
      }
    },

    removeGuestFromEvent: (
      state,
      { payload }: PayloadAction<{ eventId: string; guestEmail: string }>
    ) => {
      const event = state.data.events.find(
        (event: any) => event.id === payload.eventId
      );
      if (event) {
        event.guests = event.guests.filter(
          (guest: any) => guest.email !== payload.guestEmail
        );
      }
    },

    setScheduleList: (state, { payload }: PayloadAction<ScheduleData[]>) => {
      state.scheduleList = payload;
    },

    updateBookingLink: (state, { payload }: PayloadAction<BookingLinkData>) => {
      const index = state.data.bookingLinks.findIndex(
        (link: any) => link.bookingLinkSlug === payload.bookingLinkSlug
      );
      if (index !== -1) {
        state.data.bookingLinks[index] = {
          ...state.data.bookingLinks[index],
          ...payload,
        };
      }
    },

    deleteBookingLink: (state, { payload }: PayloadAction<string>) => {
      state.data.bookingLinks = state.data.bookingLinks.filter(
        (link: any) => link.bookingLinkSlug !== payload
      );
    },

    loadBookingLinks: (
      state,
      { payload }: PayloadAction<BookingLinkData[]>
    ) => {
      state.data.bookingLinks = payload;
    },
  },
});

export const {
  recallAPI,
  toggleCalendarIdVisibility,
  updateFilterCriteria,
  updateStartEndTimeBasedOnTimeZone,
  /* Create Calendar actions */
  createCalendar__Request: createCalendar__RequestAction,
  createCalendar__Failure: createCalendar__FailureAction,
  createCalendar__Success: createCalendar__SuccessAction,
  deleteOneEvents__Success: deleteOneEvents__SuccessAction,

  /* Get all Calendars actions */
  getAllCalendars__Request: getAllCalendars__RequestAction,
  getAllCalendars__Failure: getAllCalendars__FailureAction,
  getAllCalendars__Success: getAllCalendars__SuccessAction,

  /* Get one Calendar actions */
  getOneCalendar__Request: getOneCalendar__RequestAction,
  getOneCalendar__Failure: getOneCalendar__FailureAction,
  getOneCalendar__Success: getOneCalendar__SuccessAction,

  /* Update Calendar actions */
  updateCalendar__Request: updateCalendar__RequestAction,
  updateCalendar__Failure: updateCalendar__FailureAction,
  updateCalendar__Success: updateCalendar__SuccessAction,

  /* Delete Calendar actions */
  deleteCalendar__Request: deleteCalendar__RequestAction,
  deleteCalendar__Failure: deleteCalendar__FailureAction,
  deleteCalendar__Success: deleteCalendar__SuccessAction,

  /* Create Calendar Settings actions */
  createCalendarSettings__Request: createCalendarSettings__RequestAction,
  createCalendarSettings__Failure: createCalendarSettings__FailureAction,
  createCalendarSettings__Success: createCalendarSettings__SuccessAction,

  /* Get Calendar Settings actions */
  getCalendarSettings__Request: getCalendarSettings__RequestAction,
  getCalendarSettings__Failure: getCalendarSettings__FailureAction,
  getCalendarSettings__Success: getCalendarSettings__SuccessAction,

  /* Update Calendar Settings actions */
  updateCalendarSettings__Request: updateCalendarSettings__RequestAction,
  updateCalendarSettings__Failure: updateCalendarSettings__FailureAction,
  updateCalendarSettings__Success: updateCalendarSettings__SuccessAction,

  /* Add Connected Calendar actions */
  addConnectedCalendar__Request: addConnectedCalendar__RequestAction,
  addConnectedCalendar__Failure: addConnectedCalendar__FailureAction,
  addConnectedCalendar__Success: addConnectedCalendar__SuccessAction,

  /* Remove Connected Calendar actions */
  removeConnectedCalendar__Request: removeConnectedCalendar__RequestAction,
  removeConnectedCalendar__Failure: removeConnectedCalendar__FailureAction,
  removeConnectedCalendar__Success: removeConnectedCalendar__SuccessAction,

  /* Create Event actions */
  createEvent__Request: createEvent__RequestAction,
  createEvent__Failure: createEvent__FailureAction,
  createEvent__Success: createEvent__SuccessAction,

  /* Get all Events actions */
  getAllEvents__Request: getAllEvents__RequestAction,
  getAllEvents__Failure: getAllEvents__FailureAction,
  getAllEvents__Success: getAllEvents__SuccessAction,

  /* Create Task actions */
  createTask__Request: createTask__RequestAction,
  createTask__Failure: createTask__FailureAction,
  createTask__Success: createTask__SuccessAction,

  /* Get all Tasks actions */
  getAllTasks__Request: getAllTasks__RequestAction,
  getAllTasks__Failure: getAllTasks__FailureAction,
  getAllTasks__Success: getAllTasks__SuccessAction,

  /* Create Booking Link actions */
  createBookingLink__Request: createBookingLink__RequestAction,
  createBookingLink__Failure: createBookingLink__FailureAction,
  createBookingLink__Success: createBookingLink__SuccessAction,

  /* Get Booking Links actions */
  getBookingLinks__Request: getBookingLinks__RequestAction,
  getBookingLinks__Failure: getBookingLinks__FailureAction,
  getBookingLinks__Success: getBookingLinks__SuccessAction,

  /* Event Response actions */
  eventResponse__Request: eventResponse__RequestAction,
  eventResponse__Failure: eventResponse__FailureAction,
  eventResponse__Success: eventResponse__SuccessAction,

  // Schedule actions
  createSchedule__Request: createSchedule__RequestAction,
  createSchedule__Failure: createSchedule__FailureAction,
  createSchedule__Success: createSchedule__SuccessAction,

  getAllSchedules__Request: getAllSchedules__RequestAction,
  getAllSchedules__Failure: getAllSchedules__FailureAction,
  getAllSchedules__Success: getAllSchedules__SuccessAction,

  getOneSchedule__Request: getOneSchedule__RequestAction,
  getOneSchedule__Failure: getOneSchedule__FailureAction,
  getOneSchedule__Success: getOneSchedule__SuccessAction,

  updateSchedule__Request: updateSchedule__RequestAction,
  updateSchedule__Failure: updateSchedule__FailureAction,
  updateSchedule__Success: updateSchedule__SuccessAction,

  deleteSchedule__Request: deleteSchedule__RequestAction,
  deleteSchedule__Failure: deleteSchedule__FailureAction,
  deleteSchedule__Success: deleteSchedule__SuccessAction,

  subscribeToICal__Request: subscribeToICal__RequestAction,
  subscribeToICal__Failure: subscribeToICal__FailureAction,
  subscribeToICal__Success: subscribeToICal__SuccessAction,

  subscribeToUserCal__Request: subscribeToUserCal__RequestAction,
  subscribeToUserCal__Failure: subscribeToUserCal__FailureAction,
  subscribeToUserCal__Success: subscribeToUserCal__SuccessAction,

  getAllBookingLinks__Request: getAllBookingLinks__RequestAction,
  getAllBookingLinks__Failure: getAllBookingLinks__FailureAction,
  getAllBookingLinks__Success: getAllBookingLinks__SuccessAction,

  trackVisit__Request: trackVisit__RequestAction,
  trackVisit__Failure: trackVisit__FailureAction,
  trackVisit__Success: trackVisit__SuccessAction,

  getPublicBookingLink__Request: getPublicBookingLink__RequestAction,
  getPublicBookingLink__Failure: getPublicBookingLink__FailureAction,
  getPublicBookingLink__Success: getPublicBookingLink__SuccessAction,

  getPrivateBookingLink__Request: getPrivateBookingLink__RequestAction,
  getPrivateBookingLink__Failure: getPrivateBookingLink__FailureAction,
  getPrivateBookingLink__Success: getPrivateBookingLink__SuccessAction,

  getAvailableTimeSlots__Request: getAvailableTimeSlots__RequestAction,
  getAvailableTimeSlots__Failure: getAvailableTimeSlots__FailureAction,
  getAvailableTimeSlots__Success: getAvailableTimeSlots__SuccessAction,

  createBookingEvent__Request: createBookingEvent__RequestAction,
  createBookingEvent__Failure: createBookingEvent__FailureAction,
  createBookingEvent__Success: createBookingEvent__SuccessAction,

  LoginExternalCalendar__Request: LoginExternalCalendar__RequestAction,
  LoginExternalCalendar__Failure: LoginExternalCalendar__FailureAction,
  LoginExternalCalendar__Success: LoginExternalCalendar__SuccessAction,

  AuthExternalCalendar__Request: AuthExternalCalendar__RequestAction,
  AuthExternalCalendar__Success: AuthExternalCalendar__SuccessAction,
  AuthExternalCalendar__Failure: AuthExternalCalendar__FailureAction,

  getOneEvent__Request: getOneEvent__RequestAction,
  getOneEvent__Failure: getOneEvent__FailureAction,
  getOneEvent__Success: getOneEvent__SuccessAction,

  updateCalendarAppearance__Request: updateCalendarAppearance__RequestAction,
  updateCalendarAppearance__Success: updateCalendarAppearance__SuccessAction,
  updateCalendarAppearance__Failure: updateCalendarAppearance__FailureAction,
  shareCalendarWithUser__Request: shareCalendarWithUser__RequestAction,
  shareCalendarWithUser__Success: shareCalendarWithUser__SuccessAction,
  shareCalendarWithUser__Failure: shareCalendarWithUser__FailureAction,

  getSharedWith__Request: getShareWith__RequestAction,
  getSharedWith__Success: getShareWith__SuccessAction,
  getSharedWith__Failure: getShareWith__FailureAction,

  updateEventDate__Request: updateEventDate__RequestAction,
  updateEventDate__Success: updateEventDate__SuccessAction,
  updateEventDate__Failure: updateEventDate__FailureAction,

  updateEventTaskStatus__Request: updateEventTaskStatus__RequestAction,
  updateEventTaskStatus__Failure: updateEventTaskStatus__FailureAction,
  updateEventTaskStatus__Success: updateEventTaskStatus__SuccessAction,

  cmd__calendar__Success: cmd__calendar__SuccessAction,
} = CalendarSlice.actions;

export default CalendarSlice.reducer;
