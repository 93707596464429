import { createApiAction } from "../constantApi_config";
import * as authSlice from "../auth/authSlice";
import * as adminSlice from "./AdminPortalSlice";
/* Invitation to user for joining org... */
export const invitationToJoinOrg__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/admin-portal/users/invite`,
        onStart: `inviteUsers__RequestAction.type`,
        onSuccess: adminSlice.inviteUsers__Success.type,
        onError: `inviteUsers__FailureAction.type`,
    });

/* API to get all invitations for the company */
export const getAllInvitationsForCompany__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/admin-portal/company/invitations`,
        onStart: `getAllInvitationsForCompany__RequestAction.type`,
        onSuccess: adminSlice.getAllInvitationsForCompany__Success.type,
        onError: `getAllInvitationsForCompany__FailureAction.type`,
    });

/* API to get all users for the company */
export const getAllUsersForCompany__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/admin-portal/team/users?expand=free`,
        onStart: `getAllInvitationsForCompany__RequestAction.type`,
        onSuccess: adminSlice.getAllUserForCompany__Success.type,
        onError: `getAllInvitationsForCompany__FailureAction.type`,
    });

/* API to create a team */
export const createTeam__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/admin-portal/team/team`,
        onStart: `createTeam__RequestAction.type`,
        onSuccess: adminSlice.createTeam__Success.type,
        onError: `createTeam__FailureAction.type`,
    });

/* API to get all teams for the company */
export const getAllTeamsForCompany__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/admin-portal/team/team`,
        onStart: `getAllTeamsForCompany__RequestAction.type`,
        onSuccess: adminSlice.getAllTeamsForCompany__Success.type,
        onError: `getAllTeamsForCompany__FailureAction.type`,
    });

/* API to get company details */
export const getCompanyDetails__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/admin-portal/company`,
        onStart: `getCompanyDetails__RequestAction.type`,
        onSuccess: adminSlice.getCompanyDetails__Success.type,
        onError: `getCompanyDetails__FailureAction.type`,
    });

/* API to update company email */
export const updateCompanyEmail__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/admin-portal/company/email`,
        onStart: `updateCompanyDetails__RequestAction.type`,
        onSuccess: adminSlice.updateCompanyDetails__Success.type,
        onError: `updateCompanyDetails__FailureAction.type`,
    });

/* API to update company name */
export const updateCompanyName__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/admin-portal/company/name`,
        onStart: `updateCompanyDetails__RequestAction.type`,
        onSuccess: adminSlice.updateCompanyDetails__Success.type,
        onError: `updateCompanyDetails__FailureAction.type`,
    });

/* API to update company icon */
export const updateCompanyIcon__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/admin-portal/company/icon`,
        onStart: `updateCompanyDetails__RequestAction.type`,
        onSuccess: adminSlice.updateCompanyDetails__Success.type,
        onError: `updateCompanyDetails__FailureAction.type`,
    });

/* API to update company departments */
export const updateCompanyDepartments__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/admin-portal/company/departments`,
        onStart: `updateCompanyDetails__RequestAction.type`,
        onSuccess: authSlice.updateUserDeptDetails__SuccessAction.type,
        onError: `updateCompanyDetails__FailureAction.type`,
    });

/* API to update company Headquartered Location */
export const updateCompanyHeadquarteredLocation__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/admin-portal/company/headquarters-location`,
        onStart: `updateCompanyDetails__RequestAction.type`,
        onSuccess: authSlice.updateUserDeptDetails__SuccessAction.type,
        onError: `updateCompanyDetails__FailureAction.type`,
    });

/* API to update company domains */
export const updateCompanyDomains__api = (cid: string, payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/admin-portal/organizations/${cid}/domains`,
        onStart: `updateCompanyDetails__RequestAction.type`,
        onSuccess: authSlice.updateUserDeptDetails__SuccessAction.type,
        onError: `updateCompanyDetails__FailureAction.type`,
    });

/* API to update company reference-id */
export const updateCompanyReferenceId__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/admin-portal/company/reference-id`,
        onStart: `updateCompanyDetails__RequestAction.type`,
        onSuccess: adminSlice.updateCompanyDetails__Success.type,
        onError: `updateCompanyDetails__FailureAction.type`,
    });

/* API to add company domain */
export const addCompanyDomain__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/admin-portal/company/domain`,
        onStart: `addCompanyDomain__RequestAction.type`,
        onSuccess: adminSlice.addCompanyDomain__Success.type,
        onError: `addCompanyDomain__FailureAction.type`,
    });
