import {MESSAGE_ENUMS} from "../components/pages/Message/constant";

const getChannelType = (channelId: string) => {
    if (channelId.length === 11) return MESSAGE_ENUMS.SLACK;
    if (channelId.length === 24) return MESSAGE_ENUMS.BEARISH;
    return MESSAGE_ENUMS.TEAMS;
};

export {
    getChannelType,
};