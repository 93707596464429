import { UserMsgStatusType } from "../../../components/pages/Message/common";

/**
 * Represents a user object.
 */
export interface User {
    id?: string;
    email?: string;
    token: string;
    data?: any;
    _id?: any;
    isWorkOSUser?: any;
    companyId?: any;
    firstName?: string;
    lastName?: string;
    avatar?: string;
    walkthroughCompleted?:boolean;
}

// export type UserMsgStatusType = 'active' | 'pending' | 'doNotDisturb'

export interface SearchableUserType {
    _id?: any;
    id?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    avatar?: string;
    status?:UserMsgStatusType
}

export interface CompanyAvailability {
    success: boolean;
    message: string;
    data: object;
}

export interface CompanyOption {
    claimed: boolean;
    name: string | null;
    domain: string;
    icon: string | null;
    brandId: string;
}

export interface fetchCompanyList {
    success: boolean;
    message: string;
    data?: CompanyOption[];
}

/* AUTH _ CONNECTED ACCOUNTS ACROSS APPLICATION */
// Define an enum for account centers
export enum AccountCenter {
    EMAIL = "EMAIL",
    WORKSPACE = "WORKSPACE",
    CRM = "CRM",
    CALL = "CALL",
    PLANS = "PLANS",
    MESSAGE = "MESSAGE",
    TRAX = "TRAX",
}

// Define an enum for account types
export enum AccountType {
    GMAIL = "gmail",
    OUTLOOK = "outlook",
    TEAMS = "teams",
}

// Define the connected accounts type using the AccountType enum
export type ConnectedAccount = {
    id: string; // Unique identifier for the account
    name: string; // Display name of the account
    type: AccountType; // Type of the account
    isTokenAlive: boolean; // Indicates if the token is alive
    center: AccountCenter; // Center from which the account is connected
    accountProfilePic?: string; // Optional profile picture URL
    isVisible?: boolean; // Indicates if the account is visible
    token?: string; // Optional token
};

/**
 * Represents the authentication state.
 */
export interface AuthState
    extends ExternalAuthState,
    optVerificationState,
    TempAuthenticationResult {
    isAuthenticated: boolean;
    user_token?:any | null;
    user: User | null;
    error: string | null;
    isSignUp?: boolean;
    data?: any;
    eMessage?: string | null;
    eData?: any;
    eSuccess?: boolean | null;
    eError?: string | null;
    departmentList?: [];
    companyAvailibility?: CompanyAvailability | {} | [] | null;
    companyList?: fetchCompanyList | null;
    users: {
        [key: string]: User;
    };
    usersList: any;
    usersListWithId: Record<string, User>;
    connectedAccounts: ConnectedAccount[]; // Updated to include the new field
    workOSClient: any | null;
}

/**
 * Represents the opt state.
 */
interface optVerificationState {
    isOptSend?: boolean | null;
    isOptVerify?: boolean | null;
}

export interface OptSendOrVerifyResult {
    success: boolean | null;
    message: string | null;
    data?: {};
}

export interface globalApiResult {
    success?: boolean | null;
    message: string | null;
    data?: [];
}

export interface TempAuthenticationResult {
    /* This inteface rarely used */
    email_temp?: string | "" | undefined;
    password_temp?: string | "" | undefined;
    timestamp_store_at?: number | "" | 0;
}

// Define the types for GoogleAuth and MicrosoftAuth
interface GoogleAuth {
    type: "GoogleAuth";
}

interface MicrosoftAuth {
    type: "MicrosoftAuth";
}

// Use a type alias to define a union type
type ExternalEntity = GoogleAuth | MicrosoftAuth | null;

export interface ExternalAuthState {
    externalEntities: ExternalEntity | null;
    message?: string | null;
    error?: string | null;
    data?: any;
    success?: boolean | null;
}
/**
 * Represents the payload for login action.
 */
export interface LoginPayload {
    email: string;
    password: string;
}

export interface SendOtpPayload {
    sendTo: string;
    password: string | "" | undefined;
}

/**
 * Represents the payload for sign up action.
 */
export interface SignUpPayload {
    firstName: string | "";
    lastName: string | "";
    email: string | "";
    domain: string | "" | null;
    projectName?: string | "";
    jobTitle?: string;
    departmentIds?: string[];
    teamIds?: string[];
    phoneNumber?: string;
    mobileNumber?: string;
    location?: string;
    timezone?: string;
    language?: string;
    password?: string;
    twoFactorEnabled?: boolean;
    mfaBackupCodes?: string[];
    ipRestrictions?: string[];
    status?: string;
    notifications?: {
        desktop?: boolean;
        slack?: boolean;
        email?: {
            allActivity?: boolean;
            mentions?: boolean;
            dailySummaries?: boolean;
            weeklyUpdates?: boolean;
        };
    };
    avatar?: string | File;
    sessionAudits?: string[];
    companyId?: string;
    companyName?: string;
    agreedToTerms?: boolean | true;
    loginHistory?: Array<{
        loginTime?: string;
        logoutTime?: string;
    }>;
    activityLog?: Array<{
        center?: string;
        timestamp?: string;
    }>;
    paymentMethod?: string;
    planType?: string;
    userType?: string;
    usageLimits?: {
        storage?: number;
        apiCalls?: number;
    };
    walkthroughCompleted?: boolean;
    preferences?: {
        theme?: string;
        fontSize?: string;
    };
    accessTokens?: {
        [key: string]: string;
    };
    compliance?: {
        gdpr?: boolean;
        ccpa?: boolean;
    };
}

export interface companyAvailibityPayload {
    companyName?: string;
    domain?:string;
}

export interface sendOptPayload {
    sendTo: string;
    firstName?: string;
    lastName?: string;
    password?: string;
}

export interface verifyOptPayload {
    OTP?: string | null | "";
    email?: string | "";
}

export interface ExternalAuthType {
    optionAuth?: string;
    email?: string;
    password?: string;
}

/**
 * Represents an action related to authentication.
 */
export interface AuthAction {
    type: string;
    payload: any;
}

export interface usersPayload {
    success?: boolean | null;
    message: string | null;
    data?: User[];
}
