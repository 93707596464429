import React, { useEffect, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  IconButton,
  InputBase,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  BoardCardDatePicker,
  CheckboxMedium,
  ColoredBadge,
  DropdownWithColor,
  DUMMY_PRIORITY_OPTIONS,
  EditableTimeTextField,
  ThreeDotLargeMenuItemProps,
  ThreeDotMenuLarge,
} from "../../../../../common";
import {
  ArchiveIcon,
  ClockIcon,
  CommentsIcon,
  ConnectedIcon,
  CopyIcon,
  DepedanciesIcon,
  EmailIcon,
  FlagIcon,
  FullViewIcon,
  LastCalendarIcon,
  MergeIcon,
  NotesIcon,
  NotificationIcon,
  PlusIcon,
  RenameIcon,
  RightArrowIcon,
  SelectIcon,
  StrongestrelationshipIcon,
  TemplateIcon,
  TypeIcon,
  UpdateIcon,
} from "../../../../../../images";
import styles from "./BoardCardDisplay.module.css";
import { adjustHexColor } from "../../../../../../Utils";
import dayjs, { Dayjs } from "dayjs";

interface props {
  title: string;
  titleMain: string;
  selectedColor: string;
  onTitleChange?: (value: string) => void;
  handleFullView?: (mode?: string, data?: any) => void;
  stack?: any;
  updateStackData?: any;
}

const BoardCardDisplay: React.FC<props> = ({
  title,
  titleMain,
  selectedColor,
  onTitleChange,
  handleFullView,
  stack,
  updateStackData,
}) => {
  const [titleValue, setTitleValue] = React.useState(stack?.text);
  const [estimate, setEstimate] = useState({
    m: stack?.estimatedTime?.minutes || 0,
    h: stack?.estimatedTime?.hours || 0,
  });

  useEffect(() => {
    if (stack?.text) {
      setTitleValue(stack?.text);
    }
    if (stack?.estimatedTime) {
      setEstimate({
        m: stack?.estimatedTime?.minutes || 0,
        h: stack?.estimatedTime?.hours || 0,
      });
    }
  }, [stack?.text, stack?.estimatedTime]);

  const badgedColor = adjustHexColor(
    stack?.status?.customColorHex ||
      stack?.status?.defaultColorHex ||
      selectedColor,
    100
  );
  const initialDate: Dayjs = dayjs(stack?.dueDate);

  const [debouncedValue, setDebouncedValue] = useState(titleValue);

  // Handle the onChange event to update the titleValue state
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitleValue(e.target.value);
  };

  // Debounce effect: Set a timeout to call updateStackData after a delay
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (debouncedValue !== titleValue) {
        setDebouncedValue(titleValue);
        if (onTitleChange) {
          onTitleChange(titleValue);
        }
        updateStackData(stack?.id, "text", titleValue); // Call updateStackData when titleValue changes
      }
    }, 500); // Debounce delay (500ms)

    return () => {
      clearTimeout(timeoutId); // Clear timeout if titleValue changes within the debounce delay
    };
  }, [titleValue, stack, onTitleChange, updateStackData, debouncedValue]);
  // Adjust this function and store
  const handleDateSelect = (date: Dayjs) => {
    // Convert to ISO 8601 string
    const formattedDate = date.toISOString(); // Converts to "2024-09-24T18:30:00.000Z"

    // Update the dueDate in the stack data
    updateStackData(stack.id, "dueDate", formattedDate);
  };

  // Handle the blur event when the user clicks outside the input
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onTitleChange) {
      onTitleChange(titleValue);
      updateStackData(stack, stack?.id, "text", titleValue);
    }
  };

  // Handle the keydown event for the Enter key
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default behavior for Enter
      if (onTitleChange) {
        onTitleChange(titleValue);
        updateStackData(stack, stack?.id, "text", titleValue);
      }
    }
  };

  return (
    <Stack className={styles["board-card-display"]} sx={{ bgcolor: "white" }}>
      {/* card header */}
      <Stack className={styles["board-card-display__header"]}>
        {/* card-header- name */}
        <Stack className={styles["board-card-display__header-name"]}>
          <InputBase
            sx={{
              font: "normal normal normal 14px/20px Source Serif Pro",
              flexGrow: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            value={titleValue}
            onChange={handleChange}
            onBlur={(e: any) => handleBlur(e)}
            onKeyDown={(e: any) => handleKeyDown(e)}
          />
        </Stack>
        {/* card-header-options buttons */}
        <Stack className={styles["board-card-display__header-options"]}>
          <ThreeDotMenuLarge options={THREE_DOT_MENU_OPTIONS} />
          <Tooltip placement="top" arrow title="Full View">
            <IconButton
              disableRipple
              className={styles["board-card-display__icon-button"]}
              onClick={() => handleFullView && handleFullView("view", stack)}
            >
              <FullViewIcon />
            </IconButton>
          </Tooltip>
          <CheckboxMedium />
        </Stack>
      </Stack>
      {/* bard-body here */}
      
      <Stack className={styles["board-card-display__body"]}>
        {/* -- icon and badge here -- */}
        <Stack className={styles["board-card-display__body-item"]}>
          <SelectIcon color={badgedColor || "black"} w={15} />
          <ColoredBadge title={stack?.status?.value} bgColor={badgedColor} />
        </Stack>

        {/* -- time component -- */}
        <Stack className={styles["board-card-display__body-item"]}>
          <LastCalendarIcon w={15} />
          <EditableTimeTextField
            initialValue={estimate?.h || "0"}
            variant="hrs"
            onSave={(hours) => {
              // Call updateStackData with hours and current minutes
              updateStackData(stack?.id, "estimatedTime", {
                hours: hours,
                minutes: estimate?.m || 0,
              });
            }}
          />
          <EditableTimeTextField
            initialValue={estimate?.m || "0"}
            variant="mins"
            onSave={(minutes) => {
              // Call updateStackData with current hours and new minutes
              updateStackData(stack?.id, "estimatedTime", {
                hours: estimate?.h || 0,
                minutes: minutes,
              });
            }}
          />
        </Stack>

        {/* -- priority component -- */}
        <Stack className={styles["board-card-display__body-item"]}>
          <FlagIcon w={15} />
          <DropdownWithColor
            options={DUMMY_PRIORITY_OPTIONS}
            onChange={(value) => null}
            onSelect={(value) => {
              updateStackData(stack?.id, "priority", value);
            }}
            initialValue={stack?.priority}
          />
          {/* <HighPriorityMenuDropdown /> */}
        </Stack>

        <Stack className={styles["board-card-display__body-item"]}>
          <StrongestrelationshipIcon w={15} />

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            {stack?.assignedTo?.length > 1 ? (
              <AvatarGroup max={4}>
                {stack?.assignedTo?.map((assignee: any, index: number) => (
                  <Avatar
                    key={assignee?._id || index}
                    alt={`${assignee?.firstName} ${assignee?.lastName}`}
                    src={assignee?.avatar}
                    sx={{ width: 20, height: 20 }}
                  />
                ))}
              </AvatarGroup>
            ) : (
              <Stack direction="row" gap="5px" alignItems="center">
                <Avatar
                  alt={`${stack?.assignedTo?.[0]?.firstName} ${stack?.assignedTo?.[0]?.lastName}`}
                  src={stack?.assignedTo?.[0]?.avatar}
                  sx={{ width: 20, height: 20 }}
                />
                <Typography
                  sx={{
                    font: "normal normal 300 12px/16px Source Serif Pro",
                    color: "black",
                  }}
                >
                  {`${stack?.assignedTo?.[0]?.firstName} ${stack?.assignedTo?.[0]?.lastName}`}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>

        {/* --date component -- */}
        <Stack className={styles["board-card-display__body-item"]}>
          <ClockIcon w={15} />
          <BoardCardDatePicker
            initialDate={initialDate}
            onSelect={(value: any) => {
              handleDateSelect(value);
            }}
          />
        </Stack>
      </Stack>

      {/* card-footer here */}
      <Stack className={styles["board-card-display__footer"]}>
        {/* -- add notes component -- */}
        <Tooltip placement="top" arrow title="Add Notes">
          <IconButton
            className={styles["board-card-display__icon-button"]}
            disableRipple
          >
            <NotesIcon w={15} />
          </IconButton>
        </Tooltip>

        {/* -- add comments component -- */}
        <Tooltip placement="top" arrow title="Add Comments">
          <IconButton
            className={styles["board-card-display__icon-button"]}
            disableRipple
          >
            <CommentsIcon w={15} />
          </IconButton>
        </Tooltip>

        {/* -- total depedancies number -- */}
        <Tooltip placement="top" arrow title="Depedancies">
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              cursor: "default",
            }}
          >
            <DepedanciesIcon w={15} />
            <Typography
              sx={{ font: "normal normal 300 12px/16px Source Serif Pro" }}
            >
              0
            </Typography>
          </Stack>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default BoardCardDisplay;

export const THREE_DOT_MENU_OPTIONS: ThreeDotLargeMenuItemProps[] = [
  {
    text: "Rename",
    icon: RenameIcon,
    action: () => console.log("Rename clicked"),
  },
  {
    text: "Add to",
    icon: PlusIcon,
    action: () => console.log("Add to clicked"),
  },
  {
    text: "Convert to",
    icon: UpdateIcon,
    action: () => console.log("Convert to clicked"),
  },
  {
    text: "Connect to",
    icon: ConnectedIcon,
    action: () => console.log("Connect to clicked"),
  },
  {
    text: "Task type",
    icon: TypeIcon,
    action: () => console.log("Task type clicked"),
  },
  { divider: true },
  {
    text: "Duplicate",
    icon: CopyIcon,
    action: () => console.log("Undo clicked"),
  },
  {
    text: "Remind me",
    icon: NotificationIcon,
    action: () => console.log("View edit history clicked"),
  },
  {
    text: "Send email about task",
    icon: EmailIcon,
    action: () => console.log("View analytics clicked"),
  },
  {
    text: "View edit history",
    icon: ClockIcon,
    action: () => console.log("Export clicked"),
  },
  {
    text: "Merge",
    icon: MergeIcon,
    action: () => console.log("Open Bearish OS app clicked"),
  },
  {
    text: "Move",
    icon: RightArrowIcon,
    action: () => console.log("Connect to project clicked"),
  },
  {
    text: "Dependencies",
    icon: DepedanciesIcon,
    action: () => console.log("Customize page clicked"),
  },
  { divider: true },
  {
    text: "Template",
    icon: TemplateIcon,
    action: () => console.log("Customize page clicked"),
  },
  {
    text: "Archive",
    icon: ArchiveIcon,
    action: () => console.log("Customize page clicked"),
  },
];
