import {IconButton, Popover, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import ItemInsightsAccessCardForChat from "../../../ItemInsightsAccessCard/ItemInsightsAccessCardForChat";
import {useTranslation} from "react-i18next";
import CustomAddMember from "../../../../../../common/CustomAddMemberPopover/CustomAddMemberPopover";
import {AuthState} from "../../../../../../../redux";
import {RootState} from "../../../../../../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {
    addParticipantsToChannel,
} from "../../../../../../../redux/Slice/message/messageActions";

interface ContactsTabProps {
    channelDetails?: any
}

const ContactsTab: React.FC<ContactsTabProps> = ({channelDetails}) => {
    const {t} = useTranslation()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const {usersList} = useSelector((state: RootState) => state.auth as AuthState);
    const dispatch = useDispatch();

    const handleAddMembersToChannel = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSetSelectedUser = (userList: any) => {
        setSelectedUser(userList);
    }

    const addMembersToChannel = async ()  => {
        const userIds = selectedUser.map((user: any) => user._id);

        const payload = {
            members: userIds,
        }
        await dispatch(addParticipantsToChannel(payload, channelDetails._id));
        handleClose();
    }

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <Stack sx={{gap: "20px", height: "100%"}}>
            <Stack sx={{flexDirection: "row", justifyContent: "space-between"}}>
                <Typography
                    sx={{font: "normal normal 300 14px/18px Source Serif Pro"}}
                >
                    {t('MESSAGE.ALL_CONTACTS')}
                </Typography>
                <IconButton onClick={handleAddMembersToChannel} sx={{p: 0, borderRadius: 0}} disableRipple>
                    <Typography
                        sx={{
                            font: "normal normal 400 14px/18px Source Serif Pro",
                            color: "#000AFF",
                        }}
                    >
                        {t('MESSAGE.SHARE')}
                    </Typography>
                </IconButton>
            </Stack>

            <Stack
                sx={{
                    flexGrow: 1,
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                    gap: "20px",
                }}
            >
                {channelDetails?.participants
                    ?.filter((userDetail: any) => !userDetail.isLeave)
                    .map((userDetail: any, index: any) => {
                        return (
                            <ItemInsightsAccessCardForChat
                                key={index}
                                userId={userDetail.userId}
                                currentChannelId={channelDetails._id}
                                userNameOrEmail={userDetail?.firstName || userDetail?.email || ""}
                                timestamp={userDetail?.createdAt}
                                userIconSrc={userDetail.avatar}
                                userRole={userDetail.role}
                                channelDetails={channelDetails}
                            />
                        );
                    })}
            </Stack>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <CustomAddMember userList={usersList} setSelectedUsers={handleSetSelectedUser} onAdd={addMembersToChannel} onCancel={handleClose}/>
            </Popover>
        </Stack>
    );
};

export default ContactsTab;
