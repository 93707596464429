import { Stack } from "@mui/material";
import React from "react";
import {
  BalancesAndTalliesView,
  ImageDetailsView,
  QuickActionsView,
} from "./view";
import { ButtonView } from "../../../Dashboard/components/TodayShiftDashboard/view";

interface PersonalInfoContainerProps {}

const PersonalInfoContainer: React.FC<PersonalInfoContainerProps> = ({}) => {
  return (
    <Stack sx={{ gap: "5px", height: "100%" }}>
      <ImageDetailsView />

      {/* -- buttons here -- */}
      <Stack gap={"5px"}>
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
          <ButtonView buttonLabel="Clock In" bgclr="#308893" />
          <ButtonView buttonLabel="Clock Out" bgclr="#FF0000" />
        </Stack>

        <ButtonView bgclr="#FFB100" buttonLabel="Clock Out, On a Break" />
      </Stack>

      {/* -- -- -- -- -- -- -- -- -- -- -- */}
      <Stack
        sx={{
          height: "2px",
          bgcolor: "#E9EDF2",
          my: "5px",
          borderRadius: "5px",
        }}
      />
      {/* -- -- -- -- -- -- -- -- -- -- -- */}

      <Stack sx={{ gap: "5px", overflow: "auto", scrollbarWidth: "none" }}>
        <QuickActionsView />

        {/* -- -- -- -- -- -- -- -- -- -- -- */}
        <Stack
          sx={{
            height: "2px",
            bgcolor: "#E9EDF2",
            my: "5px",
            borderRadius: "5px",
          }}
        />
        {/* -- -- -- -- -- -- -- -- -- -- -- */}

        <BalancesAndTalliesView />
      </Stack>
    </Stack>
  );
};

export default PersonalInfoContainer;
