import { Button, ButtonProps, Typography } from "@mui/material";
import React from "react";

interface DottedButtonProps extends ButtonProps {
  icon: React.ElementType;
  title: string;
}

const DottedButton: React.FC<DottedButtonProps> = ({
  icon,
  title,
  sx,
  ...props
}) => {
  return (
    <Button
      variant="text"
      sx={{
        bgcolor: "white",
        border: "0.5px dashed #E9EDF2",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
        boxSizing: "border-box",
        height: "25px",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
        minWidth: 0,
        width: "fit-content",
        padding: "0px 10px",
        textTransform: "none",
        color: "black",
        flexShrink: 0,
        ...sx,
      }}
      {...props}
    >
      {React.createElement(icon, { w: 15 })}
      <Typography
        sx={{
          font: "normal normal normal 13px/17px Source Serif Pro",
          color: "black",
        }}
      >
        {title}
      </Typography>
    </Button>
  );
};

export default DottedButton;
