import React from "react";
import {
    Divider,
    IconButton,
    Menu,
    MenuItem,
    MenuProps,
    styled,
    SxProps,
    Tooltip,
    Typography,
} from "@mui/material";
import styles from "./SidebarOpenMenu.module.css";
import { useTranslation } from "react-i18next";
import {
    CloudIcon,
    RightSidebarIcon,
    StartCallIcon,
} from "../../../../../images";
import {
    ButtonType,
    MainActionButton,
    MainActionColor,
} from "../../../../common";
import {
    MailIcon,
    PlanIcon,
    TaskIcon,
    VideoIcon,
} from "../../../../../Router/RouteIcon";

interface SidebarOpenMenuButtonProps {
    w?: number;
    sx?: SxProps;
    menuItems?: any;
    onClickAction?: (selectedOption: string) => void;
    displayOption?: "left" | "right";
    headerIcon?: React.ElementType;
    menuWidth?: string;
    hoverText?: string;
    r?: any;
}

const StyledMenu = styled((props: MenuProps) => {
    const horizontal =
        props.anchorEl instanceof HTMLElement &&
            props.anchorEl.getAttribute("data-display-option") === "right"
            ? "right"
            : "center";

    const transformHorizontal =
        props.anchorEl instanceof HTMLElement &&
            props.anchorEl.getAttribute("data-display-option") === "right"
            ? "left"
            : "right";

    return (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: "bottom",
                horizontal,
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: transformHorizontal,
            }}
            {...props}
        />
    );
})(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 5,
        minWidth: 125,
        boxSizing: "border-box",
        boxShadow: "0px 3px 6px #00000029",
        "& .MuiMenu-list": {
            padding: "5px 0px",
        },
    },
}));

const MENU_LISTS: any[] = [
    {
        id: 1,
        icon: StartCallIcon,
        text: "Start Call",
        LANG: "VIDEO_CENTER.MEETING.START_CALL",
    },
    {
        id: 2,
        icon: VideoIcon,
        text: "Start Meeting",
        LANG: "VIDEO_CENTER.MEETING.START_MEETING",
    },
    {
        id: 3,
        icon: MailIcon,
        text: "Open Mail",
        LANG: "VIDEO_CENTER.MEETING.OPEN_MAIL",
    },
    {
        id: 4,
        icon: PlanIcon,
        text: "Open Calendar",
        LANG: "VIDEO_CENTER.MEETING.OPEN_CALENDER",
    },
    {
        id: 5,
        icon: TaskIcon,
        text: "Open Tasks",
        LANG: "VIDEO_CENTER.MEETING.OPEN_TASKS",
    },
    {
        id: 6,
        icon: CloudIcon,
        text: "Open Cloud",
        LANG: "VIDEO_CENTER.MEETING.OPEN_CLOUD",
    },
];

const SidebarOpenMenu: React.FC<SidebarOpenMenuButtonProps> = ({
    w = 16,
    sx,
    menuItems = MENU_LISTS,
    onClickAction,
    displayOption = "left",
    headerIcon = RightSidebarIcon,
    menuWidth = "125px",
    hoverText,
    r,
    ...props
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { t } = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const target = event.currentTarget;
        target.setAttribute("data-display-option", displayOption);
        setAnchorEl(target);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Tooltip title={hoverText} placement="top" arrow disableInteractive>
                {r ? (
                    <MainActionButton
                        sx={{ marginY: "25px" }}
                        onClick={handleClick}
                        colorVarient={MainActionColor.CallsSalmon}
                        icon="circlePlus"
                        label="Add new"
                        types={ButtonType.Button}
                    />
                ) : (
                    <IconButton
                        {...props}
                        onClick={handleClick}
                        sx={{ ...sx, p: "2px" }}
                        disableRipple
                        disableFocusRipple
                    >
                        {React.createElement(headerIcon, { w: w })}
                    </IconButton>
                )}
            </Tooltip>

            <StyledMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: { minWidth: menuWidth, width: "145px", padding: "0px" },
                }}
            >
                <Typography
                    className={styles.SidebarOpenMenuItemText}
                    sx={{ color: "black", padding: "10px" }}
                >
                    Open in Sidebar
                </Typography>
                {menuItems.map((item: any) => {
                    return item.divider ? (
                        <Divider
                            key={item.id} // Add key to Divider
                            sx={{
                                marginTop: "1px !important",
                                marginBottom: "1px !important",
                                marginX: "10px",
                            }}
                        />
                    ) : (
                        <MenuItem
                            key={item.id}
                            className={styles.SidebarOpenMenuItem}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                onClickAction && onClickAction(item.text);
                                handleClose();
                            }}
                        >
                            {item.icon && (
                                <item.icon
                                    w={15}
                                    color={item.color || "black"}
                                    direction={item.direction}
                                />
                            )}
                            <Typography
                                className={styles.SidebarOpenMenuItemText}
                                sx={{ color: item.textColor || "black" }}
                            >
                                {item?.LANG ? t(item.LANG) : item.text}
                            </Typography>
                        </MenuItem>
                    );
                })}
            </StyledMenu>
        </>
    );
};

export default SidebarOpenMenu;
