type FileType = 'folder' | 'file' | 'image' | 'video' | 'audio' | 'link'

export type MsgAttachmentsType = {
    fileId: string;
    fileType: FileType;
    fileUrl?: string
    fileName?: string
}

export interface MessageDataTyps {
    messageType?: "send" | "received" | string;
    messageText?: any;
    timeStamp?: string;
    messageId?: any;
    replyMessage?: any;
    isEdited?: boolean;
    displayName?: string;
    editMessageHistory?: any;
    editHistory?: any;
    reactions?: any;
    attachedFile?: any;
    attachedImage?: any;
    attachments?: MsgAttachmentsType[],
    content?: any,
    replyTo?: any,
    createdAt?: any,
    userId?: any,
    userDetails?: any,
    _id: any
}

export function filterAttachmentsByType(
    { data, fileType }: {
        data: MsgAttachmentsType[],
        fileType: FileType
    }
): MsgAttachmentsType[] {
    return data.filter((item) => item.fileType === fileType);
}