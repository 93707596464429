import { Stack } from "@mui/material";
import React from "react";
import { ActionsAdmin } from "../../../ActionsAdmin";
import { AllUserTableDataType } from "../../AllUserTableType";

interface ActionsMenuViewProps {
  data: AllUserTableDataType;
}

const ActionsMenuView: React.FC<ActionsMenuViewProps> = ({ data }) => {
  const actionHandler = (value: string) => {
    switch (value) {
      case "Resend Invite":
        console.log("Resend Invite", data);
        break;
      case "Show details":
        console.log("Show details", data);
        break;
    }
  };

  return (
    <Stack sx={{ marginLeft: "15px" }}>
      <ActionsAdmin onSelect={actionHandler} />
    </Stack>
  );
};

export default ActionsMenuView;
