import React, { useEffect, useRef, useState } from "react";
import {
  ButtonSmall,
  MiniPopupContainer,
  MiniPopupContainerLightLabel,
  MiniPopupContainerNormalInput,
  SingleTeammateDropdown,
  SmallButtonColor,
  useCustomSnackbar,
} from "../../../../../../common";
import { DepartmentIcon } from "../../../../../../../images";
import { TeamName } from "../TeamName";
import { Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  invitationToJoinOrg__api,
  reCallAdminAPIsHandler,
} from "../../../../../../../redux";

interface AddTeamleadPopupProps {
  onCloseClick: () => void;
  users?: any[] | null;
}

const AddTeamleadPopup: React.FC<AddTeamleadPopupProps> = ({
  users = [],
  onCloseClick,
}) => {
  const dispatch = useDispatch();
  const enqueueSnackbar_v1 = useCustomSnackbar();
  const inputRef = useRef<HTMLInputElement | null>(null); // Reference for the input
  const [email, setEmail] = useState<string>("");
  const [managerId, setManagerId] = useState<string>("");
  const [teamId, setTeamId] = useState<any>("");

  const inviteTeamLead = async () => {
    try {
      const payload: any = {
        email: email,
        role: "Team Lead",
        managerId: managerId,
        teamId: teamId,
      };

      const action = invitationToJoinOrg__api(payload); // API call to invite admin to the organization

      const r: any = await dispatch(action); // Wait for the API call to complete

      if (r?.success) {
        dispatch(reCallAdminAPIsHandler("user_list")); // Recall all APIs to fetch the updated data
      }
    } catch (error) {
      console.error(error);
    } finally {
      onCloseClick();
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus to the input field when component mounts
    }
  }, []); // Handle email input change
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const transformedUsers = users
    ?.filter((user: any) => user?.userInvitationType === "Manager")
    ?.map((user: any) => {
      const [firstName, lastName] = user?.userName?.split?.(" ");
      return {
        id: user?._id,
        firstName,
        lastName,
        email: user?.userEmail,
        avatar: user?.userIconSrc,
      };
    });

  // Handle save button click
  const handleSaveClick = () => {
    if (email && managerId && teamId) {
      inviteTeamLead(); // Close the popup
    } else {
      enqueueSnackbar_v1("warning", `Missing Email, team ID or Manager ID`, {
        position: { vertical: "top", horizontal: "center" },
        autoHideDuration: 5000,
      });
    }
  };
  return (
    <MiniPopupContainer
      headerIcon={DepartmentIcon}
      containerWidth="270px"
      headerTitle="Add a Team Lead"
      subtitle="You are about to add a team lead to your Bearish OS account."
    >
      <div>
        <MiniPopupContainerLightLabel label="Email Address" />
        <MiniPopupContainerNormalInput
          inputRef={inputRef}
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter email address"
        />
      </div>

      <SingleTeammateDropdown
        label="Manager"
        options={transformedUsers as any}
        onSelect={(e: any) => {
          setManagerId(e?.id as any);
        }}
      />

      <div>
        <MiniPopupContainerLightLabel label="Team name" />
        <TeamName
          onTeamSelect={(e: any) => {
            setTeamId(e?._id);
          }}
        />
      </div>

      <Stack sx={{ alignItems: "center" }}>
        <ButtonSmall
          label="Save"
          onClick={handleSaveClick}
          colorVarient={SmallButtonColor.BearishClay}
        />
      </Stack>
    </MiniPopupContainer>
  );
};

export default AddTeamleadPopup;
