import React from "react";
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover,
    Stack,
    IconButton,
} from "@mui/material";

/* Icons */
import {
    BoxDriveIconCloudStorage as BoxDriveIcon,
    OneDriveIconCloudStorage as OneDriveIcon,
    GoogleDriveIconCloudStroge as GoogleIcon,
    DropBoxIconCloudStorage as DropBoxIcon,
    JiraWorkspace as JiraIcon,
    ConfluenceWorkspace as ConfluenceIcon,
    OutlookCalendar as MicrosoftCalendarIcon,
    GearIcon,
} from "../../../images";

/** Service type */
type Service = {
    name: string;
    icon: React.ReactElement;
};

/** Category type */
type Category = {
    key: string;
    title: string;
    services: Service[];
};

/** Props type */
type Props = {
    categories?: string[]; // Optional: Filter categories (e.g., "cloud", "workspace", "calendar").
    onServiceClick?: (serviceName: string) => void; // Callback for button clicks.
};

/** Configuration for all available categories and their services */
const ALL_CATEGORIES: Category[] = [
    {
        key: "cloud",
        title: "Cloud Storage Services",
        services: [
            { name: "Google Drive", icon: <GoogleIcon /> },
            { name: "OneDrive", icon: <OneDriveIcon /> },
            { name: "Box", icon: <BoxDriveIcon /> },
            { name: "Dropbox", icon: <DropBoxIcon /> },
        ],
    },
    {
        key: "workspace",
        title: "Workspace Services",
        services: [
            { name: "Jira", icon: <JiraIcon /> },
            { name: "Confluence", icon: <ConfluenceIcon /> },
        ],
    },
    {
        key: "calendar",
        title: "Calendar Services",
        services: [{ name: "Microsoft Calendar", icon: <MicrosoftCalendarIcon /> }],
    },
    {
        key: "contact",
        title: "Contact Services",
        services: [{ name: "Microsoft Contacts", icon: <MicrosoftCalendarIcon /> }],
    },
];

/** Main Component */
const CreateConnectionGearIcon: React.FC<Props> = ({
    categories,
    onServiceClick,
}) => {
    // Filter categories to display only those specified in props or show all
    const displayedCategories = categories
        ? ALL_CATEGORIES.filter((cat) => categories.includes(cat.key))
        : ALL_CATEGORIES;

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    // Popover Handlers
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    // Handle Service Click
    const handleServiceClick = (serviceName: string) => {
        if (onServiceClick) onServiceClick(serviceName);
    };

    return (
        <>
            {/* Trigger Button */}
            <Stack>
                <IconButton onClick={handleClick}>
                    <GearIcon w={17} />
                </IconButton>
            </Stack>

            {/* Popover Content */}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Box
                    sx={{
                        borderRadius: "8px",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                        padding: "8px 18px",
                        backgroundColor: "white",
                        maxWidth: "400px",
                        minWidth: "275px",
                        fontFamily: "Source Serif Pro",
                        height: "275px",
                        overflowY: "auto",
                        scrollbarWidth: "none",
                    }}
                >
                    {/* Title */}
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: "700",
                            marginBottom: "8px",
                            fontFamily: "Source Serif Pro",
                        }}
                    >
                        Create Connection
                    </Typography>

                    {/* Dynamic Rendering of Categories */}
                    {displayedCategories.map((category) => (
                        <Box key={category.key} sx={{ marginBottom: "8px" }}>
                            {/* Category Title */}
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    color: "gray",
                                    textTransform: "capitalize",
                                    fontSize: "14px",
                                    borderBottom: "2px solid #db4b4b",
                                    display: "inline-block",
                                    marginBottom: "8px",
                                    fontFamily: "Source Serif Pro",
                                }}
                            >
                                {category.title}
                            </Typography>

                            {/* List of Services */}
                            <List>
                                {category.services.map((service) => (
                                    <ListItem
                                        key={service.name}
                                        disableGutters
                                        button // Makes the entire list item clickable
                                        onClick={() => handleServiceClick(service.name)} // Click handler for the service
                                        sx={{
                                            padding: "4px 8px", // Reduce padding for a compact look
                                            borderRadius: "4px", // Optional: Add rounded corners for button-like look
                                            "&:hover": {
                                                backgroundColor: "#f5f5f5", // Hover effect for button-like behavior
                                            },
                                            marginBottom: "5px",
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: "25px", // Reducing icon spacing
                                                marginRight: "18px", // Fine-tuning gap between icon and text
                                            }}
                                        >
                                            {service.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={service.name}
                                            primaryTypographyProps={{
                                                sx: {
                                                    fontFamily: "Source Serif Pro",
                                                    fontWeight: 400,
                                                },
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    ))}
                </Box>
            </Popover>
        </>
    );
};

export default CreateConnectionGearIcon;
