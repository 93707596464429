import React, { useState } from "react";
import { Stack, Menu, MenuItem, Typography } from "@mui/material";
import {
  TimeMultiplieOptions,
  TimeMultiplieOptionsType,
} from "./TimeMultiplierMenuType";

interface TimeMultiplierMenuProps {
  option?: TimeMultiplieOptionsType[];
  initialValue?: TimeMultiplieOptionsType;
  onselect: (value: TimeMultiplieOptionsType) => void;
}

const TimeMultiplierMenu: React.FC<TimeMultiplierMenuProps> = ({
  onselect,
  option = TimeMultiplieOptions,
  initialValue = option[0],
}) => {
  const [selectedValue, setSelectedValue] =
    useState<TimeMultiplieOptionsType>(initialValue);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectHandler = (item: TimeMultiplieOptionsType) => {
    setSelectedValue(item);
    onselect(item);
    handleClose();
  };

  return (
    <Stack>
      <Stack
        sx={{
          font: "normal normal 300 13px/16px Source Serif Pro",
          width: "35px",
          cursor: "pointer",
          flexDirection: "row",
          justifyContent: "end",
        }}
        onClick={handleClick}
      >
        <Typography sx={{ font: "inherit" }}>{selectedValue.title}</Typography>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            width: "120px",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            mt: "5px",
          },
        }}
      >
        {option.map((item) => (
          <MenuItem
            key={item._id}
            onClick={() => selectHandler(item)}
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              minHeight: 0,
              height: "25px",
              padding: "0px 10px",
            }}
          >
            <Typography
              sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}
            >
              {item.title}
            </Typography>
            <Typography
              sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
            >
              {item.subTitle}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default TimeMultiplierMenu;
