import { Typography } from "@mui/material";
import React from "react";

interface LateInfoHoverCardProps {
  lateInWeek?: number;
  lateInMonth?: number;
  lateLoginTime?: number;
}

const LateInfoHoverCard: React.FC<LateInfoHoverCardProps> = ({
  lateInMonth,
  lateInWeek,
  lateLoginTime,
}) => {
  return (
    <Typography
      sx={{
        width: "175px",
        whiteSpace: "wrap",
        font: "normal normal 300 11px/14px Source Serif Pro",
      }}
    >
      Clocked in {lateLoginTime} minutes late. Late {lateInWeek} week{" "}
      {lateInMonth} this month.
    </Typography>
  );
};

export default LateInfoHoverCard;
