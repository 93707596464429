import {createApiAction} from "../constantApi_config";
import {
    createScreenRecording__FailureAction,
    createScreenRecording__RequestAction,
    createScreenRecording__SuccessAction,
    deleteScreenRecording__FailureAction,
    deleteScreenRecording__RequestAction,
    deleteScreenRecording__SuccessAction,
    getAllScreenRecordings__FailureAction,
    getAllScreenRecordings__RequestAction,
    getAllScreenRecordings__SuccessAction,
    getCoverImages__FailureAction,
    getCoverImages__RequestAction,
    getCoverImages__SuccessAction,
    getScreenRecordingById__FailureAction,
    getScreenRecordingById__RequestAction,
    getScreenRecordingById__SuccessAction,
    saveOrUpdateCoverImage__FailureAction,
    saveOrUpdateCoverImage__RequestAction,
    saveOrUpdateCoverImage__SuccessAction,
    updateScreenRecording__FailureAction,
    updateScreenRecording__RequestAction,
    updateScreenRecording__SuccessAction,
    getAllProjectsGlobal__RequestAction,
    getAllProjectsGlobal__SuccessAction,
    getAllProjectsGlobal__FailureAction,
    getAllWorkspaceGlobal__RequestAction,
    getAllWorkspaceGlobal__SuccessAction,
    getAllWorkspaceGlobal__FailureAction,
    getAllFoldersGlobal__RequestAction,
    getAllFoldersGlobal__SuccessAction,
    getAllFoldersGlobal__FailureAction,
    globalSearch__RequestAction,
    globalSearch__SuccessAction,
    globalSearch__FailureAction,
    getAllUserContactsRequestAction,
    getAllUserContactsSuccessAction,
    getAllUserContactsFailureAction,
    getAllCloudStorageFiles__RequestAction,
    getAllCloudStorageFiles__SuccessAction,
    getAllCloudStorageFiles__FailureAction,
    initiateGroupCall__RequestAction,
    initiateGroupCall__SuccessAction,
    initiateGroupCall__FailureAction,
    getCallUserDetails__RequestAction,
    getCallUserDetails__SuccessAction,
    getCallUserDetails__FailureAction
} from "./globalSlice";

// Create a new screen recording
export const createScreenRecording__api = (data: any) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: "/global/screen-recordings",
        data: data,
        onStart: createScreenRecording__RequestAction.type,
        onSuccess: createScreenRecording__SuccessAction.type,
        onError: createScreenRecording__FailureAction.type,

    });

// Get all screen recordings for the current user
export const getAllScreenRecordings__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: "/global/screen-recordings",
        onStart: getAllScreenRecordings__RequestAction.type,
        onSuccess: getAllScreenRecordings__SuccessAction.type,
        onError: getAllScreenRecordings__FailureAction.type,
    });

// Get a single screen recording by ID
export const getScreenRecordingById__api = (id: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/global/screen-recordings/${id}`,
        onStart: getScreenRecordingById__RequestAction.type,
        onSuccess: getScreenRecordingById__SuccessAction.type,
        onError: getScreenRecordingById__FailureAction.type,
    });

// Update a screen recording by ID
export const updateScreenRecording__api = (id: string, data: any) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/global/screen-recordings/${id}`,
        data: data,
        onStart: updateScreenRecording__RequestAction.type,
        onSuccess: updateScreenRecording__SuccessAction.type,
        onError: updateScreenRecording__FailureAction.type,
    });

// Delete a screen recording by ID
export const deleteScreenRecording__api = (id: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/global/screen-recordings/${id}`,
        onStart: deleteScreenRecording__RequestAction.type,
        onSuccess: deleteScreenRecording__SuccessAction.type,
        onError: deleteScreenRecording__FailureAction.type,
    });

// save cover image

export const saveOrUpdateCoverImage__api = (data: any) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: "/global/cover-images",
        data: data,
        onStart: saveOrUpdateCoverImage__RequestAction.type,
        onSuccess: saveOrUpdateCoverImage__SuccessAction.type,
        onError: saveOrUpdateCoverImage__FailureAction.type,

    });

// Get all cover images for the current user
export const getCoverImages__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: "/global/cover-images",
        onStart: getCoverImages__RequestAction.type,
        onSuccess: getCoverImages__SuccessAction.type,
        onError: getCoverImages__FailureAction.type,
    });

export const getAllProjectsGlobal__api = (userId:any) =>
    createApiAction({
        onStart: getAllProjectsGlobal__RequestAction.type,
        onSuccess: getAllProjectsGlobal__SuccessAction.type,
        onError: getAllProjectsGlobal__FailureAction.type,
        method: "GET",
        url: `/project/get-all-project?ownerId=${userId}`,
        axiosRequired: true,
        unwrap: false,
    });

/* getAllWorkspace__api */
/* Done */
export const getAllWorkspaceGlobal__api = () =>
    createApiAction({
        axiosRequired: true, // Explicitly setting axiosRequired
        method: "GET",
        url: "/workspace",
        onStart: getAllWorkspaceGlobal__RequestAction.type,
        onSuccess: getAllWorkspaceGlobal__SuccessAction.type,
        onError: getAllWorkspaceGlobal__FailureAction.type,
    });

// Get All Folders API
export const getAllFoldersGlobal__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: "/cloud/get-all-folders",
        onStart: getAllFoldersGlobal__RequestAction.type,
        onSuccess: getAllFoldersGlobal__SuccessAction.type,
        onError: getAllFoldersGlobal__FailureAction.type,
    });

// Global Search API
export const globalSearch__api = (body: any) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/global/search`,
        data: body,
        onStart: globalSearch__RequestAction.type,
        onSuccess: globalSearch__SuccessAction.type,
        onError: globalSearch__FailureAction.type,
    });

export const getAllUserContacts = () =>
    createApiAction({
        onStart: getAllUserContactsRequestAction.type,
        onSuccess: getAllUserContactsSuccessAction.type,
        onError: getAllUserContactsFailureAction.type,
        method: "GET",
        url: `/user/contacts`,
        axiosRequired: true,
    });

/* Get All Files */
export const getAllCloudStorageFiles__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/cloud/get-all-files`,
        onStart: getAllCloudStorageFiles__RequestAction.type,
        onSuccess: getAllCloudStorageFiles__SuccessAction.type,
        onError: getAllCloudStorageFiles__FailureAction.type,
    });

// initiateGroupCall
export const initiateGroupCall = (channelId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/global/initiateGroupCall/${channelId}`,
        onStart: initiateGroupCall__RequestAction.type,
        onSuccess: initiateGroupCall__SuccessAction.type,
        onError: initiateGroupCall__FailureAction.type,
    });

export const getCallUserDetails = (data: any) => createApiAction({
    axiosRequired: true,
    method: "POST",
    url: `/user/basic-details`,
    data,
    onStart: getCallUserDetails__RequestAction.type,
    onSuccess: getCallUserDetails__SuccessAction.type,
    onError: getCallUserDetails__FailureAction.type,
});