import React from "react";
import { ButtonSmall, ButtonType, SmallButtonColor } from "../../../../common";
import { ButtonProps } from "@mui/material";

interface MediumButtonSmallProps extends ButtonProps {
  types: ButtonType;
  label: string;
  colorVarient?: SmallButtonColor;
  onClick?: () => void;
}

const MediumButtonSmall: React.FC<MediumButtonSmallProps> = ({
  types,
  label,
  sx,
  onClick,
  colorVarient = SmallButtonColor.CallsSalmon,
  ...props
}) => {
  return (
    <ButtonSmall
      label={label}
      onClick={onClick}
      sx={{
        font: "normal normal 400 15px/18px Source Serif Pro",
        height: "35px",
        width: "135px",
        boxShadow: "0px 6px 3px 0px rgba(42, 48, 181, 0.16)",
        ":hover": { boxShadow: "0px 6px 3px 0px rgba(42, 48, 181, 0.16)" },
        ...sx,
      }}
      types={types}
      colorVarient={colorVarient}
      {...props}
    />
  );
};

export default MediumButtonSmall;
