import React from "react";
import { AllUserTableDataType } from "../../AllUserTableType";
import { UserTypeBadge } from "../../../UserTypeBadge";
import { UserTypeEnum } from "../../../../AdminConsolePageType";

interface UserHierarchyViewProps {
  data: AllUserTableDataType;
}

const UserHierarchyView: React.FC<UserHierarchyViewProps> = ({ data }) => {
  // add logic here if response.usertype is not UserTypeEnum or other
  const getBadgeType = (): UserTypeEnum => {
    switch (data.userInvitationType) {
      case UserTypeEnum.ADMIN:
        return UserTypeEnum.ADMIN;
      case UserTypeEnum.AUDITOR:
        return UserTypeEnum.AUDITOR;
      case UserTypeEnum.GUEST:
        return UserTypeEnum.GUEST;
      case UserTypeEnum.MANAGER:
        return UserTypeEnum.MANAGER;
      case UserTypeEnum.TEAM_LEAD:
        return UserTypeEnum.TEAM_LEAD;
      case UserTypeEnum.USER:
        return UserTypeEnum.USER;
    }
  };

  return <UserTypeBadge userType={getBadgeType()} />;
};

export default UserHierarchyView;
