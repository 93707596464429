import React, { useState } from "react";
import {
  PlateformOptionalListType,
  PlatformDropdown,
  ReplyContainer,
  SquareInput,
} from "../view";
import { Stack, Typography } from "@mui/material";
import { DatePickerWithIcon } from "../../DatePickerWithIcon";
import { DatePickerMini } from "../../DatePickerMini";

interface ScheduleVideoMeetingBarProps {
    onCloseClick: () => void;
    selectedUser: string | null ;
}

const ScheduleVideoMeetingBar: React.FC<
  ScheduleVideoMeetingBarProps
> = ({onCloseClick,selectedUser}) => {
  const [selected, setSelected] = useState<PlateformOptionalListType>();

  return (
    <ReplyContainer
      replyType="videoMeeting"
      dateAndTimeComponents={
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            font: "normal normal 600 11px/14px Source Serif Pro",
          }}
        >
          <Typography sx={{ font: "inherit" }}>{selectedUser}</Typography>
          <Typography
            sx={{
              font: "normal normal 300 11px/14px Source Serif Pro",
              whiteSpace: "nowrap",
            }}
          >
            on
          </Typography>
          <DatePickerWithIcon
            hideIcon
            dateFormat="MM-DD-YYYY"
            sx={{ font: "inherit", width: "55px" }}
          />
          <DatePickerMini
            sx={{ font: "inherit", border: "0px", width: "60px" }}
            hideIcon
          />
          <span>to</span>
          <DatePickerMini
            sx={{ font: "inherit", border: "0px", width: "60px" }}
            hideIcon
          />
        </Stack>
      }
      infoComponents={
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <SquareInput
            squareTitle="Meeting Name"
            initialValue=""
            onValueChange={(value) => console.log("Final Value:", value)}
            placeholder="Enter Meeting Name..."
          />
          <Typography
            sx={{
              font: "normal normal 300 11px/14px Source Serif Pro",
              whiteSpace: "nowrap",
            }}
          >
            in
          </Typography>
          <PlatformDropdown
            initialValue={selected}
            onSelect={(val) => setSelected(val)}
          />
        </Stack>
      }
      onCloseClick={() => onCloseClick()}
      userName="Abhishek"
      userStatus={false}
    />
  );
};

export default ScheduleVideoMeetingBar;
