import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { cmd__calendar_api, get__cmd__for__user__api } from "../../../../redux";
import { CommandControlDashboard } from "./CommandControlDashboard";
import { Typography, Skeleton } from "@mui/material"; // Added CircularProgress for loader
import Styles from "./cmd.module.css";
import {
    handle_CMD_EVENTS_PLANS___utils,
    handle_CMD_PLANS___utils,
    handle_CRM_WORKSPACE___utils,
} from "./cmdUtils";
import { getUser } from "../../../../Utils";

// Props Type Definition
interface CommandQuickViewProps {
    handleMouseEnter?: (cardType: string, data: any) => void;
    handleMouseLeave?: () => void;
}

const useCommandData = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true); // Add loading state

    const { cmd__Notifications } = useSelector(
        (state: RootState) => (state && state?.calendar) || {}
    );
    const { cmd__control__for__workspace } = useSelector(
        (state: RootState) => (state && state?.workspace) || {}
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    dispatch(cmd__calendar_api()),
                    dispatch(get__cmd__for__user__api()),
                ]);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    // Helper: Normalize Data for both Calendar and Workspace
    const normalizeData = (item: any, source: "calendar" | "workspace") => {
        if (source === "calendar") {
            return {
                id: item?._id,
                cardType: item?.variant?.startsWith("@")
                    ? item?.variant?.slice(1)
                    : item?.variant || "default",
                assignedTo: {
                    avatar: item?.userInfo?.avatar || "",
                    firstName: item?.userInfo?.firstName || "",
                    lastName: item?.userInfo?.lastName || "",
                    status: "ONLINE",
                },
                createdBy: {
                    avatar: item?.creatorInfo?.avatar || "",
                    firstName: item?.creatorInfo?.firstName || "",
                    lastName: item?.creatorInfo?.lastName || "",
                },
                status: item?.status || "PENDING",
                data: item,
                center_source: "Calendar",
            };
        } else if (source === "workspace") {
            return {
                id: item?._id,
                cardType: item?.userQuote?.toLowerCase() || "default", // For Workspace, cardType = userQuote
                assignedTo: {
                    avatar: item?.assignedTo?.avatar || "",
                    firstName: item?.assignedTo?.firstName || "",
                    lastName: item?.assignedTo?.lastName || "",
                    status: item?.assignedTo?.status || "ONLINE",
                },
                createdBy: {
                    avatar: item?.createdBy?.avatar || "",
                    firstName: item?.createdBy?.firstName || "",
                    lastName: item?.createdBy?.lastName || "",
                },
                status: item?.status || "PENDING",
                data: item,
                center_source: "Workspace",
            };
        }
        return {};
    };

    // Process and combine the data
    const combinedData = useMemo(() => {
        const calendarData = cmd__Notifications || [];
        const workspaceData = cmd__control__for__workspace || [];

        const normalizedCalendar = calendarData?.map((item: any) =>
            normalizeData(item, "calendar")
        );

        const normalizedWorkspace = workspaceData?.map((item: any) =>
            normalizeData(item, "workspace")
        );

        return [...normalizedCalendar, ...normalizedWorkspace];
    }, [cmd__Notifications, cmd__control__for__workspace]);

    return { combinedData, loading };
};

// Main Component
const CommandQuickView: React.FC<CommandQuickViewProps> = ({
    handleMouseEnter,
    handleMouseLeave,
}) => {
    const { combinedData, loading } = useCommandData();
    // Optimized Callback for Mouse Events
    const onMouseEnter = useCallback(
        (cardType: string, data: any) => {
            handleMouseEnter?.(cardType, data);
        },
        [handleMouseEnter]
    );

    const dispatch = useDispatch();

    const user = getUser();

    return (
        <div className={Styles["command-quick-view-container"]}>
            {loading ? (
                <div className={Styles["loading-state"]}>
                    {Array.from({ length: 6 }).map((_, index) => (
                        <Skeleton
                            key={index}
                            variant="rounded"
                            width="485px"
                            height={40}
                            sx={{
                                fontSize: "1rem",
                                bgcolor: "lightgray",
                                opacity: 1,
                                marginBottom: "10px",
                            }}
                        />
                    ))}
                </div>
            ) : combinedData.length === 0 ? (
                <div className={Styles["empty-state"]}>
                    <Typography
                        variant="h5"
                        color="textPrimary"
                        align="center"
                        sx={{
                            fontWeight: 600,
                            fontSize: "1.75rem",
                            color: "#333",
                            marginBottom: 2,
                            fontFamily: "Source Serif Pro",
                        }}
                    >
                        Nothing to Display at the Moment
                    </Typography>

                    <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                        sx={{
                            fontSize: "1rem",
                            color: "#555",
                            lineHeight: 1.6,
                            maxWidth: "600px",
                            margin: "0 auto",
                            fontFamily: "Source Serif Pro",
                        }}
                    >
                        There are no active events or tasks available right now. Please
                        check again later for updates.
                    </Typography>
                </div>
            ) : (
                combinedData &&
                combinedData.map(({ id, cardType, data, ...rest }) => (
                    <div
                        key={id}
                        onMouseEnter={() =>
                            onMouseEnter(cardType, { id, cardType, data, ...rest })
                        }
                        onMouseLeave={handleMouseLeave}
                        className="command-card"
                    >
                        <CommandControlDashboard
                            cmd_data={{ id, cardType, data, ...rest }}
                            cardType={cardType}
                            w={"auto"}
                            /* calendar : */
                            onDoneButton_Click={() => /* consider MAY_BE */ {
                                handle_CMD_EVENTS_PLANS___utils(
                                    dispatch,
                                    "maybe",
                                    data,
                                    user?.data?._id
                                );

                                handle_CMD_PLANS___utils(dispatch, id, "DONE", "PUT");
                            }}
                            onRejectButton_Click={() => {
                                handle_CMD_EVENTS_PLANS___utils(
                                    dispatch,
                                    "rejected",
                                    data,
                                    user?.data?._id
                                );

                                handle_CMD_PLANS___utils(dispatch, id, "DONE", "PUT");
                            }}
                            onAcceptButton_Click={() => {
                                handle_CMD_EVENTS_PLANS___utils(
                                    dispatch,
                                    "accepted",
                                    data,
                                    user?.data?._id
                                );

                                handle_CMD_PLANS___utils(dispatch, id, "DONE", "PUT");
                            }}
                            /* Workspace */
                            onUpdateButton_Click={() => {
                                if (rest.center_source === "Workspace") {
                                    handle_CRM_WORKSPACE___utils(dispatch, id, "Done");
                                } else if (rest.center_source === "Calendar") {
                                    handle_CMD_PLANS___utils(dispatch, id, "DONE", "PUT");
                                }
                            }}
                            onSnoozed__Click={(value: any) => { }}
                        />
                    </div>
                ))
            )}
        </div>
    );
};

export default React.memo(CommandQuickView);
