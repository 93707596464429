import { InputBase, InputBaseProps, Stack, Typography } from "@mui/material";
import React from "react";

interface LargeheightInputBoxProps extends InputBaseProps {
  inputTitle: string;
}

const LargeheightInputBox: React.FC<LargeheightInputBoxProps> = ({
  inputTitle,
  ...props
}) => {
  return (
    <Stack sx={{ gap: "4px" }}>
      <Typography
        sx={{
          font: "normal normal 600 10px/13px Source Serif Pro",
        }}
      >
        {inputTitle}
      </Typography>

      <InputBase
        sx={{
          border: "0.5px solid #E9EDF2",
          borderRadius: "5px",
          height: "40px",
          font: "normal normal 400 15px/20px Source Serif Pro",
          boxSizing: "border-box",
          padding: "0px 8px",
        }}
        {...props}
      />
    </Stack>
  );
};

export default LargeheightInputBox;
