import React, { useState, useRef, DragEvent } from "react";
import { Box, Stack, Typography } from "@mui/material";
import S from "./ProjectImageSelector.module.css"; // Import the CSS module
import { BearPng } from "../../../images";
import { GreenArrowButton } from "../Buttons";
import { API_IMAGE_DATA } from "../../pages/Workspace/components/HugeCreateItemWorkspace/view/MainDetails/MainDetails";

interface ProjectImageSelectorProps {
  onAppearanceSelectionsChanged?: (selection: string | File) => void;
}

const ProjectImageSelector: React.FC<ProjectImageSelectorProps> = ({onAppearanceSelectionsChanged}) => {
  const [imgDisplay, setImgDisplay] = useState<"one" | "two">("one");
  const [selectedImageIndex, setSelectedImageIndex] = useState<string | null>(
    null
  );
  const [dragOver, setDragOver] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if(file) {
      if (onAppearanceSelectionsChanged) {
        onAppearanceSelectionsChanged(file)
      }
    }
    // Handle file change logic
  };

  const handleBoxClick = (index: string) => {
    setSelectedImageIndex(index);
    if(index){
      if(onAppearanceSelectionsChanged) {
        onAppearanceSelectionsChanged(index)
      }
    }
  };

  const handleArrowClick = () => {
    setImgDisplay((prev) => (prev === "one" ? "two" : "one"));
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      if(onAppearanceSelectionsChanged){
        onAppearanceSelectionsChanged(file)
      }
    }
  };

  return (
    <Stack className={S["main-details__image-container"]}>
      <span style={{ position: "relative" }}>
        <GreenArrowButton
          sx={{
            position: "absolute",
            right: "-20px",
            top: "34px",
            transform: imgDisplay === "one" ? "rotate(0deg)" : "rotate(180deg)",
          }}
          onClick={handleArrowClick}
        />
      </span>
      {imgDisplay === "one" ? (
        <Stack className={S["main-details__image-list"]}>
          {API_IMAGE_DATA.map((item, index) => (
            <Box
              component="div"
              key={index}
              className={`${S["main-details__box"]} ${
                selectedImageIndex === item?.url2
                  ? S["main-details__box--selected"]
                  : ""
              }`}
              onClick={() => handleBoxClick(item.url2)}
            >
              <Box
                component="img"
                src={item.url}
                className={S["main-details__image"]}
              />
            </Box>
          ))}
        </Stack>
      ) : (
        <div
          onClick={handleButtonClick}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          style={{
            height: "99%",
            width: "100%",
            cursor: "pointer",
            border: dragOver ? "0.5px dashed #aaa" : "0.5px solid transparent",
          }}
        >
          <Stack className={S["main-details__drag-drop-container"]}>
            <Box
              component={"img"}
              src={BearPng}
              height={"75px"}
              width={"65px"}
            />
            <Stack sx={{ width: "190px" }}>
              <Typography className={S["main-details__drag-drop-text"]}>
                Drag and Drop an Image here,
              </Typography>
              <Typography className={S["main-details__drag-drop-text"]}>
                Choose one from the list or BEBA
              </Typography>
              <Typography className={S["main-details__drag-drop-text"]}>
                AI will create one for you
              </Typography>
            </Stack>
          </Stack>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
      )}
    </Stack>
  );
};

export default ProjectImageSelector;
