import React, { useState } from "react";
import { InputBase } from "@mui/material";

interface EditableNumberInputProps {
  value?: string; // Accepting value as a string
  onChange?: (value: string) => void; // Callback to pass the updated value
  placeholder?: string;
  style?: React.CSSProperties;
  pxPerWord?: number;
  readOnlyComponent?: boolean;
}

const EditableNumberInput: React.FC<EditableNumberInputProps> = ({
  value = "00",
  onChange = () => null,
  placeholder = "00",
  pxPerWord = 6.5,
  style,
  readOnlyComponent = false,
}) => {
  const [inputValue, setInputValue] = useState<string>(value);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    if (/^\d*$/.test(input)) {
      setInputValue(input);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onChange(inputValue); // Return the numeric string when Enter is pressed
    }
  };

  const calculateWidth = () => {
    const length = inputValue.length || 1; // Minimum length of 1
    return Math.max(length * pxPerWord, 25); // Minimum width of 25px
  };

  return (
    <InputBase
      readOnly={readOnlyComponent}
      value={inputValue}
      placeholder={placeholder}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      type="text"
      style={{
        font: "normal normal 300 13px/16px Source Serif Pro",
        minWidth: "25px",
        width: `${calculateWidth()}px`,
        transition: "width 0.2s ease-in-out",
        ...style,
      }}
      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
    />
  );
};

export default EditableNumberInput;
