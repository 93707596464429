import React from "react";

export const CalendarViewDropDown = React.lazy(() =>
    import("./CalendarViewDropDown").then((module) => ({
        default: module.CalendarViewDropDown,
    }))
);

export const CalendarRequestHover = React.lazy(() =>
    import("./CalendatRequestHover").then((module) => ({
        default: module.CalendatRequestHover,
    }))
);

export const QuickCreateEvent = React.lazy(() =>
    import("./QuickCreateEvent").then((module) => ({
        default: module.QuickCreateEvent,
    }))
);

export const SearchAndInviteMenu = React.lazy(() =>
    import("./SearchAndInviteMenu").then((module) => ({
        default: module.SearchAndInviteMenu,
    }))
);

export const CreateBookingLinks = React.lazy(() =>
    import("./CreateBookingLinks").then((module) => ({
        default: module.CreateBookingLinks,
    }))
);
export const TopSideRightCalBar = React.lazy(() =>
    import("./TopSideRightCalBar").then((module) => ({
        default: module.TopSideRightCalBar,
    }))
);
