import React from "react";
import { Box, Link as MuiLink, Stack, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import styles from "./IconWordLongButton.module.css"; // Import the CSS module
import { DrawerButton } from "../../DrawerButton";
// import { createAdminPortalSession__api } from "../../../../redux/Slice/auth/authActions";
// import { getUser } from "../../../../Utils";
// import { useDispatch } from "react-redux";

interface IconWordLongButtonProps {
  iconSrc: string;
  text: string;
  toMuiLink?: string;
  isWorkOS?: boolean;
}

// This component is used in the drawer-navbar
const IconWordLongButton: React.FC<IconWordLongButtonProps> = ({
  iconSrc,
  text,
  toMuiLink = "#",
  isWorkOS = false,
}) => {
  // const dispatch = useDispatch();
  // const user = getUser();
  // const handleClick = async (
  //   event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  // ) => {
  //   if (false) {
  //     event.preventDefault();

  //     try {
  //       const action = createAdminPortalSession__api(user?.data?._id);

  //       const actionDispatch: any = await dispatch(action);

  //       if (actionDispatch?.success && actionDispatch?.data) {
  //         if (actionDispatch?.data?.link) {
  //           window.location.href = actionDispatch?.data?.link;
  //         }
  //       }
  //     } catch (e) {
  //     /*   console.log(e); */
  //     }
  //   }
  // };

  const location = useLocation(); // Get the current location (path)

  // Normalize paths by removing trailing slashes for both current path and provided path
  const normalizedLocation = location.pathname.replace(/\/$/, ""); // Remove trailing slash from current path
  const normalizedPath = toMuiLink.replace(/\/$/, ""); // Remove trailing slash from the provided path

  // Check if the current path is `/CRM` or starts with `/Settings/` (this includes subpaths like `/Settings/general`)
  const isSelected =
    normalizedLocation === normalizedPath || normalizedLocation.startsWith(`${normalizedPath}/`) || (normalizedLocation === "/Settings" && toMuiLink === "/Settings/general");


  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="start"
      className={styles.iconWordLongButton}
    >
      <MuiLink
        component={Link}
        to={toMuiLink}
        // onClick={handleClick}
        className={styles.link}
      >
        <DrawerButton
          labelFont={"normal normal 300 13px/17px Source Serif Pro"}
          buttonLabel={text}
          isSelected={isSelected}
          icon={iconSrc as string}
        />
      </MuiLink>
    </Stack>
  );
};

export default IconWordLongButton;
