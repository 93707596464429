import Cookies from "js-cookie";
import { setToken } from "../components/common/LocalStorage";

export const ENABLE_WORKOS_MODE = false;

// Determine the correct domain for cookies based on the environment
export const cookieDomain =
  process.env.REACT_APP_NODE === "production"
    ? ".bearishos.com"
    : ".localtest.me";

// Function to set the token and userId in a cookie
export const setAuthCookie = (token: string, userId: string) => {
  // Log the domain being used for cookie storage
  console.log("Setting authentication cookie at domain:", cookieDomain);

  // Set the cookie with token and userId
  Cookies.set("auth_token", token, {
    domain: cookieDomain, // Cookie is set for the main domain or subdomains
    path: "/", // Set path to root
    secure: process.env.REACT_APP_NODE === "production", // Secure cookies for production
    expires: 7, // Cookie expiration in days
  });

  Cookies.set("user_id", userId, {
    domain: cookieDomain, // Cookie is set for the main domain or subdomains
    path: "/", // Set path to root
    secure: process.env.REACT_APP_NODE === "production", // Secure cookies for production
    expires: 7, // Cookie expiration in days
  });

  // Log to verify that cookies are set properly
  console.log("Auth cookie and user ID cookie set:", {
    token,
    userId,
    domain: cookieDomain,
  });
};

// Function to transfer cookies to localStorage when the user lands on apple.localtest.me
export const transferCookiesToLocalStorage = () => {
  const authToken = Cookies.get("auth_token");
  const userId = Cookies.get("user_id");

  // Check if both auth_token and user_id exist in cookies
  if (authToken && userId) {
    // Check if the current cookies' values are different from those in localStorage
    const storedAuthToken = localStorage.getItem("token");
    const storedUserId = localStorage.getItem("user_id");

    // If cookies are different or not present in localStorage, transfer them
    if (storedAuthToken !== authToken) {
      setToken(authToken); // Save the token to localStorage
      console.log("Auth token transferred to localStorage.");
    }

    if (storedUserId !== userId) {
      localStorage.setItem("user_id", userId); // Save the user ID to localStorage
      console.log("User ID transferred to localStorage.");
    }
  } 
  // else {
  //   console.warn("No cookies found for auth_token or user_id.");
  // }
};
