import { PayloadAction } from "@reduxjs/toolkit";
import { CloudStorageState } from "../CloudStorageType";
// Upload File to Cloud Success
export const uploadCloudFile__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Get All Files
const mergeCloudStorage = (
    state: CloudStorageState,
    newFiles: any[],
    platform: string
) => {
    // Ensure newFiles is an array before calling map
    const filesToProcess = Array.isArray(newFiles) ? newFiles : [];

    // Normalize and enrich files with platform-specific properties
    const updatedFiles = filesToProcess.map((file: any) => ({
        ...file,
        platform, // Set platform (e.g., "BearishOS" or "Box")
        isSelected: false, // Default selection state
    }));

    // Maintain a map for deduplication based on _id
    const fileMap = new Map(
        (state?.bearishOSCloudStorage || [])?.map((file: any) => [file?._id, file])
    );

    // Merge new files into the map
    updatedFiles?.forEach((file: any) => {
        fileMap?.set(file._id, { ...file, platform }); // Overwrite if already exists
    });

    // Update the state with the merged files
    state.bearishOSCloudStorage = Array?.from(fileMap?.values());
};

export const getAllFiles__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    const newFiles = Array.isArray(action?.payload?.data)
        ? action.payload.data
        : [];
    mergeCloudStorage(state, newFiles, "BearishOS");
};

export const getAllFiles__Box__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    const newFiles = Array.isArray(action?.payload?.data)
        ? action.payload.data
        : [];
    mergeCloudStorage(state, newFiles, "Box");
};

// Get One File
export const getOneFile__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
    state.memberOfCloudStorageFile = action?.payload?.data;
};

// Download File
export const downloadFile__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
    state.downloadFile = action?.payload?.data;
};

// Share File With Users
export const shareFileWithUsers__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Share File With Email
export const shareFileWithEmail__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Reducer for Deleting Files
export const deleteFile__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    const deletedFileId = action.payload?.data?._id; // Extract the deleted file ID

    if (!deletedFileId) {
        console.warn("No file ID provided for deletion.");
        return;
    }

    // Filter out the deleted file from the state
    state.bearishOSCloudStorage = (state?.bearishOSCloudStorage || [])?.filter(
        (file: any) => file?._id !== deletedFileId
    );
};
// Update File
export const updateFile__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Upload New Version
export const uploadNewVersion__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Create Folder
export const createFolder__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Rename Folder
export const renameFolder__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

const mergeCloudStorageFolders = (
    state: CloudStorageState,
    newFolders: any[],
    platform: string
) => {
    // Ensure newFolders is an array before calling map
    const foldersToProcess = Array.isArray(newFolders) ? newFolders : [];

    // Normalize and enrich folders with platform-specific properties
    const updatedFolders = foldersToProcess.map((folder: any) => ({
        ...folder,
        platform, // Add platform (e.g., "BearishOS" or "Box")
        isSelected: false, // Default selection state
    }));

    // Maintain a map for deduplication based on _id
    const folderMap = new Map(
        (state.bearishOSCloudStorageFolders || []).map((folder: any) => [
            folder._id,
            folder,
        ])
    );

    // Merge new folders into the map
    updatedFolders.forEach((folder: any) => {
        folderMap.set(folder._id, { ...folder, platform }); // Overwrite if already exists
    });

    // Update the state with the merged folders
    state.bearishOSCloudStorageFolders = Array.from(folderMap.values());
};

export const getAllFolders__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    const newFolders = Array.isArray(action?.payload?.data)
        ? action.payload.data
        : [];
    mergeCloudStorageFolders(state, newFolders, "BearishOS");
};

export const getAllFolders__Box__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    const newFolders = Array.isArray(action?.payload?.data)
        ? action.payload.data
        : [];
    mergeCloudStorageFolders(state, newFolders, "Box");
};

export const getAllMemberCS__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    // Assuming action.payload.data is an array of member objects.
    const members = action?.payload?.data ?? [];

    // Process the members if needed (e.g., filtering or mapping).
    const processedMembers = members?.map((member: any) => ({
        ...member,
        // Add any transformations here if 2needed
    }));

    // Store the processed members into the state
    state.data = processedMembers;
    state.memberOfCloudStorageFile = processedMembers;
};

// Create Link
export const createLink__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Get Link
export const getLink__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
    state.linkData = action?.payload?.data;
};

// Delete Link
export const deleteLink__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Get All Link
export const getAllLink__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Add View
export const addView__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Add Time
export const addTime__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Toggle Download
export const toggleDownload__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Toggle Ask Details
export const toggleAskDetails__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Update Expiration
export const updateExpiration__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Update Link Password
export const updateLinkPassword__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Toggle Watermark
export const toggleWatermark__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

/* -------------- BOX ------------------ */

// File Management Routes
export const uploadFile__BOX__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};
export const downloadFile__BOX__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};
export const lockFile__BOX__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};
export const unlockFile__BOX__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};
export const createSharedLink__BOX__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};
export const disableSharedLink__BOX__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};
export const addComment__BOX__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};
export const retrieveComments__BOX__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};

// Folder Management
export const createFolder__BOX__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};
export const deleteFolder__BOX__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};
export const searchFilesAndFolders__BOX__Success = (
    state: CloudStorageState,
    action: PayloadAction<any>
) => {
    state.data = action?.payload?.data;
};
