import {Drawer, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Box, styled} from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import styles from "../../DrawerNavbar.module.css";
import {ChannelsContainer, DirectMessageContainer, PinedContainer,} from "../../../../pages/Message/components";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAllExistingUsers} from "../../../../../redux/Slice/auth/authActions";
import {MessagesState} from "../../../../../redux/Slice/message/messageTypes";
import {RootState} from "../../../../../redux/store";
import {
  getSlackUsers,
  getTeamsUser,
  getThirdPartyUserProfiles,
  getUserConnections,
} from "../../../../../redux/Slice/message/messageActions";
import pusher from "../../../../../Utils/pusher";
import {
    addMessageAction,
    addNewConnectionAction,
    sortChannelsBasedOnLastMessageAction, updateMessageUnreadCountAction,
} from "../../../../../redux/Slice/message/messageSlice";
import {
  requestNotificationPermission,
  triggerNotification,
  triggerNotificationForCreatedNewConnection,
  triggerNotificationForEditMessage,
  triggerNotificationForReaction,
} from "../../../../../Utils/notifications";
import {AuthState} from "../../../../../redux";
import {CommandControlSearchContainer} from "../../../../pages/Dashboard/components/CommandControlSearchContainer";
import FullScreenLoader from "../../../Loaders/FullScreenLoader";
import {getAllCloudStorageFiles__api, getAllUserContacts} from "../../../../../redux/Slice/Global/globalActions";
import {CommonButtonLabels, SidebarDrawerButtons} from "../components";

type MessageDrawerProps = {
    drawerOpen: boolean;
    drawerCloser: () => void;
};

const DrawerContainer = styled("div")({
    position: "fixed",
    top: 0,
    height: "100vh",
    zIndex: 1100, // Ensure the drawer is behind the navbar
});

const MessageDrawer: React.FC<MessageDrawerProps> = ({
                                                         drawerOpen,
                                                         drawerCloser,
                                                     }) => {

    const [showSearchContainer, setShowSearchContainer] = useState(false);
    const [selectedButton, setSelectedButton] =
        useState<CommonButtonLabels | null>(null);
    const {id} = useParams();

    const {user} = useSelector((state: RootState) => state.auth as AuthState);
    const dispatch = useDispatch();

    const {directChannel, chatTabState, loading} = useSelector(
        (state: RootState) => state.message as MessagesState
    );

    const handleButtonClick = (label: CommonButtonLabels) => {
        setSelectedButton(label);
    };


    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getUserConnections());
            await dispatch(getAllCloudStorageFiles__api());
            await dispatch(getAllUserContacts());
            await dispatch(getAllExistingUsers());
            const userProfiles: any = await dispatch(getThirdPartyUserProfiles());
            if (userProfiles?.data?.slackProfile !== null) {
                await dispatch(getSlackUsers());
            } else if (userProfiles?.data?.teamsProfile !== null) {
                dispatch(getTeamsUser());
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        requestNotificationPermission();
        const currentChannelIdInst: any = id;

        if (user?.data?._id) {
            const channel = pusher.subscribe(user?.data?._id ? user?.data?._id : "");

            const callback = (eventName: any, data: any) => {
                if (eventName === "new-connection") {
                    dispatch(addNewConnectionAction(data));
                    triggerNotificationForCreatedNewConnection(data, dispatch);
                }
                if (eventName === "new-message") {
                    if (data?.message?.channelId === currentChannelIdInst) {
                        dispatch(addMessageAction(data));
                        dispatch(sortChannelsBasedOnLastMessageAction(data));
                        if (data.addEmoji) {
                            triggerNotificationForReaction(dispatch, data, user?.data);
                        } else if (data.isEdited) {
                            triggerNotificationForEditMessage(dispatch, data, user?.data);
                        } else if (data.message.userId !== user?.data?._id) {
                            triggerNotification(dispatch, data, user?.data, directChannel);
                        } else {
                            // console.log(data.message);
                        }
                    } else {
                        dispatch(sortChannelsBasedOnLastMessageAction(data));
                        dispatch(updateMessageUnreadCountAction(data));
                        if (data.addEmoji) {
                            triggerNotificationForReaction(dispatch, data, user?.data);
                        } else if (data.isEdited) {
                            triggerNotificationForEditMessage(dispatch, data, user?.data);
                        } else {
                            triggerNotification(dispatch, data, user?.data, directChannel);
                        }
                    }
                }
            };

            channel.bind_global(callback);

            return () => {
                if (channel) {
                    channel.unbind_global(callback);
                    pusher.unsubscribe(user?.data?._id);
                }
            };
        }
    }, [id]);


    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (
                (event.ctrlKey && event.key === " ") ||
                (event.metaKey && event.key === " ") ||
                (event.ctrlKey && event.key === "s") ||
                (event.metaKey && event.key === "s")
            ) {
                event.preventDefault();
                setShowSearchContainer((prev: any) => !prev);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    if (loading) {
        return <FullScreenLoader/>;
    }

    return (
        <>
            <DrawerContainer>
                <Drawer
                    sx={{
                        position: "absolute",
                        top: 0,
                        height: "100vh",
                        width: "215px",
                        transition: drawerOpen
                            ? "left 225ms ease-out"
                            : "left 195ms ease-in",
                        left: drawerOpen ? "30px" : "-215px",
                        zIndex: 1100,
                        "& .MuiPaper-root": {
                            position: "absolute",
                            top: 0,
                            height: "100vh",
                            width: "215px",
                            left: 0,
                            boxSizing: "border-box",
                            zIndex: 1100,
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={drawerOpen}
                >
                    <Stack
                        bgcolor={"white"}
                        direction="column"
                        flex={1}
                        className={`${styles.drawerNavbar__drawer}`}
                    >
                        <CompanyIconAndName arrowButtonClickHandler={drawerCloser}/>
                        <Stack className={styles.drawerNavbar__content}>
                            <Stack mt={3} >
                                <SidebarDrawerButtons
                                    baseColor={"#775EE2"}
                                    buttonLabels={[
                                        CommonButtonLabels.BEBA_AI,
                                        CommonButtonLabels.COMMAND_CONTROL,
                                        CommonButtonLabels.DRAFTS_TEMPLATES,
                                        CommonButtonLabels.NEW_ANNOUNCEMENT,
                                    ]}
                                    selectedButton={selectedButton}
                                    onButtonClick={(l: any) => {
                                        handleButtonClick(l);
                                    }}
                                />
                            </Stack>
                            <PinedContainer/>
                            <DirectMessageContainer/>
                            <ChannelsContainer/>
                            {showSearchContainer && (
                                <Box
                                    sx={{
                                        position: "fixed",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        zIndex: 1000,
                                    }}
                                >
                                    <CommandControlSearchContainer/>
                                </Box>
                            )}
                        </Stack>
                    </Stack>
                </Drawer>
            </DrawerContainer>
        </>
    );
};

export default MessageDrawer;