import React, { memo, useEffect, useMemo } from "react";
import {
    Container,
    Box,
    Typography,
    Paper,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../redux/store";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from "react-beautiful-dnd";
import { handleDragEnd__boardCore } from "./utils.core"; // Add the function

interface CheckListCoreProps {
    l?: boolean;
}

const CheckListCore: React.FC<CheckListCoreProps> = ({ l }) => {
    const dispatch = useDispatch();
    const workspaceState = useSelector((state: RootState) => state?.workspace);

    // Memoize userAllTasks to prevent unnecessary re-renders
    const userAllTasks = useMemo(
        () => workspaceState?.userAllTasks || [],
        [workspaceState?.userAllTasks]
    );

    // useEffect(() => {
    //     if (userAllTasks.length) {
    //         console.log("userAllTasks updated", userAllTasks);
    //     }
    // }, [userAllTasks]);

    const onDragEnd = (result: DropResult) => {
        if (workspaceState?.userAllTasks) {
            handleDragEnd__boardCore(result, userAllTasks, dispatch);
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box
                sx={{
                    minWidth: `${l ? "calc(1050px + 15%)" : "100%"}`,
                    overflowX: "scroll", // Horizontal scrolling enabled
                    overflowY: "hidden", // No vertical scrolling
                    "&::-webkit-scrollbar": {
                        display: "none", // Hide the scrollbar
                    },
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                }}
            >
                {/* Assuming `userAllTasks` contains groups for checklists */}
                {userAllTasks && userAllTasks.map((group: any, groupIndex: any) => (
                    <Droppable key={group.groupName} droppableId={group.groupName}>
                        {(provided) => (
                            <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                sx={{
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    padding: "16px",
                                    margin: "8px",
                                    width: `${l ? "80%" : "95%"}`,
                                    flex: 1,
                                }}
                            >
                                <Typography variant="h6">{group?.groupName}</Typography>
                                {group?.tasks?.map((task: any, taskIndex: number) => (
                                    <Draggable
                                        key={task._id}
                                        draggableId={task._id} // Use task._id as draggableId
                                        index={taskIndex}
                                    >
                                        {(provided) => (
                                            <Paper
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "8px",
                                                    marginBottom: "8px",
                                                    background: "#f5f5f5",
                                                    borderRadius: "4px",
                                                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                                    cursor: "move",
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={task.isChecked || false} // Assuming task has isChecked
                                                            onChange={(e) => {
                                                                // Handle the change in checked state
                                                            }}
                                                        />
                                                    }
                                                    label={task?.name || "Untitled Task"}
                                                />
                                            </Paper>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                ))}
            </Box>
        </DragDropContext>
    );
};

export default memo(CheckListCore);
