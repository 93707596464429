import { ButtonProps, IconButton, SxProps, Typography } from "@mui/material";
import React from "react";
import { SmallThinButtonColor } from "../AllButtonProps";
import style from "./SmallThinButton.module.css";

interface SmallThinButtonProps extends ButtonProps {
  colorVarient: SmallThinButtonColor;
  label: string;
  sx?: SxProps;
}

const SmallThinButton: React.FC<SmallThinButtonProps> = ({
  label,
  colorVarient,
  sx,
  ...props
}) => {
  const buttonClass = `${style.SmallThinButton} ${style[`SmallThinButton--${colorVarient}`]}`;
  return (
    <IconButton
      sx={{
        font: "normal normal 600 10px/13px Source Serif Pro",
        ...sx,
      }}
      className={buttonClass}
      {...props}
      aria-label={label}
    >
      <Typography sx={{ font: "inherit" }}>{label}</Typography>
    </IconButton>
  );
};

export default SmallThinButton;
