import React, { useState } from "react";
import { Stack, Typography, Popover, Box, MenuItem } from "@mui/material";
import { StyledRadioButtonBlack } from "../../../RadioButtonBlack";
import {
  PlateformDropdownOptionalList,
  PlateformOptionalListType,
  renderVideoCallIcon,
} from "./PlatefomDropdownType";

interface PlatformDropdownProps {
  options?: PlateformOptionalListType[];
  initialValue?: PlateformOptionalListType;
  onSelect: (value: PlateformOptionalListType) => void;
}

const PlatformDropdown: React.FC<PlatformDropdownProps> = ({
  onSelect,
  options = PlateformDropdownOptionalList,
  initialValue,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<
    PlateformOptionalListType | undefined
  >(initialValue);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "platform-popover" : undefined;

  const handleSublistClick = (value: PlateformOptionalListType) => {
    setSelected(value);
    onSelect(value);
    handleClose();
  };

  return (
    <Stack>
      {/* Clickable Stack */}
      <Stack onClick={handleClick} sx={{ cursor: "pointer" }}>
        <Stack sx={{ font: "normal normal 900 11px/14px Source Serif Pro" }}>
          {selected ? (
            <Stack
              sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}
            >
              <Box
                component={"img"}
                src={renderVideoCallIcon(selected.value)}
                alt="logo"
                sx={{ height: "15px", width: "15px" }}
              />
              <span>{selected.title}</span>
            </Stack>
          ) : (
            "[plateform]"
          )}
        </Stack>
      </Stack>

      {/* Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            mt: "-5px",
          },
        }}
      >
        <Stack
          sx={{
            width: "170px",
            flexShrink: 0,
            boxSizing: "border-box",
            border: "1px solid #E9EDF2",
            borderRadius: "5px",
          }}
        >
          <Stack
            sx={{
              margin: "15px 15px 0px 15px",
              borderBottom: "2px solid #E9EDF2",
              boxSizing: "border-box",
            }}
          >
            <Typography
              sx={{
                font: "normal normal 400 13px/16px Source Serif Pro",
                pb: "5px",
              }}
            >
              Choose your platform
            </Typography>
          </Stack>

          <Stack>
            {options.map((item) => (
              <MenuItem
                key={item._id}
                onClick={() => handleSublistClick(item)}
                selected={selected?._id === item._id}
                sx={{
                  minHeight: 0,
                  padding: "0px 5px 0px 15px",
                  gap: "5px",
                  flexShrink: 0,
                  height: "30px",
                  alignItems: "center",
                }}
              >
                <StyledRadioButtonBlack checked={selected?._id === item._id} />
                <Box
                  component={"img"}
                  src={renderVideoCallIcon(item.value)}
                  alt="logo"
                  sx={{ height: "20px", width: "20px", mr: "5px" }}
                />
                <Typography
                  sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
                >
                  {item.title}
                </Typography>
              </MenuItem>
            ))}
          </Stack>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default PlatformDropdown;
