import React, { useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import styles from "./ProjectOverviewForm.module.css";
import { CloseIcon, ProjectIcon } from "../../../../../images";
import { ButtonSmall, ButtonType, SmallButtonColor } from "../../../../common";
import { Details, Objective } from "./view";
import { PageIndicator } from "./PageIndicator";

interface ProjectOverviewFormInterface {
  onCloseClick: () => void;
}

const ProjectOverviewForm: React.FC<ProjectOverviewFormInterface> = ({
  onCloseClick,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleBackPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, 2));
  };

  const renderCurrentPage = () => {
    switch (currentPage) {
      case 1:
        return <Details />;
      case 2:
        return <Objective />;
      default:
        return null;
    }
  };

  const closeHandler = (): void => {
    onCloseClick();
  };

  return (
    <Stack className={styles["project-overview-form"]}>
      {/* Header with SVG and text */}
      <Stack className={styles["project-overview-form__header"]}>
        <Stack sx={{ flexDirection: "row", gap: "15px", alignItems: "center" }}>
          <Box className={styles["project-overview-form__icon-container"]}>
            <ProjectIcon color="black" w={25} />
          </Box>
          <Typography className={styles["project-overview-form__title"]}>
            Project name
          </Typography>
        </Stack>

        <IconButton sx={{ p: 0 }} onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </Stack>

      {/* Page indicator */}
      <PageIndicator
        setCurrentPage={(id: number) => setCurrentPage(id)}
        activePage={currentPage}
      />

      {/* Dynamic page content */}
      <Stack sx={{ flexGrow: 1 }}>{renderCurrentPage()}</Stack>

      {/* Buttons */}
      <Stack className={styles["project-overview-form__button-container"]}>
        <ButtonSmall
          onClick={handleBackPage}
          label="Cancel"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.White}
        />
        <ButtonSmall
          onClick={handleNextPage}
          label={currentPage === 1 ? "Next" : "Save"}
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.MildGreen}
        />
      </Stack>
    </Stack>
  );
};

export default ProjectOverviewForm;
