import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { adjustHexColor } from "../../../../../Utils";
import { ErrorHoverInfo } from "../../../../common";

export const LeaveBalanceInfoBox: React.FC<{
  label: string;
  value: number;
  itemPerRow?: number;
  showError?: boolean;
  errorText?: string;
}> = ({
  label,
  value,
  itemPerRow,
  showError = false,
  errorText = "This employee has a zero balance in the time requested off.",
}) => {
  return (
    <Grid
      item
      sx={{
        flexBasis: `calc(100% / ${itemPerRow})`,
        boxSizing: "border-box",
      }}
    >
      <Stack
        sx={{
          flex: 1,
          flexShrink: 0,
          borderBottom: "1px solid #E9EDF2",
          borderRight: "1px solid #E9EDF2",
        }}
      >
        <Stack
          sx={{
            height: "30px",
            flexShrink: 0,
            borderBottom: "1px solid #E9EDF2",
            boxSizing: "border-box",
            padding: "5px",
          }}
        >
          <Stack
            sx={{
              bgcolor: showError ? adjustHexColor("#FF0000", 25) : "inherit",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
              flex: 1,
            }}
          >
            <Typography
              sx={{
                font: "normal normal 300 13px/16px Source Serif Pro",
              }}
            >
              {label}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          sx={{
            height: "40px",
            flexShrink: 0,
            boxSizing: "border-box",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          <Typography
            sx={{ font: "normal normal 900 13px/16px Source Serif Pro" }}
          >
            {value.toString().padStart(2, "0")}
          </Typography>

          {showError && <ErrorHoverInfo errorText={errorText} />}
        </Stack>
      </Stack>
    </Grid>
  );
};

// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------

interface BalanceDetailsAreaTableProps {
  children: React.ReactNode;
  itemPerRow?: number;
  width?: string;
}

export const LeaveBalanceInfoTable: React.FC<BalanceDetailsAreaTableProps> = ({
  children,
  itemPerRow = 3,
  width = "300px",
}) => {
  return (
    <Box sx={{ width: width }}>
      <Grid
        container
        sx={{
          flexWrap: "wrap",
          borderTop: "1px solid #E9EDF2",
          borderLeft: "1px solid #E9EDF2",
        }}
      >
        {React.Children.map(children, (child) =>
          React.isValidElement(child) && typeof child.type !== "string"
            ? React.cloneElement(
                child as React.ReactElement<{ itemPerRow: number }>,
                { itemPerRow }
              )
            : child
        )}
      </Grid>
    </Box>
  );
};
