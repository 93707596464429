import { Typography } from "@mui/material";
import React from "react";

interface EmojiProps {
  emoji: string | any;
  size?: number; // size in pixels
}

const Emoji: React.FC<EmojiProps> = ({ emoji, size = 18 }) => {
  return (
    <Typography
      component="span"
      sx={{
        display: "inline-block",
        fontSize: size,
        height: size,
        width: size,
        lineHeight: 1,
      }}
      aria-label="emoji"
      role="img"
    >
      {emoji}
    </Typography>
  );
};

export default Emoji;
