import React from "react";
import { Stack } from "@mui/material";
import { VisibleShiftInWeekDaysType } from "../../AttendanceScheduleTableType";
import { ShiftNameBox } from "../../common";
import { sortShifts } from "../../AttendanceTableUtils";
import { shiftTiming } from "../../AttendanceScheduleSetting";

interface ShowShiftContainerProps {
  visibleShift: VisibleShiftInWeekDaysType;
  useAsUserView?: boolean;
}

const ShowShiftContainer: React.FC<ShowShiftContainerProps> = ({
  visibleShift,
  useAsUserView = false,
}) => {
  return (
    <Stack
      sx={{
        width: "150px",
        flexDirection: "row",
        justifyContent: "center",
        gap: "4px",
        borderRight: "1px solid #E9EDF2",
        borderLeft: "1px solid #E9EDF2",
        boxSizing: "border-box",
      }}
    >
      {sortShifts(visibleShift.selectedShift).map((shift, index, array) => (
        <ShiftNameBox
          useAsUserView={useAsUserView}
          key={index}
          count={array.length}
          shiftInfo={shiftTiming.find((item) => item.shiftType === shift)!}
        />
      ))}
    </Stack>
  );
};

export default ShowShiftContainer;
