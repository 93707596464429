import { WeekDaysList } from "../../../../../Utils";
import { AttendanceListInterface } from "../TimeCardTable";

export interface ScheduleTableInterface {
    selectedEmployees?: AttendanceTableEmployeeListType[];
    tableData: ScheduleTableData;

}

export type ShiftType = 'morning' | 'midDay' | 'night'
export type DayStatusType = 'working' | 'dayOff' | 'holiday'
export type VisibleShiftInWeekDaysType = { _id: string, dayIndex: 0 | 1 | 2 | 3 | 4 | 5 | 6, dayName: WeekDaysList, selectedShift: ShiftType[] }
export type ShiftTimingType = { _id: string, shiftType: ShiftType, startTime: string, endTime: string }
export type ShiftColorListType = Record<ShiftType, string>;

export type UnfilledShiftType = {
    totalShiftCount: number;
    unfilledShiftCount: number;
    filledShiftData: {
        ShiftType: ShiftType;
        filledShiftData: {
            jobName: string;
            totalShift: number;
            filledShift: number;
        }[];
    }[];
};

export type ScheduleTableDateListType = {
    date: string;
    type: DayStatusType;
    holidayName?: string;
    isShiftUnfilled?: boolean; // use this when table is use for multiple user (schedule view not personal view)
    unfilledShiftData?: UnfilledShiftType  // use this when table is use for multiple user (schedule view not personal view)
}

export type AttendanceTableEmployeeListType = {
    _id: string;
    name: string;
    scheduledHours: string;
    workingDayPerWeek: string;
    jobName: string;
    balance: string;
    attendanceList: AttendanceListInterface[];
    lateInWeek?: number; // pass here when api use for week view
    lateInMonth?: number  // pass here when api use for week view
}

export type ScheduleTableData = {
    dateList: ScheduleTableDateListType[];
    employeeList: AttendanceTableEmployeeListType[]

}

export type DayViewHeaderAreaType = {
    _id: number;
    startTime: number;
    endTime: number;
    label: string;
};


export const dayViewHeaderArea: DayViewHeaderAreaType[] = [
    { _id: 0, startTime: 0, endTime: 1, label: '12:00 AM' },
    { _id: 1, startTime: 1, endTime: 2, label: '01:00 AM' },
    { _id: 2, startTime: 2, endTime: 3, label: '02:00 AM' },
    { _id: 3, startTime: 3, endTime: 4, label: '03:00 AM' },
    { _id: 4, startTime: 4, endTime: 5, label: '04:00 AM' },
    { _id: 5, startTime: 5, endTime: 6, label: '05:00 AM' },
    { _id: 6, startTime: 6, endTime: 7, label: '06:00 AM' },
    { _id: 7, startTime: 7, endTime: 8, label: '07:00 AM' },
    { _id: 8, startTime: 8, endTime: 9, label: '08:00 AM' },
    { _id: 9, startTime: 9, endTime: 10, label: '09:00 AM' },
    { _id: 10, startTime: 10, endTime: 11, label: '10:00 AM' },
    { _id: 11, startTime: 11, endTime: 12, label: '11:00 AM' },
    { _id: 12, startTime: 12, endTime: 13, label: '12:00 PM' },
    { _id: 13, startTime: 13, endTime: 14, label: '01:00 PM' },
    { _id: 14, startTime: 14, endTime: 15, label: '02:00 PM' },
    { _id: 15, startTime: 15, endTime: 16, label: '03:00 PM' },
    { _id: 16, startTime: 16, endTime: 17, label: '04:00 PM' },
    { _id: 17, startTime: 17, endTime: 18, label: '05:00 PM' },
    { _id: 18, startTime: 18, endTime: 19, label: '06:00 PM' },
    { _id: 19, startTime: 19, endTime: 20, label: '07:00 PM' },
    { _id: 20, startTime: 20, endTime: 21, label: '08:00 PM' },
    { _id: 21, startTime: 21, endTime: 22, label: '09:00 PM' },
    { _id: 22, startTime: 22, endTime: 23, label: '10:00 PM' },
    { _id: 23, startTime: 23, endTime: 24, label: '11:00 PM' },
    { _id: 24, startTime: 0, endTime: 1, label: '12:00 AM' },
];

export type DayViewAreaWidthType = {
    timeAreaWidth: number;
    distance: number;
    boxWidth: number;
};

export const dayViewAreaWidth: DayViewAreaWidthType = {
    timeAreaWidth: 13,
    distance: 27,
    get boxWidth() {
        return this.timeAreaWidth + this.distance;
    }
};
export const dummy_ScheduleTableList: ScheduleTableData = {
    dateList: [
        { date: "2024-09-01", isShiftUnfilled: false, type: "dayOff" }, // Sunday
        {
            date: "2024-09-02",
            isShiftUnfilled: true,
            unfilledShiftData: {
                totalShiftCount: 45,
                unfilledShiftCount: 25,
                filledShiftData: [
                    {
                        ShiftType: "morning",
                        filledShiftData: [
                            { jobName: "jobName1", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName2", filledShift: 1, totalShift: 3 },
                            { jobName: "jobName3", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName4", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "midDay",
                        filledShiftData: [
                            { jobName: "jobName22", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName33", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName322", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "night",
                        filledShiftData: [
                            { jobName: "job one", filledShift: 2, totalShift: 5 },
                            { jobName: "job two", filledShift: 1, totalShift: 3 },
                            { jobName: "job three", filledShift: 0, totalShift: 6 },
                            { jobName: "job four", filledShift: 1, totalShift: 2 },

                        ],
                    },
                ],
            },
            type: "working",
        },
        { date: "2024-09-03", isShiftUnfilled: false, type: "working" },
        { date: "2024-09-04", isShiftUnfilled: false, type: "holiday", holidayName: "Holiday 1" },
        {
            date: "2024-09-05", isShiftUnfilled: true, unfilledShiftData: {
                totalShiftCount: 23,
                unfilledShiftCount: 10,
                filledShiftData: [
                    {
                        ShiftType: "morning",
                        filledShiftData: [
                            { jobName: "jobName1", filledShift: 1, totalShift: 2 },
                            { jobName: "jobName2", filledShift: 1, totalShift: 3 },
                            { jobName: "jobName3", filledShift: 2, totalShift: 6 },
                            { jobName: "jobName4", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "night",
                        filledShiftData: [
                            { jobName: "job one", filledShift: 2, totalShift: 5 },
                            { jobName: "job two", filledShift: 1, totalShift: 3 },
                            { jobName: "job three", filledShift: 0, totalShift: 6 },
                            { jobName: "job four", filledShift: 1, totalShift: 2 },

                        ],
                    },
                ],
            }, type: "working"
        },
        { date: "2024-09-06", isShiftUnfilled: false, type: "working" },
        { date: "2024-09-07", isShiftUnfilled: false, type: "dayOff" }, // Saturday
        { date: "2024-09-08", isShiftUnfilled: false, type: "dayOff" }, // Sunday
        {
            date: "2024-09-09", isShiftUnfilled: true, unfilledShiftData: {
                totalShiftCount: 25,
                unfilledShiftCount: 19,
                filledShiftData: [

                    {
                        ShiftType: "night",
                        filledShiftData: [
                            { jobName: "job one", filledShift: 2, totalShift: 5 },
                            { jobName: "job two", filledShift: 1, totalShift: 3 },
                            { jobName: "job three", filledShift: 0, totalShift: 6 },
                            { jobName: "job four", filledShift: 1, totalShift: 2 },
                            { jobName: "job four", filledShift: 1, totalShift: 2 },
                            { jobName: "job four", filledShift: 1, totalShift: 2 },

                        ],
                    },
                ],
            }, type: "working"
        },
        { date: "2024-09-10", isShiftUnfilled: false, type: "working" },
        {
            date: "2024-09-11", isShiftUnfilled: true, unfilledShiftData: {
                totalShiftCount: 45,
                unfilledShiftCount: 25,
                filledShiftData: [
                    {
                        ShiftType: "morning",
                        filledShiftData: [
                            { jobName: "jobName1", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName2", filledShift: 1, totalShift: 3 },
                            { jobName: "jobName3", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName4", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "midDay",
                        filledShiftData: [
                            { jobName: "jobName22", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName33", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName322", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "night",
                        filledShiftData: [
                            { jobName: "job one", filledShift: 2, totalShift: 5 },
                            { jobName: "job two", filledShift: 1, totalShift: 3 },
                            { jobName: "job three", filledShift: 0, totalShift: 6 },
                            { jobName: "job four", filledShift: 1, totalShift: 2 },

                        ],
                    },
                ],
            }, type: "working"
        },
        { date: "2024-09-12", isShiftUnfilled: false, type: "working" },
        {
            date: "2024-09-13", isShiftUnfilled: true, unfilledShiftData: {
                totalShiftCount: 45,
                unfilledShiftCount: 25,
                filledShiftData: [
                    {
                        ShiftType: "midDay",
                        filledShiftData: [
                            { jobName: "jobName22", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName33", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName322", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "night",
                        filledShiftData: [
                            { jobName: "job one", filledShift: 2, totalShift: 5 },
                            { jobName: "job two", filledShift: 1, totalShift: 3 },
                            { jobName: "job three", filledShift: 0, totalShift: 6 },
                            { jobName: "job four", filledShift: 1, totalShift: 2 },

                        ],
                    },
                ],
            }, type: "working"
        },
        { date: "2024-09-14", isShiftUnfilled: false, type: "dayOff" }, // Saturday
        { date: "2024-09-15", isShiftUnfilled: false, type: "dayOff" }, // Sunday
        {
            date: "2024-09-16", isShiftUnfilled: true, unfilledShiftData: {
                totalShiftCount: 45,
                unfilledShiftCount: 25,
                filledShiftData: [
                    {
                        ShiftType: "morning",
                        filledShiftData: [
                            { jobName: "jobName1", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName2", filledShift: 1, totalShift: 3 },
                            { jobName: "jobName3", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName4", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "midDay",
                        filledShiftData: [
                            { jobName: "jobName22", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName33", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName322", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "night",
                        filledShiftData: [
                            { jobName: "job one", filledShift: 2, totalShift: 5 },
                            { jobName: "job two", filledShift: 1, totalShift: 3 },
                            { jobName: "job three", filledShift: 0, totalShift: 6 },
                            { jobName: "job four", filledShift: 1, totalShift: 2 },

                        ],
                    },
                ],
            }, type: "working"
        },
        {
            date: "2024-09-17", isShiftUnfilled: true, unfilledShiftData: {
                totalShiftCount: 45,
                unfilledShiftCount: 25,
                filledShiftData: [
                    {
                        ShiftType: "morning",
                        filledShiftData: [
                            { jobName: "jobName1", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName2", filledShift: 1, totalShift: 3 },
                            { jobName: "jobName3", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName4", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "midDay",
                        filledShiftData: [
                            { jobName: "jobName22", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName33", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName322", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "night",
                        filledShiftData: [
                            { jobName: "job one", filledShift: 2, totalShift: 5 },
                            { jobName: "job two", filledShift: 1, totalShift: 3 },
                            { jobName: "job three", filledShift: 0, totalShift: 6 },
                            { jobName: "job four", filledShift: 1, totalShift: 2 },

                        ],
                    },
                ],
            }, type: "working"
        },
        { date: "2024-09-18", isShiftUnfilled: false, type: "holiday", holidayName: "Holiday 2" },
        {
            date: "2024-09-19", isShiftUnfilled: true, unfilledShiftData: {
                totalShiftCount: 45,
                unfilledShiftCount: 25,
                filledShiftData: [
                    {
                        ShiftType: "morning",
                        filledShiftData: [
                            { jobName: "jobName1", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName2", filledShift: 1, totalShift: 3 },
                            { jobName: "jobName3", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName4", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "midDay",
                        filledShiftData: [
                            { jobName: "jobName22", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName33", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName322", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "night",
                        filledShiftData: [
                            { jobName: "job one", filledShift: 2, totalShift: 5 },
                            { jobName: "job two", filledShift: 1, totalShift: 3 },
                            { jobName: "job three", filledShift: 0, totalShift: 6 },
                            { jobName: "job four", filledShift: 1, totalShift: 2 },

                        ],
                    },
                ],
            }, type: "working"
        },
        {
            date: "2024-09-20", isShiftUnfilled: true, unfilledShiftData: {
                totalShiftCount: 45,
                unfilledShiftCount: 25,
                filledShiftData: [
                    {
                        ShiftType: "morning",
                        filledShiftData: [
                            { jobName: "jobName1", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName2", filledShift: 1, totalShift: 3 },
                            { jobName: "jobName3", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName4", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "midDay",
                        filledShiftData: [
                            { jobName: "jobName22", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName33", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName322", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "night",
                        filledShiftData: [
                            { jobName: "job one", filledShift: 2, totalShift: 5 },
                            { jobName: "job two", filledShift: 1, totalShift: 3 },
                            { jobName: "job three", filledShift: 0, totalShift: 6 },
                            { jobName: "job four", filledShift: 1, totalShift: 2 },

                        ],
                    },
                ],
            }, type: "working"
        },
        { date: "2024-09-21", isShiftUnfilled: false, type: "dayOff" }, // Saturday
        { date: "2024-09-22", isShiftUnfilled: false, type: "dayOff" }, // Sunday
        {
            date: "2024-09-23", isShiftUnfilled: true, unfilledShiftData: {
                totalShiftCount: 45,
                unfilledShiftCount: 25,
                filledShiftData: [
                    {
                        ShiftType: "morning",
                        filledShiftData: [
                            { jobName: "jobName1", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName2", filledShift: 1, totalShift: 3 },
                            { jobName: "jobName3", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName4", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "midDay",
                        filledShiftData: [
                            { jobName: "jobName22", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName33", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName322", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "night",
                        filledShiftData: [
                            { jobName: "job one", filledShift: 2, totalShift: 5 },
                            { jobName: "job two", filledShift: 1, totalShift: 3 },
                            { jobName: "job three", filledShift: 0, totalShift: 6 },
                            { jobName: "job four", filledShift: 1, totalShift: 2 },

                        ],
                    },
                ],
            }, type: "working"
        },
        {
            date: "2024-09-24", isShiftUnfilled: true, unfilledShiftData: {
                totalShiftCount: 45,
                unfilledShiftCount: 25,
                filledShiftData: [
                    {
                        ShiftType: "morning",
                        filledShiftData: [
                            { jobName: "jobName1", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName2", filledShift: 1, totalShift: 3 },
                            { jobName: "jobName3", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName4", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "midDay",
                        filledShiftData: [
                            { jobName: "jobName22", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName33", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName322", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "night",
                        filledShiftData: [
                            { jobName: "job one", filledShift: 2, totalShift: 5 },
                            { jobName: "job two", filledShift: 1, totalShift: 3 },
                            { jobName: "job three", filledShift: 0, totalShift: 6 },
                            { jobName: "job four", filledShift: 1, totalShift: 2 },

                        ],
                    },
                ],
            }, type: "working"
        },
        {
            date: "2024-09-25", isShiftUnfilled: true, unfilledShiftData: {
                totalShiftCount: 45,
                unfilledShiftCount: 25,
                filledShiftData: [
                    {
                        ShiftType: "morning",
                        filledShiftData: [
                            { jobName: "jobName1", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName2", filledShift: 1, totalShift: 3 },
                            { jobName: "jobName3", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName4", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "midDay",
                        filledShiftData: [
                            { jobName: "jobName22", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName33", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName322", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "night",
                        filledShiftData: [
                            { jobName: "job one", filledShift: 2, totalShift: 5 },
                            { jobName: "job two", filledShift: 1, totalShift: 3 },
                            { jobName: "job three", filledShift: 0, totalShift: 6 },
                            { jobName: "job four", filledShift: 1, totalShift: 2 },

                        ],
                    },
                ],
            }, type: "working"
        },
        { date: "2024-09-26", isShiftUnfilled: false, type: "holiday", holidayName: "Holiday 3" },
        {
            date: "2024-09-27", isShiftUnfilled: true, unfilledShiftData: {
                totalShiftCount: 45,
                unfilledShiftCount: 25,
                filledShiftData: [
                    {
                        ShiftType: "morning",
                        filledShiftData: [
                            { jobName: "jobName1", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName2", filledShift: 1, totalShift: 3 },
                            { jobName: "jobName3", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName4", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "midDay",
                        filledShiftData: [
                            { jobName: "jobName22", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName33", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName322", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "night",
                        filledShiftData: [
                            { jobName: "job one", filledShift: 2, totalShift: 5 },
                            { jobName: "job two", filledShift: 1, totalShift: 3 },
                            { jobName: "job three", filledShift: 0, totalShift: 6 },
                            { jobName: "job four", filledShift: 1, totalShift: 2 },

                        ],
                    },
                ],
            }, type: "working"
        },
        { date: "2024-09-28", isShiftUnfilled: false, type: "dayOff" }, // Saturday
        { date: "2024-09-29", isShiftUnfilled: false, type: "dayOff" }, // Sunday
        {
            date: "2024-09-30", isShiftUnfilled: true, unfilledShiftData: {
                totalShiftCount: 45,
                unfilledShiftCount: 25,
                filledShiftData: [
                    {
                        ShiftType: "morning",
                        filledShiftData: [
                            { jobName: "jobName1", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName2", filledShift: 1, totalShift: 3 },
                            { jobName: "jobName3", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName4", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "midDay",
                        filledShiftData: [
                            { jobName: "jobName22", filledShift: 2, totalShift: 5 },
                            { jobName: "jobName33", filledShift: 0, totalShift: 6 },
                            { jobName: "jobName322", filledShift: 1, totalShift: 2 },

                        ],
                    },
                    {
                        ShiftType: "night",
                        filledShiftData: [
                            { jobName: "job one", filledShift: 2, totalShift: 5 },
                            { jobName: "job two", filledShift: 1, totalShift: 3 },
                            { jobName: "job three", filledShift: 0, totalShift: 6 },
                            { jobName: "job four", filledShift: 1, totalShift: 2 },

                        ],
                    },
                ],
            }, type: "working"
        },
    ],
    employeeList: [
        {
            _id: "1a",
            name: "Employee One",
            balance: "4+",
            jobName: "Front Desk",
            workingDayPerWeek: "5",
            scheduledHours: "240hrs",
            lateInMonth: 5, lateInWeek: 2,
            attendanceList: [
                // { _id: '1a1', date: '2024-09-01', approvalStatus: "approved", shiftType: 'morning', day: 'sunday', attendanceType: 'dayoff' },
                // { _id: '1a2', date: '2024-09-02', approvalStatus: "approved", shiftType: 'morning', day: 'monday', attendanceType: 'onTime', workStartTime: "07:54:12", workStopTime: "18:12:28" },
                // { _id: '1a3', date: '2024-09-03', approvalStatus: "approved", shiftType: 'morning', day: 'tuesday', attendanceType: 'late', workStartTime: "08:14:12", workStopTime: "18:15:48" },
                // { _id: '1a4', date: '2024-09-04', approvalStatus: "approved", shiftType: 'morning', day: 'wednesday', attendanceType: 'absent' },
                // { _id: '1a5', date: '2024-09-05', approvalStatus: "approved", shiftType: 'morning', day: 'thursday', attendanceType: 'onTime', workStartTime: "07:54:19", workStopTime: "18:12:28" },
                // { _id: '1a6', date: '2024-09-06', approvalStatus: "approved", shiftType: 'morning', day: 'friday', attendanceType: 'pto' },
                // { _id: '1a7', date: '2024-09-07', approvalStatus: "approved", shiftType: 'morning', day: 'saturday', attendanceType: 'dayoff' },
                // { _id: '1a8', date: '2024-09-08', approvalStatus: "approved", shiftType: 'morning', day: 'sunday', attendanceType: 'dayoff' },
                // { _id: '1a9', date: '2024-09-09', approvalStatus: "approved", shiftType: 'morning', day: 'monday', attendanceType: 'sick' },
                { _id: '1a10', date: '2024-09-10', approvalStatus: "approved", shiftType: 'morning', day: 'tuesday', attendanceType: 'late', workStartTime: "08:10:45", workStopTime: "18:55:03", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '1a11', date: '2024-09-11', approvalStatus: "approved", shiftType: 'morning', day: 'wednesday', attendanceType: 'late', workStartTime: "08:16:45", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '1a12', date: '2024-09-12', approvalStatus: "approved", shiftType: 'morning', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '1a13', date: '2024-09-13', approvalStatus: "approved", shiftType: 'morning', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '1a14', date: '2024-09-14', approvalStatus: "approved", shiftType: 'morning', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '1a15', date: '2024-09-15', approvalStatus: "approved", shiftType: 'morning', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '1a16', date: '2024-09-16', approvalStatus: "approved", shiftType: 'morning', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '1a17', date: '2024-09-17', approvalStatus: "approved", shiftType: 'morning', day: 'tuesday', attendanceType: 'dayoff' },
                { _id: '1a18', date: '2024-09-18', approvalStatus: "approved", shiftType: 'morning', day: 'wednesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '1a19', date: '2024-09-19', approvalStatus: "approved", shiftType: 'morning', day: 'thursday', attendanceType: 'dayoff' },
                { _id: '1a20', date: '2024-09-20', approvalStatus: "approved", shiftType: 'morning', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '1a21', date: '2024-09-21', approvalStatus: "approved", shiftType: 'morning', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '1a22', date: '2024-09-22', approvalStatus: "approved", shiftType: 'morning', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '1a23', date: '2024-09-23', approvalStatus: "approved", shiftType: 'morning', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '1a24', date: '2024-09-24', approvalStatus: "approved", shiftType: 'morning', day: 'tuesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '1a25', date: '2024-09-25', approvalStatus: "approved", shiftType: 'morning', day: 'wednesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '1a26', date: '2024-09-26', approvalStatus: "approved", shiftType: 'morning', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '1a27', date: '2024-09-27', approvalStatus: "approved", shiftType: 'morning', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '1a28', date: '2024-09-28', approvalStatus: "approved", shiftType: 'morning', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '1a29', date: '2024-09-29', approvalStatus: "approved", shiftType: 'morning', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '1a30', date: '2024-09-30', approvalStatus: "approved", shiftType: 'morning', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            ],
        },
        {
            _id: "2b",
            name: "Employee Two",
            balance: "3+",
            jobName: "HR Specialist",
            workingDayPerWeek: "5",
            scheduledHours: "220hrs",
            lateInMonth: 9, lateInWeek: 2,
            attendanceList: [
                { _id: '2b1', date: '2024-09-01', approvalStatus: "approved", shiftType: 'morning', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '2b2', date: '2024-09-02', approvalStatus: "approved", shiftType: 'morning', day: 'monday', attendanceType: 'onTime', workStartTime: "07:54:12", workStopTime: "18:12:28", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '2b3', date: '2024-09-03', approvalStatus: "approved", shiftType: 'morning', day: 'tuesday', attendanceType: 'late', workStartTime: "08:14:12", workStopTime: "18:15:48", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '2b4', date: '2024-09-04', approvalStatus: "approved", shiftType: 'morning', day: 'wednesday', attendanceType: 'absent' },
                { _id: '2b5', date: '2024-09-05', approvalStatus: "approved", shiftType: 'morning', day: 'thursday', attendanceType: 'onTime', workStartTime: "07:54:19", workStopTime: "18:12:28", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '2b6', date: '2024-09-06', approvalStatus: "approved", shiftType: 'morning', day: 'friday', attendanceType: 'pto' },
                { _id: '2b7', date: '2024-09-07', approvalStatus: "approved", shiftType: 'morning', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '2b8', date: '2024-09-08', approvalStatus: "approved", shiftType: 'morning', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '2b9', date: '2024-09-09', approvalStatus: "approved", shiftType: 'morning', day: 'monday', attendanceType: 'sick' },
                { _id: '2b10', date: '2024-09-10', approvalStatus: "approved", shiftType: 'morning', day: 'tuesday', attendanceType: 'late', workStartTime: "08:10:45", workStopTime: "18:55:03", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '2b11', date: '2024-09-11', approvalStatus: "approved", shiftType: 'morning', day: 'wednesday', attendanceType: 'late', workStartTime: "08:16:45", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '2b12', date: '2024-09-12', approvalStatus: "approved", shiftType: 'morning', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '2b13', date: '2024-09-13', approvalStatus: "approved", shiftType: 'morning', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '2b14', date: '2024-09-14', approvalStatus: "approved", shiftType: 'morning', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '2b15', date: '2024-09-15', approvalStatus: "approved", shiftType: 'morning', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '2b16', date: '2024-09-16', approvalStatus: "approved", shiftType: 'morning', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '2b17', date: '2024-09-17', approvalStatus: "approved", shiftType: 'morning', day: 'tuesday', attendanceType: 'dayoff' },
                { _id: '2b18', date: '2024-09-18', approvalStatus: "approved", shiftType: 'morning', day: 'wednesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '2b19', date: '2024-09-19', approvalStatus: "approved", shiftType: 'morning', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '2b20', date: '2024-09-20', approvalStatus: "approved", shiftType: 'morning', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '2b21', date: '2024-09-21', approvalStatus: "approved", shiftType: 'morning', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '2b22', date: '2024-09-22', approvalStatus: "approved", shiftType: 'morning', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '2b23', date: '2024-09-23', approvalStatus: "approved", shiftType: 'morning', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '2b24', date: '2024-09-24', approvalStatus: "approved", shiftType: 'morning', day: 'tuesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '2b25', date: '2024-09-25', approvalStatus: "approved", shiftType: 'morning', day: 'wednesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '2b26', date: '2024-09-26', approvalStatus: "approved", shiftType: 'morning', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '2b27', date: '2024-09-27', approvalStatus: "approved", shiftType: 'morning', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '2b28', date: '2024-09-28', approvalStatus: "approved", shiftType: 'morning', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '2b29', date: '2024-09-29', approvalStatus: "approved", shiftType: 'morning', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '2b30', date: '2024-09-30', approvalStatus: "approved", shiftType: 'morning', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            ],
        },
        {
            _id: "3c",
            name: "Employee Three",
            balance: "5",
            jobName: "Software Engineer",
            workingDayPerWeek: "5",
            scheduledHours: "250hrs",
            lateInMonth: 7, lateInWeek: 2,
            attendanceList: [
                { _id: 'ec1', date: '2024-09-01', approvalStatus: "approved", shiftType: 'midDay', day: 'sunday', attendanceType: 'dayoff' },
                { _id: 'ec2', date: '2024-09-02', approvalStatus: "approved", shiftType: 'midDay', day: 'monday', attendanceType: 'onTime', workStartTime: "07:54:12", workStopTime: "18:12:28", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: 'ec3', date: '2024-09-03', approvalStatus: "approved", shiftType: 'midDay', day: 'tuesday', attendanceType: 'late', workStartTime: "08:14:12", workStopTime: "18:15:48", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: 'ec4', date: '2024-09-04', approvalStatus: "approved", shiftType: 'midDay', day: 'wednesday', attendanceType: 'absent' },
                { _id: 'ec5', date: '2024-09-05', approvalStatus: "approved", shiftType: 'midDay', day: 'thursday', attendanceType: 'onTime', workStartTime: "07:54:19", workStopTime: "18:12:28", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: 'ec6', date: '2024-09-06', approvalStatus: "approved", shiftType: 'midDay', day: 'friday', attendanceType: 'pto' },
                { _id: 'ec7', date: '2024-09-07', approvalStatus: "approved", shiftType: 'midDay', day: 'saturday', attendanceType: 'dayoff' },
                { _id: 'ec8', date: '2024-09-08', approvalStatus: "approved", shiftType: 'midDay', day: 'sunday', attendanceType: 'dayoff' },
                { _id: 'ec9', date: '2024-09-09', approvalStatus: "approved", shiftType: 'midDay', day: 'monday', attendanceType: 'sick' },
                { _id: 'ec10', date: '2024-09-10', approvalStatus: "approved", shiftType: 'midDay', day: 'tuesday', attendanceType: 'late', workStartTime: "08:10:45", workStopTime: "18:55:03", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: 'ec11', date: '2024-09-11', approvalStatus: "approved", shiftType: 'midDay', day: 'wednesday', attendanceType: 'late', workStartTime: "08:16:45", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: 'ec12', date: '2024-09-12', approvalStatus: "approved", shiftType: 'midDay', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: 'ec13', date: '2024-09-13', approvalStatus: "approved", shiftType: 'midDay', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: 'ec14', date: '2024-09-14', approvalStatus: "approved", shiftType: 'midDay', day: 'saturday', attendanceType: 'dayoff' },
                { _id: 'ec15', date: '2024-09-15', approvalStatus: "approved", shiftType: 'midDay', day: 'sunday', attendanceType: 'dayoff' },
                { _id: 'ec16', date: '2024-09-16', approvalStatus: "approved", shiftType: 'midDay', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: 'ec17', date: '2024-09-17', approvalStatus: "approved", shiftType: 'midDay', day: 'tuesday', attendanceType: 'dayoff' },
                { _id: 'ec18', date: '2024-09-18', approvalStatus: "approved", shiftType: 'midDay', day: 'wednesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: 'ec19', date: '2024-09-19', approvalStatus: "approved", shiftType: 'midDay', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: 'ec20', date: '2024-09-20', approvalStatus: "approved", shiftType: 'midDay', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: 'ec21', date: '2024-09-21', approvalStatus: "approved", shiftType: 'midDay', day: 'saturday', attendanceType: 'dayoff' },
                { _id: 'ec22', date: '2024-09-22', approvalStatus: "approved", shiftType: 'midDay', day: 'sunday', attendanceType: 'dayoff' },
                { _id: 'ec23', date: '2024-09-23', approvalStatus: "approved", shiftType: 'midDay', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: 'ec24', date: '2024-09-24', approvalStatus: "approved", shiftType: 'midDay', day: 'tuesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: 'ec25', date: '2024-09-25', approvalStatus: "approved", shiftType: 'midDay', day: 'wednesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: 'ec26', date: '2024-09-26', approvalStatus: "approved", shiftType: 'midDay', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: 'ec27', date: '2024-09-27', approvalStatus: "approved", shiftType: 'midDay', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: 'ec28', date: '2024-09-28', approvalStatus: "approved", shiftType: 'midDay', day: 'saturday', attendanceType: 'dayoff' },
                { _id: 'ec29', date: '2024-09-29', approvalStatus: "approved", shiftType: 'midDay', day: 'sunday', attendanceType: 'dayoff' },
                { _id: 'ec30', date: '2024-09-30', approvalStatus: "approved", shiftType: 'midDay', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            ],
        },
        {
            _id: "4d",
            name: "Employee Four",
            balance: "6+",
            jobName: "Marketing Manager",
            workingDayPerWeek: "5",
            scheduledHours: "230hrs",
            lateInMonth: 9, lateInWeek: 1,
            attendanceList: [
                { _id: '4d1', date: '2024-09-01', approvalStatus: "approved", shiftType: 'night', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '4d2', date: '2024-09-02', approvalStatus: "approved", shiftType: 'night', day: 'monday', attendanceType: 'onTime', workStartTime: "07:54:12", workStopTime: "18:12:28", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d3', date: '2024-09-03', approvalStatus: "approved", shiftType: 'night', day: 'tuesday', attendanceType: 'late', workStartTime: "08:14:12", workStopTime: "18:15:48", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d4', date: '2024-09-04', approvalStatus: "approved", shiftType: 'night', day: 'wednesday', attendanceType: 'absent' },
                { _id: '4d5', date: '2024-09-05', approvalStatus: "approved", shiftType: 'night', day: 'thursday', attendanceType: 'onTime', workStartTime: "07:54:19", workStopTime: "18:12:28", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d6', date: '2024-09-06', approvalStatus: "approved", shiftType: 'night', day: 'friday', attendanceType: 'pto' },
                { _id: '4d7', date: '2024-09-07', approvalStatus: "approved", shiftType: 'night', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '4d8', date: '2024-09-08', approvalStatus: "approved", shiftType: 'night', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '4d9', date: '2024-09-09', approvalStatus: "approved", shiftType: 'night', day: 'monday', attendanceType: 'sick' },
                { _id: '4d10', date: '2024-09-10', approvalStatus: "approved", shiftType: 'night', day: 'tuesday', attendanceType: 'late', workStartTime: "08:10:45", workStopTime: "18:55:03", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d11', date: '2024-09-11', approvalStatus: "approved", shiftType: 'night', day: 'wednesday', attendanceType: 'late', workStartTime: "08:16:45", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d12', date: '2024-09-12', approvalStatus: "approved", shiftType: 'night', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d13', date: '2024-09-13', approvalStatus: "approved", shiftType: 'night', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d14', date: '2024-09-14', approvalStatus: "approved", shiftType: 'night', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '4d15', date: '2024-09-15', approvalStatus: "approved", shiftType: 'night', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '4d16', date: '2024-09-16', approvalStatus: "approved", shiftType: 'night', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d17', date: '2024-09-17', approvalStatus: "approved", shiftType: 'night', day: 'tuesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d18', date: '2024-09-18', approvalStatus: "approved", shiftType: 'night', day: 'wednesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d19', date: '2024-09-19', approvalStatus: "approved", shiftType: 'night', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d20', date: '2024-09-20', approvalStatus: "approved", shiftType: 'night', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d21', date: '2024-09-21', approvalStatus: "approved", shiftType: 'night', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '4d22', date: '2024-09-22', approvalStatus: "approved", shiftType: 'night', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '4d23', date: '2024-09-23', approvalStatus: "approved", shiftType: 'night', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d24', date: '2024-09-24', approvalStatus: "approved", shiftType: 'night', day: 'tuesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d25', date: '2024-09-25', approvalStatus: "approved", shiftType: 'night', day: 'wednesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d26', date: '2024-09-26', approvalStatus: "approved", shiftType: 'night', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d27', date: '2024-09-27', approvalStatus: "approved", shiftType: 'night', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '4d28', date: '2024-09-28', approvalStatus: "approved", shiftType: 'night', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '4d29', date: '2024-09-29', approvalStatus: "approved", shiftType: 'night', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '4d30', date: '2024-09-30', approvalStatus: "approved", shiftType: 'night', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            ],
        },
        {
            _id: "5e",
            name: "Employee Five",
            balance: "2+",
            jobName: "Sales Executive",
            workingDayPerWeek: "6",
            scheduledHours: "240hrs",
            lateInMonth: 5, lateInWeek: 2,
            attendanceList: [
                { _id: '5e1', date: '2024-09-01', approvalStatus: "approved", shiftType: 'midDay', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '5e2', date: '2024-09-02', approvalStatus: "approved", shiftType: 'midDay', day: 'monday', attendanceType: 'onTime', workStartTime: "07:54:12", workStopTime: "18:12:28", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '5e3', date: '2024-09-03', approvalStatus: "approved", shiftType: 'midDay', day: 'tuesday', attendanceType: 'late', workStartTime: "08:14:12", workStopTime: "18:15:48", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '5e4', date: '2024-09-04', approvalStatus: "approved", shiftType: 'midDay', day: 'wednesday', attendanceType: 'absent' },
                { _id: '5e5', date: '2024-09-05', approvalStatus: "approved", shiftType: 'midDay', day: 'thursday', attendanceType: 'onTime', workStartTime: "07:54:19", workStopTime: "18:12:28", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '5e6', date: '2024-09-06', approvalStatus: "approved", shiftType: 'midDay', day: 'friday', attendanceType: 'pto' },
                { _id: '5e7', date: '2024-09-07', approvalStatus: "approved", shiftType: 'midDay', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '5e8', date: '2024-09-08', approvalStatus: "approved", shiftType: 'midDay', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '5e9', date: '2024-09-09', approvalStatus: "approved", shiftType: 'midDay', day: 'monday', attendanceType: 'sick' },
                { _id: '5e10', date: '2024-09-10', approvalStatus: "approved", shiftType: 'midDay', day: 'tuesday', attendanceType: 'late', workStartTime: "08:10:45", workStopTime: "18:55:03", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '5e11', date: '2024-09-11', approvalStatus: "approved", shiftType: 'midDay', day: 'wednesday', attendanceType: 'late', workStartTime: "08:16:45", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '5e12', date: '2024-09-12', approvalStatus: "approved", shiftType: 'midDay', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '5e13', date: '2024-09-13', approvalStatus: "approved", shiftType: 'midDay', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '5e14', date: '2024-09-14', approvalStatus: "approved", shiftType: 'midDay', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '5e15', date: '2024-09-15', approvalStatus: "approved", shiftType: 'midDay', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '5e16', date: '2024-09-16', approvalStatus: "approved", shiftType: 'midDay', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '5e17', date: '2024-09-17', approvalStatus: "approved", shiftType: 'midDay', day: 'tuesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '5e18', date: '2024-09-18', approvalStatus: "approved", shiftType: 'midDay', day: 'wednesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '5e19', date: '2024-09-19', approvalStatus: "approved", shiftType: 'midDay', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '5e20', date: '2024-09-20', approvalStatus: "approved", shiftType: 'midDay', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '5e21', date: '2024-09-21', approvalStatus: "approved", shiftType: 'midDay', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '5e22', date: '2024-09-22', approvalStatus: "approved", shiftType: 'midDay', day: 'sunday', attendanceType: 'dayoff' },
                // { _id: '5e23', date: '2024-09-23', approvalStatus: "approved", shiftType: 'midDay', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                // { _id: '5e24', date: '2024-09-24', approvalStatus: "approved", shiftType: 'midDay', day: 'tuesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                // { _id: '5e25', date: '2024-09-25', approvalStatus: "approved", shiftType: 'midDay', day: 'wednesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                // { _id: '5e26', date: '2024-09-26', approvalStatus: "approved", shiftType: 'midDay', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                // { _id: '5e27', date: '2024-09-27', approvalStatus: "approved", shiftType: 'midDay', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                // { _id: '5e28', date: '2024-09-28', approvalStatus: "approved", shiftType: 'midDay', day: 'saturday', attendanceType: 'dayoff' },
                // { _id: '5e29', date: '2024-09-29', approvalStatus: "approved", shiftType: 'midDay', day: 'sunday', attendanceType: 'dayoff' },
                // { _id: '5e30', date: '2024-09-30', approvalStatus: "approved", shiftType: 'midDay', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            ],
        },
        {
            _id: "6f",
            name: "Employee Six",
            balance: "4+",
            jobName: "Operations Specialist",
            workingDayPerWeek: "5",
            scheduledHours: "225hrs",
            lateInMonth: 5, lateInWeek: 2,
            attendanceList: [
                { _id: '6f1', date: '2024-09-01', approvalStatus: "approved", shiftType: 'night', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '6f2', date: '2024-09-02', approvalStatus: "approved", shiftType: 'night', day: 'monday', attendanceType: 'onTime', workStartTime: "07:54:12", workStopTime: "18:12:28", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '6f3', date: '2024-09-03', approvalStatus: "approved", shiftType: 'night', day: 'tuesday', attendanceType: 'late', workStartTime: "08:14:12", workStopTime: "18:15:48", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '6f4', date: '2024-09-04', approvalStatus: "approved", shiftType: 'night', day: 'wednesday', attendanceType: 'absent' },
                { _id: '6f5', date: '2024-09-05', approvalStatus: "approved", shiftType: 'night', day: 'thursday', attendanceType: 'onTime', workStartTime: "07:54:19", workStopTime: "18:12:28", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '6f6', date: '2024-09-06', approvalStatus: "approved", shiftType: 'night', day: 'friday', attendanceType: 'pto' },
                { _id: '6f7', date: '2024-09-07', approvalStatus: "approved", shiftType: 'night', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '6f8', date: '2024-09-08', approvalStatus: "approved", shiftType: 'night', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '6f9', date: '2024-09-09', approvalStatus: "approved", shiftType: 'night', day: 'monday', attendanceType: 'sick' },
                { _id: '6f10', date: '2024-09-10', approvalStatus: "approved", shiftType: 'night', day: 'tuesday', attendanceType: 'late', workStartTime: "08:10:45", workStopTime: "18:55:03", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '6f11', date: '2024-09-11', approvalStatus: "approved", shiftType: 'night', day: 'wednesday', attendanceType: 'late', workStartTime: "08:16:45", shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '6f12', date: '2024-09-12', approvalStatus: "approved", shiftType: 'night', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '6f13', date: '2024-09-13', approvalStatus: "approved", shiftType: 'night', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '6f14', date: '2024-09-14', approvalStatus: "approved", shiftType: 'night', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '6f15', date: '2024-09-15', approvalStatus: "approved", shiftType: 'night', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '6f16', date: '2024-09-16', approvalStatus: "approved", shiftType: 'night', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '6f17', date: '2024-09-17', approvalStatus: "approved", shiftType: 'night', day: 'tuesday', attendanceType: 'dayoff' },
                { _id: '6f18', date: '2024-09-18', approvalStatus: "approved", shiftType: 'night', day: 'wednesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '6f19', date: '2024-09-19', approvalStatus: "approved", shiftType: 'night', day: 'thursday', attendanceType: 'sick' },
                { _id: '6f20', date: '2024-09-20', approvalStatus: "approved", shiftType: 'night', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '6f21', date: '2024-09-21', approvalStatus: "approved", shiftType: 'night', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '6f22', date: '2024-09-22', approvalStatus: "approved", shiftType: 'night', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '6f23', date: '2024-09-23', approvalStatus: "approved", shiftType: 'night', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '6f24', date: '2024-09-24', approvalStatus: "approved", shiftType: 'night', day: 'tuesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '6f25', date: '2024-09-25', approvalStatus: "approved", shiftType: 'night', day: 'wednesday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '6f26', date: '2024-09-26', approvalStatus: "approved", shiftType: 'night', day: 'thursday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '6f27', date: '2024-09-27', approvalStatus: "approved", shiftType: 'night', day: 'friday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
                { _id: '6f28', date: '2024-09-28', approvalStatus: "approved", shiftType: 'night', day: 'saturday', attendanceType: 'dayoff' },
                { _id: '6f29', date: '2024-09-29', approvalStatus: "approved", shiftType: 'night', day: 'sunday', attendanceType: 'dayoff' },
                { _id: '6f30', date: '2024-09-30', approvalStatus: "approved", shiftType: 'night', day: 'monday', attendanceType: 'upcoming', shiftStartTime: '09:00:00', shiftEndTime: '17:00:00' },
            ],
        },
    ],
};