import { PublicClientApplication } from "@azure/msal-browser";
import { LogLevel } from "@azure/msal-browser";
export const msalConfig = {
  auth: {
    clientId: "4e0e2079-971b-447b-b046-96cc8743b125", // Replace with your Azure AD App's clientId
    authority: "https://login.microsoftonline.com/common", // Set to 'common' for multi-tenant apps
    // authority: `https://login.microsoftonline.com/3b4f9c52-8880-4e10-b868-b70c9811dbc0`, // Replace with your tenant ID
    redirectUri: "https://os.bearishos.com/Calendar",
    // redirectUri: 'http://localhost:3000/Calendar' // URL where the user will be redirected after login
  },
  cache: {
    cacheLocation: "localStorage", // For SPA, using localStorage is generally preferred
    storeAuthStateInCookie: false, // Set to false for SPAs
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) return;
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            // console.info(message);
            break;
          case LogLevel.Verbose:
            // console.debug(message);
            break;
          case LogLevel.Warning:
            // console.warn(message);
            break;
          default:
            // console.log(message);
        }
      },
      piiLoggingEnabled: false,
      logLevel: LogLevel.Verbose, // Set to verbose for detailed logging
    },
  },
};

// Create MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

// Properly initialize MSAL (only use handleRedirectPromise for redirect flows)
const initializeMsal = async () => {
  try {
    await msalInstance.handleRedirectPromise(); // Ensures MSAL is initialized properly
    console.log("MSAL Initialized");
  } catch (error) {
    console.error("MSAL initialization error:", error);
  }
};

// Export the instance and initialization method
export { msalInstance, initializeMsal };
