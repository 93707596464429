import React, { useEffect, useState } from "react";
import { Stack, Popover, MenuItem, Typography, InputBase } from "@mui/material";
import { DepartmentIcon } from "../../../../../../../images";
import {
  ButtonSmall,
  DUMMY_AVATAR_USERS,
  SmallButtonColor,
  SmallGroupedAvatars,
} from "../../../../../../common";
import styles from "./TeamName.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createTeam__api,
  reCallAdminAPIsHandler,
} from "../../../../../../../redux";
import { RootState } from "../../../../../../../redux/rootReducer";

interface TeamNameProps {
  menuWidth?: string;
  onTeamSelect?: any
}

const TeamName: React.FC<TeamNameProps> = ({ menuWidth = "240px", onTeamSelect }) => {
  const dispatch = useDispatch();

  const adminState = useSelector((state: RootState) => state && state?.admin);
  const { teams } = adminState || {};

  const [teamList, setTeamList] = useState<any>([]);

  const [teamName, setTeamName] = useState<any>("");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [selectedTeam, setSelectedTeam] = useState<any>([]);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const createCompant_team = async () => {
    try {
      if (!teamName) return;

      const payload: any = { name: teamName };

      const action = createTeam__api(payload); // API call to create a new team in the organization
      await dispatch(action); // Wait for the API call to complete
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(reCallAdminAPIsHandler("team_list")); // Recall all APIs to fetch the updated data
    }
  };

  useEffect(() => {
    if (teams && teams?.length > 0) {
      setTeamList(teams);
    }
  }, [teams]);

  return (
    <Stack className={styles.teamName} style={{ width: menuWidth }}>
      <Stack className={styles.teamName__container} onClick={handleClick}>
        <Stack className={styles.teamName__iconWrapper}>
          <DepartmentIcon />
        </Stack>
        <Typography className={styles.teamName__typography}>
          {selectedTeam?.name || "Team Name"}
        </Typography>
      </Stack>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          className: styles.teamName__popover,
        }}
      >
        <Stack
          className={styles.teamName__popoverContainer}
          sx={{ width: menuWidth }}
        >
          {/* Input Area */}
          <Stack className={styles.teamName__inputArea}>
            <Stack className={styles.teamName__inputWrapper}>
              <InputBase
                placeholder="Create a new team"
                className={styles.teamName__input}
                onChange={(e: any) => setTeamName(e.target.value)}
              />
              <ButtonSmall
                colorVarient={SmallButtonColor.BearishClay}
                label="Add new"
                onClick={() => createCompant_team()}
                sx={{
                  height: "17px",
                  padding: "0px 5px",
                  minWidth: "fit-content",
                  font: "normal normal 900 10px/13px Source Serif Pro",
                }}
              />
            </Stack>
          </Stack>

          {/* Options */}
          <Stack className={styles.teamName__options}>
            {teamList &&
              teamList?.map((team: any) => {
                return (
                  <MenuItem
                    key={team?._id}
                    onClick={() => {
                      setSelectedTeam(team);
                      handleClose();
                      onTeamSelect && onTeamSelect(team);
                    }}
                    className={styles.teamName__menuItem}
                  >
                    <Typography className={styles.teamName__menuTypography}>
                      {team?.name}
                    </Typography>
                    <SmallGroupedAvatars
                      maxAvatars={4}
                      usersData={team?.users || []}
                    />
                  </MenuItem>
                );
              })}
          </Stack>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default TeamName;
