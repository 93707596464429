// src/utils/sortChats.ts
import dayjs from "dayjs";

export const sortChats = (chats: any) => {
    const sortedChatIds = Object.keys(chats).sort((a, b) => {
        const dateA = chats[a].lastMessageDate
            ? dayjs(chats[a].lastMessageDate)
            : dayjs(chats[a].createdAt);
        const dateB = chats[b].lastMessageDate
            ? dayjs(chats[b].lastMessageDate)
            : dayjs(chats[b].createdAt);

        return dateB.valueOf() - dateA.valueOf();
    });

    const sortedChats: any = {};
    sortedChatIds.forEach((id) => {
        sortedChats[id] = chats[id];
    });

    return sortedChats;
};