// MainTopWorkspace.tsx

import React, { useEffect, useRef, useState } from "react";
import { Stack, Popover } from "@mui/material";
import {
  CmdIconChecker,
  MediumSearchbar,
  SwitchSmall,
  XlargeCreateButton,
  XlargeCreateButtonColor,
} from "../../../../common";
import {
  ConnectionItem,
  CreateCardData,
  ShareWorkspaceItem,
  initialCreateCardData,
} from "./types";
import { ProjectIcon } from "../../../../../images";
import { HugeCreateItemWorkspace } from "../HugeCreateItemWorkspace";
import { MainTopWorkspaceProps } from "../../types";
import { useDispatch } from "react-redux";

import {
  addShare__api,
  AddWorkspaceMember,
  addWorkspaceMember__api,
  createProject__Jira__api,
  createSpace__Confluence__api,
  // CreateWorkspace,
  createWorkspace__api,
  updateProject__Jira__api,
  // Description,
  // Formatting,
  // UpdateWorkspace,
} from "../../../../../redux";
import {
  //  getUser,
  isAuthenticated,
} from "../../../../../Utils";
import { useNavigate } from "react-router-dom";
import CreateWorkspacePopUp from "../CreateWorkapcePopUp/CreateWorkapcePopUp";
const MainTopWorkspace: React.FC<MainTopWorkspaceProps> = ({
  viewMode,
  setViewMode,
  getAllWorkspace,
  handleEditPopUp,
  editOpenPopUp,
  intialData,
  initialDataOfWorkspaceId,
  updateWorkspace,
  iconApp,
  theme,
  cmd_toggle,
  setCmd_toggle,
  initialDataOfsource,
  da__data,
}) => {
  const auth = isAuthenticated();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [createCardData, setCreateCardData] = useState<CreateCardData>(
    intialData ? intialData : initialCreateCardData
  );

  const [createLoader, setCreateLoader] = useState<boolean>(false);

  const [create__space__type, setCreate__space__type] = useState<
    "ask__type" | "BearishOS" | "Confluence" | "Jira" | null
  >(null);

  const popRef = useRef(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    if (true) {
      setCreate__space__type("ask__type");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (handleEditPopUp) {
      handleEditPopUp();
    }
  };

  useEffect(() => {
    if (editOpenPopUp && intialData) {
      setCreateCardData(intialData);
      setCreate__space__type(
        initialDataOfsource as
        | "BearishOS"
        | "Confluence"
        | "ask__type"
        | "Jira"
        | null
      );
      setAnchorEl(popRef.current);
    } // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [editOpenPopUp]);

  const updateMainDetails = (value: Partial<CreateCardData["mainDetails"]>) => {
    setCreateCardData((prevData) => ({
      ...prevData,
      mainDetails: {
        ...prevData.mainDetails,
        ...value,
      },
    }));
  };

  const updateConnections = (
    value: Partial<{ connectionList: ConnectionItem[] }>
  ) => {
    setCreateCardData((prevData) => {
      const existingConnectionList = prevData.connections.connectionList;
      const newConnections =
        value.connectionList?.filter(
          (newConnection) =>
            newConnection &&
            typeof newConnection.id === "string" &&
            typeof newConnection.text === "string" &&
            !existingConnectionList.some(
              (existingConnection) => existingConnection.id === newConnection.id
            )
        ) || [];
      return {
        ...prevData,
        connections: {
          ...prevData.connections,
          connectionList: [...existingConnectionList, ...newConnections],
        },
      };
    });
  };

  const updateShareWorkspace = (
    value: Partial<{ shareWorkspaceList: ShareWorkspaceItem[] }>
  ) => {
    setCreateCardData((prevData) => {
      const existingShareWorkspaceList =
        prevData.shareWorkspace.shareWorkspaceList;
      const newShareWorkspaceItems =
        value.shareWorkspaceList?.filter(
          (newItem) =>
            newItem &&
            typeof newItem.userId === "string" &&
            typeof newItem.text === "string" &&
            typeof newItem.value === "string" &&
            !existingShareWorkspaceList?.some(
              (existingItem) => existingItem?.userId === newItem?.userId
            )
        ) || [];
      return {
        ...prevData,
        shareWorkspace: {
          ...prevData.shareWorkspace,
          shareWorkspaceList: [
            ...existingShareWorkspaceList,
            ...newShareWorkspaceItems,
          ],
        },
      };
    });
  }; // Helper function to calculate a date 3 months ahead
  function getThreeMonthsAheadDate() {
    const date = new Date();
    date.setMonth(date.getMonth() + 3); // Add 3 months
    return date.toISOString(); // Convert to ISO format
  }

  const handleCreateWorkspace = async () => {
    const formData = new FormData();

    if (editOpenPopUp && updateWorkspace && initialDataOfWorkspaceId) {
      if (create__space__type === "BearishOS") {
        const emojis = ["🫠", "🌟", "🚀", "🎉", "🧩"];
        const getRandomEmoji = () =>
          emojis[Math.floor(Math.random() * emojis.length)];

        // Add form data for editing a workspace
        formData.append("name", createCardData?.mainDetails?.workspaceName);

        formData.append(
          "description[content]",
          createCardData?.mainDetails?.workspaceDetails
        );

        // Check if uploadWorkspaceImage is a File object
        const coverImage = createCardData?.mainDetails?.uploadWorkspaceImage;

        if (coverImage instanceof File) {
          formData.append(
            "appearance[coverImage]",
            coverImage || intialData?.mainDetails?.uploadWorkspaceImage
          );
        } else {
          formData.append(
            "appearance[coverImage]",
            coverImage || intialData?.mainDetails?.uploadWorkspaceImage
          );

          // Handle non-file scenarios if needed (e.g., URLs)
        }

        formData.append("appearance[icon]", iconApp || getRandomEmoji());

        formData.append("appearance[theme][primaryColor]", theme?.primaryColor);

        setCreateLoader(true);
        await updateWorkspace(formData, initialDataOfWorkspaceId);

        const shareWorkspaceList =
          createCardData?.shareWorkspace?.shareWorkspaceList;
        // Check if the shareWorkspaceList is not empty
        if (shareWorkspaceList && shareWorkspaceList?.length > 0) {
          // console.log(
          //   shareWorkspaceList,
          //   "createCardData.shareWorkspace.shareWorkspaceList"
          // );

          // Iterate over each member in the shareWorkspaceList
          for (const member of shareWorkspaceList) {
            const payload: AddWorkspaceMember = {
              memberId: member?.userId,
              memberRole: member?.role || "editor",
              permissions: member?.permissions || "edit",
            };

            const currentUrl = window.location.href; // Get current URL dynamically

            const share__add_payload: any = {
              mainLink: currentUrl, // Current URL
              entityId: initialDataOfWorkspaceId, // Ensure workspaceId is defined
              entityName: "workspace",
              memberId: member?.userId, // Member ID
              role: member?.role || "editor", // Default role: "editor"
              expirationDate: getThreeMonthsAheadDate(), // 3 months ahead in ISO format
            };
            const action_share = addShare__api(share__add_payload);

            try {
              // Dispatch the action to add the member to the workspace
              const action = addWorkspaceMember__api(
                payload,
                initialDataOfWorkspaceId
              );
              await dispatch(action);
              await dispatch(action_share);
            } catch (error) {
              console.error(`Error adding member ${member.userId}:`, error);
            }
          }
        }

        setCreateCardData(initialCreateCardData);
        setCreateLoader(false);
        handleClose();
        await getAllWorkspace();
      } else if (create__space__type === "Confluence") {
      } else if (create__space__type === "Jira") {
        if (!da__data?.__id) {
          return;
        }
        setCreateLoader(true);

        const payload: any = {
          name: createCardData?.mainDetails?.workspaceName,
        };

        await dispatch(updateProject__Jira__api(da__data?.__id, payload));
        setCreateCardData(initialCreateCardData);
        setCreateLoader(false);
        handleClose();
        await getAllWorkspace();
      }
    } else {
      try {
        if (!auth) return;

        formData.append("name", createCardData.mainDetails.workspaceName);
        formData.append(
          "description[content]",
          createCardData?.mainDetails?.workspaceDetails
        );
        formData.append("visibility", "public");

        // Handle cover image
        const coverImage = createCardData?.mainDetails?.uploadWorkspaceImage;
        if (coverImage instanceof File) {
          formData.append("appearance[coverImage]", coverImage);
        } else {
          formData.append("appearance[coverImage]", coverImage);
          console.warn("Cover image is not a file:", coverImage);
          // Handle non-file scenarios if needed (e.g., URLs)
        }

        formData.append("appearance[icon]", "🍊");
        formData.append("appearance[theme][primaryColor]", "#ff5733");
        formData.append("appearance[theme][secondaryColor]", "#33ff57");
        formData.append("appearance[theme][textColor]", "#333333");
        formData.append("appearance[theme][font]", "Avenir Next");

        // Members
        createCardData.connections.connectionList.forEach((member, index) => {
          formData.append(`members[${index}]`, JSON.stringify(member));
        });

        // Custom Fields
        formData.append("customFields[0][name]", "Custom1");
        formData.append("customFields[0][type]", "text");
        formData.append("customFields[0][options]", "");
        formData.append("customFields[0][value]", "Add Content Here...");

        // // Reminders
        // createCardData.shareWorkspace.shareWorkspaceList.forEach(
        //   (reminder, index) => {
        //     formData.append(`reminders[${index}]`, JSON.stringify(reminder));
        //   }
        // );

        setCreateLoader(true);

        if (create__space__type === "Confluence") {
          const generateRandomSpaceKey = () => {
            const prefix = "SPACE"; // You can change this prefix to anything you want
            const randomString = Math.random()
              .toString(36)
              .substring(2, 8)
              .toUpperCase(); // Generates a random 6-character string
            return `${prefix}${randomString}`; // Combines prefix with random string
          };

          const spaceKey = generateRandomSpaceKey(); // Generate the random space key

          const spacePayload = {
            key: spaceKey, // Use the generated random space key
            name: createCardData.mainDetails.workspaceName, // The space name (required, max 255 characters)
            description: {
              plain: {
                value: createCardData?.mainDetails?.workspaceDetails, // The space description (required)
                representation: "plain", // The representation type, which should be 'plain'
              },
            },
            icon: coverImage
              ? {
                path: coverImage, // Set the path to the image URL
                width: 100, // You can adjust this value
                height: 100, // You can adjust this value
                isDefault: false, // Set whether this icon is default (false if it's a custom icon)
              }
              : undefined, // If no image URL is provided, the icon field is omitted
          };

          // Dispatch action to create space with the generated payload
          await dispatch(createSpace__Confluence__api(spacePayload));
        } else if (create__space__type === "Jira") {
          // Function to generate a random key for the Jira project
          // Function to generate a random key for the Jira project, ensuring it starts with an uppercase letter
          const generateRandomKey = (length = 6) => {
            const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"; // Possible characters for the key
            let randomKey = "";

            // Ensure the first character is always an uppercase letter
            randomKey += characters.charAt(Math.floor(Math.random() * 26)); // First character is a letter

            // Generate the remaining characters (upper case letters and numbers)
            for (let i = 1; i < length; i++) {
              randomKey += characters.charAt(
                Math.floor(Math.random() * characters.length)
              );
            }

            return randomKey;
          };

          const createProject_payload = {
            key: generateRandomKey(),
            name: createCardData.mainDetails.workspaceName,
            projectTypeKey: "software",
            assigneeType: "UNASSIGNED",
            description: createCardData?.mainDetails?.workspaceDetails,
            url: "https://plus.unsplash.com/premium_photo-1664474619075-644dd191935f?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aW1hZ2V8ZW58MHx8MHx8fDA%3D",
          };

          await dispatch(createProject__Jira__api(createProject_payload));
        } else {
          const res: any = await dispatch(createWorkspace__api(formData));

          if (res?.data?._id) {
            const workspaceId = res?.data?._id;
            const shareWorkspaceList =
              createCardData?.shareWorkspace?.shareWorkspaceList;

            // Check if the shareWorkspaceList is not empty
            if (shareWorkspaceList && shareWorkspaceList?.length > 0) {
              // console.log(
              //   shareWorkspaceList,
              //   "createCardData.shareWorkspace.shareWorkspaceList"
              // );

              // Iterate over each member in the shareWorkspaceList
              for (const member of shareWorkspaceList) {
                const payload: AddWorkspaceMember = {
                  memberId: member?.userId,
                  memberRole: member?.role || "editor",
                  permissions: member?.permissions || "edit",
                };

                const currentUrl = window.location.href; // Get current URL dynamically

                const share__add_payload: any = {
                  mainLink: currentUrl, // Current URL
                  entityId: workspaceId, // Ensure workspaceId is defined
                  entityName: "workspace",
                  memberId: member?.userId, // Member ID
                  role: member?.role || "editor", // Default role: "editor"
                  expirationDate: getThreeMonthsAheadDate(), // 3 months ahead in ISO format
                };

                try {
                  // Dispatch the action to add the member to the workspace
                  const action = addWorkspaceMember__api(payload, workspaceId);
                  const action_share = addShare__api(share__add_payload);
                  await dispatch(action);
                  await dispatch(action_share);
                } catch (error) {
                  console.error(`Error adding member ${member.userId}:`, error);
                }
              }
            }
          }
        }

        setCreateCardData(initialCreateCardData);
        setCreateLoader(false);
        handleClose();
        await getAllWorkspace();
      } catch (error) {
        setCreateLoader(false);
        handleClose();
        console.error("Error creating workspace:", error);
      }
    }
  };

  const handleOpenCloudStoragePanel = () => {
    navigate(`/cloud-storage/`);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className="boxProject" // Replace with your actual CSS class name
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={1}
        >
          <XlargeCreateButton
            label={`${editOpenPopUp ? "Update" : "Create"} a New Workspace`}
            subtitle="Workspaces hold Docs, Lists, Wikis, and More"
            colorVarient={XlargeCreateButtonColor.Orange}
            icon={<ProjectIcon color="white" />}
            onClick={(e: any) => handleClick(e)}
            popRef={popRef}
          />
          <XlargeCreateButton
            label="Cloud Storage"
            subtitle="Open Cloud Storage panel to the right of this screen"
            colorVarient={XlargeCreateButtonColor.LightOrange}
            icon={<ProjectIcon color="white" />}
            onClick={() => handleOpenCloudStoragePanel()}
          />
          <CmdIconChecker
            checked={cmd_toggle}
            onChange={setCmd_toggle} // Update state
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap={2}>
          <SwitchSmall viewMode={viewMode} setViewMode={setViewMode} />
          <MediumSearchbar ph={"Search all Workspace"} />
        </Stack>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {create__space__type !== "ask__type" ? (
          <HugeCreateItemWorkspace
            createCardData={createCardData}
            updateMainDetails={updateMainDetails}
            updateConnections={updateConnections}
            loader={createLoader}
            handleClickButton={handleCreateWorkspace}
            updateShareWorkspace={updateShareWorkspace}
            editOpenPopUp={editOpenPopUp}
            create__space__type={create__space__type}
            handleClose={handleClose}
          />
        ) : create__space__type === "ask__type" ? (
          <CreateWorkspacePopUp
            handleOnClick={(e: any) => setCreate__space__type(e)}
          />
        ) : null}
      </Popover>
    </>
  );
};

export default MainTopWorkspace;
