import React from "react";
import { UserMsgStatusType } from "./MessageAvatarChipType";
import { AvatarNormal } from "../../../../common";

interface MessageAvatarChipProps {
  avatarSize?: number;
  userMsgStatus?: UserMsgStatusType;
  username?: string;
  imgSrc?: string;
  avatarColor?: string;
  avatarApiData?: string; //"#FF5733|AS" or img-url-string
}

const MessageAvatarChip: React.FC<MessageAvatarChipProps> = ({
  avatarSize = 25,
  userMsgStatus,
  avatarColor,
  imgSrc,
  username,
  avatarApiData,
}) => {
  const getBordercolor = (): string => {
    switch (userMsgStatus) {
      case "active":
        return "#008D4C";

      case "doNotDisturb":
        return "#FF0000";

      case "pending":
        return "#FFB100";

      default:
        return "";
    }
  };

  return (
    <AvatarNormal
      size={avatarSize}
      avatarColor={avatarColor}
      imgSrc={imgSrc}
      username={username}
      avatarApiData={avatarApiData}
      sx={{ boxSizing: "border-box", border: `1px solid ${getBordercolor()}` }}
    />
  );
};

export default MessageAvatarChip;
