import { Stack, Typography } from "@mui/material";
import React from "react";
import { CheckboxMedium } from "../../../../../../common";

interface TableHeaderSelectAllContainerProps {}

const TableHeaderSelectAllContainer: React.FC<
  TableHeaderSelectAllContainerProps
> = ({}) => {
  return (
    <Stack
      sx={{
        position: "sticky",
        left: 0,
        width: "220px",
        zIndex: 200,
        height: "100%",
        bgcolor: "white",
        justifyContent: "space-between",
        borderRight: "1px solid #E9EDF2",
        paddingY: "12px",
        flexShrink: 0,
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <CheckboxMedium />
        <Typography
          sx={{
            font: "normal normal 300 13px/16px Source Serif Pro",
          }}
        >
          Select All
        </Typography>
      </Stack>
      <Typography
        sx={{
          font: "normal normal 300 14px/18px Source Serif Pro",
        }}
      >
        Employees
      </Typography>
    </Stack>
  );
};

export default TableHeaderSelectAllContainer;
