import React, { useState } from "react";
import { IconContainer } from "../../../CreateShiftMenu/view";
import { DepartmentIcon } from "../../../../../../../images";
import { Box, Modal, Stack } from "@mui/material";
import { TextLinkButton } from "../../../../../../common";
import { UserInfoChip } from "../../../../../Email/components";
import { adjustHexColor } from "../../../../../../../Utils";
import { SearchBarUsersTrax } from "../../../SearchBarUsersTrax";

interface ReportsToViewProps {}

const ReportsToView: React.FC<ReportsToViewProps> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <IconContainer icon={DepartmentIcon} iconSize={18} title="Reports to">
      <Stack sx={{ gap: "5px", alignItems: "end" }}>
        <UserInfoChip
          popoverPlace="top"
          userEmail="user email"
          userName="user name"
          chipHeight="30px"
          popoverZindex={1212}
        />
        <TextLinkButton
          buttonLabel="Update"
          sx={{ width: "fit-content" }}
          onClick={handleOpenModal}
        />
      </Stack>

      {/* Modal */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        BackdropProps={{
          sx: {
            backgroundColor: adjustHexColor("#000000", 20),
            border: "0px",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minHeight: "462px",
          }}
        >
          <SearchBarUsersTrax />
        </Box>
      </Modal>
    </IconContainer>
  );
};

export default ReportsToView;
