import { Stack, Typography } from "@mui/material";
import React from "react";
import {
  EditableTextArea,
  MediumInputIconBox,
  SingleTeammateDropdown,
  SmallDateRangePicker,
} from "../../../../../../common";
import { LargeheightInputBox } from "../../../../common";

const ProjectOwnerDropdownDummy: string[] = [
  "John Doe",
  "Jane Smith",
  "Emily Johnson",
  "Michael Brown",
  "Sarah Davis",
  "David Wilson",
];

const ProjectOverviewDetails: React.FC = () => {
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack
        sx={{
          boxSizing: "border-box",
          paddingTop: "20px",
          paddingBottom: "20px",
          gap: "20px",
        }}
      >
        <Stack>
          <Typography
            sx={{
              font: "normal normal 600 10px/13px Source Serif Pro",
            }}
          >
            Full project name
          </Typography>
        </Stack>

        <LargeheightInputBox inputTitle="Full project name" />

        <Stack sx={{ gap: "4px" }}>
          <Typography
            sx={{
              font: "normal normal 600 10px/13px Source Serif Pro",
            }}
          >
            Details
          </Typography>

          <EditableTextArea
            style={{
              border: "0.5px solid #E9EDF2",
              borderRadius: "5px",
              font: "normal normal 400 17px/22px Source Serif Pro",
              boxSizing: "border-box",
              padding: "0px 8px",
            }}
            minRows={3}
            maxRows={3}
          />
        </Stack>
      </Stack>
      <Stack direction="row" gap="10px">
        <Stack sx={{ width: "125px", gap: "10px" }}>
          <Typography
            sx={{
              font: "normal normal 600 10px/13px Source Serif Pro",
            }}
          >
            Timeline
          </Typography>
          <SmallDateRangePicker onDateChange={(date: any) => null} />
        </Stack>
        <Stack sx={{ width: "100%", gap: "10px" }}>
          <Typography
            sx={{
              font: "normal normal 600 10px/13px Source Serif Pro",
            }}
          >
            Project Owner
          </Typography>
          <SingleTeammateDropdown
            options={ProjectOwnerDropdownDummy}
            placeholder="Select Project Owner"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProjectOverviewDetails;
