import React, { lazy, Suspense, useState } from "react";
import { Stack, IconButton, Typography } from "@mui/material";
import { XCircleIcon } from "../../../../../images";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";
import { useTranslation } from "react-i18next";

import LazyContactsTab from "./tabs/ContactsTab/ContactsTab";
import LazyDetailsTab from "./tabs/DetailsTab/DetailsTab";
interface ChannelDetailsMenuProps {
  channelDetails?: any;
  closeChannelDetailSection?: () => void;
}

const ChannelDetailsMenu: React.FC<ChannelDetailsMenuProps> = ({
  channelDetails,
  closeChannelDetailSection,
}) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);

  const tabs = [
    {
      label: "Details",
      LANG: "VIDEO_CENTER.MEETING.DETAILS",
      content: (
        <LazyDetailsTab
          channelDetails={channelDetails}
        />
      ),
    },
    {
      label: "Contacts",
      LANG: "VIDEO_CENTER.MEETING.CONTACTS",
      content: <LazyContactsTab channelDetails={channelDetails} />,
    },
  ];

  return (
    <Stack
      sx={{
        width: "375px",
        height: "430px",
        bgcolor: "white",
        padding: "15px",
        boxSizing: "border-box",
        gap: "15px",
        zIndex: "200",
        position: "fixed",
        top: "174px",
        left: "284px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ font: "normal normal 700 15px/19px Source Serif Pro" }}
        >
          {t("MESSAGE.CHANNEL_NAME")}
        </Typography>

        <IconButton
          onClick={closeChannelDetailSection}
          sx={{ p: 0 }}
          disableRipple
        >
          <XCircleIcon w={15} />
        </IconButton>
      </Stack>

      <TabsWithState
        ariaLabel="channel details tabs"
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
      >
        {tabs.map((tab, index) => (
          <StyledTab
            key={index}
            disableRipple
            label={t(tab.LANG)}
            {...a11yProps(index)}
          />
        ))}
      </TabsWithState>

      {tabs.map((tab, index) => (
        <TabPanel key={index} value={tabValue} index={index}>
          <Suspense fallback={<div>Loading...</div>}>{tab.content}</Suspense>
        </TabPanel>
      ))}
    </Stack>
  );
};

export default ChannelDetailsMenu;
