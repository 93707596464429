import { PayloadAction } from "@reduxjs/toolkit";
import { AdminPortalState } from "../AdminPortalType";

export const inviteUsers__Failure = (
    state: AdminPortalState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
    state.data = null;
};
