import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { SxProps, TextFieldProps } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

type DateAndTimePickerProps = {
  onSelect?: (value: string | null) => void; // Passing ISO string
  hideUnderlineHover?: boolean;
  sx?: SxProps;
};

const DateAndTimePicker: React.FC<DateAndTimePickerProps> = ({
  onSelect,
  hideUnderlineHover = true,
  sx,
}) => {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs());
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue);
    
    // Convert to ISO string if newValue is not null
    const isoString = newValue ? newValue.toISOString() : null;

    // Pass ISO string to parent through onSelect prop
    if (onSelect) {
      onSelect(isoString);
    }

    // Uncomment the line below if you want to close the picker after selecting a date/time
    // handleClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        value={value}
        onChange={handleChange}
        open={open}
        onClose={handleClose}
        onAccept={handleClose} // Close the picker when the OK button is pressed
        format="MMM DD, YYYY hh:mm a" // Custom date format
        slotProps={{
          textField: {
            onClick: handleOpen,
            InputProps: {
              endAdornment: null, // Remove the icon
              sx: {
                border: "none",
                font: "normal normal 300 12px/16px Source Serif Pro",
                ...sx,
              }, // Remove the border
            },
            variant: "standard" as TextFieldProps["variant"], // Apply standard variant to remove the border
            sx: {
              "& .MuiInputBase-input": {
                cursor: "pointer",
                padding: 0,
              },
              "& .MuiInput-underline:before": {
                borderBottom: "none",
                content: hideUnderlineHover ? "none" : '"\u00A0"',
              },
              "& .MuiInput-underline:hover:before": {
                borderBottom: "none",
              },
              "& .MuiInput-underline:after": {
                borderBottom: "none",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
              "& .MuiOutlinedInput-root:hover": {
                borderBottom: "none",
              },
            },
          } as TextFieldProps,
        }}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
      />
    </LocalizationProvider>
  );
};

export default DateAndTimePicker;
