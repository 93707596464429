import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { InboxIcon } from "../../../../../../../images";
import { adjustHexColor } from "../../../../../../../Utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../redux/store";

type SmartFilterProps = {
  handleFilterEmails: (value: string) => Promise<void>;
  getFilters?: () => Promise<void>;
  activeFilterList: FilterItem[];
};

interface FilterItem {
  _id: string;
  name: string;
  isActive: boolean;
  count: number;
  icon: React.ReactNode;
}

const SmartFilter: React.FC<SmartFilterProps> = ({
  handleFilterEmails,
  activeFilterList,
}) => {
  const baseColor = adjustHexColor("#00767B", 25);

  const emailsCenterFilters = useSelector(
    (state: RootState) => state?.emails?.email_filters
  );

  const filtersWithSelection =
    activeFilterList &&
    activeFilterList?.map((category) => {
      const emailFilter =
        emailsCenterFilters &&
        emailsCenterFilters.find((filter: any) => filter._id === category._id);
      return {
        ...category,
        isSelected: emailFilter ? emailFilter.isActive : false, // Set isSelected based on isActive from email_filters
      };
    });

  return (
    <Box>
      <Stack gap={"5px"}>
        {filtersWithSelection
          .filter((item) => item.isActive) // Only show active filters
          .map((category, index) => (
            <Stack direction="row" alignItems="center" key={index}>
              <Button
                sx={{
                  backgroundColor: "none",
                  textTransform: "capitalize",
                  font: "normal normal 300 13px/17px Source Serif Pro",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                  color: "black",
                  bgcolor: category.isSelected ? baseColor : "inherit", // Set background color based on isSelected
                  transition: "background-color 0.3s ease",
                }}
                onClick={() => handleFilterEmails(category._id)}
              >
                <Stack direction="row" alignItems="center">
                  <InboxIcon w={18} />
                  <span style={{ marginLeft: "8px" }}>{category.name}</span>
                </Stack>
                {category.count > 0 && <span style={{ color: "blue" }}>({category.count})</span>}
              </Button>
            </Stack>
          ))}
      </Stack>
    </Box>
  );
};

export default SmartFilter;
