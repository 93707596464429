import React, {useState} from "react";
import styles from "./WaitingRoomHeader.module.css";
import {Box, Divider, MenuItem, Stack, Typography,} from "@mui/material";

import {AuthState,} from "../../../../../redux";
import {useSelector} from "react-redux";
import UserProfile from "../../../../common/TopNavbar/UserProfile";
import {StyledMenu} from "../../../../common/TopNavbar/StyledMenubar";
import Logo from '../../../../../images/other/topNavbar/BearishOSNewLogo.png'
import {RootState} from "../../../../../redux/store";
import {VideoCenterState} from "../../../../../redux/Slice/videoCenter/VideoCenterTypes";
import {AvatarMedium, BgColorCode} from "../../../../common";
import {GreenArrow} from "../../../../../images";
import {clearAllStorage} from "../../../../../redux/middleware/apiMiddleware";
import {cookieDomain, ENABLE_WORKOS_MODE} from "../../../../../Utils";
import {useNavigate} from "react-router-dom";

const WaitingRoomHeader = () => {
    const { onGoingMeeting } = useSelector(
        (state: RootState) => state.videoCenter as VideoCenterState
    );
    let bgColorCode: BgColorCode = BgColorCode.lightBlueWhite; // Default color
    const [guestAnchor, setGuestAnchor] = useState<any>()
    const { user } = useSelector((state: RootState) => state.auth as AuthState);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<any>()

    const handleAvatarClick = (event: any) => {
        if (onGoingMeeting?.isNonBearishUser) {
            setGuestAnchor(event.currentTarget)
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        if (onGoingMeeting?.isNonBearishUser) {
            setGuestAnchor(null);
        } else {
            setAnchorEl(null);
        }
    };

    const handleStatusClick = (event: React.MouseEvent<HTMLElement>) => {
        // setStatusAnchorEl(event.currentTarget);
    };

    // Function to open the support page
    const openSupportPage = () => {
        window.open("https://bearishos.freshdesk.com/support/home", "_blank");
    };

    // Function to open the legal page
    const openLegalPage = () => {
        window.open("https://www.bearishos.com/termofservice", "_blank");
    };

    // Function to handle logout and redirect to os.localtest.me with f=1 query param
    const handleLogout = () => {
        // Clear all cookies and localStorage
        clearAllStorage();

        if (ENABLE_WORKOS_MODE) {
            // Redirect to os.localtest.me with f=1 query parameter
            const redirectUrl = `http://os${cookieDomain === ".localtest.me" ? ".localtest.me:3000" : cookieDomain
                }/dashboard?f=1`; // Or use relative path as needed
            window.location.replace(redirectUrl);
        } else {
            window.location.replace("/login");
        }
    };


    return (
                <Stack className={styles.topNavbarContainer}>
                    {/* Left Section */}
                    <Stack
                        sx={{
                            textAlign: "start",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "25%",
                            gap: "10px"
                        }}
                        className={styles.upcomingCallCard__header}
                    >
                        <Box
                            component={"img"}
                            src={Logo}
                            sx={{ height: "31px", width: "30px", marginRight: "8px" }}
                            alt="logo"
                        />
                        <Typography
                            className={styles.upcomingCallCard__meetingName}
                            sx={{ font: "normal normal 800 18px/23px Source Serif Pro" }}
                        >
                            Bearish OS
                        </Typography>
                    </Stack>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "33%",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Divider
                            orientation="vertical"
                            flexItem
                            style={{marginLeft: "5px", marginRight: "20px", marginTop: "8px", marginBottom: "8px"}}
                        />

                        <AvatarMedium
                            showBadge={true}
                            imgSrc={onGoingMeeting?.userAccountDetails?.avatar || onGoingMeeting?.userAccountDetails?.firstName || "u"}
                            userName={onGoingMeeting?.userAccountDetails?.firstName + " " + onGoingMeeting?.userAccountDetails?.lastName || "u"}
                            bgColorCode={bgColorCode}
                            onClick={handleAvatarClick}
                        />
                        <StyledMenu
                            anchorEl={guestAnchor}
                            open={Boolean(guestAnchor)}
                            onClose={handleClose}
                        >
                            <Box className={styles.topNavbarUserBox}>
                                <UserProfile
                                    fullName={
                                        user ? user?.data?.firstName + " " + user?.data?.lastName :
                                            onGoingMeeting?.userAccountDetails?.firstName + " " + onGoingMeeting?.userAccountDetails?.lastName
                                    }
                                    email={user?.data?.email || onGoingMeeting?.userAccountDetails?.email}
                                />
                            </Box>
                        </StyledMenu>
                        <StyledMenu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <Box className={styles.topNavbarUserBox}>
                                <UserProfile
                                    fullName={
                                        user?.data?.firstName + " " + user?.data?.lastName ||
                                        "user-name"
                                    }
                                    email={user?.data?.email || "user-email"}
                                />
                            </Box>
                            <Stack justifyContent="center" className={styles.topNavbarMenuItem}>
                                <Typography className={styles.topNavbarSubscriptionType}>
                                    Subscription Type:
                                    <Typography
                                        component="span"
                                        className={styles.topNavbarSubscriptionTypeValue}
                                    >
                                        Pro
                                    </Typography>
                                </Typography>
                            </Stack>
                            <MenuItem
                                className={styles.topNavbarMenuItem}
                                sx={{ justifyContent: "space-between" }}
                                onClick={() => {
                                    openLegalPage();
                                }}
                            >
                                <Typography className={styles.topNavbarMenuItemText}>
                                    Bearish's Policies & Legal
                                </Typography>
                                <Box
                                    className={styles.topNavbarMenuItemBox}
                                    style={{ backgroundImage: `url(${GreenArrow})` }}
                                />
                            </MenuItem>
                            <MenuItem
                                className={styles.topNavbarMenuItem}
                                sx={{ justifyContent: "space-between" }}
                                onClick={handleStatusClick}
                            >
                                <Typography className={styles.topNavbarMenuItemText}>
                                    Status:
                                    <Typography
                                        component="span"
                                        className={styles.topNavbarStatusValue}
                                    >
                                        {user?.data?.status || "Online"}
                                    </Typography>
                                </Typography>
                                <Box
                                    className={styles.topNavbarMenuItemBox}
                                    style={{ backgroundImage: `url(${GreenArrow})` }}
                                />
                            </MenuItem>
                            <MenuItem
                                onClick={() => navigate("/Settings")}
                                className={styles.topNavbarMenuItem}
                            >
                                <Typography className={styles.topNavbarMenuItemText}>
                                    Settings
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    openSupportPage();
                                }}
                                className={styles.topNavbarMenuItem}
                            >
                                <Typography className={styles.topNavbarMenuItemText}>
                                    Support
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={() => handleLogout()}
                                className={styles.topNavbarMenuItem}
                            >
                                <Typography className={styles.topNavbarMenuItemText}>
                                    Logout
                                </Typography>
                            </MenuItem>
                        </StyledMenu>
                        <StyledMenu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <Box className={styles.topNavbarUserBox}>
                                <UserProfile
                                    fullName={
                                        user?.data?.firstName + " " + user?.data?.lastName ||
                                        "user-name"
                                    }
                                    email={user?.data?.email || "user-email"}
                                />
                            </Box>
                        </StyledMenu>
                    </div>
                </Stack>
             );
};

export default WaitingRoomHeader;