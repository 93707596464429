import { Button, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../../../Utils";
import { MessageAvatarChip } from "../../../../common";

interface MemberViewButtonProps {
  userName: string | undefined;
  onButtonClick: () => void;
}

const MemberViewButton: React.FC<MemberViewButtonProps> = ({
  userName,
  onButtonClick,
}) => {
  return (
    <Button
      onClick={onButtonClick}
      sx={{
        height: "24px",
        textTransform: "none",
        minHeight: 0,
        justifyContent: "start",
        boxSizing: "border-box",
        padding: "0px 5px",
        gap: "5px",
        color: "#775EE2",
        ":hover": { bgcolor: adjustHexColor("#775EE2", 50) },
      }}
    >
      <MessageAvatarChip
        userMsgStatus="pending"
        avatarSize={20}
        username={userName}
      />

      <Typography
        sx={{
          color: "black",
          font: "normal normal 300 13px/16px Source Serif Pro",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {userName}
      </Typography>
    </Button>
  );
};

export default MemberViewButton;
