import {Box, Button, IconButton, Stack, Typography} from "@mui/material";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {
    ArrowUpIcon,
    MinimizeIcon,
    PlayArrowIcon,
    StreamIconBearishOSIconPng,
} from "../../../../../images";
import {MessageAvatarChip, UserPreviewInCall} from "../../common";
import {adjustHexColor} from "../../../../../Utils";
import {
    EndCallButton,
    MicButton,
    VideoButton,
} from "../../../Calls/components";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import {GlobalState} from "../../../../../redux/Slice/Global/globalTypes";
import {MeetingContext} from "../../../../Povider";
import {getCallUserDetails} from "../../../../../redux/Slice/Global/globalActions";
import {logMeetingActivity} from "../../../../../redux";

interface MiniProgressCallPopupProps {
}

const MiniProgressCallPopup: React.FC<MiniProgressCallPopupProps> = ({}) => {
    const [isExpended, setIsExpended] = useState<boolean>(true);
    const {groupCallState} = useSelector((state: RootState) => state.global as GlobalState);
    const meetingContext: any = useContext(MeetingContext);

    const totalUserInCall = 7;

    const dispatch = useDispatch();

    const sendActivityToAPI = async (event: any) => {
        try {
            const meetingId = groupCallState?.callData?.meeting?._id;
            const response = {
                event
            };
            await dispatch(logMeetingActivity(meetingId, response));
        } catch (error) {
            console.error("Failed to send activity to API", error);
        }
    };

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const videoTiles = useMemo(() => {
        const sorted = meetingContext.VideoPreferences.sortVideoTiles(
            Object.values(meetingContext.realtimeAttendees)
        );
        return sorted;
    }, [meetingContext.VideoPreferences]);

    const ownVideoTile = useMemo(
        () =>
            videoTiles.find(
                ({userId, chimeAttendeeId}: any) =>
                    groupCallState?.userAccountDetails?._id === userId && !chimeAttendeeId?.includes("#content")
            ),
        [groupCallState?.userAccountDetails?._id, videoTiles]
    );

    useEffect(() => {
        const fetchUserDetails = async () => {
            if (!Object.keys(meetingContext?.realtimeAttendees) || Object.keys(meetingContext?.realtimeAttendees).length === 0) {
                return;
            }
            const newUserIds = Object.keys(meetingContext?.realtimeAttendees).map((key: any) => meetingContext?.realtimeAttendees[key]?.userId);
            await dispatch(getCallUserDetails({userIds: newUserIds}));
        };
        fetchUserDetails();
    }, [meetingContext?.realtimeAttendees, dispatch]);

    const onMicButtonClickHandler = useCallback(async () => {
        if (ownVideoTile?.muted) {
            await meetingContext.unmuteAudioInput();
            sendActivityToAPI("MIC_ON");
        } else {
            await meetingContext.muteAudioInput();
            sendActivityToAPI("MIC_OFF");
        }
    }, [ownVideoTile?.muted]);

    const onVideoButtonClickHandler = useCallback(async () => {
        if (ownVideoTile?.videoTileState?.active) {
            await meetingContext.stopVideoInput();
            sendActivityToAPI("CAMERA_OFF");
        } else {
            await meetingContext.startVideoInput();
            sendActivityToAPI("CAMERA_ON");
        }
    }, [ownVideoTile?.videoTileState?.active]);

    const handleScroll = (direction: "up" | "down") => {
        if (scrollContainerRef.current) {
            const scrollAmount = 320; // Set the amount to scroll
            scrollContainerRef.current.scrollBy({
                top: direction === "up" ? -scrollAmount : scrollAmount,
                behavior: "smooth",
            });
        }
    };

    const handleCallEnd = useCallback(async () => {
        if (groupCallState?.userAccountDetails?._id === groupCallState?.callData?.meetingHostId) {
            const meetingDetail = {
                meeting: groupCallState?.callData,
            };
            meetingContext.handleCallEnd(meetingDetail);
            meetingContext.leaveMeeting();
        } else {
            sendActivityToAPI("USER_LEFT");
            meetingContext.leaveMeeting();
        }
    }, []);

    return (
        <Stack
            sx={{
                bgcolor: "white",
                width: "250px",
                borderRadius: "5px",
                overflow: "hidden",
                boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            }}
        >
            {/* ---- fixed header here ---- */}
            <Stack
                sx={{
                    height: "50px",
                    flexShrink: 0,
                    boxSizing: "border-box",
                    padding: "0px 5px",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: "8px",
                }}
            >
                <Stack
                    sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                    }}
                >
                    <IconButton onClick={() => setIsExpended((prev) => !prev)}>
                        <MinimizeIcon w={15}/>
                    </IconButton>

                    <Stack
                        sx={{
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "3px",
                        }}
                    >
                        <MessageAvatarChip
                            avatarSize={25}
                            userMsgStatus="active"
                            avatarApiData="https://randomuser.me/api/portraits/men/86.jpg"
                            //   avatarApiData="#FF5733|pS"
                        />
                        <Box
                            component={"img"}
                            src={StreamIconBearishOSIconPng}
                            alt="logo"
                            sx={{height: "17px", width: "17px"}}
                        />
                        <Typography
                            children="Tina Applesmith Tina Applesmith"
                            sx={{
                                font: "normal normal 400 13px/16px Source Serif Pro",
                                maxWidth: "125px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        />
                    </Stack>
                </Stack>

                <IconButton>
                    <ArrowUpIcon/>
                </IconButton>
            </Stack>
            {/* -------------Header end here -------------- */}
            {isExpended && (
                <Stack
                    sx={{
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    {totalUserInCall > 2 && (
                        <Button
                            sx={{
                                color: "#3C9C9C9",
                                width: "220px",
                                height: "25px",
                                bgcolor: "#C9C9C9",
                                ":hover": {bgcolor: adjustHexColor("#C9C9C9", 125)},
                            }}
                            onClick={() => handleScroll("up")}
                        >
                            <PlayArrowIcon direction="up" color="#fff" w={18}/>
                        </Button>
                    )}
                    <Stack
                        ref={scrollContainerRef}
                        sx={{
                            maxHeight: "310px",
                            overflow: "auto",
                            scrollbarWidth: "none",
                            gap: "10px",
                        }}
                    >
                        {videoTiles.map((tile: any, i: any) => {
                            return (
                                <UserPreviewInCall
                                    {...tile}
                                    aspectRatio={1}
                                    key={i}
                                    showIcon={true}
                                    isMicOff
                                    showNameInBotttom
                                />
                            )
                        })}
                    </Stack>

                    {totalUserInCall > 2 && (
                        <Button
                            sx={{
                                color: "#3C9C9C9",
                                width: "220px",
                                height: "25px",
                                bgcolor: "#C9C9C9",
                                ":hover": {bgcolor: adjustHexColor("#C9C9C9", 125)},
                            }}
                            onClick={() => handleScroll("down")}
                        >
                            <PlayArrowIcon direction="down" color="#fff" w={18}/>
                        </Button>
                    )}

                    <Stack
                        sx={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "20px",
                            mb: "15px",
                        }}
                    >
                        <MicButton
                            initialStatus={ownVideoTile?.muted}
                            onClickChange={onMicButtonClickHandler}
                        />
                        <EndCallButton onClick={handleCallEnd} btnSize="small"/>
                        <VideoButton
                            initialStatus={ownVideoTile?.videoTileState?.active}
                            onClickChange={onVideoButtonClickHandler}
                        />
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};

export default MiniProgressCallPopup;
