// TaskInterface/TaskInterface.tsx
import React, { useEffect, useState } from "react";
import { Container, Box, Stack } from "@mui/material";
import { AddViewMenu, TabContent, TabView } from "./core-component";
import { availableViews } from "./TaskInterfaceType";
import { useDispatch } from "react-redux";
import { getAllTask_function } from "./utils.workspace";

interface TaskInterfaceProps {
    stateOfRightBar?: any;
}

const TaskInterface: React.FC<TaskInterfaceProps> = ({ stateOfRightBar }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState({
        initialLoader: false,
        refreshers: false,
    });

    const [activeTab, setActiveTab] = useState<string>("Overview");
    const [availableTabs, setAvailableTabs] = useState([
        { label: "Overview" },
        { label: "Board" },
    ]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAddView = (viewLabel: string) => {
        if (!availableTabs.some((tab) => tab.label === viewLabel)) {
            setAvailableTabs((prevTabs) => [...prevTabs, { label: viewLabel }]);
        }
        handleMenuClose();
    };

    /* --------- API CALLS -------------- */

    const handleTaskFetch = async (isInitialLoad: boolean) => {
        await getAllTask_function(dispatch, setLoading, isInitialLoad);
    };

    useEffect(() => {
        handleTaskFetch(true);
        // ? If you are confident that the current implementation is correct
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [dispatch]);

    /* --------- API CALLS -------------- */

    return (
        <Container
            sx={{
                maxWidth: "none !important", // Force the maxWidth override
                marginRight: "unset",
                marginLeft: "unset",
                "&.css-cz1h7t-MuiContainer-root": {
                    maxWidth: "none !important", // Override the specific class if it's applied
                    marginRight: "unset",
                    marginLeft: "unset",
                },
            }}
            disableGutters
        >
            <Box width="100%">
                <Stack sx={{ flex: 1 }}>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                        gap={2}
                    >
                        {/* Tab View */}
                        <TabView
                            activeTab={activeTab}
                            availableTabs={availableTabs}
                            handleTabChange={handleTabChange}
                        />

                        {/* Add View Menu */}
                        <AddViewMenu
                            availableViews={availableViews}
                            handleAddView={handleAddView}
                            anchorEl={anchorEl}
                            handleMenuOpen={handleMenuOpen}
                            handleMenuClose={handleMenuClose}
                        />
                    </Stack>

                    <Stack
                        maxWidth={stateOfRightBar ? "calc(100% - -12%)" : "100%"}
                        sx={{
                            overflowX: "scroll", // Horizontal scrolling enabled
                            overflowY: "hidden", // No vertical scrolling
                            "&::-webkit-scrollbar": {
                                display: "none", // Hide the scrollbar
                            },
                        }}
                    >
                        <TabContent
                            stateOfRightBar={stateOfRightBar}
                            loading={loading}
                            activeTab={activeTab}
                        />
                    </Stack>
                </Stack>
            </Box>
        </Container>
    );
};

export default React.memo(TaskInterface);
