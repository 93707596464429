import {Stack, Typography} from "@mui/material";
import React from "react";

import styles from "./ItemInsightsAccessCard.module.css";
import {useDispatch} from "react-redux";
import {changeUserRole} from "../../../../../redux/Slice/message/messageActions";
import {AvatarNormal, InternalShareButtonOptionValue} from "../../../../common";
import {InternalSharedButton} from "../../../../common/Buttons/InternalSharedButton";
import moment from "moment-timezone";

interface ItemInsightsAccessCardForChatProps {
    userNameOrEmail: string;
    userIconSrc?: string;
    timestamp: string;
    userRole?: any;
    channelDetails?: any;
    userId?: any;
    currentChannelId?: any
}

const ItemInsightsAccessCardForChat: React.FC<ItemInsightsAccessCardForChatProps> = ({
                                                                                         timestamp,
                                                                                         userNameOrEmail,
                                                                                         userIconSrc,
                                                                                         userRole,
                                                                                         userId,
                                                                                         currentChannelId,
                                                                                         channelDetails
                                                                                     }) => {
    const dispatch = useDispatch();
    const handleChangeUserRoles = async (value: string) => {
        if (value) {
            const payload = {
                channelId: currentChannelId,
                userId: userId,
                userRole: value.toUpperCase()
            }
            const response:any  = await dispatch(changeUserRole(payload))
            if (response.success) {
                const updatedParticipant = response?.data?.updatedParticipant;
                const updatedChannelDetails = {
                    ...channelDetails,
                    participants: channelDetails?.participants.map((participant: any) =>
                        participant?.userId === updatedParticipant?.userId
                            ? { ...participant, role: updatedParticipant?.role }
                            : participant
                    ),
                };
            }
        }
    };

    return (
        <Stack className={styles["item-insights-access-card"]}>
            <Stack className={styles["item-insights-access-card__details"]}>
                <AvatarNormal
                    size={40}
                    username={userNameOrEmail}
                    imgSrc={userIconSrc}
                />
                <Stack className={styles["item-insights-access-card__text-container"]}>
                    <Typography className={styles["item-insights-access-card__username"]}>
                        {userNameOrEmail}
                    </Typography>
                    <Typography
                        className={styles["item-insights-access-card__timestamp"]}
                    >
                        {moment(timestamp).format('MMMM D, YYYY')} at {moment(timestamp).format('h:mm a')}
                    </Typography>
                </Stack>
            </Stack>
            <InternalSharedButton
                selectedOption={InternalShareButtonOptionValue.Guest}
                onSelectionChange={(value: string) => handleChangeUserRoles(value)}
                userRole={userRole}
                channelDetails={channelDetails}
            />
        </Stack>
    );
};

export default ItemInsightsAccessCardForChat;
