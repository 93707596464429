import { InputBase, Stack, ClickAwayListener } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { SmallThinButton } from "../Buttons";
import { SmallThinButtonColor } from "../Buttons/AllButtonProps";
import { AvatarNamePlateSmall } from "../AvatarNamePlateSmall";
import { InviteBarUserListInterface } from "./InviteBarTypes";
import styles from "./InviteBar.module.css";

interface InviteBarProps {
  option: InviteBarUserListInterface[];
}

const InviteBar: React.FC<InviteBarProps> = ({ option }) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setIsDropdownOpen(true);
    setHighlightedIndex(-1); // Reset the highlighted index when input changes
  };

  const handleOptionClick = (user: InviteBarUserListInterface) => {
    // Handle user selection logic here
    setInputValue(user.userName);
    setIsDropdownOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!isDropdownOpen || filteredOptions.length === 0) return;

    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0
        );
        break;
      case "ArrowUp":
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1
        );
        break;
      case "Enter":
        event.preventDefault();
        if (
          highlightedIndex >= 0 &&
          highlightedIndex < filteredOptions.length
        ) {
          handleOptionClick(filteredOptions[highlightedIndex]);
        }
        break;
      case "Escape":
        setIsDropdownOpen(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (dropdownRef.current && highlightedIndex >= 0) {
      const highlightedOption = dropdownRef.current.children[
        highlightedIndex
      ] as HTMLElement;
      highlightedOption.scrollIntoView({ block: "nearest" });
    }
  }, [highlightedIndex]);

  const filteredOptions = option.filter((user: any) =>
    user?.userName?.toLowerCase()?.includes(inputValue?.toLowerCase())
  );

  return (
    <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
      <Stack className={styles.inviteBar}>
        <Stack className={styles.inviteBar__inputContainer}>
          <InputBase
            className={styles.inviteBar__input}
            placeholder="Search users"
            value={inputValue}
            onChange={handleInputChange}
            onClick={() => setIsDropdownOpen(true)}
            onKeyDown={handleKeyDown}
          />
          <SmallThinButton
            colorVarient={SmallThinButtonColor.CalendarMarronPink}
            label="Invite"
          />
        </Stack>
        {isDropdownOpen && filteredOptions?.length > 0 && (
          <Stack className={styles.inviteBar__dropdown} ref={dropdownRef}>
            {filteredOptions?.map((user, index) => (
              <Stack
                key={user.userName}
                className={`${styles.inviteBar__item} ${
                  index === highlightedIndex
                    ? styles.inviteBar__itemHighlighted
                    : ""
                }`}
                onClick={() => handleOptionClick(user)}
              >
                <AvatarNamePlateSmall
                  department={user?.department}
                  size="default"
                  teamName={user?.teamName}
                  userName={user?.userName}
                  avatarColor={user?.avatarColor}
                  imgSrc={user?.imgSrc || ""}
                />
                <SmallThinButton
                  colorVarient={SmallThinButtonColor.CalendarMarronPink}
                  label="Invite"
                />
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
    </ClickAwayListener>
  );
};

export default InviteBar;
