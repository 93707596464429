import { Stack, Typography } from "@mui/material";
import React from "react";
import ActionInfoView from "../ActionInfoView/ActionInfoView";
import {
  BalancesIcon,
  CertificatesIcon,
  ShiftIcon,
  SwapHorizontalIcon,
} from "../../../../../../../images";

interface QuickActionsViewProps {}

const QuickActionsView: React.FC<QuickActionsViewProps> = ({}) => {
  return (
    <Stack sx={{ gap: "10px" }}>
      <Typography sx={{ font: "normal normal 900 13px/16px Source Serif Pro" }}>
        Quick Actions
      </Typography>

      <ActionInfoView icon={BalancesIcon} label="Request Time Off" />
      <ActionInfoView
        icon={SwapHorizontalIcon}
        label="Swap Shifts"
        countValue={1}
      />
      <ActionInfoView icon={ShiftIcon} label="Schedule Preferences " />
      <ActionInfoView icon={CertificatesIcon} label="Manage Skills" />
    </Stack>
  );
};

export default QuickActionsView;
