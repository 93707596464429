import React from "react";
import {
  TextNormalDropdown,
  TextNormalDropdownOptionsType,
} from "../../../../common";
import { UserTypeEnum } from "../../../Settings/Components/AdminConsolePage/AdminConsolePageType";
import { Link, Stack } from "@mui/material";

interface VisibilityDropdownProps {}

const visibilityDropDownOptions: TextNormalDropdownOptionsType[] = [
  { _id: "1", title: "Admin", value: UserTypeEnum.ADMIN },
  { _id: "2", title: "Managers", value: UserTypeEnum.MANAGER },
  { _id: "3", title: "Team Leads", value: UserTypeEnum.TEAM_LEAD },
  { _id: "4", title: "Users", value: UserTypeEnum.USER },
  { _id: "5", title: "Guests", value: UserTypeEnum.GUEST },
  { _id: "6", title: "Auditors", value: UserTypeEnum.AUDITOR },
];

const VisibilityDropdown: React.FC<VisibilityDropdownProps> = ({}) => {
  return (
    <TextNormalDropdown
      placeholder="Select visibility..."
      //   initialValue={visibilityDropDownOptions[2]}
      options={visibilityDropDownOptions}
      onSelect={(value) => console.log(value)}
      menuHeight="300px"
      additionalOption={
        <Stack
          sx={{
            font: "normal italic 300 12px/15px Source Serif Pro",
            color: "#0027FF",
            display: "block",
          }}
        >
          Visibility of the shift will be locked to the selected Bearish OS user
          types above.{" "}
          <Link
            underline="hover"
            sx={{
              cursor: "pointer",
              color: "inherit",
              display: "inline-block",
            }}
          >
            Go to Settings.
          </Link>
        </Stack>
      }
    />
  );
};

export default VisibilityDropdown;
