import { EmailState } from "../emailTypes";
import { PayloadAction } from "@reduxjs/toolkit";

export const getAllEmails__Failure = (
    state: EmailState,
    action: PayloadAction<string>
) => {
    state.is_allEmail_fetching = false;
    state.emails = {
        data: [], // Reset the email list on failure
        api_call_timeStamp: new Date().toISOString(), // Record the time of the API call
        is_api_called: false, // Set the flag to false
        error: action.payload, // Store the error message
    };
};

export const getMainSettings__Failure = (
    state: EmailState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};
export const updateMainSettings__Failure = (
    state: EmailState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

export const deleteEmail__Failure = (
    state: EmailState,
    action: PayloadAction<string>
) => {
    // state.emails = action.payload;
    console.log("in deleteEmail__Failure");
};
