import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import { XCircleIcon } from "../../../../../images";
import { SmallPinnedCard } from "../../../SmallPinnedCard";
import styles from "./ReplyContainer.module.css";

interface ReplyContainerProps {
  replyType: "task" | "videoMeeting" | "eventSchedule" | "MessageReply";
  onCloseClick: () => void;
  userName: string;
  userImgSrc?: string;
  userStatus?: boolean;
  userAvatarColor?: string;
  dateAndTimeComponents: React.ReactNode;
  infoComponents: React.ReactNode;
  titleActionButton?: React.ReactNode;
  forViewOnly?: boolean;
}

const ReplyContainer: React.FC<ReplyContainerProps> = ({
  dateAndTimeComponents,
  infoComponents,
  onCloseClick = () => {},
  replyType,
  userImgSrc,
  userName,
  userAvatarColor,
  userStatus,
  titleActionButton,
                                                         forViewOnly
}) => {
  const getProperty = (): {
    highlightColor: string;
    cardtitle: string;
    cardSubtitle: string;
  } => {
    switch (replyType) {
      case "task":
        return {
          highlightColor: "#A53A01",
          cardtitle: "Assigning Task",
          cardSubtitle:
            "Sending this message will assign the task as listed below",
        };
      case "videoMeeting":
        return {
          highlightColor: "#FF8181",
          cardtitle: "Schedule a Video Meeting",
          cardSubtitle:
            "Sending this message will create a call and invite this user",
        };
      case "eventSchedule":
        return {
          highlightColor: "#B5005B",
          cardtitle: "Schedule an Event",
          cardSubtitle:
            "Sending this message will create an event and invite this user",
        };
      case "MessageReply":
        return {
          highlightColor: "#775EE2",
          cardtitle: "Replying to...",
          cardSubtitle:
              "",
        };
    }

    return { cardSubtitle: "", cardtitle: "", highlightColor: "" };
  };

  return (
    <Stack className={styles["reply-container"]}>
      <Stack
        className={styles["reply-container__sidebar"]}
        style={{
          backgroundColor: adjustHexColor(getProperty().highlightColor, 25),
        }}
      />
      <Stack className={styles["reply-container__content"]} sx={{ overflow: "hidden", width: "100%"}}>
        {/* Reply type and cancel button */}
        <Stack className={styles["reply-container__header"]}>
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}
          >
            <Typography className={styles["reply-container__header-title"]}>
              {getProperty().cardtitle}
            </Typography>

            {titleActionButton}

            {/* <TextLinkButton
              sx={{ font: "normal italic 600 11px/14px Source Serif Pro" }}
              buttonLabel="Convert to video call"
            /> */}
          </Stack>
          {!forViewOnly && (<IconButton
              onClick={onCloseClick}
              className={styles["reply-container__cancel-button"]}
          >
            <XCircleIcon color="red" w={18} />
          </IconButton>)}

        </Stack>

        {/* Body */}
        <Stack className={styles["reply-container__body"]}>
          <SmallPinnedCard
            size={30}
            isActive={userStatus}
            userAvatarColor={userAvatarColor}
            userName={userName}
            userIconSrc={userImgSrc}
          />

          <Stack className={styles["reply-container__info-group"]}>
            <Stack className={styles["reply-container__info-row"]}>
              {dateAndTimeComponents}
              <Typography className={styles["reply-container__italic-text"]}>
                {getProperty().cardSubtitle}
              </Typography>
            </Stack>

            <Stack>{infoComponents}</Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ReplyContainer;
