import React, { useState, useEffect } from "react";
import { Box, Stack, IconButton, Typography, Paper, Drawer } from "@mui/material";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { ChecklistItemPM } from "../../../components";
import { PlayArrowIcon } from "../../../../../../images";
import Smallheader from "./Smallheader";
import { useSearchParams } from "react-router-dom";
import { updateOneTask__api, UpdateTaskPayload } from "../../../../../../redux";
import { useDispatch } from "react-redux";
import { formatDate } from "../../../../../../Utils";
import NoTask from "../NoTask";
import { ObjectiveAndTaskLargeModel } from "../../../components/ObjectiveAndTaskLargeModel";
const colorMapping: { [key: string]: { [value: string]: string } } = {
  status: {
    "To Do": "#808080",
    "In Progress": "#FFA500",
    Delayed: "#FF0000",
    Done: "#008000",
  },
  priority: {
    Low: "#00FF00",
    Medium: "#FFFF00",
    High: "#FFA500",
    Critical: "#FF0000",
    Urgent: "#FF4500",
  },
  // Add mappings for other filter keys like assignedTo, dueDate, taskType, etc.
};

// Map the filterKey to the correct keys in `Item`
const filterKeyMap: Record<string, keyof Item> = {
  Status: "status",
  Priority: "priority",
  Assignedto: "assignedTo", // Correct key is "assignedTo"
  DueDate: "dueDate", // Correct key is "dueDate"
  Tasktype: "taskType", // Correct key is "taskType"
};

interface DragAndDropColumnsProps {
  filterKey?: "Status" | "Priority" | "Assignedto" | "DueDate" | "Tasktype";
  workspaceItemData?: any;
  taskWorkspaceItemData?: any;
  setTaskWorkspaceItemData?: any;
  fetchComponentData?: any;
}
interface userList {
  _id: string;
  firstName: string;
  lastName: string;
  avatar: string;
}
// Define the type for individual items
type Item = {
  id: string;
  title: string;
  checked: boolean;
  componentType: "main" | "subTask";
  opened: boolean;
  showDetails?: boolean;
  dataSet?: any;
  priority: { value: string; defaultColorHex: string; customColorHex: string }; // Update type
  status: { value: string; defaultColorHex: string; customColorHex: string }; // Update type
  dueDate: string;
  assignedTo: userList[]; // SHOWN BY _ID
  taskType: string; // Task, Milestone, Bugs, Campaign, Update, Feature, Research, Review, Approval, Documentation and testing
  color: string;
};

// Define the type for droppable areas
type DroppableArea = {
  _id: string;
  items: Item[];
  collapsed?: boolean;
  priority: { value: string; defaultColorHex: string; customColorHex: string }; // Update type
  dueDate: string;
  status: { value: string; defaultColorHex: string; customColorHex: string }; // Use status object directly
};

const CheckListOverView: React.FC<DragAndDropColumnsProps> = ({
  taskWorkspaceItemData,workspaceItemData
}) => {
  const dispatch = useDispatch();
  const [droppableAreas, setDroppableAreas] = useState<DroppableArea[]>([]);

  const [filterKey, setFilterKey] = React.useState<string>("Status");

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const newFilterKey = searchParams?.get("filterkey");
    setFilterKey(newFilterKey || "Status");
  }, [searchParams]);

  useEffect(() => {
    if (taskWorkspaceItemData && taskWorkspaceItemData?.length > 0) {
      // Map taskWorkspaceItemData to the droppable areas format
      const initialAreas = taskWorkspaceItemData?.map(
        (group: any, index: number) => ({
          _id: `droppable-${index}`,
          status: group?.tasks?.[0]?.status || {
            value: "Unknown",
            defaultColorHex: "#808080",
            customColorHex: "#808080",
          }, // Use status object or default
          items: group?.tasks?.map((task: any) => ({
            id: task?._id,
            title: task?.name,
            checked: false, // Assuming checklist items start unchecked
            componentType: "main",
            opened: false, // Assuming items are initially closed
            priority: task?.priority, // Keeping the object
            status: task?.status, // Keeping the object
            dueDate: task?.dueDate,
            assignedTo: task?.assignedUsers.map((user: any) => ({
              _id: user?._id,
              firstName: user?.firstName,
              lastName: user?.lastName,
              avatar: user?.avatar,
            })),
            taskType: task?.taskType,
            color: task?.cardColor || "#808080", // Use cardColor or default
            dataSet: task,
          })),
          collapsed: false, // Initialize collapsed to false
        })
      );

      setDroppableAreas(initialAreas);

      const validFilterKey = filterKeyMap[filterKey] || "Status";

      const newStacks = groupTasksByFilterKey(validFilterKey, initialAreas);
      setDroppableAreas(newStacks); // Update stacks based on the filtered key
    } else {
      setDroppableAreas([]);
    }
  }, [taskWorkspaceItemData]);

  useEffect(() => {
    if (droppableAreas?.length > 0) {
      const validFilterKey = filterKeyMap[filterKey] || "Status";

      const newStacks = groupTasksByFilterKey(validFilterKey, droppableAreas);
      setDroppableAreas(newStacks); // Update stacks based on the filtered key
    }
  }, [filterKey]); // Trigger whenever `filterKey` changes

  const getColorForGroup = (
    filterKey: keyof Item,
    groupValue: string,
    tasksInGroup: Item[]
  ): string => {
    const defaultDarkColor = "#333333"; // Dark color as a fallback

    // Check if any task in the group has a custom color
    const customColor = tasksInGroup?.find((task) => task.color)?.color;
    if (customColor) {
      return customColor; // Use custom color if found
    }

    // Check if filter type matches the group type, otherwise use a dark color
    const matchingColor = colorMapping?.[filterKey]?.[groupValue];
    return matchingColor ? matchingColor : defaultDarkColor;
  };

  const getGroupKey = (value: any): string => {
    if (Array.isArray(value)) {
      return value?.map((user: userList) => user?._id)?.join(",");
    }
    return String(value);
  };

  // Update function similar to updateStackData
  const updateDroppableAreaData = async (
    taskId: string,
    field: keyof Item | "r" | "customElements",
    value: any
  ) => {
    const updatedAreas = droppableAreas?.map((area: any) => {
      const updatedItems = area?.items?.map((task: any) => {
        if (task?.id === taskId) {
          let updatedTask = { ...task };

          // Use a switch statement to handle different field updates
          switch (field) {
            case "title":
              updatedTask.title = value;
              break;
            case "checked":
              updatedTask.checked = value;
              break;
            case "priority":
              updatedTask.priority = {
                ...updatedTask.priority,
                ...value, // Assuming value is an object containing new priority properties
              };
              break;
            case "status":
              updatedTask.status = {
                ...updatedTask.status,
                ...value, // Assuming value is an object containing new status properties
              };
              break;
            case "dueDate":
              updatedTask.dueDate = value; // Assuming value is a date string
              break;
            case "assignedTo":
              updatedTask.assignedTo = value?.map((user: any) => ({ ...user })); // Assuming value is an array of user objects
              break;
            case "taskType":
              updatedTask.taskType = value;
              break;
            case "color":
              updatedTask.color = value;
              break;

            case "customElements":
              // Handle customElements updates
              if (updatedTask?.dataSet) {
                const newDataSet = { ...updatedTask.dataSet };

                // Ensure customElements exists, or create a deep copy of it
                newDataSet.customElements = { ...newDataSet.customElements };

                // Update or add the specific fields in customElements
                Object?.keys(value)?.forEach((key) => {
                  if (
                    value?.[key] !== undefined &&
                    value?.[key] !== null &&
                    value?.[key] !== ""
                  ) {
                    // Only update if value is not empty, null, or undefined
                    newDataSet.customElements[key] = value?.[key];
                  }
                });

                // Update the task's dataSet with the new customElements
                updatedTask.dataSet = newDataSet;
              }
              break;

            default:
              break;
          }

          // Call handleUpdateTask with the updated task
          handleUpdateTask(updatedTask);

          return updatedTask;
        }
        return task;
      });

      return { ...area, items: updatedItems };
    });

    // Skip updating state if the field is "r"
    if (field === "r") {
      return;
    }

    // Update the droppable areas state with the modified areas
    setDroppableAreas(updatedAreas);
  };

  const handleUpdateTask = async (task: UpdateTaskPayload) => {
    const payload = getUpdatedTaskPayloads([task])?.[0]; // Prepare payload using the helper function

    try {
      const action = updateOneTask__api(payload?.taskId, payload);
      await dispatch(action);
      // await fetchComponentData();
    } catch (error) {
      console.error("Failed to update task:", error);
    }
  };

  const getUpdatedTaskPayloads = (stack: Array<any>): UpdateTaskPayload[] => {
    return stack?.map((task) => {
      // Extract estimated time in minutes
      const estimatedTime = task?.estimatedTime
        ? task?.estimatedTime?.hours * 60 + task?.estimatedTime?.minutes
        : undefined;

      // Extract and filter unique checklists from dataSet
      const checklists = task?.dataSet?.checklists
        ? task?.dataSet?.checklists
            ?.filter(
              (checklist: any, index: number, self: Array<any>) =>
                // Filter out duplicate checklists by name
                index ===
                self?.findIndex((cl: any) => cl?.name === checklist?.name)
            )
            ?.map((checklist: any) => ({
              name: checklist?.name,
              // Extract and filter unique items within the checklist
              item: checklist?.items
                ? checklist?.items
                    ?.filter(
                      (item: any, idx: number, arr: Array<any>) =>
                        // Filter out duplicate items by itemName
                        idx ===
                        arr?.findIndex(
                          (i: any) => i?.itemName === item?.itemName
                        )
                    )
                    ?.map((item: any) => ({
                      itemName: item?.itemName,
                      assignee: item?.assignee?._id,
                      isChecked: item?.isChecked,
                    }))
                : [], // Fallback to an empty array if items is undefined
            }))
        : []; // Fallback to an empty array if checklists is undefined
      // Extract custom elements from dataSet, only include fields that have values
      const customElements = task?.dataSet?.customElements
        ? Object?.keys(task?.dataSet?.customElements)?.reduce(
            (acc: any, key: any) => {
              const value = task?.dataSet?.customElements?.[key];
              if (value !== undefined && value !== null && value !== "") {
                acc[key] = value;
              }
              return acc;
            },
            {}
          )
        : undefined;

      // Construct the UpdateTaskPayload for the current task
      const updatePayload: UpdateTaskPayload = {
        taskId: task?.id,
        name: task?.title,
        details: task?.detail || "",
        assignedUserIds: task?.assignedTo?.map((user: any) => user?._id),
        priority: task?.priority
          ? {
              id: task?.priority?.id,
              value: task?.priority?.value,
              defaultColorHex: task?.priority?.defaultColorHex,
              customColorHex: task?.priority?.customColorHex,
            }
          : undefined,
        dueDate: task?.dueDate,
        status: task?.status
          ? {
              id: task?.status?.id,
              value: task?.status?.value,
              defaultColorHex: task?.status?.defaultColorHex,
              customColorHex: task?.status?.customColorHex,
            }
          : undefined,
        taskColor: task?.color,
        estimatedTime: estimatedTime,
        taskType: task?.taskType,
        checklists: checklists || [], // Include the filtered checklists in the payload
        // Only include customElements if it's not empty
        ...(customElements && Object?.keys(customElements)?.length > 0
          ? { customElements }
          : {}),
      };

      return updatePayload;
    });
  };

  const groupTasksByFilterKey = (
    key: keyof Item,
    droppableAreas: DroppableArea[]
  ): DroppableArea[] => {
    const groupedStacks: { [key: string]: Item[] } = {};

    droppableAreas?.forEach((area) => {
      area?.items?.forEach((task) => {
        let groupByValue;

        // Group by the key, using the value property for status and priority
        if (key === "status" && typeof task?.status === "object") {
          groupByValue = task?.status?.value; // Use the value for status
        } else if (key === "priority" && typeof task?.priority === "object") {
          groupByValue = task?.priority?.value; // Use the value for priority
        } else if (key === "assignedTo") {
          groupByValue =
            task?.assignedTo
              ?.map((user) => `${user?.firstName} ${user?.lastName}`)
              ?.join(", ") || "Unassigned";
        } else if (key === "dueDate") {
          groupByValue = task?.dueDate;
        } else if (key === "taskType") {
          groupByValue = task?.taskType; // Use taskType directly
        } else {
          groupByValue = task?.[key];
        }

        const groupKey = getGroupKey(groupByValue); // Get a valid key for grouping

        if (!groupedStacks?.[groupKey]) {
          groupedStacks[groupKey] = [];
        }
        groupedStacks?.[groupKey]?.push(task);
      });
    });

    return Object?.keys(groupedStacks)?.map((group, index) => ({
      _id: `group-${index}`,
      status:
        key === "status"
          ? groupedStacks?.[group]?.[0]?.status
          : {
              value: group,
              defaultColorHex: getColorForGroup(
                key,
                group,
                groupedStacks?.[group]
              ),
              customColorHex: getColorForGroup(
                key,
                group,
                groupedStacks?.[group]
              ),
            }, // Provide default color properties
      items: groupedStacks?.[group],
      collapsed: false,
      priority:
        key === "priority"
          ? groupedStacks[group]?.[0]?.priority
          : groupedStacks[group]?.[0]?.priority, // Keep priority object
      dueDate: key === "dueDate" ? group : groupedStacks?.[group]?.[0]?.dueDate, // Use first task's dueDate
      taskType:
        key === "taskType" ? group : groupedStacks?.[group]?.[0]?.taskType, // Add taskType
      // Use getColorForGroup to dynamically assign a color based on filterKey and group
      color: getColorForGroup(key, group, groupedStacks?.[group]), // Use the color returned by the function
    }));
  };

  const handleDragEnd = (result: DropResult) => {
    // console.log("Drag ended:", result);
    const { destination, source } = result;

    // If there's no destination or the source and destination are the same, do nothing
    if (
      !destination ||
      (destination?.droppableId === source?.droppableId &&
        destination?.index === source?.index)
    )
      return;

    // Find the source and destination droppable areas
    const sourceDroppable = droppableAreas?.find(
      (area) => area?._id === source?.droppableId
    );
    const destinationDroppable = droppableAreas?.find(
      (area) => area?._id === destination?.droppableId
    );

    // console.log(destinationDroppable, "destinationDroppable");

    if (!sourceDroppable || !destinationDroppable) return;

    // Remove the item from the source droppable area
    const [movedItem] = sourceDroppable?.items?.splice(source?.index, 1);

    const destinationItems = Array?.from(destinationDroppable?.items);

    // Update the moved item based on filterKey
    if (filterKey === "Assignedto") {
      const assignedUserData = destinationItems
        ?.flatMap((item) => item?.assignedTo)
        ?.find((user) => user?._id === destinationDroppable?.status?.value); // Use the name from the destination as reference

      if (assignedUserData) {
        movedItem.assignedTo = [assignedUserData] || []; // Update the assignedTo field with full user data
      } else {
        console.warn("Assigned user data not found in destination column.");
      }
    } else if (filterKey) {
      // For other keys, update the relevant field to the new column's name
      const keyToUpdate = filterKeyMap[filterKey] as keyof Item;
      if (keyToUpdate === "priority" || keyToUpdate === "status") {
        // Update the whole object with the destination's status object
        movedItem[keyToUpdate] = destinationDroppable?.status;
      } else if (keyToUpdate) {
        (movedItem[keyToUpdate] as any) = destinationDroppable?.status?.value;
      }
    }

    // Add the item to the destination droppable area
    destinationDroppable?.items?.splice(destination?.index, 0, movedItem);

    // Update the droppable areas state
    setDroppableAreas(
      droppableAreas?.map((area) =>
        area?._id === source?.droppableId ||
        area._id === destination?.droppableId
          ? area?._id === source?.droppableId
            ? { ...sourceDroppable }
            : { ...destinationDroppable }
          : area
      )
    );

    // Use the update function to sync with the backend
    const keyToUpdate = filterKeyMap[filterKey] as keyof Item;
    if (keyToUpdate) {
      updateDroppableAreaData(movedItem?.id, "r", movedItem?.[keyToUpdate]);
    }

    // console.log("Updated Droppable Areas after drag:", droppableAreas);
  };

  const toggleCollapse = (areaId: string) => {
    // console.log("Toggling collapse for area:", areaId);
    setDroppableAreas((prevAreas) =>
      prevAreas?.map((area) =>
        area?._id === areaId ? { ...area, collapsed: !area?.collapsed } : area
      )
    );
  };


   const [open___open__bar, setOpen___open__bar] = React.useState(false);
    const toggleDrawer = (newOpen: boolean) => () => {
      setOpen___open__bar(newOpen);
    };
    const [objectTaskMode, setObjectTaskMode] = useState<string>("");
    const [objectTaskData, setObjectTaskData] = useState<any>([]);
  
    const handleFullView = (mode?: string, dataStack?: any) => {
      const finalMode = mode || "";
      const data = dataStack || {};
      setObjectTaskData(data);
      setObjectTaskMode(finalMode);
      toggleDrawer(open___open__bar ? false : true)(); // Immediately calls the returned function
    };

  return (
    <>
      <div>
        <Stack>
          <Smallheader />
        </Stack>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Stack direction="column" gap={2} sx={{ width: "100%" }}>
          {droppableAreas && droppableAreas?.length > 0 ? (
            droppableAreas?.map((area) => (
              <Droppable key={area._id} droppableId={area?._id}>
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{
                      borderRadius: 1,
                      p: 1,
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      gap={2}
                    >
                      <IconButton
                        onClick={() => toggleCollapse(area?._id)}
                        sx={{ p: 0, borderRadius: 0 }}
                        disableRipple
                      >
                        <PlayArrowIcon
                          w={15}
                          color={
                            area?.status?.customColorHex ||
                            area?.status?.defaultColorHex ||
                            "black"
                          }
                          direction={area?.collapsed ? "down" : "right"}
                        />
                      </IconButton>
                      <Typography
                        children={
                          // Dynamically display the group name based on the filterKey
                          filterKey === "Assignedto"
                            ? area?.items?.[0]?.assignedTo
                                ?.map(
                                  (user) =>
                                    `${user?.firstName} ${user?.lastName}`
                                )
                                ?.join(", ") || "Unassigned"
                            : filterKey === "DueDate"
                            ? formatDate(area?.items?.[0]?.dueDate) ||
                              "No Due Date"
                            : filterKey === "Priority"
                            ? area?.priority?.value // Access priority value
                            : filterKey === "Status"
                            ? area?.status?.value // Access status value
                            : filterKey === "Tasktype"
                            ? area?.items?.[0]?.taskType || "Unknown Task Type" // Display taskType
                            : "Unknown" // Fallback text if no valid filterKey is found
                        }
                        sx={{
                          fontFamily: "Source Serif Pro",
                          fontSize: "14px",
                          fontWeight: "300",
                          lineHeight: "17.54px",
                          textAlign: "left",
                        }}
                      />

                      <Paper
                        sx={{
                          minWidth: "10px",
                          minHeight: "10px",
                          backgroundColor:
                            area?.status?.customColorHex ||
                            area?.status?.defaultColorHex,
                          borderRadius: "50%",
                          background:
                            area?.status?.customColorHex ||
                            area?.status?.defaultColorHex,
                        }}
                      />
                    </Stack>
                    {!area?.collapsed && (
                      <Stack direction="column" gap={1}>
                        {area?.items?.map((item, index) => (
                          <Draggable
                            key={item?.id}
                            draggableId={item?.id}
                            index={index}
                          >
                            {(provided) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{
                                  borderRadius: 1,
                                  p: 1,
                                  minHeight: 20,
                                }}
                              >
                                <ChecklistItemPM
                                  title={item?.title}
                                  checked={item?.checked}
                                  showDetails={item?.showDetails}
                                  componentType={item?.componentType}
                                  opened={item?.opened}
                                  workspaceItemData={workspaceItemData}
                                  handleFullView={handleFullView}
                                  statusColor={
                                    item?.status?.customColorHex ||
                                    item?.status?.defaultColorHex
                                  } // Use status color
                                  priority={item?.priority}
                                  dueDate={item?.dueDate}
                                  item={item}
                                  updateDroppable={(field: any, value: any) =>
                                    updateDroppableAreaData(
                                      item?.id,
                                      field,
                                      value
                                    )
                                  }
                                />
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Stack>
                    )}
                  </Box>
                )}
              </Droppable>
            ))
          ) : (
            <NoTask />
          )}
        </Stack>
      </DragDropContext>



      <div>
        <React.Fragment>
          <Drawer
            anchor="right" // This makes the drawer open from the right
            open={open___open__bar}
            ModalProps={{ hideBackdrop: true }} // Completely removes the backdrop
            SlideProps={{
              timeout: {
                enter: 200, // Duration in milliseconds for opening
                exit: 200, // Duration in milliseconds for closing
              },
            }}
          >
            <ObjectiveAndTaskLargeModel
              mode={(objectTaskMode as "add" | "view") || "add"}
              toggleDrawer={handleFullView}
              workspaceItemData={workspaceItemData}
              objectTaskData={objectTaskData}
            // updateStackData={"updateStackData"}
            />
          </Drawer>
        </React.Fragment>
      </div>
    </>
  );
};

export default CheckListOverView;
