import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { adjustHexColor } from "../../../../../Utils";

export type HighlightTabsType = {
  tabindex: number;
  translatedTitle: string;
  value: string;
};

interface HighlightTabsProps {
  onChange: (value: HighlightTabsType) => void;
  options: HighlightTabsType[];
}

const HighlightTabs: React.FC<HighlightTabsProps> = ({ onChange, options }) => {
  const [selectedValue, setSelectedValue] = useState<HighlightTabsType>(
    options[0]
  );

  const handleChange = (newValue: HighlightTabsType) => {
    setSelectedValue(newValue);
  };

  useEffect(() => {
    // Trigger onChange callback whenever the selected value changes
    onChange(selectedValue);
  }, [selectedValue]);

  return (
    <Stack
      sx={{
        height: "22px",
        width: "fit-content",
        boxSizing: "border-box",
        flexShrink: 0,
        padding: "0px 6px",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: "5px",
        bgcolor: "rgba(143, 141, 141, 0.10)",
        boxShadow: "0px 5px 5px 0px rgba(143, 141, 141, 0.15) inset",
        font: "normal normal 400 12px/15px Source Serif Pro",
      }}
    >
      {options.map((item, index) => {
        return (
          <Stack
            onClick={() => handleChange(item)}
            key={index}
            sx={{
              height: "18px",
              boxSizing: "border-box",
              padding: "0px 5px",
              flexDirection: "row",
              alignItems: "center",
              borderRadius: "5px",
              bgcolor:
                selectedValue.tabindex === item.tabindex
                  ? "white"
                  : "transparent",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
              ":hover": {
                bgcolor:
                  selectedValue.tabindex !== item.tabindex
                    ? adjustHexColor("#ffffff", 50)
                    : "white",
              },
            }}
          >
            {item.translatedTitle}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default HighlightTabs;
