import React from "react";
import { Stack } from "@mui/material";
import { AttendanceListInterface } from "../../../TimeCardTable";
import { ShiftStatusCard } from "../../common";
import { isToday } from "../../AttendanceTableUtils";

interface StatusViewCardProps {
  rowDate: string;
  attendanceDataArray: AttendanceListInterface[];
}

const StatusViewCard: React.FC<StatusViewCardProps> = ({
  rowDate,
  attendanceDataArray,
}) => {
  const rowDataAsHeader: AttendanceListInterface | undefined =
    attendanceDataArray.find((item) => item.date === rowDate);
  return (
    <Stack
      sx={{
        position: "relative",
        cursor: "pointer",
        height: "fit-content",
      }}
      onClick={() => console.log(rowDate, rowDataAsHeader?.date)}
    >
      {isToday(rowDate) && (
        <Stack
          sx={{
            width: "100%",
            height: "2px",
            borderRadius: "2px",
            bgcolor: "#00767B",
            position: "absolute",
            top: "-5px",
          }}
        />
      )}

      {!rowDataAsHeader ? (
        <ShiftStatusCard cardType="noShift" />
      ) : (
        <ShiftStatusCard
          cardType={rowDataAsHeader.attendanceType}
          startTime={
            rowDataAsHeader.workStartTime || rowDataAsHeader.shiftStartTime
          }
          endTime={rowDataAsHeader.workStopTime || rowDataAsHeader.shiftEndTime}
        />
      )}
    </Stack>
  );
};

export default StatusViewCard;
