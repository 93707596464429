import {Box, Dialog, Stack, Typography} from "@mui/material";
import AgendaLeftSideBar from "../components/AgendaLeftSideBar";
import CenterVideoTile from "../CenterVideoTile";
import {ConferenceRoomHeader, InCallGuestMenu, PrePostMeetingNotes} from "../components";
import VideoTiles from "../VideoTiles";
import {BlackLongArrowButton} from "../components/BlackLongArrowButton";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {MeetingContext} from "../../../Povider";
import {RootState} from "../../../../redux/store";
import {getUserBasicDetails} from "../../../../redux";
import {VideoCenterState} from "../../../../redux/Slice/videoCenter/VideoCenterTypes";
import Logo from "../../../../images/other/topNavbar/BearishOSNewLogo.png";
import {useTranslation} from "react-i18next";

const ConferenceRoom = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const meetingContext: any = useContext(MeetingContext);
    const {t} = useTranslation()
    const {onGoingMeeting} = useSelector(
        (state: RootState) => state.videoCenter as VideoCenterState
    );

    const [openPopup, setOpenPopup] = useState(false);
    const [showAllTiles, setShowAllTiles] = useState(false);
    const [isWorkSpaceMode, setIsWorkSpaceMode] = useState(false);

    const toggleTiles = () => {
        setShowAllTiles(!showAllTiles);
    };

    const videoTiles = useMemo(() => {
        const sorted = meetingContext.VideoPreferences.sortVideoTiles(
            Object.values(meetingContext.realtimeAttendees)
        );
        return sorted;
    }, [meetingContext.VideoPreferences]);

    const ownVideoTile = useMemo(
        () =>
            videoTiles.find(
                ({userId, chimeAttendeeId}: any) =>
                    onGoingMeeting?.userAccountDetails?._id === userId && !chimeAttendeeId?.includes("#content")
            ),
        [onGoingMeeting?.userAccountDetails?._id, videoTiles]
    );

    const participants = videoTiles.map((attendee: any) => {
        const isCurrentUser = attendee?.userId === onGoingMeeting?.userAccountDetails?._id;
        const isContentShare = attendee?.chimeAttendeeId?.includes("#content");
        return {
            ...attendee,
            isMicOn: !attendee?.muted,
            isVideoOn: attendee?.videoTileState?.boundVideoStream?.active,
            userName:
                !isContentShare && isCurrentUser
                    ? `${onGoingMeeting?.realTimeMeetingAttendee?.[attendee.userId]?.firstName} ${
                        onGoingMeeting?.realTimeMeetingAttendee?.[attendee.userId]?.lastName
                    } (You)`
                    : `${onGoingMeeting?.realTimeMeetingAttendee?.[attendee.userId]?.firstName} ${
                        onGoingMeeting?.realTimeMeetingAttendee?.[attendee.userId]?.lastName
                    }`.trim(),
            userEmail: onGoingMeeting?.realTimeMeetingAttendee?.[attendee.userId]?.email,
            avatar: onGoingMeeting?.realTimeMeetingAttendee?.[attendee.userId]?.avatar,
        };
    });

    const ownContentTile = useMemo(
        () =>
            videoTiles.find(
                ({userId, chimeAttendeeId}: any) =>
                    onGoingMeeting?.userAccountDetails?._id === userId && chimeAttendeeId?.includes("#content")
            ),
        [onGoingMeeting?.userAccountDetails?._id, videoTiles]
    );


    const handleClickOnclose = () => {
        setOpenPopup(false);
    };

    const handelSetWorkSpaceMode = () => {
        setIsWorkSpaceMode(!isWorkSpaceMode);
    }

    useEffect(() => {
        const fetchUserDetails = async () => {
            if (!Object.keys(meetingContext?.realtimeAttendees) || Object.keys(meetingContext?.realtimeAttendees).length === 0) {
                return;
            }
            const newUserIds = Object.keys(meetingContext?.realtimeAttendees).map((key: any) => meetingContext?.realtimeAttendees[key]?.userId);
            await dispatch(getUserBasicDetails({userIds: newUserIds}));
        };
        fetchUserDetails();
    }, [meetingContext?.realtimeAttendees, dispatch]);

    useEffect(() => {
        if (onGoingMeeting?.isMeetingEnded === true) {
            setOpenPopup(true);
            setTimeout(() => {
                navigate("/Calls");
            }, 3000);
        }
    }, [onGoingMeeting?.isMeetingEnded]);

    return (
        <Stack
            sx={{
                flexDirection: "row",
                width: "100%",
            }}
        >
            <Box
                sx={{
                    flex: 1.5,
                    backgroundColor: "#fcfdfd", // Add background color
                    padding: "8px 16px 16px 16px", // Add padding for spacing
                }}
            >
                {/* Left Section */}
                <Stack
                    sx={{
                        textAlign: "start",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "12px", // Adjust spacing between elements
                        paddingLeft: "10px", // Adjust padding for spacing
                    }}
                >
                    <Box
                        component={"img"}
                        src={Logo}
                        sx={{
                            height: "30px", // Match logo size as per UI
                            width: "30px",
                            marginRight: "8px",
                            borderRadius: "50%", // Ensures circular logo
                        }}
                        alt="logo"
                    />
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "20.05px",
                            fontFamily: "Source Serif Pro",
                            color: "#000000", // Adjust color to match UI
                        }}
                    >
                        {onGoingMeeting?.meeting?.meetingType === "INSTANT"
                            ? t("VIDEO_CENTER.MEETING.INSTANT_MEETING")
                            : onGoingMeeting?.meetingsDetails?.title}
                    </Typography>
                </Stack>

                {/* Agenda Sidebar */}
                <Box sx={{marginTop: "6px"}}> {/* Space between header and sidebar */}
                    <AgendaLeftSideBar/>
                </Box>
            </Box>
            <Stack sx={{flex: 8.5, flexDirection: "column"}}>
                <ConferenceRoomHeader handelSetWorkSpaceMode={handelSetWorkSpaceMode}/>
                <Stack
                    p={2}
                    sx={{
                        flexGrow: 1,
                        flexDirection: "row",
                        gap: 4,
                        padding: "0",
                        height: "100vh",
                    }}
                >
                    <Dialog
                        onClose={handleClickOnclose}
                        open={openPopup}
                        PaperProps={{
                            sx: {
                                borderRadius: "12px",
                                padding: "24px",
                                maxWidth: "400px",
                                textAlign: "center",
                            },
                        }}
                    >
                        <Box sx={{textAlign: "center", padding: "16px"}}>
                            <Typography
                                variant="h6"
                                sx={{fontWeight: "bold", marginBottom: "8px"}}
                            >
                                Meeting Ended
                            </Typography>
                            <Typography variant="body1" sx={{color: "#757575"}}>
                                The meeting has ended. Thank you for participating.
                            </Typography>
                        </Box>
                    </Dialog>

                    <Stack flex={9} sx={{flexDirection: "column", gap: 1}}>
                        <Stack
                            sx={{
                                flexDirection: "row",
                                gap: 2,
                                marginLeft: 4.1,
                                marginRight: 2.3,
                            }}
                        >
                            <Stack flex={2.5} justifyContent={isWorkSpaceMode ? "space-between" : undefined}>
                                {!isWorkSpaceMode ? (
                                    <CenterVideoTile
                                        videoTile={videoTiles?.[0]}
                                        ownVideoTile={ownVideoTile}
                                        ownContentTile={ownContentTile}
                                        meetingDetails={onGoingMeeting.meeting}
                                        videoTiledata={videoTiles}
                                        userId={onGoingMeeting?.userAccountDetails?._id}
                                        newcss={false}
                                    />
                                ) : (
                                    <div style={{height: "200px"}}>

                                    </div>
                                )}
                                <Box style={{marginTop: "40px"}}>
                                    <PrePostMeetingNotes
                                        isInstantMeeting={onGoingMeeting?.isInstantMeeting}
                                        meetingId={onGoingMeeting?.meeting?._id}
                                        accessDenied={onGoingMeeting?.isNonBearishUser}
                                    />
                                </Box>
                            </Stack>

                            <Stack flex={1} sx={{maxWidth: "275px"}}>
                                <Stack
                                    flexDirection={"column"}
                                    style={{maxWidth: "275px"}}
                                    gap={1}
                                >
                                    {isWorkSpaceMode && (
                                        <div>
                                            <CenterVideoTile
                                                videoTile={videoTiles?.[0]}
                                                ownVideoTile={ownVideoTile}
                                                ownContentTile={ownContentTile}
                                                newcss={true}
                                                videoTiledata={videoTiles}
                                                meetingDetails={onGoingMeeting.meeting}
                                                userId={onGoingMeeting?.userAccountDetails?._id}
                                            />
                                        </div>
                                    )}
                                </Stack>
                                {!isWorkSpaceMode && (
                                    <Box
                                        sx={{
                                            display: "grid",
                                            gridTemplateColumns: "repeat(2, 1fr)",
                                            gap: 1,
                                            flex: 3,

                                        }}
                                    >
                                        {videoTiles.slice(1, showAllTiles ? 9 : 5).map((tile: any, i: any) => (
                                            <VideoTiles
                                                {...tile}
                                                aspectRatio={1}
                                                key={i}
                                                showIcon={true}
                                            />
                                        ))}
                                    </Box>
                                )}
                                {videoTiles.length > 5 && (
                                    <Box style={{marginTop: "15px", marginBottom: "20px"}}>
                                        <BlackLongArrowButton onClick={toggleTiles}/>
                                    </Box>
                                )}
                                <Stack flex={6} sx={{justifyContent: "flex-end"}}>
                                    <InCallGuestMenu
                                        joinedUserList={participants}
                                        waitingUserList={onGoingMeeting?.WaitingParticipants}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
};

export default ConferenceRoom;
