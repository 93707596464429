import * as React from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import S from "./DropdownWIconLarge.module.css";
import { ChevronIcon, GreenDropdownIcon } from "../../../images";

interface DropdownWIconLargeProps {
  label?: string;
  placeholder?: string;
  iconSrc?: string;
  options: string[];
  multiple?: boolean;
  width?: string;
  disabled?: boolean;
  initialValue?: string;
  onChange?: (selectedOption: string) => void;
  icon?: "greenArrow" | "chevron";
  inputHeight?: 40 | 25;
}

const DropdownWIconLarge: React.FC<DropdownWIconLargeProps> = ({
  options,
  placeholder,
  label,
  iconSrc,
  initialValue = "",
  multiple = false,
  width = "300px",
  disabled = false,
  onChange,
  inputHeight = 40,
  icon = "greenArrow",
  ...props
}) => {
  const [selectedValues, setSelectedValues] = React.useState<string[]>(
    multiple ? (initialValue ? initialValue.split(",") : []) : [initialValue]
  );
  const [open, setOpen] = React.useState(false);

  // Sync selectedValues with initialValue when it changes
  React.useEffect(() => {
    if (multiple) {
      setSelectedValues(initialValue ? initialValue.split(",") : []);
    } else {
      setSelectedValues([initialValue]);
    }
  }, [initialValue, multiple]);

  const handleChange = React.useCallback(
    (event: SelectChangeEvent<typeof selectedValues>) => {
      const {
        target: { value },
      } = event;
      setSelectedValues(typeof value === "string" ? value.split(",") : value);

      if (onChange) {
        const selectedValue =
          typeof value === "string" ? value : value.join(", ");
        onChange(selectedValue);
      }
    },
    [onChange, setSelectedValues]
  );

  const MenuProps = React.useMemo(
    () => ({
      PaperProps: {
        className: S["dropdownWicon-large__menu"],
        style: { width: width },
      },
    }),
    [width]
  );

  const getCss = (): { fontCss: string; borderCss: string; height: string } => {
    switch (inputHeight) {
      case 40:
        return {
          fontCss: "normal normal normal 15px/21px Source Serif Pro",
          borderCss: "0.5px solid #e9edf2 !important",
          height: "40px",
        };
      case 25:
        return {
          fontCss: "normal normal 300 13px/16px Source Serif Pro",
          borderCss: "1px solid #e9edf2 !important",
          height: "25px",
        };

      default:
        return {
          fontCss: "normal normal normal 15px/21px Source Serif Pro",
          borderCss: "0.5px solid #e9edf2 !important",
          height: "40px",
        };
    }
  };

  const getOpenedIcon = () => {
    switch (icon) {
      case "chevron":
        return (
          <ChevronIcon
            w={20}
            direction={open ? "down" : "up"}
            style={{ marginRight: "10px" }}
          />
        );

      case "greenArrow":
        return <GreenDropdownIcon open={open} sx={{ marginRight: "10px" }} />;
      default:
        return <GreenDropdownIcon open={open} sx={{ marginRight: "10px" }} />;
    }
  };

  return (
    <FormControl size="small" style={{ width }} disabled={disabled} {...props}>
      {label && (
        <Typography
          sx={{
            font: "normal normal normal 10px/13px Source Serif Pro",
            color: "black",
            marginBottom: "5px",
          }}
        >
          {label}
        </Typography>
      )}
      <Select
        onClick={!open && !disabled ? () => setOpen(true) : () => null}
        open={open}
        multiple={multiple}
        displayEmpty
        IconComponent={() => getOpenedIcon()}
        value={selectedValues}
        onChange={handleChange}
        input={
          <OutlinedInput
            className={S["dropdownWicon-large__inputbox"]}
            sx={{ border: getCss().borderCss, height: getCss().height }}
            classes={{
              notchedOutline: S["dropdownWicon-large__notchedOutline"],
              focused: S["dropdownWicon-large__focused"],
            }}
            startAdornment={
              iconSrc ? ( // Conditionally render the icon
                <InputAdornment position="start">
                  <Box
                    component="img"
                    src={iconSrc}
                    sx={{ height: "20px", width: "20px", flexShrink: 0 }}
                  />
                </InputAdornment>
              ) : null
            }
          />
        }
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderValue={(selected) => {
          if (selected?.length === 0) {
            return (
              <em
                style={{ font: getCss().fontCss }}
                className={S["dropdownWicon-large__selectPlaceholder"]}
              >
                {placeholder}
              </em>
            );
          }

          return (
            <span style={{ font: getCss().fontCss }}>
              {selected?.join(", ")}
            </span>
          );
        }}
        MenuProps={MenuProps}
        aria-label={placeholder}
        disabled={disabled}
      >
        {options &&
          options?.map((name) => (
            <MenuItem
              className={S["dropdownWicon-large__menu-item"]}
              key={name}
              value={name}
            >
              <ListItemText
                primary={name}
                primaryTypographyProps={{
                  className: S["dropdownWicon-large__list-item-text"],
                }}
              />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default DropdownWIconLarge;
