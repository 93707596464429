import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import { StreamBearishOSLogoIconPng } from "../../../../../images";
import styles from "./PastCallCard.module.css";
import moment from "moment-timezone";

interface PastCallCardProps {
  isSelected?: boolean;
  meetingDetail?: any;
  handleMeetingClick?: any;
    key?: any;
    userTimeZone?: any;
}

const PastCallCard: React.FC<PastCallCardProps> = ({
  isSelected = false,
  key,
  meetingDetail = {},
  handleMeetingClick,
                                                       userTimeZone
}) => {

    const formatTimeInTimeZone = (time: string, timeZone: string) => {
        return moment.tz(time, timeZone).format("hh:mm a");
    };
    const formattedStartTime = formatTimeInTimeZone(meetingDetail?.startTime, userTimeZone);
    const formattedEndTime = meetingDetail?.endTime ? formatTimeInTimeZone(meetingDetail?.endTime, userTimeZone) : "";
    const formattedDateTime = `${formattedStartTime} - ${formattedEndTime} ${moment.tz(userTimeZone).format('z')}`;

  return (
    <Stack key={key} className={styles.PastCallCard}>
      <Stack className={styles.PastCallCard__indicator} />
      <Stack
        className={styles.PastCallCard__content}
        sx={{ bgcolor: isSelected ? adjustHexColor("#E9EDF2", 35) : "inherit" }}
        onClick={() => handleMeetingClick(meetingDetail?._id)}
      >
        <Stack className={styles.PastCallCard__header}>
          <Box
            component={"img"}
            src={StreamBearishOSLogoIconPng}
            sx={{ height: "20px", width: "20px" }}
            alt="logo"
          />
          <Typography className={styles.PastCallCard__meetingName}>
            {meetingDetail?.title || " "}
          </Typography>
        </Stack>
        <Typography className={styles.PastCallCard__time}>
            {formattedDateTime}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PastCallCard;
