import React, { memo } from "react";
import {
    Container,
    List,
    ListItemButton,
    ListItemText,
    Stack,
    Collapse,
} from "@mui/material";
import { PlusButtons } from "../../../components";
import { ArrowAllIcon } from "../../../../../../../images";
interface ListSectionProps {
    sectionName: string;
    openList: any;
    handleClick: (section: string) => void;
    content: React.ReactNode;
}
const ListSection: React.FC<ListSectionProps> = ({
    sectionName,
    openList,
    handleClick,
    content,
}) => {
    const normalizedSectionName = sectionName
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
            index === 0 ? match.toLowerCase() : match.toUpperCase()
        )
        .replace(/\s+/g, "");
    return (
        <Container
            sx={{
                minHeight: !openList[normalizedSectionName] ? "0" : "150px",
                maxHeight: "150px",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                    display: "none",
                },
                scrollbarWidth: "none", 
            }}
            disableGutters
        >
            <List>
                <ListItemButton
                    sx={{
                        padding: "0", // Custom padding
                    }}
                    disableRipple
                    disableTouchRipple
                    onMouseDown={(e: React.MouseEvent<HTMLElement>) =>
                        e.stopPropagation()
                    }
                    onClick={() => handleClick(normalizedSectionName)}
                    component="div"
                >
                    <ListItemText
                        sx={{
                            fontFamily: "'Source Serif Pro', serif",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "16px",
                            "& span": {
                                fontFamily: "'Source Serif Pro', serif",
                                fontWeight: 600,
                                fontSize: "14px",
                                lineHeight: "16px",
                            },
                        }}
                        primary={sectionName}
                    />

                    <Stack direction="row" alignItems="center" gap="8px">
                        <PlusButtons
                            popOverOrModalType="modal"
                            plusType={normalizedSectionName as any}
                        />
                        <ArrowAllIcon w={15} open={openList[normalizedSectionName]} />
                    </Stack>
                </ListItemButton>

                <Collapse
                    in={openList[normalizedSectionName]}
                    timeout="auto"
                    unmountOnExit
                >
                    <List component="div" disablePadding>
                        <Stack p="0 5px 0 0" direction="column" gap="8px" mt="5px" mb="5px">
                            {content}
                        </Stack>
                    </List>
                </Collapse>
            </List>
        </Container>
    );
};

export default memo(ListSection);
