import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateGuestUser, AuthState, getOnGoingMeetingAgenda, requestToJoinMeeting } from '../../../../redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../../redux/store';
import { MeetingContext } from '../../../Povider';
import { VideoCenterState } from '../../../../redux/Slice/videoCenter/VideoCenterTypes';
import { FormControl, Stack, Typography } from "@mui/material";
import ButtonSmall from "../../../common/Buttons/ButtonSmall/ButtonSmall";
import { ButtonType, ChipWithAvatar, SmallButtonColor } from "../../../common";
import styles from "./WaitingRoom.module.css";
import { useTranslation } from "react-i18next";

// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { InputAreaWithLabel, JoinRequestCard, MicButton, VideoButton, WaitingRoomHeader } from "../components";
import { setIsReadyToJoinMeetingAction } from "../../../../redux/Slice/videoCenter/VideoCenterSlice";
import { setToken } from "../../../common/LocalStorage";

const WaitingRoom: React.FC = () => {
    const params = useParams();
    const { user } = useSelector((state: RootState) => state.auth as AuthState);
    const videoElementRef: any = useRef(null);
    const [userDetails, setUserDetails] = useState({
        firstName: '',
        email: '',
        lastName: '',
    })
    const [isDisabled, setIsDisabled] = useState(false);
    const [joinButtonDisable, setJoinButtonDisable] = useState(false)
    const [isVideoOn, setIsVideoOn] = useState(false);
    const [isMicOn, setIsMicOn] = useState(false);
    const videoStreamRef = useRef<MediaStream | null>(null);
    const audioStreamRef = useRef<MediaStream | null>(null);
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const meetingContext: any = useContext(MeetingContext);

    const { onGoingMeeting } = useSelector(
        (state: RootState) => state.videoCenter as VideoCenterState
    );
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserDetails({ ...userDetails, [name]: value });
    };


    const handleRequestToJoinTheMeeting = async () => {
        try {
            if (user) {
                if (params.id && user?.data?._id && onGoingMeeting?.meeting?.chimeMeetingId) {
                    setIsDisabled(true);
                    dispatch(
                        requestToJoinMeeting({
                            meetingId: params.id,
                            userId: user?.data?._id,
                            chimeMeetingId: onGoingMeeting?.meeting?.chimeMeetingId,
                            meeting_id: onGoingMeeting?.meeting?._id,
                            message: "A guest user wants to join the meeting.",
                        })
                    );
                }
            } else {
                // For guest users
                const payload = {
                    firstName: userDetails?.firstName,
                    lastName: userDetails?.lastName,
                    email: userDetails?.email,
                };

                // Dispatch action to create guest user
                const response: any = await dispatch(authenticateGuestUser(payload));
                setToken(response?.data?.guestUserDetails?.token);
                if (response?.success) {
                    // Assuming the response contains the newly created guest user's ID
                    const guestUserId = response?.data?.guestUserDetails?._id;

                    if (params.id && guestUserId && onGoingMeeting?.meeting?.chimeMeetingId) {
                        setIsDisabled(true);
                        await dispatch(
                            requestToJoinMeeting({
                                meetingId: params.id,
                                userId: guestUserId,
                                chimeMeetingId: onGoingMeeting?.meeting?.chimeMeetingId,
                                meeting_id: onGoingMeeting?.meeting?._id,
                                message: "A guest user wants to join the meeting.",
                            })
                        );
                    }
                }
            }
        } catch (error) {
            console.error('Error while joining the meeting:', error);
            // Optionally handle errors, e.g., show an error message
        }
    };

    useEffect(() => {
        setUserDetails({
            firstName: user?.data?.firstName || '',
            lastName: user?.data?.lastName || '',
            email: user?.data?.email || ''
        });
    }, [user]);

    useEffect(() => {
        if (onGoingMeeting?.ClintNotification && onGoingMeeting?.meeting?.meetingHostId !== user?.data?._id) {
            NotificationManager.info(
                <JoinRequestCard
                    cardType={onGoingMeeting?.ClintNotification?.status}
                    userName={onGoingMeeting?.notificationData?.userDetails?.userName || 'Guest'}
                />,
                'Join Request',
                0, // Duration set to 0 to keep the notification open indefinitely
            );
        }
    }, [onGoingMeeting?.ClintNotification]);

    const handleVideoButtonClick = async () => {
        if (isVideoOn) {
            // Stop the video stream
            videoStreamRef.current?.getTracks().forEach(track => track.stop());
            videoElementRef.current.srcObject = null;
            setIsVideoOn(false);
        } else {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                videoStreamRef.current = stream;
                if (videoElementRef.current) {
                    videoElementRef.current.srcObject = stream;
                }
                setIsVideoOn(true);
            } catch (error) {
                console.error('Error accessing camera:', error);
            }
        }
    };

    const handleMicButtonClick = async () => {
        if (isMicOn) {
            // Stop the audio stream
            audioStreamRef.current?.getTracks().forEach(track => track.stop());
            setIsMicOn(false);
        } else {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                audioStreamRef.current = stream;
                setIsMicOn(true);
            } catch (error) {
                console.error('Error accessing microphone:', error);
            }
        }
    };

    const handleJoinMeeting = async () => {
        setJoinButtonDisable(true)
        const chimeMeetingId = onGoingMeeting?.meeting?.chimeMeetingId;
        const meetingId = onGoingMeeting?.meeting?._id;
        if (meetingId && chimeMeetingId) {
            const attendee = await meetingContext?.createAttendee({
                meetingId: params?.id,
            });
            meetingContext.joinMeeting({
                meetingId: chimeMeetingId,
                attendeeId: attendee?.chimeAttendeeId,
            });
            await meetingContext.configureAudioOutput();
            await meetingContext.startVideoInput();
            await meetingContext.startAudioInput();

            await meetingContext.startSession();

            dispatch(getOnGoingMeetingAgenda(meetingId));
            dispatch(setIsReadyToJoinMeetingAction(true))
        }
        setJoinButtonDisable(false)
    }

    return (
        <Stack sx={{ height: '100vh', width: '100vw', position: 'relative', zIndex: 1 }} >

            <Stack sx={{ position: "absolute", height: '100vh', width: '100vw', zIndex: -1, flexDirection: 'row', }}>
                <Stack sx={{ width: '18vw', backgroundColor: '#E9EDF2' }} />
                <Stack sx={{ background: 'linear-gradient(to bottom right, #E9EDF2 50%, white 50%)', flex: 1 }} />
                <Stack sx={{ width: '29vw', background: 'white' }} />
            </Stack>


            <WaitingRoomHeader />
            <Stack sx={{ flexDirection: 'row', alignItems: 'center', flex: 1, justifyContent: 'space-between', padding: '20px 20px 0px 35px', gap: '45px', boxSizing: 'border-box', overflow: "auto", scrollbarWidth: 'none' }}>
                {/* video and mute/video button here */}
                <Stack sx={{ width: '752px', flexShrink: 0, gap: '25px', paddingBottom: '5px' }}>
                    {/* <Stack className={styles.videoControl}> */}
                    <video
                        ref={videoElementRef}
                        autoPlay={true}
                        playsInline={true}
                        muted={false}
                        style={{
                            border: '1px solid #E9EDF2',
                            boxSizing: 'border-box',
                            width: "750px",
                            height: "350px",
                            objectFit: "cover",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                            aspectRatio: "750/350",
                        }}
                    />
                    {/* </Stack> */}
                    <Stack sx={{ flexDirection: "row", justifyContent: 'center', gap: '25px' }}>
                        <MicButton
                            initialStatus={true}
                            onClickChange={handleMicButtonClick}
                        />
                        <VideoButton
                            initialStatus={true}
                            onClickChange={handleVideoButtonClick}
                        />
                    </Stack>

                </Stack>

                {/* call info area */}
                <Stack sx={{ alignItems: 'center', flex: 1, gap: '40px' }}>
                    <div className={styles.hedding}>
                        <Typography
                            style={{ font: "normal normal 900 25px Source Serif Pro" }}>{t('VIDEO_CENTER.MEETING.WELCOME_TO_CALL')}</Typography>
                        <Typography
                            style={{ font: "normal normal 300 18px Source Serif Pro" }}>{t('VIDEO_CENTER.MEETING.WHO_IS_IN_CALL')}</Typography>
                        <Stack className={styles.userDiv}>
                            {onGoingMeeting?.realtimeAttendees?.map((user: any, i: any) => (
                                <ChipWithAvatar
                                    key={i}
                                    userName={user.firstName || ''}
                                    userImgSrc={`https://example.com/avatar${i + 1}.png`}
                                    userAvatarClr={`#${user.avatar}`}
                                />
                            ))}
                        </Stack>
                    </div>
                    <div className={styles.hedding}>
                        <Typography style={{ font: "normal italic 300 18px Source Serif Pro" }}>{t('VIDEO_CENTER.MEETING.JOIN_CALL_INSTRUCTIONS')}</Typography>
                        <div className={styles.formControl}>
                            <FormControl variant="standard">
                                <InputAreaWithLabel
                                    id="firstName-input"
                                    inputLabel={t('VIDEO_CENTER.MEETING.FIRST_NAME')}
                                    name="firstName"
                                    value={userDetails.firstName}
                                    onChange={handleInputChange}
                                    disabled={user ? true : false}
                                />
                            </FormControl>
                            <FormControl variant="standard">
                                <InputAreaWithLabel
                                    id="lastName-input"
                                    inputLabel={t('VIDEO_CENTER.MEETING.LAST_NAME')}
                                    name="lastName"
                                    value={userDetails.lastName}
                                    onChange={handleInputChange}
                                    disabled={user ? true : false}
                                />
                            </FormControl>
                            <FormControl variant="standard">
                                <InputAreaWithLabel
                                    inputWidth={'200px'}
                                    id="email-input"
                                    inputLabel={t('VIDEO_CENTER.MEETING.EMAIL')}
                                    name="email"
                                    value={userDetails.email}
                                    onChange={handleInputChange}
                                    disabled={user ? true : false}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className={styles.hedding}>
                        {onGoingMeeting?.isUserIsHost || onGoingMeeting?.isUserInvitedToMeeting || onGoingMeeting.isAbleToJoinMeeting
                            ?
                            <ButtonSmall
                                className={styles.buttonControl}
                                onClick={handleJoinMeeting}
                                label={"Join Now"}
                                types={ButtonType.Submit}
                                disabled={joinButtonDisable}
                                colorVarient={SmallButtonColor?.CallsSalmon} />
                            :
                            <ButtonSmall
                                className={styles.buttonControl}
                                disabled={!userDetails?.email || !userDetails?.firstName || !userDetails?.lastName || isDisabled}
                                onClick={handleRequestToJoinTheMeeting}
                                label={t('VIDEO_CENTER.MEETING.ASK_TO_JOIN')}
                                types={ButtonType.Submit}
                                colorVarient={SmallButtonColor?.CallsSalmon} />
                        }
                    </div>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default WaitingRoom;