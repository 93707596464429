import { MessagesState } from "../messageTypes";

export const getChannelMessageRequest = (state: MessagesState) => {
  state.error = null;
};
export const sendMessageRequest = (state: MessagesState) => {
  state.error = null;
};
export const getUserConnectionsRequest = (state: MessagesState) => {
  state.error = null;
};
export const getExternalConnectionsRequest = (state: MessagesState) => {
  state.error = null;
};
export const createNewConnectionRequest = (state: MessagesState) => {
  state.error = null;
};
export const updateConnectionRequest = (state: MessagesState) => {
  state.error = null;
};

export const updateMessageRequest = (state: MessagesState) => {
  state.error = null;
};

export const scheduleMessageRequest = (state: MessagesState) => {
  state.error = null;
};

export const forwardMessageRequest = (state: MessagesState) => {
  state.error = null;
};

export const addReactionToMessageRequest = (state: MessagesState) => {
  state.error = null;
};
export const removeReactionToMessageRequest = (state: MessagesState) => {
  state.error = null;
};
export const markMessagesAsReadRequest = (state: MessagesState) => {
  state.error = null;
};
export const changeParticipantsRoleRequest = (state: MessagesState) => {
  state.error = null;
};
export const getChannelActivitiesRequest = (state: MessagesState) => {
  state.error = null;
};
export const getMessageActivitiesRequest = (state: MessagesState) => {
  state.error = null;
  state.messageActivity = [];
};
export const changeMessagesActivitiesStatusRequest = (state: MessagesState) => {
  state.error = null;
};
export const setSnoozeMessagesActivitiesRequest = (state: MessagesState) => {
  state.error = null;
};
export const getSlackUsersRequest = (state: MessagesState) => {
  state.error = null;
};
export const getSlackUserProfileRequest = (state: MessagesState) => {
  state.error = null;
};
export const getTeamsUserProfileRequest = (state: MessagesState) => {
  state.error = null;
};

export const leaveSlackConversationRequest = (state: MessagesState) => {
  state.error = null;
};

export const pinMessageRequest = (state: MessagesState) => {
    state.error = null;
}

export const unPinMessageRequest = (state: MessagesState) => {
    state.error = null;
}

export const getPinnedMessagesRequest = (state: MessagesState) => {
    state.error = null;
}

export const getAttachedFilesRequest = (state: MessagesState) => {
    state.error = null;
}