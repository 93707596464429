import { IconButton, Stack } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import {
  PauseBoldIcon,
  PlayCircleIcon,
  StopBoldIcon,
} from "../../../../../images";

interface MsgAudioPreviewProps {}

const MsgAudioPreview: React.FC<MsgAudioPreviewProps> = ({}) => {
  const [ml, setMl] = useState<"0px" | "100px">("0px");
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const resizeObserver = new ResizeObserver(() => {
        const height = container.offsetHeight;
        setMl(height > 45 ? "100px" : "0px");
      });

      resizeObserver.observe(container);

      return () => resizeObserver.disconnect();
    }
  }, []);

  return (
    <Stack
      ref={containerRef}
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        gap: "5px",
        flexShrink: 0,
        flexWrap: "wrap",
      }}
    >
      <Stack
        sx={{
          height: "45px",
          width: "300px",
          bgcolor: "#D9D9D9",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Audio component
      </Stack>

      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          width: "fit-content",
          marginLeft: ml,
        }}
      >
        <IconButton>
          <PauseBoldIcon w={20} />
        </IconButton>

        <IconButton>
          <PlayCircleIcon w={20} />
        </IconButton>

        <IconButton>
          <StopBoldIcon w={20} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default MsgAudioPreview;
