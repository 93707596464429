import React, { useState, useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { FirstLoginDataProps } from "../StepsType";
import CompanyDetails from "./CompanyDetails";

interface CompanyOption {
  claimed: boolean;
  name: string | null;
  domain: string;
  icon: string | null;
  brandId: string; // Unique identifier
}

interface Props extends FirstLoginDataProps {
  companyAvalibilityCheckLoader: boolean;
  companyOptions: CompanyOption[];
  handleCompanyRegistryList: (searchValue: string) => void; // Function to handle API call
  disableCompanySelectionAndSetDefault?: boolean; // Disable company selection and set default value
}

const CompanyNameAutocomplete: React.FC<Props> = ({
  firstLoginData,
  setFirstLoginData,
  companyAvalibilityCheckLoader,
  companyOptions = [],
  handleCompanyRegistryList, disableCompanySelectionAndSetDefault
}) => {
  const [inputValue, setInputValue] = useState<string>(
    firstLoginData?.companyNameSearch || ""
  );
  const [value, setValue] = useState<CompanyOption | null>(null);
  // ? If you are confident that the current implementation is correct
  const [selectedByUser, setSelectedByUser] = useState<boolean>(false); // Track user-initiated selection

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (disableCompanySelectionAndSetDefault) {
      return;
    }
    setInputValue(firstLoginData?.companyNameSearch || "");
    setValue(
      companyOptions.find(
        (option) => option.name === firstLoginData?.companyNameSearch
      ) || null
    );
  }, [firstLoginData, companyOptions]);

  const handleInputChange = (
    event: React.SyntheticEvent,
    newInputValue: string
  ) => {
    if (disableCompanySelectionAndSetDefault) {
      return;
    }
    setInputValue(newInputValue);
    setFirstLoginData({
      ...firstLoginData,
      companyNameSearch: newInputValue,
    });
    setValue(null); // Clear selected value when input changes
    setSelectedByUser(false); // Reset user selection flag
    // Debounce the API call
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      handleCompanyRegistryList(newInputValue);
    }, 1000); // 1000ms = 1 second
    if (selectedByUser) {
      /** !do nothing for now! */
    }
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: CompanyOption | null
  ) => {
    if (disableCompanySelectionAndSetDefault) {
      return;
    }
    if (newValue) {
      setInputValue(newValue.name || "");
      setFirstLoginData({
        ...firstLoginData,
        companyNameSearch: newValue.name || "",
        companyName: newValue.name || "",
        domain: newValue.domain || "",
        logo: newValue.icon || "",
      });
      setValue(newValue);
    }
  };

  return (
    <Autocomplete
      disablePortal
      disabled={disableCompanySelectionAndSetDefault}
      id="company-name-autocomplete"
      options={companyOptions}
      loading={companyAvalibilityCheckLoader}
      loadingText="Loading..."
      noOptionsText="Type your company name"
      getOptionLabel={(option: CompanyOption) => option.name || ""}
      isOptionEqualToValue={(option: CompanyOption, value: CompanyOption) =>
        option.brandId === value.brandId
      }
      sx={{ width: "100%" }}
      value={value}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange} // Only trigger API call on manual selection
      onFocus={() => setSelectedByUser(true)} // Set user selection flag on focus
      onBlur={() => setSelectedByUser(false)} // Reset user selection flag on blur
      renderOption={(props, option) => (
        <li {...props} key={option.brandId}>
          <CompanyDetails company={option} />
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Company name"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {companyAvalibilityCheckLoader && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default CompanyNameAutocomplete;
