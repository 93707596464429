import { Box, Button, Stack, Typography, IconButton } from "@mui/material";
import React from "react";
import {  BearishLogoPng, HelpIcon } from "../../../images";

const PublicTopBar = ({ redirectToLogin }: { redirectToLogin: any }) => {
  return (
    <Box sx={{ marginBottom: "10px", borderBottom: "2px solid #E9EDF2" }}>
      <Stack
        width={"100%"}
        height={"60px"}
        alignItems={"center"}
        justifyContent={"space-between"}
        padding={"10px 20px 10px 20px"}
        direction={"row"}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={1}
        >
       <img src={BearishLogoPng} style={{width:"50px", height:"50px"}} alt="" />
          <Typography
            children={"Bearish OS"}
            sx={{ font: "normal normal 500 25px/32px Source Serif Pro" }}
          />
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={1}
        >
          <IconButton>
            <HelpIcon />
          </IconButton>

          <Button
            sx={{
              height: "40px",
              padding: "4px 8px",
              textTransform: "capitalize",
              font: "normal normal 500 15px/18px Source Serif Pro",
              color: "black",
              border: "1px solid #E9EDF2",
              borderRadius: "5px",
            }}
            onClick={() => redirectToLogin && redirectToLogin()}
          >
            Create a Free Account
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default PublicTopBar;
