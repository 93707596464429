export interface SearchFoldersDropdownInterface {
  folderid: number;
  foldername: string;
  _id?: string;
  folderName?: string;
}

export const Dummy_SearchFoldersDropdownList: SearchFoldersDropdownInterface[] =
  [
    { folderid: 1, foldername: "Bright Document 27.pdf" },
    { folderid: 2, foldername: "Cool Picture 13.img" },
    { folderid: 3, foldername: "Funny File 98.mp4" },
    { folderid: 4, foldername: "Lively Note 74.txt" },
    { folderid: 5, foldername: "Dark Vfolderideo s dajsjasdjaksd kjsdhajksdkjsd asjdasjkdas dj 59.doc" },
    { folderid: 6, foldername: "Amazing Audio 33.ai" },
    { folderid: 7, foldername: "Cool Document 48.pdf" },
    { folderid: 8, foldername: "Funny Picture 22.folder" },
    { folderid: 9, foldername: "Bright File 85.video" },
    { folderid: 10, foldername: "Lively Note 11" },
    { folderid: 11, foldername: "Dark Document 66" },
    { folderid: 12, foldername: "Amazing Video 92" },
    { folderid: 13, foldername: "Cool Audio 77" },
    { folderid: 14, foldername: "Funny File 49" },
    { folderid: 15, foldername: "Bright Picture 83" },
    { folderid: 16, foldername: "Lively Document 54" },
    { folderid: 17, foldername: "Dark Note 18" },
    { folderid: 18, foldername: "Amazing Picture 30" },
    { folderid: 19, foldername: "Cool Video 89" },
    { folderid: 20, foldername: "Funny Audio 42" },
  ];
