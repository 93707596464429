import React from "react";
import {
  TextNormalDropdown,
  TextNormalDropdownOptionsType,
} from "../../../../common";

const DUMMY_EventTypeDropdown: TextNormalDropdownOptionsType[] = [
  {
    _id: "1",
    title: "Day Off",
    value: "dayOff",
  },
  {
    _id: "2",
    title: "Holiday",
    value: "holiday",
  },
  {
    _id: "3",
    title: "Religious Holiday",
    value: "religiousHoliday",
  },
  {
    _id: "4",
    title: "Country Holiday",
    value: "countryHoliday",
  },
  {
    _id: "5",
    title: "Company Holiday",
    value: "companyHoliday",
  },
  {
    _id: "6",
    title: "Summer Recess",
    value: "summerRecess",
  },
  {
    _id: "7",
    title: "Winter Recess",
    value: "winterRecess",
  },
  {
    _id: "8",
    title: "Work Event",
    value: "workEvent",
  },
  {
    _id: "9",
    title: "Other",
    value: "other",
  },
];

interface EventTypeDropdownProps {}

const EventTypeDropdown: React.FC<EventTypeDropdownProps> = () => {
  return (
    <TextNormalDropdown
      placeholder="Select Event type..."
    //   initialValue={DUMMY_EventTypeDropdown[3]}
      options={DUMMY_EventTypeDropdown}
      onSelect={(value) => console.log(value)}
    />
  );
};

export default EventTypeDropdown;
