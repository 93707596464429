import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { getFileIcon } from "../../../../../Utils";
import { ChevronIcon } from "../../../../../images";

interface MsgFileMiniPreviewProps {
  fileName: string;
  fileNameWithExtensition: string;
  onButtonClick: () => void;
}

const MsgFileMiniPreview: React.FC<MsgFileMiniPreviewProps> = ({
  fileName,
  fileNameWithExtensition,
  onButtonClick,
}) => {
  return (
    <Stack
      sx={{
        height: "35px",
        flexShrink: 0,
        borderRadius: "5px",
        border: "1px solid #E9EDF2",
        boxSizing: "border-box",
        padding: "0px 5px 0px 10px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          maxWidth: "calc(100% - 35px)",
        }}
      >
        {getFileIcon({ name: fileNameWithExtensition, iconSize: 25 })}
        <Typography
          sx={{
            font: "normal normal 300 12px/15px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {fileName}
        </Typography>
      </Stack>

      <IconButton onClick={onButtonClick}>
        <ChevronIcon w={15} direction="right" />
      </IconButton>
    </Stack>
  );
};

export default MsgFileMiniPreview;
