import { UserTypeEnum } from "../../AdminConsolePageType";

export type AddUserAdminPortalOptionType = {
    _id: string;
    translatedTitle: string;
    value: UserTypeEnum
}

export const AddUserAdminPortalOptionList: AddUserAdminPortalOptionType[] = [
    { _id: '1', translatedTitle: 'Add Admin', value: UserTypeEnum.ADMIN },
    { _id: '2', translatedTitle: 'Add Manager', value: UserTypeEnum.MANAGER },
    { _id: '3', translatedTitle: 'Add Team Lead', value: UserTypeEnum.TEAM_LEAD },
    { _id: '4', translatedTitle: 'Add User', value: UserTypeEnum.USER },
    { _id: '5', translatedTitle: 'Add Guest', value: UserTypeEnum.GUEST },
    { _id: '6', translatedTitle: 'Add Auditor', value: UserTypeEnum.AUDITOR },
]
