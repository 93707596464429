import React from "react";
import { TraxTimeAttendancePageType } from "../../../../../pages";
import {
  a11yProps,
  StyledTab,
  TabsWithState,
} from "../../../../CardTabsWithoutIcon";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { TraxDrawerMenu } from "../../../../../pages/Trax/components";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/rootReducer";

const TraxTimeAttendanceRoute: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const drawerState = useSelector((state: RootState) => state && state.layout);
  const { isDrawer_Trax } = drawerState || {};

  const value: TraxTimeAttendancePageType | null = searchParams.get(
    "value"
  ) as TraxTimeAttendancePageType;

  const tabName = [
    {
      label: "Scheduling",
      value: "schedule" as TraxTimeAttendancePageType,
    },
    {
      label: "Personal",
      value: "personal" as TraxTimeAttendancePageType,
    },
  ];

  const [tabValue, setTabValue] = React.useState<number>(0);

  React.useEffect(() => {
    if (value) {
      const index = tabName.findIndex((item) => item.value === value);
      setTabValue(index !== -1 ? index : 0);
    } else {
      setTabValue(0);
    }
  }, [value, tabName]);

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "30px" }}>
      <TabsWithState
        ariaLabel="Trax TimeAttendanceRoute tabs"
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
        tabIndicatorColor="#00767B"
        sx={{
          borderBottom: "0px solid #E9EDF2",
          "& .css-1aquho2-MuiTabs-indicator": {
            bottom: "0px !important",
          },
        }}
      >
        {tabName.map((tab, index) => (
          <StyledTab
            style={{
              font: "normal normal 300 13px/16px Source Serif Pro",
              padding: "5px 0px",
            }}
            key={index}
            disableRipple
            onClick={() => navigate(`/Trax/time-attendance?value=${tab.value}`)}
            label={t(tab.label)}
            {...a11yProps(index)}
          />
        ))}
      </TabsWithState>

      {!isDrawer_Trax && <TraxDrawerMenu />}
    </Stack>
  );
};

export default TraxTimeAttendanceRoute;
