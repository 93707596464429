import { Stack, TableCell, TableCellProps, Typography } from "@mui/material";
import React from "react";

interface UserTableHeaderCellProps extends TableCellProps {
  children?: React.ReactNode;
  translatedTitle: string;
  minWidth: string;
  widthPercentage: string;
}

export const UserTableHeaderCell: React.FC<UserTableHeaderCellProps> = ({
  minWidth,
  translatedTitle,
  widthPercentage,
  children,
  sx,
  ...props
}) => {
  return (
    <TableCell
      sx={{
        padding: "0px 5px 0px 15px",
        boxSizing: "border-box",
        minHeight: 0,
        width: widthPercentage,
        minWidth: minWidth,
        font: "normal normal 600 14px/18px Source Serif Pro",
        ...sx,
      }}
      {...props}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        {children}
        <Typography sx={{ font: "inherit" }}>{translatedTitle}</Typography>
      </Stack>
    </TableCell>
  );
};

interface UserTableBodyCellProps extends TableCellProps {
  children?: React.ReactNode;
  childrenStyle?: React.CSSProperties;
}

export const UserTableBodyCell: React.FC<UserTableBodyCellProps> = ({
  children,
  childrenStyle,
  sx,
  ...props
}) => {
  return (
    <TableCell
      sx={{
        padding: "0px 5px 0px 15px",
        boxSizing: "border-box",
        minHeight: 0,
        ...sx,
      }}
      {...props}
    >
      <Stack
        style={{
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          ...childrenStyle,
        }}
      >
        {children}
      </Stack>
    </TableCell>
  );
};
