import React from "react";
import { ErrorIcon } from "../../../../../images";
import { Tooltip, Typography } from "@mui/material";
import { ErrorHoverInfo } from "../../../../common";

/** Props for different conflict types */
export type ConflictingInfoProps =
  | { conflictType: "shiftConflict"; startDate: string; time: string }
  | { conflictType: "timeOffConflicting"; startDate: string; time: string }
  | { conflictType: "ptoConflicting"; startDate: string; endDate: string }
  | { conflictType: "leaveConflicting" }
  | { conflictType: "positionConflicting"; jobTitle: string };

const ConflictingInfo: React.FC<ConflictingInfoProps> = (props) => {
  const renderTooltipContent = () => {
    switch (props.conflictType) {
      case "shiftConflict":
        return `This employee has a conflicting shift on ${props.startDate} at ${props.time}`;
      case "timeOffConflicting":
        return `This employee has requested time off on ${props.startDate} at ${props.time}`;
      case "ptoConflicting":
        return `This employee is on PTO from ${props.startDate} to ${props.endDate} day.`;
      case "leaveConflicting":
        return "This employee is currently on leave.";
      case "positionConflicting":
        return `This employee is not a ${props.jobTitle}. Proceed? `;
    }
  };

  return <ErrorHoverInfo errorText={renderTooltipContent()} />;
};

export default ConflictingInfo;
