import React from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./SmallContactCardHover.module.css";
import { AvatarNormal } from "../AvatarNormal";
import { QuickActionsMenu } from "../QuickActionsMenu";
import { AddUserIcon } from "../../../images";
import { useCustomSnackbar } from "../EnqueueSnackbar";

interface SmallContactCardHoverProps {
  userName: string;
  userEmail: string;
  userIconSrc?: string;
  userAvatarColor?: string;
}

// Utility for email validation
const isValidEmail = (email: string): boolean =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

const SmallContactCardHover: React.FC<SmallContactCardHoverProps> = ({
  userName,
  userEmail,
  userIconSrc,
  userAvatarColor,
}) => {
  const navigate = useNavigate();
  const enqueueSnackbar_v1 = useCustomSnackbar();

  const handleNavigateTo__Contact_Page = () => {
    if (!isValidEmail(userEmail)) {
      console.error("Invalid or missing email address");
      enqueueSnackbar_v1("error", `Invalid or missing email address`, {
        position: { vertical: "top", horizontal: "center" },
        autoHideDuration: 3000,
      });
      return;
    }
    const emailQuery = `?q=${encodeURIComponent(userEmail)}`;
    navigate(`/CRM/people/contact-card-hover${emailQuery}`);
  };

  return (
    <Stack className={styles["smallContactCardHover"]}>
      {/* Header Section */}
      <Stack className={styles["smallContactCardHover__header"]}>
        <Stack className={styles["smallContactCardHover__header-content"]}>
          <AvatarNormal
            size={30}
            username={userName}
            imgSrc={userIconSrc}
            avatarColor={userAvatarColor}
          />
          <Stack className={styles["smallContactCardHover__text-container"]}>
            <Typography
              className={styles["smallContactCardHover__companyName"]}
            >
              {userName}
            </Typography>
            <Typography className={styles["smallContactCardHover__email"]}>
              {userEmail}
            </Typography>
          </Stack>
        </Stack>
        <IconButton sx={{ p: 0 }} disableRipple>
          <AddUserIcon w={15} />
        </IconButton>
      </Stack>

      {/* Actions Section */}
      <Stack className={styles["smallContactCardHover__actions"]}>
        <IconButton
          className={styles["smallContactCardHover__openDetailsButton"]}
          onClick={handleNavigateTo__Contact_Page}
        >
          <Typography
            className={styles["smallContactCardHover__openDetailsText"]}
          >
            Open contact details
          </Typography>
        </IconButton>
        <QuickActionsMenu
          onSelect={() => {
            /* Handle action */
          }}
        />
      </Stack>
    </Stack>
  );
};

export default SmallContactCardHover;
