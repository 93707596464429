import {PayloadAction} from "@reduxjs/toolkit";
import {GlobalPayload, GlobalState} from "../globalTypes";
import {saveOrUpdateCoverImage__Success} from "./GlobalSliceSuccess";
import {getAllProjectsGlobal__api} from "../globalActions";

export const createScreenRecording__Failure = (
    state: GlobalState,
    action: PayloadAction<GlobalPayload>
) => {
    state.error = action.payload;
};

export const getAllScreenRecordings__Failure = (
    state: GlobalState,
    action: PayloadAction<GlobalPayload>
) => {
    state.error = action.payload;
};

export const getScreenRecordingById__Failure = (
    state: GlobalState,
    action: PayloadAction<GlobalPayload>
) => {
    state.error = action.payload;
};

export const updateScreenRecording__Failure = (
    state: GlobalState,
    action: PayloadAction<GlobalPayload>
) => {
    state.error = action.payload;
};

export const deleteScreenRecording__Failure = (
    state: GlobalState,
    action: PayloadAction<GlobalPayload>
) => {
    state.error = action.payload;
};

export const saveOrUpdateCoverImage__Failure = ( state: GlobalState, action: PayloadAction<GlobalPayload>) => {
    state.error = action.payload;
}

export const getCoverImages__Failure = ( state: GlobalState, action: PayloadAction<GlobalPayload>) => {
    state.error = action.payload;
}

export const getAllProjectsGlobal__Failure = (state: GlobalState, action: PayloadAction<GlobalPayload>) => {
    state.error = action.payload;
}

export const getAllWorkspaceGlobal__Failure = (state: GlobalState, action: PayloadAction<GlobalPayload>) => {
    state.error = action.payload;
}

export const getAllFoldersGlobal__Failure = (state: GlobalState, action: PayloadAction<GlobalPayload>) => {
    state.error = action.payload;
}

export const globalSearch__Failure = (state: GlobalState, action: PayloadAction<GlobalPayload>) => {
    state.error = action.payload;
}

export const getAllUserContactsFailure = (state: GlobalState, action: PayloadAction<GlobalPayload>) => {
    state.error = action.payload;
}

export const getAllCloudStorageFiles__Failure = (state: GlobalState, action: PayloadAction<GlobalPayload>) => {
    state.error = action.payload;
}

export const initiateGroupCall__Failure = (state: GlobalState, action: PayloadAction<GlobalPayload>) => {
    state.error = action.payload;
}

export const getCallUserDetails__Failure = (state: GlobalState, action: any) => {
    state.loading = false;
}