import React from "react";
import { AllUserTableDataType } from "../../AllUserTableType";
import { Typography } from "@mui/material";

interface InviteStatusViewProps {
  data: AllUserTableDataType;
}

const InviteStatusView: React.FC<InviteStatusViewProps> = ({ data }) => {
  return (
    <Typography sx={{ font: "normal normal 400 12px/15px Source Serif Pro" }}>
      {data.isInviteAccepted ? "Accepted" : "Invited"}
    </Typography>
  );
};

export default InviteStatusView;
