import { Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import {ChevronIcon, FolderFileIcon} from "../../../../../images";

interface ConnectedFolderChipButtonProps {
    folder?: any;
    btnWidth?: any;
}

const ConnectedFolderChipButton: React.FC<ConnectedFolderChipButtonProps> = ({
                                                                                   folder,
                                                                                   btnWidth,
                                                                               }) => {
    const adjustedColor = adjustHexColor("#21B481", 70);
    return (
        <Stack sx={{ height: "35px", flexDirection: "row", gap: "10px"}}>
            <Stack
                sx={{
                    width: "30px",
                    borderRadius: "5px 0px",
                    bgcolor: adjustedColor,
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <FolderFileIcon w={20} color="black" />
            </Stack>

            <Stack
                sx={{
                    width: btnWidth,
                    borderRadius: "5px 0px",
                    bgcolor: adjustedColor,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "15px",
                    padding: "0px 10px 0px 5px",
                    boxSizing: "border-box",
                    cursor: "pointer",
                }}
            >
                <Typography
                    sx={{
                        font: "normal normal 400 13px/16px Source Serif Pro",
                        color: "white",

                        display: "-webkit-box",
                        WebkitLineClamp: 1, // Limit to 2 lines
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {folder?.name || folder?.folderName}
                </Typography>
                <ChevronIcon w={20} direction="right" color="#fff" />
            </Stack>
        </Stack>
    );
};

export default ConnectedFolderChipButton;
