import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../../../Utils";
import {
  AddUserIcon,
  CheckedCircleIcon,
  DateIcon,
  DoubleUserOutlineIcon,
  SingleUserOutlineIcon,
  VideocamIcon,
} from "../../../../../../../images";

export type CommandType = "task" | "event" | "call" | "expense" | "team";

interface CommandsButtonViewProps {
  commandType: CommandType;
  onSelect?: (value: CommandType) => void;
}

const CommandsButtonView: React.FC<CommandsButtonViewProps> = ({
  commandType,
  onSelect,
}) => {
  const getProperty = (): {
    icon: React.ElementType;
    tagName: string;
    subTitle: string;
  } => {
    switch (commandType) {
      case "task":
        return {
          icon: CheckedCircleIcon,
          tagName: "Task",
          subTitle: "Assign Task",
        };
      case "event":
        return {
          icon: DateIcon,
          tagName: "Event",
          subTitle: "Add an event",
        };
      case "call":
        return {
          icon: VideocamIcon,
          tagName: "Call",
          subTitle: "Schedule a call",
        };
      case "expense":
        return {
          icon: SingleUserOutlineIcon,
          tagName: "Expense",
          subTitle: "Report",
        };
      case "team":
        return {
          icon: DoubleUserOutlineIcon,
          tagName: "Team",
          subTitle: "Add a team",
        };
    }
  };

  return (
    <Button
      sx={{
        height: "24px",
        textTransform: "none",
        minHeight: 0,
        justifyContent: "start",
        boxSizing: "border-box",
        padding: "0px 0px 0px 5px",
        gap: "5px",
        color: "#775EE2",
        ":hover": { bgcolor: adjustHexColor("#775EE2", 50) },
      }}
        onClick={() => onSelect && onSelect(commandType)}
    >
      {React.createElement(getProperty().icon, { w: 20, color: "black" })}

      <Typography
        sx={{
          color: "black",
          font: "normal normal 300 13px/16px Source Serif Pro",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        @{getProperty().tagName}
      </Typography>

      <Stack
        sx={{
          width: "1px",
          borderRadius: "2px",
          bgcolor: "#E9EDF2",
          height: "15px",
        }}
      />

      <Typography
        sx={{
          color: "black",
          font: "normal normal 300 13px/16px Source Serif Pro",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {getProperty().subTitle}
      </Typography>
    </Button>
  );
};

export default CommandsButtonView;
