import { Button, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../../../Utils";
import { BearishOSIcon } from "../../../../../../../images";

interface BebaAiMentionButtonProps {}

const BebaAiMentionButton: React.FC<BebaAiMentionButtonProps> = ({}) => {
  return (
    <Button
      sx={{
        height: "24px",
        textTransform: "none",
        minHeight: 0,
        justifyContent: "start",
        boxSizing: "border-box",
        padding: "0px 5px",
        gap: "5px",
        color: "#775EE2",
        ":hover": { bgcolor: adjustHexColor("#775EE2", 50) },
      }}
    >
      <BearishOSIcon w={20} />

      <Typography
        sx={{
          color: "black",
          font: "normal normal 300 13px/16px Source Serif Pro",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        BEBA AI
      </Typography>
    </Button>
  );
};

export default BebaAiMentionButton;
