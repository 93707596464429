import * as React from "react";
import {
  Box,
  ClickAwayListener,
  InputBase,
  InputAdornment,
  Stack,
  SxProps,
  Popover,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs, { Dayjs } from "dayjs"; // Import `dayjs` function
import { ClockIcon, LastCalendarIcon } from "../../../images";
import {
  adjustHexColor,
  FormattedDateType,
  getFormattedDateObject,
} from "../../../Utils";

interface DatePickerWithIconProps {
  hideIcon?: boolean;
  sx?: SxProps;
  dateFormat?: "MM/DD/YY" | "MM-DD-YYYY" | "MMM D, YYYY";
  initialDate?: Dayjs | null; // Added initialDate prop
  onDateChange?: (date?: string) => void; // Optional date toString callback
  readonlyComponent?: boolean;
  headerType?: "default" | "LargeBox" | "miniBox";
  onDateChangeAsDayJs?: (date?: Dayjs) => void;
}

const DatePickerWithIcon: React.FC<DatePickerWithIconProps> = ({
  hideIcon,
  sx,
  dateFormat = "MM/DD/YY", // default to "MM/DD/YY"
  initialDate = null, // default initial date
  onDateChange,
  readonlyComponent = false,
  headerType = "default",
  onDateChangeAsDayJs,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(
    initialDate || dayjs() 
  );

  React.useEffect(() => {
    setSelectedDate(initialDate || dayjs());
  }, [initialDate]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!readonlyComponent) {
      setAnchorEl(event.currentTarget);
    }
  };

  // const handleClickAway = () => {
  //   setAnchorEl(null);
  // };

  const open = Boolean(anchorEl);
  const id = open ? "single-date-picker-popover" : undefined;

  // Helper function to determine placeholder format
  const getPlaceholder = (format: string) => {
    switch (format) {
      case "MM/DD/YY":
        return "00/00/00";
      case "MM-DD-YYYY":
        return "00-00-0000";
      case "MMM D, YYYY":
        return "00-00-0000";
      default:
        return "";
    }
  };

  // Format the date based on the provided dateFormat prop
  const formatDate = selectedDate ? selectedDate?.format(dateFormat) : "";

  const handleDateChange = (newValue: Dayjs | null | string) => {
    setSelectedDate((newValue as Dayjs) || (newValue as string));
    setAnchorEl(null);

    // Return the date as a string if available, else undefined
    if (onDateChange) {
      onDateChange(newValue as string);
    }
    // Return the date as a string if available, else undefined
    if (onDateChangeAsDayJs) {
      onDateChangeAsDayJs(newValue as Dayjs);
    }
  };

  const date: FormattedDateType | string = selectedDate
    ? getFormattedDateObject(selectedDate.format("YYYY-MM-DD"))
    : "Select Date";

  // // const {} = selectedDate
  //   ? getFormattedDateObject(selectedDate.format("YYYY-MM-DD"))
  //    : "";

  const renderHeaderComponent = (): React.ReactNode => {
    switch (headerType) {
      case "default":
        return (
          <Stack
            sx={{
              cursor: "pointer",
              position: "relative",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: "1px",
              width: "65px",
              font: "normal normal 300 11px/11px Source Serif Pro",
              ...sx,
            }}
          >
            <InputBase
              sx={{
                borderRadius: "5px",
                height: "14px",
                width: "inherit",
                boxSizing: "border-box",
                paddingTop: "0px",
                font: "inherit",
              }}
              inputProps={{
                style: {
                  cursor: "pointer",
                },
              }}
              startAdornment={
                !hideIcon && (
                  <InputAdornment
                    position="end"
                    sx={{
                      marginLeft: "auto",
                      padding: "0px 5px 0px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <ClockIcon w={14} />
                  </InputAdornment>
                )
              }
              value={formatDate}
              placeholder={getPlaceholder(dateFormat)} // Use the helper function
              readOnly
            />
          </Stack>
        );

      case "LargeBox":
        return (
          <Stack
            sx={{
              height: "25px",
              width: "100%",
              flexShrink: 0,
              borderRadius: "5px",
              border: "1px solid #E9EDF2",
              font: "normal normal 400 12px/15px Source Serif Pro",
              cursor: "pointer",
              flexDirection: "row",
              alignItems: "center",
              boxSizing: "border-box",
              padding: "0px 10px",
              gap: "10px",
              ...sx,
            }}
          >
            {!hideIcon && <LastCalendarIcon w={15} />}
            <Typography
              sx={{
                font: "inherit",
                color: formatDate ? "black" : adjustHexColor("#000000", 50),
              }}
            >
              {typeof date === "string"
                ? date
                : `${date.day}, ${date.month} ${date.date} ${date.year}`}
            </Typography>
          </Stack>
        );
      case "miniBox":
        return (
          <Stack
            sx={{
              height: "30px",
              width: "100px",
              flexShrink: 0,
              borderRadius: "5px",
              border: "1px solid #E9EDF2",
              font: "normal normal 300 12px/15px Source Serif Pro",
              cursor: "pointer",
              flexDirection: "row",
              alignItems: "center",
              boxSizing: "border-box",
              padding: "0px 10px",
              gap: "10px",
              ...sx,
            }}
          >
            {!hideIcon && <LastCalendarIcon w={15} />}
            <Typography
              sx={{
                font: "inherit",
                color: formatDate ? "black" : adjustHexColor("#000000", 50),
              }}
            >
              {formatDate ? formatDate : getPlaceholder(dateFormat)}
            </Typography>
          </Stack>
        );
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ width: "fit-content" }} onClick={handleClick}>
        {renderHeaderComponent()}
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            mt: "5px",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            p: 0,
            borderRadius: "5px",
          },
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            boxShadow: 1,
            cursor: "pointer",
            border: "1px solid #E9EDF2",
            padding: 0,
            borderRadius: "5px",
          }}
        >
          <DateCalendar value={selectedDate} onChange={handleDateChange} />
        </Box>
      </Popover>
    </LocalizationProvider>
  );
};

export default DatePickerWithIcon;
