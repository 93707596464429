export type TimeMultiplierValue = 0 | 0.25 | 0.50 | 0.75 | 1 | 1.25 | 1.50 | 1.75 | 2

export type TimeMultiplieOptionsType = {
    _id: string;
    title: string;
    subTitle?: string;
    value: TimeMultiplierValue
}

export const TimeMultiplieOptions: TimeMultiplieOptionsType[] = [
    { _id: '1', title: '0.00x', value: 0 },
    { _id: '2', title: '0.25x', subTitle: '25% more', value: 0.25 },
    { _id: '3', title: '0.50x', subTitle: '50% more', value: 0.5 },
    { _id: '4', title: '0.75x', subTitle: '75% more', value: 0.75 },
    { _id: '5', title: '1x', subTitle: '100% more', value: 1 },
    { _id: '6', title: '1.25x', subTitle: '125% more', value: 1.25 },
    { _id: '7', title: '1.50x', subTitle: '150% more', value: 1.5 },
    { _id: '8', title: '1.75x', subTitle: '175% more', value: 1.75 },
    { _id: '9', title: '2x', subTitle: '200% more', value: 2 },
]