import React from "react";

// Lazy load the components
export const AddCalendarEvent = React.lazy(() =>
    import("./AddCalendarEvent").then((module) => ({
        default: module.AddCalendarEvent,
    }))
);
export const FilterButton = React.lazy(() =>
    import("./FilterButton").then((module) => ({ default: module.FilterButton }))
);
export const CalendarSettings = React.lazy(() =>
    import("./CalendarSettings").then((module) => ({
        default: module.CalendarSettings,
    }))
);
export const CalendarSettingsEditPopup = React.lazy(() =>
    import("./CalendarSettingsEditPopup").then((module) => ({
        default: module.CalendarSettingsEditPopup,
    }))
);
export const IconPopupCalendar = React.lazy(() =>
    import("./IconPopupCalendar").then((module) => ({
        default: module.IconPopupCalendar,
    }))
);

export const SidebarCalenderContainer = React.lazy(() =>
    import("./SidebarCalenderContainer").then((module) => ({
        default: module.SidebarCalenderContainer,
    }))
);
export const SidebarCalenderInfoCard = React.lazy(() =>
    import("./SidebarCalenderInfoCard").then((module) => ({
        default: module.SidebarCalenderInfoCard,
    }))
);
export const EventCard = React.lazy(() =>
    import("./EventCard").then((module) => ({ default: module.EventCard }))
);
export const ControlsCalendar = React.lazy(() =>
    import("./ControlsCalendar").then((module) => ({
        default: module.ControlsCalendar,
    }))
);
export const SidebarMiniCalender = React.lazy(() =>
    import("./SidebarMiniCalender").then((module) => ({
        default: module.SidebarMiniCalender,
    }))
);
export const CalendarViewSelect = React.lazy(() =>
    import("./CalendarViewSelect").then((module) => ({
        default: module.CalendarViewSelect,
    }))
);
export const RepeatOptionMenu = React.lazy(() =>
    import("./RepeatOptionMenu").then((module) => ({
        default: module.RepeatOptionMenu,
    }))
);

export const InCalendarEventPopup = React.lazy(() =>
    import("./InCalendarEventPopup").then((module) => ({
        default: module.InCalendarEventPopup,
    }))
);
export const SidebarCalendar = React.lazy(() =>
    import("./SidebarCalendar").then((module) => ({
        default: module.SidebarCalendar,
    }))
);
export const BookingLinkCalendarCreator = React.lazy(() =>
    import("./BookingLinkCalendarCreator").then((module) => ({
        default: module.BookingLinkCalendarCreator,
    }))
);
export const CalendarScheduleMenu = React.lazy(() =>
    import("./CalendarScheduleMenu").then((module) => ({
        default: module.CalendarScheduleMenu,
    }))
);

export const DaySelection = React.lazy(() =>
    import("./DaySelection").then((module) => ({ default: module.DaySelection }))
);
export const CalendarSchedulePopup = React.lazy(() =>
    import("./CalendarSchedulePopup").then((module) => ({
        default: module.CalendarSchedulePopup,
    }))
);
export const AppointmentMenu = React.lazy(() =>
    import("./AppointmentMenu").then((module) => ({
        default: module.AppointmentMenu,
    }))
);

export const ShowAsFilter = React.lazy(() =>
    import("./ShowAsFilter").then((module) => ({ default: module.ShowAsFilter }))
);

export const CalendarEventCardLong = React.lazy(() =>
    import("./CalendarEventCardLong").then((module) => ({
        default: module.CalendarEventCardLong,
    }))
);
export const CreateBookingLinkButton = React.lazy(() =>
    import("./CreateBookingLinkButton").then((module) => ({
        default: module.CreateBookingLinkButton,
    }))
);
export const ViewAllCalendarListed = React.lazy(() =>
    import("./ViewAllCalendarListed").then((module) => ({
        default: module.ViewAllCalendarListed,
    }))
);
export const BookingLinkCalendarPreview = React.lazy(() =>
    import("./BookingLinkCalendarPreview").then((module) => ({
        default: module.BookingLinkCalendarPreview,
    }))
);
export const NamePlackCard = React.lazy(() =>
    import("./NamePlackCard").then((module) => ({
        default: module.NamePlackCard,
    }))
);
export const LinkAnalyticsContainer = React.lazy(() =>
    import("./LinkAnalyticsContainer").then((module) => ({
        default: module.LinkAnalyticsContainer,
    }))
);
export const CalendarPicker = React.lazy(() =>
    import("./CalendarPicker").then((module) => ({
        default: module.CalendarPicker,
    }))
);
