import { Stack, SxProps, Typography } from "@mui/material";
import React from "react";

interface BalanceInfoViewProps {
  label: string;
  labelSx?: SxProps;
  value?: number;
}

const BalanceInfoView: React.FC<BalanceInfoViewProps> = ({
  label,
  value = 0,
  labelSx,
}) => {
  const formattedValue = value.toString().padStart(2, "0");

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "2px",
      }}
    >
      <Typography
        sx={{
          font: "normal normal 300 13px/16px Source Serif Pro",
          whiteSpace: "nowrap",
          ...labelSx,
        }}
      >
        {label}
      </Typography>
      <Typography sx={{ font: "normal normal 900 13px/16px Source Serif Pro" }}>
        {formattedValue}
      </Typography>
    </Stack>
  );
};

export default BalanceInfoView;
