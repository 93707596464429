import dayjs, { Dayjs } from "dayjs";

/**
 * Converts a date string in "YYYY-MM-DD" format to a Dayjs object.
 * @param dateStr - The date string in "YYYY-MM-DD" format.
 * @returns A Dayjs object or null if the date string is invalid.
 */
export const convertToDayjs = (dateStr: string): Dayjs | null => {
  const parsedDate = dayjs(dateStr, "YYYY-MM-DD");
  return parsedDate.isValid() ? parsedDate : null;
};

// --------------------------------------------------

/**
 * Converts a Dayjs object to a string in "YYYY-MM-DD" format.
 * @param dayjsDate - The Dayjs object to convert.
 * @returns A string in "YYYY-MM-DD" format or an empty string if the input is invalid.
 */
export const convertDayjsToString = (
  dayjsDate: Dayjs | undefined | null
): string => {
  return dayjsDate && dayjsDate.isValid() ? dayjsDate.format("YYYY-MM-DD") : "";
};

// --------------------------------------------------
/**
 * Converts the provided Dayjs object to HH:mm format.
 *
 * @param time - Dayjs instance representing the selected time.
 * @returns - Formatted time as HH:mm.
 */
export const convertToHHmm = (time: Dayjs): string => {
  return time.format("HH:mm");
};

// --------------------------------------------------

// Centralized configuration for date formatting
interface DateFormatConfig {
  locale: string;
  options: Intl.DateTimeFormatOptions;
}

const dateFormatConfig: DateFormatConfig = {
  locale: "en-US",
  options: {
    day: "numeric",
    month: "long",
    year: "numeric",
  },
};

/**
 * Formats a date string into a more readable format with an ordinal suffix.
 * @param dateString - The date string to format.
 * @returns The formatted date or an error message if the date is invalid.
 */
export const formatDate = (dateString: string): string => {
  // Validate input type and sanitize input
  const sanitizedDateString =
    typeof dateString === "string" ? dateString.trim() : "";
  if (!sanitizedDateString) {
    return "Invalid date";
  }

  // Create a Date object and validate its value
  const date = new Date(sanitizedDateString);
  if (isNaN(date.getTime())) {
    console.warn("Invalid date provided:", sanitizedDateString);
    return "Invalid date";
  }

  // Format the date using the centralized configuration
  let formattedDate: string;
  try {
    formattedDate = new Intl.DateTimeFormat(
      dateFormatConfig.locale,
      dateFormatConfig.options
    ).format(date);
  } catch (error) {
    console.error("Date formatting failed:", error);
    return "Date formatting error";
  }

  const day = date.getDate();

  // Determine the ordinal suffix
  const daySuffix = (day: number): string => {
    if ([11, 12, 13].includes(day)) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Safely replace day number with ordinal
  try {
    return formattedDate.replace(/\d+/, `${day}${daySuffix(day)}`);
  } catch (error) {
    console.error("Error in replacing day with ordinal:", error);
    return formattedDate; // Return formatted date without suffix if error occurs
  }
};

/* How to use : 


? Example date strings to format
const date1 = '2024-08-19'; // Standard date string
const date2 = '   2024-12-01  '; // Date string with extra spaces
const date3 = 'invalid-date'; // Invalid date string

? Using the formatDate function
const formattedDate1 = formatDate(date1); // Expected output: "August 19th, 2024"
const formattedDate2 = formatDate(date2); // Expected output: "December 1st, 2024"
const formattedDate3 = formatDate(date3); // Expected output: "Invalid date"

*/

// --------------------------------------

export interface FormattedDateType {
  day: string;
  date: string;
  month: string;
  year: string;
}

/**
 * Function to return a formatted date object containing:
 * - day (e.g., "Monday")
 * - date (e.g., "13th")
 * - month (e.g., "January")
 * - year (e.g., "2025")
 *
 * @param date - The date as a string (e.g., "2025-01-13")
 * @returns An object with properties: day, date, month, year
 *
 * Example:
 * getFormattedDate('2025-01-13')
 * returns:
 * {
 *   day: 'Monday',
 *   date: '13th',
 *   month: 'January',
 *   year: '2025'
 * }
 */
export function getFormattedDateObject(date: string): FormattedDateType {
  // Split the input string into components: year, month, and day
  const [year, month, day] = date.split("-").map(Number);

  // Get the day of the week (0 = Sunday, 1 = Monday, etc.)
  const dateObject = new Date(year, month - 1, day);
  const dayOfWeek = dateObject.getDay();

  let dayName: string;
  let monthName: string;

  // Switch case for days of the week
  switch (dayOfWeek) {
    case 0:
      dayName = "Sunday";
      break;
    case 1:
      dayName = "Monday";
      break;
    case 2:
      dayName = "Tuesday";
      break;
    case 3:
      dayName = "Wednesday";
      break;
    case 4:
      dayName = "Thursday";
      break;
    case 5:
      dayName = "Friday";
      break;
    case 6:
      dayName = "Saturday";
      break;
    default:
      dayName = "";
  }

  // Switch case for months
  switch (month - 1) {
    case 0:
      monthName = "January";
      break;
    case 1:
      monthName = "February";
      break;
    case 2:
      monthName = "March";
      break;
    case 3:
      monthName = "April";
      break;
    case 4:
      monthName = "May";
      break;
    case 5:
      monthName = "June";
      break;
    case 6:
      monthName = "July";
      break;
    case 7:
      monthName = "August";
      break;
    case 8:
      monthName = "September";
      break;
    case 9:
      monthName = "October";
      break;
    case 10:
      monthName = "November";
      break;
    case 11:
      monthName = "December";
      break;
    default:
      monthName = "";
  }

  // Function to add the correct suffix to the day (e.g., "st", "nd", "rd", "th")
  const suffix = (day: number): string => {
    if (day >= 11 && day <= 13) return "th"; // Special case for 11th, 12th, 13th
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const formattedDate = `${day}${suffix(day)}`; // Format the date with the appropriate suffix

  return {
    day: dayName, // The day of the week (e.g., "Monday")
    date: formattedDate, // The day of the month with suffix (e.g., "13th")
    month: monthName, // The month (e.g., "January")
    year: year.toString(), // The year (e.g., "2025")
  };
}

// Example usage:
// const dateString = '2025-01-13';
// const formattedDate = getFormattedDate(dateString);
// console.log(formattedDate);
// Expected output: { day: 'Monday', date: '13th', month: 'January', year: '2025' }
