import { IconButton, Stack } from "@mui/material";
import React, { useRef } from "react";
import {
    PauseBoldIcon,
    PlayCircleIcon,
    StopBoldIcon,
} from "../../../../../images";

interface MsgVideoPreviewProps {
    videoData: any;
}

const MsgVideoPreview: React.FC<MsgVideoPreviewProps> = ({ videoData }) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const handlePlay = () => {
        videoRef.current?.play();
    };

    const handlePause = () => {
        videoRef.current?.pause();
    };

    const handleStop = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    };

    return (
        <Stack
            sx={{
                width: "100%",
                maxWidth: "400px",
                boxSizing: "border-box",
                position: "relative",
                "&:before": {
                    content: '""',
                    display: "block",
                    paddingTop: "50%", // This makes the height 50% of the width
                },
            }}
        >
            <video
                ref={videoRef}
                src={videoData.fileUrl}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                }}
            >
                Your browser does not support the video tag.
            </video>

            <Stack
                sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    paddingBottom: "5px",
                    position: "absolute",
                    zIndex: 5,
                    bottom: "5px",
                    left: "36%",
                }}
            >
                <IconButton onClick={handlePause}>
                    <PauseBoldIcon w={20} />
                </IconButton>

                <IconButton onClick={handlePlay}>
                    <PlayCircleIcon w={20} />
                </IconButton>

                <IconButton onClick={handleStop}>
                    <StopBoldIcon w={20} />
                </IconButton>
            </Stack>
        </Stack>
    );
};

export default MsgVideoPreview;