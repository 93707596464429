import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { AddNowTeamMembers } from "../../../../common";

interface SearchTeamcardProps {}

const SearchTeamcard: React.FC<SearchTeamcardProps> = ({}) => {
  return (
    <Stack
      sx={{
        height: "45px",
        width: "100%",
        flexShrink: 0,
        flexDirection: "row",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 17px 0px 8px",
        gap: "6px",
      }}
    >
      <Box sx={{ fontSize: "35px" }}>😄</Box>
      <Stack sx={{ width: "calc(100% - 145px)", gap: "3px" }}>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            font: "normal normal 600 18px/23px Source Serif Pro",
          }}
        >
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Harum
          molestias rerum eligendi ullam odit? Aliquam.
        </Typography>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            font: "normal normal 300 13px/16px Source Serif Pro",
          }}
        >
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Harum
          molestias rerum eligendi ullam odit? Aliquam.
        </Typography>
      </Stack>
      <AddNowTeamMembers buttonType="invite" />
    </Stack>
  );
};

export default SearchTeamcard;
