import React from "react";
import { ScheduleTableUserViewInterface } from "../AttendanceScheduleTableUserViewType";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  ShowShiftContainer,
  WeekViewAttendanceArea,
  WeekViewDateArea,
} from "../../AttendanceScheduleTable/view";
import { visibleShiftInWeekDays } from "../../AttendanceScheduleTable/AttendanceScheduleSetting";

const ScheduleTableWeekUserView: React.FC<ScheduleTableUserViewInterface> = ({
  tableData,
}) => {
  const empData = tableData.employeeList;
  return (
    <Stack
      sx={{
        height: "100%",
        overflowX: "auto",
        scrollbarWidth: "none",
        boxSizing: "border-box",
      }}
    >
      <TableContainer
        sx={{
          height: "100%",
          scrollbarWidth: "none",
          boxShadow: "none",
          borderRadius: 0,
        }}
        component={Paper}
      >
        <Table stickyHeader>
          {/* Header */}
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderBottom: "2px solid #E9EDF2",
                  height: "90px",
                  padding: 0,
                  boxSizing: "border-box",
                  minHeight: 0,
                }}
              >
                <Stack sx={{ flexDirection: "row", height: "100%" }}>
                  {/* ------- select-all checkbox here ------- */}
                  {/* <TableHeaderSelectAllContainer /> */}

                  {/* All dates and week-one/ week-two/ ... list */}
                  <Stack
                    sx={{
                      // paddingLeft: "17px",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* 7 day date map (  like monday-01 / tue-02 ).... */}
                    <Stack
                      sx={{
                        flexDirection: "row",
                        gap: "2px",
                      }}
                    >
                      {tableData.dateList.map((date, index) => (
                        <WeekViewDateArea
                          dateList={date}
                          key={index}
                          hideUnfilledShiftHover
                        />
                      ))}
                    </Stack>

                    {/* morning-shift / day-shift / night-shift box here */}
                    <Stack
                      sx={{
                        flexDirection: "row",
                        gap: "2px",
                      }}
                    >
                      {tableData.dateList.map((date, index) => (
                        <ShowShiftContainer
                          useAsUserView
                          key={index}
                          visibleShift={
                            visibleShiftInWeekDays.find(
                              (item) =>
                                item.dayIndex == new Date(date.date).getDay()
                            )!
                          }
                        />
                      ))}
                    </Stack>
                  </Stack>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>

          {/* ------- Table Body -------- */}
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  height: "fit-content",
                  padding: 0,
                  boxSizing: "border-box",
                  minHeight: "150px",
                  display: "flex",
                }}
              >
                <Stack sx={{ flexDirection: "row", height: "100%" }}>
                  {/* ------Fixed (sticky) Employee name + check-box and details Column ------ */}
                  {/* <EmployeeDetailsInfoView empData={empData} /> */}

                  {/* Scrollable Time Slots */}
                  <Stack
                    sx={{
                      flexDirection: "row",
                      paddingTop: "20px",
                      gap: "2px",
                      // paddingLeft: "17px",
                    }}
                  >
                    {/* here date list are map and whole array of attendance list and sppecific date are passed inside this component ,
                    inside this component , if specific date are avilable in attendance list then it render else show no-shift card 
                  [ Here pass header date -> 7 Days fixed(1-week exact) ]  */}
                    {tableData.dateList.map((date, index) => (
                      <WeekViewAttendanceArea
                        key={index}
                        visibleShift={
                          visibleShiftInWeekDays.find(
                            (item) =>
                              item.dayIndex == new Date(date.date).getDay()
                          )!
                        }
                        rowDate={date}
                        employeeData={empData}
                      />
                    ))}
                  </Stack>
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
          {/* ------------- Table body end ------------ */}
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default ScheduleTableWeekUserView;
