import React from "react";
import {
  SmallThreeDotMenu,
  SmallThreeDotMenuListInterface,
} from "../../../../common";
import {
  BoardIcon,
  CalendarViewIcon,
  GanttIcon,
  TimelineIcon,
  TreeIcon,
} from "../../../../../images";

interface ObjectiveTimeLineThreeDotMenuProps {
  onClick: (value: ProjectObjectiveViewEnum) => void;
}

/** Enum for objective view types */
export enum ProjectObjectiveViewEnum {
  TREE_VIEW = "treeView",
  CALENDAR_VIEW = "calendarView",
  TIMELINE_VIEW = "timelineView",
  BOARD_VIEW = "boardView",
  GANTT_VIEW = "ganttView",
}

const MENU_LISTS: SmallThreeDotMenuListInterface[] = [
  {
    id: 1,
    icon: TreeIcon,
    text: ProjectObjectiveViewEnum.TREE_VIEW,
    LANG: "Tree View",
  },
  {
    id: 2,
    icon: CalendarViewIcon,
    text: ProjectObjectiveViewEnum.CALENDAR_VIEW,
    LANG: "Calendar View",
  },
  {
    id: 3,
    icon: TimelineIcon,
    text: ProjectObjectiveViewEnum.TIMELINE_VIEW,
    LANG: "Timeline View",
  },
  {
    id: 4,
    icon: BoardIcon,
    text: ProjectObjectiveViewEnum.BOARD_VIEW,
    LANG: "Board View",
  },
  {
    id: 5,
    icon: GanttIcon,
    text: ProjectObjectiveViewEnum.GANTT_VIEW,
    LANG: "Gantt View",
  },
];

const ObjectiveTimeLineThreeDotMenu: React.FC<
  ObjectiveTimeLineThreeDotMenuProps
> = ({ onClick }) => {
  return (
    <SmallThreeDotMenu
      menuItems={MENU_LISTS}
      hoverText="Objectives View"
      onClickAction={(val) => onClick(val as ProjectObjectiveViewEnum)}
    />
  );
};

export default ObjectiveTimeLineThreeDotMenu;
