import React from "react";
import { UserTypeEnum } from "../../AdminConsolePageType";
import { AddAdminPopup } from "../AddAdminPopup";
import { AddManagerPopup } from "../AddManagerPopup";
import { AddTeamleadPopup } from "../AddTeamleadPopup";
import { AddUserPopup } from "../AddUserPopup";
import { AddGuestPopup } from "../AddGuestPopup";
import { AddAuditorPopup } from "../AddAuditorPopup";

interface AddUserContainerProps {
  addUserType: UserTypeEnum;
  modalClose?: () => void;
  users?: any[] | null;
}

const AddUserContainer: React.FC<AddUserContainerProps> = ({
  addUserType,
  modalClose = () => { },
  users = [],
}) => {
  const getPopupComponent = (value: UserTypeEnum): React.ReactNode => {
    switch (value) {
      case UserTypeEnum.ADMIN:
        return <AddAdminPopup onCloseClick={() => modalClose()} />;
      case UserTypeEnum.MANAGER:
        return <AddManagerPopup users={users} onCloseClick={() => modalClose()} />;
      case UserTypeEnum.TEAM_LEAD:
        return <AddTeamleadPopup users={users} onCloseClick={() => modalClose()} />;
      case UserTypeEnum.USER:
        return <AddUserPopup users={users} onCloseClick={() => modalClose()} />;
      case UserTypeEnum.GUEST:
        return <AddGuestPopup onCloseClick={() => modalClose()} />;
      case UserTypeEnum.AUDITOR:
        return <AddAuditorPopup onCloseClick={() => modalClose()} />;
    }
  };
  return <div>{getPopupComponent(addUserType)}</div>;
};

export default AddUserContainer;
