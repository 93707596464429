import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import styles from "./CustomAddMemberPopover.module.css";
import { ButtonSmall } from "../Buttons";
import { DropdownWithImgBadgeInterface } from "../DropdownWithImageBadge/DropdownWithImageBadgeTypes";
import { ButtonType, SmallButtonColor } from "../Buttons/AllButtonProps";
import { useTranslation } from "react-i18next";
import Custom from "./Custom";

interface AddMemberProps {
    setSelectedUsers?:any
    onAdd?: any;
    onCancel?: any;
    userList?:any
}

const CustomAddMember: React.FC<AddMemberProps> = ({ onCancel, onAdd, userList, setSelectedUsers }) => {
    const {t}=useTranslation()
    const usersArray:DropdownWithImgBadgeInterface[] = userList ? Object.values(userList) : [];
    return (
        <Stack
            className={styles["add-member"]}
        >
            <Typography className={styles["add-member__title"]}>
                Add Member
            </Typography>
            <Stack className={styles["add-member__content"]}>
                <Stack className={styles["add-member__recipient-row"]}>
                    <Typography className={styles["add-member__recipient-label"]}>
                        Members
                    </Typography>
                    <Custom
                        options={usersArray}
                        onChangeSelection={(userList:any) => setSelectedUsers(userList)}
                    />
                </Stack>
            </Stack>
            <Stack className={styles["add-member__actions"]}>
                <ButtonSmall
                    onClick={onCancel}
                    label={t('MESSAGE.CANCEL')}
                    types={ButtonType.Button}
                    colorVarient={SmallButtonColor.Transparent}
                />
                <ButtonSmall
                    label= {t('VIDEO_CENTER.MEETING.ADD')}
                    onClick={onAdd}
                    types={ButtonType.Button}
                    colorVarient={SmallButtonColor.MessagePurple}
                />
            </Stack>
        </Stack>
    );
};

export default CustomAddMember;
