import React, { memo, useMemo } from "react";
import { Box, Stack } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../../../redux/store";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from "react-beautiful-dnd";
import { handleDragEnd__boardCore } from "./utils.core";
import { TaskBody, TaskHeader } from "./TaskBox";
import { adjustHexColor } from "../../../../../../../Utils";
import styles from "../../../../components/BoardCard/BoardCardDisplay/BoardCardDisplay.module.css";
interface BoardCoreProps {
    l?: boolean;
}

const BoardCore: React.FC<BoardCoreProps> = ({ l }) => {
    const dispatch = useDispatch();
    const workspaceState = useSelector((state: RootState) => state?.workspace);

    // Memoize userAllTasks to prevent unnecessary re-renders
    const userAllTasks = useMemo(
        () => workspaceState?.userAllTasks || [],
        [workspaceState?.userAllTasks]
    );

    // useEffect(() => {
    //     if (userAllTasks.length) {
    //         console.log("userAllTasks updated", userAllTasks);
    //     }
    // }, [userAllTasks]);

    const onDragEnd = (result: DropResult) => {
        if (workspaceState?.userAllTasks) {
            handleDragEnd__boardCore(result, userAllTasks, dispatch);
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box
                sx={{
                    minWidth: `${l ? "calc(1200px + 15%)" : "100%"}`,
                    overflowX: "scroll",
                    overflowY: "hidden",
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    minHeight:"90dvh"
                }}
            >
                {userAllTasks &&
                    userAllTasks?.map((group: any, groupIndex: any) => (
                        <Droppable key={group?.groupName} droppableId={group?.groupName}>
                            {(provided, snapshot) => (
                                <Stack
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    sx={{
                                        border: "1px solid #ccc",
                                        borderRadius: "5px",
                                        padding: "2px",
                                        margin: "8px",
                                        // width: "255px",
                                        flex: 1,
                                        backgroundColor: adjustHexColor(
                                            group?.tasks?.[0]?.status?.customColorHex,
                                            50
                                        ),
                                    }}
                                >
                                    <TaskHeader taskData={group} />
                                    <Stack
                                        className={styles["board-card__body"]}
                                        sx={{
                                            bgcolor: snapshot?.isDraggingOver
                                                ? adjustHexColor(
                                                    group?.tasks?.[0]?.status?.customColorHex,
                                                    5
                                                )
                                                : adjustHexColor(
                                                    group?.tasks?.[0]?.status?.customColorHex,
                                                    8
                                                ),
                                            // maxHeight: "215px",
                                        }}
                                        minWidth={"225px"}
                                        direction={"column"}
                                    >
                                        {group?.tasks?.map((task: any, taskIndex: number) => (
                                            <Draggable
                                                key={task._id}
                                                draggableId={task._id} // Use task._id as draggableId
                                                index={taskIndex}
                                            >
                                                {(provided, snapshot) => (
                                                    <Stack
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        sx={{
                                                            padding: "8px",
                                                            marginBottom: "8px",
                                                            background: "#f5f5f5",
                                                            borderRadius: "4px",
                                                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                                        }}
                                                    >
                                                        {/* {task?.name || "Untitled Task"} */}
                                                        <TaskBody c={group?.tasks?.[0]?.status?.customColorHex} taskData={task} />
                                                    </Stack>
                                                )}
                                            </Draggable>
                                        ))}
                                    </Stack>
                                    {provided.placeholder}
                                </Stack>
                            )}
                        </Droppable>
                    ))}
            </Box>
        </DragDropContext>
    );
};

export default memo(BoardCore);
