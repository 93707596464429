import {Stack} from "@mui/material";
import React, {useCallback, useContext, useEffect} from "react";
import WaitingRoom from "../WaitingRoom";
import {ConferenceRoom} from "../ConferenceRoom";
import {
    removeGuestRequestAction,
    setIsAbleToJoinMeetingAction,
    setIsInstantMeetingAction,
    setIsNonBearishUserAction,
    setIsUserInvitedToMeetingAction,
    setIsUserIsHostAction,
    setJoinedUserTypeAction,
    setOnGoingMeetingAction,
    setUserAccountDetailsAction,
    setWaitingParticipantsAction
} from "../../../../redux/Slice/videoCenter/VideoCenterSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/store";
import {VideoCenterState} from "../../../../redux/Slice/videoCenter/VideoCenterTypes";
import {useParams} from "react-router-dom";
import {AuthState, getMeetingDetails, responseToJoinMeeting} from "../../../../redux";
import {MeetingContext} from "../../../Povider";
import {FullScreenLoader} from "../../../common";
//@ts-ignore
import {NotificationContainer, NotificationManager} from "react-notifications/lib";
import JoinRequestCard from "../components/JoinRequestCard/JoinRequestCard";
import "../Notifection/Notification.Module.css";

const VideoConferenceRoom = ({}) => {
    const [loading, setLoading] = React.useState(false);
    const params = useParams();
    const meetingContext: any = useContext(MeetingContext);
    const dispatch = useDispatch();
    const {user} = useSelector((state: RootState) => state.auth as AuthState);
    const {onGoingMeeting} = useSelector(
        (state: RootState) => state.videoCenter as VideoCenterState
    );

    const handleGetMeetingDetails = useCallback(async () => {
        setLoading(true);
        if (!user?.data?._id) {
            await dispatch(setJoinedUserTypeAction("GUEST_USER"));
            await dispatch(setIsNonBearishUserAction(true));
            const meetingData = await meetingContext?.getMeeting({
                meetingId: params?.id,
            });
            const meetingType: any = (meetingData?.meetingType === "INSTANT");
            await dispatch(setIsInstantMeetingAction(meetingType));
            await dispatch(setOnGoingMeetingAction(meetingData));
            await dispatch(setIsUserIsHostAction(false));
            await dispatch(setIsUserInvitedToMeetingAction(false));
        }

        if (user?.data?._id) {
            await dispatch(setJoinedUserTypeAction("AUTHENTICATED_USER"));
            const meetingData = await meetingContext?.getMeeting({
                meetingId: params?.id,
            });
            const payload = {
                firstName: user?.data?.firstName,
                lastName: user?.data?.lastName,
                email: user?.data?.email,
                _id: user?.data?._id,
                token: user?.token,
            }
            dispatch(setUserAccountDetailsAction(payload));

            if (meetingData?.meetingType === "INSTANT") {
            await dispatch(setIsInstantMeetingAction(true));
            }
            if (meetingData?.meetingType === "SCHEDULED") {
                await dispatch(setIsInstantMeetingAction(false));
                await dispatch(getMeetingDetails(meetingData?._id));
            }

            await dispatch(setOnGoingMeetingAction(meetingData));
            if (meetingData?.meetingHostId === user?.data?._id) {
                dispatch(setIsUserIsHostAction(true));
                dispatch(setIsUserInvitedToMeetingAction(true));
                dispatch(setIsAbleToJoinMeetingAction(true));
            } else {
                dispatch(setIsUserIsHostAction(false));
                dispatch(setIsUserInvitedToMeetingAction(false));
                dispatch(setIsAbleToJoinMeetingAction(false));
            }
        }

        setLoading(false);
    }, [params?.id, user?.data?._id, dispatch]);

    const handleGuestResponse = async (userDetails: any, chimeMeetingId: any, status: any) => {
        try {
            if (status === "wait") {
                const payload = {
                    userId: userDetails?.userId,
                    userName: userDetails?.userName,
                    userEmail: userDetails?.userEmail,
                    chimeMeetingId: chimeMeetingId,
                }
                await dispatch(setWaitingParticipantsAction(payload));
            }
            await dispatch(
                responseToJoinMeeting({
                    meetingId: params.id,
                    userId: userDetails?.userId,
                    chimeMeetingId,
                    meeting_id: onGoingMeeting?.meeting?._id,
                    status,
                })
            );
            await dispatch(removeGuestRequestAction(userDetails?.userId));
        } catch (error) {
            console.error(`Error handling guest ${status}:`, error);
        }
    };

    useEffect(() => {
        const {notificationData, meeting} = onGoingMeeting || {};
        const {userDetails} = notificationData || {};

        if (notificationData && meeting?.meetingHostId === user?.data?._id) {
            const handleResponse = (status: any) =>
                handleGuestResponse(userDetails, meeting.chimeMeetingId, status);

            NotificationManager.info(
                <JoinRequestCard
                    cardType="joinRequest"
                    userName={userDetails?.userName || "Guest"}
                    handleReject={() => handleResponse("rejected")}
                    handleAccept={() => handleResponse("accepted")}
                    OnHandleWait={() => handleResponse("wait")}
                />,
                "Join Request",
                0
            );
        }
    }, [onGoingMeeting?.notificationData]);

    useEffect(() => {
        (async () => await handleGetMeetingDetails())();
    }, [handleGetMeetingDetails]);

    if (loading) {
        return <FullScreenLoader/>;
    }

    return (
        <Stack
            p={2}
            sx={{
                flexGrow: 1,
                flexDirection: "row",
                gap: 4,
                padding: "0",
                width: "100%",
            }}
        >
            {onGoingMeeting?.isReadyToJoinMeeting ? <ConferenceRoom/> : <WaitingRoom/>}
            <NotificationContainer/>
        </Stack>
    )
};

export default VideoConferenceRoom;