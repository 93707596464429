import { Drawer, IconButton, Stack } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import { GreenArrowButton, MainActionButton } from "../../../Buttons";
import styles from "../../DrawerNavbar.module.css";
import { ButtonType, MainActionColor } from "../../../Buttons/AllButtonProps";
import DrawerSectionText from "../../DrawerSectionText";
import { WorkspaceList } from "./components";
import { WelcomeBannerPublicShort } from "../../../WelcomeBannerPublicShort";

import { CreateConnectionGearIcon } from "../../../CreateConnectionGearIcon";
import {
  confluence__loginUrl__handle,
  Jira__loginUrl__handle,
} from "../../../../../redux";
import { useDispatch } from "react-redux";

type WorkspaceDrawerProps = {
  drawerOpen: boolean;
  drawerCloser: () => void;
  shared?: boolean;
};

const DrawerContainer = styled("div")({
  position: "fixed",
  top: 0,
  height: "100vh",
  zIndex: 1100, // Ensure the drawer is behind the navbar
});
const WorkspaceDrawer: React.FC<WorkspaceDrawerProps> = ({
  drawerOpen,
  drawerCloser,
  shared = false,
}) => {
  const dispatch = useDispatch();

  const loginToConfluence = async () => {
    try {
      // Dispatch the action to get the Confluence login URL
      const action = confluence__loginUrl__handle();
      const r: any = await dispatch(action);

      // Automatically redirect the user to the Confluence login URL
      if (r?.data?.loginUrl) {
        window.location.href = r?.data.loginUrl; // Redirect to Confluence login URL
      } else {
        console.log("Confluence login URL not found");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loginToJira = async () => {
    try {
      // Dispatch the action to get the Jira login URL
      const action = Jira__loginUrl__handle();
      const r: any = await dispatch(action);

      // Automatically redirect the user to the Jira login URL
      if (r?.data?.loginUrl) {
        window.location.href = r?.data.loginUrl; // Redirect to Jira login URL
      } else {
        console.log("Jira login URL not found");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <DrawerContainer>
        <Drawer
          sx={{
            position: "absolute",
            top: 0,
            height: "100vh",
            width: shared ? "250px" : "215px",
            // left: '-240px', // Start hidden behind the Navbar
            transition: drawerOpen
              ? "left 225ms ease-out"
              : "left 195ms ease-in",
            left: drawerOpen
              ? shared
                ? "5px"
                : "30px"
              : shared
                ? "-250px"
                : "-215px",
            zIndex: 1100, // Ensure the drawer is behind the navbar
            "& .MuiPaper-root": {
              position: "absolute",
              top: 0,
              height: "100vh",
              width: shared ? "250px" : "215px",
              left: 0,
              boxSizing: "border-box",
              zIndex: 1100, // Ensure the paper is also behind the navbar
            },
          }}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
        >
          <Stack
            bgcolor={"white"}
            direction="column"
            flex={1}
            position={"relative"}
            height={"100%"}
            width={shared ? "250px !important" : "215px"}
            className={`${styles.drawerNavbar__drawer}`}
          >
            {/* Company logo and name here */}
            {!shared ? (
              <CompanyIconAndName arrowButtonClickHandler={drawerCloser} />
            ) : (
              <div style={{ position: "absolute", right: "4px", top: "8px" }}>
                <IconButton sx={{ p: "8px" }} onClick={drawerCloser}>
                  <GreenArrowButton />
                </IconButton>
              </div>
            )}
            {shared && (
              <Stack
                mb={"20px"}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
              >
                <WelcomeBannerPublicShort />
              </Stack>
            )}
            <Stack className={styles.drawerNavbar__content}>
              {/* Buttons and other lists here */}
              {!shared && (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <MainActionButton
                    sx={{ marginY: "25px" }}
                    onClick={() => console.log("Home button clicked")}
                    colorVarient={MainActionColor.Orange}
                    label="Create"
                    types={ButtonType.Button}
                  />
                  <CreateConnectionGearIcon
                    categories={["workspace"]}
                    onServiceClick={(s: any) => {
                      if (s === "Jira") { loginToJira() } else if (s === "Confluence") { loginToConfluence() }
                    }}
                  />
                </Stack>
              )}
              {/* <DrawerSectionText title="Connections" />
              <MainActionButton
                sx={{ marginY: "15px" }}
                onClick={() => {
                  loginToConfluence();
                }}
                colorVarient={MainActionColor.Orange}
                label="Confluence"
                types={ButtonType.Button}
              />
              <MainActionButton
                sx={{ marginY: "15px" }}
                onClick={() => {
                  loginToJira();
                }}
                colorVarient={MainActionColor.Orange}
                label="Jira"
                types={ButtonType.Button}
              /> */}
              <DrawerSectionText title="Workspaces" />
              <Stack
                height="100%"
                overflow="hidden"
                maxHeight="80%"
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "0 !important", // hide scrollbar on Chrome, Safari, etc.
                  },
                  scrollbarWidth: "none", // hide scrollbar on Firefox
                  overflowX: "hidden",
                  overflowY: "scroll",
                }}
              >
                <WorkspaceList filterWorkspace={false} />
              </Stack>
            </Stack>
          </Stack>
          {/* {showSearchContainer && (
            <Box
              sx={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1000,
              }}
            >
              <CommandControlSearchContainer />
            </Box>
          )} */}
        </Drawer>
      </DrawerContainer>
    </>
  );
};

export default WorkspaceDrawer;
