import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminPortalState } from "./AdminPortalType";
import * as AdminActions from "./AdminPortalSliceAction"; // Import all actions as an object

// Initial state for the admin slice
const initialState: AdminPortalState = {
    error: null,
    data: null,
    companyInfo: {},
    teams: [],
    teamMembers: [],
    invitedTeamMembers: [],
    selectedTeamDetails: null,
    reCall_AdminPortal_APIs: "All",
};

// Create admin slice
const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        // Handles team preview selection
        teamPreviewHandler: (state, { payload }: PayloadAction<string | null>) => {
            state.selectedTeamDetails = payload;
        },

        // Handles API recall logic
        reCallAdminAPIsHandler: (
            state,
            { payload }: PayloadAction<string | null>
        ) => {
            state.reCall_AdminPortal_APIs = payload ?? null;
        },

        ...AdminActions,
    },
});

// Export actions with custom names
export const {
    teamPreviewHandler,
    reCallAdminAPIsHandler,
    inviteUsers__Request,
    inviteUsers__Failure,
    inviteUsers__Success,
    getAllInvitationsForCompany__Success,
    createTeam__Success,
    getAllTeamsForCompany__Success,
    getCompanyDetails__Success,
    updateCompanyDetails__Success,
    addCompanyDomain__Success,
    getAllUserForCompany__Success,
} = adminSlice.actions;

// Export reducer
export default adminSlice.reducer;
