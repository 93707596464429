import {createSlice} from "@reduxjs/toolkit";
import {MessagesState} from "./messageTypes";
import {
    addMembersToChannelSuccess,
    addReactionToMessageFailure,
    addReactionToMessageRequest,
    addReactionToMessageSuccess,
    changeMessagesActivitiesStatusFailure,
    changeMessagesActivitiesStatusRequest,
    changeMessagesActivitiesStatusSuccess,
    changeParticipantsRoleFailure,
    changeParticipantsRoleRequest,
    changeParticipantsRoleSuccess,
    createNewConnectionFailure,
    createNewConnectionRequest,
    createNewConnectionSuccess,
    forwardMessageFailure,
    forwardMessageRequest,
    forwardMessageSuccess,
    getChannelActivitiesFailure,
    getChannelActivitiesRequest,
    getChannelActivitiesSuccess,
    getChannelMessageFailure,
    getChannelMessageRequest,
    getChannelMessageSuccess,
    getExternalConnectionsFailure,
    getExternalConnectionsRequest,
    getExternalConnectionsSuccess,
    getMessageActivitiesFailure,
    getMessageActivitiesRequest,
    getMessageActivitiesSuccess,
    getPinnedMessagesFailure,
    getPinnedMessagesRequest,
    getPinnedMessagesSuccess,
    getSlackUserProfileFailure,
    getSlackUserProfileRequest,
    getSlackUserProfileSuccess,
    getSlackUsersFailure,
    getSlackUsersRequest,
    getSlackUsersSuccess,
    getTeamsUserProfileFailure,
    getTeamsUserProfileRequest,
    getTeamsUserProfileSuccess,
    getUserConnectionsFailure,
    getUserConnectionsRequest,
    getUserConnectionsSuccess,
    leaveSlackConversationFailure,
    leaveSlackConversationRequest,
    leaveSlackConversationSuccess,
    markMessagesAsReadFailure,
    markMessagesAsReadRequest,
    markMessagesAsReadSuccess,
    pinMessageFailure,
    pinMessageRequest,
    pinMessageSuccess,
    removeReactionToMessageFailure,
    removeReactionToMessageRequest,
    removeReactionToMessageSuccess,
    scheduleMessageFailure,
    scheduleMessageRequest,
    scheduleMessageSuccess,
    sendMessageFailure,
    sendMessageRequest,
    sendMessageSuccess,
    setSnoozeMessagesActivitiesFailure,
    setSnoozeMessagesActivitiesRequest,
    setSnoozeMessagesActivitiesSuccess,
    updateConnectionFailure,
    updateConnectionRequest,
    updateConnectionSuccess,
    updateMessageFailure,
    updateMessageRequest,
    updateMessageSuccess,
} from "./MessageSliceActions";
import {sortChats} from "../../../Utils/sortChats";

/*
 * ==============================================
 * Define the initial state for the message slice
 * ==============================================
 */
interface Channel {
    _id: string;
    createdAt: string;
    lastMessageDate?: string;
    lastMessageId?: string;
    lastMessageContent?: string;
}

const initialState: MessagesState = {
    channelActivity: null, // Channel activity
    loading: false, // Loading state
    error: null, // Error state
    slackUsers: [],
    teamsUsers: [],
    messageActivity: [],
    slackUserProfile: {},
    teamsUserProfile: {},
    pinnedChannels: {},
    directChannel: {},
    channelChats: {},
    chatTabState: {
        selectedConversation: {
            conversationId: "",
            conversationType: "",
            conversationDetails: {},
            isUserLeftTheConversation: false,
            isUserGuest: false,
            conversationPlatform: "",
            conversationName: "",
        },
        conversationChats: {},
    },
    calenderTabState: {},
    taskTabState: {},
    traxTabState: {},
    rightSidebarsStates: {
        chatDetailsSidebar: {
            pinnedChats: {}
        },
        callSidebar: {},
        cloudStorageSidebar: {},
        mailSidebar: {},
        meetingSidebar: {},
        tasksSidebar: {},
        calenderSidebar: {},
    },
};

/*
 * ==============================================
 * Create a slice for Fetch Messages
 * ==============================================
 */
const messageSlice = createSlice({
    name: "message", // Name of the slice
    initialState, // Initial state of the slice

    reducers: {
        // Reducers to handle different actions
        getChannelMessageRequest,
        getChannelMessageSuccess,
        getChannelMessageFailure,

        sendMessageRequest,
        sendMessageSuccess,
        sendMessageFailure,

        getUserConnectionsRequest,
        getUserConnectionsSuccess,
        getUserConnectionsFailure,
        getExternalConnectionsRequest,
        getExternalConnectionsSuccess,
        getExternalConnectionsFailure,

        createNewConnectionRequest,
        createNewConnectionSuccess,
        createNewConnectionFailure,

        updateConnectionFailure,
        updateConnectionSuccess,
        updateConnectionRequest,

        updateMessageFailure,
        updateMessageSuccess,
        updateMessageRequest,

        scheduleMessageFailure,
        scheduleMessageSuccess,
        scheduleMessageRequest,

        forwardMessageFailure,
        forwardMessageSuccess,
        forwardMessageRequest,

        addReactionToMessageFailure,
        addReactionToMessageSuccess,
        addReactionToMessageRequest,

        removeReactionToMessageFailure,
        removeReactionToMessageSuccess,
        removeReactionToMessageRequest,

        markMessagesAsReadFailure,
        markMessagesAsReadSuccess,
        markMessagesAsReadRequest,

        changeParticipantsRoleFailure,
        changeParticipantsRoleSuccess,
        changeParticipantsRoleRequest,

        getChannelActivitiesRequest,
        getChannelActivitiesSuccess,
        getChannelActivitiesFailure,

        getMessageActivitiesRequest,
        getMessageActivitiesSuccess,
        getMessageActivitiesFailure,

        changeMessagesActivitiesStatusRequest,
        changeMessagesActivitiesStatusSuccess,
        changeMessagesActivitiesStatusFailure,

        setSnoozeMessagesActivitiesRequest,
        setSnoozeMessagesActivitiesSuccess,
        setSnoozeMessagesActivitiesFailure,

        addMembersToChannelSuccess,

        /* get slack users */
        getSlackUsersRequest,
        getSlackUsersSuccess,
        getSlackUsersFailure,

        /* get slack users */
        getSlackUserProfileRequest,
        getSlackUserProfileSuccess,
        getSlackUserProfileFailure,
        /* get slack users */
        getTeamsUserProfileRequest,
        getTeamsUserProfileSuccess,
        getTeamsUserProfileFailure,

        leaveSlackConversationSuccess,
        leaveSlackConversationRequest,
        leaveSlackConversationFailure,

        pinMessageRequest,
        pinMessageSuccess,
        pinMessageFailure,

        getPinnedMessagesRequest,
        getPinnedMessagesSuccess,
        getPinnedMessagesFailure,

        addSelectedConversationId: (state, action) => {
            state.chatTabState.selectedConversation.conversationId = action.payload;
        },
        addSelectedConversationDetails: (state, action) => {
            state.chatTabState.selectedConversation.conversationDetails = action.payload;
            state.chatTabState.selectedConversation.conversationName = action.payload?.displayName;
            state.chatTabState.selectedConversation.conversationType = action.payload?.type;
            state.chatTabState.selectedConversation.conversationPlatform = action.payload?.channelType;
            state.chatTabState.selectedConversation.isUserLeftTheConversation = action.payload?.currentUserLeave;
        },

        resetSelectedConversation: (state) => {
            state.chatTabState.selectedConversation = {
                conversationId: "",
                conversationType: "",
                conversationDetails: {},
                isUserLeftTheConversation: false,
                isUserGuest: false,
                conversationPlatform: "",
                conversationName: "",
            };
        },

        addMessage(state, action) {
            const {message} = action.payload;
            if (message?._id) {
                state.chatTabState.conversationChats[message._id] = message;
            }
        },

        addEditMessageContentState(state, action) {
            const message = state.chatTabState.conversationChats[action.payload.id?._id];
            if (message) {
                message.content = action?.payload?.content;
                message.isEdited = true;
            } else {
                console.error("Message not found:", action.payload.id);
            }
        },

        setIsLoading(state, action) {
            state.loading = action.payload;
        },

        removeMessageActivity(state, action) {
            delete state.messageActivity[action.payload];
        },

        handlePinAndUnPinChannel(state, action) {
            const {payload} = action.payload;
            const {_id, isPinned} = payload;

            const updateChannel = (source: any, target: any) => {
                if (source[_id]) {
                    target[_id] = source[_id];
                    target[_id].isPinned = isPinned;
                    delete source[_id];
                }
            };

            if (isPinned) {
                updateChannel(state.channelChats, state.pinnedChannels);
                updateChannel(state.directChannel, state.pinnedChannels);
            } else {
                updateChannel(state.pinnedChannels, state.channelChats);
            }
        },

        sortChannelsBasedOnLastMessage(state, action) {
            const {message} = action.payload;

            if (state.directChannel[message?.channelId]) {
                state.directChannel[message?.channelId].lastMessageDate = message?.createdAt;
                state.directChannel = sortChats(state.directChannel);
            }

            if (state.channelChats[message?.channelId]) {
                state.channelChats[message?.channelId].lastMessageDate = message?.createdAt;
                state.channelChats = sortChats(state.channelChats);
            }

            if (state.pinnedChannels[message?.channelId]) {
                state.pinnedChannels[message?.channelId].lastMessageDate = message?.createdAt;
                state.pinnedChannels = sortChats(state.pinnedChannels);
            }
        },

        updateMessageUnreadCount(state, action) {
            const {message} = action.payload;
            if (state.directChannel[message?.channelId]) {
                state.directChannel[message?.channelId].unreadMessageCount = state.directChannel[message?.channelId].unreadMessageCount + 1;
            }
            if (state.channelChats[message?.channelId]) {
                state.channelChats[message?.channelId].unreadMessageCount = state.channelChats[message?.channelId].unreadMessageCount + 1;
            }
            if (state.pinnedChannels[message?.channelId]) {
                state.pinnedChannels[message?.channelId].unreadMessageCount = state.pinnedChannels[message?.channelId].unreadMessageCount + 1;
            }
        },

        sortChannels(state) {
            state.directChannel = sortChats(state.directChannel);
            state.channelChats = sortChats(state.channelChats);
            state.pinnedChannels = sortChats(state.pinnedChannels);
        },

        handleRemoveChannel(state, action) {
            const channelId = action.payload;
            delete state.directChannel[channelId] || delete state.channelChats[channelId] || delete state.pinnedChannels[channelId];
        },

        addNewConnection(state, action) {
            const {data} = action.payload;

            if (data.type === "ONE_TO_ONE") {
                state.directChannel = sortChats(state.directChannel);
            }

            if (data.type === "CHANNEL") {
                state.channelChats = sortChats(state.channelChats);
            }
        }
    },
});

/*
 * ======================================================
 * Export the actions to be used in dispatch calls
 * ======================================================
 */
export const {
    /* Get Channel Messages */
    getChannelMessageRequest: getChannelMessageRequestAction,
    getChannelMessageSuccess: getChannelMessageSuccessAction,
    getChannelMessageFailure: getChannelMessageFailureAction,

    /* send Messages */
    sendMessageRequest: sendMessageRequestAction,
    sendMessageSuccess: sendMessageSuccessAction,
    sendMessageFailure: sendMessageFailureAction,

    /* get user connections */
    getUserConnectionsRequest: getUserConnectionsRequestAction,
    getUserConnectionsSuccess: getUserConnectionsSuccessAction,
    getUserConnectionsFailure: getUserConnectionsFailureAction,

    /* get external connections */
    getExternalConnectionsRequest: getExternalConnectionsRequestAction,
    getExternalConnectionsSuccess: getExternalConnectionsSuccessAction,
    getExternalConnectionsFailure: getExternalConnectionsFailureAction,

    /* create new connection */
    createNewConnectionRequest: createNewConnectionRequestAction,
    createNewConnectionSuccess: createNewConnectionSuccessAction,
    createNewConnectionFailure: createNewConnectionFailureAction,

    /* update connection */
    updateConnectionRequest: updateConnectionRequestAction,
    updateConnectionSuccess: updateConnectionSuccessAction,
    updateConnectionFailure: updateConnectionFailureAction,

    /* update message */
    updateMessageRequest: updateMessageRequestAction,
    updateMessageSuccess: updateMessageSuccessAction,
    updateMessageFailure: updateMessageFailureAction,

    /* schedule message */
    scheduleMessageRequest: scheduleMessageRequestAction,
    scheduleMessageSuccess: scheduleMessageSuccessAction,
    scheduleMessageFailure: scheduleMessageFailureAction,

    /* forward message */
    forwardMessageRequest: forwardMessageRequestAction,
    forwardMessageSuccess: forwardMessageSuccessAction,
    forwardMessageFailure: forwardMessageFailureAction,

    /* add reaction message */
    addReactionToMessageRequest: addReactionToMessageRequestAction,
    addReactionToMessageSuccess: addReactionToMessageSuccessAction,
    addReactionToMessageFailure: addReactionToMessageFailureAction,

    /* remove reaction message */
    removeReactionToMessageRequest: removeReactionToMessageRequestAction,
    removeReactionToMessageSuccess: removeReactionToMessageSuccessAction,
    removeReactionToMessageFailure: removeReactionToMessageFailureAction,

    /* read message */
    markMessagesAsReadRequest: markMessagesAsReadRequestAction,
    markMessagesAsReadSuccess: markMessagesAsReadSuccessAction,
    markMessagesAsReadFailure: markMessagesAsReadFailureAction,

    /* change role */
    changeParticipantsRoleRequest: changeParticipantsRoleRequestAction,
    changeParticipantsRoleSuccess: changeParticipantsRoleSuccessAction,
    changeParticipantsRoleFailure: changeParticipantsRoleFailureAction,

    /* get channel activities */
    getChannelActivitiesRequest: getChannelActivitiesRequestAction,
    getChannelActivitiesSuccess: getChannelActivitiesSuccessAction,
    getChannelActivitiesFailure: getChannelActivitiesFailureAction,

    /* get message activities */
    getMessageActivitiesRequest: getMessageActivitiesRequestAction,
    getMessageActivitiesSuccess: getMessageActivitiesSuccessAction,
    getMessageActivitiesFailure: getMessageActivitiesFailureAction,

    /* get message activities */
    changeMessagesActivitiesStatusRequest: changeMessagesActivitiesStatusRequestAction,
    changeMessagesActivitiesStatusSuccess: changeMessagesActivitiesStatusSuccessAction,
    changeMessagesActivitiesStatusFailure: changeMessagesActivitiesStatusFailureAction,

    /* get message activities */
    setSnoozeMessagesActivitiesRequest: setSnoozeMessagesActivitiesRequestAction,
    setSnoozeMessagesActivitiesSuccess: setSnoozeMessagesActivitiesSuccessAction,
    setSnoozeMessagesActivitiesFailure: setSnoozeMessagesActivitiesFailureAction,

    /* get slack users */
    getSlackUsersRequest: getSlackUsersRequestAction,
    getSlackUsersSuccess: getSlackUsersSuccessAction,
    getSlackUsersFailure: getSlackUsersFailureAction,

    /* get slack users */
    getSlackUserProfileRequest: getSlackUserProfileRequestAction,
    getSlackUserProfileSuccess: getSlackUserProfileSuccessAction,
    getSlackUserProfileFailure: getSlackUserProfileFailureAction,
    /* get slack users */
    getTeamsUserProfileRequest: getTeamsUserProfileRequestAction,
    getTeamsUserProfileSuccess: getTeamsUserProfileSuccessAction,
    getTeamsUserProfileFailure: getTeamsUserProfileFailureAction,

    addMembersToChannelSuccess: addMembersToChannelSuccessAction,

    leaveSlackConversationFailure: leaveSlackConversationFailureAction,
    leaveSlackConversationRequest: leaveSlackConversationRequestAction,
    leaveSlackConversationSuccess: leaveSlackConversationSuccessAction,

    getPinnedMessagesRequest: getPinnedMessagesRequestAction,
    getPinnedMessagesSuccess: getPinnedMessagesSuccessAction,
    getPinnedMessagesFailure: getPinnedMessagesFailureAction,

    //pin message actions
    pinMessageRequest: pinMessageRequestAction,
    pinMessageSuccess: pinMessageSuccessAction,
    pinMessageFailure: pinMessageFailureAction,

    /* add message */
    addMessage: addMessageAction,

    resetSelectedConversation: resetSelectedConversationAction,

    addEditMessageContentState: addEditMessageContentStateAction,
    addSelectedConversationId: addSelectedConversationIdAction,
    addSelectedConversationDetails: addSelectedConversationDetailsAction,
    handleRemoveChannel: handleRemoveChannelAction,
    handlePinAndUnPinChannel: handlePinAndUnPinChannelAction,
    removeMessageActivity: removeMessageActivityAction,
    sortChannels: sortChannelsAction,
    sortChannelsBasedOnLastMessage: sortChannelsBasedOnLastMessageAction,
    addNewConnection: addNewConnectionAction,
    updateMessageUnreadCount: updateMessageUnreadCountAction,
} = messageSlice.actions;

/*
 * ======================================================
 * Export the reducer to be used in the store
 * ======================================================
 */
export default messageSlice.reducer;
