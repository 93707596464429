import React, { useState } from "react";
import { ErrorIcon } from "../../../../../../../images";
import { Tooltip } from "@mui/material";
import { LargeDetailsMenuView, SortDetailsMenuView } from "./view";
import { UnfilledShiftType } from "../../AttendanceScheduleTableType";

interface UnfilledShiftInfoViewProps {
  unfilledShiftData: UnfilledShiftType;
}

const UnfilledShiftInfoView: React.FC<UnfilledShiftInfoViewProps> = ({
  unfilledShiftData,
}) => {
  const [fullView, setFullView] = useState<boolean>(false);

  return (
    <Tooltip
      title={
        fullView ? (
          <LargeDetailsMenuView
            onBackClick={() => setFullView(false)}
            unfilledShiftData={unfilledShiftData}
          />
        ) : (
          <SortDetailsMenuView
            onMoreClick={() => setFullView(true)}
            totalShift={unfilledShiftData.totalShiftCount}
            unFilledShift={unfilledShiftData.unfilledShiftCount}
          />
        )
      }
      arrow
      placement="bottom"
    >
      <span>
        <ErrorIcon w={15} color="#852221" />
      </span>
    </Tooltip>
  );
};

export default UnfilledShiftInfoView;
