import { Typography, Popper, Stack } from "@mui/material";
import React, { useState } from "react";
import UserInfoChip from "../UserInfoChip/UserInfoChip";
import { UserInfoChipProps } from "../UserInfoChip/UserInfoChipType";

interface UserCountProps {
  userList: any[]; 
  sx?: any;
}

const UserCount: React.FC<UserCountProps> = ({ userList = [], sx }) => {
  const userLength = Array.isArray(userList) ? userList.length : 0; // Safely check if userList is an array
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    if (anchorEl && !anchorEl.contains(document.activeElement)) {
      setAnchorEl(null);
    }
  };

  return (
    <Stack
      sx={{
        cursor: "pointer",
        width: "fit-content",
        font: "normal normal 300 11px/14px Source Serif Pro",
        color: "#2A30B5",
        ...sx,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {userLength > 0 && (
        <Typography
          sx={{
            font: "inherit",
            color: "inherit",
          }}
        >
          +{userLength}
        </Typography>
      )}

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        onMouseLeave={handleMouseLeave}
        sx={{
          zIndex: 10,
          boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
          borderRadius: "5px",
        }}
      >
        <Stack
          sx={{
            bgcolor: "white",
            borderRadius: "5px",
            width: "100%",
            maxHeight: "130px",
            overflowY: "scroll",
            scrollbarWidth: "none",
            flexDirection: "column",
            alignItems: "center",
            boxSizing: "border-box",
            padding: "10px",
            gap: "10px",
          }}
        >
          {userLength > 0 ? (
            userList.map((userData, index) => (
              <UserInfoChip
                key={index}
                popoverPlace="right"
                userName={userData?.emailAddress?.name || "Unknown"}
                userEmail={userData?.emailAddress?.address || "Unknown"}
                userIconSrc={userData?.emailAddress?.logo}
                userAvatarColor={userData?.userAvatarColor}
              />
            ))
          ) : (
            <Typography
              sx={{
                font: "normal normal 400 11px/14px Source Serif Pro",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              No User
            </Typography>
          )}
        </Stack>
      </Popper>
    </Stack>
  );
};

export default UserCount;
