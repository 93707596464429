import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import {
  ShiftType,
  UnfilledShiftType,
} from "../../../../AttendanceScheduleTableType";

const InlineContainer: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => {
  return (
    <Stack sx={{ font: "inherit", gap: "5px" }}>
      <Typography sx={{ font: "inherit" }}>{title} :</Typography>
      <Stack sx={{ marginLeft: "5px", gap: "5px" }}>{children}</Stack>
    </Stack>
  );
};
const InlineInfo: React.FC<{
  jobName: string;
  totalShift: number;
  filledShift: number;
}> = ({ filledShift, jobName, totalShift }) => {
  return (
    <Typography sx={{ font: "inherit" }}>
      {jobName} {filledShift} of {totalShift} Filled
    </Typography>
  );
};

interface LargeDetailsMenuViewProps {
  onBackClick: () => void;
  unfilledShiftData: UnfilledShiftType;
}

const LargeDetailsMenuView: React.FC<LargeDetailsMenuViewProps> = ({
  onBackClick,
  unfilledShiftData,
}) => {
  const getLabel = (value: ShiftType): string => {
    switch (value) {
      case "morning":
        return "Morning Shift";
      case "midDay":
        return "Midday Shift";
      case "night":
        return "Night Shift";
    }
  };
  return (
    <Stack
      sx={{
        width: "165px",
        font: "normal normal 300 11px/14px Source Serif Pro",
      }}
    >
      <Stack
        sx={{
          font: "inherit",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        <Typography sx={{ font: "inherit" }}>Not all shifts full:</Typography>

        <IconButton
          sx={{
            p: 0,
            font: "normal normal 300 11px/14px Source Serif Pro",
            borderRadius: 0,
            color: "white",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          disableRipple
          onClick={onBackClick}
        >
          Back
        </IconButton>
      </Stack>

      <Stack
        sx={{
          maxHeight: "200px",
          overflow: "auto",
          scrollbarWidth: "none",
          gap: "5px",
        }}
      >
        {/* --- --- --- ---- ---- */}
        {unfilledShiftData.filledShiftData.map((item, index) => (
          <InlineContainer title={getLabel(item.ShiftType)} key={index}>
            {item.filledShiftData.map((shiftData, i) => (
              <InlineInfo
                key={i}
                jobName={shiftData.jobName}
                filledShift={shiftData.filledShift}
                totalShift={shiftData.totalShift}
              />
            ))}
          </InlineContainer>
        ))}
      </Stack>
    </Stack>
  );
};

export default LargeDetailsMenuView;
