// AnonymousComponent.tsx
import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  CloudStorageRoute,
  TraxRoute,
  TraxTimeAttendanceRoute,
  WorkspaceRoute,
} from "./components";
import { RoutesParamType } from "../TopNavBarType";
import { TraxPaths } from "../../../pages";

const AnonymousComponent: React.FC = () => {
  const params = useParams<RoutesParamType>();
  const location = useLocation();

  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  if (params?.workspaceId) {
    return (
      <WorkspaceRoute
        workspaceId={params?.workspaceId}
        itemId={params?.componentId}
        sharedType={params?.sharedType}
      />
    );
  } else if (params?.fileType) {
    return (
      <CloudStorageRoute folderId={params?.fileType} fileId={params?.fileId} />
    );
  } else if (params?.empId) {
    return <TraxRoute employeeId={params.empId} />;
  } else if (currentPath.includes("/Trax/time-attendance" as TraxPaths)) {
    return <TraxTimeAttendanceRoute />;
  }

  return null;
};

export default AnonymousComponent;
