import { Box, Stack, Typography } from "@mui/material";
import React from "react";

interface DocumentOrItemViewProps {
  imgSrc: string;
  title: string;
}

const DocumentOrItemView: React.FC<DocumentOrItemViewProps> = ({
  imgSrc,
  title,
}) => {
  return (
    <Stack
      sx={{
        height: "40px",
        width: "100%",
        flexShrink: 0,
        boxSizing: "border-box",
        border: "1px solid #E9EDF2",
        borderTop: "0px",
        borderRadius: "0px 0px 5px 5px",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px 10px",
      }}
    >
      <Box
        component={"img"}
        src={imgSrc}
        alt="logo"
        sx={{ height: "25px", width: "25px", boxSizing: "border-box" }}
      />

      <Typography
        sx={{
          font: "normal normal normal 15px/21px Source Serif Pro",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </Typography>
    </Stack>
  );
};

export default DocumentOrItemView;
