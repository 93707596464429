import React from "react";
import {Box, ButtonBase, Typography} from "@mui/material";
import {FormatBold, FormatItalic, FormatUnderlined, Image, Link} from "@mui/icons-material";

interface ToolbarButtonProps {
    label: string;
    icon: React.ReactNode;
    onClick: () => void;
    width?: string;
}

const ToolbarButton: React.FC<ToolbarButtonProps> = ({ label, icon, onClick,width }) => (
    <ButtonBase
        onClick={onClick}
        sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            width: width,
            height: "33px",
            '&:hover': {
                backgroundColor: "#f0f0f0",
            },
        }}
    >
        <Box sx={{ fontSize:"20px" }}>{icon}</Box>
       <Typography sx={{ font: "normal normal 300 11px/13.78px Source Serif Pro" }}>
    {label}
</Typography>
    </ButtonBase>
);

interface ToolbarProps {
    onBoldClick: () => void;
    onItalicClick: () => void;
    onUnderlineClick: () => void;
    onLinkClick: () => void;
    onImageClick: any;
}

const CustomRichTextDecoration: React.FC<ToolbarProps> = ({
                                                              onBoldClick,
                                                              onItalicClick,
                                                              onUnderlineClick,
                                                              onLinkClick,
                                                              onImageClick,
                                                          }) => (
    <Box
        sx={{
            display: "flex",
            gap: "14px",
            padding: "6px 11px 6px 11px",
            border: "2px solid #E9EDF2",
            borderRadius: "8px",
            width:"230px"
        }}
    >
        <ToolbarButton label="Bold" icon={<FormatBold/>} onClick={onBoldClick} width={"23px"}/>
        <ToolbarButton label="Italic" icon={<FormatItalic/>} onClick={onItalicClick} width={"26px"}/>
        <ToolbarButton label="Underline" icon={<FormatUnderlined/>} onClick={onUnderlineClick} width={"50px"}/>
        <ToolbarButton label="Link" icon={<Link/>} onClick={onLinkClick} width={"23px"}/>
        <ToolbarButton label="Image" icon={<Image/>} onClick={onImageClick} width={"31px"}/>
    </Box>
);

export default CustomRichTextDecoration;