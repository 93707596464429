import React, { memo, useEffect, useState } from "react";
import { Container, Box, Stack, Typography, Zoom } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { cmd__calendar_api, recallAPI } from "../../../../../redux";
import { variantColorMap, VariantKeys } from "../../../../../Utils";
import g from "../../Calendar.module.css";
import { EmptyEvent } from "../../../../../images";
import { CalendarEventCardLong } from "../../components";
import { useTranslation } from "react-i18next";
interface CommandControlCalendarProps {
    // Define your props here
}

const CommandControlCalendar: React.FC<CommandControlCalendarProps> = () => {
    const dispatch = useDispatch();
    const calendarState = useSelector(
        (state: RootState) => state && state?.calendar
    );
    const [tabSelected, setTabSelected] = useState("Up Next");
    const { reCall, cmd__Notifications } = calendarState || {};

    const [pendingEvents, setPendingEvents] = useState<any[]>([]);
    const [upNextEvents, setUpNextEvents] = useState<any[]>([]);
    const [doneEvents, setDoneEvents] = useState<any[]>([]);
    useEffect(() => {
        if (!cmd__Notifications || cmd__Notifications.length === 0) return;

        const splitEvents = () => {
            const pending: any[] = [];
            const upNext: any[] = [];
            const done: any[] = [];

            cmd__Notifications.forEach((event: any) => {
                // Get the color for the event based on its variant
                const eventColor =
                    event?.variant && event.variant in variantColorMap
                        ? variantColorMap[event.variant as VariantKeys]
                        : "#000000"; // Default color

                // Define event status based on conditions
                if (event?.status === "DONE") {
                    done.push({ ...event, status: "done", color: eventColor });
                } else if (
                    event?.userId === event?.createdBy &&
                    event?.status !== "DONE"
                ) {
                    // Add to upNext only if userId === createdBy and status is not "DONE" or other positive status
                    upNext.push({ ...event, status: "up-next", color: eventColor });
                } else {
                    // All other cases go to pending
                    pending.push({ ...event, status: "pending", color: eventColor });
                }
            });

            // Update state with categorized events
            setPendingEvents(pending);
            setUpNextEvents(upNext);
            setDoneEvents(done);
        };

        splitEvents();
    }, [cmd__Notifications]);

    const get__All__cmd = async () => {
        try {
            const action = cmd__calendar_api();
            await dispatch(action);
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(recallAPI(null)); // Reset any recall state if applicable
        }
    };

    useEffect(() => {
        get__All__cmd();
    }, []);
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Container
            sx={{
                maxWidth: "none !important",
                marginRight: "unset",
                width: "unset",
                marginLeft: "unset",
            }}
            disableGutters
        >
            <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                width={"100%"}
                flexDirection={"column"}
                marginTop={'20px'}
            >
                <div style={{ width: "80%" }} className={g.tabs_container}>
                    <div className={g.tabs}>
                        <span
                            onClick={() => setTabSelected("Up Next")}
                            style={{
                                cursor: "pointer",
                                borderBottom:
                                    tabSelected === "Up Next" ? "2px solid #B5005B" : "none",
                                padding: "8px 0px", // Ensures the dark border is flush with the light border
                            }}
                        >
                            {t("CALENDAR.UP_NEXT")}
                        </span>

                        <span
                            onClick={() => setTabSelected("Done")}
                            style={{
                                cursor: "pointer",
                                borderBottom:
                                    tabSelected === "Done" ? "2px solid #B5005B" : "none",
                                padding: "8px 0px", // Ensures the dark border is flush with the light border
                            }}
                        >
                            {t("CALENDAR.DONE")}
                        </span>

                        <span
                            onClick={() => setTabSelected("Pending")}
                            style={{
                                cursor: "pointer",
                                borderBottom:
                                    tabSelected === "Pending" ? "2px solid #B5005B" : "none",
                                padding: "8px 0px", // Ensures the dark border is flush with the light border
                            }}
                        >
                            {t("CALENDAR.PENDING")}
                        </span>
                    </div>
                </div>
                <Zoom in={true}>
                    <div className={g.Up_next}>
                        {tabSelected === "Up Next" ? (
                            <>
                                {upNextEvents && upNextEvents?.length > 0 ? (
                                    upNextEvents?.map((upNext) => (
                                        <React.Suspense fallback={<div>Loading Sidebar...</div>}>
                                            <CalendarEventCardLong
                                                key={upNext?._id}
                                                openBookingLink={() => {
                                                    console.log("");
                                                }}
                                                variant="up-next"
                                                bookingLinkDatas={upNext}
                                            />
                                        </React.Suspense>
                                    ))
                                ) : (
                                    <Stack
                                        gap={2}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        direction={"column"}
                                    >
                                        <img
                                            style={{ width: "450px", height: "400px" }}
                                            src={EmptyEvent}
                                            alt="No UpComing events available"
                                        />
                                        <Typography
                                            children={t("CALENDAR.NO_UPNEXT_EVENT")}
                                            sx={{
                                                font: "normal normal 500 17px/21px Source Serif Pro",
                                                textAlign: "center",
                                                color: "text.secondary", // Optional: adjust color to fit your theme
                                            }}
                                        />
                                    </Stack>
                                )}
                            </>
                        ) : tabSelected === "Done" ? (
                            <>
                                {doneEvents && doneEvents?.length > 0 ? (
                                    doneEvents?.map((Dones) => (
                                        <React.Suspense fallback={<div>Loading Sidebar...</div>}>
                                            <CalendarEventCardLong
                                                key={Dones?._id}
                                                openBookingLink={() => {
                                                    console.log("");
                                                }}
                                                variant="Done"
                                                bookingLinkDatas={Dones}
                                            />
                                        </React.Suspense>
                                    ))
                                ) : (
                                    <Stack
                                        gap={2}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        direction={"column"}
                                    >
                                        <img
                                            style={{ width: "450px", height: "400px" }}
                                            src={EmptyEvent}
                                            alt="No Done events available"
                                        />
                                        <Typography
                                            children={t("CALENDAR.NO_DONE_EVENT")}
                                            sx={{
                                                font: "normal normal 500 17px/21px Source Serif Pro",
                                                textAlign: "center",
                                                color: "text.secondary", // Optional: adjust color to fit your theme
                                            }}
                                        />
                                    </Stack>
                                )}
                            </>
                        ) : tabSelected === "Pending" ? (
                            <>
                                {pendingEvents && pendingEvents.length > 0 ? (
                                    pendingEvents.map((pendings) => (
                                        <React.Suspense fallback={<div>Loading Sidebar...</div>}>
                                            <CalendarEventCardLong
                                                key={pendings?._id}
                                                openBookingLink={() => {
                                                    console.log("");
                                                }}
                                                variant="Pending"
                                                bookingLinkDatas={pendings}
                                            />
                                        </React.Suspense>
                                    ))
                                ) : (
                                    <Stack
                                        gap={2}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        direction={"column"}
                                    >
                                        <img
                                            style={{ width: "450px", height: "400px" }}
                                            src={EmptyEvent}
                                            alt="No Pending events available"
                                        />
                                        <Typography
                                            children={t("CALENDAR.NO_PENDING_EVENT")}
                                            sx={{
                                                font: "normal normal 500 17px/21px Source Serif Pro",
                                                textAlign: "center",
                                                color: "text.secondary", // Optional: adjust color to fit your theme
                                            }}
                                        />
                                    </Stack>
                                )}
                            </>
                        ) : null}
                    </div>
                </Zoom>
            </Box>
        </Container>
    );
};

export default memo(CommandControlCalendar);
