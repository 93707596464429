// HugeCreateItemWorkspace.tsx

import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { ProjectIcon } from "../../../../../images";
import { MainDetails, Connect, ShareProject } from "./view";
import { ButtonSmall, ButtonType, SmallButtonColor } from "../../../../common";
import { WorkspacePageIndicator } from "./WorkspacePageIndicator";
import styles from "./HugeCreateItemWorkspace.module.css";

import { CreateCardData } from "../MainTop/types";
import { renderIconPng } from "../../../Dashboard/common";

interface HugeCreateItemWorkspaceProps {
  createCardData: CreateCardData;
  updateMainDetails: (value: Partial<CreateCardData["mainDetails"]>) => void;
  updateConnections?: (value: Partial<CreateCardData["connections"]>) => void;
  updateShareWorkspace?: (
    value: Partial<CreateCardData["shareWorkspace"]>
  ) => void;
  handleClickButton?: (c?: any) => void;
  loader?: boolean;
  editOpenPopUp?: boolean;
  handleClose?: any;
  create__space__type?: any;
}

const HugeCreateItemWorkspace: React.FC<HugeCreateItemWorkspaceProps> = ({
  createCardData,
  updateMainDetails,
  updateConnections = () => { },
  updateShareWorkspace = () => { },
  handleClickButton = (c = "BearishOS") => { },
  loader = false,
  editOpenPopUp = false,
  handleClose,
  create__space__type = "BearishOS",
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const cancelClickHandler = (): void => {
    if (handleClose) {
      handleClose();
    }
  };

  const handleNextPage = (): void => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, 3));
  };

  const renderCurrentPage = () => {
    switch (currentPage) {
      case 1:
        return (
          <MainDetails
            createCardData={createCardData}
            updateMainDetails={updateMainDetails}
          />
        );
      case 2:
        return (
          <Connect
            createCardData={createCardData}
            updateConnections={updateConnections}
          />
        );
      case 3:
        return (
          <ShareProject
            createCardData={createCardData}
            updateShareWorkspace={updateShareWorkspace}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Stack className={styles["huge-create-workspace-card"]}>
      {/* header here svg and text */}
      <Stack className={styles["huge-create-workspace-card__header"]}>
        <Box className={styles["huge-create-workspace-card__icon-container"]}>
          <ProjectIcon color="black" w={25} />
        </Box>
        <Typography className={styles["huge-create-workspace-card__title"]}>
          {editOpenPopUp ? "Update" : "Create"} a Workspace

          <img
            style={{
              width: "18px",
              height: "18px",
            }}
            src={renderIconPng(
              create__space__type === "Jira"
                ? "jiraPng"
                : create__space__type === "Confluence"
                  ? "confluencePng"
                  : "bearishPng"
            )}
            alt="bearishPng"
          />
        </Typography>
      </Stack>

      {/* page indicator for selected page */}
      <WorkspacePageIndicator
        activePage={currentPage}
        setCurrentPage={(pageNo: number) => setCurrentPage(pageNo)}
      />

      {/* dynamic page here */}
      <Stack sx={{ flexGrow: 1 }}>{renderCurrentPage()}</Stack>

      {/* button here */}
      <Stack className={styles["huge-create-workspace-card__button-container"]}>
        <ButtonSmall
          onClick={() => cancelClickHandler()}
          label="Cancel"
          types={ButtonType.Button}
          colorVarient={SmallButtonColor.Transparent}
        />
        <Stack sx={{ flexDirection: "row", gap: "10px" }}>
          {/* <ButtonSmall
            label={editOpenPopUp ? "Update Jira" : "Create Jira"}
            types={ButtonType.Button}
            loaderLogin={loader}
            disabled={
              !createCardData?.mainDetails?.workspaceName ||
              !createCardData?.mainDetails?.uploadWorkspaceImage
            }
            colorVarient={SmallButtonColor.Blue}
            onClick={() => {
              handleClickButton && handleClickButton("Jira");
            }}
          />
          <ButtonSmall
            label={editOpenPopUp ? "Update Confluence" : "Create Confluence"}
            types={ButtonType.Button}
            loaderLogin={loader}
            disabled={
              !createCardData?.mainDetails?.workspaceName ||
              !createCardData?.mainDetails?.uploadWorkspaceImage
            }
            colorVarient={SmallButtonColor.Blue}
            onClick={() => {
              handleClickButton && handleClickButton("Confluence");
            }}
          /> */}
          <ButtonSmall
            label={editOpenPopUp ? "Update" : "Create"}
            types={ButtonType.Button}
            loaderLogin={loader}
            disabled={
              !createCardData?.mainDetails?.workspaceName ||
              !createCardData?.mainDetails?.uploadWorkspaceImage
            }
            colorVarient={SmallButtonColor.Orange}
            onClick={() => handleClickButton && handleClickButton("BearishOS")}
          />
          {(currentPage === 1 || currentPage === 2) && (
            <ButtonSmall
              onClick={handleNextPage}
              label="Next"
              types={ButtonType.Button}
              colorVarient={SmallButtonColor.Gray}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HugeCreateItemWorkspace;
