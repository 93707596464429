import React, { useEffect, useState, useCallback } from "react";
import { Modal, Box, Grow } from "@mui/material";
import { CommandControlSearchContainer } from "../Dashboard/components";

const SearchContainer = () => {
    const [isSearchContainerVisible, setIsSearchContainerVisible] = useState(false);

    const toggleSearchContainer = useCallback((event: KeyboardEvent) => {
        const isShortcutPressed =
            (event.ctrlKey || event.metaKey) && (event.key === " " || event.key.toLowerCase() === "s");

        if (isShortcutPressed) {
            event.preventDefault();
            setIsSearchContainerVisible((prev) => !prev);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", toggleSearchContainer);
        return () => {
            window.removeEventListener("keydown", toggleSearchContainer);
        };
    }, [toggleSearchContainer]);

    return (
        <Modal
            open={isSearchContainerVisible}
            onClose={() => setIsSearchContainerVisible(false)}
            aria-labelledby="search-modal-title"
            aria-describedby="search-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1000,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: 2,
                    outline: "none", // Accessibility improvement
                }}
            >
                <Grow in={true} timeout={400}>
                    <div>
                        <CommandControlSearchContainer />
                    </div>
                </Grow>
            </Box>
        </Modal>
    );
};

export default SearchContainer;
