import { Drawer, Stack } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import styles from "../../DrawerNavbar.module.css";
import DrawerSectionText from "../../DrawerSectionText";
import { AvailableConnection } from "./component";
import { ThreeDotMenuSmall } from "../../../../pages/CloudStorage/components";
import { CreateConnectionGearIcon } from "../../../CreateConnectionGearIcon";
import { useDispatch } from "react-redux";
import { box__loginUrl__handle } from "../../../../../redux";

type WorkspaceDrawerProps = {
  drawerOpen: boolean;
  drawerCloser: () => void;
  shared?: boolean;
};

const DrawerContainer = styled("div")({
  position: "fixed",
  top: 0,
  height: "100vh",
  zIndex: 1100,
});
const CloudStorageDrawer: React.FC<WorkspaceDrawerProps> = ({
  drawerOpen,
  drawerCloser,
  shared = false,
}) => {
  const dispatch = useDispatch();
  const connectBox = async () => {
    try {
      const response: any = await dispatch(box__loginUrl__handle());
      if (response?.loginUrl) {
        const redirectUrl = response?.loginUrl;
        window.location.href = redirectUrl;  // Redirect in the same window
      }
    } catch (error) {
      console.error("Error during Box login:", error);
    }
  }
  

  return (
    <>
      <DrawerContainer>
        <Drawer
          sx={{
            position: "absolute",
            top: 0,
            height: "100vh",
            width: "215px",
            // left: '-240px', // Start hidden behind the Navbar
            transition: drawerOpen
              ? "left 225ms ease-out"
              : "left 195ms ease-in",
            left: drawerOpen ? (shared ? "2px" : "30px") : "-215px",
            zIndex: 1100, // Ensure the drawer is behind the navbar
            "& .MuiPaper-root": {
              position: "absolute",
              top: 0,
              height: "100vh",
              width: "215px",
              left: 0,
              boxSizing: "border-box",
              zIndex: 1100, // Ensure the paper is also behind the navbar
            },
          }}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
        >
          <Stack
            bgcolor={"white"}
            direction="column"
            flex={1}
            height={"100%"}
            className={`${styles.drawerNavbar__drawer}`}
          >
            {/* Company logo and name here */}
            <CompanyIconAndName arrowButtonClickHandler={drawerCloser} />
            <Stack className={styles.drawerNavbar__content}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <ThreeDotMenuSmall r={true} dy={`BearishOS`} />
                <Stack>
                  <CreateConnectionGearIcon
                    categories={["cloud"]}
                    onServiceClick={(s: any) => { if (s === "Box") { connectBox() } }}
                  />
                </Stack>
              </Stack>

              <DrawerSectionText title="Cloud Storage" />
              <Stack
                height="35%"
                overflow="hidden"
                maxHeight="35%"
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "0 !important", // hide scrollbar on Chrome, Safari, etc.
                  },
                  scrollbarWidth: "none", // hide scrollbar on Firefox
                  overflowX: "hidden",
                  overflowY: "scroll",
                }}
              >
                <AvailableConnection />
              </Stack>
              <DrawerSectionText title="My Collection" />
              <div
                style={{
                  font: "normal normal 11px Source Serif Pro",
                  margin: "20px 0 0 0",
                  opacity: 0.5,
                }}
              >
                Drag items here to have quick access to them{" "}
              </div>
            </Stack>
          </Stack>
        </Drawer>
      </DrawerContainer>
    </>
  );
};

export default CloudStorageDrawer;
