import React from "react";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import ColoredBadge from "../ColoredBadge";
import { GreenArrowButton } from "../../Buttons";
import { PlusIcon } from "../../../../images";
import styles from "./styleHeader.module.css";
import { adjustHexColor } from "../../../../Utils";
type WorkspaceBoardTitleHeaderProps = {
  title?: string;
  color?: string;
  onClick?: () => void; // Updated to correct function type
  collapsed?: boolean;
  stackLength?: number | undefined | null;
};

const WorkspaceBoardTitleHeader: React.FC<WorkspaceBoardTitleHeaderProps> = ({
  title = "Done",
  color = "green",
  onClick = () => { },
  collapsed = false,
  stackLength = 0,
}) => {
  const darkenedOrSoftenColor = adjustHexColor("#fff", 35);
  return (
    <Stack
      alignItems={"center"}
      width={"100%"}
      justifyContent={"space-between"}
      direction={collapsed ? "column" : "row"}
    >
      <ColoredBadge
        title={title}
        bgColor={darkenedOrSoftenColor}
        iconColor={color}
        showIcon
        vertical__textAlign={collapsed}
      />
      <Stack className={styles["board-card__header-stack"]}>
        <Stack onClick={() => onClick()}>
          <Tooltip title="button" placement="top" arrow>
            <GreenArrowButton
              sx={{
                transform: collapsed ? "rotate(90deg)" : "rotate(180deg)",
                py: "2px",
                px: "4px",
              }}
            />
          </Tooltip>
        </Stack>
        {!collapsed && (
          <Tooltip title="button" placement="top" arrow>
            <IconButton
              disableRipple
              sx={{ height: "20px", width: "20px", padding: 0 }}
            >
              <PlusIcon w={14} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      {collapsed && (
        <Stack
          p={"6px 0px"}
          borderRadius={"5px"}
          sx={{ backgroundColor: adjustHexColor(color, 20) }}
        >
          <Typography
            sx={{
              writingMode: "vertical-rl", // Rotates the text vertically
              transform: "rotate(0deg)", // Keeps text upright
              textAlign: "center", // Center-align text
              font: "normal normal 400 14px/18px Source Serif Pro",
              color: "inherit",
            }}
          >
            {/* Add leading zero if stackLength is less than 10 */}
            {String(stackLength || 0).padStart(2, "0")}
          </Typography>
        </Stack>

      )}
    </Stack>
  );
};

export default WorkspaceBoardTitleHeader;
