import React from "react";
import {
  TextNormalDropdown,
  TextNormalDropdownOptionsType,
} from "../../../../common";

const DUMMY_positionsDropDownOptions: TextNormalDropdownOptionsType[] = [
  { _id: "1", title: "Senior Developer", value: "seniorDeveloper" },
  { _id: "2", title: "Junior Developer", value: "juniorDeveloper" },
  { _id: "3", title: "Project Manager", value: "projectManager" },
  { _id: "4", title: "QA Engineer", value: "qaEngineer" },
  { _id: "5", title: "UI/UX Designer", value: "uiUxDesigner" },
  { _id: "6", title: "DevOps Engineer", value: "devOpsEngineer" },
  { _id: "7", title: "Product Owner", value: "productOwner" },
  { _id: "8", title: "Scrum Master", value: "scrumMaster" },
  { _id: "9", title: "Data Scientist", value: "dataScientist" },
  { _id: "10", title: "Business Analyst", value: "businessAnalyst" },
];

const PositionsDropDown: React.FC = () => {
  return (
    <TextNormalDropdown
      placeholder="Select position..."
      initialValue={{
        _id: "3",
        title: "Project Manager",
        value: "projectManager",
      }}
      options={DUMMY_positionsDropDownOptions}
      onSelect={(value) => console.log(value)}
    />
  );
};

export default PositionsDropDown;
