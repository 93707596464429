import { Stack } from "@mui/material";
import React from "react";
import { EventIconButton } from "../../../../../../common";
import {
  CheckboxFilledIcon,
  MultiCircleIcon,
  XCircleIcon,
} from "../../../../../../../images";

interface ButtonGroupTwoViewProps {
  onAcceptButton_Click?: () => void;
  onRejectButton_Click?: () => void;
  onDoneButton_Click?: () => void;
}

const ButtonGroupTwoView: React.FC<ButtonGroupTwoViewProps> = ({
  onAcceptButton_Click,
  onRejectButton_Click,
  onDoneButton_Click,
}) => {
  const buttons = [
    {
      title: "Accept",
      icon: CheckboxFilledIcon,
      color: "#21B481",
      onClick: onAcceptButton_Click,
    },
    {
      title: "Reject",
      icon: XCircleIcon,
      color: "#FF0000",
      onClick: onRejectButton_Click,
    },
    {
      title: "Maybe",
      icon: MultiCircleIcon,
      color: "#FFB100",
      onClick: onDoneButton_Click,
    },
  ];

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
      {buttons.map(({ title, icon, color, onClick }) => (
        <EventIconButton
          key={title}
          buttonTitle={title}
          icon={icon}
          iconColor={color}
          onClick={ () => onClick && onClick?.()}
        />
      ))}
    </Stack>
  );
};

export default ButtonGroupTwoView;
