import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, {
  lazy,
  Suspense,
  useEffect,
  createContext,
  useState,
  ReactNode,
} from "react";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";
import { XCircleIcon } from "../../../../../images";
import { updateMailSettings__api } from "../../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

// Lazy-loaded components
const LazyAccountsTab = lazy(() => import("./tabs/AccountsTab/AccountsTab"));
const LazyAppearanceTab = lazy(
  () => import("./tabs/AppearanceTab/AppearanceTab")
);
const LazyAutomaticRepliesTab = lazy(
  () => import("./tabs/AutomaticRepliesTab/AutomaticRepliesTab")
);
const LazyCleanAndJunkTab = lazy(
  () => import("./tabs/CleanAndJunkTab/CleanAndJunkTab")
);
const LazyGeneralTab = lazy(() => import("./tabs/GeneralTab/GeneralTab"));
const LazyRulesTab = lazy(() => import("./tabs/RulesTab/RulesTab"));

interface EmailSettingsContextType {
  handleClose: () => void;
  emailSettingsData: Partial<SettingsPayload> | null;
  handleSettings: (payload: Partial<SettingsPayload>) => Promise<void>;
}

export const EmailSettingsContext = createContext<
  EmailSettingsContextType | undefined
>(undefined);

interface MailSettingsProps {
  handleClose: () => void;
}

interface Account {
  name?: string;
  accountType: "GMAIL" | "OUTLOOK" | "YAHOO" | "";
}

interface SettingsPayload {
  theme?: any;

  appreanceTab?: {
    smartFilters?: boolean;
    cardStyleAndSpacing?:
      | "COMFORTABLE_CARD"
      | "CONDENSED_CARD"
      | "CONDENSED_BAR";
    messageAppearance?: "NEWEST_TOP" | "NEWEST_BOTTOM" | "UNREAD_FIRST";
    theme?: any;
  };

  generalTab?: {
    useSignature?: boolean;
    name?: string;
    content?: string;
    signatureArray?: string[];
    signatureForNewEmails?: string;
    signatureForRepliedEmails?: string;
    replyTo?: "REPLY" | "REPLY_ALL";
    undoSend?: {
      enabled: boolean;
      duration: number;
    };
    bebaAI?:
      | "CHECK_YOUR_SPELLING"
      | "CHECK_YOUR_GRAMMAR"
      | "AUTOCORRECT_YOUR_TEXT"
      | "COMPOSE_YOUR_NEXT_LINE";
    automaticTranslation?: boolean;
  };

  rulesTab?: {
    betaFeatures?: boolean;
  };

  cleanAndJunkTab?: {
    useBEBAclean?: boolean;
    incomingMailRules?: "MOVE_TO_JUNK" | "FLAG_AS_JUNK" | "CONTACTS_ONLY";
  };

  automaticReplyTab?: {
    enabled?: boolean;
    until?: string;
    subjectLine?: string;
    message?: string;
  };

  accountTab?: Account[];
}

const MailSettings: React.FC<MailSettingsProps> = ({ handleClose }) => {
  const [tabValue, setTabValue] = useState(0);
  const { mailSettings } = useSelector(
    (state: RootState) => state && state?.emails
  );
  const [emailSettingsData, setEmailSettingsData] =
    useState<Partial<SettingsPayload> | null>(null);
  const dispatch = useDispatch();
  // Utility to update a nested property in an object
  const deepClone = (obj: any) => {
    return JSON?.parse(JSON?.stringify?.(obj));
  };

  const updateNestedProperty = (object: any, path: string, value: any) => {
    const keys = path?.split?.(".");
    let current = object;

    keys?.forEach((key, index) => {
      if (index === keys?.length - 1) {
        current[key] = value;
      } else {
        if (!current[key]) current[key] = {};
        current = current[key];
      }
    });
  };

  const updateSetting = (fieldPath: string, value: any) => {
    // Deep clone mailSettings to ensure all nested properties are writable
    const updatedSettings = deepClone(mailSettings);

    // Update the specified field
    updateNestedProperty(updatedSettings, fieldPath, value);

    // Dispatch the update with the modified settings
    handleSettings(updatedSettings);
  };

  // Dispatch function to handle API update
  const handleSettings = async (payload: any) => {
    try {
      const action = updateMailSettings__api(payload); // Your API action
      await dispatch(action);
    } catch (err) {
      console.error("Error updating settings:", err);
    }
  };

  // Example of calling the updateSetting function
  // updateSetting("generalTab.undoSend.duration", 60);
  useEffect(() => {
    if (mailSettings && mailSettings?.length > 0) {
      setEmailSettingsData(mailSettings);
    }
  }, [mailSettings]);

  const tabs: { label: string; content: ReactNode }[] = [
    {
      label: "Appearance",
      content: <LazyAppearanceTab updateSetting={updateSetting} />,
    },
    {
      label: "General",
      content: <LazyGeneralTab updateSetting={updateSetting} />,
    },
    { label: "Rules", content: <LazyRulesTab updateSetting={updateSetting} /> },
    {
      label: "Clean & Junk",
      content: <LazyCleanAndJunkTab updateSetting={updateSetting} />,
    },
    {
      label: "Automatic Replies",
      content: <LazyAutomaticRepliesTab updateSetting={updateSetting} />,
    },
    {
      label: "Accounts",
      content: <LazyAccountsTab updateSetting={updateSetting} />,
    },
  ];

  return (
    <EmailSettingsContext.Provider
      value={{ handleSettings, emailSettingsData, handleClose }}
    >
      <Stack
       sx={{
        bgcolor: "white",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        padding: "20px",
        gap: "20px",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          display: "none", 
        },
        scrollbarWidth: "none", 
      }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              font: "normal normal 900 20px/25px Source Serif Pro",
              marginBottom: "10px",
            }}
          >
            Mail Settings
          </Typography>
          <Tooltip title="Close" placement="top" arrow>
            <IconButton onClick={handleClose} sx={{ p: 1 }} disableRipple>
              <XCircleIcon w={20} color="black" />
            </IconButton>
          </Tooltip>
        </Stack>
        <TabsWithState
          ariaLabel="Email details tabs"
          value={tabValue}
          onChange={(event, newValue) => setTabValue(newValue)}
          tabIndicatorColor="#008D4C"
          sx={{
            maxWidth: "max-content",
            gap: "20px",
            borderBottom: "1px solid #E9EDF2",
            "& .css-1aquho2-MuiTabs-indicator": {
              bottom: "0px !important",
            },
          }}
        >
          {tabs.map((tab, index) => (
            <StyledTab
              style={{
                font: "normal normal 400 18px/23px Source Serif Pro",
              }}
              key={index}
              disableRipple
              label={tab.label}
              {...a11yProps(index)}
            />
          ))}
        </TabsWithState>

        {tabs.map((tab, index) => (
          <TabPanel key={index} value={tabValue} index={index}>
            <Suspense fallback={<div>Loading...</div>}>{tab.content}</Suspense>
          </TabPanel>
        ))}
      </Stack>
    </EmailSettingsContext.Provider>
  );
};

export default MailSettings;
