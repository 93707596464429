import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import {StreamBearishOSLogoIconPng} from "../../../../../images";
import { AvatarNormal } from "../../../../common";
import styles from "./MeetingInfoCardSquare.module.css";
import {getHTMLContent} from "../../../Message/constant";

interface MeetingInfoCardSquareProps {
  noteDetails?: any;
}

const MeetingInfoCardSquare: React.FC<MeetingInfoCardSquareProps> = ({noteDetails}) => {
  return (
    <Stack className={styles.meetingInfoCardSquare}>
      <Stack className={styles.meetingInfoCardSquare__content}>
        <Stack className={styles.meetingInfoCardSquare__header}>
          <Box
            component={"img"}
            src={StreamBearishOSLogoIconPng}
            className={styles.meetingInfoCardSquare__logo}
            alt="logo"
          />
          <Typography className={styles.meetingInfoCardSquare__title}>
            {noteDetails?.meetingTitle || "Instant Meeting"}
          </Typography>
        </Stack>

          <Stack className={styles.meetingInfoCardSquare__description}>
            <Typography className={styles.meetingInfoCardSquare__noteName} dangerouslySetInnerHTML={{ __html: getHTMLContent(noteDetails?.title) }}/>
            <Typography className={styles.meetingInfoCardSquare__noteInfo} dangerouslySetInnerHTML={{ __html: getHTMLContent(noteDetails?.content) }}/>
          </Stack>
        </Stack>

      <Stack className={styles.meetingInfoCardSquare__footer}>
        <Stack className={styles.meetingInfoCardSquare__footerLeft}>
          <AvatarNormal
            size={20}
            avatarColor="orange"
            imgSrc={noteDetails?.authorDetails?.userAvatar}
            username={noteDetails?.authorDetails?.userName}
          />
          <Typography className={styles.meetingInfoCardSquare__footerText}>
            {noteDetails?.authorDetails?.userName || "Author Name"}
          </Typography>
        </Stack>
        <Typography className={styles.meetingInfoCardSquare__date}>
          {new Date(noteDetails?.createdAt).toLocaleDateString('en-GB')}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default MeetingInfoCardSquare;
