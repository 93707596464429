import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconDropDownButton } from "../../../../common";
import { FiltersIcon } from "../../../../../images";
import { Divider, Popover, Stack } from "@mui/material";
import {
  AttendanceScheduleFilterMenuType,
  AttendanceScheduleFilterOptionsType,
} from "./AttendanceScheduleFilterType";
import { FilterCheckboxTitle } from "../../common";
import { ChildMenu } from "./view";

interface AttendanceScheduleFilterProps {
  options: AttendanceScheduleFilterOptionsType[];
  initialSelect?: AttendanceScheduleFilterMenuType[];
  onChange: (value: AttendanceScheduleFilterMenuType[]) => void;
}

const AttendanceScheduleFilter: React.FC<AttendanceScheduleFilterProps> = ({
  initialSelect,
  onChange,
  options,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [arrowAnchorEl, setArrowAnchorEl] = useState<null | HTMLElement>(null);

  const [subMenu, setSubMenu] =
    useState<AttendanceScheduleFilterMenuType | null>(null);

  const open = Boolean(anchorEl);
  const arrowOpen = Boolean(arrowAnchorEl);
  const id = open ? "dropdown-popover" : undefined;
  const arrowPopoverId = arrowOpen ? "arrow-popover" : undefined;

  const [selectedValues, setSelectedValues] = useState<
    AttendanceScheduleFilterMenuType[]
  >(initialSelect || []);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget); // Toggle the popover
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the popover
  };

  const handleArrowButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    subMenuItems: AttendanceScheduleFilterMenuType
  ) => {
    setArrowAnchorEl(arrowAnchorEl ? null : event.currentTarget);
    setSubMenu(subMenuItems);
  };

  const handleArrowPopoverClose = () => {
    setArrowAnchorEl(null); // Close the arrow popover
    setSubMenu(null);
  };

  const mainMenuClicked = (item: AttendanceScheduleFilterMenuType) => {
    setSelectedValues((pre) => {
      if (pre.some((i) => i._id === item._id)) {
        return pre.filter((val) => val._id !== item._id);
      }
      return [
        ...pre,
        {
          ...item,
          subMenuItems: [],
        },
      ];
    });
  };

  useEffect(() => {
    onChange(selectedValues);
  }, [selectedValues]);

  return (
    <div>
      <IconDropDownButton
        label={
          selectedValues.length > 0 ? t("TRAX.FILTERED") : t("TRAX.FILTER")
        }
        icon={FiltersIcon}
        numCount={selectedValues.length > 0 ? selectedValues.length : undefined}
        menuOpen={open}
        onButtonClick={handleButtonClick}
        sx={{ width: "150px" }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            padding: 0,
            marginTop: "10px",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
            borderRadius: "5px",
          },
        }}
      >
        <Stack
          sx={{
            border: "1px solid #E9EDF2",
            borderRadius: "5px",
            boxSizing: "border-box",
            bgcolor: "#fff",
            padding: "13px 10px 10px 10px",
            width: "fit-content",
            maxHeight: "425px",
            gap: "15px",
          }}
        >
          {options.map((item, index) =>
            item.divider ? (
              <Divider key={index} />
            ) : (
              <FilterCheckboxTitle
                key={item._id}
                item={item}
                isSelected={selectedValues.some((i) => i._id === item._id)}
                onCheckboxClick={(menuItem) => mainMenuClicked(menuItem)}
                label={item.label}
                isChilds={!!item.subMenuItems?.length}
                arrowButtonClick={(event, _item) =>
                  handleArrowButtonClick(event, item)
                }
              />
            )
          )}
        </Stack>
      </Popover>

      {/* Arrow Button Popover */}
      <Popover
        id={arrowPopoverId}
        open={arrowOpen}
        anchorEl={arrowAnchorEl}
        onClose={handleArrowPopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            padding: 0,
            marginLeft: "20px",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
            borderRadius: "5px",
          },
        }}
      >
        {subMenu && (
          <ChildMenu
            options={subMenu}
            mainState={selectedValues}
            setMainState={setSelectedValues}
          />
        )}
      </Popover>
    </div>
  );
};

export default AttendanceScheduleFilter;
