import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Divider, Popover, Stack, Typography } from "@mui/material";
import { AddToWorkspace, WordWorkspace } from "../components";
import { useDispatch, useSelector } from "react-redux";
import g from "./InsideWorkspace.module.css";
import "./insideWorkspace__editorCSS.css";
import {
  ShareButton,
  ThreeDotMenuLarge,
  MediumSearchbar,
  useCustomSnackbar,
  WorkspaceList,
  DocumentCommandsMenu,
} from "../../../common";
import { menuItems } from "./InsideWorkspaceType";
import {
  AddWorkspaceContent,
  addWorkspaceContent__api,
  // ContentItem,
  ContentType,
  Get_delete_By____Id,
  getOneWorkspace__api,
  getSpace__Confluence__api,
  UpdateWorkspace,
  updateWorkspace__api,
  WorkspaceState,
  // updateWorkspaceContent__api,
  AddWorkspaceTemplate,
  addWorkspaceTemplate__api,
  UpdatableFields,
  // deleteWorkspaceContent__api,
  Add_or_update__WorkspaceCustomField,
  updateWorkspaceCustomField__api,
  getProjectDetails__Jira__api,
  getAllDocs__api,
} from "../../../../redux";
import { updateFieldWorkspaceAction } from "../../../../redux/Slice/workspace/workspaceSlice";
import { RootState } from "../../../../redux/store";
import FroalaEditorComponent from "../../../common/FroalaEditor/FroalaEditor";

interface InsideWorkspaceProps {
  coverSection?: boolean;
}
type Params = Record<string, string | undefined>;

const InsideWorkspace: FC<InsideWorkspaceProps> = () => {
  const { workspaceId, sharedType } = useParams<Params>();
  const dispatch = useDispatch();
  const location = useLocation(); // Access the current location

  const workspaceState = useSelector(
    (state: RootState) => state?.workspace as WorkspaceState
  );

  const { lockPage } = workspaceState || {};

  // State to manage the fetched workspace data
  const [workspaceData, setWorkspaceData] = useState<any>(null);
  const [sklLoader, setSklLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const enqueueSnackbar_v1 = useCustomSnackbar();
  const searchParams = new URLSearchParams(location.search); // Parse query parameters
  const source = searchParams.get("source"); // Get the 'source' parameter value
  // Fetch workspace data from API, no local check for existing workspaces
  const fetchWorkspaceData = useCallback(async () => {
    if (workspaceId && !sharedType) {
      try {
        setSklLoader(true); // Start loader
        const payload: Get_delete_By____Id | string = { _id: workspaceId };

        try {
          let result;

          // Determine the API call based on the 'source' query parameter
          if (source === "Confluence") {
            result = await dispatch(getSpace__Confluence__api(workspaceId));
          } else if (source === "Jira") {
            result = await dispatch(getProjectDetails__Jira__api(workspaceId));
          } else {
            result = await dispatch(getOneWorkspace__api(payload));
          }
          // Type assertion to bypass TypeScript warnings
          const resultData = result as any; // Bypass type checking here

          // Check if the result object has 'success' and 'data'
          try {
            if (resultData?.success) {
              // Ensure 'data' exists before setting
              if (resultData.data) {
                setWorkspaceData(resultData.data);
              } else {
                console.error("Data field is missing from the API response");
              }
            } else {
              console.error(
                "Failed to fetch workspace data:",
                resultData?.message || "Unknown error"
              );
            }
          } catch (dataError) {
            console.error("Error processing result data:", dataError);
          }
        } catch (apiError) {
          console.error("API call failed:", apiError);
        }
      } catch (err) {
        console.error("Failed to initiate workspace data fetch:", err);
      } finally {
        setSklLoader(false); // Stop loader
      }
    }  // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, workspaceId]);

  // UseEffect to fetch data once when component mounts or workspaceId changes
  useEffect(() => {
    if (workspaceId && !sharedType) {
      fetchWorkspaceData();
      if (source === "BearishOS") {
        action__get_doc__();
      }
    }
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [fetchWorkspaceData, workspaceId, sharedType]);

  // Handle updating workspace details (icon, name)
  const handleUpdateWorkspace = async (
    icon: string,
    name: string,
    appearance?: string | File
  ) => {
    if (sharedType) {
      return;
    }
    if (!workspaceId) {
      console.error("Workspace ID is not defined");
      return;
    }

    const payload: UpdateWorkspace = {
      name: name || workspaceData?.name,
      appearance: {
        coverImage: appearance
          ? appearance
          : workspaceData?.appearance?.coverImage,
        icon,
      },
    };

    try {
      await dispatch(updateWorkspace__api(payload, workspaceId));
      await fetchWorkspaceData(); // Refetch data after update
    } catch (err) {
      console.error("Failed to update workspace:", err);
    }
  };

  // Handle adding new content to the workspace
  const handleAddContent = async (action: string) => {
    if (sharedType) {
      return;
    }
    if (!workspaceId) {
      console.error("Workspace ID is not defined");
      return;
    }

    const itemType: ContentType = (() => {
      switch (action) {
        case "text":
          return ContentType.TEXT;
        case "image":
          return ContentType.IMAGE;
        case "video":
          return ContentType.VIDEO;
        case "file":
          return ContentType.FILE;
        case "audio":
          return ContentType.AUDIO;
        case "embed":
          return ContentType.EMBED;
        case "code":
          return ContentType.CODE;
        case "callout":
          return ContentType.CALLOUT;
        case "table":
          return ContentType.TABLE;
        case "whiteboard":
          return ContentType.WHITEBOARD;
        case "calendar":
          return ContentType.CALENDAR;
        case "board":
          return ContentType.BOARD;
        case "gantt":
          return ContentType.GANTT;
        case "timeline":
          return ContentType.TIMELINE;
        case "list":
          return ContentType.LIST;
        case "checklist":
          return ContentType.CHECKLIST;
        case "tree":
          return ContentType.TREE;
        case "divider":
          return ContentType.DIVIDER;
        case "headinglarge":
          return ContentType.HEADING_LARGE;
        case "headingmedium":
          return ContentType.HEADING_MEDIUM;
        case "headingsmall":
          return ContentType.HEADING_SMALL;
        case "textquote":
          return ContentType.QUOTE;
        case "textbulletedlist":
          return ContentType.NUMERICLIST;
        case "froalaeditor":
          return ContentType.FROALAEDITOR;
        default:
          console.error("Unsupported content type:", action);
          return ContentType.TEXT; // Default to TEXT
      }
    })();

    const payload: AddWorkspaceContent = {
      content: [
        {
          type: itemType,
          data: "",
          isDeleted: false,
        },
      ],
    };

    try {
      await dispatch(addWorkspaceContent__api(payload, workspaceId));
    } catch (err) {
      console.error("Failed to add content:", err);
    }
  };

  // // Handle updating content within the workspace
  // const onContentUpdate = async (
  //   componentId: string,
  //   updatedContent: any,
  //   type: string
  // ) => {
  //   if (sharedType) {
  //     return;
  //   }
  //   if (!workspaceId || !componentId) {
  //     console.error("Workspace ID or Component ID is not defined");
  //     return;
  //   }

  //   // Update local workspace data
  //   setWorkspaceData((prevData: any) => {
  //     const updatedContentList = prevData.content.map((item: ContentItem) => {
  //       if (item._id === componentId) {
  //         if (type === "checklist" && Array.isArray(updatedContent)) {
  //           return {
  //             ...item,
  //             type,
  //             data: { items: updatedContent },
  //           };
  //         }
  //         return { ...item, type, data: updatedContent };
  //       }
  //       return item;
  //     });

  //     return { ...prevData, content: updatedContentList };
  //   });

  //   // Prepare payload for API update
  //   let apiPayload;
  //   switch (type) {
  //     case "checklist":
  //       apiPayload = { type, data: updatedContent };
  //       break;
  //     case "text":
  //       apiPayload = { type, data: updatedContent?.toString() };
  //       break;
  //     default:
  //       apiPayload = { type, data: updatedContent };
  //   }

  //   const newPayload = {
  //     ...apiPayload,
  //     data: JSON.stringify(apiPayload.data), // Stringify the data field
  //   };

  //   try {
  //     await dispatch(
  //       updateWorkspaceContent__api(newPayload, workspaceId, componentId)
  //     );
  //   } catch (err) {
  //     console.error("Failed to update content:", err);
  //   }
  // };

  // // Handle deleting content
  // const onContentDelete = async (componentId: string) => {
  //   if (sharedType) {
  //     return;
  //   }
  //   if (!workspaceId || !componentId) {
  //     console.error("Workspace ID or Component ID is not defined");
  //     return;
  //   }

  //   // Update local state to mark the content as deleted
  //   setWorkspaceData((prevData: any) => {
  //     const updatedContentList = prevData.content.map((item: ContentItem) => {
  //       if (item._id === componentId) {
  //         return { ...item, isDeleted: true };
  //       }
  //       return item;
  //     });

  //     return { ...prevData, content: updatedContentList };
  //   });

  //   try {
  //     await dispatch(deleteWorkspaceContent__api(componentId, workspaceId));
  //   } catch (err) {
  //     console.error("Failed to delete content:", err);
  //   }
  // };

  // Export workspace as template
  const ExportTemplate = async () => {
    if (sharedType) {
      return;
    }
    if (!workspaceId) {
      console.error("Workspace ID is not defined");
      return;
    }

    const payload: AddWorkspaceTemplate = {
      name: workspaceData?.name,
      description: workspaceData?.description?.content,
      content: workspaceData.content,
    };

    try {
      await dispatch(addWorkspaceTemplate__api(payload, workspaceId));
    } catch (err) {
      console.error("Failed to add workspace content as template:", err);
    }
  };

  // Handle specific field update like locking the page
  const updateSpecificField = (field: UpdatableFields, value: boolean) => {
    dispatch(updateFieldWorkspaceAction({ field, value }));
  };

  const onCustomeFieldUpdate = async (data: any, value: any) => {
    if (!workspaceId || !value || !data) {
      return;
    }

    const payload: Add_or_update__WorkspaceCustomField = {
      name: data?.name,
      type: data?.type,
      options: "",
      value: value,
    };

    const action = updateWorkspaceCustomField__api(
      workspaceId,
      data?._id,
      payload
    );

    await dispatch(action);
  };

  // Handle actions from three-dot menu
  const getClickedOption = async (name: string) => {
    if (name === "Export") {
      ExportTemplate();
    } else if (name === "Lock page") {
      updateSpecificField("lockPage", !lockPage);
      enqueueSnackbar_v1(
        "info",
        `Lock page ${!lockPage ? "enabled" : "disabled"}`,
        {
          position: { vertical: "bottom", horizontal: "left" },
          autoHideDuration: 5000,
        }
      );
    }
  };

  // // Popover handling
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const decodeHTML = (html: any) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };
  const [documentTypes, setDocumentTypes] = useState<any[]>([]);
  const action__get_doc__ = async () => {
    try {
      const doc: any = {
        workspaceId: workspaceId,
        page: 1,
        limit: 10,
        sort: "asc",
        filter: "proposal",
        isStarred: false,
      };
      const docs: any = await dispatch(getAllDocs__api(doc));
      // Extract document types from the fetched documents and update state
      const docTypes = docs?.data?.map((doc: any) => ({
        _id: doc?._id,
        name: doc?.name || doc?.title,
        componentType: "Document", // Assuming 'Document' is the type for docs
      }));
      setDocumentTypes(docTypes);
    } catch (e) { console.log(e); }
  }

  return (
    <>
      <Box sx={{ minHeight: "92.5vh" }}>
        {/* Cover section */}
        <Stack
          minHeight={"125px"}
          width={"100%"}
          minWidth={"100%"}
          style={{
            backgroundImage: `url(${workspaceData?.appearance?.coverImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></Stack>
        {/* Cover section end */}
        <Stack p="30px 25px 2px 25px" height={"100%"}>
          <Box>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Stack>
                <WordWorkspace
                  workSpaceName={workspaceData?.name}
                  eIcon={workspaceData?.appearance?.icon}
                  handle__Update_workspace={handleUpdateWorkspace}
                  sklLoader={sklLoader}
                />
              </Stack>
              <Stack
                gap={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <ShareButton wId={workspaceData?._id} />
                <AddToWorkspace sources={source} documentTypes={documentTypes} label={true} />
                <ThreeDotMenuLarge
                  getClickedOption={getClickedOption}
                  options={menuItems}
                />
                <MediumSearchbar
                  ph={`Search ${workspaceData?.name || "Workspace Name"}`}
                />
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              width={"100%"}
              height={"100%"}
              mt={"0px"}
            >
              {/* <Stack width={"67.5%"} height={"100%"} mt={"40px"}>
                {filteredContent && filteredContent.length > 0 ? (
                  <Stack gap={0} direction={"column"}>
                    {filteredContent.map((item: ContentItem, index: number) => {
                      let parsedData;

                      try {
                        // Check if item.data is a string and attempt to parse it
                        if (typeof item.data === "string") {
                          parsedData = JSON.parse(item.data);
                        } else {
                          parsedData = item.data;
                        }
                      } catch (error) {
                        // Handle JSON parse error
                        console.error("Error parsing JSON data:", error);
                        parsedData = null; // or set to a default value
                      }

                      return (
                        <div key={item._id || index}>
                          <ContentDisplay
                            onContentUpdate={onContentUpdate}
                            itemData={parsedData}
                            type={item?.type}
                            componentId={item?._id || "content__id"}
                            onContentDelete={onContentDelete}
                            d={item.isDeleted}
                          />
                        </div>
                      );
                    })}
                  </Stack>
                ) : (
                  <h5
                    onClick={(e: any) => handleClick(e)}
                    style={{ opacity: "0.5" }}
                  >
                    Start writing, press the spacebar for BEBA AI, or “/” for
                    commands
                  </h5>
                )}
              </Stack> */}
              <Stack mt={"10px"}>
                <div className="insideWorkspace__editor">
                  <FroalaEditorComponent
                    initialContent={decodeHTML(
                      workspaceData?.customFields?.[0]?.value
                    )}
                    onContentChange={(value: any) => {
                      onCustomeFieldUpdate(
                        workspaceData?.customFields?.[0],
                        value
                      );
                    }}
                    isCommentButtonClicked={false}
                    setCommentButtonClicked={() => { }}
                    isCommentNeeded={false}
                  />
                </div>
              </Stack>
              {!sharedType && (
                <Stack
                  className={g.itemBox}
                  width={"31.5%"}
                  minWidth={"30.4%"}
                  maxWidth={"30.4%"}
                  height={"100%"}
                  mt={'8px'}
                >
                  <Typography
                    className={g.witext}
                    children={"Workspace items"}
                  />
                  <Divider sx={{ width: "150px", height: "6px" }} />
                  <Stack
                    height="100%"
                    overflow="hidden"
                    maxHeight="95%"
                    sx={{
                      "&::-webkit-scrollbar": { width: "0 !important" },
                      scrollbarWidth: "none",
                      overflowX: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    <WorkspaceList minMaxEclip={true} filterWorkspace={true} open__bar={true} />
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Box>
        </Stack>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <DocumentCommandsMenu onClickAction={handleAddContent} />
      </Popover>
    </>
  );
};

export default InsideWorkspace;
