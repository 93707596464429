import { Link, Popover, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LinkPreviewCloudStorage } from "../../../LinkPreviewCloudStorage";
import { useDispatch } from "react-redux";
import {
  getAllLinks__api,
  getDetailsSliderLinkDataState,
  getDetailsSliderState,
  getLink__api,
} from "../../../../../redux";
import { CreateLinkCloud } from "../../../CreateLinkCloud";

interface LinksViewProps {
  fileData: any;
}

const LinksView: React.FC<LinksViewProps> = ({ fileData }) => {
  const dispatch = useDispatch();

  const handleStateToggle = async (link: any) => {
    dispatch(getDetailsSliderState("Link Details"));
    dispatch(getDetailsSliderLinkDataState(link));
  };

  const [linkFetchingLoader, setLinkFetchingLoader] = useState({
    initialState: true,
    reInitialState: false,
  });

  const [FileLinks, setFileLinks] = useState([]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleGetAllLink = async () => {
    if (!fileData?._id) {
      return;
    }

    try {
      setLinkFetchingLoader({ initialState: false, reInitialState: true });

      const action = getAllLinks__api(fileData?._id);

      const linkFetchRes: any = await dispatch(action);

      if (
        linkFetchRes?.success &&
        linkFetchRes?.data &&
        linkFetchRes?.data?.links?.length > 0
      ) {
        setFileLinks(linkFetchRes?.data?.links);
      }
    } catch (e) {
      // console.log(e);
    } finally {
      setLinkFetchingLoader({ initialState: false, reInitialState: false });
    }
  };

  const handleGetOneLink = async (link: string) => {
    if (!link) {
      return;
    }

    try {
      const action = getLink__api(link);

      const linkFetchRes: any = await dispatch(action);

      if (linkFetchRes?.success) {
        return true;
      }
      return false;
    } catch (e) {
      // console.log(e);
      return false;
    }
  };

  useEffect(() => {
    handleGetAllLink();
  }, [fileData?._id]);

  return (
    <Stack sx={{ gap: "20px" }}>
      <Stack
        sx={{
          font: "normal normal 400 14px/18px Source Sarif Pro",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ font: "inherit" }}>All links</Typography>
        <Link
          sx={{ font: "inherit", color: "#000AFF", cursor: "pointer" }}
          underline="hover"
          onClick={(e: any) => handleClick(e)}
        >
          Create new link
        </Link>
      </Stack>

      <Stack gap={"20px"}>
        {!linkFetchingLoader?.reInitialState &&
        !linkFetchingLoader?.initialState ? (
          FileLinks.length > 0 ? ( // Check if FileLinks has any items
            FileLinks.map((link: any) => {
              return (
                <LinkPreviewCloudStorage
                  key={link._id} // Ensure each item has a unique key
                  linkName={link?.name || "Dummy Link Preview"}
                  linkVersion={link?.linkNumber || 5}
                  dateStamp={link?.updatedAt || "2024-10-07T14:55:05.858Z"}
                  onClickLink={() => handleStateToggle(link)}
                />
              );
            })
          ) : (
            <Typography
              sx={{
                fontFamily: "Source Sarif pro",
                color: "red",
                padding: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "#ffcaca",
                borderRadius: "5px",
                fontWeight: "900",
              }}
            >
              No links found
            </Typography> // Show message if no links
          )
        ) : (
          <Stack direction={"column"} gap={2}>
            <Skeleton variant="rounded" width={"100%"} height={55} />
            <Skeleton variant="rounded" width={"100%"} height={55} />
            <Skeleton variant="rounded" width={"100%"} height={55} />
            <Skeleton variant="rounded" width={"100%"} height={55} />
          </Stack>
        )}

        {linkFetchingLoader?.reInitialState &&
          !linkFetchingLoader?.initialState && (
            <>
              <Skeleton variant="rounded" width={"100%"} height={55} />
            </>
          )}
      </Stack>

      <Link
        sx={{
          font: "normal normal 400 14px/18px Source Sarif Pro",
          color: "#000AFF",
          cursor: "pointer",
        }}
        underline="hover"
        onClick={(e: any) => handleClick(e)}
      >
        Create new link
      </Link>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <CreateLinkCloud
          handleGetAllLink={handleGetAllLink}
          fileId={fileData?._id}
          handleClose={handleClose}
        />
      </Popover>
    </Stack>
  );
};

export default LinksView;
