import {
  Stack,
  Typography,
  Popover,
  InputBase,
  MenuItem,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { AllDirArrowCircleIcon, InfoIcon } from "../../../../../../../images";
import { ButtonSmall, SmallButtonColor } from "../../../../../../common";
import styles from "./ActiveDomains.module.css";
import {
  reCallAdminAPIsHandler,
  updateCompanyDomains__api,
} from "../../../../../../../redux";
import { useDispatch } from "react-redux";
import { getUser } from "../../../../../../../Utils";

interface ActiveDomainsProps {
  menuWidth?: string;
  domain?: string;
  domains?: string[];
}

const ActiveDomains: React.FC<ActiveDomainsProps> = ({
  menuWidth = "260px",
  domain,
  domains,
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [nameOfDomain, setNameOfDomain] = useState<string>("");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const user = getUser();

  const handleAddCompanyDomain = async () => {
    try {
      // Early return if required conditions are not met
      if (!user?.data?.companyId?._id || !nameOfDomain) return;

      const payload = { domainName: nameOfDomain };

      // Dispatch the action and await response
      const response: any = await dispatch(
        updateCompanyDomains__api(user.data.companyId?._id, payload)
      );

      // If the action was successful, trigger the re-call action
      if (response.success) {
        dispatch(reCallAdminAPIsHandler("company_info"));
      }
    } catch (error) {
      // Log the error or handle it according to your logging strategy
      console.error("Error adding company domain:", error);
    }
  };

  return (
    <Stack className={styles["active-domains"]} style={{ width: menuWidth }}>
      <Stack className={styles["active-domains__header"]} onClick={handleClick}>
        <Typography className={styles["active-domains__count"]}>
          {(domains && domains?.length + 1) || 1}
        </Typography>
        <Typography className={styles["active-domains__label"]}>
          Active Domains
        </Typography>

        <Stack className={styles["active-domains__icon-wrapper"]}>
          <AllDirArrowCircleIcon w={15} direction={open ? "down" : "up"} />
        </Stack>
      </Stack>

      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: { boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.15)", mt: "5px" },
        }}
      >
        <Stack className={styles["active-domains__popover"]}>
          {/* input area */}
          <Stack className={styles["active-domains__input-area"]}>
            <Stack className={styles["active-domains__input-wrapper"]}>
              <InputBase
                placeholder="Create a new team"
                value={nameOfDomain}
                className={styles["active-domains__input"]}
                onChange={(e: any) => {
                  setNameOfDomain(e.target.value);
                }}
              />
              <ButtonSmall
                colorVarient={SmallButtonColor.BearishClay}
                label="Add new"
                onClick={() => {
                  handleAddCompanyDomain();
                }}
                sx={{
                  height: "17px",
                  padding: "0px 5px",
                  minWidth: "fit-content",
                  font: "normal normal 900 10px/13px Source Serif Pro",
                }}
              />
            </Stack>
          </Stack>
          {/* ------------ */}

          {/* options */}
          <Stack className={styles["active-domains__options"]}>
            {/* Show domain first */}
            {domain && (
              <MenuItem
                onClick={handleClose}
                className={styles["active-domains__option-item"]}
              >
                <Typography className={styles["active-domains__option-label"]}>
                  {domain}
                </Typography>
                <IconButton className={styles["active-domains__info-button"]}>
                  <InfoIcon w={13} />
                </IconButton>
              </MenuItem>
            )}

            {/* Show domains if they exist */}
            {domains &&
              domains.length > 0 &&
              domains.map((domainItem : any, index) => (
                <MenuItem
                  key={index}
                  onClick={handleClose}
                  className={styles["active-domains__option-item"]}
                >
                  <Typography
                    className={styles["active-domains__option-label"]}
                  >
                    {domainItem?.name}
                  </Typography>
                  <IconButton className={styles["active-domains__info-button"]}>
                    <InfoIcon w={13} />
                  </IconButton>
                </MenuItem>
              ))}
          </Stack>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default ActiveDomains;
