import React, { lazy, Suspense, useEffect, useState } from "react";
import { Container, Box, Stack, Slide } from "@mui/material";
import CalendarTabs from "./CalendarTabs";

import {
  AuthCalendars__api,
  changeLayoutState,
  LayoutState,
  recallAPI,
} from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { MiniListCalendarView } from "../../common";
import { RootState } from "../../../redux/store";
import { msalInstance } from "./authConfigCalendar";
import { TopSideRightCalBar } from "./components_v2_0_1";
import { TaskInterface } from "../Workspace";

const MainCalInterface = lazy(() => import("./MainCalInterface"));

const Calendar: React.FC = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const calendarState = useSelector(
    (state: RootState) => state && state?.calendar
  );
  const { reCall } = calendarState || {};

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const layoutState = useSelector(
    (state: RootState) => state && (state?.layout as LayoutState)
  );

  const [calendarSidebar, setCalendarSidebar] = useState({
    state_open: false,
    state_application: null as
      | "start_call"
      | "start_meeting"
      | "open_mail"
      | "open_calendar_mini"
      | "open_task"
      | "open_cloud"
      | null,
  });

  const handleShow_rightBar = (view?: string) => {
    const drawerType = "isDrawer_Calendar";

    if (!view) {
      console.warn("No view specified for the sidebar.");
      return;
    }

    // Open or close the sidebar based on the current state
    const isSidebarOpen =
      calendarSidebar.state_open && calendarSidebar.state_application === view;

    if (isSidebarOpen) {
      // Close the sidebar
      setCalendarSidebar({
        state_open: false,
        state_application: null,
      });
      dispatch(
        changeLayoutState({
          type: drawerType,
          value: true, // Close the drawer
        })
      );
    } else {
      // Open the sidebar with the specified view
      setCalendarSidebar({
        state_open: true,
        state_application: view as typeof calendarSidebar.state_application,
      });
      dispatch(
        changeLayoutState({
          type: drawerType,
          value: false, // Open the drawer
        })
      );
    }
  };

  useEffect(() => {
    // Monitor layoutState?.isDrawer_Calendar for changes
    if (layoutState?.isDrawer_Calendar && calendarSidebar.state_open) {
      // If the sidebar is open and the drawer is toggled, reset the calendarSidebar state
      setCalendarSidebar({
        state_open: false,
        state_application: null,
      });
    } // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [layoutState?.isDrawer_Calendar]);

  const [isMsalInitialized, setIsMsalInitialized] = useState<boolean>(false);
  const loginRequest = {
    scopes: ["openid", "profile", "Calendars.ReadWrite", "offline_access"],
  };
  useEffect(() => {
    const initializeMsal = async () => {
      try {
        // Only initialize MSAL once
        await msalInstance.initialize();
        setIsMsalInitialized(true);

        // Check for redirect and handle it immediately
        const handleRedirectResponse = async () => {
          const authResponse: any = await msalInstance.handleRedirectPromise();
          if (authResponse) {
            await sendTokensToBackend(authResponse);
          }
        };

        await handleRedirectResponse();
      } catch (err) {
        console.error("MSAL Initialization Error:", err);
      }
    };

    initializeMsal(); // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const login = async () => {
    try {
      if (!isMsalInitialized) return;

      // This will open a popup for the user to log in
      const loginResponse = await msalInstance.loginPopup(loginRequest);

      // After login, acquire the token
      const tokenResponse = await msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: loginResponse.account,
      });

      // Send the acquired token to the backend
      await sendTokensToBackend(tokenResponse);
      dispatch(recallAPI(null));
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  const sendTokensToBackend = async (tokenResponse: any) => {
    try {
      if (!tokenResponse) {
        return;
      }

      const action = AuthCalendars__api("Microsoft", tokenResponse);
      await dispatch(action);
      dispatch(recallAPI("All"));
    } catch (error) {
      console.error("Error saving tokens:", error);
    }
  };

  useEffect(() => {
    switch (reCall) {
      case "OutLookLogin":
        login();
        break;

      default:
        break;
    } // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [reCall]);

  // Render the application sidebar
  const renderApplicationSidebar = (view: string | null) => {
    if (view === "open_calendar_mini") {
      return (
        <Suspense fallback={<div>Loading Sidebar...</div>}>
          <MiniListCalendarView layoutAltered={calendarSidebar.state_open} />
        </Suspense>
      );
    }
    if (view === "open_task") {
      return (
        <Suspense fallback={<div>Loading Sidebar...</div>}>Task View</Suspense>
      );
    }
    if (view === "start_call") {
      return (
        <Suspense fallback={<div>Loading Sidebar...</div>}>
          <div>Start Call View</div>{" "}
          {/* Replace with actual component for Start Call */}
        </Suspense>
      );
    }
    if (view === "start_meeting") {
      return (
        <Suspense fallback={<div>Loading Sidebar...</div>}>
          <div>Start Meeting View</div>{" "}
          {/* Replace with actual component for Start Meeting */}
        </Suspense>
      );
    }
    if (view === "open_mail") {
      return (
        <Suspense fallback={<div>Loading Sidebar...</div>}>
          <div>Open Mail View</div>{" "}
          {/* Replace with actual component for Open Mail */}
        </Suspense>
      );
    }
    if (view === "open_cloud") {
      return (
        <Suspense fallback={<div>Loading Sidebar...</div>}>
          <div>Open Cloud View</div>{" "}
          {/* Replace with actual component for Open Cloud */}
        </Suspense>
      );
    }
    return null;
  };

  return (
    <Container
      sx={{

        maxWidth: "none !important", // Force the maxWidth override
        marginRight: "unset",
        marginLeft: "unset",
        display: "flex",
        alignItems: "flex-start",
        gap: 0.2,
        "&.css-cz1h7t-MuiContainer-root": {
          maxWidth: "none !important", // Override the specific class if it's applied
          marginRight: "unset",
          marginLeft: "unset",
        },
      }}
      disableGutters
    >
      <Stack width={"100%"} padding={ !calendarSidebar.state_open ? '10px 15px 10px 15px' : "10px 10px 10px 15px"}>
        <Box
          width={"100%"}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          {/* Tabs for navigation */}
          <Stack
            width={"100%"}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <CalendarTabs
              value={value}
              calendarSidebar={calendarSidebar}
              handleShow_rightBar={handleShow_rightBar}
              handleChange={(e: any, v: any) => handleChange(e, v)}
            />
          </Stack>
        </Box>
        {/* Content Area */}
        <Box mt={2}>
          {value === 0 && (
            <Suspense fallback={<div>Loading Calendar...</div>}>
              <MainCalInterface stateOfRightBar={calendarSidebar.state_open} />
            </Suspense>
          )}
          {value === 1 && (
            <TaskInterface stateOfRightBar={calendarSidebar.state_open} />
          )}
          {value === 2 && (
            <TaskInterface stateOfRightBar={calendarSidebar.state_open} />
          )}
          {value === 3 && <div>Trax Component Placeholder</div>}
        </Box>
      </Stack>

      {/* {calendarSidebar.state_open && ( */}
      <Slide
        direction="left"
        in={calendarSidebar.state_open}
        mountOnEnter
        unmountOnExit
        timeout={{
          enter: 350, // Time (in ms) for the opening animation
          exit: 350, // Time (in ms) for the closing animation
        }}
      >
        <Stack width={"40%"} position={"relative"}>
          <Stack
            width={"100%"}
            position={"absolute"}
            top={"-10px"}
            right={"0px"}
            padding={'15px 5px 5px 5px'}
            zIndex={1}
            maxHeight="143dvh"
            minHeight="143dvh"
            sx={{
              overflowY: "scroll",
              overflowX: "hidden",
              borderLeft: "1px solid #E9EDF2",
              boxShadow: "3px 0px 12px 0px #00000040",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Suspense fallback={<div>Loading Sidebar...</div>}>
              <TopSideRightCalBar
                sidebarActive={calendarSidebar.state_application}
                handleShow_rightBar={handleShow_rightBar}
              />
            </Suspense>
            {calendarSidebar.state_open &&
              renderApplicationSidebar(calendarSidebar.state_application)}
          </Stack>
        </Stack>
      </Slide>
      {/* // )} */}
    </Container>
  );
};

export default Calendar;
