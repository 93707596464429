import { IconButton, Stack } from "@mui/material";
import React, { lazy, Suspense } from "react";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";
import { SettingsIcon } from "../../../../../images";
import { TabLoader } from "../../../../common";
import { ProjectOptionThreeDotMenu } from "../ProjectOptionThreeDotMenu";

const LazyOverviewTab = lazy(() => import("./tabs/OverviewTab/OverviewTab"));
const LazyPublicPageTab = lazy(
  () => import("./tabs/PublicPageTab/PublicPageTab")
);
const LazyTimelineTab = lazy(() => import("./tabs/TimelineTab/TimelineTab"));
const LazyTeamTab = lazy(() => import("./tabs/TeamTab/TeamTab"));
const LazyResourcesTab = lazy(() => import("./tabs/ResourcesTab/ResourcesTab"));

const tabs = [
  {
    label: "Overview",
    content: <LazyOverviewTab />,
  },
  {
    label: "Public page",
    content: <LazyPublicPageTab />,
  },
  {
    label: "Timeline",
    content: <LazyTimelineTab />,
  },
  {
    label: "Team",
    content: <LazyTeamTab />,
  },
  {
    label: "Resources",
    content: <LazyResourcesTab />,
  },
];

const ProjectInfoTab: React.FC = ({}) => {
  const [tabValue, setTabValue] = React.useState(0);
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        gap: "10px",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "20px",
          borderBottom: "1px solid #E9EDF2",
        }}
      >
        <TabsWithState
          ariaLabel="Project info tabs"
          value={tabValue}
          onChange={(event, newValue) => setTabValue(newValue)}
          tabIndicatorColor="#008D4C"
          sx={{
            maxWidth: "max-content",
            borderBottom: "0px solid #E9EDF2",
            "& .css-1aquho2-MuiTabs-indicator": {
              bottom: "0px !important",
            },
          }}
        >
          {tabs.map((tab, index) => (
            <StyledTab
              style={{
                font: "normal normal 300 15px/19px Source Serif Pro",
                padding: "10px 0px",
              }}
              key={index}
              disableRipple
              label={tab.label}
              {...a11yProps(index)}
            />
          ))}
        </TabsWithState>

        <ProjectOptionThreeDotMenu />

        <IconButton>
          <SettingsIcon w={15} />
        </IconButton>
      </Stack>

      {tabs.map((tab, index) => (
        <TabPanel
          style={{ display: "contents" }}
          key={index}
          value={tabValue}
          index={index}
        >
          <Suspense fallback={<TabLoader />}>{tab.content}</Suspense>
        </TabPanel>
      ))}
    </Stack>
  );
};

export default ProjectInfoTab;
