import {
  Avatar,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

import styles from "./TeamMembersTable.module.css";
import { AvatarNormal, BorderLinearProgress } from "../../../../common";

// ////////////////////////////////////////////////////////////////////////////////

const COLUMNS_NAME: { id: number; label: string }[] = [
  { id: 1, label: "Name" },
  { id: 2, label: "Email" },
  { id: 3, label: "Progress" },
  { id: 4, label: "Role" },
  { id: 5, label: "Team" },
  //   total > 150 + 150 + 94 + 95 + 86 + 1 =  585 px
];

const TeamMembersTable: React.FC = () => {
  return (
    <Paper
      sx={{ width: "100%", overflow: "hidden", border: "1px solid #E9EDF2" }}
    >
      <TableContainer className={styles["team-members-table-container"]}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {COLUMNS_NAME.map((column) => (
                <TableCell
                  key={column.id}
                  className={`${
                    styles["team-members-table-header-container"]
                  } ${
                    styles[`team-members-table__${column.label.toLowerCase()}`]
                  }`}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {DUMMY_DATA.map((item) => (
              <TableRow key={item.email}>
                <TableCell
                  className={`${styles["team-members-table-body-container"]} ${styles["team-members-table__name"]}`}
                >
                  <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
                    <AvatarNormal
                      size={25}
                      avatarColor={item.avatarColor}
                      username={item.name}
                      imgSrc={item.imageSrc}
                    />
                    {item.name}
                  </Stack>
                </TableCell>
                <TableCell
                  className={`${styles["team-members-table-body-container"]} ${styles["team-members-table__email"]}`}
                >
                  {item.email}
                </TableCell>
                <TableCell
                  className={`${styles["team-members-table-body-container"]} ${styles["team-members-table__progress"]}`}
                >
                  <BorderLinearProgress
                    sx={{ height: "5px" }}
                    progressPercent={item.progress}
                  />
                </TableCell>
                <TableCell
                  className={`${styles["team-members-table-body-container"]} ${styles["team-members-table__role"]}`}
                >
                  {item.role}
                </TableCell>
                <TableCell
                  className={`${styles["team-members-table-body-container"]} ${styles["team-members-table__team"]}`}
                >
                  {item.team}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TeamMembersTable;

type DummyData = {
  imageSrc: string;
  avatarColor: string;
  name: string;
  email: string;
  progress: number;
  role: string;
  team: string;
};

const DUMMY_DATA: DummyData[] = [
  {
    imageSrc: "https://randomuser.me/api/portraits/men/10.jpg",
    avatarColor: "#FF5733",
    name: "John Doe",
    email: "doe@exampdjsfnsflshlfs;kle.com",
    progress: 85,
    role: "Developer",
    team: "Alpha",
  },
  {
    imageSrc: "https://randomuser.me/api/portraits/women/10.jpg",
    avatarColor: "#3357FF",
    name: "Jane Smith",
    email: "smith@example.com",
    progress: 72,
    role: "Designer",
    team: "Beta",
  },
  {
    imageSrc: "https://randomuser.me/api/portraits/men/20.jpg",
    avatarColor: "#33FF57",
    name: "Mike Johnson",
    email: "johnson@example.com",
    progress: 60,
    role: "Manager",
    team: "Gamma",
  },
  {
    imageSrc: "https://randomuser.me/api/portraits/women/20.jpg",
    avatarColor: "#F333FF",
    name: "Emily Davis",
    email: "davis@example.com",
    progress: 90,
    role: "Developer",
    team: "Alpha",
  },
  {
    imageSrc: "https://randomuser.me/api/portraits/men/30.jpg",
    avatarColor: "#33FFF5",
    name: "David Wilson",
    email: "wilson@example.com",
    progress: 40,
    role: "QA Engineer",
    team: "Beta",
  },
  {
    imageSrc: "https://randomuser.me/api/portraits/women/30.jpg",
    avatarColor: "#F5FF33",
    name: "Sophia Brown",
    email: "brown@example.com",
    progress: 75,
    role: "Product Owner",
    team: "Gamma",
  },
  {
    imageSrc: "https://randomuser.me/api/portraits/men/40.jpg",
    avatarColor: "#FF5733",
    name: "James Lee",
    email: "lee@example.com",
    progress: 65,
    role: "Scrum Master",
    team: "Alpha",
  },
  {
    imageSrc: "https://randomuser.me/api/portraits/women/40.jpg",
    avatarColor: "#3357FF",
    name: "Olivia Harris",
    email: "harris@example.com",
    progress: 80,
    role: "Designer",
    team: "Beta",
  },
  {
    imageSrc: "https://randomuser.me/api/portraits/men/50.jpg",
    avatarColor: "#33FF57",
    name: "William Clark",
    email: "clark@example.com",
    progress: 50,
    role: "Developer",
    team: "Gamma",
  },
  {
    imageSrc: "https://randomuser.me/api/portraits/women/50.jpg",
    avatarColor: "#F333FF",
    name: "Isabella Martinez",
    email: "martinez@example.com",
    progress: 95,
    role: "Developer",
    team: "Alpha",
  },
];
