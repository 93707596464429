import { InputBase, RadioGroup, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  AssignedEmployeesView,
  BreaksView,
  CreateRoleContainer,
  HeaderView,
  OvertimeView,
  PayScaleinputArea,
  ReportsToView,
} from "./view";
import {
  EyeIcon,
  FlagIcon,
  FundingIcon,
  LocationIcon,
  NotesLineIcon,
  PositionTypeIcon,
  RelatedTaskIcon,
  ShiftIcon,
  TemplateIcon,
  TranslateIcon,
} from "../../../../../images";
import {
  ButtonSmall,
  CustomRadioFormControlLabel,
  DottedButton,
  EditableTextArea,
  LanguageDropdownMenu,
  languagesList,
  SmallButtonColor,
  StyledRadioButtonBlack,
  TextLinkButton,
} from "../../../../common";
import { IconContainer } from "../CreateShiftMenu/view";
import { PositionsDropDown } from "../PositionsDropDown";
import { VisibilityDropdown } from "../VisibilityDropdown";
import { ShiftDropdown } from "../ShiftDropdown";
import { DegreeDropdown } from "../DegreeDropdown";
import { JobLocationsDropdown } from "../JobLocationsDropdown";
import { RoleLanguageDropdown } from "../RoleLanguageDropdown";

interface CreateRoleMenuProps {
  onCloseClick: () => void;
}

const CreateRoleMenu: React.FC<CreateRoleMenuProps> = ({ onCloseClick }) => {
  const [makeTemplateCheckbox, setMakeTemplateCheckbox] = useState(false);
  const [isSingleRole, setIsSingleRole] = useState<boolean>(false);
  const [roleName, setRoleName] = useState("");
  const [roleFilledRequired, setRoleFilledRequired] = useState<boolean>(false);
  return (
    <Stack sx={{ width: "550px", height: "100%", bgcolor: "white" }}>
      {/* card header */}
      <HeaderView
        onGlobalRoleSettingsClick={() => null}
        onCloseClick={onCloseClick}
        checkboxClick={() => setMakeTemplateCheckbox((pre) => !pre)}
        isCheckboxSelected={makeTemplateCheckbox}
      />

      {/* ---- card body ---- */}
      <Stack
        sx={{
          flex: 1,
          overflow: "auto",
          scrollbarWidth: "none",
          padding: "20px",
          gap: "35px",
        }}
      >
        <CreateRoleContainer
          icon={ShiftIcon}
          titleComponent={
            <InputBase
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              placeholder="Create new role..."
              sx={{
                font: "normal normal 900 13px/16px Source Serif Pro",
                maxWidth: "200px",
              }}
            />
          }
        >
          <Stack
            onClick={() => setIsSingleRole((pre) => !pre)}
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
              width: "fit-content",
            }}
          >
            <StyledRadioButtonBlack checked={isSingleRole} radioSize={19} />
            <Typography
              sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
            >
              Single
            </Typography>
          </Stack>
        </CreateRoleContainer>

        {/* ----- Job Role/Position Area ------ */}
        <IconContainer
          icon={PositionTypeIcon}
          iconSize={18}
          title="Job Role/Position"
        >
          <Stack sx={{ gap: "5px", alignItems: "end" }}>
            <PositionsDropDown />
            <TextLinkButton
              buttonLabel="Create new position"
              sx={{ width: "fit-content" }}
            />
          </Stack>
        </IconContainer>

        {/* ----- Select Shift Area ------ */}
        <IconContainer icon={ShiftIcon} title="Select Shift">
          <ShiftDropdown />
        </IconContainer>

        {/* ----- Competency/Skills Area ------ */}
        <IconContainer icon={RelatedTaskIcon} title="Competency/Skills">
          <DegreeDropdown />
        </IconContainer>

        {/* ----- Visibility Area ------ */}
        <IconContainer icon={EyeIcon} title="Visibility">
          <VisibilityDropdown />
        </IconContainer>

        {/* ----- Critical/Priority Area ------ */}
        <IconContainer
          icon={FlagIcon}
          title="Critical/Priority"
          titleMarginTop="0px"
        >
          <Stack
            onClick={() => setRoleFilledRequired((pre) => !pre)}
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
              width: "fit-content",
            }}
          >
            <StyledRadioButtonBlack checked={roleFilledRequired} />
            <Typography
              sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
            >
              Role must always be filled
            </Typography>
          </Stack>
        </IconContainer>

        {/* ----- Reports To Area ------ */}
        <ReportsToView />

        {/* ----- Job Location Area ------ */}
        <IconContainer icon={LocationIcon} title="Job Location">
          <JobLocationsDropdown />
        </IconContainer>

        {/* ----- Role Language  Area ------ */}
        <IconContainer icon={TranslateIcon} title="Role Language ">
          <RoleLanguageDropdown />
        </IconContainer>

        {/* ----- Pay Scale Area ------ */}
        <IconContainer icon={FundingIcon} title="Pay Scale">
          <PayScaleinputArea />
        </IconContainer>

        {/* ----- BreaksView Area ------ */}
        <BreaksView />

        {/* ----- OvertimeView Area ------ */}
        <OvertimeView />

        {/* ----- AssignedEmployeesView Area ------ */}
        <AssignedEmployeesView />

        {/* ----- Notes Area ------ */}
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <NotesLineIcon w={15} />
          <Typography
            sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
          >
            Notes
          </Typography>
        </Stack>

        <EditableTextArea
          minRows={5}
          maxRows={5}
          style={{
            font: "normal normal 300 13px/16px Source Serif Pro",
            border: "1px solid #E9EDF2",
            marginTop: "-10px",
            flexShrink: 0,
          }}
        />

        {/* ----- Save Buttons ------ */}
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            gap: "10px",
          }}
        >
          <ButtonSmall
            sx={{ padding: "0px 10px" }}
            label="Save & Go to Assign Role"
            colorVarient={SmallButtonColor.Transparent}
          />
          <ButtonSmall
            label="Save"
            colorVarient={SmallButtonColor.TraxBluish}
          />
        </Stack>

        <DottedButton
          title="View all templates"
          icon={TemplateIcon}
          sx={{ marginTop: "-20px" }}
        />
      </Stack>
    </Stack>
  );
};

export default CreateRoleMenu;
