import React, { useEffect, useState } from "react";
import { DropdownLarge } from "../../../common";
import { Department, FirstLoginData, FirstLoginDataProps } from "./StepsType";
import { Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  AuthState,
  CompanyAvailability,
  companyAvailibityPayload,
  companyRegistryCheck,
  fetchCompanyLists,
  getAllDepartment,
} from "../../../../redux";
import { RootState } from "../../../../redux/store";
import CompanyNameAutocomplete from "./CompanyNameAutocomplete/CompanyNameAutocomplete ";

interface StepTwoProps extends FirstLoginDataProps {
  firstLoginData: FirstLoginData;
  setFirstLoginData: React.Dispatch<React.SetStateAction<FirstLoginData>>;
}

const StepTwo: React.FC<StepTwoProps> = ({
  firstLoginData,
  setFirstLoginData,
}) => {
  const {
    departmentList = [],
    companyAvailibility = {},
    companyList,
    user,
  } = useSelector((state: RootState) => state && state.auth as AuthState);

  const dispatch = useDispatch();

  const [companyAvalibilityCheckLoader, setCompanyAvalibilityCheckLoader] =
    useState<boolean>(false);

  // Function to update the department in firstLoginData
  const updateDepartment = (departments: Department[]) => {
    setFirstLoginData({
      ...firstLoginData,
      departMent: departments,
    });
  };

  const getAllDepartments = async () => {
    const urlParams = new URLSearchParams(window.location.search);

    // Extract parameters from URL
    const isOrganizationSet = urlParams.get("isOrganizationSet") === "true";
    const email = urlParams.get("email");
    const userType = urlParams.get("userType");

    // If no parameters are found in the URL, fetch departments with general access
    if (!isOrganizationSet && !email && !userType) {
      await dispatch(getAllDepartment());
      return;
    }

    // Handle Admin-specific logic
    if (userType === "Admin") {
      // Add Admin-specific logic here if needed
    }

    // Add email to API call only if isOrganizationSet is false
    const params = !isOrganizationSet ? {} : { email };

    // Check if userType is not Manager (skip if not Manager or Admin)
    if (userType !== "Manager" && userType !== "Admin") {
      return;
    }

    // Proceed with fetching departments
    await dispatch(getAllDepartment(params));
  };


  useEffect(() => {
    if (departmentList.length === 0) {
      getAllDepartments();
    }
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [firstLoginData]);

  const handleCompanyRegistryList = async (value: string) => {
    setCompanyAvalibilityCheckLoader(true);
    if (!value) {
      return;
    }
    const payload: companyAvailibityPayload = {
      companyName: value,
    };
    await dispatch(fetchCompanyLists(payload));
    setCompanyAvalibilityCheckLoader(false);
  };

  const handleCompanyRegistryCheck = async () => {
    if (!firstLoginData.companyName) {
      return;
    }
    setCompanyAvalibilityCheckLoader(true);
    const payload: companyAvailibityPayload = {
      domain: firstLoginData.domain,
    };
    await dispatch(companyRegistryCheck(payload));

    setCompanyAvalibilityCheckLoader(false);
  };

  // Debounce the company registry check
  useEffect(() => {
    if (user && user?.data?.companyId) {
      return;
    }
    handleCompanyRegistryCheck();
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [firstLoginData.companyName]);

  useEffect(() => {
    if (user && user?.data?.companyId) {
      return;
    }
    if (
      companyAvailibility &&
      "message" in companyAvailibility &&
      "success" in companyAvailibility
    ) {
      const availability = companyAvailibility as CompanyAvailability;
      setFirstLoginData({
        ...firstLoginData,
        companyRegistryChecked:
          availability.message === "Company name is available" &&
          availability.success === true,
      });
    }    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [companyAvailibility]);

  return (
    <Stack
      width={"100%"}
      gap={"15px"}
      alignItems={"center"}
      justifyContent={"center"}
      mb={"20px"}
    >
      {/* <LargeInputBox
        borderColor="black"
        loader={companyAvalibilityCheckLoader || false}
        label="Company name"
        value={firstLoginData?.companyName || ""}
        autoComplete="off"
        onChange={(e) => {
          const value = typeof e === "string" ? e : e.target.value;
          setFirstLoginData({
            ...firstLoginData,
            companyName: value,
          });
        }}
      /> */}

      <CompanyNameAutocomplete
        disableCompanySelectionAndSetDefault={user?.data?.companyId ? true : false}
        companyOptions={companyList?.data ?? []}
        firstLoginData={firstLoginData}
        setFirstLoginData={setFirstLoginData}
        companyAvalibilityCheckLoader={companyAvalibilityCheckLoader || false}
        handleCompanyRegistryList={handleCompanyRegistryList}
      />

      {user?.data ? (
        user?.data?.userType === "Admin" || user?.data.userType === "Manager" ? (
          <DropdownLarge
            width="476px"
            componentHeader="Department"
            options={departmentList}
            onChange={updateDepartment} // Pass the updateDepartment function to the onChange prop
            borderColor="black"
            multiple={true} // Ensure multiple selection is enabled
            initialSelectedOptions={firstLoginData.departMent}
          />
        ) : (
          // For Guest or Auditor, text will indicate that they inherit the Admin's department
          (user?.data?.userType === "Guest" || user?.data.userType === "Auditor") ? (
            <Typography
              sx={{
                fontSize: "11px",
                fontWeight: "400",
                fontFamily: "Source Serif Pro",
                color: "#555",
              }}
            >
              Your department has been inherited from the Admin. No action required.
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: "11px",
                fontWeight: "400",
                fontFamily: "Source Serif Pro",
                color: "#555",
              }}
            >
              Your department has already been assigned to your Manager.
            </Typography>
          )
        )
      ) : (
        <DropdownLarge
          width="476px"
          componentHeader="Department"
          options={departmentList}
          onChange={updateDepartment} // Pass the updateDepartment function to the onChange prop
          borderColor="black"
          multiple={true} // Ensure multiple selection is enabled
          initialSelectedOptions={firstLoginData.departMent}
        />
      )}

    </Stack>
  );
};

export default StepTwo;
