import React, { useState, useEffect } from "react";
import { Box, Grow, Skeleton, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ViewMode } from "../Projects";
import { useDispatch, useSelector } from "react-redux";
import { ActiveWorkspaceTab, MainTopWorkspace } from "./components";
import {
  LargeCardAndImage,
  LargeStackedAndImage,
} from "../Projects/components";
import {
  confluence__oauth__handle,
  deleteSpace__Confluence__api,
  deleteWorkspace__api,
  getAllSharedWorkspace__api,
  getAllUsers,
  getAllWorkspace__api,
  listSpaces__Confluence__api,
  getAllProjects__Jira__api,
  UpdateWorkspace,
  updateWorkspace__api,
  WorkspaceData,
  // WorkspaceState,
} from "../../../redux";
import { RootState } from "../../../redux/store";
import { getUser } from "../../../Utils";
import { CommandControl_W as CmdW } from "./CommandControl_W";
import { SearchContainer } from "../SearchContainer";
import { no_workspace } from "../../../images";

/**
 * Styles for the container box that holds project cards.
 */
const BOX_STYLE = {
  maxHeight: "81.5vh", // Maximum height of the container box
  overflow: "hidden", // Hide overflowing content
  overflowX: "hidden", // Hide horizontal overflow
  overflowY: "scroll", // Enable vertical scrolling for overflow
  "&::-webkit-scrollbar": {
    width: "5px", // Width of the scrollbar
    borderRadius: "4px", // Border radius of the scrollbar
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "gray", // Color of the scrollbar thumb
    borderRadius: "4px", // Border radius of the scrollbar thumb
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "lightgray", // Color of the scrollbar track
    borderRadius: "8px", // Border radius of the scrollbar track
  },
};

/**
 * Sticky tab styles for the top navigation tabs.
 */
const STICKY_TAB_STYLE = {
  position: "sticky", // Stick to the top of the container
  top: 0, // Stick to the very top of the container
  zIndex: 1000, // Ensure it's above other content
  background: "white", // Background color of the sticky tab
  marginTop: "10px", // Space above the sticky tab
};

const Workspace: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const [workspaceLoader, setWorkspaceLoader] = useState({
    firstTime: true,
    regularLoader: false,
  });

  const workspaceState = useSelector(
    (state: RootState) => state && state?.workspace
  );
  const { error, workspaceList, sharedWorkspaceList } = workspaceState || {};
  const {
    workspaceList: workspaces = [],
    api_call_timeStamp,
    is_api_called,
    WorkspaceListError,
  } = workspaceList || {};
  const {
    workspaceSharedList: workspacesShared = [],

    WorkspaceSharedListError,
  } = sharedWorkspaceList || {};

  const [tabActive, setTabActive] = useState<number>(0);
  const [viewMode, setViewMode] = useState<ViewMode>("square");

  const handleGetTokenFromConfluence = async (token: string) => {
    try {
      const action = confluence__oauth__handle(token);

      const r: any = await dispatch(action);

      if (r?.message === "Authorization successful") {
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const slug = location.pathname.split("/")[1]; // Extract 'oauth' or 'invitee' from the path

    if (code && slug) {
      handleGetTokenFromConfluence(code);
    }
  }, [location.pathname]);

  const handleNavigate = (id: string, source?: string) => {
    navigate(`/workspace/${id}?source=${source}`);
  };

  const [editOpenPopUp, setEditOpenPopUp] = useState<boolean>(false);
  const handleEditPopUp = () => {
    setEditOpenPopUp(false);
  };
  const [initialDataOfWorkspace, setIntialDataOfWorkspace] =
    useState<WorkspaceData | null>(null);

  const getAllWorkspace = async () => {
    setWorkspaceLoader((prevState) => ({ ...prevState, regularLoader: true }));
    try {
      await dispatch(getAllWorkspace__api());
      await dispatch(listSpaces__Confluence__api());
      await dispatch(getAllProjects__Jira__api());
    } catch (err) {
      // console.error("Failed to fetch workspaces:", err);
      if (error) {
        console.error("Error from state:", error);
      }
    } finally {
      setWorkspaceLoader((prevState) => ({
        ...prevState,
        regularLoader: false,
        firstTime: false,
      }));
    }
  };

  const getAllSharedWorkspace = async () => {
    setWorkspaceLoader((prevState) => ({ ...prevState, regularLoader: true }));
    try {
      await dispatch(getAllSharedWorkspace__api());
    } catch (err) {
      // console.error("Failed to fetch workspaces:", err);
      if (error) {
        console.error("Error from state:", error);
      }
    } finally {
      setWorkspaceLoader((prevState) => ({
        ...prevState,
        regularLoader: false,
        firstTime: false,
      }));
    }
  };

  // Delete workspace logic
  const deleteWorkspace = async (_id: string, source: string) => {
    try {
      if (!_id) {
        console.error("Workspace ID is not defined");
        return;
      }

      // Hard check: If it's a valid Confluence space key and not a MongoDB ObjectId
      if (source === "Confluence") {
        // console.log("Deleting workspace (Confluence space key):", _id);
        await dispatch(deleteSpace__Confluence__api(_id)); // Dispatch Confluence-specific delete action
      }
      // Hard check: If it's a valid MongoDB ObjectId and NOT a Confluence space key
      else if (source === "BearishOS") {
        // console.log("Deleting workspace (MongoDB ObjectId):", _id);
        await dispatch(deleteWorkspace__api({ _id: _id })); // Dispatch MongoDB-specific delete action
      } else {
        // If neither, it's an invalid ID or mixing up types
        console.error(`Invalid workspace ID or mixing up space types: ${_id}`);
      }
    } catch (err) {
      console.error("Failed to delete workspace:", err);
    }
  };

  const updateWorkspace = async (
    data: UpdateWorkspace | FormData,
    workspaceId: string
  ) => {
    try {
      if (!workspaceId) {
        console.error("Workspace ID is not defined", workspaceId, data);
        return;
      }

      await dispatch(updateWorkspace__api(data, workspaceId));
    } catch (err) {
      console.error("Failed to update workspace:", err);
    }
  };

  const handleWorkspaceColorThemes = async (
    data: any,
    workspaceId: string,
    theme: string
  ) => {
    const formData = new FormData();
    const emojis = ["🫠", "🌟", "🚀", "🎉", "🧩"];
    const getRandomEmoji = () =>
      emojis[Math.floor(Math.random() * emojis.length)];

    if (workspaceId && theme && data) {
      formData.append("name", data?.name);
      formData.append("description[content]", data?.description?.content);
      formData.append("appearance[coverImage]", data?.appearance?.coverImage);
      formData.append(
        "appearance[icon]",
        data?.appearance?.icon || getRandomEmoji()
      );
      formData.append("appearance[theme][primaryColor]", theme);
      updateWorkspace(formData, workspaceId);
    }
  };

  const onClickAction = async (args: string, _id: string, source?: string) => {
    if (!_id) {
      console.error("Failed to get workspace ID", _id, args);
    }

    if (args === "Delete") {
      if (!source) {
        return;
      }
      await deleteWorkspace(_id, source);
      getAllWorkspace();
    } else if (args === "Update Details" || args === "Rename") {
      setEditOpenPopUp(true);
      const wd = nonSharedWorkspaces.find(
        (workspace: WorkspaceData) => workspace?._id === _id
      );
      if (!wd) {
        console.error("Workspace not found for ID:", _id);
        return;
      }

      setIntialDataOfWorkspace(wd ? wd : null);
    }
  };

  useEffect(() => {
    const currentTime = Date.now();
    const apiCallTime = new Date(api_call_timeStamp).getTime();
    const timeDiff = currentTime - apiCallTime;

    // Define the period to wait before making a new API call
    const periodToCallApi = 90000; // 90 seconds

    // Check if WorkspaceListError is an object with a message property
    const isErrorObject =
      typeof WorkspaceListError === "object" &&
      WorkspaceListError !== null &&
      "message" in WorkspaceListError;

    // console.log("Effect triggered:", {
    //   timeDiff,
    //   workspaces,
    //   WorkspaceListError,
    //   is_api_called,
    // });

    // Determine if an API call should be made
    const shouldCallApi = (() => {
      // Check if there are no workspaces and there is no error
      if (workspaces?.length === 0 && !isErrorObject) {
        return true;
      }

      // Check if the time since the last API call is greater than the defined period and no API call is in progress
      if (timeDiff > periodToCallApi && !is_api_called) {
        return true;
      }

      // If there is an error and enough time has passed since the last call, make another API call
      if (isErrorObject && timeDiff > periodToCallApi) {
        return true;
      }

      return false;
    })();

    if (shouldCallApi && !is_api_called) {
      // console.log("Calling API...");
      getAllWorkspace(); // Trigger the API call
      if (!WorkspaceSharedListError) {
        getAllSharedWorkspace();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    workspaces,
    api_call_timeStamp,
    WorkspaceListError,
    is_api_called,
    workspaceList,
  ]);

  const handleTabChange = (newTab: number) => {
    setTabActive(newTab);
  };

  const user = getUser();
  const sharedWorkspaces =
    workspacesShared &&
    workspacesShared?.filter(
      (card: WorkspaceData) =>
        card?.visibility === "team" || card?.visibility === "public"
      // &&
      //   card?.ownerId !== user?.data?._id
    );

  const nonSharedWorkspaces =
    workspaces &&
    workspaces?.filter(
      (card: WorkspaceData) =>
        (card?.visibility === "private" || card?.visibility === "public") &&
        card?.ownerId === user?.data?._id
    );

  const handleGetAlluser = async () => {
    await dispatch(getAllUsers(true));
  };

  useEffect(() => {
    handleGetAlluser();
  }, []);

  const [cmd_toggle, setCmd_toggle] = useState<boolean>(false);

  return (
    <Box>
      <Stack p="30px 25px 2px 25px">
        <MainTopWorkspace
          getAllWorkspace={getAllWorkspace}
          viewMode={viewMode}
          setViewMode={setViewMode}
          cmd_toggle={cmd_toggle}
          setCmd_toggle={setCmd_toggle}
          editOpenPopUp={editOpenPopUp}
          handleEditPopUp={handleEditPopUp}
          intialData={{
            mainDetails: {
              workspaceName: initialDataOfWorkspace?.name,
              workspaceDetails: initialDataOfWorkspace?.description?.content,
              defaultWorkspaceImage: true,
              defaultWorkspaceImageIndex: 0,
              uploadWorkspaceImage:
                initialDataOfWorkspace?.appearance?.coverImage,
            },
            connections: {
              connectionList: [],
            },
            shareWorkspace: {
              shareWorkspaceList: [],
            },
          }}
          initialDataOfWorkspaceId={initialDataOfWorkspace?._id}
          initialDataOfsource={initialDataOfWorkspace?.source}
          da__data={initialDataOfWorkspace}
          updateWorkspace={updateWorkspace}
          iconApp={initialDataOfWorkspace?.appearance.icon}
          theme={initialDataOfWorkspace?.appearance?.theme}
        />
        <SearchContainer />
      </Stack>
      {!cmd_toggle ? (
        <Box sx={BOX_STYLE}>
          {/* Sticky tabs for switching between project views */}
          <Box sx={{ ...STICKY_TAB_STYLE, padding: "10px 0px 0px 25px" }}>
            <Stack>
              <ActiveWorkspaceTab
                tabActive={tabActive}
                setTabActive={handleTabChange}
              />
            </Stack>
          </Box>

          <Stack
            mt="10px"
            direction={viewMode === "square" ? "row" : "column"}
            alignItems="center"
            justifyContent="flex-start"
            gap={1}
            flexWrap="wrap"
            sx={{ padding: "10px" }}
          >
            <>
              {workspaceLoader.firstTime &&
                workspaceLoader.regularLoader &&
                tabActive === 1 ? (
                Array.from({ length: 8 }, (_, index) => (
                  <Skeleton
                    key={index}
                    variant="rounded"
                    width={250}
                    height={225}
                    sx={{ bgcolor: "lightgray", opacity: 0.6 }}
                  />
                ))
              ) : (
                <>
                  {sharedWorkspaces?.length === 0 && tabActive === 1 ? (
                    <Stack
                      gap={2}
                      alignItems={"center"}
                      justifyContent={"center"}
                      direction={"column"}
                      width={'100%'}
                    >
                      <img
                        style={{ width: "450px", height: "400px" }}
                        src={no_workspace}
                        alt="No sharedWorkspaces available"
                      />
                      <Typography
                        children={
                          "You don’t have any shared workspaces at the moment. Consider joining or requesting access to shared spaces."
                        }
                        sx={{
                          font: "normal normal 500 17px/21px Source Serif Pro",
                          textAlign: "center",
                          color: "text.secondary",
                        }}
                      />
                    </Stack>
                  ) : (
                    sharedWorkspaces?.map((card: any, index: number) => (
                      <Grow
                        key={index}
                        in={tabActive === 1} // Only animate if active tab is for shared projects
                        {...(tabActive === 1 ? { timeout: 500 } : {})}
                        mountOnEnter
                        unmountOnExit
                        style={{ transformOrigin: "top" }}
                      >
                        <div
                          style={{
                            width: viewMode !== "square" ? "100%" : "auto",
                          }}
                        >
                          <SharedProjectCard
                            title={card?.name}
                            dateCreated={card?.createdAt}
                            imageUrl={card?.appearance?.coverImage as string}
                            viewMode={viewMode}
                            handleNavigate={(id: string) => {
                              handleNavigate(id, card?.source);
                            }}
                            _id={card?._id}
                            onClickAction={(arg: any, id: string) => {
                              onClickAction(arg, id, card?.source);
                            }}
                            handleWorkspaceColorThemes={(theme: string) => {
                              handleWorkspaceColorThemes(
                                card,
                                card?._id,
                                theme
                              );
                            }}
                            cardColor={card?.appearance?.theme?.primaryColor}
                          />
                        </div>
                      </Grow>
                    ))
                  )}
                </>
              )}
            </>

            {/* Non-Shared Workspaces */}
            {workspaceLoader.firstTime &&
              workspaceLoader.regularLoader &&
              tabActive === 0 ? (
              Array.from({ length: 8 }, (_, index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  width={250}
                  height={225}
                  sx={{ bgcolor: "lightgray", opacity: 0.6 }}
                />
              ))
            ) : (
              <>
                {nonSharedWorkspaces?.length === 0 && tabActive === 0 ? (
                  <Stack
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                    direction={"column"}
                    width={'100%'}
                  >
                    <img
                      style={{ width: "450px", height: "400px" }}
                      src={no_workspace}
                      alt="No Workspaces available"
                    />
                    <Typography
                      children={
                        "No workspace found. Please create a new one to get started."
                      }
                      sx={{
                        font: "normal normal 500 17px/21px Source Serif Pro",
                        textAlign: "center",
                        color: "text.secondary",
                      }}
                    />
                  </Stack>
                ) : (
                  nonSharedWorkspaces?.map((card: any, index: number) => (
                    <Grow
                      key={index}
                      in={tabActive === 0} // Only animate if active tab is for non-shared projects
                      {...(tabActive === 0 ? { timeout: 500 } : {})}
                      mountOnEnter
                      unmountOnExit
                      style={{ transformOrigin: "top" }}
                    >
                      <div
                        style={{
                          width: viewMode !== "square" ? "100%" : "auto",
                        }}
                      >
                        <NonSharedProjectCard
                          title={card?.name}
                          dateCreated={card?.createdAt}
                          imageUrl={card?.appearance?.coverImage as string}
                          viewMode={viewMode}
                          handleNavigate={(id: string) => {
                            handleNavigate(id, card?.source);
                          }}
                          _id={card?._id}
                          onClickAction={(arg: any, id: string) => {
                            onClickAction(arg, id, card?.source);
                          }}
                          handleWorkspaceColorThemes={(theme: string) => {
                            handleWorkspaceColorThemes(card, card?._id, theme);
                          }}
                          cardColor={
                            card?.appearance?.theme?.primaryColor || "black"
                          }
                        />
                      </div>
                    </Grow>
                  ))
                )}
              </>
            )}
          </Stack>
        </Box>
      ) : (
        <CmdW />
      )}
    </Box>
  );
};
/**
 * Props interface for the card components.
 */
interface CardProps {
  title: string; // Title of the project card
  dateCreated: string; // Date when the project was created
  imageUrl: string | File; // URL of the project's image
  viewMode: string; // View mode of the project
  handleNavigate?: (arg: string) => void; //
  _id: string;
  onClickAction: (arg: string, _id: string) => void;
  handleWorkspaceColorThemes?: (theme: string) => void;
  cardColor: string;
}

/**
 * Component for displaying a shared project card.
 * @param title - Title of the project.
 * @param dateCreated - Date when the project was created.
 * @param imageUrl - URL of the project's image.
 * @param viewMode - View mode of the project
 */
const SharedProjectCard: React.FC<CardProps> = ({
  title,
  dateCreated,
  imageUrl,
  viewMode,
  handleNavigate,
  _id,
  onClickAction,
  handleWorkspaceColorThemes,
  cardColor,
}) => (
  <>
    {viewMode === "square" ? (
      <LargeCardAndImage
        title={title}
        dateCreated={dateCreated}
        imageUrl={imageUrl}
        handleNavigate={handleNavigate}
        _id={_id}
        onClickAction={onClickAction}
        onThemeUpdate={(theme: string) => {
          // Same logic for this component, call the function
          handleWorkspaceColorThemes && handleWorkspaceColorThemes(theme);
        }}
        cardColor={cardColor}
      />
    ) : (
      <LargeStackedAndImage
        projectName={title}
        dateCreated={dateCreated}
        imageUrl={imageUrl}
        handleNavigate={handleNavigate}
        _id={_id}
        onClickAction={onClickAction}
        onThemeUpdate={(theme: string) => {
          // Same logic for this component, call the function
          handleWorkspaceColorThemes && handleWorkspaceColorThemes(theme);
        }}
        cardColor={cardColor}
      />
    )}
  </>
);

/**
 * Component for displaying a non-shared project card.
 * @param title - Title of the project.
 * @param dateCreated - Date when the project was created.
 * @param imageUrl - URL of the project's image.
 * @param viewMode - View mode of the project
 */
const NonSharedProjectCard: React.FC<CardProps> = ({
  title,
  dateCreated,
  imageUrl,
  viewMode,
  handleNavigate,
  _id,
  onClickAction,
  handleWorkspaceColorThemes,
  cardColor,
}) => (
  <>
    {viewMode === "square" ? (
      <LargeCardAndImage
        title={title}
        dateCreated={dateCreated}
        imageUrl={imageUrl}
        handleNavigate={handleNavigate}
        _id={_id}
        onClickAction={onClickAction}
        onThemeUpdate={(theme: string) => {
          // Same logic for this component, call the function
          handleWorkspaceColorThemes && handleWorkspaceColorThemes(theme);
        }}
        cardColor={cardColor}
      />
    ) : (
      <LargeStackedAndImage
        projectName={title}
        dateCreated={dateCreated}
        imageUrl={imageUrl}
        handleNavigate={handleNavigate}
        _id={_id}
        onClickAction={onClickAction}
        onThemeUpdate={(theme: string) => {
          // Same logic for this component, call the function
          handleWorkspaceColorThemes && handleWorkspaceColorThemes(theme);
        }}
        cardColor={cardColor}
      />
    )}
  </>
);

export default Workspace;
