import React from "react";
import {
  TextNormalDropdown,
  TextNormalDropdownOptionsType,
} from "../../../../common";

const DUMMY_frequencyOptions: TextNormalDropdownOptionsType[] = [
  { _id: "1", title: "Weekly", value: "weekly" },
  { _id: "2", title: "Monthly", value: "monthly" },
  { _id: "3", title: "Yearly", value: "yearly" },
  { _id: "4", title: "Quarterly", value: "quarterly" },
];

interface FrequencyDropdownProps {}

const FrequencyDropdown: React.FC<FrequencyDropdownProps> = () => {
  return (
    <TextNormalDropdown
      placeholder="Select Frequency..."
      // initialValue={DUMMY_frequencyOptions[1]}
      options={DUMMY_frequencyOptions}
      onSelect={(value) => console.log(value)}
    />
  );
};

export default FrequencyDropdown;
