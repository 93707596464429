import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { AvatarNormal, CheckboxMedium } from "../../../../../../common";
import { AllDirArrowCircleIcon } from "../../../../../../../images";
import styles from "./EmpPreviewTableRow.module.css";
import {
  ConflictingInfo,
  ConflictingInfoProps,
} from "../../../ConflictingInfo";

interface EmpPreviewTableRowProps {
  onCheckboxClick: () => void;
  isSelected?: boolean;
  userName: string;
  userImgSrc?: string;
  avatarColor?: string;
  jobTitle: string;
  countryFlagSrc: string;
  countryName: string;
  currenTime: string;
  onButtonClick: () => void;
  isConflict?: ConflictingInfoProps;
  isLastChild?: boolean;
}

const EmpPreviewTableRow: React.FC<EmpPreviewTableRowProps> = ({
  countryFlagSrc,
  countryName,
  currenTime,
  jobTitle,
  onButtonClick,
  onCheckboxClick,
  userName,
  isSelected,
  avatarColor,
  userImgSrc,
  isConflict,
  isLastChild = false,
}) => {
  return (
    <Stack className={styles["emp-preview-table-row"]}>
      <CheckboxMedium checked={isSelected} onClick={() => onCheckboxClick()} />

      <Stack
        className={styles["emp-preview-table-row__content"]}
        sx={{
          borderBottom: isLastChild ? "1px solid #e9edf2" : "0px solid #e9edf2",
        }}
      >
        <Stack className={styles["emp-preview-table-row__user-info"]}>
          <AvatarNormal
            avatarColor={avatarColor}
            username={userName}
            imgSrc={userImgSrc}
            size={20}
            sx={{ borderRadius: "5px" }}
          />
          <Typography className={styles["emp-preview-table-row__user-name"]}>
            {userName}
          </Typography>
        </Stack>
        <Typography className={styles["emp-preview-table-row__job-title"]}>
          {jobTitle}
        </Typography>
        <Stack className={styles["emp-preview-table-row__location"]}>
          <Box
            component={"img"}
            src={countryFlagSrc}
            alt="flag"
            className={styles["emp-preview-table-row__flag"]}
          />
          <Typography className={styles["emp-preview-table-row__country"]}>
            {countryName}
          </Typography>
          <Typography className={styles["emp-preview-table-row__time"]}>
            {currenTime}
          </Typography>
        </Stack>
        <IconButton onClick={() => onButtonClick()}>
          <AllDirArrowCircleIcon direction="right" />
        </IconButton>
      </Stack>

      {/* -- if any conflict -- */}
      <Box component={"span"} sx={{ ml: "-5px", width: "18px" }}>
        {isConflict && <ConflictingInfo {...isConflict} />}
      </Box>
    </Stack>
  );
};

export default EmpPreviewTableRow;
