import React from "react";
import { Stack } from "@mui/material";
import { EventIconButton } from "../../../../../../common";
import { DoneIcon } from "../../../../../../../images";
import { SnoozeButton } from "../../../../../Message/components";

interface ButtonGroupOneViewProps {
  onDoneClick?: () => void; 
  onSnoozeSelect?: (value: any) => void;
}

// Component Definition
const ButtonGroupOneView: React.FC<ButtonGroupOneViewProps> = ({
  onDoneClick,
  onSnoozeSelect,
}) => {
  
  const handleDoneClick = () => {
    if (onDoneClick) {
      onDoneClick();
    } 
  };

  const handleSnoozeSelect = (value: any) => {
    if (onSnoozeSelect) {
      onSnoozeSelect(value);
    }
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <EventIconButton
        buttonTitle="Done"
        icon={DoneIcon}
        iconColor="#008D4C"
        onClick={handleDoneClick} 
      />
      <SnoozeButton onSelect={handleSnoozeSelect} /> 
    </Stack>
  );
};

export default ButtonGroupOneView;
