import React, { useState } from "react";
import { IconButton, RadioGroup, Stack, Typography } from "@mui/material";
import { TravelIcon, XCircleIcon } from "../../../../../images";
import {
  CustomRadioFormControlLabel,
  DatePickerMini,
  EditableNumberInput,
  EditableTimeTextField,
  TextLinkButton,
} from "../../../../common";
import styles from "./BreaksDetailsPopup.module.css";

interface TimeEntry {
  id: number;
}

interface BreaksDetailsPopupProps {}

const BreaksDetailsPopup: React.FC<BreaksDetailsPopupProps> = () => {
  const [breakType, setBreakType] = useState("paid");
  const [timeEntries, setTimeEntries] = useState<TimeEntry[]>([{ id: 1 }]);

  const handleAddTimeEntry = () => {
    setTimeEntries((prev) => [
      ...prev,
      { id: Math.max(...prev.map((entry) => entry.id)) + 1 },
    ]);
  };

  const handleRemoveTimeEntry = (id: number) => {
    if (timeEntries.length > 1) {
      setTimeEntries((prev) => prev.filter((entry) => entry.id !== id));
    }
  };

  return (
    <Stack className={styles.breaksDetailsPopup}>
      {/* ----- header icon and card title ------ */}
      <Stack className={styles.breaksDetailsPopup__header}>
        <TravelIcon w={15} />
        <Typography className={styles.breaksDetailsPopup__title}>
          Breaks Details
        </Typography>
      </Stack>

      {/* --- total Breaks --- */}
      <Stack className={styles.breaksDetailsPopup__totalBreaks}>
        <Typography className={styles.breaksDetailsPopup__title}>
          Total Breaks
        </Typography>
        <EditableNumberInput />
      </Stack>

      {/* --- total Breaks --- */}
      <Stack className={styles.breaksDetailsPopup__totalBreaks}>
        <Typography className={styles.breaksDetailsPopup__title}>
          Total Breaks
        </Typography>
        <EditableTimeTextField
          initialValue=""
          onSave={() => null}
          variant="hrs"
        />
        <EditableTimeTextField
          initialValue=""
          onSave={() => null}
          variant="mins"
        />
      </Stack>

      {/* Radio Group - Breaks area*/}
      <RadioGroup
        aria-labelledby="calendar-setting-radio-buttons-group"
        name="calendar-setting-radio-buttons-group"
        value={breakType}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setBreakType(event.target.value)
        }
        className={styles.breaksDetailsPopup__radioGroup}
      >
        <CustomRadioFormControlLabel value="paid" label="Paid Breaks" />
        <CustomRadioFormControlLabel value="unpaid" label="Unpaid Breaks" />
      </RadioGroup>

      {/* ---  Breaks Times --- */}
      <Typography className={styles.breaksDetailsPopup__breakTimeLabel}>
        Break Time(s)
      </Typography>

      {/* --- dynamic time area */}
      {timeEntries.map((entry) => (
        <Stack key={entry.id} className={styles.breaksDetailsPopup__timeArea}>
          <Stack className={styles.breaksDetailsPopup__timeSection}>
            <Typography className={styles.breaksDetailsPopup__timeLabel}>
              Start:
            </Typography>
            <DatePickerMini hideIcon sx={{ border: 0, width: "62px" }} />
          </Stack>
          <Stack className={styles.breaksDetailsPopup__timeSection}>
            <Typography className={styles.breaksDetailsPopup__timeLabel}>
              End:
            </Typography>
            <DatePickerMini hideIcon sx={{ border: 0, width: "62px" }} />
          </Stack>

          {timeEntries.length > 1 && (
            <IconButton
              sx={{ p: 0 }}
              onClick={() => handleRemoveTimeEntry(entry.id)}
            >
              <XCircleIcon color="black" w={15} />
            </IconButton>
          )}
        </Stack>
      ))}

      {/* dynamic time area */}
      <Stack className={styles.breaksDetailsPopup__addButton}>
        <TextLinkButton
          buttonLabel="Add another"
          onClick={handleAddTimeEntry}
        />
      </Stack>
    </Stack>
  );
};

export default BreaksDetailsPopup;
