import { Divider, IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";
import { ZoomButton } from "../ZoomButton";
import {
  FullViewIcon,
  HighlightIcon,
  SearchIcon,
  SidebarIcon,
} from "../../../images";
import { MarkerButton } from "../MarkerButton";
import { AddComment } from "../AddComment";
import { PageControllerButtons } from "../PageControllerButtons";

interface PopupupCloudStorageDocumentProps {
  onAction: (actionName: string, actionValue?: any) => void;
  numPages?: number;
}

const PopupupCloudStorageDocument: React.FC<
  PopupupCloudStorageDocumentProps
> = ({ onAction, numPages }) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        padding: "10px 25px",
        boxSizing: "border-box",
        gap: "25px",
        bgcolor: "white",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
        height: "45px",
      }}
    >
      <Tooltip title={"Sidebar"} placement="top" arrow>
        <IconButton
          onClick={() => onAction && onAction("sidebar")}
          sx={{ p: 0, borderRadius: 0 }}
          disableRipple
        >
          <SidebarIcon w={25} />
        </IconButton>
      </Tooltip>

      <Tooltip title={"Search"} placement="top" arrow>
        <IconButton
          onClick={() => onAction("search")}
          sx={{ p: 0, borderRadius: 0 }}
          disableRipple
        >
          <SearchIcon w={25} />
        </IconButton>
      </Tooltip>

      <Divider orientation="vertical" sx={{ borderColor: "#E9EDF2" }} />
      <PageControllerButtons
        totalPage={numPages ? numPages : 40}
        onSelect={(value) => onAction("pageChange", value)}
      />
      <Divider orientation="vertical" sx={{ borderColor: "#E9EDF2" }} />

      <ZoomButton iconSize={25} onChange={(value) => {
        // console.log(value)
        }} />

      <Tooltip title={"Full Screen"} placement="top" arrow>
        <IconButton
          onClick={() => onAction("fullScreen")}
          sx={{ p: 0, borderRadius: 0 }}
          disableRipple
        >
          <FullViewIcon w={25} />
        </IconButton>
      </Tooltip>

      <MarkerButton direction="horizontal" />

      <Tooltip title={"Highlight"} placement="top" arrow>
        <IconButton
          onClick={() => onAction("highlight")}
          sx={{ p: 0, borderRadius: 0 }}
          disableRipple
        >
          <HighlightIcon w={25} />
        </IconButton>
      </Tooltip>

      <Divider orientation="vertical" sx={{ borderColor: "#E9EDF2" }} />

      <AddComment iconSize={25} />
    </Stack>
  );
};

export default PopupupCloudStorageDocument;
