import { createApiAction } from "../constantApi_config";
import {
    /* Upload file to cloud */
    uploadCloudFile__FailureAction,
    uploadCloudFile__RequestAction,
    uploadCloudFile__SuccessAction,

    /* Get All Files */
    getAllFiles__FailureAction,
    getAllFiles__RequestAction,
    getAllFiles__SuccessAction,

    /* Get One File */
    getOneFile__FailureAction,
    getOneFile__RequestAction,
    getOneFile__SuccessAction,

    /* Download File */
    downloadFile__FailureAction,
    downloadFile__RequestAction,
    downloadFile__SuccessAction,

    /* Share File With Users */
    shareFileWithUsers__FailureAction,
    shareFileWithUsers__RequestAction,
    shareFileWithUsers__SuccessAction,

    /* Share File With Email */
    shareFileWithEmail__FailureAction,
    shareFileWithEmail__RequestAction,
    shareFileWithEmail__SuccessAction,

    /* Delete File */
    deleteFile__FailureAction,
    deleteFile__RequestAction,
    deleteFile__SuccessAction,

    /* Update File */
    updateFile__FailureAction,
    updateFile__RequestAction,
    updateFile__SuccessAction,

    /* Upload New Version */
    uploadNewVersion__FailureAction,
    uploadNewVersion__RequestAction,
    uploadNewVersion__SuccessAction,

    /* Create Folder */
    createFolder__FailureAction,
    createFolder__RequestAction,
    createFolder__SuccessAction,

    /* Rename Folder */
    renameFolder__FailureAction,
    renameFolder__RequestAction,
    renameFolder__SuccessAction,

    /* Get All Folders */
    getAllFolders__FailureAction,
    getAllFolders__RequestAction,
    getAllFolders__SuccessAction,

    /* Get ALl members */
    getAllMemberCS__RequestAction,
    getAllMemberCS__SuccessAction,
    getAllMemberCS__FailureAction,

    /* Create Link */
    createLink__RequestAction,
    createLink__SuccessAction,
    createLink__FailureAction,

    /* Get Link */
    getLink__RequestAction,
    getLink__SuccessAction,
    getLink__FailureAction,

    /* Delete Link */
    deleteLink__RequestAction,
    deleteLink__SuccessAction,
    deleteLink__FailureAction,

    /* Get All Link */
    getAllLink__RequestAction,
    getAllLink__SuccessAction,
    getAllLink__FailureAction,

    /* Add View */
    addView__RequestAction,
    addView__SuccessAction,
    addView__FailureAction,

    /* Add Time */
    addTime__RequestAction,
    addTime__SuccessAction,
    addTime__FailureAction,

    /* Toggle Download */
    toggleDownload__RequestAction,
    toggleDownload__SuccessAction,
    toggleDownload__FailureAction,

    /* Toggle Ask Details */
    toggleAskDetails__RequestAction,
    toggleAskDetails__SuccessAction,
    toggleAskDetails__FailureAction,

    /* Update Expiration */
    updateExpiration__RequestAction,
    updateExpiration__SuccessAction,
    updateExpiration__FailureAction,

    /* Update Link Password */
    updateLinkPassword__RequestAction,
    updateLinkPassword__SuccessAction,
    updateLinkPassword__FailureAction,

    /* Toggle Watermark */
    toggleWatermark__RequestAction,
    toggleWatermark__SuccessAction,
    toggleWatermark__FailureAction,
    /* --- BOX --- */

    /* --- BOX --- */

    /* File Management Routes */
    uploadFile__BOX__RequestAction,
    uploadFile__BOX__SuccessAction,
    uploadFile__BOX__FailureAction,
    downloadFile__BOX__RequestAction,
    downloadFile__BOX__SuccessAction,
    downloadFile__BOX__FailureAction,
    lockFile__BOX__RequestAction,
    lockFile__BOX__SuccessAction,
    lockFile__BOX__FailureAction,
    unlockFile__BOX__RequestAction,
    unlockFile__BOX__SuccessAction,
    unlockFile__BOX__FailureAction,
    createSharedLink__BOX__RequestAction,
    createSharedLink__BOX__SuccessAction,
    createSharedLink__BOX__FailureAction,
    disableSharedLink__BOX__RequestAction,
    disableSharedLink__BOX__SuccessAction,
    disableSharedLink__BOX__FailureAction,
    addComment__BOX__RequestAction,
    addComment__BOX__SuccessAction,
    addComment__BOX__FailureAction,
    retrieveComments__BOX__RequestAction,
    retrieveComments__BOX__SuccessAction,
    retrieveComments__BOX__FailureAction,

    /* Folder Management */
    createFolder__BOX__RequestAction,
    createFolder__BOX__SuccessAction,
    createFolder__BOX__FailureAction,
    deleteFolder__BOX__RequestAction,
    deleteFolder__BOX__SuccessAction,
    deleteFolder__BOX__FailureAction,
    searchFilesAndFolders__BOX__RequestAction,
    searchFilesAndFolders__BOX__SuccessAction,
    searchFilesAndFolders__BOX__FailureAction,
    getAllFiles__Box__SuccessAction,
    getAllFolders__Box__SuccessAction,

    /* And so on... */
} from "./CloudStorageSlice";

/* Upload File */
export const uploadCloudFile__api = (files: FormData) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: files,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        url: `/cloud/upload-file`,
        onStart: uploadCloudFile__RequestAction.type,
        onSuccess: uploadCloudFile__SuccessAction.type,
        onError: uploadCloudFile__FailureAction.type,
    });

/* Get All Files */
export const getAllFiles__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/cloud/get-all-files`,
        onStart: getAllFiles__RequestAction.type,
        onSuccess: getAllFiles__SuccessAction.type,
        onError: getAllFiles__FailureAction.type,
    });

/* Get One File */
export const getOneFile__api = (fileId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/cloud/get-one-file/${fileId}`,
        onStart: getOneFile__RequestAction.type,
        onSuccess: getOneFile__SuccessAction.type,
        onError: getOneFile__FailureAction.type,
    });

/* Download File */
export const downloadFile__api = (fileId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/cloud/download-file/${fileId}`,
        onStart: downloadFile__RequestAction.type,
        onSuccess: downloadFile__SuccessAction.type,
        onError: downloadFile__FailureAction.type,
    });

/* Share File With Users */
export const shareFileWithUsers__api = (
    fileId: string,
    payload: { memberId: string; memberRole: string }
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/cloud/share-file/${fileId}`,
        onStart: shareFileWithUsers__RequestAction.type,
        onSuccess: shareFileWithUsers__SuccessAction.type,
        onError: shareFileWithUsers__FailureAction.type,
    });

/* Share File With Email */
export const shareFileWithEmail__api = (payload: {
    fileId: string;
    recipientEmail: string;
}) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/cloud/share-file-with-email`,
        onStart: shareFileWithEmail__RequestAction.type,
        onSuccess: shareFileWithEmail__SuccessAction.type,
        onError: shareFileWithEmail__FailureAction.type,
    });

// Delete File API
export const deleteFile__api = (fileId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/cloud/delete-file/${fileId}`,
        onStart: deleteFile__RequestAction.type,
        onSuccess: deleteFile__SuccessAction.type,
        onError: deleteFile__FailureAction.type,
    });

// Update File API
export const updateFile__api = (fileId: string, payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/cloud/update-file/${fileId}`,
        data: payload,
        onStart: updateFile__RequestAction.type,
        onSuccess: updateFile__SuccessAction.type,
        onError: updateFile__FailureAction.type,
    });

// Upload New Version API
export const uploadNewVersion__api = (fileId: string, files: FormData) => {
    return createApiAction({
        axiosRequired: true,
        method: "PUT",
        headers: {
            "Content-Type": "multipart/form-data",
        },
        url: `/cloud/upload-new-version/${fileId}`,
        data: files,
        onStart: uploadNewVersion__RequestAction.type,
        onSuccess: uploadNewVersion__SuccessAction.type,
        onError: uploadNewVersion__FailureAction.type,
    });
};

// Create Folder API
export const createFolder__api = (payload: { folderName: string }) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: "/cloud/create-folder",
        data: payload,
        onStart: createFolder__RequestAction.type,
        onSuccess: createFolder__SuccessAction.type,
        onError: createFolder__FailureAction.type,
    });

// Rename Folder API
export const renameFolder__api = (
    folderId: string,
    payload: { folderName: string }
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/cloud/rename-folder/${folderId}`,
        data: payload,
        onStart: renameFolder__RequestAction.type,
        onSuccess: renameFolder__SuccessAction.type,
        onError: renameFolder__FailureAction.type,
    });

// Get All Folders API
export const getAllFolders__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: "/cloud/get-all-folders",
        onStart: getAllFolders__RequestAction.type,
        onSuccess: getAllFolders__SuccessAction.type,
        onError: getAllFolders__FailureAction.type,
    });

// Get All <Members> API
export const getAllMembersCS__api = (fileId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/cloud/member/${fileId}`,
        onStart: getAllMemberCS__RequestAction.type,
        onSuccess: getAllMemberCS__SuccessAction.type,
        onError: getAllMemberCS__FailureAction.type,
    });

// Create Link API
export const createLink__api = (
    fileId: string,
    payload: {
        name: string;
        enableWDownload: boolean;
        askDetails: boolean;
        expirationDate: string | null;
        password: string;
        isWatermark: boolean;
    }
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/cloud/create-link`,
        data: {
            fileId,
            ...payload,
        },
        onStart: createLink__RequestAction.type,
        onSuccess: createLink__SuccessAction.type,
        onError: createLink__FailureAction.type,
    });

// Get Link API
export const getLink__api = (link: string) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/cloud/get-link`,
        data: { link },
        onStart: getLink__RequestAction.type,
        onSuccess: getLink__SuccessAction.type,
        onError: getLink__FailureAction.type,
    });

// Delete Link API
export const deleteLink__api = (linkId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/cloud/delete-link`,
        data: { linkId: linkId },
        onStart: deleteLink__RequestAction.type,
        onSuccess: deleteLink__SuccessAction.type,
        onError: deleteLink__FailureAction.type,
    });

// Get All Links API
export const getAllLinks__api = (fileId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/cloud/get-all-links/${fileId}`,
        onStart: getAllLink__RequestAction.type,
        onSuccess: getAllLink__SuccessAction.type,
        onError: getAllLink__FailureAction.type,
    });

// Add View API
export const addView__api = (linkId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/cloud/add-view`,
        data: { linkId },
        onStart: addView__RequestAction.type,
        onSuccess: addView__SuccessAction.type,
        onError: addView__FailureAction.type,
    });

// Add Time API
export const addTime__api = (linkId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: `/cloud/add-time`,
        data: { linkId },
        onStart: addTime__RequestAction.type,
        onSuccess: addTime__SuccessAction.type,
        onError: addTime__FailureAction.type,
    });

// Toggle Download API
export const toggleDownload__api = (linkId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/cloud/toggle-download`,
        data: { linkId: linkId },
        onStart: toggleDownload__RequestAction.type,
        onSuccess: toggleDownload__SuccessAction.type,
        onError: toggleDownload__FailureAction.type,
    });

// Toggle Ask Details API
export const toggleAskDetails__api = (linkId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/cloud/toggle-ask-details`,
        data: { linkId: linkId },
        onStart: toggleAskDetails__RequestAction.type,
        onSuccess: toggleAskDetails__SuccessAction.type,
        onError: toggleAskDetails__FailureAction.type,
    });

// Update Expiration API
export const updateExpiration__api = (linkId: string, expirationDate: string) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/cloud/update-expiration`,
        data: { linkId: linkId, expirationDate: expirationDate },
        onStart: updateExpiration__RequestAction.type,
        onSuccess: updateExpiration__SuccessAction.type,
        onError: updateExpiration__FailureAction.type,
    });

// Update Link Password API
export const updateLinkPassword__api = (linkId: string, password: string) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/cloud/update-password`,
        data: { linkId: linkId, password: password },
        onStart: updateLinkPassword__RequestAction.type,
        onSuccess: updateLinkPassword__SuccessAction.type,
        onError: updateLinkPassword__FailureAction.type,
    });

// Toggle Watermark API
export const toggleWatermark__api = (linkId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/cloud/toggle-watermark`,
        data: { linkId: linkId },
        onStart: toggleWatermark__RequestAction.type,
        onSuccess: toggleWatermark__SuccessAction.type,
        onError: toggleWatermark__FailureAction.type,
    });

/* BOX */

// Upload File API
export const uploadFile__BOX__api = (payload: FormData) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: "/box/files/content",
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onStart: uploadFile__BOX__RequestAction.type,
        onSuccess: uploadFile__BOX__SuccessAction.type,
        onError: uploadFile__BOX__FailureAction.type,
    });

export const box__oauth__handle = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/box/oauth?code=${payload}`,
        onStart: `uploadFile__BOX__RequestAction.type`,
        onSuccess: `uploadFile__BOX__SuccessAction.type`,
        onError: `uploadFile__BOX__FailureAction.type`,
    });
export const box__loginUrl__handle = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/box/getBoxLoginUrl`,
        onStart: `uploadFile__BOX__RequestAction.type`,
        onSuccess: `uploadFile__BOX__SuccessAction.type`,
        onError: `uploadFile__BOX__FailureAction.type`,
    });

export const getAllFile__BOX__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/box/files/boxfiles`,
        onStart: `getAllFiles__RequestAction.type`,
        onSuccess: getAllFiles__Box__SuccessAction.type,
        onError: `getAllFiles__FailureAction.type`,
    });

// Get All Folders API
export const getAllFolders__BOX__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: "/box/folders/boxfolders",
        onStart: `getAllFolders__RequestAction.type`,
        onSuccess: getAllFolders__Box__SuccessAction.type,
        onError: `getAllFolders__FailureAction.type`,
    });

// Delete Box File API
export const deleteFile__Box__api = (fileId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/box/files/${fileId}/delete`,
        onStart: deleteFile__RequestAction.type,
        onSuccess: deleteFile__SuccessAction.type,
        onError: deleteFile__FailureAction.type,
    });

// Download File API
export const downloadFile__BOX__api = (fileId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/box/files/${fileId}/content`,
        onStart: downloadFile__BOX__RequestAction.type,
        onSuccess: downloadFile__BOX__SuccessAction.type,
        onError: downloadFile__BOX__FailureAction.type,
    });

// get one File API
export const getOneFile__BOX__api = (fileId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/box/files/${fileId}/get-content`,
        onStart: getOneFile__RequestAction.type,
        onSuccess: getOneFile__SuccessAction.type,
        onError: getOneFile__FailureAction.type,
    });

// Lock File API
export const lockFile__BOX__api = (fileId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/box/files/${fileId}`,
        onStart: lockFile__BOX__RequestAction.type,
        onSuccess: lockFile__BOX__SuccessAction.type,
        onError: lockFile__BOX__FailureAction.type,
    });

// Unlock File API
export const unlockFile__BOX__api = (fileId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/box/files/${fileId}/unlock`,
        onStart: unlockFile__BOX__RequestAction.type,
        onSuccess: unlockFile__BOX__SuccessAction.type,
        onError: unlockFile__BOX__FailureAction.type,
    });

// Create Shared Link API
export const createSharedLink__BOX__api = (fileId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/box/files/${fileId}/shared_link`,
        onStart: createSharedLink__BOX__RequestAction.type,
        onSuccess: createSharedLink__BOX__SuccessAction.type,
        onError: createSharedLink__BOX__FailureAction.type,
    });

// Disable Shared Link API
export const disableSharedLink__BOX__api = (fileId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        url: `/box/files/${fileId}/shared_link/disable`,
        onStart: disableSharedLink__BOX__RequestAction.type,
        onSuccess: disableSharedLink__BOX__SuccessAction.type,
        onError: disableSharedLink__BOX__FailureAction.type,
    });

// Add Comment API
export const addComment__BOX__api = (payload: {
    fileId: string;
    comment: string;
}) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: "/comments",
        data: payload,
        onStart: addComment__BOX__RequestAction.type,
        onSuccess: addComment__BOX__SuccessAction.type,
        onError: addComment__BOX__FailureAction.type,
    });

// Retrieve Comments API
export const retrieveComments__BOX__api = (fileId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/box/files/${fileId}/comments`,
        onStart: retrieveComments__BOX__RequestAction.type,
        onSuccess: retrieveComments__BOX__SuccessAction.type,
        onError: retrieveComments__BOX__FailureAction.type,
    });

// Create Folder API
export const createFolder__BOX__api = (payload: { folderName: string }) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        url: "/box/folders",
        data: payload,
        onStart: createFolder__BOX__RequestAction.type,
        onSuccess: createFolder__BOX__SuccessAction.type,
        onError: createFolder__BOX__FailureAction.type,
    });

// Delete Folder API
export const deleteFolder__BOX__api = (folderId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/folders/${folderId}`,
        onStart: deleteFolder__BOX__RequestAction.type,
        onSuccess: deleteFolder__BOX__SuccessAction.type,
        onError: deleteFolder__BOX__FailureAction.type,
    });

// Search Files and Folders API
export const searchFilesAndFolders__BOX__api = (query: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/search?q=${query}`,
        onStart: searchFilesAndFolders__BOX__RequestAction.type,
        onSuccess: searchFilesAndFolders__BOX__SuccessAction.type,
        onError: searchFilesAndFolders__BOX__FailureAction.type,
    });


