type MenuList = {
  divider?: false;
  value: string;
  textColor?: string;
  LANG:string;
};

type MenuDivider = {
  divider: true;
  value?: never;
  textColor?: never;
};

export type MessageDetailsMenusListInterface = MenuList | MenuDivider;

// Example usage:
export const messageDetailsMenusChannelList: MessageDetailsMenusListInterface[] =
  [
    { value: "View Channel Details",LANG:"MESSAGE.VIEW_CHANNEL_DETAILS" },
    { value: "Copy link",LANG:"MESSAGE.COPY_LINK" },
    { divider: true },
    { value: "Mute Channel",LANG:"MESSAGE.MUTE_CHANNEL" },
    // { value: "Notification Settings" ,LANG:"MESSAGE.NOTIFICATION_SETTINGS" },
    { value: "Pin Channel",LANG:"MESSAGE.PIN_CHANNEL" },
    { divider: true },
    { value: "Leave Channel",LANG:"MESSAGE.LEAVE_CHANNEL", textColor: "#FF0000" },
  ];

// GROUP
export const messageDetailsMenusChatListForPinChannels: MessageDetailsMenusListInterface[] = [
    { value: "View Channel Details", LANG: "MESSAGE.VIEW_CHANNEL_DETAILS" },
    { value: "Copy link", LANG: "MESSAGE.COPY_LINK" },
    { divider: true },
    { value: "Mute Channel", LANG: "MESSAGE.MUTE_CHANNEL" },
    { value: "Hide Channel", LANG: "MESSAGE.HIDE_CHANNEL", textColor: "#9747FF" },
    { value: "Un-Pin Channel", LANG: "MESSAGE.UN_PIN_CHANNEL" },
    { divider: true },
    { value: "Leave Channel", LANG: "MESSAGE.LEAVE_CHANNEL", textColor: "#FF0000" },
];

// GROUP
export const messageDetailsMenusChatListForWithOutLeave: MessageDetailsMenusListInterface[] = [
    { value: "View Channel Details", LANG: "MESSAGE.VIEW_CHANNEL_DETAILS" },
    { value: "Copy link", LANG: "MESSAGE.COPY_LINK" },
    { divider: true },
    { value: "Mute Channel", LANG: "MESSAGE.MUTE_CHANNEL" },
    { value: "Hide Channel", LANG: "MESSAGE.HIDE_CHANNEL", textColor: "#9747FF" },
    { value: "Un-Pin Channel", LANG: "MESSAGE.UN_PIN_CHANNEL" },
    { divider: true },
];

// PERSONAL
export const messageDetailsMenusChatList: MessageDetailsMenusListInterface[] = [
    { value: "Copy link", LANG: "MESSAGE.COPY_LINK" },
    { divider: true },
    { value: "Mute Chat", LANG: "MESSAGE.MUTE_CHAT" },
    { value: "Hide Chat", LANG: "MESSAGE.HIDE_CHAT", textColor: "#9747FF" },
    { value: "Pin Chat", LANG: "MESSAGE.PIN_CHAT" },
    { divider: true },
];

// PERSONAL
export const messageDetailsMenusChatListForPinChats: MessageDetailsMenusListInterface[] = [
    { value: "Copy link", LANG: "MESSAGE.COPY_LINK" },
    { divider: true },
    { value: "Mute Chat", LANG: "MESSAGE.MUTE_CHAT" },
    { value: "Hide Chat", LANG: "MESSAGE.HIDE_CHAT", textColor: "#9747FF" },
    { value: "Un-Pin Chat", LANG: "MESSAGE.UN_PIN_CHAT" },
    { divider: true },
];

// GROUP
export const hideLeaveForGroup: MessageDetailsMenusListInterface[] = [
    { value: "View Channel Details", LANG: "MESSAGE.VIEW_CHANNEL_DETAILS" },
    { value: "Copy link", LANG: "MESSAGE.COPY_LINK" },
    { divider: true },
    { value: "Mute Channel", LANG: "MESSAGE.MUTE_CHANNEL" },
    // { value: "Notification Settings", LANG: "MESSAGE.NOTIFICATION_SETTINGS" },
    { value: "Pin Channel", LANG: "MESSAGE.PIN_CHANNEL" },
    { divider: true },
];

// PERSONAL
export const hideLeaveForPrsonalChat: MessageDetailsMenusListInterface[] = [
    { value: "Copy link", LANG: "MESSAGE.COPY_LINK" },
    { divider: true },
    { value: "Mute Chat", LANG: "MESSAGE.MUTE_CHAT" },
    { value: "Hide Chat", LANG: "MESSAGE.HIDE_CHAT", textColor: "#9747FF" },
    { value: "Pin Chat", LANG: "MESSAGE.PIN_CHAT" },
    { divider: true },
];
// third party
export const thirdPartyChat: MessageDetailsMenusListInterface[] = [
    { value: "Copy link", LANG: "MESSAGE.COPY_LINK" },
    { divider: true },
    { value: "View Channel Details", LANG: "MESSAGE.VIEW_CHANNEL_DETAILS" },
    { divider: true },
    { value: "Leave Channel", LANG: "MESSAGE.LEAVE_CHANNEL", textColor: "#FF0000" },
];

// PERSONAL
export const upperchenalMenuWithoutLeave: MessageDetailsMenusListInterface[] = [
    { value: "Copy link", LANG: "MESSAGE.COPY_LINK" },
    { divider: true },
    { value: "Mute Chat", LANG: "MESSAGE.MUTE_CHAT" },
    { value: "Hide Chat", LANG: "MESSAGE.HIDE_CHAT", textColor: "#9747FF" },
    { value: "Un-Pin Chat", LANG: "MESSAGE.UN_PIN_CHAT" },
    { divider: true },
];



