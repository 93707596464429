import React, {  useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import {
  CloudStorageState,
  setSelectedDrive,
  toggleFilterBy,
} from "../../../../../../redux";
import { Stack, Typography } from "@mui/material";
import { getDriveIcon } from "../../../../../../Utils";
import { useCustomSnackbar } from "../../../../EnqueueSnackbar";
import { ThreeDotMenuSmall } from "../../../../../pages/CloudStorage/components";

type Props = {};

const AvailableConnection = (props: Props) => {
  const dispatch = useDispatch();
  const enqueueSnackbar_v1 = useCustomSnackbar();
  const cloudStorageState = useSelector(
    (state: RootState) => state && (state?.cloudStorage as CloudStorageState)
  );
  const { selectedDrive, availableDrives } = cloudStorageState || {};

  const handleDriveClick = useCallback(
    async (driveType: string) => {
      const clickedDrive = availableDrives?.find(
        (drive) => drive.type === driveType
      );

      if (!clickedDrive) {
        enqueueSnackbar_v1("warning", `Drive of type ${driveType} not found.`, {
          position: { vertical: "top", horizontal: "center" },
          autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
        });
        return;
      }
      await dispatch(toggleFilterBy(clickedDrive.type as any));
      if (!clickedDrive.connected) {
        enqueueSnackbar_v1(
          "warning",
          `${clickedDrive.name} is not connected.`,
          {
            position: { vertical: "top", horizontal: "center" },
            autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
          }
        );
        return;
      }

      if (selectedDrive === driveType) {
        return;
      }

      dispatch(setSelectedDrive(driveType));

      enqueueSnackbar_v1("success", `Drive Changed to ${driveType}`, {
        position: { vertical: "top", horizontal: "center" },
        autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
      });
    },
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [dispatch, enqueueSnackbar_v1, availableDrives, selectedDrive]
  );

  return (
    <div>
      <Stack
        mt={1}
        direction={"column"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        gap={1}
      >
        {availableDrives?.map((drive) => (
          <Stack
            key={drive.id}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              cursor: "pointer", // Show pointer cursor on hover
              padding: 1,
              width: "100%",
              borderRadius: 1,
              backgroundColor:
                selectedDrive === drive.type ? "#f0f0f0" : "transparent", // Highlight selected drive
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "#dcffde", // Light hover effect
              },
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                onClick={() => handleDriveClick(drive.type)} // Handle click to set selected drive
                justifyContent={"flex-start"}
                gap={2}
              >
                {getDriveIcon(drive.type, 20)}
                <Typography
                  sx={{
                    fontFamily: "Source Serif Pro",
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    lineHeight: "17.54px",
                  }}
                >
                  {drive.name}
                </Typography>
              </Stack>
              <ThreeDotMenuSmall dy={drive.type} />
            </Stack>
          </Stack>
        ))}
      </Stack>
    </div>
  );
};

export default AvailableConnection;
