import React from "react";
import { MsgMiniCard } from "../MsgMiniCard";
import {DummyImage, SlackPng} from "../../../../../images";
import { Box, Stack } from "@mui/material";

interface DrawerChannelViewCardProps {
  unreadMsgCount?: number;
  isSelected?: boolean;
  onCardClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    title?: string;
    platformIcon?: string;
    onCardRightClick?: any; // Add this prop
}

const DrawerChannelViewCard: React.FC<DrawerChannelViewCardProps> = ({
  unreadMsgCount = 0,
  isSelected,
  onCardClick,
                                                                         title = "",
                                                                         platformIcon = SlackPng,
                                                                         onCardRightClick
}) => {
  return (
    <MsgMiniCard
      headerComponent={
        <Stack
          sx={{
            height: "25px",
            width: "25px",
            borderRadius: "5px",
            bgcolor: "#E9EDF2",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component={"img"}
            src={DummyImage}
            alt="logo"
            sx={{ height: "15px", width: "15px" }}
          />
        </Stack>
      }
      hostImgUrl={platformIcon}
      label={title}
      unreadMsgCount={unreadMsgCount}
      isSelected={isSelected}
      onCardClick={(e) => onCardClick && onCardClick(e)}
      onCardRightClick={onCardRightClick} // Pass this prop
    />
  );
};

export default DrawerChannelViewCard;
