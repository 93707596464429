import React, {useRef} from "react";
import {IconButton, Stack} from "@mui/material";
import {FileWithUploadArrowIcon} from "../../../../../../../images";

interface UploadBoxViewProps {
    onFileSelect?: (file: File) => void;
}

const UploadBoxView: React.FC<UploadBoxViewProps> = ({ onFileSelect }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && onFileSelect) {
        const file = event.target.files[0];
        onFileSelect(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && onFileSelect) {
        const file = event.dataTransfer.files[0];
        onFileSelect(file);
    }
  };

  const handleChooseFileClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Stack
      sx={{
      height: "100px",
      width: "100%",
      border: "1px dashed #E9EDF2",
      justifyContent: "center",
      alignItems: "center",
      gap: "3px",
      borderRadius: "5px",
      }}
      onDragOver={(event) => {
      handleDragOver(event);
      event.currentTarget.style.borderColor = "black";
      }}
      onDragLeave={(event) => {
      event.currentTarget.style.borderColor = "#E9EDF2";
      }}
      onDrop={(event) => {
      handleDrop(event);
      event.currentTarget.style.borderColor = "#E9EDF2";
      }}
    >
      <FileWithUploadArrowIcon w={30} />
      <span
      style={{
        font: "normal normal 300 11px/14px Source Serif Pro",
      }}
      >
      Drag and Drop File here or
      <IconButton
        disableRipple
        onClick={handleChooseFileClick}
        sx={{
        display: "inline-block",
        padding: "0px 3px",
        font: "inherit",
        color: "black",
        ":hover": {
          textDecoration: "underline",
        },
        }}
      >
        Choose File
      </IconButton>
      <input
        type="file"
        ref={fileInputRef}
        multiple
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      </span>
    </Stack>
  );
};

export default UploadBoxView;
