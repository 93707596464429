import React from "react";
import { Stack, Typography } from "@mui/material";
import { ScheduleTableDateListType } from "../../AttendanceScheduleTableType";
import {
  formatDateAsDDMMYYYY,
  getDayNameByDate,
  isToday,
} from "../../AttendanceTableUtils";
import { FilledShiftInfoView, UnfilledShiftInfoView } from "../../common";

interface WeekViewDateAreaProps {
  dateList: ScheduleTableDateListType;
  fullWidth?: boolean;
  hideUnfilledShiftHover?: boolean;
}

const WeekViewDateArea: React.FC<WeekViewDateAreaProps> = ({
  dateList,
  fullWidth,
  hideUnfilledShiftHover = false,
}) => {
  const { date } = dateList;
  return (
    <Stack
      sx={{
        width: fullWidth ? "100%" : "150px",
        height: "40px",
        boxSizing: "border-box",
        bgcolor: "#E9EDF2",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 5px",
        border: isToday(date) ? "2px solid #00767B" : "0px",
      }}
    >
      <Stack
        sx={{
          gap: "1px",
          font: "normal normal 600 11px/14px Source Serif Pro",
        }}
      >
        <Typography sx={{ font: "inherit" }}>
          {getDayNameByDate({ dateString: date, dayLength: "full" })}
        </Typography>
        <Typography sx={{ font: "inherit" }}>
          {formatDateAsDDMMYYYY(date)}
        </Typography>
      </Stack>

      {!hideUnfilledShiftHover && (
        <>
          {dateList.isShiftUnfilled ? (
            <UnfilledShiftInfoView
              unfilledShiftData={dateList.unfilledShiftData!}
            />
          ) : (
            <FilledShiftInfoView />
          )}
        </>
      )}
    </Stack>
  );
};

export default WeekViewDateArea;
