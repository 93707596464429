import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import { SmallPinnedCard } from "../../../../common";
import { ButtonGroupOneView, ButtonGroupTwoView } from "./view";
import styles from "./CommandControlDashboard.module.css";
import { renderIconPng } from "../../common";

export type CommandControlDashboardCardVariants =
  | "task"
  | "taskDone"
  | "overdueTask"
  | "message"
  | "flag"
  | "chat"
  | "pendingEvent"
  | "upcomingEvent"
  | "reminderEvent"
  | "call"
  | "postCall"
  | "timeOff"
  | "expense"
  | "payroll"
  | "timesheet"
  | "performace"
  | "development"
  | "compliance";

interface CommandControlDashboardProps {
  cardType: any;
  showDetailsArea?: boolean;
  details?: string;
  dateOrAmmonut?: string;
  handleShowPopup?: () => void;
  cmd_data?: any;
  w?: any;
  onUpdateButton_Click?: any;
  onSnoozed__Click?: any;

  /* Calendar Events... */
  onDoneButton_Click?: any;
  onRejectButton_Click?: any;
  onAcceptButton_Click?: any;
}

const CommandControlDashboard: React.FC<CommandControlDashboardProps> = ({
  cardType,
  dateOrAmmonut,
  details,
  showDetailsArea = false,
  handleShowPopup,
  cmd_data,
  w = "485px",
  onUpdateButton_Click,
  onSnoozed__Click,

  onDoneButton_Click,
  onRejectButton_Click,
  onAcceptButton_Click,
}) => {
  const propertyMap: Record<
    string,
    { tagName: string; tagColor: string; buttonGroup: "group1" | "group2" }
  > = {
    task: { tagName: "@Task", tagColor: "#A53A01", buttonGroup: "group1" },
    taskDone: {
      tagName: "@TaskDone",
      tagColor: "#A53A01",
      buttonGroup: "group1",
    },
    overdueTask: {
      tagName: "@OverdueTask",
      tagColor: "#A53A01",
      buttonGroup: "group1",
    },
    message: {
      tagName: "@message",
      tagColor: "#775EE2",
      buttonGroup: "group1",
    },
    flag: { tagName: "@flag", tagColor: "#FF0000", buttonGroup: "group1" },
    chat: { tagName: "@chat", tagColor: "#775EE2", buttonGroup: "group1" },
    pending_events: {
      tagName: "@pendingEvent",
      tagColor: "#B5005B",
      buttonGroup: "group2",
    },
    upcoming_events: {
      tagName: "@upcomingEvent",
      tagColor: "#B5005B",
      buttonGroup: "group1",
    },
    past_events: {
      tagName: "@pastEvents",
      tagColor: "#B5005B",
      buttonGroup: "group1",
    },
    ongoing_events: {
      tagName: "@ongoingEvents",
      tagColor: "#B5005B",
      buttonGroup: "group1",
    },
    booking_links: {
      tagName: "@bookingLinks",
      tagColor: "#B5005B",
      buttonGroup: "group1",
    },
    reminder_events: {
      tagName: "@reminderEvent",
      tagColor: "#B5005B",
      buttonGroup: "group1",
    },
    call: { tagName: "@call", tagColor: "#FF8181", buttonGroup: "group1" },
    calls: { tagName: "@calls", tagColor: "#FF8181", buttonGroup: "group1" },
    post_call: {
      tagName: "@postCall",
      tagColor: "#FF8181",
      buttonGroup: "group1",
    },
    timeOff: {
      tagName: "@timeOff",
      tagColor: "#308893",
      buttonGroup: "group1",
    },
    expense: {
      tagName: "@expense",
      tagColor: "#308893",
      buttonGroup: "group1",
    },
    payroll: {
      tagName: "@payroll",
      tagColor: "#308893",
      buttonGroup: "group1",
    },
    timesheet: {
      tagName: "@timesheet",
      tagColor: "#308893",
      buttonGroup: "group1",
    },
    performace: {
      tagName: "@performace",
      tagColor: "#308893",
      buttonGroup: "group1",
    },
    development: {
      tagName: "@development",
      tagColor: "#308893",
      buttonGroup: "group1",
    },
    compliance: {
      tagName: "@compliance",
      tagColor: "#308893",
      buttonGroup: "group1",
    },
  };

  const getProperty = (): {
    tagName: string;
    tagColor: string;
    buttonGroup: "group1" | "group2";
  } => {
    return (
      propertyMap[cardType] || {
        tagName: "",
        tagColor: "",
        buttonGroup: "group1",
      }
    ); // Fallback to default values
  };

  const getButton = (buttonGroup: "group1" | "group2") => {
    switch (buttonGroup) {
      case "group1":
        return (
          <ButtonGroupOneView
            onDoneClick={() => onUpdateButton_Click?.()}
            onSnoozeSelect={(value: any) => onSnoozed__Click?.(value)}
          />
        );
      case "group2":
        return (
          <ButtonGroupTwoView
            onDoneButton_Click={() =>  onDoneButton_Click?.()}
            onRejectButton_Click={() =>  onRejectButton_Click?.()}
            onAcceptButton_Click={() =>  onAcceptButton_Click?.()}
          />
        );
    }
  };

  return (
    <Stack
      className={styles.CommandControlContainer}
      sx={{
        bgcolor: adjustHexColor("#ffffff", 65),
        marginBottom: "10px",
        width: w,
      }}
    >
      <Stack className={styles.CommandControlDashboard}>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            width: "60%",
          }}
          onClick={handleShowPopup}
        >
          <SmallPinnedCard
            userIconSrc={cmd_data?.assignedTo?.avatar}
            size={25}
            isActive={cmd_data?.assignedTo?.status === "ONLINE" ? true : false}
          />
          <Typography className={styles.CommandControlDashboard__userName}>
            {(cmd_data?.assignedTo?.firstName || "Unassigned") +
              " " +
              (cmd_data?.assignedTo?.lastName || "")}
          </Typography>

          <Typography
            className={styles.CommandControlDashboard__tag}
            style={{ color: getProperty().tagColor }}
          >
            {getProperty().tagName}
          </Typography>
          {cmd_data?.desc && (
            <Typography
              className={styles.CommandControlDashboard__dec}
              style={{ color: "black" }}
            >
              {"Details about the command control"}
            </Typography>
          )}
        </Stack>
        <Stack className={styles.CommandControlDashboard__buttonRow}>
          <Box
            component={"img"}
            src={renderIconPng(
              cmd_data?.source === "Jira"
                ? "jiraPng"
                : cmd_data?.source === "Confluence"
                  ? "confluencePng"
                  : "bearishPng"
            )}
            alt="logo"
            className={styles.CommandControlDashboard__logo}
          />
          {(cmd_data?.status !== "DONE") && getButton(getProperty().buttonGroup)}
        </Stack>
      </Stack>

      {showDetailsArea && details && (
        <Stack className={styles.CommandControlDashboard__detailsArea}>
          <Typography
            className={styles.CommandControlDashboard__detailsText}
            style={{ width: dateOrAmmonut ? "300px" : "440px" }}
          >
            {details}
          </Typography>
          <Typography sx={{ font: "inherit" }}>{dateOrAmmonut}</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default CommandControlDashboard;
